import React, { useState, useRef } from "react";
import { GlobalStore } from "../stores/global";
import { BsOctagonHalf } from "react-icons/bs";
import { Config, ReleaseImage } from "../__generated__/graphql";
import { getConfigProperty } from "../utils";
import { useTranslation } from "react-i18next";

const Image = ({
  entry,
  className,
  id,
  alt,
  onClick
}: {
  entry: ReleaseImage;
  className?: string;
  id?: string;
  alt?: string;
  onClick?: (e: any) => void;
}) => {
  const config = GlobalStore.useState(c => c.config);
  const { t } = useTranslation();

  const [isHover, setIsHover] = useState(false);
  const imgRef = useRef(null);
  const [imageSizes, setImageSizes] = useState<{ width: number; height: number } | undefined>(undefined);

  const imageEntry = entry || null;
  let imageSrc = imageEntry?.uri || getConfigProperty(config as Config, "designs", "missingImageUri") || null;

  const alternativeSrc = imageEntry && imageEntry.alternative && imageEntry.alternative.uri;
  if (isHover && alternativeSrc) imageSrc = alternativeSrc;

  const handleImageLoaded = (e: any) => {
    setImageSizes({
      width: parseInt(e.target.getBoundingClientRect().width),
      height: parseInt(e.target.getBoundingClientRect().height)
    });
  };
  const style: any = {};
  if (imageSizes) {
    style.height = imageSizes.height;
    style.objectFit = "contain";
  }
  if (onClick) style.cursor = "pointer";

  return (
    <div
      key={id}
      onClick={() => onClick && onClick(imageEntry)}
      className={`imageComponent ${className || ""} ${alternativeSrc ? "withAlternative" : ""}`}>
      <img
        ref={imgRef}
        onLoad={handleImageLoaded}
        style={style}
        id={id}
        alt={alt}
        src={imageSrc}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      />
      {alternativeSrc ? <BsOctagonHalf /> : null}
      {imageSizes ? (
        <small className="imageRatio">
          {/* eslint-disable-next-line i18next/no-literal-string */}
          {t("Ratio")}: {imageSizes.width}x{imageSizes.height} / {(imageSizes.width / imageSizes.height).toFixed(2)}
        </small>
      ) : null}
    </div>
  );
};

export default Image;
