import React, { useRef } from "react";
import Button from "../styled/button";
import Modal from "../modal";
import Uploader from "./uploader";
import Browser from "./browser";
import { GlobalStore } from "../../stores/global";
import { Typography } from "../../componentsV2/Typography";
import { Button as ButtonV2 } from "../../componentsV2/Button";
import { useTranslation } from "react-i18next";
import { ModalHeaderContainer } from "../../componentsV2/SectionHeader/SectionHeader.styles";

export default function Media() {
  const { addNotification } = GlobalStore.useState(c => c);
  const browserRef = useRef<any>();
  const modalRef = useRef<any>();

  const { t } = useTranslation();

  document.title = "Media library";

  const handleUploaded = async () => {
    await browserRef.current.getLibrary();
  };

  return (
    <div id="media">
      <Modal style={{}} ref={modalRef}>
        <div className="mediaUploadModal">
          <ModalHeaderContainer className="header">
            <Typography variant="pageTitle" tag="h2">
              {t("Upload")}
            </Typography>
            <Button variant="noStyle" type="button" onClick={() => modalRef.current.close()}>
              <i className="cg-icon-burger-close" />
            </Button>
          </ModalHeaderContainer>
          <div className="content">
            <Uploader
              addNotification={addNotification}
              onUploaded={async () => {
                await handleUploaded();
                modalRef.current.close();
              }}
            />
          </div>
          <div className="footer">
            <div className="left"></div>
            <div className="right"></div>
          </div>
        </div>
      </Modal>
      <section className="header">
        <div className="left">
          <Typography variant="pageTitle" tag="h1">
            {t("Media library")}
          </Typography>
        </div>
        <div className="right">
          <ButtonV2 variant="primary" type="button" onClick={() => modalRef.current.open()}>
            {t("Upload")}
          </ButtonV2>
        </div>
      </section>
      <div className="content centralContainer">
        <Browser addNotification={addNotification} multiple={true} ref={browserRef} />
      </div>
    </div>
  );
}
