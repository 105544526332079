import { gql } from "../../__generated__/gql";

export const GET_HELP_ARTICLE = gql(`  
  query helpArticle($id: String!) {
    helpArticle(id: $id) {
      id
      title
      url
      body
      translatedContent {
        fr {
          title
          description
          body
          url
        }
      }
    }
  }
`);
