import React, { useState } from "react";
import Zone from "../styled/zone";
import TextArea from "../styled/textArea";
import Button from "../styled/button";
import Loader from "../common/loader";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { GlobalStore } from "../../stores/global";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import {
  GET_SUPPORT_CONVERSATION,
  GET_SUPPORT_CONVERSATION_SIGNED_URL,
  POST_SUPPORT_CONVERSATION_CLOSE,
  POST_SUPPORT_CONVERSATION_REPLY
} from "../../graphql/queries/support";
import Axios from "axios";
import * as Sentry from "@sentry/react";
import { Match } from "../../types/globals";
import { SupportConversation } from "../../__generated__/graphql";
import { Button as ButtonV2 } from "../../componentsV2/Button";
import { useTranslation } from "react-i18next";

export default function Ticket({ match }: { match: Match }) {
  const { addNotification } = GlobalStore.useState(c => c);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ticketContent, setTicketContent] = useState<{ body: ""; files: File[] }>({ body: "", files: [] });
  const ticketId = match.params.id;
  const history = useHistory();

  const { t } = useTranslation();

  const { data, loading } = useQuery(GET_SUPPORT_CONVERSATION, {
    fetchPolicy: "cache-and-network",
    variables: { conversationId: ticketId }
  });
  const [sendReply] = useMutation(POST_SUPPORT_CONVERSATION_REPLY);
  const [closeConversation] = useMutation(POST_SUPPORT_CONVERSATION_CLOSE);
  const [getSignedUrl] = useLazyQuery(GET_SUPPORT_CONVERSATION_SIGNED_URL);

  const conversation = data?.supportConversation;
  document.title = t("Support");

  const handleFileChange = (event: any) => {
    ticketContent.files = event.target.files;
    setTicketContent({ ...ticketContent });
  };

  const handleSubmitIntercomReply = async (e: any) => {
    if (!conversation) return;
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const urlsToSend = [];
      if (ticketContent.files) {
        const files = Array.from(ticketContent.files) || [];
        for (const file of files) {
          const { data } = await getSignedUrl({ variables: { conversationId: conversation.id, filename: file.name } });
          if (!data?.supportConversationSignedUrl?.url) throw new Error("Attachment error");
          const options = { headers: { "Content-Type": file.type, "Cache-Control": "max-age=31536000" } };
          await Axios.put(data.supportConversationSignedUrl.url, file, options);
          urlsToSend.push("https://static.common-ground.io/" + data.supportConversationSignedUrl.key);
        }
      }
      await sendReply({ variables: { conversationId: conversation.id, content: e.target.content.value, attachments: urlsToSend } });
      addNotification({ ok: 1, message: t("Your reply was submitted") });
      setTicketContent({ ...ticketContent, body: "" });
    } catch (e: any) {
      Sentry.captureException(e);
      addNotification({ ok: 0, message: e.message });
    } finally {
      setTicketContent({ files: [], body: "" });
      setIsSubmitting(false);
    }
  };

  const handleCloseIntercomTicket = async (ticketToClose: SupportConversation) => {
    try {
      await closeConversation({ variables: { conversationId: ticketToClose.id } });
      addNotification({ ok: 1, message: t("Ticket was closed") });
      history.push("/support");
    } catch (e: any) {
      Sentry.captureException(e);
      addNotification({ ok: 0, message: e.message });
    }
  };

  const handleTextAreaChange = (e: any) => {
    setTicketContent({ ...ticketContent, body: e.target.value });
  };

  if (loading || conversation === undefined) return <Loader withMargins />;
  else if (conversation === null) return <p>{t("Ticket not found")}</p>;

  return (
    <div id="ticket">
      <div className="header">
        <h2>
          {conversation.source?.subject || t("Ticket")} - {t("Created")} {moment(conversation.createdAt).format("ll")} -{" "}
          {conversation.state}
        </h2>
        {conversation.state === "open" ? (
          <Button variant="danger" type="button" onClick={() => handleCloseIntercomTicket(conversation)}>
            {t("Close ticket")}
          </Button>
        ) : (
          <span />
        )}
      </div>
      <div className="content">
        <div className="left stickyContainer">
          {conversation.source ? (
            <section className="ticketDescription">
              <h3>{conversation.source.subject}</h3>
              <div className="description" dangerouslySetInnerHTML={{ __html: conversation.source.body || "" }} />
            </section>
          ) : null}
          {conversation.state === "open" ? (
            <section>
              <form onSubmit={handleSubmitIntercomReply}>
                <TextArea
                  name="content"
                  style={{ marginBottom: "var(--gutter)" }}
                  placeholder={t("Enter your reply") + "..."}
                  rows="10"
                  value={ticketContent.body}
                  onChange={handleTextAreaChange}
                />
                <label>
                  {t("Attach image files such as screenshots")}:
                  <input type="file" name="attachments" multiple onChange={(e: any) => handleFileChange(e)} />
                </label>
                <div className="flexSpaceBetween">
                  <ButtonV2 variant="primary" disabled={isSubmitting || !ticketContent.body} type="submit">
                    {isSubmitting ? <Loader /> : t("Send reply")}
                  </ButtonV2>
                </div>
              </form>
            </section>
          ) : null}
        </div>
        <section className="right">
          {conversation.parts ? (
            conversation.parts.map(m => (
              <Zone key={m.id} className={`message ${m.author.type === "admin" ? "fromSupport" : ""}`}>
                <h3>
                  {m.author.name} - {moment(m.createdAt).format("ll")}, {moment(m.createdAt).fromNow()}
                </h3>
                <section dangerouslySetInnerHTML={{ __html: m.body }} />
                {m.attachments && m.attachments.length
                  ? m.attachments.map((a, idx) => (
                      <div key={idx} className="attachment">
                        <a target="_blank" href={a.url || ""} rel="noreferrer">
                          {a.title}
                        </a>
                      </div>
                    ))
                  : null}
              </Zone>
            ))
          ) : (
            <h2>{t("No messages were sent")}</h2>
          )}
        </section>
      </div>
    </div>
  );
}
