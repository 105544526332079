import React from "react";
import styled from "styled-components";
import colors from "@common-ground-io/common-assets/assets/colors.json";

const layout = {
  light: {
    primary: {
      fontColorLabel: colors.grey,
      bcgColor: colors.greyLightest,
      fontColor: colors.greyLight,
      fontColorActive: colors.greyDark
    },
    overZone: {
      fontColorLabel: colors.grey,
      bcgColor: colors.white,
      fontColor: colors.greyLight,
      fontColorActive: colors.greyDark
    }
  },
  dark: {
    primary: {
      fontColorLabel: colors.grey,

      bcgColor: colors.greyDarker,
      bcgColorHover: colors.greyDark,
      bcgColorActive: colors.greyDarker,

      borderColor: "transparent",
      borderColorHover: colors.greyLight,
      borderColorActive: colors.greyLight,

      fontColor: colors.grey,
      fontColorHover: colors.greyLighter,
      fontColorActive: colors.greyLighter
    },
    overZone: {
      fontColorLabel: colors.grey,

      bcgColor: colors.greyDarkest,
      bcgColorHover: colors.greyDark,
      bcgColorActive: colors.greyDarker,

      borderColor: "transparent",
      borderColorHover: colors.grey,
      borderColorActive: colors.grey,

      fontColor: colors.grey,
      fontColorHover: colors.greyLighter,
      fontColorActive: colors.greyLighter
    }
  }
};

const Styledlabel = styled.label<{ variant?: string }>`
  font-style: normal;
  font-weight: 600;
  font-size: inherit;
  line-height: 1;
  color: ${props => path(layout, props).fontColorLabel};
`;

const StyledCapsule = styled.button<{ active?: boolean; label?: string; fullWidth?: boolean; variant?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 10px;
  margin: 0;
  margin-top: ${props => (props.label ? "10px" : "0px")};
  border: transparent;
  border-radius: 10px;
  outline: none;

  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  line-height: 1;

  cursor: pointer;

  width: ${({ fullWidth }) => fullWidth && "100%"};
  background-color: ${props => path(layout, props).bcgColor};
  border-color: ${props => path(layout, props).borderColor};
  color: ${props => path(layout, props).fontColor};
  box-shadow: none;
  outline: none;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid;
    border-color: ${props => path(layout, props).borderColorHover};
    background-color: ${props => path(layout, props).bcgColorHover};
    color: ${props => path(layout, props).fontColorHover};
  }

  &:active {
    border: 1px solid;
    border-color: ${props => path(layout, props).borderColorActive};
    background-color: ${props => path(layout, props).bcgColorActive};
    color: ${props => path(layout, props).fontColorActive};
  }

  ${props => {
    const active = props.active;
    const obj = path(layout, props);
    if (active)
      return `
      border: 1px solid;
      border-color: ${obj.borderColorActive};
      color: ${obj.fontColorActive};
    `;
  }}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const CapsulesContainer = styled.div<{ label?: string }>`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: var(--gutter);
  margin-top: ${props => (props.label ? "10px" : "0px")};
  &.mobile {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const computeVariant = (variant: string, layout: any) => (layout.light[variant] ? variant : "primary");
const path = (layout: any, props: any) => layout[props.theme.name || "light"][props.variant];

const CapsuleGroupComponent = (props: any) => {
  const variant = computeVariant(props.variant, layout);
  const entries = props.entries || [];
  const value = props.value;

  return (
    <Container className="cg-common cg-capsuleGroup">
      {props.label && (
        <Styledlabel
          htmlFor={props.id || props.name || ""}
          className={`cg-common ${variant} ${props.className || ""}-label`}
          variant={variant}>
          {props.label}
        </Styledlabel>
      )}
      <CapsulesContainer theme={props.theme} label={props.label} className={props.isMobile ? "mobile" : ""}>
        {entries.map((entry: any, i: number) => (
          <StyledCapsule
            key={i}
            className={entry.value === value ? "active" : ""}
            onClick={() => props.onClick(entry.value, props.name)}
            variant={variant}
            active={entry.value === value}>
            {entry.label}
          </StyledCapsule>
        ))}
      </CapsulesContainer>
    </Container>
  );
};

export default CapsuleGroupComponent;
