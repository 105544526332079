import React from "react";
import { Container } from "./SectionContainer.styles";

type Props = {
  children: React.ReactNode;
};

export const SectionContainer: React.FC<Props> = ({ children }) => {
  return (
    <Container>
      {children}
      {/* Temporary div so force the gap when only 1 item is in the container */}
      {/* TODO: remove when more components are migrated */}
      <div />
    </Container>
  );
};
