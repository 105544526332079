import { gql } from "../../__generated__/gql";

export const INVOICE_FIELDS = gql(`
  fragment InvoiceFields on Invoice {
    _id
    id
    created
    title
    type
    note
    currency
    items {
      price {
        value
        currency
        currencySymbol
      }
      title
    }
    totals {
      grand
      tax
    }
    totalInConfigCurrency {
      currency
      value
      currencySymbol
    }
    taxNotice
    paid {
      status
      date
      processing
      method
    }
    pdf
  }
`);

export const GET_INVOICES = gql(`
  query invoices($limit: Int, $page: Int) {
    invoices(page: $page, limit: $limit) {
      invoices {
        ...InvoiceFields
      }
      hasOutstandingInvoices
      pagination {
        count
        pages
        page
      }
    }
  }
`);

export const POST_INVOICE_CHARGE = gql(`
  mutation invoiceCharge($invoiceRef: ID!, $paymentMethodId: ID) {
    invoiceCharge(invoiceRef: $invoiceRef, paymentMethodId: $paymentMethodId) {
      message
      invoice {
        ...InvoiceFields
      }
      error
      intent
      ok
    }
  }
`);
