import React, { useState, useRef } from "react";
import Loader from "../../common/loader";
import { Button } from "../../../componentsV2/Button";
import Input from "../../styled/input";
import TextArea from "../../styled/textArea";
import Checkbox from "../../styled/checkbox";
import Modal from "../../modal";
import { POST_SEND_INVOICE } from "../../../graphql/queries/order";
import { useMutation } from "@apollo/client";
import { SelectTemplate } from "../../common/select";
import TemplateEditor from "../../templates/editor";
import { Order, Session, Template } from "../../../__generated__/graphql";
import { AddNotification } from "../../../types/globals";
import { useTranslation } from "react-i18next";
import { Typography } from "../../../componentsV2/Typography";
import { ModalHeaderContainer } from "../../../componentsV2/SectionHeader/SectionHeader.styles";
import { GlobalStore } from "../../../stores/global";

export default ({
  order,
  addNotification,
  onSent,
  handleGeneratePdf,
  generatingPdf
}: {
  order: Order;
  addNotification: AddNotification;
  onSent: any;
  handleGeneratePdf: (noWindow: boolean) => void;
  generatingPdf: boolean;
}) => {
  const session = GlobalStore.useState(c => c.session) as Session;
  const id = order.billing.invoice && order.billing.invoice.id ? order.billing.invoice.id : order.id;
  const [sendingPdf, setSendingPdf] = useState(false);
  const [message, setMessage] = useState("");
  const [cc, setCc] = useState(false);
  const [emailAddress, setEmailAddress] = useState<string | null>(order.buyer?.email || null);
  const [subject, setSubject] = useState(`Your invoice #${id}`);
  const modalRef = useRef<any>();
  const { t } = useTranslation();

  const [sendInvoice] = useMutation(POST_SEND_INVOICE);

  const handleTemplateSelect = (template: Template) => {
    setMessage(template.data.content || "");
  };

  const handleSendPdf = async (event: any) => {
    if (!emailAddress) return;
    event.preventDefault();
    setSendingPdf(true);
    try {
      await sendInvoice({
        variables: {
          orderRef: order._id,
          orderInvoiceInput: { recipient: emailAddress, message, subject, ccMe: cc }
        }
      });
      onSent();
      addNotification({ ok: 1, message: "Invoice was sent" });
      modalRef.current.close();
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
    setSendingPdf(false);
  };

  return (
    <div>
      <Modal ref={modalRef} style={{ minWidth: "60%" }}>
        <div id="orderModal" className="sendInvoice">
          <ModalHeaderContainer>
            <Typography variant="pageTitle" tag="h2">
              {t("Send invoice")}
            </Typography>
            <button className="reset" type="button" onClick={() => modalRef.current.close()}>
              <i className="cg-icon-burger-close" />
            </button>
          </ModalHeaderContainer>
          <div className="content">
            <div className="left">
              {order.billing.invoice?.pdf ? (
                <embed src={order.billing.invoice.pdf} width="auto" height="auto" />
              ) : (
                <Button variant="secondary" disabled={generatingPdf} onClick={() => handleGeneratePdf(true)} type="button">
                  {generatingPdf ? <Loader /> : t("Generate PDF preview")}
                </Button>
              )}
            </div>
            <div className="right">
              <form onSubmit={handleSendPdf}>
                <Input
                  label={t("Recipient email address")}
                  onChange={(e: any) => setEmailAddress(e.target.value)}
                  type="email"
                  name="email"
                  required
                  value={emailAddress}
                  placeholder={t("Recipient email address")}
                />
                <Input
                  label={t("Email subject")}
                  onChange={(e: any) => setSubject(e.target.value)}
                  type="text"
                  name="subject"
                  required
                  value={subject}
                  placeholder={t("Email subject")}
                />
                <div className="templateControls">
                  <div className="flexSpaceBetween">
                    <SelectTemplate placeholder={t("Select a template")} type="message" onChange={handleTemplateSelect} />
                    <TemplateEditor type="message" />
                  </div>
                  <TextArea
                    rows="8"
                    placeholder={t("Enter your text") + "..."}
                    required
                    value={message}
                    onChange={(e: any) => setMessage(e.target.value)}
                  />
                </div>
                <Checkbox label={t("Send a copy to {{email}}", { email: session.user.email })} checked={cc} onChange={() => setCc(!cc)} />
                <div className="buttons">
                  <Button type="button" disabled={sendingPdf} variant="warning" onClick={() => modalRef.current.close()}>
                    {t("Cancel")}
                  </Button>
                  <Button disabled={sendingPdf} variant="primary" type="submit">
                    {sendingPdf ? <Loader /> : t("Send invoice")}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
      <Button fullWidth variant="primary" onClick={() => modalRef.current.open()} type="button">
        {t("Send invoice")}
      </Button>
    </div>
  );
};
