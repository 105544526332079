import React, { useEffect } from "react";
import Loader from "../common/loader";
import Pagination from "../common/pagination";
import URI from "urijs";
import { useLazyQuery } from "@apollo/client";
import { GET_ITEM_SALES_STATISTICS } from "../../graphql/queries/item";
import OrdersTable from "../../components/orders/table";
import { Location } from "../../types/globals";
import { Item } from "../../__generated__/graphql";
import { useTranslation } from "react-i18next";
import { Button } from "../../componentsV2/Button";

export default function Statistics({ item, location, itemRef }: { item: Item; location: Location; itemRef: string }) {
  const currentUri = new URI(location.pathname + location.search);
  const searchQuery = currentUri.search(true);
  const { t } = useTranslation();

  const [getSalesStatistics, { loading, data: salesStatisticsData }] = useLazyQuery(GET_ITEM_SALES_STATISTICS, {
    fetchPolicy: "cache-and-network"
  });

  useEffect(() => {
    getSalesStatistics({ variables: { itemRef, page: parseInt(searchQuery.page) || 1 } });
  }, [searchQuery.page, itemRef]);

  const statistics = salesStatisticsData && salesStatisticsData.itemSalesStatistics;

  if (!statistics || loading) return <Loader />;
  return (
    <>
      <div id="statisticsHeader" style={{ display: "flex", gap: "var(--gutter)", alignItems: "center" }}>
        <h2>{t("Sales")}</h2>
        <div className="salesExport">
          <Button href={`/orders?itemRef=${item._id}`} variant="secondary" type="link">
            {t("View in orders")}
          </Button>
        </div>
      </div>
      <hr />
      {statistics.orders && !statistics.orders.length ? (
        <h3>{t("There are no sales for this item")}</h3>
      ) : (
        <>
          <Pagination pagination={statistics.pagination} currentUri={currentUri} />
          {statistics && statistics.orders ? (
            <div id="sales">
              <OrdersTable highlight={itemRef} orders={statistics.orders} />
            </div>
          ) : (
            <Loader />
          )}
          {statistics.orders && statistics.pagination.pages > 1 ? (
            <Pagination pagination={statistics.pagination} currentUri={currentUri} />
          ) : null}
        </>
      )}
    </>
  );
}
