import React from "react";
import styled from "styled-components";

import colors from "@common-ground-io/common-assets/assets/colors.json";
import { EThemes } from "../../types/globals";

const layout = {
  light: {
    primary: {
      bcgColor: colors.purpleLight,
      fontColor: colors.purpleDark,
      borderColor: "transparent",
      borderColorHover: colors.purpleDark,
      fontColorHover: colors.purpleDark,
      bcgColorActive: colors.purple,
      borderColorActive: "transparent",
      fontColorActive: colors.purpleLight
    },
    secondary: {
      bcgColor: colors.greyLightest,
      fontColor: colors.grey,
      borderColor: "transparent",
      borderColorHover: colors.grey,
      fontColorHover: colors.grey,
      bcgColorActive: colors.grey,
      borderColorActive: "transparent",
      fontColorActive: colors.greyLightest
    },
    secondaryOverZone: {
      bcgColor: colors.white,
      fontColor: colors.grey,
      borderColor: "transparent",
      borderColorHover: colors.grey,
      fontColorHover: colors.grey,
      bcgColorActive: colors.grey,
      borderColorActive: colors.grey,
      fontColorActive: colors.white
    },
    info: {
      bcgColor: colors.blueLight,
      fontColor: colors.blueDark,
      borderColor: "transparent",
      borderColorHover: colors.blueDark,
      fontColorHover: colors.blueDark,
      bcgColorActive: colors.blueDark,
      borderColorActive: "transparent",
      fontColorActive: colors.blueLight
    },
    success: {
      bcgColor: colors.greenLight,
      fontColor: colors.greenDark,
      borderColor: "transparent",
      borderColorHover: colors.greenDark,
      fontColorHover: colors.greenDark,
      bcgColorActive: colors.greenDark,
      borderColorActive: "transparent",
      fontColorActive: colors.greenLight
    },
    danger: {
      bcgColor: colors.orangeLight,
      fontColor: colors.red,
      borderColor: "transparent",
      borderColorHover: colors.red,
      fontColorHover: colors.red,
      bcgColorActive: colors.orange,
      borderColorActive: colors.orange,
      fontColorActive: colors.orangeLight
    },
    noStyle: {
      bcgColor: "transparent",
      borderColor: "transparent",
      fontColor: colors.greyDark,
      borderColorHover: "transparent",
      fontColorHover: colors.greyDarker,
      bcgColorActive: "transparent",
      borderColorActive: "transparent",
      fontColorActive: colors.greyDarker
    }
  },
  dark: {
    primary: {
      bcgColor: colors.purple,
      fontColor: colors.purpleLight,
      borderColorHover: colors.purpleLight,
      fontColorHover: colors.purpleLight,
      bcgColorHover: colors.purple,
      bcgColorActive: colors.purpleLight,
      borderColor: "transparent",
      borderColorActive: "transparent",
      fontColorActive: colors.purple
    },
    secondary: {
      bcgColor: "transparent",
      fontColor: colors.greyLighter,
      fontColorHover: colors.greyLighter,
      bcgColorActive: colors.greyLighter,
      bcgColorHover: colors.greyDarker,
      borderColor: colors.grey,
      borderColorActive: "transparent",
      borderColorHover: colors.greyLighter,
      fontColorActive: colors.greyDark
    },
    secondaryOverZone: {
      bcgColor: colors.greyDarkest,
      fontColor: colors.greyLighter,
      fontColorHover: colors.greyLightest,
      bcgColorActive: colors.greyLighter,
      borderColor: "transparent",
      bcgColorHover: colors.greyDark,
      borderColorActive: "transparent",
      borderColorHover: colors.greyLighter,
      fontColorActive: colors.greyDark
    },
    info: {
      bcgColor: colors.blueDark,
      fontColor: colors.blueLight,
      fontColorHover: colors.blueLight,
      bcgColorActive: colors.blueLight,
      borderColor: "transparent",
      borderColorHover: colors.blueLight,
      borderColorActive: "transparent",
      fontColorActive: colors.blueDark
    },
    success: {
      bcgColor: colors.green,
      fontColor: colors.greenLight,
      fontColorHover: colors.greenLight,
      bcgColorActive: colors.greenLight,
      borderColor: "transparent",
      borderColorHover: colors.greenLight,
      borderColorActive: "transparent",
      fontColorActive: colors.green
    },
    danger: {
      fontColor: colors.orange,
      fontColorHover: colors.orange,
      fontColorActive: colors.orange,

      bcgColor: colors.greyDarker,
      bcgColorHover: colors.greyDark,
      bcgColorActive: colors.orangeLight,

      borderColor: colors.orange,
      borderColorHover: colors.orange,
      borderColorActive: colors.orange
    },
    noStyle: {
      bcgColor: "transparent",
      fontColor: colors.greyLight,
      fontColorHover: colors.greyLighter,
      bcgColorActive: "transparent",
      borderColor: "transparent",
      borderColorActive: "transparent",
      borderColorHover: "transparent",
      fontColorActive: colors.greyLighter
    }
  }
};

const StyledButton = styled.button<{ disabled?: boolean; variant: string; fullWidth?: boolean }>`
  height: auto;
  padding: 5px 10px;
  border: 1px solid ${props => path(layout, props).borderColor};
  border-radius: 10px;
  cursor: pointer;
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  font-style: normal;
  line-height: 1;
  text-align: "center";
  width: ${({ fullWidth }) => fullWidth && "100%"};
  background-color: ${props => path(layout, props).bcgColor};
  color: ${props => path(layout, props).fontColor};

  &:hover {
    border: 1px solid;
    border-color: ${props => path(layout, props).borderColorHover};
    background-color: ${props => path(layout, props).bcgColorHover};
    color: ${props => path(layout, props).fontColorHover};
  }

  &:active,
  &.active {
    border: 1px solid;
    border-color: ${props => path(layout, props).borderColorActive};
    background-color: ${props => path(layout, props).bcgColorActive};
    color: ${props => path(layout, props).fontColorActive};
  }
`;

const computeVariant = (variant: string, layout: any) => (layout.light[variant] ? variant : "primary");
const path = (layout: any, props: any) => layout[props.theme?.name || "light"][props.variant];

const ButtonComponent = (props: {
  type?: "button" | "submit";
  disabled?: boolean;
  onClick?: any;
  fullWidth?: boolean;
  style?: any;
  variant?: string;
  className?: string;
  form?: string;
  title?: string;
  children?: any;
  label?: string;
  value?: string;
  id?: string;
  overZone?: boolean;
  theme?: { name: EThemes };
}) => {
  const variant = computeVariant(props.variant || "", layout);
  return (
    <StyledButton {...props} className={`cg-common cg-button ${variant} ${props.className || ""}`} variant={variant}>
      {props.children}
    </StyledButton>
  );
};

export default ButtonComponent;
