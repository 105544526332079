import React from "react";
import { languages } from "../i18n/i18n";
import { useTranslation } from "react-i18next";

const LanguageSelector = () => {
  const { i18n, t } = useTranslation();

  const currentLanguage = i18n.language;
  const index = languages.indexOf(currentLanguage);

  const handleChangeLanguage = (index: number) => {
    localStorage.setItem("locale", languages[index]);
    i18n.changeLanguage(languages[index]);
  };

  if (languages.length < 2) return null;

  return (
    <div className="languageSelector superAdmin">
      <p>{t("Language selection")}</p>
      <div className="languages">
        {languages.map((l, i) => (
          <div className="entry" key={l}>
            <button
              onClick={() => handleChangeLanguage((index + 1) % languages.length)}
              className={`${currentLanguage === l ? "active" : ""}`}>
              {l.toUpperCase()}
            </button>
            {i < languages.length - 1 ? "/" : ""}
          </div>
        ))}
      </div>
    </div>
  );
};
export default LanguageSelector;
