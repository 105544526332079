import React, { useEffect, useRef } from "react";
import Zone from "../../components/styled/zone";
import CapsuleGroup from "../../components/styled/capsule";
import { Link, useLocation, useHistory } from "react-router-dom";
import Loader from "../../components/common/loader";
import Price from "../../components/common/price";
import Liner from "../../components/common/liner";
import moment from "moment";
import URI from "urijs";
import Pagination from "../../components/common/pagination";
import { GlobalStore } from "../../stores/global";
import CreateCreditNote from "./create";
import DetailsView from "./details";
import Modal from "../../components/modal";
import { useMutation, useQuery } from "@apollo/client";
import { GET_CREDITS, POST_CREDIT_VOID } from "../../graphql/queries/credit";
import { Config, Credit } from "../../__generated__/graphql";
import { SectionContainer } from "../../componentsV2/SectionContainer";
import { Button as ButtonV2 } from "../../componentsV2/Button";
import { RightSection, SectionHeader } from "../../componentsV2/SectionHeader";
import { useTranslation } from "react-i18next";

export default ({ userRef, noHeader }: { userRef: string; noHeader?: boolean }) => {
  const history = useHistory();
  const location = useLocation();
  const modalRef = useRef<any>();
  const currentUri = new URI(location.pathname + location.search);
  const searchQuery = currentUri.search(true);
  const { addNotification, config } = GlobalStore.useState(c => c);
  const { t } = useTranslation();
  document.title = t("Credit notes");

  const filters = {
    ...searchQuery,
    userRef: userRef || undefined,
    status: searchQuery.status || localStorage.getItem("creditNotes-status") || "All",
    sortBy: searchQuery.sortBy || "createdAt",
    sortOrder: parseInt(searchQuery.sortOrder) || -1,
    page: parseInt(searchQuery.page || 1)
  };

  const { data, loading, refetch } = useQuery(GET_CREDITS, { variables: { ...filters }, fetchPolicy: "cache-and-network" });
  const [voidCredit] = useMutation(POST_CREDIT_VOID);

  const page = data?.credits;

  const handleReload = async () => {
    modalRef.current.close();
    history.push("/creditnotes");
    refetch();
  };

  useEffect(() => {
    if (modalRef?.current && searchQuery.orderId) modalRef.current.open();
  }, [modalRef, searchQuery]);

  const handleCapsuleSelect = async (value: any, name: string) => {
    localStorage.setItem(`creditNotes-${name}`, value);
    currentUri.setSearch({ [name]: value }).removeSearch("page");
    history.push(currentUri.resource());
  };

  const handleVoid = async (creditNote: Credit) => {
    try {
      await voidCredit({ variables: { creditRef: creditNote._id } });
      addNotification({ ok: 1, message: t("Credit note updated") });
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    } finally {
      refetch();
    }
  };

  if (loading) return <Loader />;

  return (
    <div id="creditNotes">
      <Modal style={{}} ref={modalRef}>
        <div id="creditNoteCreate">
          <CreateCreditNote
            onSubmitted={handleReload}
            searchQuery={searchQuery}
            addNotification={addNotification}
            config={config as Config}
          />
        </div>
      </Modal>

      {!noHeader ? (
        <>
          <SectionContainer>
            <SectionHeader title={t("Credit notes")}>
              <div />
              <RightSection>
                <ButtonV2 variant="primary" type="button" onClick={() => modalRef.current.open()}>
                  {t("Add a credit note")}
                </ButtonV2>
              </RightSection>
            </SectionHeader>
          </SectionContainer>
          <Zone className="filters">
            <div id="status" className="column-item">
              <p>{t("Status")}</p>
              <CapsuleGroup
                variant="overZone"
                name="status"
                value={filters.status}
                onClick={handleCapsuleSelect}
                entries={[
                  { label: t("All"), value: "All" },
                  { label: t("Active"), value: "active" },
                  { label: t("Void"), value: "void" }
                ]}
              />
            </div>
            <div id="sort" className="column-item">
              <p>{t("Sort by")}</p>
              <CapsuleGroup
                variant="overZone"
                name="sortBy"
                value={filters.sortBy}
                onClick={handleCapsuleSelect}
                entries={[
                  { label: t("Total"), value: "totals.grand" },
                  { label: t("Created"), value: "createdAt" }
                ]}
              />
            </div>
            <div id="order" className="column-item">
              <p>{t("Order by")}</p>
              <CapsuleGroup
                variant="overZone"
                name="sortOrder"
                value={filters.sortOrder}
                onClick={handleCapsuleSelect}
                entries={[
                  { label: t("Ascending"), value: -1 },
                  { label: t("Descending"), value: 1 }
                ]}
              />
            </div>
          </Zone>
        </>
      ) : null}
      {page ? (
        <>
          <Pagination pagination={page.pagination} currentUri={currentUri} />
          <section className="table">
            <div className="entry header">
              <p>
                {"# "}
                {t("Title")}
              </p>
              <p className="created">{t("Created")}</p>
              <p className="value">{t("Total")}</p>
              <p>{t("Items")}</p>
              <p>{t("User")}</p>
              <p>{t("Spendings")}</p>
              <p>{t("Action")}</p>
            </div>
            {page.credits && page.credits.length === 0 ? (
              <div className="getStarted">
                <h3>{t("Get started by adding a credit note above")}</h3>
              </div>
            ) : null}
            {page.credits.map((c, index) => (
              <Liner key={c._id} index={index} className={`entry ${c.status}`}>
                <p>
                  {t("Status")}: {c.status}
                  <br />#{c.id} - {c.title}
                  <br />
                  {c.reference}
                </p>
                <p className="created">{moment(c.createdAt).format("ll")}</p>
                <p className="value">
                  <Price value={c.totals.grand} />
                  {" / "}
                  <Price value={c.totals.balance as number} />
                </p>
                <div className="items">
                  {c.items.map((i, idx) => (
                    <div key={idx} className="entry">
                      <Link to={`/item/${i.item.id}`}>
                        {i.quantity}
                        {"x "}
                        <Price value={i.price.base} /> - {i.item.description}
                      </Link>
                    </div>
                  ))}
                </div>
                <p>{c.user ? <Link to={`/user/${c.user._id}`}>{c.user.name}</Link> : ""}</p>
                <div className="metadata">
                  {c.spendings
                    ? c.spendings.map((m, i) => (
                        <div key={i} className="meta">
                          {m.orderId ? (
                            <Link to={`/order/${m.orderId}`}>
                              #{m.orderId} {"->"} <Price value={m.amount as number} />
                            </Link>
                          ) : (
                            <p>
                              {t("Spending")} {"->"} <Price value={m.amount as number} />
                            </p>
                          )}
                        </div>
                      ))
                    : null}
                </div>
                <div>
                  <DetailsView addNotification={addNotification} config={config as Config} handleVoid={handleVoid} creditNote={c} />
                </div>
              </Liner>
            ))}
          </section>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};
