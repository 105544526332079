import { gql } from "../../__generated__/gql";

export const LIST_FIELDS = gql(`
  fragment ListFields on List {
    _id
    id
    title
    created
    description
    count
  }
`);

export const GET_LISTS = gql(`
  query lists {
    lists {
      lists {
        ...ListFields
      }
    }
  }
`);

export const POST_LIST_ADD = gql(`
  mutation listAddUsers($listRef: ID!, $userRefs: [ID!]!) {
    listAddUsers(listRef: $listRef, userRefs: $userRefs) {
      ...ListFields
    }
  }
`);

export const POST_LIST_REMOVE = gql(`
  mutation listRemoveUsers($listRef: ID!, $userRefs: [ID!]!) {
    listRemoveUsers(listRef: $listRef, userRefs: $userRefs) {
      ...ListFields
    }
  }
`);
export const POST_LIST_CREATE = gql(`
  mutation listCreate($title: String!) {
    listCreate(title: $title) {
      ...ListFields
    }
  }
`);

export const POST_LIST_DELETE = gql(`
  mutation listDelete($listRef: ID!) {
    listDelete(listRef: $listRef)
  }
`);
