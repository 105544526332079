import React from "react";
import { OrdersStatistics } from "../../../../__generated__/graphql";
import ExportButton from "../../../../pages/orders/export";
import { GlobalStore } from "../../../../stores/global";
import { Typography } from "../../../Typography";
import { StyledCopyContainer, StyledCopyWrapper, StyledMainWrapper } from "../../Inventory/InventoryStatistics/InventoryStatistics.styles";
import Price from "../../../../components/common/price";
import { Statistic } from "../../../Statistic";
import { IOrdersFilters } from "../../../../components/orders/orders";
import { useTranslation } from "react-i18next";

type Props = {
  stats?: OrdersStatistics | null;
  filters: IOrdersFilters;
};

export const OrderStatistics: React.FC<Props> = ({ stats, filters }) => {
  const { addNotification, isBelowIpad } = GlobalStore.useState(c => c);
  const { t } = useTranslation();

  return (
    <StyledMainWrapper style={{ marginBottom: "10px" }}>
      <StyledCopyWrapper>
        <Typography variant="subTitle" level="secondary" style={{ whiteSpace: "nowrap", fontWeight: 400, marginRight: "20px" }}>
          {t("Total")}
          <Typography variant="subTitle" style={{ marginLeft: "10px", fontWeight: 400 }}>
            <Price value={stats?.total?.toFixed(2) ?? 0} />
          </Typography>
        </Typography>
        <StyledCopyContainer>
          {stats && (
            <>
              <Statistic copy={`Order${(stats.count ?? 0) > 1 ? "s" : ""}`} count={stats.count ?? 0} />
            </>
          )}
        </StyledCopyContainer>
      </StyledCopyWrapper>
      {stats && !isBelowIpad && <ExportButton statistics={stats} addNotification={addNotification} filters={filters} />}
    </StyledMainWrapper>
  );
};
