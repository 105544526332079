import React from "react";
import Zone from "../../components/styled/zone";
import { Link } from "react-router-dom";
import Loader from "../../components/common/loader";
import Liner from "../../components/common/liner";
import { GET_NEWS_PREVIEW } from "../../graphql/queries/other";
import { useQuery } from "@apollo/client";
import moment from "moment";
import { useTranslation } from "react-i18next";

export default function NewsPanel() {
  const { loading, data } = useQuery(GET_NEWS_PREVIEW);
  const { t } = useTranslation();
  const campaigns = data && data.supportNews?.campaigns;

  if (loading || !campaigns) return <Loader />;

  return (
    <Zone id="newsPanel">
      <div className="header">
        <h3>{t("CG News")}</h3>
        <Link to="/news">{t("Read more")}</Link>
      </div>
      <div id="content">
        {campaigns.map((c, index) => (
          <Liner index={index} key={c.id} className="post">
            <Link className="title" to={`/news#${c.id}`}>
              {c.title.replace("News: ", "")}
            </Link>
            <p className="date">
              {" "}
              {moment(c.publication?.date).isAfter(moment().subtract(2, "month")) ? (
                <span className="new">{t("New Update")}</span>
              ) : null}{" "}
              {moment(c.publication?.date).fromNow()}
            </p>
          </Liner>
        ))}
      </div>
    </Zone>
  );
}
