import { Store } from "pullstate";
import {
  DashboardPanelBestSellers,
  DashboardPanelNewMessages,
  DashboardPanelOrigins,
  DashboardPanelPageViews,
  DashboardPanelRealTimeUsers,
  DashboardPanelRecentOrders,
  DashboardPanelSalesLocations,
  DashboardPanelSalesOverTime,
  DashboardPanelVisits
} from "../__generated__/graphql";

interface StoreTypes {
  pageViews?: DashboardPanelPageViews;
  salesLocations?: DashboardPanelSalesLocations;
  origins?: DashboardPanelOrigins;
  bestSellers?: DashboardPanelBestSellers;
  recentOrders?: DashboardPanelRecentOrders;
  salesOverTime?: DashboardPanelSalesOverTime;
  newMessages?: DashboardPanelNewMessages;
  visits?: DashboardPanelVisits;
  realTimeUsers?: DashboardPanelRealTimeUsers;
}

export const DashboardStore = new Store<StoreTypes>({
  pageViews: undefined,
  salesLocations: undefined,
  origins: undefined,
  bestSellers: undefined,
  recentOrders: undefined,
  salesOverTime: undefined,
  newMessages: undefined,
  visits: undefined,
  realTimeUsers: undefined
});
