import React, { useState, useEffect, useImperativeHandle, useRef, forwardRef } from "react";
import Button from "../styled/button";
import { Button as ButtonV2 } from "../../componentsV2/Button";
import Checkbox from "../styled/checkbox";
import Input from "../styled/input";
import TextArea from "../styled/textArea";
import { Select } from "../styled/select";
import { Link, Prompt, useHistory } from "react-router-dom";
// @ts-ignore
import { LazyLoadImage } from "react-lazy-load-image-component";
import { SelectTemplate, SelectCategories, SelectLocation, SelectSupplier } from "../common/select";
import { Options } from "../common/options";
import Loader from "../common/loader";
import { FaTimes, FaLock } from "react-icons/fa";
import { GoDotFill } from "react-icons/go";
import moment from "moment";
import { StatusSelect } from "../common/listingSelect";
import Price, { PriceInput } from "../common/price";
import DatePicker from "../styled/datePicker";
import { ListingHistory } from "../global/history";
import Modal, { PropsToForward } from "../modal";
import clone from "clone";
import MarketSuggestions from "../common/marketSuggestions";
import { GlobalStore } from "../../stores/global";
import { BulkStore } from "../../stores/bulk";
import { useMutation } from "@apollo/client";
import {
  POST_ITEM_LISTING_ADD,
  POST_ITEM_LISTING_DELETE,
  POST_ITEM_LISTING_UPDATE,
  POST_ITEM_LISTING_DISCOGS
} from "../../graphql/queries/item";
import TemplateEditor from "../templates/editor";
import * as amplitude from "@amplitude/analytics-browser";
import { Config, Item, Listing as IListing, Template, ItemListingInput } from "../../__generated__/graphql";
import { getPlaceholderImage } from "../../utils";
import { ItemEditable } from "../items/edit.release";
import { useTranslation } from "react-i18next";
import { colorsAsRgbString } from "@common-ground-io/colors";

export interface ListingProps {
  overZone: boolean;
  listing: IListing;
  listingDefault?: IListing;
  config: Config;
  item: Item | ItemEditable;
  index?: number;
  edit: boolean;
  onSubmit?: any;
  onDelete?: any;
  hideSubmit?: any;
  noBulk?: boolean;
  ref?: any;
  new?: boolean;
  onCancel?: () => void;
}

export interface ListingPropsToForward {
  getListing: () => ItemListingInput;
}

const Listing = forwardRef<ListingPropsToForward, ListingProps>((props: ListingProps, ref: any) => {
  const { addNotification, isMobile, stickers } = GlobalStore.useState(c => c);
  const inventoryBulkStore = BulkStore.useState(c => c.inventory);
  const [listing, setListing] = useState({ ...clone(props.listing) });
  const { config, listingDefault, onCancel } = props;
  const isNew = !props.listing?._id;
  const [isUpdating, setIsUpdating] = useState(false);
  const [edit, setEdit] = useState<boolean>(props.edit || false);
  const [isUpdatingDiscogs, setIsUpdatingDiscogs] = useState(false);
  const { item, onSubmit } = props;
  const [dirty, setDirty] = useState(false);
  const modalRef = useRef<PropsToForward>(null);
  const [addListing] = useMutation(POST_ITEM_LISTING_ADD);
  const [updateListing] = useMutation(POST_ITEM_LISTING_UPDATE);
  const [listOrUnlistToDiscogs] = useMutation(POST_ITEM_LISTING_DISCOGS);
  const [deleteListing] = useMutation(POST_ITEM_LISTING_DELETE);
  const { t } = useTranslation();

  const history = useHistory();

  useEffect(() => {
    setListing(props.listing);
  }, [props.listing]);

  const handleEditChange = (state: boolean) => {
    if (dirty && !state && window.confirm(t("Discard changes?"))) {
      if (listingDefault) {
        setListing(clone(listingDefault));
        setDirty(false);
      }
      setEdit(state);
    } else setEdit(state);
  };

  const handleAvailableChange = (e: any) => {
    setDirty(true);
    if (e === null) listing.available = null;
    else if (!moment(e).isValid()) return;
    else listing.available = moment(e).format();
    setListing({ ...listing });
  };

  const handlePublishedChange = (e: any) => {
    setDirty(true);
    if (!moment(e).isValid()) return;
    listing.posted = moment(e).format();
    setListing({ ...listing });
  };

  useImperativeHandle(ref, () => ({
    getListing: () => {
      return listing;
    }
  }));

  const handleCreatableSelect = (option: any, event: any) => {
    setDirty(true);
    if (event.action === "clear") {
      // @ts-ignore
      listing[event.name] = null;
      localStorage.removeItem(`${config.id}-add-listing-${event.name}`);
    } else if (event.action === "select-option") {
      localStorage.setItem(`${config.id}-add-listing-${event.name}`, option.value);
      // @ts-ignore
      listing[event.name] = option.value;
    }
    setListing({ ...listing });
  };

  const handleCategoriesSelect = (options: any, event: any) => {
    setDirty(true);
    if (event.action === "clear") {
      listing.categories = [];
      localStorage.removeItem(`${config.id}-add-listing-categories`);
    } else if (event.action === "remove-value") {
      localStorage.removeItem(`${config.id}-add-listing-categories`);
      listing.categories = listing.categories.filter(c => c !== event.removedValue.value);
    } else if (event.action === "select-option") {
      const mappedOptions = options.map((i: any) => i.value);
      localStorage.setItem(`${config.id}-add-listing-categories`, JSON.stringify(mappedOptions));
      // @ts-ignore
      listing[event.name] = mappedOptions;
    }
    setListing({ ...listing });
  };

  const handleSupplierCreate = (value: string) => {
    setDirty(true);
    listing.supplierCode = value;
    setListing({ ...listing });
  };

  const handleDeleteOption = (index: number) => {
    setDirty(true);
    listing.options.splice(index, 1);
    setListing({ ...listing });
  };

  const handleAutolistChange = (e: any) => {
    setDirty(true);
    listing.preventDiscogsListing = e.target.checked;
    setListing({ ...listing });
  };

  const handleOnePerCustomerChange = (e: any) => {
    setDirty(true);
    listing.onePerCustomer = e.target.checked;
    setListing({ ...listing });
  };

  const handlePreOrderChange = (e: any) => {
    setDirty(true);
    listing.preOrder = e.target.checked;
    setListing({ ...listing });
  };

  const handleLocationCreate = (value: string) => {
    setDirty(true);
    listing.location = value;
    setListing({ ...listing });
  };

  const handleCategoryCreate = (value: string) => {
    setDirty(true);
    listing.categories.push(value);
    setListing({ ...listing });
  };

  const handlePriceChange = (event: any) => {
    setDirty(true);
    // @ts-ignore
    if (listing.prices) listing.prices[event.target.name] = event.target.valueAsNumber;
    localStorage.setItem(`${config.id}-add-listing-${event.target.name}`, event.target.value);
    setListing({ ...listing });
  };

  const handlePriceSuggestionSelect = ({ price, condition }: { price: number; condition: string }) => {
    setDirty(true);
    if (listing.prices) listing.prices.sale = price;
    const option = listing.options.find(o => o.name === "Media Condition");
    if (option && condition) option.value = condition;
    localStorage.setItem(`${config.id}-add-listing-sale`, String(price));
    setListing({ ...listing });
  };

  const handleStockChange = (event: any) => {
    setDirty(true);
    if (listing.stock) listing.stock.quantity = event.target?.value;
    setListing({ ...listing });
  };

  const handleStatusChange = (option: { value: string }) => {
    setDirty(true);
    listing.status = option.value;
    localStorage.setItem(`${config.id}-add-listing-status`, option.value);
    setListing({ ...listing });
  };

  const handleCommentsUpdate = (name: string, value: any) => {
    setDirty(true);
    // @ts-ignore
    listing[name] = value;
    localStorage.setItem(`${config.id}-add-listing-${name}`, value);
    setListing({ ...listing });
  };

  const handleSaveListing = async (e: any) => {
    e.preventDefault();
    setIsUpdating(true);
    const listingData = listing;
    if (listingData?.stock?.quantity === null || listingData?.stock?.quantity === undefined || listingData?.stock?.quantity === "")
      return addNotification({ message: "Stock quantity cannot be null", ok: 0 });

    setDirty(false);

    const isNew = !listing._id;
    const listingDataForGraphql = {
      stock: { quantity: parseInt(listingData?.stock?.quantity) },
      status: listing.status,
      available: listing.available ? moment(listing.available).format() : moment().format(),
      posted: listing.posted ? moment(listing.posted).format() : moment().format(),
      secondHand: listing.secondHand,
      location: listing.location,
      supplierCode: listing.supplierCode,
      categories: listing.categories,
      comments: listing.comments,
      taxDefinition: listing.taxDefinition,
      options: listing.options.map(o => ({ name: o.name, value: o.value })),
      prices: {
        sale: listing?.prices?.sale,
        cost: listing?.prices?.cost,
        beforeTaxes: listing?.prices?.beforeTaxes,
        compare: listing?.prices?.compare
      },
      barcode: listing.barcode,
      sku: listing.sku,
      privateComments: listing.privateComments,
      onePerCustomer: listing.onePerCustomer,
      preventDiscogsListing: listing.preventDiscogsListing,
      preOrder: listing.preOrder
    };

    try {
      if (isNew && item._id) {
        await addListing({ variables: { itemRef: item._id, itemListingInput: listingDataForGraphql } });
        if (onSubmit) onSubmit();
        addNotification({ ok: 1, message: "Listing added" });
        amplitude.track("Listing added", { itemId: item.id });
      } else {
        await updateListing({ variables: { listingRef: listing._id, itemListingInput: listingDataForGraphql } });
        amplitude.track("Listing updated", { itemId: item.id });
        addNotification({ ok: 1, message: "Listing updated" });
      }
    } catch (error: any) {
      addNotification({ ok: 0, message: error.message });
    }

    setEdit(false);
    setIsUpdating(false);
  };

  const handleListingDelete = async () => {
    try {
      await deleteListing({ variables: { listingRef: listing._id } });
      addNotification({ ok: 1, message: "Listing deleted" });
      if (props.onDelete) props.onDelete();
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
  };

  const handleDiscogsList = async () => {
    setIsUpdatingDiscogs(true);
    try {
      await listOrUnlistToDiscogs({ variables: { listingRef: listing._id, list: true } });
      addNotification({ message: "Listed to Discogs", ok: 1 });
    } catch (e: any) {
      addNotification({ message: e.message, ok: 0 });
    }
    setIsUpdatingDiscogs(false);
  };

  const handleDiscogsUnlist = async () => {
    setIsUpdatingDiscogs(true);
    try {
      await listOrUnlistToDiscogs({ variables: { listingRef: listing._id, unlist: true } });
      addNotification({ message: "Unlisted from Discogs", ok: 1 });
    } catch (e: any) {
      addNotification({ message: e.message, ok: 0 });
    }
    setIsUpdatingDiscogs(false);
  };

  const handleTemplateSelect = (template: Template) => {
    setDirty(true);
    listing.comments = template.data.content;
    localStorage.setItem(`${config.id}-add-listing-comments`, template.data.content || "");
    setListing({ ...listing });
  };

  const handleOptionOnChange = (option: any, event: any, index: number) => {
    setDirty(true);
    const storageStr = `${config.id}-add-listing-options-${item.type}-${index}-${event.name}`;
    if (event.action === "clear") {
      localStorage.removeItem(storageStr);
    } else if (event.action === "select-option") {
      // @ts-ignore
      listing.options[index][event.name] = option.value;
      localStorage.setItem(storageStr, option.value);
    }
    setListing({ ...listing });
  };

  const handleOptionCreate = (value: any, name: string, index: number) => {
    setDirty(true);
    // @ts-ignore
    listing.options[index][name] = value;
    setListing({ ...listing });
  };

  const handleAddAttribute = () => {
    setDirty(true);
    listing.options.push({ name: "", value: "" });
    setListing({ ...listing });
  };

  const handleSkuOrBarcodeChange = (e: any) => {
    setDirty(true);
    // @ts-ignore
    listing[e.target.name] = e.target.value;
    setListing({ ...listing });
  };

  const handleSecondHandChange = (value: any) => {
    setDirty(true);
    listing.secondHand = value.target.checked || false;
    localStorage.setItem(`${config.id}-add-listing-secondHand`, JSON.stringify(listing.secondHand));
    setListing({ ...listing });
  };

  const isInPrintQueue = stickers.entries.find(e => e.listing.id === listing.id);

  const handlePrint = () => {
    const isLegacyPrinting = localStorage.getItem(`${config.id}-legacyPrinting`) === "true";
    const path = `/stickers?id=${listing._id}`;

    if (isLegacyPrinting) history.push(path);
    else if (item._id) {
      if (isInPrintQueue) {
        GlobalStore.update(s => {
          const index = s.stickers.entries.findIndex(e => e.listing.id === listing.id);
          s.stickers.entries.splice(index, 1);
        });
        addNotification({
          ok: 1,
          message: (
            <p>
              {t("Listing removed from print queue")} <br />
              <br /> <Link to="/stickers">{t("Print now")}</Link>
            </p>
          )
        });
      } else {
        GlobalStore.update(s => {
          s.stickers.entries.unshift({ listing: clone(listing), item: item as Item });
        });
        addNotification({
          ok: 1,
          message: (
            <p>
              {t("Listing added to print queue")} <br />
              <br /> <Link to="/stickers">{t("Print now")}</Link>
            </p>
          )
        });
      }
    }
  };

  const couldBeListedToDiscogs = item.type === "ReleaseItem" && item?.data?.discogsId && item.data.discogsId > 0;
  const preTaxes = config?.taxes?.rules?.editPricesBeforeTaxes;
  const overZone = props.overZone;
  const selected = !!inventoryBulkStore.listings.find(l => l === listing._id);
  const priceToDisplay = preTaxes ? listing?.prices?.beforeTaxes : listing?.prices?.sale;

  return (
    <div className={`listing ${listing.readOnly ? "readOnly" : ""} ${selected ? "selected" : ""}`} ref={ref}>
      <Modal style={{}} ref={modalRef}>
        <div className="listingHistory">
          <div className="header">
            <h2>{t("Listing logs")}</h2>
            <Button variant="noStyle" onClick={() => modalRef.current?.close()} type="button">
              <FaTimes />
            </Button>
          </div>
          <ListingHistory listing={listing} />
        </div>
      </Modal>
      <Prompt when={dirty} message={() => t("Your changes will be lost, are you sure you want to leave this page ?")} />
      {!isNew ? (
        <div className="listingHeader">
          <div className="bulkAndIndicator">
            {listing.readOnly ? (
              <Loader styles={{}} />
            ) : (
              <>
                <div className={`indicator ${listing?.stock?.quantity <= 0 ? "soldOut" : "inStock"}`}>
                  <GoDotFill />
                </div>
                {!props.noBulk && item.type ? <BulkBox item={item as Item} listing={listing} /> : null}
              </>
            )}
          </div>
          <div className="image">
            <Link to={item.path || ""}>
              <LazyLoadImage
                alt={item?.descriptions?.main}
                src={(item?.data?.images?.length ? item?.data?.images[0]?.uri : null) || getPlaceholderImage(config)}
              />
            </Link>
          </div>
          <div className="description">
            <div className="priceAndLogs">
              <p>
                {listing.status === "private" ? (
                  <span className="lock">
                    <FaLock />
                  </span>
                ) : null}
                {/* eslint-disable-next-line i18next/no-literal-string */}
                <span className="title">{listing?.stock?.quantity}x </span>
                {listing?.prices?.compare ? (
                  <>
                    <span className="compared">
                      <Price value={listing.prices.compare} />
                    </span>{" "}
                    <Price value={priceToDisplay as number} />
                  </>
                ) : (
                  <>
                    <Price value={priceToDisplay as number} />
                  </>
                )}
              </p>
            </div>
            <p>
              <span className="title">{listing.preOrder ? t("Pre order") + ":" : t("Available") + ":"} </span>
              {listing.available ? moment(listing.available).format("ll") : t("Date unknown")}
            </p>
            {listing.location ? (
              <p>
                <span className="title">{t("Location")}:</span> <span className="capsule">{listing.location}</span>
              </p>
            ) : null}
            {listing.supplierCode ? (
              <p>
                <span className="title">{t("Supplier")}:</span> <span className="capsule">{listing.supplierCode}</span>
              </p>
            ) : null}
            {listing.categories && listing.categories.length ? (
              <p>
                <span className="title">{t("Categories")}:</span> <span className="capsule">{listing.categories.join(" / ")}</span>
              </p>
            ) : null}
            {listing.secondHand ? (
              <p>
                <span className="title">{t("Second hand")}</span>
              </p>
            ) : null}
            {listing.onePerCustomer ? (
              <p>
                <span className="title">{t("One per customer")}</span>
              </p>
            ) : null}
          </div>
          <div>
            {listing.options.length ? (
              <div className="options">
                {listing.options
                  .filter(o => o.name && o.value)
                  .map(o => (
                    <p key={o.name}>
                      {" "}
                      <span className="title">{o.name}: </span>
                      {o.value}{" "}
                    </p>
                  ))}
              </div>
            ) : null}
          </div>
          <div className="headerRight">
            <div className="buttons">
              <div className="discogs">
                {listing.discogsId ? (
                  <>
                    <Button variant="danger" onClick={handleDiscogsUnlist} disabled={listing.readOnly || isUpdatingDiscogs}>
                      {isUpdatingDiscogs ? <Loader /> : t("Unlist")}
                    </Button>
                    <p>
                      <a href={`https://discogs.com/buy/Vinyl/listing/${listing.discogsId}`} rel="noopener noreferrer" target="_blank">
                        #{listing.discogsId}
                      </a>
                    </p>
                  </>
                ) : null}
                {couldBeListedToDiscogs ? (
                  <>
                    {listing?.stock?.quantity > 0 && !listing.discogsId ? (
                      <Button
                        variant={`secondary${overZone ? "OverZone" : ""}`}
                        disabled={listing.readOnly || !config.discogs.enabled || isUpdatingDiscogs}
                        title={t("List to Discogs")}
                        onClick={handleDiscogsList}>
                        {isUpdatingDiscogs ? <Loader /> : t("List to Discogs")}
                      </Button>
                    ) : null}
                    {listing.preventDiscogsListing ? <p>{t("Auto-list disabled")}</p> : null}
                  </>
                ) : null}
              </div>
              <div className="history">
                <Button variant={`secondary${overZone ? "OverZone" : ""}`} onClick={() => modalRef.current?.open()}>
                  {t("Logs")}
                </Button>
              </div>
              {!isMobile ? (
                <span>
                  <Button
                    variant={isInPrintQueue ? "info" : `secondary${overZone ? "OverZone" : ""}`}
                    type="button"
                    onClick={handlePrint}
                    disabled={!!listing.readOnly}
                    className="print">
                    {isInPrintQueue ? t("Queued") : t("Print")}
                  </Button>
                </span>
              ) : null}
              <div>
                <ButtonV2
                  styleProps={{ background: colorsAsRgbString.primary, padding: "4px 10px" }}
                  variant="primary"
                  disabled={!!listing.readOnly}
                  onClick={() => handleEditChange(!edit)}>
                  {edit ? t("Close") : t("Edit")}
                  {dirty ? "*" : ""}
                </ButtonV2>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {edit ? (
        <form onSubmit={handleSaveListing} className={`listingContent ${edit ? "show" : "hide"}`}>
          <div id="columns">
            <div className="conditionsAndDate">
              <StatusSelect overZone={overZone} onChange={handleStatusChange} value={listing.status} />
              <Options
                overZone={overZone}
                onChange={handleOptionOnChange}
                onCreate={handleOptionCreate}
                onAdd={handleAddAttribute}
                options={listing.options}
                item={item}
                deleteOption={handleDeleteOption}
              />
              <Checkbox checked={listing.secondHand || false} onChange={handleSecondHandChange} label={t("Second hand listing")} />
            </div>
            <div className="priceAndTax">
              <div className="price">
                {item?.data?.discogsId && !preTaxes ? (
                  <div className="priceHeader">
                    <MarketSuggestions
                      listing={listing}
                      discogsId={item.data.discogsId}
                      handlePriceSuggestionSelect={handlePriceSuggestionSelect}
                      config={config}
                    />
                  </div>
                ) : null}
                {preTaxes ? (
                  <PriceInput
                    label={t("Price before sales tax")}
                    variant={overZone ? "overZone" : ""}
                    value={listing.prices?.beforeTaxes as number}
                    name="beforeTaxes"
                    type="number"
                    step="0.01"
                    fullWidth
                    min="0.00"
                    placeholder={t("Enter a value") + "..."}
                    required={true}
                    onChange={handlePriceChange}
                  />
                ) : (
                  <>
                    <PriceInput
                      label={t("Price incl. sales tax")}
                      variant={overZone ? "overZone" : ""}
                      value={listing.prices?.sale as number}
                      name="sale"
                      type="number"
                      step="0.01"
                      fullWidth
                      min="0.00"
                      placeholder={t("Enter a value") + "..."}
                      required={true}
                      onChange={handlePriceChange}
                    />
                    {config.taxes?.rules?.collectTaxes && listing.prices?.beforeTaxes ? (
                      <small>
                        {t("Price before sales tax")}: <Price value={listing.prices?.beforeTaxes} />
                      </small>
                    ) : null}
                  </>
                )}
              </div>
              <div className="price">
                <PriceInput
                  label={t("Compare at price")}
                  variant={overZone ? "overZone" : ""}
                  value={listing.prices?.compare as number}
                  name="compare"
                  type="number"
                  step="0.01"
                  fullWidth
                  min="0.00"
                  onChange={handlePriceChange}
                />
              </div>
              <PriceInput
                label={t("Item cost / Supplier price")}
                variant={overZone ? "overZone" : ""}
                value={listing.prices?.cost as number}
                name="cost"
                step="0.01"
                fullWidth
                min="0.00"
                type="number"
                onChange={handlePriceChange}
              />
              <Select
                label={t("National tax rate override")}
                variant={overZone && "overZone"}
                onChange={handleCreatableSelect}
                value={listing.taxDefinition ? { label: listing.taxDefinition, value: listing.taxDefinition } : null}
                options={[
                  { label: t("Default"), value: "" },
                  ...(config?.taxes?.definitions || []).filter(t => !t?.default).map(t => ({ label: t?.name, value: t?.name }))
                ]}
                name="taxDefinition"
              />
              <div>
                <small>
                  {listing?.prices?.cost && priceToDisplay && !isNaN(listing.prices.cost) && !isNaN(priceToDisplay) ? (
                    <>
                      {t("Price difference")}: <Price value={priceToDisplay - listing.prices.cost} /> |{" "}
                      {/* eslint-disable-next-line i18next/no-literal-string */}
                      {Math.abs(((listing.prices.cost - priceToDisplay) / listing.prices.cost) * 100).toFixed(2)}% | x
                      {(priceToDisplay / listing.prices.cost).toFixed(2)}
                    </>
                  ) : (
                    t("Enter cost and sale prices in order to compute the margin")
                  )}
                </small>
              </div>
            </div>
            <div className="availableAndCategory">
              <div>
                <DatePicker
                  label={t("Available from")}
                  dateFormat="dd/MM/yyyy"
                  variant={overZone && "overZone"}
                  value={listing.available}
                  onChange={handleAvailableChange}
                  className="available datePicker"
                />
                <Checkbox label={t("Set as pre order")} onChange={handlePreOrderChange} checked={listing.preOrder || false} />
              </div>
              <DatePicker
                label={t("Published date")}
                variant={overZone && "overZone"}
                value={listing.posted || moment()}
                dateFormat="dd/MM/yyyy"
                onChange={handlePublishedChange}
                className="available datePicker"
              />
              <SelectCategories
                label={t("Categories")}
                variant={overZone && "overZone"}
                isMulti
                creatable={true}
                onCreateOption={handleCategoryCreate}
                onChange={handleCategoriesSelect}
                value={listing.categories?.map(c => ({ label: c, value: c }))}
                name="categories"
              />
              {couldBeListedToDiscogs ? (
                <Checkbox label={t("Disable Discogs auto-list")} onChange={handleAutolistChange} checked={listing.preventDiscogsListing} />
              ) : null}
              <Checkbox label={t("One per customer")} onChange={handleOnePerCustomerChange} checked={listing.onePerCustomer} />
            </div>
            <div className="stockLocationSupplier">
              <Input
                label={t("Stock quantity")}
                variant={overZone && "overZone"}
                required={true}
                value={listing?.stock?.quantity}
                type="number"
                onWheel={(e: any) => e.target.blur()}
                placeholder="Available quantity"
                onChange={handleStockChange}
              />
              <SelectSupplier
                variant={overZone && "overZone"}
                label={t("Supplier")}
                onChange={handleCreatableSelect}
                onCreateOption={handleSupplierCreate}
                value={listing.supplierCode ? { label: listing.supplierCode, value: listing.supplierCode } : null}
                name="supplierCode"
              />
              <SelectLocation
                label={t("Location")}
                variant={overZone && "overZone"}
                onCreateOption={handleLocationCreate}
                onChange={handleCreatableSelect}
                value={listing.location ? { label: listing.location || "", value: listing.location || "" } : null}
                name="location"
              />
              <Input
                label={t("Barcode identifier")}
                variant={overZone && "overZone"}
                required={false}
                value={listing.barcode || ""}
                type="text"
                name="barcode"
                autoComplete="off"
                placeholder={t("Barcode identifier") + "..."}
                onChange={handleSkuOrBarcodeChange}
              />
              <Input
                label={t("SKU")}
                variant={overZone && "overZone"}
                required={false}
                value={listing.sku || ""}
                type="text"
                autoComplete="off"
                name="sku"
                placeholder={t("SKU") + "..."}
                onChange={handleSkuOrBarcodeChange}
              />
            </div>
            <div className="comments">
              <div className="public templateControls">
                <SelectTemplate
                  label={t("Public comments")}
                  variant={overZone && "overZone"}
                  placeholder={t("Select from template")}
                  type="comment"
                  onChange={handleTemplateSelect}
                />
                <TextArea
                  variant={overZone && "overZone"}
                  rows="5"
                  onChange={(e: any) => handleCommentsUpdate("comments", e.target.value)}
                  value={listing.comments || ""}
                />
                <TemplateEditor type="comment" />
              </div>
              <div className="private">
                <TextArea
                  variant={overZone && "overZone"}
                  label={t("Private comments")}
                  rows="4"
                  onChange={(e: any) => handleCommentsUpdate("privateComments", e.target.value)}
                  value={listing.privateComments || ""}
                />
              </div>
            </div>
          </div>
          {props.hideSubmit ? null : (
            <div className="submit">
              <Button
                type="button"
                variant="danger"
                onClick={() => {
                  if (
                    !isNew &&
                    window.confirm(
                      t(
                        "Are you sure you wish to delete this listing? The sales history for this listing will be lost and cannot be recovered."
                      )
                    )
                  )
                    handleListingDelete();
                  else if (isNew && onCancel) onCancel();
                }}>
                {isNew ? t("Cancel") : t("Delete listing")}
              </Button>
              <ButtonV2 type="submit" variant="primary" disabled={isUpdating || !!listing.readOnly || !dirty}>
                {isUpdating ? t("Updating") + "..." : listing.posted ? t("Update listing") + `${dirty ? "*" : ""}` : t("Submit listing")}
              </ButtonV2>
            </div>
          )}
        </form>
      ) : null}
    </div>
  );
});

export const BulkBox = ({ item, listing }: { item: Item; listing: IListing }) => {
  const inventoryBulkStore = BulkStore.useState(c => c.inventory);

  const index = inventoryBulkStore.listings.findIndex(l => l === listing._id);

  const handleSelect = (e: any) => {
    if (listing.readOnly) return;
    if (e.target.checked) {
      if (index !== -1) return;
      BulkStore.update(s => {
        s.inventory.listings.push(listing._id);
        s.inventory.entries.push({ item, listing });
      });
    } else {
      if (index === -1) return;
      BulkStore.update(s => {
        s.inventory.listings.splice(index, 1);
        s.inventory.entries.splice(index, 1);
      });
    }
  };

  return (
    <div className="bulkBox">
      <Checkbox type="checkbox" checked={index > -1} onChange={handleSelect} />
    </div>
  );
};

export default Listing;
