import { gql } from "../../__generated__/gql";

gql(`
  fragment DashboardFields on Dashboard {
    panels {
    origins {
      data {
        labels
        datasets
      }
    }
    visits {
      data {
        periods {
          id
          title
          count
          start
        }
      }
    }
    salesLocations {
      data {
        alpha2
        count
      }
    }
    realTimeUsers {
      data {
        count
      }
    }
    salesOverTime {
      data {
        labels
        datasets
      }
      settings {
        months
        days
      }
    }
    pageViews {
      data {
        labels
        datasets
      }
      settings {
        start
      }
    }
    recentOrders {
      id
      settings {
        origin
      }
      data {
        weeklySales
        orders {
          id
          incId
          status
          origin
          created
          buyer {
            _id
            firstName
            lastName
            email
            name
          }
          totals {
            grand
          }
        }
      }
    }
    newMessages {
      id
      settings {
        amount
      }
      data {
        messages {
          created
          message
          orderIncId
          sender {
            name
            email
          }
          read
          created
        }
      }
    }
    bestSellers {
      id
      settings {
        type
      }
      items {
        id
        path
        data {
          images {
            uri
          }
        }
      }
    }
  }
  }
`);

export const POST_DASHBOARD_UPDATE = gql(`
  mutation dashboardUpdate($panel: DashboardUpdatePanelInput!) {
    dashboard: dashboardUpdate(panel: $panel) {
      ...DashboardFields
    }
  }
`);

export const GET_DASHBOARD = gql(`
  query dashboard($panels: [String]!) {
    dashboard(panels: $panels) {
      ...DashboardFields
    }
  }
`);
