import React from "react";
import styled from "styled-components";

import colors from "@common-ground-io/common-assets/assets/colors.json";
import { useTranslation } from "react-i18next";

const inputLayout = {
  light: {
    primary: {
      fontColorLabel: colors.grey,
      bcgColorInput: colors.greyLightest,
      fontColorInput: colors.greyDarker,
      fontColorInputReadonly: colors.greyLight,
      fontColorPlaceholder: colors.grey
    },
    overZone: {
      fontColorLabel: colors.grey,
      bcgColorInput: colors.white,
      fontColorInput: colors.greyDarker,
      fontColorInputReadonly: colors.greyLight,
      fontColorPlaceholder: colors.grey
    }
  },
  dark: {
    primary: {
      fontColorLabel: colors.grey,
      bcgColorInput: colors.greyDarker,
      fontColorInput: colors.greyLighter,
      fontColorInputReadonly: colors.grey,
      fontColorPlaceholder: colors.greyLight
    },
    overZone: {
      fontColorLabel: colors.grey,
      bcgColorInput: colors.greyDark,
      fontColorInput: colors.greyLighter,
      fontColorInputReadonly: colors.grey,
      fontColorPlaceholder: colors.greyLight
    }
  }
};

const buttonLayout = {
  light: {
    primary: {
      bcgColor: colors.greyLightest,
      fontColor: colors.grey,
      borderColorHover: colors.grey,
      fontColorHover: colors.grey,
      bcgColorActive: colors.grey,
      borderColorActive: "transparent",
      fontColorActive: colors.greyLightest,
      separatorColor: colors.greyLighter
    },
    overZone: {
      bcgColor: colors.white,
      fontColor: colors.grey,
      borderColorHover: colors.grey,
      fontColorHover: colors.grey,
      bcgColorActive: colors.grey,
      borderColorActive: colors.grey,
      fontColorActive: colors.white,
      separatorColor: colors.greyLighter
    }
  },
  dark: {
    primary: {
      bcgColor: colors.greyDarker,
      fontColor: colors.greyLight,
      borderColorHover: colors.greyLight,
      fontColorHover: colors.greyLight,
      bcgColorActive: colors.greyLight,
      borderColorActive: "transparent",
      fontColorActive: colors.greyDark,
      separatorColor: colors.greyDark
    },
    overZone: {
      bcgColor: colors.greyDark,
      fontColor: colors.greyLight,
      borderColorHover: colors.greyLight,
      fontColorHover: colors.greyLight,
      bcgColorActive: colors.greyLight,
      borderColorActive: "transparent",
      fontColorActive: colors.greyDark,
      separatorColor: colors.grey
    }
  }
};

const Styledlabel = styled.label<{ variant?: string }>`
  font-style: normal;
  font-weight: 600;
  font-size: inherit;
  line-height: 1;

  color: ${props => path(inputLayout, props).fontColorLabel};
`;

const StyledInput = styled.input<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border: transparent;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  margin: 0;

  box-shadow: none;
  outline: none;

  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  line-height: 1;

  width: ${({ fullWidth }) => fullWidth && "100%"};
  background-color: ${props => path(inputLayout, props).bcgColorInput};
  color: ${props => path(inputLayout, props).fontColorInput};

  &:read-only {
    color: ${props => path(inputLayout, props).fontColorInputReadonly};
  }
  ::placeholder {
    color: ${props => path(inputLayout, props).fontColorPlaceholder};
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }

  ::-webkit-calendar-picker-indicator {
    filter: ${({ theme }) =>
      theme.name === "light"
        ? "invert(23%) sepia(0%) saturate(1432%) hue-rotate(140deg) brightness(111%) contrast(87%);"
        : "invert(50%) sepia(0%) saturate(3%) hue-rotate(168deg) brightness(95%) contrast(84%);"};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
    transition-delay: 9999s;
  }
`;

const StyledButton = styled.button<{ fullWidth?: boolean; variant?: string }>`
  height: auto;
  padding: 5px 10px;
  border: 1px solid transparent;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  cursor: pointer;
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  font-style: normal;
  line-height: 1;
  text-align: "center";
  margin: 0;
  width: ${({ fullWidth }) => fullWidth && "100%"};
  background-color: ${props => path(buttonLayout, props).bcgColor};
  color: ${props => path(buttonLayout, props).fontColor};
  border-left: 1px solid ${props => path(buttonLayout, props).separatorColor};

  &:hover {
    border: 1px solid;
    border-color: ${props => path(buttonLayout, props).borderColorHover};
    color: ${props => path(buttonLayout, props).fontColorHover};
  }

  &:active {
    border: 1px solid;
    border-color: ${props => path(buttonLayout, props).borderColorActive};
    background-color: ${props => path(buttonLayout, props).bcgColorActive};
    color: ${props => path(buttonLayout, props).fontColorActive};
  }
`;

const StyledContainer = styled.div`
  display: flex;
  margin-top: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const computeVariant = (variant: any, layout: any) => (layout.light[variant] ? variant : "primary");
const path = (layout: any, props: any) => layout[props.theme.name || "light"][props.variant];

const InputWithSubmitComponent = (props: any) => {
  const variant = computeVariant(props.variant, inputLayout);
  const { t } = useTranslation();

  return (
    <Container className="cg-common cg-inputWithSubmit">
      {props.label && (
        <Styledlabel
          htmlFor={props.id || props.name || ""}
          className={`cg-common ${variant} ${props.className || ""}-label`}
          variant={variant}>
          {props.label}
        </Styledlabel>
      )}
      <StyledContainer>
        <StyledInput
          {...props}
          onWheel={(e: any) => (e.target as HTMLElement).blur()}
          className={`cg-common cg-input ${variant} ${props.className || ""}`}
          variant={variant}
        />
        <StyledButton
          onClick={props.onSubmit || props.onClick}
          className={`cg-common cg-button ${variant} ${props.className || ""}`}
          variant={variant}
          type={props.buttonType || "submit"}>
          {props.submitText || t("Submit")}
        </StyledButton>
      </StyledContainer>
    </Container>
  );
};

export default InputWithSubmitComponent;
