import React, { useState, useEffect } from "react";
import { Link, useLocation, Redirect, useHistory } from "react-router-dom";
import CapsuleGroup from "../../../components/styled/capsule";
import Button from "../../../components/styled/button";
import { Button as ButtonV2 } from "../../Button";
import SearchBySubmit from "../../../components/styled/searchBySubmit";
import Zone from "../../../components/styled/zone";
import Loader from "../../../components/common/loader";
import DatePicker from "../../../components/styled/datePicker";
import { Listing } from "../../../componentsV2/Listing";
import { MediaConditions } from "../../../components/common/listingSelect";
import { InventoryPagination } from "../../../components/common/pagination";
import moment from "moment";
import URI from "urijs";
import clone from "clone";
import { SelectAsync, Select } from "../../../components/styled/select";
import { GlobalStore } from "../../../stores/global";
import { BulkStore } from "../../../stores/bulk";
import { useLazyQuery } from "@apollo/client";
import { GET_INVENTORY, GET_INVENTORY_METADTA, GET_ITEM_LABELS } from "../../../graphql/queries/item";
import { GET_JOBS } from "../../../graphql/queries/job";
import { JOB_STATUS } from "../../../config/jobs";
import { SelectCategories, SelectLocation, SelectSupplier } from "../../../components/common/select";
import { Config } from "../../../__generated__/graphql";
import { useTranslation } from "react-i18next";
import { SectionContainer } from "../../SectionContainer";
import { LeftSection, RightSection, SectionHeader } from "../../SectionHeader";
import { InventoryStatistics } from "./InventoryStatistics";
import { Typography } from "../../Typography";
import { ListingsWithItemHeader } from "../../Listing/Listing";

export default function Inventory() {
  const history = useHistory();
  const location = useLocation();
  const currentUri = new URI(location.pathname + location.search);
  const searchQuery = currentUri.search(true);
  const { config, configReload, addNotification, stickers, isMobile } = GlobalStore.useState(c => c);
  const { isRunning } = BulkStore.useState(c => c.inventory);
  const isLocationSearch = localStorage.getItem(config?.id + "-locationSearch") === "true" || false;
  const { t } = useTranslation();

  document.title = "Inventory";

  const filters = {
    stock: searchQuery.stock || "All",
    type: searchQuery.type || "All",
    status: searchQuery.status || "All",
    discogsStatus: searchQuery.discogsStatus || "All",
    hasSnippet: searchQuery.hasSnippet || "All",
    sort: searchQuery.sort || localStorage.getItem(config?.id + "-inventory-sort") || "created",
    dateFilter: searchQuery.dateFilter,
    order: searchQuery.order || localStorage.getItem(config?.id + "-inventory-order") || "-1",
    supplierCode: searchQuery.supplierCode,
    condition: Array.isArray(searchQuery.condition) ? searchQuery.condition : searchQuery.condition ? [searchQuery.condition] : null,
    secondHand: searchQuery.secondHand !== undefined ? searchQuery.secondHand === "true" : undefined,
    preOrder: searchQuery.preOrder !== undefined ? searchQuery.preOrder === "true" : undefined,
    location: searchQuery.location,
    locationSearch: searchQuery.locationSearch,
    categories: searchQuery.categories,
    labels: searchQuery.labels,
    itemId: searchQuery.itemId && parseInt(searchQuery.itemId),
    term: searchQuery.term,
    from: searchQuery.from || localStorage.getItem(config?.id + "-inventory-from") || "",
    to: searchQuery.to || localStorage.getItem(config?.id + "-inventory-to") || ""
  };

  const filterGraphQuery = (o: any) => Object.fromEntries(Object.entries(o).filter(([, v]) => v !== undefined && v !== "" && v !== null));
  const currentPage = parseInt(searchQuery.page) || 1;
  const graphQuery = { limit: 40, ...filters, page: currentPage };

  const [getPage, { data: inventoryData, error }] = useLazyQuery(GET_INVENTORY, {
    fetchPolicy: "cache-and-network",
    variables: filterGraphQuery(graphQuery)
  });
  const [getMetadata, { loading: metadataLoading, data: metadata }] = useLazyQuery(GET_INVENTORY_METADTA, {
    variables: { filters: filterGraphQuery({ ...filters, limit: graphQuery.limit }) }
  });

  const stats = metadata?.inventoryMetadata?.stats;

  const [getJobs] = useLazyQuery(GET_JOBS, { fetchPolicy: "cache-and-network" });
  const [getLabels] = useLazyQuery(GET_ITEM_LABELS, { fetchPolicy: "cache-and-network" });

  useEffect(() => {
    if (error) addNotification({ ok: 0, message: error.message });
  }, [error]);

  const page = (inventoryData && inventoryData.inventory) || {
    entries: undefined,
    pagination: { page: parseInt(searchQuery.page) || 1, pages: 1 }
  };

  const pagination = {
    page: graphQuery.page,
    pages: page?.pagination?.pages
  };

  const hasDiscogsToken = config?.discogs.enabled;

  const [redirect, setRedirect] = useState<string | null>(null);

  const handleFromToChange = async (value: string | null, type: string) => {
    if (value === null) {
      // @ts-ignore
      filters[type] = "";
      currentUri.removeSearch(type);
      history.push(currentUri.resource());
      localStorage.setItem(`${config?.id}-inventory-${type}`, "");
    } else {
      const parsedDate = moment(value).format();
      // @ts-ignore
      filters[type] = parsedDate;
      currentUri.setSearch({ [type]: parsedDate }).removeSearch("page");
      history.push(currentUri.resource());
      localStorage.setItem(`${config?.id}-inventory-${type}`, parsedDate);
    }
  };

  useEffect(() => {
    setRedirect(null);
    getPage().then(() => getMetadata());
  }, []);

  useEffect(() => {
    if (isRunning) {
      getPage();
      const timerId = setInterval(() => {
        getJobs({ variables: { taskName: "items:edit:bulk", statuses: [JOB_STATUS.PENDING, JOB_STATUS.PROCESSING] } }).then(({ data }) => {
          if (data?.jobs.pagination.count === 0) {
            getPage();
            addNotification({ ok: 1, message: "Your bulk edit was processed" });
            configReload();
            clearInterval(timerId);
            BulkStore.update(s => {
              s.inventory = { listings: [], isRunning: false, entries: [] };
            });
          }
        });
      }, 5000);
    } else if (isRunning === false) getPage();
  }, [isRunning]);

  const handleCapsuleSelect = async (value: string, name: string) => {
    currentUri.setSearch({ [name]: value }).removeSearch("page");
    history.push(currentUri.resource());
    localStorage.setItem(config?.id + `-inventory-${name}`, value);
  };

  const handleDropdownChange = async (values: any, event: any, name: string) => {
    if (values) currentUri.setSearch({ [name]: values.map((v: any) => v.value) }).removeSearch("page");
    else currentUri.removeSearch(name);
    history.push(currentUri.resource());
  };

  const handleLocationSearch = async (term: string) => {
    if (term) currentUri.setSearch({ locationSearch: term }).removeSearch("page");
    else currentUri.removeSearch("locationSearch");
    history.push(currentUri.resource());
  };

  const handleSearchSubmit = async (term: string) => {
    currentUri.setSearch({ term }).removeSearch("page").removeSearch("itemId");
    history.push(currentUri.resource());
  };

  const handleRemoveSearchFilter = async () => {
    currentUri.removeSearch("term").removeSearch("itemId").removeSearch("page");
    history.push(currentUri.resource());
  };

  const handleRemoveLocationSearchFilter = async () => {
    currentUri.removeSearch("locationSearch").removeSearch("page");
    history.push(currentUri.resource());
  };

  const handleSecondHandChange = async (e: any) => {
    const checked = e.value;
    if (checked === null) currentUri.removeSearch("secondHand").removeSearch("page");
    else if (checked) currentUri.setSearch("secondHand", true).removeSearch("page");
    else if (!checked) currentUri.setSearch("secondHand", false).removeSearch("page");
    history.push(currentUri.resource());
  };

  const handlePreOrderChange = async (e: any) => {
    const checked = e.value;
    if (checked === null) currentUri.removeSearch("preOrder").removeSearch("page");
    else if (checked) currentUri.setSearch("preOrder", true).removeSearch("page");
    else if (!checked) currentUri.setSearch("preOrder", false).removeSearch("page");
    history.push(currentUri.resource());
  };

  const handleLoadLabels = async (term: string) => {
    try {
      const { data } = await getLabels({ variables: { term } });
      return data?.itemLabels.labels.map(l => ({ value: l.id, label: l.name }));
    } catch (err: any) {
      addNotification({ ok: 0, message: err.message });
    }
  };

  if (redirect) return <Redirect to={redirect} />;

  const secondHandOptions: { label: string; value: any }[] = [
    { label: t("New"), value: false },
    { label: t("Second hand"), value: true }
  ];
  const preOrderOptions: { label: string; value: any }[] = [
    { label: t("Hide pre orders"), value: false },
    { label: t("Pre orders only"), value: true }
  ];
  if (filters.secondHand !== undefined) secondHandOptions.unshift({ label: "-", value: null });
  if (filters.preOrder !== undefined) preOrderOptions.unshift({ label: "-", value: null });

  return (
    <div id="inventory">
      <SectionContainer>
        <SectionHeader title={t("Inventory")}>
          <LeftSection>
            <ButtonV2 type="link" href="/preferences/inventory" variant="secondary" isSettings>
              {t("Settings")}
            </ButtonV2>
            <ButtonV2 type="link" variant="secondary" href="/inventory/suppliers">
              {t("Suppliers")}
            </ButtonV2>
            <ButtonV2 variant="secondary" disabled={!hasDiscogsToken} href="/discogs/listings" type="link">
              {t("My Discogs inventory")}
            </ButtonV2>
          </LeftSection>
          <RightSection>
            <ButtonV2 type="link" href="/import" variant="secondary">
              {t("Import")}
            </ButtonV2>
          </RightSection>
        </SectionHeader>
      </SectionContainer>
      <Zone id="inventoryFilters">
        <div className="column">
          <div id="type" className="column-item">
            <CapsuleGroup
              label={t("Item type")}
              variant="overZone"
              name="type"
              value={filters.type}
              onClick={handleCapsuleSelect}
              entries={[
                { label: t("All"), value: "All" },
                { label: t("Release"), value: "release" },
                { label: t("Product"), value: "product" },
                { label: t("Book"), value: "book" }
              ]}
            />
          </div>
          <div id="stock" className="column-item">
            <CapsuleGroup
              label={t("Stock")}
              variant="overZone"
              name="stock"
              value={filters.stock}
              onClick={handleCapsuleSelect}
              entries={[
                { label: t("All"), value: "All" },
                { label: t("In stock"), value: "instock" },
                { label: t("Sold out"), value: "soldout" }
              ]}
            />
          </div>
          <div id="status" className="column-item">
            <CapsuleGroup
              label={t("Listing status")}
              variant="overZone"
              name="status"
              value={filters.status}
              onClick={handleCapsuleSelect}
              entries={[
                { label: t("All"), value: "All" },
                { label: t("Published"), value: "published" },
                { label: t("Private"), value: "private" },
                { label: t("Draft"), value: "draft" }
              ]}
            />
          </div>
          <div id="discogsStatus" className="column-item">
            <CapsuleGroup
              label={t("Discogs status")}
              variant="overZone"
              name="discogsStatus"
              value={filters.discogsStatus}
              onClick={handleCapsuleSelect}
              entries={[
                { label: t("All"), value: "All" },
                { label: t("Listed"), value: "listed" },
                { label: t("Not listed"), value: "notlisted" }
              ]}
            />
          </div>
        </div>
        <div className="column">
          <div className="hasAudioSnippet column-item">
            <CapsuleGroup
              label={t("Audio snippet")}
              variant="overZone"
              name="hasSnippet"
              value={filters.hasSnippet}
              onClick={handleCapsuleSelect}
              entries={[
                { label: t("All"), value: "All" },
                { label: t("Available"), value: "true" },
                { label: t("Unavailable"), value: "false" }
              ]}
            />
          </div>
          <div id="sort" className="column-item">
            <CapsuleGroup
              isMobile={isMobile}
              label={t("Date filter")}
              variant="overZone"
              name="dateFilter"
              value={filters.dateFilter}
              onClick={handleCapsuleSelect}
              entries={[
                { label: t("Available"), value: "available" },
                { label: t("Published"), value: "posted" },
                { label: t("Item created"), value: "created" }
              ]}
            />
          </div>
          {filters.dateFilter ? (
            <div id="datesRange">
              <div className="from">
                <DatePicker
                  variant="overZone"
                  showTimeSelect={true}
                  value={filters.from}
                  label={t("From")}
                  onChange={(v: string) => handleFromToChange(v, "from")}
                />
                {filters.from ? (
                  <div className="clear">
                    <Button variant="noStyle" type="button" onClick={() => handleFromToChange(null, "from")}>
                      ×
                    </Button>
                  </div>
                ) : (
                  <span />
                )}
              </div>
              <div className="to">
                <DatePicker
                  variant="overZone"
                  showTimeSelect={true}
                  label={t("To")}
                  value={filters.to}
                  onChange={(v: string) => handleFromToChange(v, "to")}
                />
                {filters.to ? (
                  <div className="clear">
                    <Button variant="noStyle" type="button" onClick={() => handleFromToChange(null, "to")}>
                      ×
                    </Button>
                  </div>
                ) : (
                  <span />
                )}
              </div>
            </div>
          ) : null}
        </div>
        <div className="column">
          <div id="mediaConditions" className="column-item">
            <Select
              label={t("Conditions")}
              variant="overZone"
              isMulti={true}
              options={MediaConditions.filter(c => c !== "" && c !== null).map(l => ({ label: l, value: l }))}
              value={filters.condition ? filters.condition.map(o => ({ label: o, value: o })) : null}
              onChange={(a: string, b: string) => handleDropdownChange(a, b, "condition")}
            />
          </div>
          {isLocationSearch ? (
            <div className="search">
              <SearchBySubmit
                type="text"
                disabled={!!filters.location}
                term={filters.locationSearch}
                autoComplete="off"
                name="locationSearch"
                variant="overZone"
                onSubmit={handleLocationSearch}
                onClear={handleRemoveLocationSearchFilter}
                placeholder={t("Search locations") + "..."}
                label={t("Search locations")}
              />
            </div>
          ) : (
            <div className="column-item location">
              <SelectLocation
                label={t("Locations")}
                submitText={<i className="cg-icon-search" />}
                variant="overZone"
                placeholder={t("Select locations")}
                name="term"
                type="text"
                readOnly={filters.locationSearch}
                isMulti={true}
                selectOnly={true}
                value={
                  filters.location
                    ? Array.isArray(filters.location)
                      ? filters.location.map(o => ({ label: o, value: o }))
                      : [{ label: filters.location, value: filters.location }]
                    : null
                }
                onChange={(a: any, b: any) => handleDropdownChange(a, b, "location")}
              />
            </div>
          )}
          <div className="search">
            <SearchBySubmit
              type="text"
              term={filters.term || filters.itemId}
              autoComplete="off"
              name="term"
              variant="overZone"
              onSubmit={handleSearchSubmit}
              onClear={handleRemoveSearchFilter}
              placeholder={t("Enter keyword") + "..."}
              label={t("Filter inventory by keyword")}
            />
          </div>
          <div className="secondHand">
            <Select
              label={t("New") + " / " + t("Second hand")}
              variant="overZone"
              options={secondHandOptions}
              value={filters.secondHand !== undefined ? secondHandOptions.find(o => o.value === filters.secondHand) : null}
              onChange={handleSecondHandChange}
            />
          </div>
        </div>
        <div className="column">
          <div id="categories" className="column-item">
            <SelectCategories
              label={t("Categories")}
              variant="overZone"
              isMulti={true}
              selectOnly={true}
              value={
                filters.categories
                  ? Array.isArray(filters.categories)
                    ? filters.categories.map(o => ({ label: o, value: o }))
                    : [{ label: filters.categories, value: filters.categories }]
                  : null
              }
              onChange={(a: any, b: any) => handleDropdownChange(a, b, "categories")}
            />
          </div>
          <div id="supplierCode" className="column-item">
            <SelectSupplier
              label={t("Supplier Code")}
              variant="overZone"
              isMulti={true}
              selectOnly={true}
              value={
                filters.supplierCode
                  ? Array.isArray(filters.supplierCode)
                    ? filters.supplierCode.map(o => ({ label: o, value: o }))
                    : [
                        {
                          label: filters.supplierCode,
                          value: filters.supplierCode
                        }
                      ]
                  : null
              }
              onChange={(a: any, b: any) => handleDropdownChange(a, b, "supplierCode")}
            />
          </div>
          <div className="labels column-item">
            <SelectAsync
              label={t("Labels")}
              variant="overZone"
              loadOptions={handleLoadLabels}
              isMulti={true}
              placeholder={t("Search labels") + "..."}
              defaultOptions={[{ label: t("Enter label name") + "...", value: "", isDisabled: true }]}
              value={
                filters.labels
                  ? Array.isArray(filters.labels)
                    ? filters.labels.map(o => ({ label: o, value: o }))
                    : [
                        {
                          label: filters.labels,
                          value: filters.labels
                        }
                      ]
                  : null
              }
              onChange={(a: any, b: any) => handleDropdownChange(a, b, "labels")}
            />
          </div>
          <div className="preOrder">
            <Select
              label={t("Pre orders")}
              variant="overZone"
              options={preOrderOptions}
              value={filters.preOrder !== undefined ? preOrderOptions.find(o => o.value === filters.preOrder) : null}
              onChange={handlePreOrderChange}
            />
          </div>
        </div>
      </Zone>
      <InventoryStatistics filters={filters} stats={stats} />
      {page.entries && page.entries.length ? (
        <InventoryPagination
          metadataLoading={metadataLoading}
          filters={filters}
          statistics={true}
          config={config as Config}
          sorting={true}
          pagination={pagination}
          items={page.entries}
          stats={stats}
          currentUri={currentUri}
          stickers={stickers}
        />
      ) : null}
      {page.entries !== undefined && config ? (
        <>
          <section id="inventoryTable" style={{ marginTop: "calc(var(--gutter)*2)" }}>
            {page.entries && page.entries.length ? (
              page.entries.map((e, index) => <ListingsWithItemHeader config={config} key={index} item={e} />)
            ) : (
              <div className="noEntries">
                <div className="placeholder">
                  {t("There are no listings to show here. Start by")} <Link to="/release/add">{t("adding a release")}</Link> {"or" + " "}
                  <Link to="/item/new?type=ProductItem">{t("adding a product")}</Link> {" " + t("to your inventory")}.
                </div>
              </div>
            )}
          </section>
          {page.entries && page.entries.length ? (
            <InventoryPagination
              pagination={pagination}
              items={page.entries}
              filters={filters}
              statistics={false}
              config={config}
              stats={stats}
              sorting={false}
              noBulk={true}
              currentUri={currentUri}
            />
          ) : null}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}
