import React from "react";
import { Typography } from "../Typography";
import { GlobalStore } from "../../stores/global";

type Props = {
  copy: string;
  count: number;
};

export const Statistic: React.FC<Props> = ({ copy, count }) => {
  const { isBelowIpad } = GlobalStore.useState(c => c);
  return (
    <Typography variant={isBelowIpad ? "copy" : "subTitle"} level="secondary" style={{ textTransform: "capitalize", fontWeight: 400 }}>
      {copy}
      <Typography variant="subTitle" style={{ marginLeft: "10px", fontWeight: 400 }}>
        {count}
      </Typography>
    </Typography>
  );
};
