import { gql } from "../../__generated__/gql";

gql(`
  fragment PlanFields on Plan {
    _id
    seats
    active
    expires
    title
    recurrence
    description
    currency
    price {
      value
    }
    trial {
      days
      available
      active
    }
  }
`);

export const GET_PLANS = gql(`
  query plans($currencies: [String!]!) {
    plans(currencies: $currencies) {
      plans {
        ...PlanFields
      }
    }
  }
`);
