import axios from "axios";
import { getSessionJWTForDomain } from "./utils";

axios.interceptors.request.use((config: any) => {
  if (config.url.includes("api.discogs") || config.url.includes(".s3")) return config;
  const jwt = getSessionJWTForDomain();
  if (jwt) config.headers.common["commonground-jwt"] = jwt;
  config.headers.common["commonground-origin"] = localStorage.getItem("origin");
  return config;
});

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  axios
};
