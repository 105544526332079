import { Store } from "pullstate";
import { ItemEditable } from "../components/items/edit.release";
import moment from "moment";
import { Listing } from "../__generated__/graphql";

interface StoreTypes {
  release: ItemEditable;
  product: ItemEditable | null;
  book: ItemEditable | null;
  listings: Listing[];
  drafts: {
    release: boolean;
    product: boolean;
    book: boolean;
  };
}

export const AddItemStore = new Store<StoreTypes>({
  drafts: {
    release: false,
    product: false,
    book: false
  },
  release: {
    type: "ReleaseItem",
    descriptions: { main: "", shop: {} },
    data: {
      discogsId: null,
      identifiers: [],
      assetLink: "",
      title: "",
      tracklist: [],
      images: [],
      formats: [],
      country: "",
      genres: [],
      styles: [],
      releaseDate: moment().format(),
      artists: [],
      labels: [],
      weight: null
    },
    listings: []
  },
  product: null,
  book: null,
  listings: []
});
