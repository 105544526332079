import { useState, useEffect, useCallback } from "react";

export default function useWindowSize(breakpoint: number) {
  const isClient = typeof window === "object";

  const getSize = useCallback(() => {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
      isMobile: isClient && window.innerWidth < (breakpoint || 1080),
      isBelowBreakpoint: isClient && window.innerWidth < (breakpoint || 1080)
    };
  }, [breakpoint, isClient]);

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) return;
    const handleResize = () => setWindowSize(getSize());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isClient, getSize]);

  return windowSize;
}
