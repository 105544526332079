import React, { useState, useRef } from "react";
import { Button as ButtonV2 } from "../../../componentsV2/Button";
import Loader from "../../common/loader";
import { useStripe, Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Modal from "../../modal";
import { GlobalStore } from "../../../stores/global";
import { PaymentMethods } from "./billing";
import { Config, ConfigStripUserPaymentMethod, ConfigStripeUser, Plan } from "../../../__generated__/graphql";
import { AddNotification } from "../../../types/globals";
import { useTranslation } from "react-i18next";
import clone from "clone";
import { getFormattedPrice } from "../../../utils";
import { POST_PLAN_SUBSCRIBE } from "../../../graphql/queries/config";
import { useMutation } from "@apollo/client";
import { ModalHeaderContainer } from "../../../componentsV2/SectionHeader/SectionHeader.styles";
import { Typography } from "../../../componentsV2/Typography";
import * as Sentry from "@sentry/react";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE as string);

const BlockedAccount = ({
  customer,
  hasOutstandingInvoices,
  plans,
  handleReload
}: {
  customer: ConfigStripeUser;
  hasOutstandingInvoices: boolean;
  plans: Plan[];
  handleReload: () => void;
}) => {
  const { config, addNotification } = GlobalStore.useState(c => c);
  const { t } = useTranslation();

  return (
    <section id="blockedAccount">
      <h2>
        {t("Your backoffice access was temporarily deactivated")}.
        <br />
        {config?.status?.reason}
      </h2>
      <p>
        {t("To reactivate your account please add a payment method below and make payments for all outstanding invoices")}.
        <br />
        {t("Your Eshop access will remain effective for a month after your account becomes inactive")}.
        <br /> {t("For more information contact us at")} <a href="mailto:support@common-ground.io">{"support@common-ground.io"}</a>
      </p>
      {!hasOutstandingInvoices ? (
        <Elements stripe={stripePromise}>
          <RestartMembership
            plans={plans}
            config={config as Config}
            addNotification={addNotification}
            paymentMethods={customer && customer.paymentMethods}
            customer={customer}
            handleMembershipStarted={handleReload}
          />
        </Elements>
      ) : null}
    </section>
  );
};

const RestartMembership = ({
  handleMembershipStarted,
  paymentMethods,
  customer,
  addNotification,
  plans,
  config
}: {
  plans: Plan[];
  handleMembershipStarted: any;
  paymentMethods?: ConfigStripUserPaymentMethod[];
  customer: ConfigStripeUser;
  addNotification: AddNotification;
  config: Config;
}) => {
  const modalRef = useRef<any>();
  const { t } = useTranslation();
  const [subscribeToPlan] = useMutation(POST_PLAN_SUBSCRIBE);

  const [plan, setPlan] = useState<Plan>(clone(plans[0]));
  const [isCharging, setIsCharging] = useState(false);
  const stripe = useStripe();

  const handleSelectPlan = (e: any) => {
    const plan = plans.find(p => p._id === e.target.value);
    if (plan) setPlan(plan);
  };

  const handleSubmitPayment = async (e: any) => {
    e.preventDefault();
    if (!e.target.plan.value) return;
    setIsCharging(true);
    try {
      const { data } = await subscribeToPlan({ variables: { planRef: e.target.plan.value } });
      if (!data?.configPlanSubscribe.needsAuthentication) {
        addNotification({ ok: 1, message: "Plan started" });
        await handleMembershipStarted();
        modalRef.current.close();
      } else {
        const intent = data.configPlanSubscribe.intent;
        const results = await stripe?.confirmCardPayment(intent.client_secret, {
          payment_method: intent.last_payment_error.payment_method.id
        });
        if (results?.error) addNotification({ ok: 0, message: results.error.message || "" });
        else if (results?.paymentIntent.status === "succeeded") {
          addNotification({ ok: 1, message: "Invoice was paid successfully" });
          window.location.reload();
        }
      }
    } catch (error: any) {
      addNotification({ ok: 0, message: e.message });
      Sentry.captureException(error);
    } finally {
      setIsCharging(false);
    }
  };

  if (!plan) return null;

  return (
    <div className="restartMembership" style={{ display: "flex", justifyContent: "center" }}>
      <Modal style={{}} ref={modalRef}>
        <div id="restartMembershipModalContent">
          {!paymentMethods || !paymentMethods.length ? (
            <PaymentMethods addNotification={addNotification} customer={customer} />
          ) : (
            <form onSubmit={handleSubmitPayment}>
              <ModalHeaderContainer>
                <Typography variant="pageTitle" tag="h2">
                  {t("Select your preferred plan")}
                </Typography>
                <button className="reset" type="button" onClick={() => modalRef.current.close()}>
                  <i className="cg-icon-burger-close" />
                </button>
              </ModalHeaderContainer>
              {plans.map(p => (
                <label key={p._id}>
                  <input
                    type="radio"
                    className="plan"
                    name="plan"
                    checked={p._id === plan._id}
                    onChange={handleSelectPlan}
                    value={p._id}
                    style={{ marginRight: "10px" }}
                  />
                  {p.title} - {getFormattedPrice({ value: p.price.value, config })} / {t("month")}
                </label>
              ))}
              {plan ? (
                <div className="total">
                  <p>
                    {t("Payable total for this invoice is")} {getFormattedPrice({ value: plan.price.value, config })}
                    {" + VAT"}
                  </p>
                </div>
              ) : null}
              <ButtonV2 type="submit" variant="primary" disabled={!paymentMethods || paymentMethods.length === 0 || isCharging}>
                {isCharging ? <Loader /> : t("Submit payment")}
              </ButtonV2>
            </form>
          )}
        </div>
      </Modal>
      <ButtonV2 variant="primary" type="button" onClick={() => modalRef.current.open()}>
        {t("Subscribe to a plan")}
      </ButtonV2>
    </div>
  );
};

export default BlockedAccount;
