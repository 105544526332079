import { gql } from "../../__generated__/gql";

export const GET_USER = gql(`
  query user($userRef: ID!) {
    user(userRef: $userRef) {
      ...BuyerFields
    }
    lists {
      lists {
        ...ListFields
      }
    }
  }
`);

export const GET_USERS = gql(`
  query users($listRef: ID, $page: Int, $perPage: Int) {
    users(listRef: $listRef, page: $page, perPage: $perPage) {
      users {
        ...BuyerFields
      }
      pagination {
        page
        pages
        count
      }
    }
    lists {
      lists {
        ...ListFields
      }
    }
  }
`);

export const GET_USER_SEARCH = gql(`
  query userSearch($term: String!) {
    userSearch(term: $term) {
      _id
      name
      email
      path
    }
  }
`);

export const GET_USER_WANTLIST = gql(`
  query userWantlist($userRef: ID!, $page: Int!) {
    userWantlist(userRef: $userRef, page: $page) {
      pagination {
        limit
        pages
        page
      }
      entries {
        ...ItemFields
      }
    }
  }
`);

export const POST_USER_VERIFY = gql(`
  mutation userVerify($userRef: ID!) {
    userVerify(userRef: $userRef)
  }
`);

export const POST_USERS_EXPORT = gql(`
  mutation usersExport($listRef: ID) {
    usersExport(listRef: $listRef)
  }
`);

export const POST_USERS_UPLOAD = gql(`
  mutation usersUpload($file: Upload!) {
    usersUpload(file: $file) {
      _id
    }
  }
`);

export const POST_USER_BUYER_CREATE_ESHOP_ACCOUNT = gql(`
  mutation userBuyerCreateEshopAccount($userRef: ID!) {
    userBuyerCreateEshopAccount(userRef: $userRef) {
      ...BuyerFields
    }
  }
`);

export const POST_USER_BUYER_CREATE = gql(`
  mutation userBuyerCreate($email: String!, $firstName: String!, $lastName: String!, $telephone: String, $organisation: String, $taxNumber: String, $lists: [ID!], $addresses: [AddressInput!], $createEshopAccount: Boolean) {
    userBuyerCreate(email: $email, firstName: $firstName, lastName: $lastName, telephone: $telephone, organisation: $organisation, taxNumber: $taxNumber, lists: $lists, addresses: $addresses, createEshopAccount: $createEshopAccount) {
      ...BuyerFields
    }
  }
`);

export const POST_USER_BUYER_UPDATE = gql(`
  mutation userBuyerUpdate($userRef: ID!, $userBuyerUpdateInput: UserBuyerUpdateInput!) {
    userBuyerUpdate(userRef: $userRef, userBuyerUpdateInput: $userBuyerUpdateInput) {
      ...BuyerFields
    }
  }
`);

export const POST_USER_BUYER_DELETE = gql(`
  mutation userBuyerDelete($userRef: ID!) {
    userBuyerDelete(userRef: $userRef) {
      ...BuyerFields
    }
  }
`);

export const POST_USER_ADMIN_CREATE = gql(`
  mutation userAdminCreate($firstName: String! $lastName: String! $email: String!, $password: String, $ssoInput: SSOInput) {
    userAdminCreate(email: $email, firstName: $firstName, lastName: $lastName, password: $password, ssoInput: $ssoInput) {
      user {
        ...AdminFields
      }
      session {
        ...SessionFields
      }
    }
  }
`);

export const POST_USER_ADMIN_UPDATE = gql(`
  mutation userAdminUpdate($firstName: String! $lastName: String! $email: String!, $password: String) {
    userAdminUpdate(email: $email, firstName: $firstName, lastName: $lastName, password: $password) {
      ...AdminFields
    }
  }
`);

export const POST_USER_ADMIN_VERIFY = gql(`
  mutation userAdminVerify($userRef: ID!, $token: String, $resend: Boolean) {
    userAdminVerify(userRef: $userRef, token: $token, resend: $resend) {
      ...AdminFields
    }
  }
`);

export const POST_USER_ADMIN_PASSWORD_RESET = gql(`
  mutation userAdminPasswordReset($token: String!, $password: String!) {
    userAdminPasswordReset(token: $token, password: $password) {
      ...AdminFields
    }
  }
`);

export const POST_USER_ADMIN_PASSWORD_TOKEN = gql(`
  mutation userAdminPasswordToken($email: String!) {
    userAdminPasswordToken(email: $email) {
      ...AdminFields
    }
  }
`);

export const POST_USER_CREATE_PASSWORD_TOKEN = gql(`
  mutation userBuyerCreatePasswordToken($email: String!) {
    userBuyerCreatePasswordToken(email: $email) {
      ...BuyerFields
    }
}`);
