import { ApolloClient, InMemoryCache } from "@apollo/client";

const communityClient = new ApolloClient({
  uri: process.env.REACT_APP_FEED_ENDPOINT + "/graphql",
  cache: new InMemoryCache()
});

const clientClient = new ApolloClient({
  uri: process.env.REACT_APP_CLIENT_API_ENDPOINT + "/graphql",
  cache: new InMemoryCache()
});

export { communityClient, clientClient };
