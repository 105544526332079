import { gql } from "../../__generated__/gql";

gql(`
  fragment PlaylistFields on Playlist {
    _id
    id
    created
    title
    link
    entries {
      comments
      item {
        ...ItemFields
      }
    }
  }
`);

export const GET_PLAYLISTS = gql(`
  query playlists($page: Int) {
    playlists(page: $page) {
      playlists {
        ...PlaylistFields
      }
    }
  }
`);

export const GET_PLAYLIST = gql(`
  query playlist($id: Int!) {
    playlist(id: $id) {
      ...PlaylistFields
    }
  }
`);

export const POST_PLAYLIST_CREATE = gql(`
  mutation playlistCreate($title: String!) {
    playlistCreate(title: $title) {
      id
      title
    }
  }
`);

export const POST_PLAYLIST_DELETE = gql(`
  mutation playlistDelete($playlistRef: ID!) {
    playlistDelete(playlistRef: $playlistRef)
  }
`);

export const POST_PLAYLIST_UPDATE = gql(`
  mutation playlistUpdate($playlistRef: ID!, $playlistUpdateInput: PlaylistUpdateInput!) {
    playlistUpdate(playlistRef: $playlistRef, playlistUpdateInput: $playlistUpdateInput) {
      ...PlaylistFields
    }
  }
`);
