import { applyOpacity, colorsAsRgbArray } from "@common-ground-io/colors";
import { ScriptableTooltipContext } from "chart.js";

export const tooltip = (context: ScriptableTooltipContext<any>) => {
  const getOrCreateTooltip = (chart: any) => {
    if (!chart.canvas.parentNode) return null;
    let tooltipEl = chart.canvas.parentNode.querySelector("div");

    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.style.background = applyOpacity(colorsAsRgbArray.greyDarkest, 1);
      tooltipEl.style.padding = "20px";
      tooltipEl.style.borderRadius = "5px";
      tooltipEl.style.color = "white";
      tooltipEl.style.opacity = "1";
      tooltipEl.style.pointerEvents = "none";
      tooltipEl.style.position = "absolute";
      tooltipEl.style.transform = "translate(-50%, 0)";
      tooltipEl.style.transition = "all .1s ease";

      const table = document.createElement("table");
      table.style.margin = "5px";

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  };

  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltipEl && tooltip.opacity === 0) {
    tooltipEl.style.opacity = "0";
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map(b => b.lines);

    const tableHead = document.createElement("thead");
    tableHead.style.height = "30px";

    titleLines.forEach(title => {
      const tr = document.createElement("tr");
      tr.style.borderWidth = "0";

      const th = document.createElement("th");
      th.style.borderWidth = "0";
      const text = document.createTextNode(title);

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement("tbody");

    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i];

      const tr = document.createElement("tr");
      tr.style.backgroundColor = "inherit";
      tr.style.borderWidth = "0";
      tr.style.padding = "15px";
      tr.style.height = "20px";

      const td1 = document.createElement("td");
      td1.style.borderWidth = "0";

      const td2 = document.createElement("td");
      td2.style.borderWidth = "0";
      td2.style.color = colors.borderColor.toString();

      const td3 = document.createElement("td");
      td3.style.borderWidth = "0";
      td3.style.color = colors.borderColor.toString();
      td3.style.textAlign = "right";

      const textLeft = document.createTextNode(body[0].split("|")[0]);
      const textRight = document.createTextNode(body[0].split("|")[1]);

      td2.appendChild(textLeft);
      td3.appendChild(textRight);
      tr.appendChild(td2);
      tr.appendChild(td3);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector("table");

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + "px";
  tooltipEl.style.top = positionY + tooltip.caretY + "px";
  // tooltipEl.style.font = tooltip.options.bodyFont;
  tooltipEl.style.padding = tooltip.options.padding + "px " + tooltip.options.padding + "px";
};
