import { useState } from "react";
import { useIdleTimer } from "react-idle-timer";

export default function IdleDetector({ timeout = 61_000 } = { timeout: 61_000 }) {
  const [isIdled, setIsIdled] = useState(false);

  const onIdle = () => setIsIdled(true);
  const onActive = () => setIsIdled(false);

  useIdleTimer({
    onIdle,
    onActive,
    timeout,
    throttle: 500
  });

  return { isIdled };
}
