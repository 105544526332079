import { gql } from "../../__generated__/gql";

gql(`
  fragment VoucherFields on Voucher {
    _id
    id
    type
    value
    originalValue
    note {
      content
    }
    user {
      ref
      name
    }
    redeemType
    redeemed
    singleSpending
    spendings {
      orderId
      amount
    }
    expires
    resourcePath
    recipients {
      date
      recipient
    }
    unlimited
    created
  }
`);

export const GET_VOUCHERS = gql(`
  query vouchers($term: String, $type: String, $status: String, $sortBy: String, $sortOrder: Int, $expired: String, $page: Int, $userRef: ID) {
    vouchers(term: $term, type: $type, status: $status, sortBy: $sortBy, sortOrder: $sortOrder, expired: $expired, page: $page, userRef: $userRef) {
      pagination {
        page
        pages
        count
      }
      vouchers {
        ...VoucherFields
      }
    }
  }
`);

export const POST_VOUCHER_SEND_RECEIPT = gql(`
  mutation voucherSendReceipt($voucherRef: ID!, $recipient: String) {
    voucherSendReceipt(voucherRef: $voucherRef, recipient: $recipient) {
      ...VoucherFields
    }
  }
`);

export const POST_VOUCHER_CREATE = gql(`
  mutation voucherCreate($amount: Int!, $prefix: String, $id: String, $expires: Date, $unlimited: Boolean, $singleSpending: Boolean, $codeLength: Int, $userRef: ID, $type: String, $redeemType: String!, $value: Float!, $note: String) {
    voucherCreate(amount: $amount, prefix: $prefix, id: $id, codeLength: $codeLength, unlimited: $unlimited, singleSpending: $singleSpending, userRef: $userRef, expires: $expires, type: $type, redeemType: $redeemType, value: $value, note: $note) {
      ...VoucherFields
    }
  }
`);

export const POST_VOUCHER_DELETE = gql(`
  mutation voucherDelete($voucherRef: ID!) {
    voucherDelete(voucherRef: $voucherRef)
  }
`);

export const POST_VOUCHER_DEACTIVATE = gql(`
  mutation voucherDeactivate($voucherRef: ID!) {
    voucherDeactivate(voucherRef: $voucherRef) {
      ...VoucherFields
    }
  }
`);
