import React from "react";
import Button from "../components/styled/button";
import { useTranslation } from "react-i18next";

const FileUploader = ({
  handleFile,
  label,
  accept,
  name,
  isUploading
}: {
  handleFile: any;
  label?: string;
  accept?: string;
  name?: string;
  isUploading?: boolean;
}) => {
  const hiddenFileInput = React.useRef<any>(null);
  const { t } = useTranslation();

  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event: any) => {
    const fileUploaded = event.target.files[0];
    if (handleFile) handleFile(fileUploaded);
  };
  return (
    <>
      <Button disabled={isUploading} variant="secondary" type="button" onClick={handleClick}>
        {isUploading ? t("Uploading") + "..." : label || t("Upload a file")}
      </Button>
      <input type="file" name={name} accept={accept || "*"} ref={hiddenFileInput} onChange={handleChange} style={{ display: "none" }} />
    </>
  );
};
export default FileUploader;
