import { gql } from "../../__generated__/gql";

export const GET_NEWS_PREVIEW = gql(`
  query supportNewsPreview {
    supportNews {
      pagination {
        pages
      }
      campaigns {
        id
        title
        publication {
          status
          date
        }
      }
    }
  }
`);

export const GET_ARTICLES = gql(`
  query supportNews {
    supportNews {
      campaigns {
        id
        title
        html
        uri
        publication {
          status
          date
        }
      }
    }
}
`);
