import { gql } from "@apollo/client";

export const GET_CLIENT_ITEM = gql(`
  query ($itemRef: ID!) {
    item(itemRef: $itemRef) {
      _id
      id
      type
      path
      wanted
      uri
      isForbiddenForSale
      descriptions {
        main
        shop {
            text
            html
            short
        }
      }
      data {
        title
        type
        identifiers {
          type
          value
        }
        images {
          uri
          alternative {
            uri
          }
        }
        assetLink
        weight
        genres
        styles
        manufacturer
        cat
        releaseDate
        tracklist {
          type_
          title
          artists {
            name
            id
            anv
          }
          uri
          duration
          position
        }
        formats {
          descriptions
          name
          qty
        }
        country
        artists {
          name
          anv
          id
        }
        labels {
          name
          id
          catno
        }
        videos {
          uri
        }

        subtitle
        authors
        publisher
        publishedDate
        pageCount
        categories
        maturityRating
        language
      }
    }
  }
`);

const CREATE_POST = gql(`
  mutation($configRef: ID!, $item: ItemInput!) {
    createPost(configRef: $configRef, item: $item) {
      _id
      item {
        _id
        artwork
        uri
        isPreorder
        hasAudio
      }
      config {
        _id
      }
      isFavorite
      timestamp
    }
  }
`);

const GET_POSTS = gql(`
  query($userRef: ID!, $favorites: Boolean) {
    getIntervals(userRef: $userRef, favorites: $favorites) {
      _id
      config {
        _id
        name
        thumbnail
        uri
      }
      created
      posts {
        _id
        item {
          _id
          artwork
          uri
          isPreorder
          hasAudio
        }
        config {
          _id
        }
        isFavorite
        timestamp
      }
    }
  }
`);

const GET_POST = gql(`
  query($userRef: ID!, $postRef: ID!) {
    getPost(userRef: $userRef, postRef: $postRef) {
      _id
      item {
        _id
        artwork
        uri
        isPreorder
      }
      isFavorite
      timestamp
      config {
        _id
        name
        thumbnail
        uri
      }
    }
  }
`);

const POST_SAVE_POST = gql(`
  mutation($userRef: ID!, $postRef: ID!) {
    savePost(userRef: $userRef, postRef: $postRef) {
      _id
      item {
        _id
        artwork
        uri
        isPreorder
        hasAudio
      }
      config {
        _id
      }
      isFavorite
      timestamp
    }
  }
`);

export { CREATE_POST, GET_POSTS, POST_SAVE_POST, GET_POST };
