import React from "react";
import { Link } from "react-router-dom";
import { ReleaseArtist, ReleaseFormat, ReleaseLabel } from "../../__generated__/graphql";

export function ArtistNames({ artists }: { artists: ReleaseArtist[] }) {
  return (
    <>
      {artists.map((a, index) => (
        <span key={index}>
          <Link to={`/catalogue?artists=${a.id}`}>
            {a.anv || a.name} {a.join ? `${a.join} ` : " "}
          </Link>
        </span>
      ))}
    </>
  );
}

export function LabelNames({ labels }: { labels: ReleaseLabel[] }) {
  return (
    <>
      {labels.map((l, index) => (
        <span key={index}>
          <Link to={`/catalogue?labels=${l.id}`}>{l.name} </Link>
        </span>
      ))}
    </>
  );
}

export function FormatNames({ formats }: { formats: ReleaseFormat[] }) {
  return (
    <>
      {formats.map((f, i) => (
        <span key={i}>
          <span>
            {f.qty}
            {"x "}
            {f.name}
          </span>
          {f.descriptions?.map(d => (
            <span key={d}> {d}</span>
          ))}
          {f.text ? <p>{f.text}</p> : null}
        </span>
      ))}
    </>
  );
}
