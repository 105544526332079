import { gql } from "../../__generated__/gql";

export const CHECKOUT_ORDER_BUYER_FIELDS = gql(`
  fragment CheckoutBuyerFields on CheckoutOrOrderBuyer {
    _id
    name
    organisation
    email
    telephone
    lastName
    firstName
    taxNumber
    addresses {
      ...AddressFields
    }
  }
`);

export const CHECKOUT_FIELDS = gql(`
  fragment CheckoutFields on Checkout {
    _id
    id
    version
    created
    status
    origin
    orderId
    buyer {
      ...CheckoutBuyerFields
    }
    note {
      content
    }
    billing {
      voucher {
        _id
        id
        value
        originalValue
        redeemType
      }
      paymentMethods {
        origin
        amount
        charge
      }
    }
    shipping {
      status
      shopCollection
      price {
        base
        beforeTaxes
      }
      address {
        ...AddressFields
      }
    }
    items {
      _id
      quantity
      price {
        taxes {
          name
          amount
        }
        beforeTaxes
        base
        subtotal
        subtotalBeforeTaxes
      }
      item {
        id
        description
        thumbnail
        weight
        path
      }
      listing {
        _id
        id
        available
        location
        stock {
          quantity
        }
        prices {
          beforeTaxes
          sale
          compare
        }
        onePerCustomer
        options {
          name
          value
        }
        comments
      }
    }
    totals {
      subtotal
      subtotalBeforeTaxes
      grand
      shipping
      discount
      itemQuantity
      leftToPay
      taxes {
        name
        amount
      }
    }
  }
`);

export const GET_CHECKOUTS = gql(`
  query checkouts($origin: String, $status: String) {
    checkouts(origin: $origin, status: $status) {
      checkouts {
        _id
      }
    }
  }
`);

// For open checkouts only
export const GET_CHECKOUTS_WITH_ITEMS = gql(`
  query checkoutsWithItems($origin: String, $status: String, $nonEmpty: Boolean) {
    checkouts(origin: $origin, status: $status, nonEmpty: $nonEmpty) {
      checkouts {
        _id
        id
        version
        origin
        buyer {
          _id
          firstName
          lastName
          email
          name
        }
        totals {
          grand
        }
        items {
          quantity
          listing {
            id
          }
          item {
            id
            description
            path
          }
        }
      }
    }
  }
`);

export const GET_CHECKOUT = gql(`
  query checkout($checkoutRef: ID!) {
    checkout(checkoutRef: $checkoutRef) {
      ...CheckoutFields
    }
  }
`);

export const POST_CHECKOUT_ADD_LISTING = gql(`
  mutation checkoutAddListing($id: String!, $listingRef: ID!, $version: Int) {
    checkoutAddListing(id: $id, listingRef: $listingRef, version: $version) {
      ...CheckoutFields
    }
  }
`);

export const POST_CHECKOUT_REMOVE_ENTRY = gql(`
  mutation checkoutRemoveEntry($id: String!, $entryRef: ID!, $version: Int) {
    checkoutRemoveEntry(id: $id, entryRef: $entryRef, version: $version) {
      ...CheckoutFields
    }
  }
`);

export const POST_CHECKOUT_UPDATE_LINE = gql(`
  mutation checkoutUpdateLine($id: String!, $entryRef: ID!, $lineBasePrice: Float!, $lineQuantity: Int!, $version: Int) {
    checkoutUpdateLine(id: $id, entryRef: $entryRef, lineBasePrice: $lineBasePrice, lineQuantity: $lineQuantity, version: $version) {
      ...CheckoutFields
    }
  }
`);

export const POST_CHECKOUT_ADD_NOTE = gql(`
  mutation checkoutAddNote($id: String!, $content: String!, $version: Int) {
    checkoutAddNote(id: $id, content: $content, version: $version) {
      ...CheckoutFields
    }
  }
`);

export const POST_CHECKOUT_UPDATE_SHIPPING = gql(`
  mutation checkoutUpdateShipping($id: String!, $basePrice: Float, $version: Int, $addressRef: ID) {
    checkoutUpdateShipping(id: $id, basePrice: $basePrice, addressRef: $addressRef, version: $version) {
      ...CheckoutFields
    }
  }
`);

export const POST_CHECKOUT_UPDATE_BUYER = gql(`
  mutation checkoutUpdateBuyer($id: String!, $buyerRef: ID, $version: Int) {
    checkoutUpdateBuyer(id: $id, buyerRef: $buyerRef, version: $version) {
      ...CheckoutFields
    }
  }
`);

export const POST_CHECKOUT_DELETE = gql(`
  mutation checkoutDelete($id: String!, $version: Int) {
    checkoutDelete(id: $id, version: $version)
  }
`);

export const POST_CHECKOUT_CREATE = gql(`
  mutation checkoutCreate {
    checkoutCreate {
      ...CheckoutFields
    }
  }
`);

export const POST_CHECKOUT_UPDATE_PAYMENTS = gql(`
  mutation checkoutUpdatePayments($id: String!, $paymentMethods: [CheckoutPaymentInput]!, $version: Int) {
    checkoutUpdatePayments(id: $id, paymentMethods: $paymentMethods, version: $version) {
      ...CheckoutFields
    }
  }
`);

export const POST_CHECKOUT_SUBMIT = gql(`
  mutation checkoutSubmit($id: String! $collected: Boolean, $version: Int) {
    checkoutSubmit(id: $id, collected: $collected, version: $version) {
      checkout {
        ...CheckoutFields
      }
    }
  }
`);

export const POST_CHECKOUT_VERIFY = gql(`
  mutation checkoutVerify($checkoutRef: ID!, $version: Int) {
    checkoutVerify(checkoutRef: $checkoutRef, version: $version) {
      ...CheckoutFields
    }
  }
`);

export const POST_CHECKOUT_UPDATE_VOUCHER = gql(`
  mutation checkoutUpdateVoucher($checkoutRef: ID!, $voucherRef: ID, $version: Int) {
    checkoutUpdateVoucher(checkoutRef: $checkoutRef, voucherRef: $voucherRef, version: $version) {
      ...CheckoutFields
    }
  }
`);

export const POST_CHECKOUT_UPDATE_GIFT_CARD = gql(`
  mutation checkoutUpdateGiftCard($checkoutRef: ID!, $voucherRef: ID, $version: Int) {
    checkoutUpdateGiftCard(checkoutRef: $checkoutRef, voucherRef: $voucherRef, version: $version) {
      ...CheckoutFields
    }
  }
`);
