import { BaseStyledComponentProps, KeyObjType, LayoutType } from "../types/styles.types";

export const getThemeProps = <T extends KeyObjType>(
  layout: LayoutType<T, KeyObjType>,
  props: BaseStyledComponentProps<T, KeyObjType>
): T => {
  const defaultVariant = props.variant ?? "primary";

  const storybookKey = props.theme?.base;
  const layoutKey = props.theme.name || "light";

  return (layout[storybookKey ?? layoutKey][defaultVariant] as T) ?? {};
};

export const breakpoints = {
  xs: "320px",
  sm: "576px",
  md: "768px",
  mdL: "820px",
  lg: "1152px",
  wide: "1800px"
};

export const devices = {
  mobileS: `(max-width: ${breakpoints.xs})`,
  mobile: `(max-width: ${breakpoints.sm})`,
  tablet: `(max-width: ${breakpoints.md})`,
  tabletL: `(max-width: ${breakpoints.mdL})`,
  desktop: `(max-width: ${breakpoints.lg})`,
  desktopL: `(max-width: ${breakpoints.wide})`
};
