import { gql } from "../../__generated__/gql";

export const POST_TEMPLATE_SET_PRIMARY = gql(`
  mutation templateSetAsPrimary($templateRef: ID!) {
    templateSetAsPrimary(templateRef: $templateRef) {
      ...TemplateFields
    }
  }
`);

export const POST_TEMPLATE_UDPATE = gql(`
  mutation templateUpdate($templateRef: ID!, $templateUpdateInput: TemplateUpdateInput) {
    templateUpdate(templateRef: $templateRef, templateUpdateInput: $templateUpdateInput) {
      ...TemplateFields
    }
  }
`);

export const POST_TEMPLATE_DELETE = gql(`
  mutation templateDelete($templateRef: ID!) {
    templateDelete(templateRef: $templateRef)
  }
`);

export const POST_TEMPLATE_CREATE = gql(`
  mutation templateCreate($title: String!, $type: String, $data: JSON, $fromDefault: Boolean) {
    templateCreate(title: $title, type: $type, data: $data, fromDefault: $fromDefault) {
      ...TemplateFields
    }
  }
`);

export const POST_TEMPLATE_UPGRADE = gql(`
  mutation templateUpgrade($templateRef: ID!) {
    templateUpgrade(templateRef: $templateRef) {
      ...TemplateFields
    }
  }
`);

export const POST_TEMPLATE_RENDER = gql(`
  mutation templateRender($templateRef: ID!, $content: String) {
    templateRender(templateRef: $templateRef, content: $content)
  }
`);

export const POST_TEMPLATE_DATASET_GENERATE = gql(`
  mutation templateDatasetGenerate($templateRef: ID!) {
    templateDatasetGenerate(templateRef: $templateRef) {
      ...TemplateFields
    }
  }
`);

export const GET_TEMPLATES = gql(`
  query templatesConnection($types: [String], $sortBy: String, $sortOrder: Int) {
    templatesConnection(types: $types, sortBy: $sortBy, sortOrder: $sortOrder) {
      templates {
        ...TemplateFields
      }
    }
  }
`);

export const GET_TEMPLATE = gql(`
  query template($templateRef: ID!) {
    template(templateRef: $templateRef) {
      ...TemplateFields
    }
  }
`);
