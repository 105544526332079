import i18next from "i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en from "./en/ns1.json";
import fr from "./fr/ns1.json";

export const defaultNS = "ns1";
export const resources = { en: { ns1: en }, fr: { ns1: fr } } as const;
export const languages = ["en", "fr"];

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: languages[0],
    debug: false,
    supportedLngs: languages,
    interpolation: { escapeValue: false },
    resources,
    defaultNS
  });

export default i18next;
