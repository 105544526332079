import React from "react";
import { Match } from "../types/globals";
import { useTranslation } from "react-i18next";

export default function EmailVerify({ match }: { match: Match }) {
  const state = match.params.state;
  const { t } = useTranslation();

  return (
    <div id="stripeConnect" style={{ textAlign: "center", marginTop: "var(--gutter)" }}>
      <h2>{state === "success" ? t("Your email was successfully verified") : t("Error while verifying your email address")}</h2>
    </div>
  );
}
