import React, { FormEvent, useRef, useState } from "react";
import QRCode from "react-qr-code";
import Input from "../../../styled/input";
import { Button } from "../../../../componentsV2/Button";
import styled from "styled-components";
import { Address, Config, ConfigEshopCheckoutStripeLocation } from "../../../../__generated__/graphql";
import countries from "@common-ground-io/common-assets/assets/countries.json";
import { Select } from "../../../styled/select";
import { useMutation, useQuery } from "@apollo/client";
import { POST_PAYMENT_METHOD_UPDATE, POST_STRIPE_LOCATION } from "../../../../graphql/queries/config";
import { AddNotification } from "../../../../types/globals";
import ZoneComponent from "../../../styled/zone";
import { GET_REGISTRATIONS, POST_REGISTRATION_DELETE } from "../../../../graphql/queries/session";
import moment from "moment";
import ModalComponent from "../../../modal";
import { Typography } from "../../../../componentsV2/Typography";
import clone from "clone";
import * as amplitude from "@amplitude/analytics-browser";
import { useTranslation } from "react-i18next";
import AddressComponent from "../../../../componentsV2/Address";

const FieldSet = styled.fieldset`
  & > * {
    margin-bottom: var(--gutter);
  }
`;

const AddressInline = styled.fieldset`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  gap: var(--gutter);
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--gutter);
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const PointOfSale = ({ config, session, addNotification }: { config: Config; session: any; addNotification: AddNotification }) => {
  const [editLocation] = useMutation(POST_STRIPE_LOCATION);
  const { data, refetch } = useQuery(GET_REGISTRATIONS, { fetchPolicy: "cache-and-network" });
  const [deleteRegistration] = useMutation(POST_REGISTRATION_DELETE);
  const modalRef = useRef<any>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [updatePaymentMethod, { loading: paypalLoading }] = useMutation(POST_PAYMENT_METHOD_UPDATE);
  const { t } = useTranslation();
  const stripeConfig = !!config.eshop.checkout.paymentMethods?.stripe?.enabled;

  const code = session.jwt + "|" + config._id;
  const [location, setLocation] = useState<{ name: string; address: Address }>({
    name: "",
    address: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      country: "",
      alpha2: ""
    }
  });
  const [dataFromConfig, setDataFromConfig] = useState(clone(config.eshop.checkout.paymentMethods?.paypal || {}));

  const handleSubmitLocation = (e: FormEvent) => {
    setIsSubmitting(true);
    e.preventDefault();
    editLocation({ variables: { createLocation: { ...location } } })
      .then(() => {
        amplitude.track("POS create location", { title: location.name });
        modalRef.current.close();
      })
      .catch(e => addNotification({ ok: 0, message: e.message }))
      .finally(() => setIsSubmitting(false));
  };

  const handleDeleteLocation = (location: ConfigEshopCheckoutStripeLocation) => {
    editLocation({ variables: { deleteLocationId: location.id } }).catch(e => addNotification({ ok: 0, message: e.message }));
  };

  const handleDeleteRegistration = async (registrationRef: string) => {
    deleteRegistration({ variables: { registrationRef } })
      .catch(e => addNotification({ ok: 0, message: e.message }))
      .then(() => refetch())
      .finally(() => setIsSubmitting(false));
  };

  const handleChange = (name: string, value: string) => {
    // @ts-ignore
    location.address[name] = value;
    setLocation({ ...location, address: { ...location.address } });
  };

  const handleDataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    dataFromConfig[event.target.name] = event.target.value;
    setDataFromConfig({ ...dataFromConfig });
    setDirty(true);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    updatePaymentMethod({
      variables: {
        paymentMethodId: "paypal",
        paymentMethodData: dataFromConfig
      }
    })
      .then(() => {
        addNotification({ ok: 1, message: "Paypal settings saved" });
        setDirty(false);
        amplitude.track("POS paypalme", { url: dataFromConfig.meUrl });
      })
      .catch(e => addNotification({ ok: 0, message: e.message }));
  };

  const locations = config.eshop.checkout.paymentMethods?.stripe?.locations || [];
  const registrations = data?.sessionRegistrations;

  return (
    <div>
      <ModalComponent ref={modalRef} style={{}}>
        <div style={{ display: "grid" }}>
          <h2>{t("Add a reader location")}</h2>
          <form onSubmit={handleSubmitLocation}>
            <FieldSet>
              <Input
                variant="overZone"
                label="Reader name"
                type="text"
                autoComplete="off"
                required
                name="description"
                placeholder="eg. At the counter..."
                value={location.name || ""}
                onChange={(e: any) => setLocation({ ...location, name: e.target.value })}
              />
              <Input
                label="Address line 1"
                variant="overZone"
                type="text"
                placeholder="Main address line such as 2 Oxford Street"
                name="addressLine1"
                required
                value={location.address.addressLine1 || ""}
                onChange={(e: any) => handleChange(e.target.name, e.target.value)}
              />
              <Input
                label="Address line 2"
                variant="overZone"
                type="text"
                name="addressLine2"
                placeholder="Additional address line if needed"
                value={location.address.addressLine2 || ""}
                onChange={(e: any) => handleChange(e.target.name, e.target.value)}
              />
              <AddressInline>
                <Input
                  label="City"
                  variant="overZone"
                  type="text"
                  name="city"
                  placeholder="The city such as Sydney"
                  required
                  value={location.address.city || ""}
                  onChange={(e: any) => handleChange(e.target.name, e.target.value)}
                />
                <Input
                  label="Post code / zip"
                  variant="overZone"
                  type="text"
                  name="postCode"
                  required
                  placeholder="Letters or numbers such as N168UT"
                  value={location.address.postCode || ""}
                  onChange={(e: any) => handleChange(e.target.name, e.target.value)}
                />
              </AddressInline>
              <AddressInline>
                <Input
                  label="State"
                  type="text"
                  variant="overZone"
                  name="state"
                  value={location.address.state || ""}
                  placeholder="State or province like Ohio or BC"
                  onChange={(e: any) => handleChange(e.target.name, e.target.value)}
                />
                <Select
                  label="Country"
                  variant="overZone"
                  placeholder="The billing or shipping country"
                  value={{ value: location.address.alpha2, label: countries.find(c => c.code === location.address.alpha2)?.name }}
                  onChange={(option: any) => {
                    handleChange("alpha2", option.value);
                    handleChange("country", countries.find(c => c.code === option.value)?.name as string);
                  }}
                  options={countries.map(c => ({ label: c.name, value: c.code }))}
                />
              </AddressInline>
            </FieldSet>
            <Button type="submit" disabled={isSubmitting} variant="primary">
              {t("Submit")}
            </Button>
          </form>
        </div>
      </ModalComponent>
      <Typography variant="pageTitle" tag="h1" style={{ marginBottom: "20px" }}>
        {t("Point of sale")}
      </Typography>
      <Container>
        <ZoneComponent style={{ marginBottom: "0px" }}>
          <Typography variant="secondaryTitle" tag="h2" style={{ marginBottom: "var(--gutter)" }}>
            {t("Register your iOS devices")}
          </Typography>
          <Container>
            <QRCode value={code} style={{ width: "100%" }} />
            <div>
              <Typography variant="subTitle" tag="h3" level="secondary" style={{ marginBottom: "var(--gutter)" }}>
                {t("Registrations")}
              </Typography>
              <Typography variant="copy" level="secondary">
                {t("Each team member may register their iOS device")}.
                <br /> {t("Registered devices do not count towards the seat limit or web access")}.
              </Typography>
            </div>
          </Container>
          <div style={{ marginTop: "var(--gutter)" }}>
            <div style={{ display: "grid", gridGap: "var(--gutter)" }}>
              <h2>{t("Active registrations")}</h2>
              {registrations?.length ? (
                registrations?.map(r => (
                  <>
                    <div key={r._id} style={{ display: "flex", justifyContent: "space-between" }}>
                      <div style={{ display: "flex", flexDirection: "column", gap: "" }}>
                        <Typography variant="copy" tag="p" level="normal">
                          {r.deviceName}, {t("Created")} {moment(r.createdAt).format("ll")} {t("by")} {r.user.name}
                        </Typography>
                        <Typography variant="copy" tag="p" level="secondary">
                          {r.deviceId}
                        </Typography>
                      </div>
                      <div>
                        <Button disabled={isSubmitting} variant="warning" onClick={() => handleDeleteRegistration(r._id)}>
                          {t("Delete")}
                        </Button>
                      </div>
                    </div>
                    <hr />
                  </>
                ))
              ) : (
                <p>{t("No active registrations")}</p>
              )}
            </div>
          </div>
        </ZoneComponent>
        <ZoneComponent style={{ marginBottom: "0px" }}>
          <div>
            <h2>{t("Reader locations")}</h2>
            <p>{t("You must add location information in order to use the Stripe readers")}</p>
          </div>
          <hr />
          <div style={{}}>
            {locations?.map(l => (
              <>
                <div key={l.id} style={{ display: "flex", justifyContent: "space-between", padding: "var(--gutter) 0" }}>
                  <div>
                    <h3>{l.name}</h3>
                    <AddressComponent address={l.address} config={config} />
                  </div>
                  <div>
                    <Button variant="warning" onClick={() => handleDeleteLocation(l)}>
                      {t("Delete")}
                    </Button>
                  </div>
                </div>
                <hr />
              </>
            ))}
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Typography variant="copy" level="secondary">
                {t("Available locations")}: {Math.abs(locations.length - 3)}/3
              </Typography>
              <div>
                {stripeConfig ? (
                  <Button variant="primary" disabled={locations.length >= 3} onClick={() => modalRef.current.open()}>
                    {t("Add a location")}
                  </Button>
                ) : (
                  <Typography variant="copy" level="normal">
                    {t("You must link your Stripe account in order create locations")}
                  </Typography>
                )}
              </div>
            </div>
          </div>
        </ZoneComponent>
        <ZoneComponent>
          <h2 style={{ marginBottom: "var(--gutter)" }}>{"Paypal"}</h2>
          <p>{t("Display a Paypal.me QR code to your customers during checkout")}</p>
          <form onSubmit={handleSubmit}>
            <div style={{ display: "grid", gridGap: "var(--gutter)" }}>
              <Input
                variant="overZone"
                type="text"
                name="meUrl"
                placeholder="https://paypal.me/..."
                value={dataFromConfig.meUrl || ""}
                onChange={handleDataChange}
              />
              <span>
                <Button type="submit" disabled={!dirty || paypalLoading} variant="primary">
                  {t("Submit")}
                </Button>
              </span>
            </div>
          </form>
        </ZoneComponent>
      </Container>
    </div>
  );
};

export default PointOfSale;
