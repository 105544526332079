import { gql } from "../../__generated__/gql";

export const CAMPAIGN_FIELDS = gql(`
  fragment CampaignFields on Campaign {
    _id
    id
    title
    subject
    status
    created
    uri
    html
    fields {
      type
      name
      value
      description
      required
    }
    entries {
      _id
      listing {
        _id
        id
      }
      item {
        _id
        id
        path
        descriptions {
          main
        }
        data {
          title
          images {
            uri
          }
        }
      }
    }
    entriesCount
    list {
      _id
      title
      count
    }
    template {
      _id
      title
    }
  }
`);

export const GET_CAMPAIGNS = gql(`
  query campaigns ($page: Int) {
    campaigns(page: $page) {
      campaigns {
        ...CampaignFields
      }
      pagination {
        pages
        page
      }
    }
  }
`);

export const GET_CAMPAIGN = gql(`
  query campaign($campaignRef: ID!) {
    campaign(campaignRef: $campaignRef) {
      campaign {
        ...CampaignFields
      }
      metrics {
        sendAt
        deliveryRate
        openRate
        bounceRate
      }
    }
  }
`);

export const POST_CAMPAIGN_CREATE = gql(`
  mutation campaignCreate($title: String!, $listRef: ID) {
    campaignCreate(title: $title, listRef: $listRef) {
      _id
    }
  }
`);

export const POST_CAMPAIGN_UPDATE = gql(`
  mutation campaignUpdate($campaignRef: ID!, $campaignUpdateInput: CampaignUpdateInput!) {
    campaignUpdate(campaignRef: $campaignRef, campaignUpdateInput: $campaignUpdateInput) {
      ...CampaignFields
    }
  }
`);

export const POST_CAMPAIGN_DELETE = gql(`
  mutation campaignDelete($campaignRef: ID!) {
    campaignDelete(campaignRef: $campaignRef)
  }
`);

export const POST_CAMPAIGN_TEST_EMAIL = gql(`
  mutation campaignTestEmail($campaignRef: ID!, $emails: [String!]!) {
    campaignTestEmail(campaignRef: $campaignRef, emails: $emails)
  }
`);

export const POST_CAMPAIGN_SEND = gql(`
  mutation campaignSend($campaignRef: ID!, $sendAt: Date) {
    campaignSend(campaignRef: $campaignRef, sendAt: $sendAt) {
      ...CampaignFields
    }
  }
`);
