export const BillingStatuses = [
  { value: "Pending", label: "Pending" },
  { value: "Paid", label: "Paid" },
  { value: "Partially Paid", label: "Partially Paid" },
  { value: "Refunded", label: "Refunded" },
  { value: "Partially Refunded", label: "Partially Refunded" }
];

export const ShippingStatuses = [
  { value: "Pending", label: "Pending" },
  { value: "Shipped", label: "Shipped" },
  { value: "Collected", label: "Collected" }
];

export const OrderStatuses = [
  { value: "New Order", label: "New Order" },
  { value: "Buyer Contacted", label: "Buyer Contacted" },
  { value: "Invoice Sent", label: "Invoice Sent" },
  { value: "Payment Pending", label: "Payment Pending" },
  { value: "Payment Received", label: "Payment Received" },
  { value: "Shipped", label: "Shipped" },
  { value: "Refund Sent", label: "Refund Sent" },
  { value: "Cancelled", label: "Cancelled" },
  {
    value: "Cancelled (Non-Paying Buyer)",
    label: "Cancelled (Non-Paying Buyer)"
  },
  {
    value: "Cancelled (Item Unavailable)",
    label: "Cancelled (Item Unavailable)"
  },
  {
    value: "Cancelled (Per Buyer's Request)",
    label: "Cancelled (Per Buyer's Request)"
  },
  { value: "Merged", label: "Merged" }
];

export const OrderCancelStatuses = [
  {
    value: "Cancelled (Non-Paying Buyer)",
    label: "Cancelled (Non-Paying Buyer)"
  },
  {
    value: "Cancelled (Item Unavailable)",
    label: "Cancelled (Item Unavailable)"
  },
  {
    value: "Cancelled (Per Buyer's Request)",
    label: "Cancelled (Per Buyer's Request)"
  }
];

export const OrderOrigin = [
  { value: "Online", label: "Online" },
  { value: "Shop", label: "Shop" },
  { value: "Discogs", label: "Discogs" },
  { value: "Bandcamp", label: "Bandcamp" }
];
