import React from "react";
import { Link } from "react-router-dom";
import { GoDotFill } from "react-icons/go";
import Price from "../common/price";
import { Config, Item } from "../../__generated__/graphql";
import { getPlaceholderImage } from "../../utils";

export default function Tile({ entry: item, config }: { entry: Item; config: Config }) {
  const preTaxes = config.taxes?.rules?.editPricesBeforeTaxes;

  return (
    <div className="tile">
      <Link to={item.path}>
        <img src={item.data.images && item.data.images.length ? item.data.images[0].uri : getPlaceholderImage(config)} />
      </Link>
      <div className="listings">
        {item.listings.map(l => (
          <div key={l.id} className="listing">
            <div className={`indicator ${l.stock.quantity <= 0 ? "soldOut" : "inStock"}`}>
              <GoDotFill />
            </div>
            <p>
              {l.status === "private" ? <i className="cg-icon-padlock" /> : null}
              {/* eslint-disable-next-line i18next/no-literal-string */}
              {l.stock.quantity}x <Price value={(preTaxes ? l.prices.beforeTaxes : l.prices.sale) as number} />
            </p>
          </div>
        ))}
      </div>
      {item.type === "ReleaseItem" ? (
        <>
          <p className="artistName">
            {item.data.artists ? (
              <Link to={`/catalogue?artists=${item.data.artists[0].id}`}>{item.data.artists[0].anv || item.data.artists[0].name}</Link>
            ) : null}
          </p>
          <p>
            <Link to={item.path}>{item.data.title} </Link>
            <br />
            {item.data?.labels?.map((l, i) => (
              <span key={i}>
                {l.name} - {l.catno}
              </span>
            ))}
          </p>
        </>
      ) : item.type === "ProductItem" ? (
        <div className="manufacturerAndTitle">
          <p className="manufacturer">{item.data.manufacturer}</p>
          <p>{item.data.title}</p>
        </div>
      ) : item.type === "BookItem" ? (
        <Link to={item.path}>
          <p>{item.data.authors?.join(",")}</p>
          <p className="">
            {item.data.title} {item.data.subtitle}
          </p>
        </Link>
      ) : null}
    </div>
  );
}
