import { gql } from "../../__generated__/gql";

gql(`
  fragment MessageFields on Message {
    _id
    created
    sender {
      name
      alias
      email
      ref
    }
    attachments {
      _id
      uri
      title
    }
    subject
    message
    fromBuyer
    content
    read
    orderId
    orderIncId
    uri
    via
  }
`);

export const POST_MESSAGE_ARCHIVE = gql(`
  mutation messageArchive($messageRef: ID!) {
    messageArchive(messageRef: $messageRef)
  }
`);

export const POST_MESSAGE = gql(`
  mutation messageCreate($orderRef: ID!, $message: String!, $files: [Upload]) {
    messageCreate(orderRef: $orderRef, message: $message, files: $files) {
      ...MessageFields
    }
  }
`);

export const POST_MESSAGE_MARK_AS_READ = gql(`
  mutation messageMarkAsRead($messageRef: ID!) {
    messageMarkAsRead(messageRef: $messageRef) {
      ...MessageFields
    }
  }
`);

export const GET_MESSAGES = gql(`
  query messages($orderRef: String, $page: Int) {
    messages(orderRef: $orderRef, page: $page) {
      pagination {
        page
        pages
      }
      messages {
        ...MessageFields
      }
    }
  }
`);
