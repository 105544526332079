import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Button from "../styled/button";
import { Button as ButtonV2 } from "../../componentsV2/Button";
import Radio from "../styled/radio";
import SearchBySubmit from "../styled/searchBySubmit";
import Zone from "../styled/zone";
import moment from "moment";
import { Select } from "../styled/select";
import { OrdersPagination } from "../common/pagination";
import DatePicker from "../styled/datePicker";
import OrderTable from "./table";
import { OrderStatuses, OrderOrigin, BillingStatuses, ShippingStatuses } from "../common/orderDefinitions";
import { MeansOfPayments } from "../common/paymentMethods";
import { SearchOrders } from "../global/search";
import URI from "urijs";
import { today, yesterday, thisWeek, last7Days, last30Days, lastWeek, thisMonth, lastMonth } from "../common/dates";
import { GlobalStore } from "../../stores/global";
import { GET_ORDERS, GET_ORDERS_STATISTICS } from "../../graphql/queries/order";
import Reporting from "./reporting";
import { useTranslation } from "react-i18next";
import Loader from "../common/loader";
import * as Sentry from "@sentry/browser";
import { LeftSection, RightSection, SectionHeader } from "../../componentsV2/SectionHeader";
import { SectionContainer } from "../../componentsV2/SectionContainer";
import { AlignLeftContainer } from "../../componentsV2/SectionContainer/SectionContainer.styles";
import { useQuery } from "@apollo/client";
import { OrderStatistics } from "../../componentsV2/pages/Orders/OrdersStatistics";

export interface IOrdersFilters {
  from?: string;
  to?: string;
  itemRef?: string;
  term?: string;
  dateFilter?: string;
  billingStatus?: string[];
  status?: string[];
  shippingStatus?: string[];
  origin?: string[];
  paymentMethods?: string[];
}

export interface IOrdersPagination {
  page: number;
  sort: string;
  order: number;
  pages: number;
}
export default function Orders() {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const { addNotification, config, isBelowIpad } = GlobalStore.useState(c => c);
  const currentUri = new URI(location.pathname + location.search);
  const searchQuery = currentUri.search(true);

  const ranges = [
    { id: "today", title: "Today", ranges: today },
    { id: "yesterday", title: "Yesterday", ranges: yesterday },
    { id: "currentWeek", title: "This Week", ranges: thisWeek },
    { id: "previousWeek", title: "Last Week", ranges: lastWeek },
    { id: "last7Days", title: "Last 7 Days", ranges: last7Days },
    { id: "last30Days", title: "Last 30 Days", ranges: last30Days },
    { id: "currentMonth", title: "This Month", ranges: thisMonth },
    { id: "previousMonth", title: "Last Month", ranges: lastMonth }
  ];

  const dateRange = localStorage.getItem("orders-date-range");

  const filters: IOrdersFilters = {
    from: searchQuery.from || (dateRange ? ranges.find(r => r.id === dateRange)?.ranges.start : null),
    to: searchQuery.to || (dateRange ? ranges.find(r => r.id === dateRange)?.ranges.end : null),
    itemRef: searchQuery.itemRef,
    term: searchQuery.term,
    dateFilter: searchQuery.dateFilter || "created",
    billingStatus: [],
    status: [],
    shippingStatus: [],
    origin: [],
    paymentMethods: []
  };
  const pagination: IOrdersPagination = {
    pages: 0,
    page: parseInt(searchQuery.page || 1),
    sort: searchQuery.sort || localStorage.getItem(`${config?.id}-orders-sort`) || "createdDate",
    order: parseInt(searchQuery.order || localStorage.getItem(`${config?.id}-orders-order`) || -1)
  };

  const arrayFilters = ["origin", "status", "paymentMethods", "shippingStatus", "billingStatus"];
  for (const filter of arrayFilters) {
    if (searchQuery[filter]) {
      // @ts-ignore
      if (Array.isArray(searchQuery[filter])) filters[filter] = searchQuery[filter];
      // @ts-ignore
      else filters[filter] = [searchQuery[filter]];
    }
  }

  const { loading, data, error, previousData } = useQuery(GET_ORDERS, {
    fetchPolicy: "cache-and-network",
    variables: { ordersFiltersInput: { ...filters, ...{ page: pagination.page, order: pagination.order, sort: pagination.sort } } }
  });
  const { data: statisticsData } = useQuery(GET_ORDERS_STATISTICS, {
    fetchPolicy: "cache-and-network",
    variables: { ordersFiltersInput: filters }
  });

  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
      addNotification({ ok: 0, message: error.message });
    }
  }, [error]);

  const ordersData = data?.orders || previousData?.orders;
  const orders = ordersData?.orders;
  if (ordersData?.pagination) {
    pagination.page = ordersData.pagination.page;
    pagination.pages = ordersData.pagination.pages;
  }
  const statistics = statisticsData?.ordersStatistics;

  document.title = t("Orders");

  const handleDropdownChange = async (values: any, event: any, name: string) => {
    if (values) currentUri.setSearch({ [name]: values.map((v: any) => v.value) }).removeSearch("page");
    else currentUri.removeSearch(name);
    history.push(currentUri.resource());
  };

  const handleDateChange = async (value: any, name: string) => {
    if (!moment(value).isValid()) return;
    localStorage.setItem(`orders-date-${name}`, moment(value).format());
    currentUri.setSearch(`${name}`, moment(value).format()).removeSearch("page");
    history.push(currentUri.resource());
    localStorage.setItem("orders-date-range", "");
  };

  const handleDateShortcut = async (id: string) => {
    localStorage.setItem("orders-date-range", id);
    currentUri.setSearch("range", id);
    currentUri.removeSearch("from");
    currentUri.removeSearch("to");
    history.push(currentUri.removeSearch("page").resource());
  };

  const handleSearchSubmit = async (term: string) => {
    currentUri.setSearch({ term }).removeSearch("page").removeSearch("itemRef");
    history.push(currentUri.resource());
  };

  const handleRemoveSearchFilter = async () => {
    currentUri.removeSearch("term").removeSearch("itemRef").removeSearch("page");
    history.push(currentUri.resource());
  };

  const handleDateFilterTypeChange = async (e: any) => {
    currentUri.setSearch("dateFilter", e.target.value).removeSearch("page");
    history.push(currentUri.resource());
  };

  if (orders === undefined) return <Loader />;
  return (
    <div id="orders">
      <SectionContainer>
        <SectionHeader title="Orders">
          <LeftSection>
            {!isBelowIpad ? (
              <ButtonV2 type="link" href="/preferences/taxes" variant="secondary" isSettings>
                {t("Settings")}
              </ButtonV2>
            ) : null}
            {config?.discogs.enabled ? (
              <ButtonV2 variant="secondary" href="/discogs/orders" type="link">
                {t("My Discogs orders")}
              </ButtonV2>
            ) : null}
            <Reporting filters={filters} />
          </LeftSection>
          <RightSection>
            <ButtonV2 type="link" variant="primary" href="/order/new">
              {t("Create an order")}
            </ButtonV2>
          </RightSection>
        </SectionHeader>
        <AlignLeftContainer>
          <SearchOrders />
        </AlignLeftContainer>
      </SectionContainer>
      <Zone id="orderFilters">
        <div id="dates" className="column">
          <h2>{t("Date selector")}</h2>
          <div id="dateFilters">
            <div className="dateShortcuts">
              {ranges.map(d => (
                <div key={d.title} className="dateShortcut">
                  <Button
                    variant="secondaryOverZone"
                    className={`dateShortcut ${dateRange === d.id ? "active" : ""}`}
                    onClick={() => handleDateShortcut(d.id)}>
                    {d.title}
                  </Button>
                </div>
              ))}
            </div>
            <div id="dateSelectors">
              <div id="from" className="column-item">
                <DatePicker
                  variant="overZone"
                  value={moment(filters.from).isValid() ? filters.from : null}
                  startDate={moment(filters.from).isValid() ? filters.from : null}
                  endDate={moment(filters.to).isValid() ? filters.to : null}
                  onChange={handleDateChange}
                  label={t("From")}
                  selectsStart
                  className="from"
                  name="from"
                  showTimeSelect
                />
              </div>
              <div id="to" className="column-item">
                <DatePicker
                  variant="overZone"
                  startDate={moment(filters.from).isValid() ? filters.from : null}
                  endDate={moment(filters.to).isValid() ? filters.to : null}
                  value={moment(filters.to).isValid() ? filters.to : null}
                  onChange={handleDateChange}
                  label={t("To")}
                  className="to"
                  name="to"
                  selectsEnd
                  showTimeSelect
                />
              </div>
            </div>
          </div>
          <div id="sortAndOrder" className="column-item">
            <div className="sort">
              <div className="dateRadioInputs">
                <Radio
                  label={t("Filter by")}
                  onChange={handleDateFilterTypeChange}
                  value={filters.dateFilter}
                  entries={[
                    { label: t("Order created date"), value: "created" },
                    { label: t("Order payment date"), value: "billing.paymentDate" }
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="column">
          <div className="column-item">
            <Select
              variant="overZone"
              label={t("Order status")}
              isMulti={true}
              value={
                filters.status
                  ? Array.isArray(filters.status)
                    ? filters.status.map(o => ({ label: o, value: o }))
                    : [{ label: filters.status, value: filters.status }]
                  : null
              }
              options={OrderStatuses.filter(c => c.value !== "" && c !== null)}
              onChange={(a: any, b: any) => handleDropdownChange(a, b, "status")}
              placeholder={t("Filter by order status")}
            />
          </div>
          <div className="column-item">
            <Select
              variant="overZone"
              label={t("Billing status")}
              isMulti={true}
              value={
                filters.billingStatus
                  ? Array.isArray(filters.billingStatus)
                    ? filters.billingStatus.map(o => ({ label: o, value: o }))
                    : [
                        {
                          label: filters.billingStatus,
                          value: filters.billingStatus
                        }
                      ]
                  : null
              }
              options={BillingStatuses.filter(c => c.value !== "" && c !== null)}
              onChange={(a: any, b: any) => handleDropdownChange(a, b, "billingStatus")}
              placeholder={t("Filter by billing status")}
            />
          </div>
          <div className="column-item">
            <Select
              variant="overZone"
              label={t("Shipping status")}
              isMulti={true}
              value={
                filters.shippingStatus
                  ? Array.isArray(filters.shippingStatus)
                    ? filters.shippingStatus.map(o => ({ label: o, value: o }))
                    : [
                        {
                          label: filters.shippingStatus,
                          value: filters.shippingStatus
                        }
                      ]
                  : null
              }
              options={ShippingStatuses.filter(c => c.value !== "" && c !== null)}
              onChange={(a: any, b: any) => handleDropdownChange(a, b, "shippingStatus")}
              placeholder={t("Filter by shipping status")}
            />
          </div>
        </div>
        <div className="column">
          <div className="column-item">
            <Select
              variant="overZone"
              label={t("Order origin")}
              placeholder={t("Filter by order origin")}
              isMulti={true}
              options={OrderOrigin}
              value={
                filters.origin
                  ? Array.isArray(filters.origin)
                    ? filters.origin.map(o => ({ label: o, value: o }))
                    : [{ label: filters.origin, value: filters.origin }]
                  : null
              }
              onChange={(a: any, b: any) => handleDropdownChange(a, b, "origin")}
            />
          </div>
          <div className="column-item">
            <Select
              variant="overZone"
              label={t("Payment methods")}
              isMulti={true}
              value={
                filters.paymentMethods
                  ? Array.isArray(filters.paymentMethods)
                    ? filters.paymentMethods.map(o => ({ label: o, value: o }))
                    : [
                        {
                          label: filters.paymentMethods,
                          value: filters.paymentMethods
                        }
                      ]
                  : null
              }
              options={MeansOfPayments(t).map(m => ({
                label: m.placeholder,
                value: m.origin
              }))}
              onChange={(a: any, b: any) => handleDropdownChange(a, b, "paymentMethods")}
              placeholder={t("Filter by payment methods")}
            />
          </div>
          {filters.itemRef ? (
            <div className="search">
              <SearchBySubmit
                type="text"
                term={filters.itemRef}
                autoComplete="off"
                name="term"
                variant="overZone"
                onSubmit={handleSearchSubmit}
                onClear={handleRemoveSearchFilter}
                placeholder={t("Enter keyword") + "..."}
                label={t("Filter inventory by item reference")}
              />
            </div>
          ) : (
            <SearchBySubmit
              type="text"
              term={filters.term}
              autoComplete="off"
              name="term"
              variant="overZone"
              onSubmit={handleSearchSubmit}
              onClear={handleRemoveSearchFilter}
              placeholder={t("Enter keyword") + "..."}
              label={t("Filter orders by notes")}
            />
          )}
        </div>
      </Zone>
      <OrderStatistics stats={statistics} filters={filters} />
      <OrdersPagination sorting={true} pagination={pagination} statistics={statistics} config={config} currentUri={currentUri} />
      <div id="orderEntries">{loading ? <Loader /> : <OrderTable orders={orders} />}</div>
      {pagination.pages ? <OrdersPagination config={config} sorting={false} pagination={pagination} currentUri={currentUri} /> : null}
    </div>
  );
}
