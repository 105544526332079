import React, { useRef, useState } from "react";
import Radio from "./styled/radio";
import DatePicker from "./styled/datePicker";
import Modal, { PropsToForward } from "./modal";
import moment from "moment";
import { useMutation } from "@apollo/client";
import { MdReportProblem } from "react-icons/md";
import { POST_INVENTORY_EXPORT } from "../graphql/queries/item";
import { useTranslation } from "react-i18next";
import { Button } from "../componentsV2/Button";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import styled from "styled-components";
import { InventoryStats } from "../__generated__/graphql";
import { ModalHeaderContainer } from "../componentsV2/SectionHeader/SectionHeader.styles";
import { Typography } from "../componentsV2/Typography";
import { useHistory } from "react-router-dom";
import { AddNotification } from "../types/globals";

interface ExportButtonFilters {
  stock?: any;
  type?: any;
  status?: any;
  discogsStatus?: any;
  hasSnippet?: any;
  sort?: any;
  dateFilter?: any;
  order?: any;
  supplierCode?: any;
  condition?: any;
  secondHand?: any;
  preOrder?: any;
  location?: any;
  locationSearch?: any;
  categories?: any;
  labels?: any;
  itemId?: any;
  term?: any;
  from?: any;
  to?: any;
}

export const SpaceBetweenContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const ExportButton = ({
  addNotification,
  stats,
  filters
}: {
  addNotification: AddNotification;
  stats?: InventoryStats | null;
  filters: ExportButtonFilters;
}) => {
  const { t } = useTranslation();
  const modalRef: any = useRef<PropsToForward>();
  const [dateType, setDateType] = useState<string | null>(null);
  const [dates, setDates] = useState({ from: null, to: null });
  const [exportInventory] = useMutation(POST_INVENTORY_EXPORT);
  const [isSubmittingJob, setIsSubmittingJob] = useState(false);
  const [index, setIndex] = useState(0);
  const history = useHistory();

  const handleExportInventory = async () => {
    setIsSubmittingJob(true);
    try {
      const query = { ...filters } as any;
      if (dateType === "created") {
        query.salesCreatedDateFrom = dates.from ? moment(dates.from).format() : undefined;
        query.salesCreatedDateTo = dates.to ? moment(dates.to).format() : undefined;
      } else if (dateType === "payment") {
        query.salesPaymentDateFrom = dates.from ? moment(dates.from).format() : undefined;
        query.salesPaymentDateTo = dates.to ? moment(dates.to).format() : undefined;
      }
      await exportInventory({ variables: { type: index === 0 ? "inventory" : "sales", filters: query } });
      addNotification({ ok: 1, message: t("Your export file will become available to download shortly") });
      history.push("/jobs");
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    } finally {
      setIsSubmittingJob(false);
    }
  };

  const handleDateChange = (value: string, name: string) => {
    setDates({ ...dates, [name]: value });
  };

  const handleDateTypeChange = (e: any) => {
    setDateType(e.target.value || null);
  };

  return (
    <div className="exportButton">
      <Modal ref={modalRef} style={{}}>
        <ModalHeaderContainer>
          <Typography variant="pageTitle" tag="h2">
            {t("Export")}
          </Typography>
          <button className="reset" type="button" onClick={() => modalRef.current.close()}>
            <i className="cg-icon-burger-close" />
          </button>
        </ModalHeaderContainer>
        <Tabs className="tabView" selectedIndex={index} onSelect={index => setIndex(index)}>
          <TabList className="tabList">
            <Tab className={"tab react-tabs__tab"}>
              <span>{t("Export listings")}</span>
            </Tab>
            <Tab className={"tab react-tabs__tab"}>
              <span>{t("Export sales for listings")}</span>
            </Tab>
          </TabList>
          <TabPanel>
            <div>
              <h2>
                {t("{{listingCount}} {{listings}} will be exported in CSV format", {
                  listingCount: stats?.listingsCount,
                  listings: stats?.listingsCount && stats?.listingsCount > 1 ? t("listings") : t("listing")
                })}
              </h2>
              <p>{t("To open the generated CSV file, a spreadsheet software is required")}</p>
              <SpaceBetweenContainer className="footer" style={{ marginTop: "150px" }}>
                <Button variant="secondary" onClick={() => modalRef.current?.close()}>
                  {t("Cancel")}
                </Button>
                <Button variant="primary" disabled={isSubmittingJob} onClick={handleExportInventory}>
                  {t("Export listings")}
                </Button>
              </SpaceBetweenContainer>
            </div>
          </TabPanel>
          <TabPanel>
            <div>
              <div className="header">
                <h2>
                  {t("Sales for {{listingCount}} {{listings}} will be exported in CSV format", {
                    listingCount: stats?.listingsCount,
                    listings: stats?.listingsCount && stats?.listingsCount > 1 ? t("listings") : t("listing")
                  })}
                </h2>
              </div>
              <div className="content" style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                <div>
                  <Radio
                    label={t("Filter by")}
                    onChange={handleDateTypeChange}
                    value={dateType}
                    entries={[
                      { label: t("Do not filter by date"), value: null },
                      { label: t("Order created date"), value: "created" },
                      { label: t("Order payment date"), value: "payment" }
                    ]}
                  />
                  {dateType === "payment" ? (
                    <small className="danger">
                      <MdReportProblem />
                      {t("Exporting paid orders only")}
                    </small>
                  ) : null}
                </div>
                {dateType !== null ? (
                  <div>
                    <DatePicker
                      style={{ marginBottom: "var(--gutter)" }}
                      dateFormat="dd/MM/yyyy hh:mm"
                      showTimeSelect={true}
                      required={true}
                      label={dateType === "created" ? t("Orders created from") : t("Orders paid from")}
                      onChange={(e: any) => handleDateChange(e, "from")}
                      value={dates.from ? moment(dates.from) : null}
                    />
                    <DatePicker
                      label={dateType === "created" ? t("Orders created to") : t("Orders paid to")}
                      dateFormat="dd/MM/yyyy hh:mm"
                      showTimeSelect={true}
                      onChange={(e: any) => handleDateChange(e, "to")}
                      value={dates.to ? moment(dates.to) : null}
                    />
                  </div>
                ) : null}
              </div>
              <SpaceBetweenContainer className="footer" style={{ marginTop: "150px" }}>
                <Button variant="secondary" onClick={() => modalRef.current?.close()}>
                  {t("Cancel")}
                </Button>
                <Button variant="primary" disabled={isSubmittingJob} onClick={handleExportInventory}>
                  {t("Export sales for listings")}
                </Button>
              </SpaceBetweenContainer>
            </div>
          </TabPanel>
        </Tabs>
      </Modal>
      {(stats?.listingsCount || 0) > 0 ? (
        <Button variant={"primary"} onClick={() => modalRef.current.open()}>
          {t("Export")}
        </Button>
      ) : null}
    </div>
  );
};

export default ExportButton;
