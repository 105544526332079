import React from "react";
import { StyledTypography } from "./Typography.styles";

export type TypographyLevel = "info" | "warning" | "success" | "highlight" | "secondary" | "normal";
export type TypographyVariant = "copy" | "subTitle" | "secondaryTitle" | "pageTitle";

type Props = {
  children: React.ReactNode;
  tag?: "h1" | "h2" | "h3" | "p" | "span";
  level?: TypographyLevel;
  variant: TypographyVariant;
  style?: React.CSSProperties;
};

export const Typography: React.FC<Props> = ({ children, tag = "span", level = "normal", variant, style }) => {
  return (
    <StyledTypography as={tag} level={level} variant={variant} style={style}>
      {children}
    </StyledTypography>
  );
};
