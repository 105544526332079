import React, { useState, useEffect } from "react";
import Input from "../../styled/input";
import Zone from "../../styled/zone";
import TextArea from "../../styled/textArea";
import moment from "moment";
import Loader from "../../common/loader";
import { FaEnvelope, FaExclamationTriangle } from "react-icons/fa";
import { AiOutlineUser } from "react-icons/ai";
import { SelectTemplate } from "../../common/select";
import TrackVisibility from "react-on-screen";
import { POST_MESSAGE, GET_MESSAGES, POST_MESSAGE_MARK_AS_READ } from "../../../graphql/queries/message";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { GET_TEMPLATE } from "../../../graphql/queries/template";
import TemplateEditor from "../../templates/editor";
import { AddNotification } from "../../../types/globals";
import { Message as IMessage, Order, Template } from "../../../__generated__/graphql";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { Button } from "../../../componentsV2/Button";

export default ({ order, addNotification }: { order: Order; addNotification: AddNotification }) => {
  const { data, loading, refetch } = useQuery(GET_MESSAGES, { fetchPolicy: "cache-and-network", variables: { orderRef: order._id } });
  const [sendingMessage, setSendingMessage] = useState(false);
  const [addMessage] = useMutation(POST_MESSAGE);

  const { t } = useTranslation();

  const messages = data && data.messages.messages;

  const handleAddMessage = async ({ form, content }: { form: any; content: string }) => {
    setSendingMessage(true);
    if (!content) return;
    try {
      await addMessage({
        variables: {
          orderRef: order._id,
          message: content,
          files: form.target?.files?.files
        }
      });
      await refetch();
      addNotification({ ok: 1, message: "Message sent" });
    } catch (e: any) {
      addNotification({ ok: 0, message: e.data || e.toString() });
    }
    setSendingMessage(false);
  };

  if (!data) return <Loader />;

  return (
    <section id="messagesContainer">
      <div id="messagesHeader">
        <h2>{t("Messages")}</h2>
      </div>
      {order.buyer && order.buyer.email && !order.buyer._id ? (
        <p className="guestWarning">
          <FaExclamationTriangle />
          {t("The buyer for this order isn't a registered user.")}
          <br />
          {t("Messages will still be sent to their email address but they wont be able to login and respond from the site.")}
          <br />
          <a href={`mailto:${order.buyer.email}`}>{t("Contact them by email instead")}</a>
        </p>
      ) : (
        <>
          {!order.buyer || !order.buyer.email ? (
            <p className="guestWarning">
              <FaExclamationTriangle /> {t("To send a message, first assign a customer from the billing section")}
            </p>
          ) : null}
        </>
      )}
      <NewMessage
        key={messages?.length || "0"}
        order={order}
        handleAddMessage={handleAddMessage}
        addNotification={addNotification}
        sendingMessage={sendingMessage}
        t={t}
      />
      {messages && messages.length ? <hr /> : null}
      <div id="orderMessages">
        {!loading && messages && messages.length ? (
          messages.map((m, index) => (
            <Zone inverted={!m.fromBuyer} key={m._id || index} index={index}>
              <TrackVisibility className={`messageTracker ${m.fromBuyer ? "fromBuyer" : ""}`} once>
                {/* @ts-expect-error */}
                <Message message={m} order={order} addNotification={addNotification} />
              </TrackVisibility>
            </Zone>
          ))
        ) : loading ? (
          <Loader />
        ) : messages && !messages.length ? null : null}
      </div>
    </section>
  );
};

export const Message = ({
  message,
  order,
  isVisible,
  addNotification
}: {
  message: IMessage;
  order: Order;
  isVisible: boolean;
  addNotification: AddNotification;
}) => {
  const [markAsRead] = useMutation(POST_MESSAGE_MARK_AS_READ, { variables: { messageRef: message._id } });

  const handleMarkAsRead = async () => {
    try {
      await markAsRead();
    } catch (error: any) {
      addNotification({ ok: 0, message: error.toString() });
    }
  };

  useEffect(() => {
    if (isVisible && !message.read) {
      handleMarkAsRead();
    }
  }, [order.origin, isVisible, message]);

  const m = message;
  return (
    <div key={m._id} className="message">
      <p className="header">
        {m.fromBuyer ? <AiOutlineUser /> : null}
        {m.sender?.name || m.sender?.email} - {moment(m.created).format("llll")} ({moment(m.created).fromNow()})
        {m.read ? "" : <FaEnvelope />}
      </p>
      <p className="content" dangerouslySetInnerHTML={{ __html: m.content }} />
      <div className="attachments">
        {message.attachments && message.attachments.length
          ? message.attachments.map(a => (
              <div key={a._id} className="attachment">
                <i className="cg-icon-download" />
                {a.uri ? (
                  <a target="_blank" href={a.uri} rel="noreferrer">
                    {a.title}
                  </a>
                ) : null}
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

export const NewMessage = ({
  order,
  handleAddMessage,
  sendingMessage,
  addNotification,
  t
}: {
  order: Order;
  handleAddMessage: any;
  sendingMessage: boolean;
  addNotification: AddNotification;
  t: TFunction;
}) => {
  const [newMessageContent, setNewMessageContent] = useState("");
  const maxAllowedSize = 4 * 1024 * 1024;
  const [getTemplate] = useLazyQuery(GET_TEMPLATE, { fetchPolicy: "cache-and-network" });

  const handleNewMessageChange = (event: any) => {
    setNewMessageContent(event.target.value);
  };

  const handleTemplateMessageSelect = (template: Template) => {
    getTemplate({ variables: { templateRef: template._id } })
      .then(({ data }) => {
        if (data?.template?.data.content) setNewMessageContent(template.data.content);
      })
      .catch(e => addNotification({ ok: 0, message: e.message }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (e.target?.files?.files) {
      const totalSize: number = Array.from(e.target.files.files).reduce((a: number, b: any) => a + b.size, 0);
      if (totalSize > maxAllowedSize) return addNotification({ ok: 0, message: "Max total attachments size should be less than 4MB" });
    }
    try {
      await handleAddMessage({ form: e, content: newMessageContent });
      setNewMessageContent("");
    } catch (error: any) {
      addNotification({ ok: 0, message: error.toString() });
    }
  };

  const sendDisabled = !order.buyer || !order.buyer.email || sendingMessage;
  const canSendMessage = !sendDisabled && newMessageContent;

  return (
    <>
      <form id="newMessage" onSubmit={handleSubmit}>
        <div className="templateControls">
          <SelectTemplate placeholder={t("Select a template")} type="message" onChange={handleTemplateMessageSelect} />
          <span>
            <TemplateEditor type="message" />
          </span>
        </div>
        <TextArea
          disabled={!order.buyer || !order.buyer.email || sendingMessage}
          rows="8"
          required
          onChange={handleNewMessageChange}
          value={newMessageContent}
        />
        {order.origin !== "Discogs" && order.buyer?._id ? (
          <div className="file">
            <label>
              {/* eslint-disable-next-line i18next/no-literal-string */}
              {t("Attach files")} (4MB max)
              <Input disabled={!order.buyer || !order.buyer.email || sendingMessage} type="file" name="files" multiple />
            </label>
          </div>
        ) : null}
        <div>
          <Button variant="primary" disabled={!canSendMessage} type="submit">
            {sendingMessage ? <Loader size={5} /> : t("Send message")}
          </Button>
        </div>
      </form>
    </>
  );
};
