import React from "react";
import moment from "moment";
import Liner from "../common/liner";
import Loader from "../common/loader";
import { useQuery } from "@apollo/client";
import { GET_LOGS_FOR_LISTING } from "../../graphql/queries/log";
import { Listing, LogEntry } from "../../__generated__/graphql";
import { useTranslation } from "react-i18next";

export default function History({ logs }: { logs: LogEntry[] }) {
  const { t } = useTranslation();
  return (
    <div className="history">
      {!logs.length ? (
        <p className="entry">{t("No events were found")}</p>
      ) : (
        <>
          {logs.map((l, index) => (
            <Liner index={index + 1} key={l.date} className={`entry ${l.action}`}>
              <p className="nameAndDate">
                <span>{l.user ? l.user.name : t("Unknown")}</span>
                <span title={moment(l.date).format("llll")}>{moment(l.date).fromNow()}</span>
              </p>
              <p className="description">{l.description}</p>
            </Liner>
          ))}
        </>
      )}
    </div>
  );
}

const ListingHistory = ({ listing }: { listing: Listing }) => {
  const { loading, data: logsData } = useQuery(GET_LOGS_FOR_LISTING, {
    fetchPolicy: "cache-and-network",
    variables: { page: 1, ref: listing._id }
  });
  const { t } = useTranslation();

  const logsConnection = logsData && logsData.itemLogsConnection;
  if (!logsConnection?.logs || loading) return <Loader />;

  return (
    <div className="history">
      {process.env.logsConnection ? (
        <div className="header">
          <h3>
            {logsConnection.logs.length} {t("stock movements")}
          </h3>
        </div>
      ) : null}
      {!logsConnection.logs.length ? (
        <p className="entry">{t("No events were found")}</p>
      ) : (
        <>
          {logsConnection.logs.map((l, index) => (
            <Liner index={index + 1} key={l._id} className="entry">
              <p className="nameAndDate">
                <span>{l.user ? l.user.name : "Unknown"}</span>
                <span title={moment(l.created).format("llll")}>{moment(l.created).fromNow()}</span>
              </p>
              <p className="description">{l.content}</p>
            </Liner>
          ))}
        </>
      )}
    </div>
  );
};

export { ListingHistory };
