import React, { useState, useRef } from "react";
import { Button } from "../../../componentsV2/Button";
import Input from "../../styled/input";
import Radio from "../../styled/radio";
import Checkbox from "../../styled/checkbox";
import Modal from "../../modal";
import { Select } from "../../styled/select";
import { OrderCancelStatuses } from "../../common/orderDefinitions";
import { MeansOfPayments } from "../../common/paymentMethods";
import { PriceInput } from "../../common/price";
import { Config, Order } from "../../../__generated__/graphql";
import { TFunction } from "i18next";
import { ModalHeaderContainer } from "../../../componentsV2/SectionHeader/SectionHeader.styles";
import { Typography } from "../../../componentsV2/Typography";

export default function Cancel({ order, cancel, config, t }: { order: Order; cancel: any; config: Config; t: TFunction }) {
  const modalRef = useRef<any>();
  const [isCancelling, setIsCancelling] = useState(false);
  const [refundSelect, setRefundSelect] = useState("norefund");
  const [refundAmount, setRefundAmount] = useState<number | undefined>();
  const [restoreStockQuantities, setRestoreStockQuantities] = useState(false);
  const [refundOrigin, setRefundOrigin] = useState(() => ({
    label: MeansOfPayments(t)[0].placeholder,
    value: MeansOfPayments(t)[0].origin
  }));

  const [discogsReason, setDiscogsReason] = useState("Cancelled (Item Unavailable)");

  const handleRefundReasonChange = (e: any) => {
    setRefundSelect(e.target.value);
  };

  const handleSubmitCancel = async (e: any) => {
    e.preventDefault();
    setIsCancelling(true);
    const refundType = e.target.refund.value;
    const discogsReason = e.target.discogsReason ? e.target.discogsReason.value : null;
    const dataToSend: any = { note: e.target.note.value, relist: restoreStockQuantities };
    if (discogsReason) dataToSend.discogsReason = discogsReason;
    if (e.target.refundAmount) dataToSend.refundAmount = parseFloat(e.target.refundAmount.value);
    if (refundOrigin) dataToSend.refundOrigin = refundOrigin.value;
    if (refundType === "fullrefund") dataToSend.refundStatus = "Refunded";
    else if (refundType === "partialrefund") dataToSend.refundStatus = "Partially Refunded";
    await cancel(dataToSend); // Refresh will close modal
    setIsCancelling(false);
  };

  return (
    <section id="cancel">
      <Modal style={{}} ref={modalRef}>
        <div id="orderModal" className="cancelOrder">
          <ModalHeaderContainer>
            <Typography variant="pageTitle" tag="h2">
              {t("Cancel order")}
            </Typography>
            <button className="reset" type="button" onClick={() => modalRef.current.close()}>
              <i className="cg-icon-burger-close" />
            </button>
          </ModalHeaderContainer>
          <hr />
          <form onSubmit={handleSubmitCancel}>
            <Checkbox
              checked={restoreStockQuantities}
              onChange={(e: any) => setRestoreStockQuantities(e.target.checked)}
              label={t("Also restore item stock quantities")}
              name="relist"
            />
            <hr />
            <Radio
              name="refund"
              onChange={handleRefundReasonChange}
              value={refundSelect}
              entries={[
                { label: t("Order was not refunded"), value: "norefund" },
                { label: t("Order was fully refunded"), value: "fullrefund" }
              ]}
            />
            <div id="refundAmountAndOrigin">
              {refundSelect === "partialrefund" ? (
                <PriceInput
                  name="refundAmount"
                  type="number"
                  step="0.01"
                  required
                  min="0"
                  max={String(order.totals.grand)}
                  currencySymbol={config.currencySymbol as string}
                  position={config.currencySymbolPosition as string}
                  placeholder={t("Amount")}
                  value={refundAmount}
                  onChange={(e: any) => setRefundAmount(e.target.value)}
                />
              ) : null}
              {refundSelect === "fullrefund" ? <input name="refundAmount" type="hidden" value={order.totals.grand} /> : null}
              {refundSelect === "fullrefund" || refundSelect === "partialrefund" ? (
                <Select
                  name="refundOrigin"
                  type="hidden"
                  placeholder={t("Refund method")}
                  onChange={(choice: any) => setRefundOrigin(choice)}
                  value={refundOrigin}
                  options={MeansOfPayments(t).map(o => ({ label: o.placeholder, value: o.origin }))}
                />
              ) : null}
            </div>
            {order.origin === "Discogs" ? (
              <div id="discogsModalStatuses">
                <hr />
                <div className="header">
                  <img alt="header" src="https://static.common-ground.io/common/media/dicsogs.png" />
                  <h3>{t("Select a reason for cancelling this Discogs order")}</h3>
                </div>
                {OrderCancelStatuses.map(s => (
                  <label key={s.label}>
                    <input
                      type="radio"
                      name="discogsReason"
                      required
                      value={s.value}
                      onChange={(e: any) => setDiscogsReason(e.target.value)}
                      checked={discogsReason === s.label}
                    />
                    {s.label}
                  </label>
                ))}
              </div>
            ) : null}
            <hr />
            <Input label={t("Add a cancellation note")} type="text" placeholder={t("Add a note") + "..."} name="note" />
            <Button type="submit" variant="primary">
              {t("Submit")}
            </Button>
            <small style={{ marginTop: "var(--gutter)" }}>
              {t("Note: Cancelling an order is irreversible but you may edit the refund methods from the billing section at any time")}
            </small>
          </form>
        </div>
      </Modal>
      <Button variant="warning" disabled={isCancelling} onClick={() => modalRef.current.open()}>
        {t("Cancel order")}
      </Button>
    </section>
  );
}
