import React, { useState, useRef } from "react";
import Button from "../styled/button";
import Zone from "../styled/zone";
import Input from "../styled/input";
import { Link, useHistory } from "react-router-dom";
import { GlobalStore } from "../../stores/global";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Loader from "../common/loader";
import { useMutation, useQuery } from "@apollo/client";
import { POST_TEMPLATE_SET_PRIMARY, GET_TEMPLATES, POST_TEMPLATE_CREATE, POST_TEMPLATE_UPGRADE } from "../../graphql/queries/template";
import Modal from "../../components/modal";
import { Select } from "../styled/select";
import { templateTypes } from "./single";
import clone from "clone";
import moment from "moment";
import { Template } from "../../__generated__/graphql";
import { AddNotification } from "../../types/globals";
import { Button as ButtonV2 } from "../../componentsV2/Button";
import { Typography } from "../../componentsV2/Typography";
import { useTranslation } from "react-i18next";
import { ModalHeaderContainer } from "../../componentsV2/SectionHeader/SectionHeader.styles";

export default function Templates() {
  const { addNotification } = GlobalStore.useState(c => c);
  const history = useHistory();
  const { t: translate } = useTranslation();

  const [index, setIndex] = useState(0);
  document.title = translate("Templates");
  const modalRef = useRef<any>();

  const automatedTypes = [
    { value: "order-invoice-html-pdf", label: translate("Order Invoice - html/pdf") },
    { value: "credit-invoice-html-pdf", label: translate("Credit Invoice - html/pdf") },
    { value: "order-receipt-html", label: translate("Order email receipt - html") },
    { value: "invoice-receipt-html", label: translate("Invoice email receipt - html") },
    { value: "message-receipt-html", label: translate("Message email receipt - html") },
    { value: "shipping-receipt-html", label: translate("Shipping email receipt - html") },
    { value: "backinstock-receipt-html", label: translate("Back in stock receipt - html") },
    { value: "account-creation-receipt-html", label: translate("Account creation - html") }
  ];

  const otherTypes = [
    { value: "newsletter-html", label: translate("Newsletter - html") },
    { value: "sticker-html", label: translate("Sticker - html") }
  ];

  const [setAsPrimary] = useMutation(POST_TEMPLATE_SET_PRIMARY);
  const [createTemplate] = useMutation(POST_TEMPLATE_CREATE);
  const { data, loading, refetch } = useQuery(GET_TEMPLATES, { fetchPolicy: "cache-and-network" });
  const [isCreatingTemplate, setIsCreatingTemplate] = useState(false);
  const [selectedType, setSelectedType] = useState<string>();
  const [newTemplateTitle, setNewTemplateTitle] = useState<string>("");
  const templates = data?.templatesConnection?.templates ? clone(data?.templatesConnection?.templates) : null;

  const handleSetAsPrimary = async (ref: string) => {
    try {
      await setAsPrimary({ variables: { templateRef: ref } });
      refetch();
      addNotification({ ok: 1, message: translate("Updated") });
    } catch (error: any) {
      addNotification({ ok: 0, message: error.toString() });
    }
  };

  const handleCreateTemplate = async (e: any) => {
    e.preventDefault();
    try {
      setIsCreatingTemplate(true);
      const title = e.target.title.value;
      const { data } = await createTemplate({ variables: { type: selectedType, title, data: {}, fromDefault: true } });
      if (data?.templateCreate) history.push(`/template/${data.templateCreate._id}/edit`);
    } catch (error: any) {
      addNotification({ ok: 0, message: e.toString() });
    } finally {
      setIsCreatingTemplate(false);
    }
  };

  const handleSelectChange = (option: any) => {
    setSelectedType(option.value);
  };

  if (!templates || loading) return <Loader withMargins />;

  const entries = [
    { title: translate("Automated emails & PDF"), id: "automated" },
    { title: translate("Stickers & Newsletters"), id: "other" }
  ];

  const selectedTemplateTypeOption = (selectedType && templateTypes(translate).find(t => t.value === selectedType)) || null;

  return (
    <div id="templates">
      <Modal style={{}} ref={modalRef}>
        <div id="newTemplateModal">
          <form onSubmit={handleCreateTemplate}>
            <ModalHeaderContainer>
              <Typography variant="pageTitle" tag="h2">
                {translate("Create a template")}
              </Typography>
              <button className="reset" type="button" onClick={() => modalRef.current.close()}>
                <i className="cg-icon-burger-close" />
              </button>
            </ModalHeaderContainer>
            <div className="content">
              <Select
                variant="overZone"
                label={translate("Template type")}
                value={selectedTemplateTypeOption}
                onChange={handleSelectChange}
                options={templateTypes(translate)}
              />
              <Input
                disabled={isCreatingTemplate}
                label={translate("Enter a title")}
                type="text"
                name="title"
                autoComplete="off"
                required
                value={newTemplateTitle}
                onChange={(e: any) => setNewTemplateTitle(e.target.value)}
              />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <span />
                <ButtonV2 variant="primary" disabled={isCreatingTemplate || !newTemplateTitle} type="submit">
                  {isCreatingTemplate ? <Loader /> : translate("Submit")}
                </ButtonV2>
              </div>
            </div>
          </form>
        </div>
      </Modal>
      <section className="header">
        <Typography variant="pageTitle" tag="h1">
          {translate("Templates")}
        </Typography>
        <ButtonV2 variant="primary" onClick={() => modalRef.current.open()}>
          {translate("Create a template")}
        </ButtonV2>
      </section>
      <Tabs className="tabView" selectedIndex={index} onSelect={index => setIndex(index)}>
        <TabList className="tabList">
          {entries.map(e => (
            <Tab className={"tab react-tabs__tab"} key={e.id}>
              {e.title}
            </Tab>
          ))}
        </TabList>
        <TabPanel>
          <div className="types">
            {automatedTypes.map(e => (
              <Zone key={e.value} className="typeContainer">
                <div className="type">
                  <div className="header">
                    <h2>{e.label}</h2>
                    <Button
                      onClick={() => {
                        setSelectedType(e.value);
                        modalRef.current.open();
                      }}
                      variant="noStyle">
                      <i className="cg-icon-basket-add" />
                    </Button>
                  </div>
                  <hr />
                  <div className="entries">
                    {templates
                      .filter(t => t.type === e.value)
                      .map(t => (
                        <div key={t._id} className={`template ${t.type}`}>
                          <p className="title">
                            <span>
                              {t.title} {"- v"}
                              {t.version}
                            </span>
                            <br />
                            {t.createdBy ? (
                              <span className="creator">
                                {translate("By")} {t.createdBy.name}
                              </span>
                            ) : (
                              <span />
                            )}
                          </p>
                          <div className="actionsContainer">
                            <div className="actions">
                              {t.upgradeAvailable ? <UpgradeAvailable template={t} addNotification={addNotification} /> : null}
                              {t.primary ? (
                                <p>{translate("In use")}</p>
                              ) : (
                                <div>
                                  <Button onClick={() => handleSetAsPrimary(t._id)} variant={t.primary ? "primary" : "secondary"}>
                                    {translate("Use")}
                                  </Button>
                                </div>
                              )}
                              <Link to={`/${t.type === "page" ? "page" : "template"}/${t._id}/edit`}>
                                <Button variant="secondary" type="button">
                                  {translate("Edit")}{" "}
                                </Button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </Zone>
            ))}
          </div>
        </TabPanel>
        <TabPanel>
          <div className="types">
            {otherTypes.map((e, i) => (
              <Zone key={i} className="typeContainer">
                <div className="type">
                  <div className="header">
                    <h2>{e.label}</h2>
                    <Button
                      onClick={() => {
                        setSelectedType(e.value);
                        modalRef.current.open();
                      }}
                      variant="noStyle">
                      <i className="cg-icon-basket-add" />
                    </Button>
                  </div>
                  <hr />
                  <div className="entries">
                    {templates
                      .filter(t => t.type === e.value)
                      .map(t => (
                        <div key={t._id} className={`template ${t.type}`}>
                          <p className="title">
                            <span>{t.title}</span>
                            <br />
                            {t.createdBy ? (
                              <span className="creator">
                                {translate("By")} {t.createdBy.name}
                              </span>
                            ) : (
                              <span />
                            )}
                          </p>
                          <div className="actionsContainer">
                            <div className="actions">
                              {t.upgradeAvailable ? <UpgradeAvailable template={t} addNotification={addNotification} /> : null}
                              <Link to={`/${t.type === "page" ? "page" : "template"}/${t._id}/edit`}>
                                <Button variant="secondary" type="button">
                                  {translate("Edit")}{" "}
                                </Button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </Zone>
            ))}
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
}

const UpgradeAvailable = ({ template, addNotification }: { template: Template; addNotification: AddNotification }) => {
  const modalRef = useRef<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [upgradeTemplate] = useMutation(POST_TEMPLATE_UPGRADE);
  const history = useHistory();
  const { t } = useTranslation();

  const handleUpgrade = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await upgradeTemplate({ variables: { templateRef: template._id } });
      addNotification({ ok: 1, message: t("Template upgraded to latest") });
      history.push("/template/" + template._id + "/edit");
    } catch (error: any) {
      addNotification({ ok: 0, message: error.message });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal style={{}} ref={modalRef}>
        <div id="newTemplateModal">
          <form onSubmit={handleUpgrade}>
            <div className="header">
              <h2>{t("Template upgrade")}</h2>
              <Button variant="noStyle" onClick={() => modalRef.current.close()}>
                {t("Cancel")}
              </Button>
            </div>
            <hr />
            <div className="content">
              <h2>{t("Change logs")}</h2>
              <div className="versions">
                {template.versions
                  ?.sort((v, a) => (v.version < a.version ? 1 : -1))
                  .map((v, idx) => (
                    <div key={v.version} className="version">
                      <h3>
                        {t("Version")} {v.version} {idx === 0 ? " - latest" : ""} {v.version === template.version ? " - current" : ""}{" "}
                        {v.date ? ` - published ${moment(v.date).fromNow()}` : ""}
                      </h3>
                      <p>{v.notes}</p>
                    </div>
                  ))}
              </div>
              <hr />
              <p>
                <i className="cg-icon-warning" /> {t("Upgrading this template will overwrite its content and discard any customization")}
              </p>
              <ButtonV2 variant="primary" disabled={isLoading} type="submit">
                {isLoading ? <Loader /> : t("Upgrade and overwrite")}
              </ButtonV2>
            </div>
          </form>
        </div>
      </Modal>
      <ButtonV2 variant="primary" onClick={() => modalRef.current.open()}>
        {t("Upgrade")}
      </ButtonV2>
    </>
  );
};
