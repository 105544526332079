import React from "react";
import AddRelease from "./Release";
import AddBook from "./AddItem.Book";
import AddProduct from "./AddItem.Product";
import { useLocation } from "react-router-dom";
import URI from "urijs";

const AddItem = () => {
  const location = useLocation();
  const currentUri = new URI(location.pathname);
  const itemType = currentUri.segment()[2];
  return (
    <>{itemType === "release" ? <AddRelease /> : itemType === "book" ? <AddBook /> : itemType === "product" ? <AddProduct /> : null}</>
  );
};

export default AddItem;
