import React, { useState, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Button from "../styled/button";
import Zone from "../styled/zone";
import { SearchUsers } from "../global/search";
import { Select } from "../styled/select";
import UserCreate, { BuyerEditable } from "../common/userCreate";
import Loader from "../common/loader";
import Liner from "../common/liner";
import { Lists } from "./list";
import Modal from "../modal";
import Pagination from "../common/pagination";
import moment from "moment";
import URI from "urijs";
import { GlobalStore } from "../../stores/global";
import {
  POST_USER_VERIFY,
  GET_USERS,
  POST_USER_BUYER_CREATE,
  POST_USER_BUYER_CREATE_ESHOP_ACCOUNT,
  POST_USERS_EXPORT
} from "../../graphql/queries/user";
import { useMutation, useQuery } from "@apollo/client";
import { POST_LIST_ADD, POST_LIST_CREATE, POST_LIST_DELETE, POST_LIST_REMOVE } from "../../graphql/queries/list";
import { Buyer, List } from "../../__generated__/graphql";
import { AddNotification } from "../../types/globals";
import { SectionContainer } from "../../componentsV2/SectionContainer";
import { LeftSection, RightSection, SectionHeader } from "../../componentsV2/SectionHeader";
import { Button as ButtonV2 } from "../../componentsV2/Button";
import { AlignLeftContainer } from "../../componentsV2/SectionContainer/SectionContainer.styles";
import { CreateListButton } from "../../pages/users/list";
import { Typography } from "../../componentsV2/Typography";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { ModalHeaderContainer } from "../../componentsV2/SectionHeader/SectionHeader.styles";
import { CustomerStatistics } from "../../componentsV2/pages/Customers/CustomersStatistics";

export default function Users() {
  const history = useHistory();
  const location = useLocation();
  const modalRef = useRef<any>();
  const { addNotification } = GlobalStore.useState(c => c);
  const [isGeneratingExport, setIsGeneratingExport] = useState(false);
  const currentUri = new URI(location.pathname + location.search);
  const searchQuery = currentUri.search(true);
  const { t } = useTranslation();
  const [addToList, { loading: addingToList }] = useMutation(POST_LIST_ADD);
  const [removeFromList, { loading: removingFromList }] = useMutation(POST_LIST_REMOVE);
  const [createList, { loading: creatingList }] = useMutation(POST_LIST_CREATE);
  const [deleteList, { loading: deletingList }] = useMutation(POST_LIST_DELETE);
  const [createBuyer, { loading: creatingBuyer }] = useMutation(POST_USER_BUYER_CREATE);
  const [exportUsers] = useMutation(POST_USERS_EXPORT);
  const { data: usersData, refetch } = useQuery(GET_USERS, {
    fetchPolicy: "cache-and-network",
    variables: { listRef: searchQuery.listRef, page: parseInt(searchQuery.page || 1) }
  });
  document.title = "Customers & Lists";

  const lists = usersData?.lists.lists;
  const users = usersData?.users;

  const handleAddToList = async (user: Buyer, listRef: string) => {
    if (addingToList) return;
    try {
      await addToList({ variables: { listRef, userRefs: [user._id] } });
      refetch();
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
  };

  const handleRemoveFromList = async (user: Buyer, listRef: string) => {
    if (removingFromList) return;
    try {
      await removeFromList({ variables: { listRef, userRefs: [user._id] } });
      refetch();
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
  };

  const handleCreateList = async (title: string) => {
    if (creatingList) return;
    try {
      await createList({ variables: { title } });
      refetch();
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
  };

  const handleListSelect = async (option: any, event: any) => {
    const newUri = new URI(location.pathname);
    newUri.removeSearch("page");
    if (event.action === "clear") {
      newUri.removeSearch("listRef");
      localStorage.removeItem("users-list-filter");
    } else if (event.action === "select-option") {
      newUri.setSearch({ listRef: option.value._id });
      localStorage.setItem("users-list-filter", JSON.stringify(option.value));
    }
    history.push(newUri.resource());
  };

  const handleDeleteList = async (list: List) => {
    if (deletingList) return;
    try {
      await deleteList({ variables: { listRef: list._id } });
      refetch();
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
  };

  const handleCreateUser = async (userData: BuyerEditable, createEshopAccount = true) => {
    try {
      const { data } = await createBuyer({
        variables: {
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
          telephone: userData.telephone,
          taxNumber: userData.taxNumber,
          organisation: userData.organisation,
          addresses: userData.addresses,
          lists: userData.lists.map(a => a.ref),
          createEshopAccount
        }
      });
      addNotification({ ok: 1, message: "Customer created" });
      refetch();
      modalRef.current.close();
      return data;
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
  };

  const handleExportUsers = async () => {
    setIsGeneratingExport(true);
    try {
      const { data } = await exportUsers({ variables: { listRef: searchQuery.listRef } });
      if (data?.usersExport) {
        const location = new URI(data?.usersExport);
        window.open(location.toString(), "_blank");
      }
    } catch (e: any) {
      addNotification({ ok: 0, message: e.data });
    }
    setIsGeneratingExport(false);
  };

  const selectedList = lists && lists.find(l => l._id === searchQuery.listRef);

  if (lists === undefined || users === undefined) return <Loader />;

  return (
    <div id="users">
      <Modal style={{}} ref={modalRef}>
        <div className="userCreateModal">
          <ModalHeaderContainer>
            <Typography variant="pageTitle" tag="h2">
              {t("Create a customer")}
            </Typography>
            <button className="reset" type="button" onClick={() => modalRef.current.close()}>
              <i className="cg-icon-burger-close" />
            </button>
          </ModalHeaderContainer>
          <div className="content">
            <UserCreate onCreate={handleCreateUser} isCreatingUser={creatingBuyer} lists={lists} />
          </div>
        </div>
      </Modal>
      <SectionContainer>
        <SectionHeader title="Customers & Lists">
          <LeftSection>
            <ButtonV2 type="link" href="/import/customers-add" variant="secondary">
              {t("Import")}
            </ButtonV2>
          </LeftSection>
          <RightSection>
            <CreateListButton onSubmit={handleCreateList} />
            <ButtonV2 onClick={() => modalRef.current.open()} type="button" variant="primary">
              {t("Create a customer")}
            </ButtonV2>
          </RightSection>
        </SectionHeader>
        <AlignLeftContainer>
          <SearchUsers onSelect={(e: any) => e} />
        </AlignLeftContainer>
      </SectionContainer>
      <Zone id="usersFilters">
        <div id="lists">
          <div id="listFilterAndNew">
            <Select
              label="Filter by list"
              variant="overZone"
              onChange={handleListSelect}
              value={
                selectedList
                  ? {
                      label: selectedList.title,
                      value: selectedList._id
                    }
                  : null
              }
              options={(lists || []).map(l => ({ value: l, label: l.title }))}
              name="category"
            />
            {searchQuery.listRef ? (
              <Button variant="secondary" type="button" onClick={() => history.push("/users")}>
                {t("Clear")}
              </Button>
            ) : null}
          </div>
          <div id="listsView">
            {lists ? (
              lists.map(l => (
                <Zone inverted className="list" key={l.id}>
                  <div className="listHeader">
                    <h3>{l.title}</h3>
                  </div>
                  <p>
                    {l.count} {t("contacts")}
                    <br />
                    {t("Created")} {moment(l.created).fromNow()}
                  </p>
                  <Button
                    variant="danger"
                    onClick={() => window.confirm("Are you sure you wish to delete this list?") && handleDeleteList(l)}>
                    {t("Delete")}
                  </Button>
                </Zone>
              ))
            ) : (
              <p>{t("There is no list yet")}.</p>
            )}
          </div>
        </div>
      </Zone>
      <section>
        <CustomerStatistics customers={users?.pagination.count} handleExport={handleExportUsers} isLoading={isGeneratingExport} />
        {users ? (
          <>
            <Pagination pagination={users.pagination} currentUri={currentUri} isCustomer />
            <div id="userTable">
              <div className="userTableEntry header">
                <p className="name">{t("Name / Organization")}</p>
                <p className="email">{t("Email / Telephone")}</p>
                <p className="lastLogin">{t("Status")}</p>
                <p className="lists">{t("Lists")}</p>
              </div>
              {users?.users.map((u, index) => (
                <Liner index={index} className="userTableEntry" key={u._id}>
                  <div className="name">
                    <Link to={u.path || ""}>
                      {u.name}
                      {u.organisation ? (
                        <>
                          <br />
                          {u.organisation}
                        </>
                      ) : null}
                    </Link>
                  </div>
                  <div className="email">
                    <Link to={u.path || ""}>{u.email?.includes("@") ? u.email : ""}</Link>
                    <p className="telephone">{u.telephone || ""}</p>
                  </div>
                  <div className="lastLogin">
                    <UserStatus addNotification={addNotification} user={u} t={t} />
                  </div>
                  <div className="lists">
                    {lists ? <Lists lists={lists} addToList={handleAddToList} removeFromList={handleRemoveFromList} user={u} /> : null}
                  </div>
                </Liner>
              ))}
            </div>
          </>
        ) : (
          <Loader />
        )}
      </section>
      {users && users.pagination && users.users.length ? (
        <Pagination pagination={users.pagination} currentUri={currentUri} isCustomer />
      ) : null}
    </div>
  );
}

const UserStatus = ({ user, addNotification, t }: { user: Buyer; addNotification: AddNotification; t: TFunction }) => {
  const [isVerifying, setIsVerifying] = useState(false);
  const [verify] = useMutation(POST_USER_VERIFY);
  const [isCreating, setIsCreating] = useState(false);
  const [createEshopAccount] = useMutation(POST_USER_BUYER_CREATE_ESHOP_ACCOUNT);

  const handleSendVerificationEmail = async (user: Buyer) => {
    try {
      setIsVerifying(true);
      const { data } = await verify({ variables: { userRef: user._id } });
      if (data?.userVerify) addNotification({ ok: 1, message: data.userVerify });
    } catch (error: any) {
      addNotification({ ok: 0, message: error.message });
    } finally {
      setIsVerifying(false);
    }
  };

  const handleCreateEshopAccount = async (user: Buyer) => {
    try {
      setIsCreating(true);
      await createEshopAccount({ variables: { userRef: user._id } });
      addNotification({ ok: 1, message: "Eshop account created" });
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    } finally {
      setIsCreating(false);
    }
  };

  if (!user.accountCreated)
    return (
      <div>
        <p>{t("Eshop account not created")}</p>
        <Button variant="secondary" disabled={isCreating} onClick={() => handleCreateEshopAccount(user)}>
          {isCreating ? <Loader /> : t("Create Eshop account")}
        </Button>
      </div>
    );
  else if (!user.verification?.status) {
    return (
      <div>
        <p>{t("Email address not verified")}</p>
        <Button variant="secondary" disabled={isVerifying} onClick={() => handleSendVerificationEmail(user)}>
          {isVerifying ? <Loader /> : t("Send verification email")}
        </Button>
      </div>
    );
  } else if (user.accountCreated && user.verification.status) {
    return (
      <p>
        {t("Last login")}: {user.lastLogin ? moment(user.lastLogin).fromNow() : t("Never")}
      </p>
    );
  }
};
