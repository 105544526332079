import React from "react";
import { Link } from "react-router-dom";
import Price from "../common/price";
import Loader from "../common/loader";
import Liner from "../common/liner";
import { GET_CHECKOUTS_WITH_ITEMS } from "../../graphql/queries/checkout";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Typography } from "../../componentsV2/Typography";

export default function OpenCheckouts() {
  const { loading, data } = useQuery(GET_CHECKOUTS_WITH_ITEMS, {
    variables: { origin: "Online", nonEmpty: true },
    fetchPolicy: "cache-and-network"
  });
  const { t } = useTranslation();

  const checkouts = data && data.checkouts?.checkouts;

  if (loading) return <Loader />;
  return (
    <div id="openCheckouts">
      <Typography variant="pageTitle" tag="h1" style={{ marginBottom: "calc(var(--gutter) * 2)" }}>
        {t("Latest Eshop open checkouts")}
      </Typography>
      {checkouts && checkouts.length ? (
        <div id="checkoutEntries">
          {checkouts.map((c, index) => (
            <Liner index={index} key={c.id} className="checkout">
              <div>
                {c.buyer ? (
                  <>
                    {c.buyer._id ? (
                      <Link to={`/user/${c.buyer._id}`}>{c.buyer.name}</Link>
                    ) : (
                      <p>
                        {t("Guest")} - {c.buyer.name}
                      </p>
                    )}
                  </>
                ) : (
                  <p>{t("Guest")}</p>
                )}
                <p>#{c.id}</p>
              </div>
              <div>
                {c.items.map(i => (
                  <div key={i.listing.id} className="item">
                    <Link to={"/item/" + i.item.id}>
                      <p>
                        {/* eslint-disable-next-line i18next/no-literal-string */}
                        {i.quantity}x {i.item.description}
                      </p>
                    </Link>
                  </div>
                ))}
              </div>
              <div>
                <p>
                  <Price value={c.totals.grand} />
                </p>
              </div>
            </Liner>
          ))}
        </div>
      ) : (
        <p>{t("No checkouts were found")}</p>
      )}
    </div>
  );
}
