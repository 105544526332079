import React, { forwardRef } from "react";
import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";
import Picker from "react-datepicker";
import Input from "./input";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const CustomInput = forwardRef((props: { variant?: string; theme?: string; label?: string }) => (
  <Input {...props} variant={props.variant} theme={props.theme} label={props.label} />
));
CustomInput.displayName = "Custom input";

const DatePicker = (props: any) => {
  const { t } = useTranslation();
  const variant = props.variant || "primary";
  return (
    <Container className="cg-common cg-datePicker">
      <Picker
        shouldCloseOnSelect={true}
        dateFormat={props.dateFormat || "dd/MM/yyyy HH:mm"}
        selected={props.value ? new Date(props.value) : undefined}
        startDate={props.startDate ? new Date(props.startDate) : undefined}
        endDate={props.endDate ? new Date(props.endDate) : undefined}
        onChange={v => props.onChange && props.onChange(v, props.name)}
        readOnly={props.readOnly}
        placeholderText={t("Pick a date")}
        customInput={<CustomInput {...props} variant={variant} />}
        showTimeSelect={props.showTimeSelect || false}
      />
    </Container>
  );
};

export default DatePicker;
