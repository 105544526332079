import React from "react";
import getSymbolFromCurrency from "currency-symbol-map";
import { GlobalStore } from "../../stores/global";
import { round } from "@common-ground-io/common-assets/helpers/utils.js";
import InputWithIcon from "../styled/inputWithIcon";
import { getConfigProperty } from "../../utils";
import { Config } from "../../__generated__/graphql";

export const getPriceWithCurrencyString = (config: Config, value: number) => {
  return new Intl.NumberFormat(config.taxes.country.code, { style: "currency", currency: config.currency }).format(value);
};

export default function Price({
  currencySymbol,
  value,
  position: positionProp
}: {
  value: number | string;
  currencySymbol?: string;
  position?: string;
}) {
  const config = GlobalStore.useState(c => c.config);
  const symbol = currencySymbol || config?.currencySymbol;
  const position = positionProp || (config ? getConfigProperty(config, "information", "currencySymbolPosition") : "before");

  return (
    <>
      {position === "before" ? (
        <span className="price">
          {symbol}
          {round(value)}
        </span>
      ) : (
        <span className="price">
          {round(value)}
          {symbol}
        </span>
      )}
    </>
  );
}

export function PriceInConfiCurrency({ config, value, currency }: { config?: Config; value: number; currency?: string }) {
  if (!currency && !config) return null;

  return (
    <span>
      {new Intl.NumberFormat(navigator.language, {
        style: "currency",
        currency: currency || config?.plan.currency,
        maximumFractionDigits: 0
      }).format(value || 0)}
    </span>
  );
}

export function PriceString({ config, value }: { config: Config; value: number }) {
  const symbol = config.currencySymbol;
  const position = config ? getConfigProperty(config, "information", "currencySymbolPosition") : "before";

  return position === "before" ? `${symbol}${round(value)}` : `${round(value)}${symbol}`;
}

export function PriceInput({
  currencySymbol,
  currency,
  variant,
  value,
  label,
  type,
  name,
  step,
  min,
  max,
  required,
  placeholder,
  onChange,
  fullWidth,
  defaultValue,
  onBlur,
  onKeyPress,
  onClick,
  position: positionProp
}: {
  currency?: string;
  variant?: string;
  value?: number;
  defaultValue?: number;
  currencySymbol?: string;
  position?: string;
  label?: string;
  onChange?: any;
  type?: string;
  name?: string;
  step?: string;
  min?: string;
  max?: string;
  onBlur?: any;
  onKeyPress?: any;
  onClick?: any;
  fullWidth?: boolean;
  placeholder?: string;
  required?: boolean;
}) {
  const config = GlobalStore.useState(c => c.config);
  const symbol = currencySymbol || (config ? config.currencySymbol : "€") || getSymbolFromCurrency(currency);
  const position = positionProp || (config ? config.currencySymbolPosition : "before");

  const valueProps: any = {};

  if (typeof value === "number" && !isNaN(value)) valueProps.value = value;
  else if (typeof value === "string") valueProps.value = parseFloat(value) || "";
  else if (defaultValue !== undefined) valueProps.defaultValue = defaultValue;
  else valueProps.value = "";

  const props = {
    onChange,
    onKeyPress,
    onClick,
    onBlur,
    label,
    name,
    placeholder,
    type,
    step,
    fullWidth,
    min,
    max,
    required
  };

  return (
    <InputWithIcon
      iconPosition={position === "before" ? "left" : "right"}
      autoComplete="off"
      variant={variant}
      {...props}
      type="number"
      icon={symbol}
      {...valueProps}
      step="0.01"
      min="0"
    />
  );
}
