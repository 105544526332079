import React, { SyntheticEvent } from "react";
import moment from "moment";
import Button from "../styled/button";
import { Link, useLocation, useHistory } from "react-router-dom";
import Pagination from "../common/pagination";
import { AiOutlineUser } from "react-icons/ai";
import Loader from "../common/loader";
import Liner from "../common/liner";
import { GlobalStore } from "../../stores/global";
import { FaEnvelope } from "react-icons/fa";
import URI from "urijs";
import Parse, { domToReact } from "html-react-parser";
import sanitizeHtml from "sanitize-html";
import { useMutation, useQuery } from "@apollo/client";
import { POST_MESSAGE_ARCHIVE, GET_MESSAGES, POST_MESSAGE_MARK_AS_READ } from "../../graphql/queries/message";
import { replaceAll } from "../../utils";
import { Message, Query } from "../../__generated__/graphql";
import { Typography } from "../../componentsV2/Typography";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

export default function Messages() {
  const addNotification = GlobalStore.useState(c => c.addNotification);
  const location = useLocation();
  const currentUri = new URI(location.pathname + location.search);
  const searchQuery = currentUri.search(true);
  const page = parseInt(searchQuery.page || "1");
  const { data, loading, refetch } = useQuery<Query>(GET_MESSAGES, { fetchPolicy: "cache-and-network", variables: { page } });
  const { t } = useTranslation();

  document.title = "Messages";

  const messagesData = data && data.messages;
  if (!messagesData || loading) return <Loader withMargins />;

  return (
    <div id="messages">
      <section className="header">
        <div className="left">
          <Typography variant="pageTitle" tag="h1">
            {t("Messages")}
          </Typography>
        </div>
        <div className="right"></div>
      </section>
      <Pagination pagination={messagesData.pagination} currentUri={currentUri} />
      <div className="messageTable">
        <div className="messageTableEntry messageTableHead">
          <p>{t("From / Date / Message")} </p>
          <p>{t("Reference")}</p>
        </div>
        {messagesData.messages.map((m: Message, index: number) => (
          <MessageComponent t={t} key={index} addNotification={addNotification} getData={refetch} message={m} index={index} />
        ))}
      </div>
      <Pagination pagination={messagesData.pagination} currentUri={currentUri} />
    </div>
  );
}

const MessageComponent = ({
  message: m,
  addNotification,
  index,
  getData,
  t
}: {
  message: Message;
  addNotification: any;
  index: number;
  getData: any;
  t: TFunction;
}) => {
  const history = useHistory();
  const [markAsRead] = useMutation(POST_MESSAGE_MARK_AS_READ);
  const [archiveMessage] = useMutation(POST_MESSAGE_ARCHIVE, { variables: { messageRef: m._id } });

  const replace = (domNode: any) => {
    if (domNode.name === "a" && domNode.attribs.href) {
      const uri = new URI(domNode.attribs.href);
      const isAbsolute = uri.is("absolute");
      if (!isAbsolute || (isAbsolute && uri.hostname() === "admin.common-ground.io"))
        return (
          <Link onClick={(e: any) => e.stopPropagation()} to={uri.path()}>
            {domToReact(domNode.children)}
          </Link>
        );
      else
        return (
          <a target="_blank" onClick={(e: any) => e.stopPropagation()} href={uri.toString()} rel="noreferrer">
            {domToReact(domNode.children)}
          </a>
        );
    } else domToReact(domNode);
  };

  const handleMarkAsRead = async (m: any) => {
    await markAsRead({ variables: { messageRef: m._id } });
  };

  const handleLineClick = (path: any) => {
    if (path) history.push(path);
  };

  const handleArchive = async () => {
    try {
      const { data } = await archiveMessage({ variables: { messageRef: m._id } });
      getData();
      addNotification({ ok: 1, message: data?.messageArchive });
    } catch (error: any) {
      addNotification({ ok: 0, message: error.toString() });
    }
  };

  return (
    <Liner
      onClick={() => handleLineClick(m.orderId ? `/order/${m.orderId}` : null)}
      index={index}
      className={`messageTableEntry ${m.read ? "read" : "unread"}`}>
      <div className="from">
        <p className="messageHeader">
          {m.fromBuyer ? (
            <span className="fromBuyer">
              <AiOutlineUser />
            </span>
          ) : null}
          {m.read ? (
            ""
          ) : (
            <span className="unread">
              <FaEnvelope />
            </span>
          )}
          {m.sender?.name || m.sender?.email || ""}
          {" - "}
          {moment(m.created).format("ll")} ({moment(m.created).fromNow()}){" - "}
          <span>{m.subject}</span>
        </p>
        <div>
          {Parse(sanitizeHtml("<p>" + m.message?.replace(/\n{2,}/g, "</p><p>").replace(/\n/g, "<br>") + "</p>") || "", { replace })}
        </div>
      </div>
      <div className="reference">
        {m.subject !== "Discogs Import Error" ? (
          <Link onClick={(e: any) => e.stopPropagation()} to={`/order/${m.orderId}`}>
            <p>{m.orderId}</p>
          </Link>
        ) : (
          <p>
            <a target="_tab" href={`https://www.discogs.com/sell/order/${m.orderId}`}>
              {m.orderId}
            </a>
          </p>
        )}
        <div className="buttons">
          {m.via === "email" && m.sender?.email && m.message ? (
            <a
              target="_blank"
              href={`mailto:${m.sender.email}?subject=${m.subject}&body=> ${replaceAll(m.message, "\n", "%0D%0A> ")}`}
              rel="noreferrer">
              <Button fullWidth onClick={(e: SyntheticEvent) => e.stopPropagation()}>
                {t("Reply by email")}
              </Button>
            </a>
          ) : null}
          {!m.read && (
            <Button
              fullWidth
              variant="secondary"
              onClick={(e: SyntheticEvent) => {
                handleMarkAsRead(m);
                e.stopPropagation();
              }}>
              {t("Mark as read")}
            </Button>
          )}
          {m.via !== "discogs" ? (
            <Button
              fullWidth
              variant="danger"
              onClick={(e: SyntheticEvent) => {
                if (window.confirm(t("Are you sure?"))) {
                  handleArchive();
                  e.stopPropagation();
                }
              }}>
              {t("Archive")}
            </Button>
          ) : null}
        </div>
      </div>
    </Liner>
  );
};
