import React, { useState, useRef, useEffect } from "react";
import Button from "../../components/styled/button";
import { Button as ButtonV2 } from "../../componentsV2/Button";
import Modal from "../../components/modal";
import { FaTimes, FaFilePdf } from "react-icons/fa";
import moment from "moment";
import Loader from "../../components/common/loader";
import Price from "../../components/common/price";
import { GET_CREDIT, POST_CREDIT_PDF } from "../../graphql/queries/credit";
import { useMutation, useLazyQuery } from "@apollo/client";
import { Config, Credit } from "../../__generated__/graphql";
import { AddNotification } from "../../types/globals";
import { useTranslation } from "react-i18next";

export default ({
  creditNote,
  config,
  handleVoid,
  addNotification
}: {
  creditNote: Credit;
  config: Config;
  handleVoid: any;
  addNotification: AddNotification;
}) => {
  const [generatingPdf, setGeneratingPdf] = useState(false);
  const modalRef = useRef<any>();
  const [getCreditPdf] = useMutation(POST_CREDIT_PDF);
  const preTaxes = config.taxes.rules.editPricesBeforeTaxes;
  const [c, setCredit] = useState<Credit | undefined>();
  const [isShowing, setIsShowing] = useState(false);
  const [getCredit] = useLazyQuery(GET_CREDIT, { variables: { creditRef: creditNote._id }, fetchPolicy: "cache-and-network" });
  const { t } = useTranslation();

  useEffect(() => {
    if (isShowing) {
      getCredit().then(({ data }) => {
        modalRef.current.open();
        if (data?.credit) setCredit(data.credit);
      });
    } else modalRef.current.close();
  }, [isShowing]);

  const handleGeneratePdf = async (event: any) => {
    if (!c) return;
    event.preventDefault();
    setGeneratingPdf(true);
    try {
      const { data } = await getCreditPdf({ variables: { creditRef: c._id } });
      if (data?.creditPdf) {
        addNotification({ ok: 1, message: "PDF created" });
        if (data.creditPdf.uri) window.open(data.creditPdf.uri, "_blank");
      }
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    } finally {
      setGeneratingPdf(false);
    }
  };

  return (
    <>
      <Modal style={{}} ref={modalRef} onClose={() => setIsShowing(false)}>
        {c ? (
          <div className="viewCreditNoteDetails">
            <div className="modalHeader">
              <h2>
                #{c.id} - {t("Credit note")} - {c.title}
              </h2>
              <Button variant="noStyle" onClick={() => modalRef.current.close()}>
                <FaTimes />
              </Button>
            </div>
            <hr />
            <div className="content">
              <div className="header">
                <div className="customer">
                  <div className="address">
                    <p>
                      {c.user?.name}
                      <br />
                      {c.user?.email}
                      <br />
                      {c.user?.telephone ? c.user.telephone : null}
                      <br />
                    </p>
                  </div>
                </div>
                <div>
                  <p>
                    {t("Issued")}:
                    <br />
                    {moment(c.createdAt).format("llll")}
                  </p>
                  {c.pdf ? (
                    <a target="_tab" href={c.pdf}>
                      {t("Download PDF")} <FaFilePdf />
                    </a>
                  ) : (
                    <form onSubmit={handleGeneratePdf}>
                      <div className="inputAndButton">
                        <Button variant="noStyle" disabled={generatingPdf} type="submit" className="reset">
                          {generatingPdf ? (
                            <Loader size={5} />
                          ) : (
                            <span>
                              {t("Download PDF")} <FaFilePdf />
                            </span>
                          )}
                        </Button>
                      </div>
                    </form>
                  )}
                </div>
                <p>
                  {t("Title")}: {c.title}
                  <br />
                  {t("Reference")}: {c.reference}
                </p>
              </div>
              <hr />
              <div className="items">
                <div className="header">
                  <p>{t("Description")}</p>
                  <p>{t("Quantity")}</p>
                  <p>
                    {t("Price")} {preTaxes ? t("before taxes") : t("after taxes")}
                  </p>
                  <p>{t("Taxes")}</p>
                  <p>{t("Subtotal")}</p>
                </div>
                <div className="entries">
                  {c.items.map((i, idx) => (
                    <div key={idx} className="entry">
                      <p>{i.item.description}</p>
                      <p>{i.quantity}</p>
                      <p>
                        <Price value={preTaxes ? i.price.beforeTaxes : i.price.base} />
                      </p>
                      <p>
                        {i.price.taxes?.map((t, idx) => (
                          <span key={idx}>
                            {t.name} {t.rate}%
                            <br />
                          </span>
                        ))}
                      </p>
                      <p>
                        <Price value={i.price.subtotal as number} />
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <hr />
              <div className="totals">
                <div className="list">
                  <p>
                    {t("Subtotal")}: <Price value={c.totals.subtotal as number} />
                  </p>
                  <p>
                    {c.totals.taxes?.map((t, i) => (
                      <span key={i}>
                        {t.name}: <Price value={t.amount as number} />
                        <br />
                      </span>
                    ))}
                  </p>
                  <p>
                    <strong>
                      {t("Total")}: <Price value={c.totals.grand} />
                    </strong>
                  </p>
                </div>
              </div>
              <hr />
              <div className="spendings">
                {c.status !== "void" ? (
                  <div>
                    <Button
                      type="button"
                      variant="danger"
                      disabled={!!c.spendings?.length || c.status === "void"}
                      onClick={() => {
                        if (window.confirm(t("Are you sure?"))) handleVoid(c);
                      }}>
                      {t("Void")}
                    </Button>
                    {c.spendings?.length ? <p>{t("Credit notes with existing spendings cannot be void")}</p> : null}
                  </div>
                ) : (
                  <p>
                    {t("Status")}: {c.status}
                  </p>
                )}
                <div className="list">
                  <p>{!c.spendings?.length ? t("No spendings") : null}</p>
                  <p>
                    {t("Balance")}: <Price value={c.totals.balance as number} />
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </Modal>
      <ButtonV2 type="button" variant="primary" onClick={() => setIsShowing(!isShowing)}>
        {t("Details")}
      </ButtonV2>
    </>
  );
};
