import React from "react";
import { Typography } from "../Typography";
import { ButtonsContainer, LeftSectionContainer, RightSectionContainer, SectionHeaderContainer } from "./SectionHeader.styles";

type LeftSectionProps = {
  children: React.ReactNode;
};

type RightSectionProps = {
  children: React.ReactNode;
};

type Props = {
  title: string;
  children: React.ReactNode;
};

export const SectionHeader: React.FC<Props> = ({ title, children }) => {
  return (
    <SectionHeaderContainer>
      <Typography variant="pageTitle" tag="h1" level="normal" style={{ whiteSpace: "nowrap" }}>
        {title}
      </Typography>
      <ButtonsContainer>{children}</ButtonsContainer>
    </SectionHeaderContainer>
  );
};

export const LeftSection: React.FC<LeftSectionProps> = ({ children }) => {
  return <LeftSectionContainer>{children}</LeftSectionContainer>;
};

export const RightSection: React.FC<RightSectionProps> = ({ children }) => {
  return <RightSectionContainer>{children}</RightSectionContainer>;
};
