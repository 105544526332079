import styled from "styled-components";
import { devices } from "../../../../utils/styles";

export const StyledMainWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const StyledCopyWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  @media ${devices.tabletL} {
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
  }
`;

export const StyledCopyContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 18px;
  @media ${devices.tabletL} {
    width: 100%;
    justify-content: space-between;
    gap: 0;
    margin-bottom: 10px;
  }
`;
