import React, { useMemo, useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import styled from "styled-components";

import debounce from "lodash.debounce";
import colors from "@common-ground-io/common-assets/assets/colors.json";

const inputLayout = {
  light: {
    primary: {
      fontColorLabel: colors.grey,
      bcgColorResult: colors.white,
      bcgColorResultSecondary: colors.greyLightest,
      bcgColorInput: colors.greyLightest,
      fontColorInput: colors.greyDarker,
      fontColorResult: colors.greyDarker,
      fontColorInputReadonly: colors.greyLight,
      fontColorPlaceholder: colors.grey
    },
    overZone: {
      fontColorLabel: colors.grey,
      bcgColorResult: colors.white,
      bcgColorResultSecondary: colors.greyLightest,
      bcgColorInput: colors.white,
      fontColorInput: colors.greyDarker,
      fontColorResult: colors.greyDarker,
      fontColorInputReadonly: colors.greyLight,
      fontColorPlaceholder: colors.greyDark
    }
  },
  dark: {
    primary: {
      fontColorLabel: colors.grey,
      bcgColorResult: colors.greyDarker,
      bcgColorResultSecondary: colors.greyDark,
      bcgColorInput: colors.greyDarker,
      fontColorInput: colors.greyLighter,
      fontColorResult: colors.greyLighter,
      fontColorResultHover: colors.greyLight,
      fontColorInputReadonly: colors.grey,
      fontColorPlaceholder: colors.grey
    },
    overZone: {
      fontColorLabel: colors.grey,
      bcgColorResult: colors.greyDark,
      bcgColorResultSecondary: colors.greyDarker,
      bcgColorInput: colors.greyDark,
      fontColorInput: colors.greyLighter,
      fontColorResult: colors.greyLighter,
      fontColorInputReadonly: colors.grey,
      fontColorPlaceholder: colors.greyLight
    }
  }
};

const iconLayout = {
  light: {
    primary: {
      bcgColor: colors.greyLightest,
      fontColor: colors.grey,
      borderColorHover: colors.grey,
      fontColorHover: colors.grey,
      bcgColorActive: colors.grey,
      borderColorActive: "transparent",
      fontColorActive: colors.greyLightest,
      separatorColor: colors.greyLighter
    },
    overZone: {
      bcgColor: colors.white,
      fontColor: colors.grey,
      borderColorHover: colors.grey,
      fontColorHover: colors.grey,
      bcgColorActive: colors.grey,
      borderColorActive: colors.grey,
      fontColorActive: colors.white,
      separatorColor: colors.greyLighter
    }
  },
  dark: {
    primary: {
      bcgColor: colors.greyDarker,
      fontColor: colors.greyLight,
      borderColorHover: colors.greyLight,
      fontColorHover: colors.greyLight,
      bcgColorActive: colors.greyLight,
      borderColorActive: "transparent",
      fontColorActive: colors.greyDark,
      separatorColor: colors.greyDark
    },
    overZone: {
      bcgColor: colors.greyDark,
      fontColor: colors.greyLight,
      borderColorHover: colors.greyLight,
      fontColorHover: colors.greyLight,
      bcgColorActive: colors.greyLight,
      borderColorActive: "transparent",
      fontColorActive: colors.greyDark,
      separatorColor: colors.grey
    }
  }
};

const Styledlabel = styled.label<{ variant?: string }>`
  font-style: normal;
  font-weight: 600;
  font-size: inherit;
  line-height: 1;

  color: ${props => path(inputLayout, props).fontColorLabel};
`;

const StyledInput = styled.input.attrs({ autoComplete: "off", autoFill: "off" })<{ variant?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 10px;
  border: 1px solid transparent;

  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  margin: 0;

  box-shadow: none;
  outline: none;

  font-style: normal;
  font-family: inherit;
  font-weight: normal;
  font-size: inherit;
  line-height: 1;

  width: 100%;
  background-color: ${props => path(inputLayout, props).bcgColorInput};
  color: ${props => path(inputLayout, props).fontColorInput};

  &:read-only {
    color: ${props => path(inputLayout, props).fontColorInputReadonly};
  }
  ::placeholder {
    color: ${props => path(inputLayout, props).fontColorPlaceholder};
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
    transition-delay: 9999s;
  }
`;

const StyledIconZone = styled.div<{ variant?: string; fullWidth?: boolean }>`
  display: flex;
  align-items: center;
  height: auto;
  border: 1px solid transparent;

  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  padding: 4px;

  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  font-style: normal;
  line-height: 1;
  text-align: center;
  margin: 0;
  width: ${({ fullWidth }) => fullWidth && "100%"};
  background-color: ${props => path(iconLayout, props).bcgColor};
  color: ${props => path(iconLayout, props).fontColor};
`;

const StyledResults = styled.div<{ variant?: string }>`
  background-color: ${props => path(inputLayout, props).bcgColorResult};
  position: absolute;
  border-radius: 10px;
  top: 40px;
  height: auto;
  max-height: 30vh;
  overflow-y: scroll;
  width: calc(100% - 0px);
  z-index: 2;
`;

const StyledResult = styled.div<{ variant?: string }>`
  text-decoration: none;
  padding: 10px;
  &:nth-child(even) {
    background-color: ${props => path(inputLayout, props).bcgColorResultSecondary};
  }
`;

const StyledContainer = styled.div<{ label?: string; fullWidth?: boolean }>`
  display: flex;
  align-content: flex-start;
  margin-top: ${props => (props.label ? "10px" : "0px")};
  position: relative;
`;

const Container = styled.div`
  display: block;
`;

const computeVariant = (variant: any, layout: any) => (layout.light[variant] ? variant : "primary");
const path = (layout: any, props: any) => layout[props.theme.name || "light"][props.variant];

const SearchComponent = (props: any, ref: any) => {
  const variant = computeVariant(props.variant, inputLayout);
  const [term, setTerm] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [results, setResults] = useState<any>("");
  const inputRef = useRef<any>();

  const localRef = useRef<any>(null);

  useImperativeHandle(ref, () => ({ close: resetAndClose, results }));

  const eventHandler = (term: string) => {
    if (props.search) {
      props
        .search(term)
        .then((results: any) => {
          setResults(results);
          setIsSearching(false);
        })
        .catch((e: any) => console.error(e));
    }
  };

  const changeHandler = (e: any) => {
    const value = e.target.value;
    setIsSearching(true);
    setTerm(value);
    if (value) debouncedChangeHandler(value);
    else resetAndClose();
  };

  const resetAndClose = () => {
    setResults(null);
    setTerm("");
    setIsSearching(false);
  };

  const handleClickOutside = (event: any) => {
    if (localRef.current && !localRef.current.contains(event.target)) {
      resetAndClose();
    }
  };

  const handleResultClick = (options: any) => {
    resetAndClose();
    if (options?.focus && inputRef.current) inputRef.current.focus();
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => document.removeEventListener("click", handleClickOutside, true);
  });

  const debouncedChangeHandler = useMemo(() => debounce(eventHandler, props.debounceTime || 300), []);

  return (
    <Container
      ref={localRef}
      id={props.id || undefined}
      style={props.style || {}}
      className={`cg-common cg-search ${props.className || ""}`}>
      {props.label && (
        <Styledlabel
          htmlFor={props.id || props.name || ""}
          className={`cg-common ${variant} ${props.className || ""} cg-label`}
          variant={variant}>
          {props.label}
        </Styledlabel>
      )}
      <StyledContainer label={props.label}>
        <StyledInput
          ref={inputRef}
          type="text"
          name="search"
          value={term}
          onChange={changeHandler}
          placeholder={props.placeholder || "Search..."}
          className={`cg-common cg-input ${variant} ${props.className || ""}`}
          variant={variant}
        />
        <StyledIconZone variant={variant}>
          <i className="cg-icon-search" />
        </StyledIconZone>
        <StyledResults className="results" variant={variant}>
          {isSearching ? (
            <StyledResult variant={variant} className="result">
              <p>Searching...</p>
            </StyledResult>
          ) : null}
          {!isSearching && results && Array.isArray(results) && results.length === 0 ? (
            <StyledResult variant={variant} className="result">
              <p>No results...</p>
            </StyledResult>
          ) : null}
          {!isSearching && results && Array.isArray(results) && results.length
            ? results.map((entry, i) => (
                <StyledResult variant={variant} key={i} className="result">
                  <props.suggestionComponent entry={entry} results={results} term={term} handleResultClick={handleResultClick} />
                </StyledResult>
              ))
            : null}
        </StyledResults>
      </StyledContainer>
    </Container>
  );
};

export default forwardRef(SearchComponent);
