import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { AiOutlineCloudUpload } from "react-icons/ai";
import Loader from "../common/loader";
import { useMutation } from "@apollo/client";
import { POST_MEDIA_CREATE } from "../../graphql/queries/media";
import { AddNotification } from "../../types/globals";
import { useTranslation } from "react-i18next";

export default ({ onUploaded, addNotification }: { onUploaded: any; addNotification: AddNotification }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | undefined | null>();
  const [createMedia] = useMutation(POST_MEDIA_CREATE);

  const { t } = useTranslation();

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (!acceptedFiles.length) return setError("Invalid file, size limit is 5MB");
      else setError(null);
      setIsUploading(true);
      try {
        const files = acceptedFiles.map(af => ({ progress: 0, file: af }));
        await Promise.all(
          files.map(async f => {
            const formData = new FormData();
            formData.append("file", f.file);
            await createMedia({ variables: { file: f.file } });
          })
        );
        onUploaded();
      } catch (e: any) {
        addNotification({ ok: 0, message: e.toString() });
      } finally {
        setIsUploading(false);
      }
    },
    [addNotification, onUploaded]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop, minSize: 0, maxSize: 5000000 });

  return (
    <section id="mediaUploader">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        {isUploading ? (
          <div className="files">
            <p>{t("Uploading")}...</p>
            <Loader withMargins />
          </div>
        ) : (
          <div className="content">
            <AiOutlineCloudUpload />
            <p>{t("Drag and drop a file here, or click to select one")}</p>
            {error ? <h2 className="danger">{error}</h2> : null}
          </div>
        )}
      </div>
    </section>
  );
};
