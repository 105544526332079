import React, { useRef, useState } from "react";
import Modal, { PropsToForward } from "../../components/modal";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Button } from "../../componentsV2/Button";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import styled from "styled-components";
import { POST_ORDERS_EXPORT } from "../../graphql/queries/order";
import { IOrdersFilters } from "../../components/orders/orders";
import { OrdersStatistics } from "../../__generated__/graphql";
import { ModalHeaderContainer } from "../../componentsV2/SectionHeader/SectionHeader.styles";
import { Typography } from "../../componentsV2/Typography";
import { useHistory } from "react-router-dom";
import { AddNotification } from "../../types/globals";

export const SpaceBetweenContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const ExportButton = ({
  addNotification,
  filters,
  statistics
}: {
  addNotification: AddNotification;
  filters: IOrdersFilters;
  statistics: OrdersStatistics;
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const modalRef: any = useRef<PropsToForward>();
  const [exportPDFs] = useMutation(POST_ORDERS_EXPORT);
  const [isSubmittingJob, setIsSubmittingJob] = useState(false);
  const [index, setIndex] = useState(0);

  const handleExportOrders = async () => {
    setIsSubmittingJob(true);
    try {
      await exportPDFs({ variables: { format: index === 0 ? "csv" : "pdf", ordersFiltersInput: filters } });
      addNotification({ ok: 1, message: t("Your export file will become available to download shortly") });
      history.push("/jobs");
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    } finally {
      setIsSubmittingJob(false);
    }
  };

  return (
    <div className="exportButton">
      <Modal ref={modalRef} style={{}}>
        <ModalHeaderContainer>
          <Typography variant="pageTitle" tag="h2">
            {t("Export")}
          </Typography>
          <button className="reset" type="button" onClick={() => modalRef.current.close()}>
            <i className="cg-icon-burger-close" />
          </button>
        </ModalHeaderContainer>
        <Tabs className="tabView" selectedIndex={index} onSelect={index => setIndex(index)}>
          <TabList className="tabList">
            <Tab className={"tab react-tabs__tab"}>
              <span>{t("Export as {{format}}", { format: "CSV" })}</span>
            </Tab>
            <Tab className={"tab react-tabs__tab"}>
              <span>{t("Export as {{format}}", { format: "PDF" })}</span>
            </Tab>
          </TabList>
          <TabPanel>
            <div>
              <h2>{t("{{amount}} orders will be exported in CSV format", { amount: statistics.count })}</h2>
              <p>{t("To open the generated CSV file, a spreadsheet software is required")}.</p>
              <SpaceBetweenContainer className="footer" style={{ marginTop: "150px" }}>
                <Button variant="secondary" onClick={() => modalRef.current?.close()}>
                  {t("Cancel")}
                </Button>
                <Button variant="primary" disabled={isSubmittingJob} onClick={handleExportOrders}>
                  {t("Export")}
                </Button>
              </SpaceBetweenContainer>
            </div>
          </TabPanel>
          <TabPanel>
            <div>
              <h2>{t("{{amount}} orders will be exported in a ZIP archive", { amount: statistics.count })}</h2>
              <p>
                {t("To open the generated ZIP file, an unarchiving software is required")}.
                <br /> {t("This export is limited to {{amount}} PDF files", { amount: "100" })}.
              </p>
              <div className="content" style={{ display: "flex", justifyContent: "space-between" }}></div>
              <SpaceBetweenContainer className="footer" style={{ marginTop: "150px" }}>
                <Button variant="secondary" onClick={() => modalRef.current?.close()}>
                  {t("Cancel")}
                </Button>
                <Button variant="primary" disabled={isSubmittingJob} onClick={handleExportOrders}>
                  {t("Export")}
                </Button>
              </SpaceBetweenContainer>
            </div>
          </TabPanel>
        </Tabs>
      </Modal>
      {(statistics.count || 0) > 0 ? (
        <Button variant={"primary"} onClick={() => modalRef.current.open()}>
          {t("Export")}
        </Button>
      ) : null}
    </div>
  );
};

export default ExportButton;
