import { gql } from "../../__generated__/gql";
import { BUYER_FIELDS } from "../types";

export const SESSION_FIELDS = gql(`
  fragment SessionFields on Session {
    _id
    user {
      ...AdminFields
    }
    adminDomains {
      _id
      domainName
      siteName
      active
    }
    jwt
    expires
    createdAt
    origin
    deviceName
    deviceId
  }
`);

export const GET_SESSION = gql(`
  query session($jwt: String) {
    session(jwt: $jwt) {
      ...SessionFields
    }
  }
`);

export const GET_REGISTRATIONS = gql(`
  query sessionRegistrations {
    sessionRegistrations {
      ...SessionFields
    }
  }
`);

export const POST_SESSION_LOGIN = gql(`
  mutation sessionLogin($email: String!, $password: String!) {
    sessionLogin(email: $email, password: $password) {
      ...SessionFields
    }
  }
`);

export const POST_SESSION_LOGOUT = gql(`
  mutation sessionLogout {
    sessionLogout
  }
`);

export const POST_REGISTRATION_DELETE = gql(`
  mutation sessionDeregisterDevice($registrationRef: ID!) {
    sessionDeregisterDevice(registrationRef: $registrationRef)
  }
`);

export const POST_USER_VERIFY = gql(`
  mutation userVerify($userRef: ID!) {
    userVerify(userRef: $userRef)
  }
`);

export const POST_USER_CHECK = gql(`
  mutation userAdminCheck($email: String!) {
    userAdminCheck(email: $email)
  }
`);

export const POST_SSO_SIGNIN = gql(`
  mutation sessionLoginWithSSO($ssoInput: SSOInput) {
    session: sessionLoginWithSSO(ssoInput: $ssoInput) {
      ...SessionFields
    }
  }
`);

export const POST_USER_CREATE_PASSWORD_TOKEN = gql(`
  ${BUYER_FIELDS}
  mutation userBuyerCreatePasswordToken($email: String!) {
    userBuyerCreatePasswordToken(email: $email) {
      ...BuyerFields
    }
}`);
