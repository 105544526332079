import http from "../httpService";
import URI from "urijs";

export function updateOrderStatus(data: any) {
  const uri = new URI(`${process.env.REACT_APP_API_ENDPOINT}/order/status`);
  return http.put(uri.toString(), data);
}

export function updateOrderPaymentMethods(data: any) {
  const uri = new URI(`${process.env.REACT_APP_API_ENDPOINT}/order/paymentmethods`);
  return http.put(uri.toString(), data);
}

export function updateOrderBilling(data: any) {
  const uri = new URI(`${process.env.REACT_APP_API_ENDPOINT}/order/billing`);
  return http.put(uri.toString(), data);
}

export function cancelOrder(data: any) {
  const uri = new URI(`${process.env.REACT_APP_API_ENDPOINT}/order/cancel`);
  return http.put(uri.toString(), data);
}

export function addVoucher(data: any) {
  const uri = new URI(`${process.env.REACT_APP_API_ENDPOINT}/order/voucher`);
  return http.post(uri.toString(), data);
}

export function addDiscount(data: any) {
  const uri = new URI(`${process.env.REACT_APP_API_ENDPOINT}/order/${data.orderRef}/discount/${data.type}`);
  return http.post(uri.toString(), data);
}

export function removeVoucher(data: any) {
  const uri = new URI(`${process.env.REACT_APP_API_ENDPOINT}/order/voucher`);
  return http.delete(uri.toString(), { data });
}

export function removeDiscount(data: any) {
  const uri = new URI(`${process.env.REACT_APP_API_ENDPOINT}/order/${data.orderRef}/discount/${data.type}`);
  return http.delete(uri.toString(), { data });
}

export function mergeOrder(data: any) {
  const uri = new URI(`${process.env.REACT_APP_API_ENDPOINT}/orders/merge`);
  return http.post(uri.toString(), data);
}

export function addItem(data: any) {
  const uri = new URI(`${process.env.REACT_APP_API_ENDPOINT}/order/item`);
  return http.post(uri.toString(), data);
}

export function updateLineItem(data: any) {
  const uri = new URI(`${process.env.REACT_APP_API_ENDPOINT}/order/line`);
  return http.put(uri.toString(), data);
}
