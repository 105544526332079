import React, { useRef } from "react";
import {
  ActionButtons,
  Capsules,
  // CapsulesVertical,
  CategorieCapsule,
  InventoryViewItemHeader,
  InventoryViewItemListings,
  ListingColumnContainer,
  ListingContainer,
  ListingsWithItemHeaderContainer,
  OptionCapsule,
  TagCapsule
} from "./Listing.styles";
import { Config, Item, Listing } from "../../__generated__/graphql";
import { Button } from "../Button";
import moment from "moment";
import Price from "../../components/common/price";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography } from "../Typography";
import ModalComponent, { PropsToForward } from "../../components/modal";
// @ts-ignore
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getPlaceholderImage } from "../../utils";
import { ListingEditor } from "./Listing.edit";
import { GlobalStore } from "../../stores/global";
import { ListingHistory } from "../../components/global/history";
import { ModalHeaderContainer } from "../SectionHeader/SectionHeader.styles";
import { GoDotFill } from "react-icons/go";
import { colorsAsRgbString } from "@common-ground-io/colors";
import { BulkBox } from "../../components/listings/single";
import Truncate from "@konforti/react-truncate";
import clone from "clone";

const ListingView = ({ item, listing, config, bulk }: { item: Item; listing: Listing; config: Config; bulk: boolean }) => {
  const { addNotification, isMobile, stickers } = GlobalStore.useState(c => c);

  const modalRef = useRef<any>(null);
  const { t } = useTranslation();
  const history = useHistory();

  const isInSstock = listing.stock.quantity;
  const isInPrintQueue = stickers.entries.find(e => e.listing.id === listing.id);

  const handlePrint = () => {
    const isLegacyPrinting = localStorage.getItem(`${config.id}-legacyPrinting`) === "true";
    const path = `/stickers?id=${listing._id}`;

    if (isLegacyPrinting) history.push(path);
    else if (item._id) {
      if (isInPrintQueue) {
        GlobalStore.update(s => {
          const index = s.stickers.entries.findIndex(e => e.listing.id === listing.id);
          s.stickers.entries.splice(index, 1);
        });
        addNotification({
          ok: 1,
          message: (
            <p>
              {t("Listing removed from print queue")} <br />
              <br /> <Link to="/stickers">{t("Print now")}</Link>
            </p>
          )
        });
      } else {
        GlobalStore.update(s => {
          s.stickers.entries.unshift({ listing: clone(listing), item: item as Item });
        });
        addNotification({
          ok: 1,
          message: (
            <p>
              {t("Listing added to print queue")} <br />
              <br /> <Link to="/stickers">{t("Print now")}</Link>
            </p>
          )
        });
      }
    }
  };

  return (
    <ListingContainer bulk={bulk}>
      <ModalComponent style={{ width: isMobile ? "100%" : "75%", height: isMobile ? "100%" : "80%" }} ref={modalRef}>
        <ListingEditor
          item={item}
          config={config}
          addNotification={addNotification}
          listing={listing}
          onSubmit={() => modalRef.current.close()}
        />
      </ModalComponent>
      {bulk ? (
        <ListingColumnContainer>
          <BulkBox item={item as Item} listing={listing} />
        </ListingColumnContainer>
      ) : null}
      <ListingColumnContainer>
        <TagCapsule>{listing.stock.quantity}</TagCapsule>
        <TagCapsule>
          <Price value={listing.prices.sale as number} />
        </TagCapsule>
        <div style={{ marginTop: "0" }}>
          <MentionWithIndicator state={isInSstock ? "active" : "warning"} text={"POS"} />
          <MentionWithIndicator state={isInSstock ? "active" : "warning"} text={"Eshop"} />
          <MentionWithIndicator state={isInSstock ? "active" : "warning"} text={"Meta"} />
          {item.type === "ReleaseItem" ? <MentionWithIndicator state={listing.discogsId ? "active" : "warning"} text={"Discogs"} /> : null}
          {/* {item.type === "ReleaseItem" ? <MentionWithIndicator state={"active"} text={"Bandcamp"} /> : null} */}
        </div>
      </ListingColumnContainer>

      <ListingColumnContainer>
        <Link to={item.path || ""}>
          <LazyLoadImage
            alt={item?.descriptions?.main}
            src={(item?.data?.images?.length ? item?.data?.images[0]?.uri : null) || getPlaceholderImage(config)}
          />
        </Link>
      </ListingColumnContainer>

      <ListingColumnContainer>
        <Typography variant="copy" level={listing.preOrder ? "info" : "secondary"} tag="span">
          {t("Available")}: {moment(listing.available).format("ll")}
        </Typography>
        {listing.onePerCustomer ? (
          <Typography variant="copy" level="normal" tag="p">
            {t("One per customer")}
          </Typography>
        ) : null}
        {listing.location ? (
          <Typography variant="copy" level="secondary" tag="span">
            {t("Location")}: {listing.location}
          </Typography>
        ) : null}
        {listing.supplierCode ? (
          <Typography variant="copy" level="secondary" tag="span">
            {t("Supplier")}: {listing.supplierCode}
          </Typography>
        ) : null}
        {listing.sku ? (
          <Typography variant="copy" level="secondary" tag="span">
            {"SKU"}: {listing.sku}
          </Typography>
        ) : null}
        {listing.barcode ? (
          <Typography variant="copy" level="secondary" tag="span">
            {t("Barcode")}: {listing.barcode}
          </Typography>
        ) : null}
        <Capsules style={{ marginTop: "5px" }}>
          {listing.categories.map(o => (
            <div key={o} style={{ marginBottom: "5px" }}>
              <CategorieCapsule>{o}</CategorieCapsule>
            </div>
          ))}
        </Capsules>
      </ListingColumnContainer>

      <ListingColumnContainer>
        {listing.comments ? (
          <div>
            <Typography variant="copy" level="secondary" tag="p">
              {t("Public comments")}
            </Typography>
            <Typography variant="copy" level="secondary" tag="p" style={{ fontStyle: "italic" }}>
              <Truncate line={1}>{listing.comments}</Truncate>
            </Typography>
          </div>
        ) : null}
        {listing.privateComments ? (
          <div>
            <Typography variant="copy" level="secondary" tag="p">
              {t("Private comments")}
            </Typography>
            <Typography variant="copy" level="secondary" tag="p" style={{ fontStyle: "italic" }}>
              <Truncate line={1}>{listing.privateComments}</Truncate>
            </Typography>
          </div>
        ) : null}
        <Capsules style={{ marginTop: "10px" }}>
          {listing.options.map(o => (
            <div key={o.name} style={{ marginBottom: "5px" }}>
              <OptionCapsule>{o.value}</OptionCapsule>
            </div>
          ))}
        </Capsules>
      </ListingColumnContainer>

      <div>
        <ActionButtons>
          <Button variant="primary" onClick={() => modalRef.current.open()}>
            {t("Edit")}
          </Button>
          <Button variant={isInPrintQueue ? "navigation" : "secondary"} type="button" onClick={handlePrint} disabled={!!listing.readOnly}>
            {isInPrintQueue ? t("Queued") : t("Print")}
          </Button>
          {/* <LogsButton listing={listing} /> */}
        </ActionButtons>
        {/* <div style={{ marginTop: "var(--gutter)" }}>
          <MentionWithIndicator state={"active"} text={"POS"} />
          <MentionWithIndicator state={"active"} text={"Eshop"} />
          {item.type === "ReleaseItem" ? <MentionWithIndicator state={listing.discogsId ? "active" : "danger"} text={"Discogs"} /> : null}
          {item.type === "ReleaseItem" ? <MentionWithIndicator state={"active"} text={"Bandcamp"} /> : null}
        </div> */}
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <GoDotFill style={{ color: "white", width: "10px", height: "10px" }} />
        <GoDotFill style={{ color: "white", width: "10px", height: "10px" }} />
        <GoDotFill style={{ color: "white", width: "10px", height: "10px" }} />
      </div>
    </ListingContainer>
  );
};

export const MentionWithIndicator = ({ state, text }: { state: "active" | "warning" | "danger"; text: string }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
      <GoDotFill style={{ color: state === "active" ? colorsAsRgbString.success : colorsAsRgbString.alert }} />
      <span></span>
      {text}
    </div>
  );
};

const ListingsWithItemHeader = ({ item, config }: { item: Item; config: Config }) => {
  const { t } = useTranslation();
  return (
    <ListingsWithItemHeaderContainer>
      <InventoryViewItemHeader>
        <Link to={item.path}>
          <Typography variant="subTitle" tag="h2">
            <Truncate line={1}>{item.descriptions.main}</Truncate>
          </Typography>
        </Link>
        <span>
          {item.wants ? (
            <span>
              {t("Wants")}: {item.wants} |{" "}
            </span>
          ) : null}{" "}
          {item.incId ? `#${item.incId}` : ""}
        </span>
      </InventoryViewItemHeader>
      <InventoryViewItemListings>
        {item.listings.map(l => (
          <ListingView key={l.id} listing={l} item={item} config={config} bulk={true} />
        ))}
      </InventoryViewItemListings>
    </ListingsWithItemHeaderContainer>
  );
};

const LogsButton = ({ listing }: { listing: Listing }) => {
  const modalRef = useRef<PropsToForward>(null);
  const { t } = useTranslation();

  return (
    <div>
      <ModalComponent style={{}} ref={modalRef}>
        <ModalHeaderContainer>
          <Typography variant="pageTitle">{t("Listing logs")}</Typography>
          <button className="reset" type="button" onClick={() => modalRef.current?.close()}>
            <i className="cg-icon-burger-close" />
          </button>
        </ModalHeaderContainer>
        <ListingHistory listing={listing} />
      </ModalComponent>
      <Button variant="secondary" onClick={() => modalRef.current?.open()}>
        {t("Logs")}
      </Button>
    </div>
  );
};

export { ListingView as Listing, ListingsWithItemHeader };
