import React from "react";
import { useHistory, Switch, Route } from "react-router-dom";
import colors from "@common-ground-io/common-assets/assets/colors.json";
import { useHelpPanel } from "../../components/helpPanel";
import styled from "styled-components";
import CSVImporter from "./csv";
import ButtonComponent from "../../components/styled/button";
import { useTranslation } from "react-i18next";
import { Typography } from "../../componentsV2/Typography";

interface Imports {
  title: string;
  entries: {
    Component?: React.FC;
    link?: string;
    id: string;
    title: string;
    description: string;
  }[];
}

const Uploaders = () => {
  const history = useHistory();
  const { load } = useHelpPanel({ id: 7328994, show: false });
  const { t } = useTranslation();

  const imports: Imports[] = [
    {
      title: t("Releases"),
      entries: [
        {
          link: "/discogs/listings",
          id: "releases-from-discogs-account",
          title: t("Add from your Discogs account"),
          description: t("Items are fetched directly from your Discogs inventory")
        },
        {
          Component: CSVImporter,
          id: "releases-with-discogs",
          title: t("Add with Discogs ID"),
          description: t("Item are pre-filled from the unique Discogs ID and CSV columns")
        },
        {
          Component: CSVImporter,
          id: "releases-manually",
          title: t("Add manually"),
          description: t("Fields are filled from CSV columns")
        }
      ]
    },
    {
      title: "Listings",
      entries: [
        {
          Component: CSVImporter,
          id: "listings-update",
          title: t("Update"),
          description: t("Listings fields are updated from CSV columns")
        },
        {
          Component: CSVImporter,
          id: "listings-add",
          title: t("Add"),
          description: t("Add Listings for specific items from CSV columns")
        },
        {
          Component: CSVImporter,
          id: "listings-delete",
          title: t("Delete"),
          description: t("Delete listings from CSV columns")
        }
      ]
    },
    {
      title: t("Customers"),
      entries: [{ Component: CSVImporter, id: "customers-add", title: t("Add"), description: t("Customers are created from CSV columns") }]
    }
  ];

  return (
    <Switch>
      <Route exact path={"/import"}>
        <Intro load={load} imports={imports} t={t} history={history} />
      </Route>
      {imports.map(i =>
        i.entries.map(({ Component, id }) =>
          Component ? (
            <Route key={id} exact path={"/import/" + id}>
              <Component />
            </Route>
          ) : null
        )
      )}
    </Switch>
  );
};
type LoadHelp = (id: string) => void;

const Intro = ({ t, history, imports, load }: { t: any; history: any; imports: Imports[]; load: LoadHelp }) => {
  return (
    <div className="releasesWithDiscogsId">
      <Header className="header" style={{ display: "flex" }}>
        <Typography variant="pageTitle" tag="h2">
          <h1>{t("Import")}</h1>
        </Typography>
        <ButtonComponent variant="secondary" style={{ marginLeft: "var(--gutter" }} type="button" onClick={() => load("7328994")}>
          <span>{t("Help?")}</span>
        </ButtonComponent>
      </Header>

      {imports.map(row => (
        <Row key={row.title}>
          <h2>{row.title}</h2>
          <Entries>
            {row.entries.map(entry => (
              <Entry key={entry.id} onClick={() => history.push(entry.link || "/import/" + entry.id)}>
                <h2>{entry.title}</h2>
                <p>{entry.description}</p>
              </Entry>
            ))}
          </Entries>
        </Row>
      ))}
    </div>
  );
};

const Header = styled.div`
  margin-bottom: calc(var(--gutter) * 2);
`;

const Entries = styled.div`
  display: grid;
  grid-gap: var(--gutter);
  grid-template-columns: repeat(4, 1fr);
`;
const Row = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: var(--gutter);
  margin-bottom: calc(var(--gutter) * 4);
`;
const Entry = styled.div`
  background-color: ${({ theme }) => (theme.name === "light" ? colors.greyLightest : colors.greyDarker)};
  padding: 50px;
  border-radius: 15px;
  text-align: center;
  border: 1px solid transparent;
  h2 {
    margin-bottom: var(--gutter);
  }
  :hover {
    border: 1px solid ${colors.blue};
    cursor: pointer;
  }
`;

export default Uploaders;
