import React from "react";
import styled from "styled-components";

const getLogoData = (theme: string) => {
  if (theme === "light") return logos.light.noText;
  else if (theme === "dark") return logos.dark.noText;
};

const StyledLogoImg = styled.img`
  content: ${({ theme }) => `url(${getLogoData(theme.name)})`};
`;

const NoTextComponent = (): JSX.Element => <StyledLogoImg className={"cg-common cg-logo"} />;

const NoText = NoTextComponent;
export { NoText };

const logos = {
  dark: {
    noText:
      "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iOTZweCIgaGVpZ2h0PSI1NXB4IiB2aWV3Qm94PSIwIDAgOTYgNTUiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDYxLjIgKDg5NjUzKSAtIGh0dHBzOi8vc2tldGNoLmNvbSAtLT4KICAgIDx0aXRsZT5Db21iaW5lZCBTaGFwZSBDb3B5IDQ8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8cGF0aCBkPSJNNjYuOTUxLC0xLjIwNzkyMjY1ZS0xMyBDNzUuMzQ3LDEwLjA0NiA4NC44OTEsMTkuMTU5IDk1LjM2NywyNy4wNTMgQzg0Ljg5MSwzNS4wOSA3NS4zNDcsNDQuMDYgNjYuOTUxLDU0LjEwNiBDNTkuOTE5LDQ1LjYzOCA1Mi4wMjUsMzcuODg4IDQzLjQxNCwzMC44NTYgQzQzLjQ4NiwzNi42NjkgNDMuOTE2LDQyLjQ4MSA0NC42MzQsNDguMjkzIEMyOS43OCw0Ni43MTUgMTQuODU0LDQ2LjcxNSAxLjQyMTA4NTQ3ZS0xNCw0OC4yOTMgQzEuNzIyLDM0LjIyOSAxLjcyMiwyMC4wMiAxLjQyMTA4NTQ3ZS0xNCw1Ljg4NCBDMTQuODU0LDcuNTM0IDI5Ljc4LDcuNTM0IDQ0LjYzNCw1Ljg4NCBDNDMuOTE2LDExLjY5NiA0My40ODYsMTcuNTA5IDQzLjQxNCwyMy4zMjEgQzUyLjAyNSwxNi4zNjEgNTkuOTE5LDguNDY3IDY2Ljk1MSwtMS4yMDc5MjI2NWUtMTMgWiBNMjIuMzE3LDE5Ljk0OSBDMTguMjk4LDE5Ljk0OSAxNS4xNDEsMjMuMTA2IDE1LjE0MSwyNy4wNTMgQzE1LjE0MSwzMSAxOC4yOTgsMzQuMyAyMi4zMTcsMzQuMyBDMjYuMjY0LDM0LjMgMjkuNDkzLDMxIDI5LjQ5MywyNy4wNTMgQzI5LjQ5MywyMy4xMDYgMjYuMjY0LDE5Ljk0OSAyMi4zMTcsMTkuOTQ5IFogTTYxLjkyOCwyMS45NTggQzU5LjEyOSwyNC43NTcgNTkuMTI5LDI5LjM0OSA2MS45MjgsMzIuMjE5IEM2NC43MjYsMzQuOTQ2IDY5LjI0NywzNC45NDYgNzIuMTE4LDMyLjE0OCBDNzQuOTE2LDI5LjM0OSA3NC45MTYsMjQuNzU3IDcyLjExOCwyMi4wMyBDNjkuMjQ3LDE5LjE1OSA2NC43MjYsMTkuMTU5IDYxLjkyOCwyMS45NTggWiIgaWQ9IkNvbWJpbmVkLVNoYXBlLUNvcHktNCIgZmlsbD0iIzdBN0E3QSI+PC9wYXRoPgogICAgPC9nPgo8L3N2Zz4="
  },
  light: {
    noText:
      "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iOTZweCIgaGVpZ2h0PSI1NXB4IiB2aWV3Qm94PSIwIDAgOTYgNTUiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDYxLjIgKDg5NjUzKSAtIGh0dHBzOi8vc2tldGNoLmNvbSAtLT4KICAgIDx0aXRsZT5Db21iaW5lZCBTaGFwZTwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxwYXRoIGQ9Ik02Ni45NTEsLTcuMTA1NDI3MzZlLTE1IEM3NS4zNDcsMTAuMDQ2IDg0Ljg5MSwxOS4xNTkgOTUuMzY3LDI3LjA1MyBDODQuODkxLDM1LjA5IDc1LjM0Nyw0NC4wNiA2Ni45NTEsNTQuMTA2IEM1OS45MTksNDUuNjM4IDUyLjAyNSwzNy44ODggNDMuNDE0LDMwLjg1NiBDNDMuNDg2LDM2LjY2OSA0My45MTYsNDIuNDgxIDQ0LjYzNCw0OC4yOTMgQzI5Ljc4LDQ2LjcxNSAxNC44NTQsNDYuNzE1IDEuNDIxMDg1NDdlLTE0LDQ4LjI5MyBDMS43MjIsMzQuMjI5IDEuNzIyLDIwLjAyIDEuNDIxMDg1NDdlLTE0LDUuODg0IEMxNC44NTQsNy41MzQgMjkuNzgsNy41MzQgNDQuNjM0LDUuODg0IEM0My45MTYsMTEuNjk2IDQzLjQ4NiwxNy41MDkgNDMuNDE0LDIzLjMyMSBDNTIuMDI1LDE2LjM2MSA1OS45MTksOC40NjcgNjYuOTUxLC03LjEwNTQyNzM2ZS0xNSBaIE0yMi4zMTcsMTkuOTQ5IEMxOC4yOTgsMTkuOTQ5IDE1LjE0MSwyMy4xMDYgMTUuMTQxLDI3LjA1MyBDMTUuMTQxLDMxIDE4LjI5OCwzNC4zIDIyLjMxNywzNC4zIEMyNi4yNjQsMzQuMyAyOS40OTMsMzEgMjkuNDkzLDI3LjA1MyBDMjkuNDkzLDIzLjEwNiAyNi4yNjQsMTkuOTQ5IDIyLjMxNywxOS45NDkgWiBNNjEuOTI4LDIxLjk1OCBDNTkuMTI5LDI0Ljc1NyA1OS4xMjksMjkuMzQ5IDYxLjkyOCwzMi4yMTkgQzY0LjcyNiwzNC45NDYgNjkuMjQ3LDM0Ljk0NiA3Mi4xMTgsMzIuMTQ4IEM3NC45MTYsMjkuMzQ5IDc0LjkxNiwyNC43NTcgNzIuMTE4LDIyLjAzIEM2OS4yNDcsMTkuMTU5IDY0LjcyNiwxOS4xNTkgNjEuOTI4LDIxLjk1OCBaIiBpZD0iQ29tYmluZWQtU2hhcGUiIGZpbGw9IiMwMDAwMDAiPjwvcGF0aD4KICAgIDwvZz4KPC9zdmc+",
    textHorizontal: ""
  }
};
