import React from "react";
import Lottie from "lottie-react";
import loader from "../assets/loader.json";
import { withTheme } from "styled-components";
import { EThemes } from "../types/globals";

const Intro = ({ theme }: { theme: { name: EThemes } }) => {
  const themeToApply = theme.name?.toString() || "light";

  return (
    <div id="intro" className={themeToApply}>
      {/* @ts-ignore */}
      <Lottie animationData={loader[themeToApply]} />
    </div>
  );
};

export default withTheme(Intro);
