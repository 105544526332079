import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

export default function Carousel({ entries }: { entries: { to?: string; src: string }[] }) {
  const slickSettings = {
    dots: true,
    fade: false,
    infinite: true,
    speed: 800,
    autoplay: true,
    lazyload: "ondemand",
    slidesToShow: 1,
    arrows: false,
    draggable: false,
    slidesToScroll: 1,
    focusOnSelect: true
  };

  return (
    <div className="carouselContainer">
      <Slider {...slickSettings}>
        {entries.map((e, i) => (
          <React.Fragment key={i}>
            {e.to && typeof e.to === "string" ? (
              <>
                {e.to.includes("http") ? (
                  <a href={e.to} target="_blank" rel="noopener noreferrer">
                    <img key={e.src} src={e.src} alt={`Carousel entry ${i}`} />
                  </a>
                ) : (
                  <Link to={e.to}>
                    <img key={e.src} src={e.src} alt={`Carousel entry ${i}`} />
                  </Link>
                )}
              </>
            ) : (
              <img key={e.src} src={e.src} alt={`Carousel entry ${i}`} />
            )}
          </React.Fragment>
        ))}
      </Slider>
    </div>
  );
}
