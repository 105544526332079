import React, { useContext } from "react";
import ReactSelect from "react-select";
import Creatable from "react-select/creatable";
import styled, { ThemeContext } from "styled-components";
import { AsyncPaginate, withAsyncPaginate } from "react-select-async-paginate";

import Async from "react-select/async";

import colors from "@common-ground-io/common-assets/assets/colors.json";
import { useTranslation } from "react-i18next";

const layout = {
  light: {
    primary: {
      fontColorLabel: colors.grey,
      bcgColorInput: colors.greyLightest,
      bcgColorMultiValue: colors.greyLighter,
      fontColorInput: colors.greyDarker,
      fontColorMenuSelected: colors.greyDarker,
      fontColorMenuInactive: colors.grey,
      fontColorPlaceholder: colors.grey,
      indicatorColor: colors.greyDark
    },
    overZone: {
      fontColorLabel: colors.grey,
      bcgColorInput: colors.white,
      bcgColorMultiValue: colors.greyLighter,
      fontColorInput: colors.greyDarker,
      fontColorMenuSelected: colors.greyDarker,
      fontColorMenuInactive: colors.grey,
      fontColorPlaceholder: colors.grey,
      indicatorColor: colors.grey
    }
  },
  dark: {
    primary: {
      fontColorLabel: colors.grey,
      bcgColorInput: colors.greyDarker,
      bcgColorMultiValue: colors.greyDark,
      fontColorInput: colors.greyLighter,
      fontColorMenuSelected: colors.greyLighter,
      fontColorMenuInactive: colors.grey,
      fontColorPlaceholder: colors.greyLight,
      indicatorColor: colors.grey
    },
    overZone: {
      fontColorLabel: colors.grey,
      bcgColorInput: colors.greyDark,
      bcgColorMultiValue: colors.grey,
      fontColorInput: colors.greyLighter,
      fontColorMenuSelected: colors.greyLighter,
      fontColorMenuInactive: colors.grey,
      fontColorPlaceholder: colors.greyLight,
      indicatorColor: colors.grey
    }
  }
};

const computeStyles = ({ theme = "light", variant = "primary", label }: { theme?: string; variant?: string; label: string }) => {
  return {
    control: (provided: any) => {
      return {
        ...provided,
        height: "auto",
        minHeight: "35px",
        boxShadow: "none !important",
        borderColor: "transparent",
        borderRadius: 10,
        // @ts-ignore
        backgroundColor: layout[theme][variant].bcgColorInput,
        borderStyle: "none",
        // @ts-ignore
        color: layout[theme][variant].fontColorInput,
        marginTop: label ? "10px" : "0px",
        "&:hover": {
          borderColor: "transparent",
          // @ts-ignore
          color: layout[theme][variant].fontColorInput
        }
      };
    },
    clearIndicator: (base: any) => {
      // The cross to delete values
      return {
        ...base,
        // @ts-ignore
        color: layout[theme][variant].indicatorColor,
        "&:hover": {
          // @ts-ignore
          color: layout[theme][variant].indicatorColor
        }
      };
    },
    indicator: (base: any) => {
      // The vertical bar
      return { ...base };
    },
    indicatorContainer: (base: any) => {
      // The vertical bar
      return { ...base, height: "30px" };
    },
    indicatorSeparator: () => {
      // The vertical bar
      return { display: "none" };
    },
    dropdownIndicator: (base: any) => {
      // The arrow to open the dropdown
      return {
        ...base,
        // @ts-ignore
        color: layout[theme][variant].indicatorColor,
        "&:hover": {
          // @ts-ignore
          color: layout[theme][variant].indicatorColor
        }
      };
    },
    menu: (base: any) => ({
      ...base,
      zIndex: 2,
      color: "red",
      // @ts-ignore
      backgroundColor: layout[theme][variant].bcgColorInput,
      borderRadius: 10
    }),
    menuList: (base: any) => ({
      ...base,
      zIndex: 2,
      borderRadius: 10
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      // @ts-ignore
      color: layout[theme][variant].indicatorColor,
      borderRadius: 10,
      height: "auto"
    }),
    singleValue: (provided: any) => ({
      // Value shows at rest
      ...provided,
      // @ts-ignore
      backgroundColor: layout[theme][variant].bcgColorInput,
      // @ts-ignore
      color: layout[theme][variant].fontColorInput,
      "&:hover": {
        cursor: "pointer"
      }
    }),
    multiValue: (provided: any) => ({
      // Multi Value shows at rest
      ...provided,
      // @ts-ignore
      backgroundColor: layout[theme][variant].bcgColorMultiValue
    }),
    multiValueLabel: (provided: any) => ({
      // Multi Value shows at rest
      ...provided,
      // @ts-ignore
      color: layout[theme][variant].fontColorInput
    }),
    multiValueRemove: (provided: any) => ({
      // Multi Value shows at rest
      ...provided,
      // @ts-ignore
      color: layout[theme][variant].fontColorInput,
      "&:hover": {
        cursor: "pointer",
        // @ts-ignore
        backgroundColor: layout[theme][variant].indicatorColor,
        // @ts-ignore
        color: layout[theme][variant].fontColorInput
      }
    }),
    input: (provided: any) => ({
      // input value as you type in
      ...provided,
      margin: "0px",
      backgroundColor: "transparent",
      // @ts-ignore
      color: layout[theme][variant].fontColorInput
    }),
    option: (provided: any, state: any) => {
      // dropdown input value
      return {
        ...provided,
        borderColor: "transparent",
        // @ts-ignore
        color: state.isSelected ? layout[theme][variant].fontColorMenuSelected : layout[theme][variant].fontColorMenuInactive,
        // @ts-ignore
        backgroundColor: layout[theme][variant].bcgColorInput,
        "&:hover": {
          cursor: "pointer"
        }
      };
    }
  };
};

const computeVariant = (variant: any, layout: any) => (layout.light[variant] ? variant : "primary");
const path = (layout: any, props: any) => layout[props.theme.name || "light"][props.variant];

const Styledlabel = styled.label<{ variant?: string }>`
  font-style: normal;
  font-weight: 600;
  font-size: inherit;
  line-height: 1;

  color: ${props => path(layout, props).fontColorLabel};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Select = (props: any) => {
  const { t } = useTranslation();
  const variant = computeVariant(props.variant, layout);
  const { name: theme } = useContext(ThemeContext);

  const Component: any = props.withPagination ? AsyncPaginate : ReactSelect;

  return (
    <Container className="cg-common cg-select">
      {props.label && (
        <Styledlabel
          htmlFor={props.id || props.name || ""}
          className={`cg-common ${variant} ${props.className || ""}-label`}
          variant={variant}>
          {props.label}
        </Styledlabel>
      )}
      <Component
        {...props}
        className={`select ${props.className || ""}`}
        styles={computeStyles({ variant, theme, label: props.label })}
        isMulti={props.isMulti || false}
        classNamePrefix="select"
        isDisabled={props.readOnly || props.readonly || props.disabled}
        placeholder={props.placeholder ? props.placeholder : t("Select") + "..."}
      />
    </Container>
  );
};

const AsyncCreatable = withAsyncPaginate(Creatable);
const SelectCreatable = (props: any) => {
  const { t } = useTranslation();
  const variant = computeVariant(props.variant, layout);
  const { name: theme } = useContext(ThemeContext);

  const Component: any = props.withPagination ? AsyncCreatable : Creatable;

  return (
    <Container className="cg-common cg-select cg-selectCreatable">
      {props.label && (
        <Styledlabel
          htmlFor={props.id || props.name || ""}
          className={`cg-common ${variant} ${props.className || ""}-label`}
          variant={variant}>
          {props.label}
        </Styledlabel>
      )}
      <Component
        {...props}
        className={props.className || "select"}
        classNamePrefix="select"
        styles={computeStyles({ variant, theme, label: props.label })}
        isMulti={props.isMulti || false}
        isClearable={props.isClearable === undefined || false}
        placeholder={props.placeholder ? props.placeholder : t("Select") + "..."}
      />
    </Container>
  );
};

const SelectAsync = (props: any) => {
  const { t } = useTranslation();
  const variant = props.variant || "primary";
  const { name: theme } = useContext(ThemeContext);

  return (
    <Container className="cg-common cg-select cg-selectAsync">
      {props.label && (
        <Styledlabel
          htmlFor={props.id || props.name || ""}
          className={`cg-common ${variant} ${props.className || ""}-label`}
          variant={variant}>
          {props.label}
        </Styledlabel>
      )}
      <Async
        className={`select ${props.className || ""}`}
        styles={computeStyles({ variant, theme, label: props.label })}
        classNamePrefix="select"
        isDisabled={props.readonly}
        placeholder={props.placeholder ? props.placeholder : t("Select") + "..."}
        {...props}
      />
    </Container>
  );
};

export { Select, SelectAsync, SelectCreatable };
