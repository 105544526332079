import React from "react";
import styled from "styled-components";

import colors from "@common-ground-io/common-assets/assets/colors.json";

const layout = {
  light: {
    primary: {
      fontColorLabel: colors.grey,
      bcgColorInput: colors.greyLighter,
      iconColor: "transparent",
      iconColorActive: colors.greyLight
    },
    overZone: {
      fontColorLabel: colors.grey,
      bcgColorInput: colors.greyLight,
      iconColor: "transparent",
      iconColorActive: colors.greyLightest
    }
  },
  dark: {
    primary: {
      fontColorLabel: colors.grey,
      bcgColorInput: colors.greyDark,
      iconColor: "transparent",
      iconColorActive: colors.greyLighter
    },
    overZone: {
      fontColorLabel: colors.grey,
      bcgColorInput: colors.greyDark,
      iconColor: "transparent",
      iconColorActive: colors.greyLighter
    }
  }
};

const Styledlabel = styled.label<{ variant?: string }>`
  font-style: normal;
  font-weight: 600;
  font-size: inherit;
  line-height: 1;
  margin-bottom: 10px;
  color: ${props => path(layout, props).fontColorLabel};
`;

const StyledInputlabel = styled.label<{ variant?: string }>`
  font-style: normal;
  font-weight: 400;
  font-size: inherit;
  line-height: 1;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: ${props => path(layout, props).fontColorLabel};
`;

const HiddenRadioItem = styled.input.attrs({ type: "radio" })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledRadio = styled.div<{ variant?: string; checked?: boolean }>`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${props => path(layout, props).bcgColorInput};
  border-radius: 10px;
  transition: all 150ms;
  margin-right: 5px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 5px;
`;

const Icon = styled.svg<{ checked?: boolean; variant?: string }>`
  fill: ${props => (props.checked ? path(layout, props).iconColorActive : path(layout, props).iconColor)};
  stroke-width: 2px;
`;

const StyledCircle = styled.circle.attrs({ cx: 12, cy: 12, r: 6 })<{ checked?: boolean; variant?: string }>`
  fill: ${props => (props.checked ? path(layout, props).iconColorActive : path(layout, props).iconColor)};
`;

const computeVariant = (variant: any, layout: any) => (layout.light[variant] ? variant : "primary");
const path = (layout: any, props: any) => layout[props.theme.name || "light"][props.variant];

const Radio = (props: any) => {
  const variant = computeVariant(props.variant, layout);
  const { entries = [] } = props;

  return (
    <Container className={`cg-common cg-radio ${variant}`}>
      {props.label ? <Styledlabel variant={variant}>{props.label}</Styledlabel> : null}
      {entries.map((e: any, i: number) => (
        <StyledInputlabel variant={variant} key={i}>
          <HiddenRadioItem {...props} variant={variant} name={props.name} checked={props.value === e.value} entry={e} value={e.value} />
          <StyledRadio variant={variant} checked={props.value === e.value}>
            <Icon viewBox="0 0 24 24" variant={variant} name={undefined}>
              <StyledCircle variant={variant} checked={props.value === e.value} />
            </Icon>
          </StyledRadio>
          {e.label}
        </StyledInputlabel>
      ))}
    </Container>
  );
};

export default Radio;
