import { useEffect, useCallback } from "react";
import WaveSurfer from "wavesurfer.js";
import { PlayerStore } from "../stores/player";
import clone from "clone";
import { Item, ReleaseTrack } from "../__generated__/graphql";

export const PlayerContext = () => {
  const { wavesurfer } = PlayerStore.useState(c => c);

  const primary = "#000";
  const secondary = "#86d3ff";

  const loadTrack = useCallback(
    (track: ReleaseTrack, release: Item) => {
      try {
        wavesurfer.load(track.uri);
        PlayerStore.update(s => {
          s.isShowing = true;
          s.playedTrackData = { track: clone(track), release: clone(release) };
        });
      } catch (e: any) {
        console.log(e);
      }
    },
    [wavesurfer]
  );

  const playPauseTrack = useCallback(() => {
    wavesurfer.playPause();
    PlayerStore.update(s => {
      s.isPlaying = !s.isPlaying;
    });
  }, [wavesurfer]);

  const stopAndClose = useCallback(() => {
    wavesurfer.stop();
    PlayerStore.update(s => {
      s.isPlaying = false;
      s.isShowing = false;
    });
  }, [wavesurfer]);

  useEffect(() => {
    if (!wavesurfer) return;
    PlayerStore.update(s => {
      s.stopAndClose = stopAndClose;
      s.playPauseTrack = playPauseTrack;
      s.loadTrack = loadTrack;
    });
    wavesurfer.on("ready", () => {
      wavesurfer.play();
      PlayerStore.update(s => {
        s.isPlaying = true;
      });
    });
    return () => {
      wavesurfer.destroy();
      console.log("Shutting down player");
    };
  }, [wavesurfer]);

  useEffect(() => {
    PlayerStore.update(s => {
      s.wavesurfer = WaveSurfer.create({
        normalize: true,
        hideScrollbar: true,
        container: "#waveform",
        waveColor: primary,
        progressColor: secondary,
        height: 23.5
      });
    });
  }, [primary, secondary]);

  return null;
};
