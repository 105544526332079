import React, { useState } from "react";
import Address from "./address";
import Button from "../styled/button";
import Input from "../styled/input";
import TextArea from "../styled/textArea";
import { POST_USER_VERIFY, POST_USER_BUYER_CREATE_ESHOP_ACCOUNT, POST_USER_CREATE_PASSWORD_TOKEN } from "../../graphql/queries/user";
import { useMutation } from "@apollo/client";
import Loader from "../common/loader";
import { Lists } from "./list";
import moment from "moment";
import { POST_LIST_ADD, POST_LIST_REMOVE } from "../../graphql/queries/list";
import { Buyer, List } from "../../__generated__/graphql";
import { AddNotification } from "../../types/globals";
import { Button as ButtonV2 } from "../../componentsV2/Button";
import { useTranslation } from "react-i18next";

export function EditUser({
  user,
  dirty,
  addNotification,
  getUser,
  handleUpdate,
  lists,
  handleChange,
  removeAddress,
  handleAddressChange,
  addNewAddress
}: {
  user: Buyer;
  dirty: boolean;
  addNotification: AddNotification;
  getUser: any;
  handleUpdate: any;
  lists: List[];
  handleChange: any;
  removeAddress: any;
  handleAddressChange: any;
  addNewAddress: any;
}) {
  const [isVerifying, setIsVerifying] = useState(false);
  const [verify] = useMutation(POST_USER_VERIFY);
  const [isCreating, setIsCreating] = useState(false);
  const [createPasswordToken, { loading: createPasswordTokenLoading }] = useMutation(POST_USER_CREATE_PASSWORD_TOKEN);
  const [createEshopAccount] = useMutation(POST_USER_BUYER_CREATE_ESHOP_ACCOUNT);
  const [addToList, { loading: addingToList }] = useMutation(POST_LIST_ADD);
  const [removeFromList, { loading: removingFromList }] = useMutation(POST_LIST_REMOVE);
  const { t } = useTranslation();

  const handleSendVerificationEmail = async (user: Buyer) => {
    try {
      setIsVerifying(true);
      const { data } = await verify({ variables: { userRef: user._id } });
      if (data?.userVerify) addNotification({ ok: 1, message: data.userVerify });
    } catch (error: any) {
      addNotification({ ok: 0, message: error.message });
    } finally {
      setIsVerifying(false);
    }
  };

  const handlePasswordReset = async () => {
    const email = user.email;
    try {
      if (!email) throw new Error("Invalid email address");
      await createPasswordToken({ variables: { email } });
      addNotification({ ok: 1, message: "Password reset email was sent" });
    } catch (e: any) {
      addNotification({ ok: 0, message: e.data || e.toString() });
    }
  };

  const handleAddToList = async (user: Buyer, listRef: string) => {
    if (addingToList) return;
    try {
      await addToList({ variables: { listRef, userRefs: [user._id] } });
      await getUser(user._id);
    } catch (e: any) {
      addNotification({ ok: 0, message: e.data || e.toString() });
    }
  };

  const handleRemoveFromList = async (user: Buyer, listRef: string) => {
    if (removingFromList) return;
    try {
      await removeFromList({ variables: { listRef, userRefs: [user._id] } });
      await getUser(user._id);
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
  };

  const handleCreateEshopAccount = async (user: Buyer) => {
    try {
      setIsCreating(true);
      const { data } = await createEshopAccount({ variables: { userRef: user._id } });
      await getUser(user._id);
      if (data?.userBuyerCreateEshopAccount) addNotification({ ok: 1, message: "Eshop account created" });
    } catch (error: any) {
      addNotification({ ok: 0, message: error.message });
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <form id="editUser" onSubmit={handleUpdate}>
      <div className="header">
        <span />
        <ButtonV2 type="submit" disabled={!dirty} variant="primary">
          {t("Submit")}
        </ButtonV2>
      </div>
      <div className="content">
        <div className="left">
          <div className="infos">
            <p>
              {t("Created")} {moment(user.created).fromNow()}
            </p>
            <p>
              {t("Last login")}: {user.lastLogin ? moment(user.lastLogin).fromNow() : "Never"}
            </p>
          </div>
          {!user.accountCreated ? (
            <div>
              <p>{t("Eshop account not created")}</p>
              <Button type="button" variant="secondary" disabled={isCreating} onClick={() => handleCreateEshopAccount(user)}>
                {isCreating ? <Loader /> : "Create Eshop account"}
              </Button>
            </div>
          ) : !user.verification?.status ? (
            <div>
              <p>{t("Email address not verified")}</p>
              <Button type="button" variant="secondary" disabled={isVerifying} onClick={() => handleSendVerificationEmail(user)}>
                {isVerifying ? <Loader /> : "Send verification email"}
              </Button>
            </div>
          ) : null}
          {user.accountCreated ? (
            <div>
              <p>{t("Create a password reset token")}</p>
              <Button variant="secondary" type="button" onClick={handlePasswordReset}>
                {createPasswordTokenLoading ? <Loader /> : t("Send password reset email")}
              </Button>
            </div>
          ) : null}
          {lists && lists.length ? (
            <div id="lists">
              <div id="listButtons">
                <Lists addToList={handleAddToList} removeFromList={handleRemoveFromList} lists={lists} user={user} />
              </div>
            </div>
          ) : null}
          <TextArea
            name="notes"
            value={user.notes || ""}
            onChange={handleChange}
            rows="10"
            label={t("Add a note")}
            placeholder={t("Add a note") + "..."}
          />
        </div>
        <div className="right">
          <div id="fields">
            <Input
              label={t("First name")}
              required
              placeholder={t("First name") + "..."}
              type="text"
              name="firstName"
              value={user.firstName || ""}
              onChange={handleChange}
            />
            <Input
              label={t("Last name")}
              required
              placeholder={t("Last name") + "..."}
              type="text"
              name="lastName"
              value={user.lastName || ""}
              onChange={handleChange}
            />
            <Input
              label={t("Organization")}
              placeholder={t("Organization") + "..."}
              type="text"
              name="organisation"
              value={user.organisation || ""}
              onChange={handleChange}
            />
            <Input
              label={t("Tax ID")}
              placeholder={t("Tax ID") + "..."}
              type="text"
              name="taxNumber"
              value={user.taxNumber || ""}
              onChange={handleChange}
            />
            <Input
              label={t("Phone")}
              placeholder={t("Phone") + "..."}
              type="text"
              name="telephone"
              value={user.telephone || ""}
              onChange={handleChange}
            />
            <Input
              label={t("Email")}
              required
              type="text"
              placeholder={t("Email") + "..."}
              name="email"
              readOnly={false}
              value={user.email || ""}
              onChange={handleChange}
            />
          </div>
          <div className="addresses">
            {user.addresses &&
              user.addresses.map((a, index) => (
                <Address key={index} index={index} removeAddress={removeAddress} handleChange={handleAddressChange} address={a} />
              ))}
            {user.addresses.length < 2 && (
              <Button variant="secondary" type="button" onClick={addNewAddress}>
                {t("Add an address")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </form>
  );
}

export default EditUser;
