import React from "react";
import Button from "../styled/button";
import { PlayerStore } from "../../stores/player";
import { Item, ReleaseTrack } from "../../__generated__/graphql";
import { ItemEditable } from "../items/edit.release";

export function PlayerButton({ track, release }: { track: ReleaseTrack; release: Item | ItemEditable }) {
  const { loadTrack, playPauseTrack, playedTrackData, isPlaying } = PlayerStore.useState(c => c);

  const buttonIsPlaying = isPlaying && playedTrackData.track.uri === track.uri;
  return (
    <Button
      variant="noStyle"
      key={track.position}
      onClick={(e: any) => {
        e.preventDefault();
        if (buttonIsPlaying) playPauseTrack();
        else loadTrack(track, release);
      }}
      className="player">
      {buttonIsPlaying ? <i className="cg-icon-pause" /> : <i className="cg-icon-play" />}
    </Button>
  );
}
