import { Store } from "pullstate";
import { Config, Session } from "../__generated__/graphql";
import { EThemes } from "../types/globals";
import { StickerEntry } from "../components/stickers/stickers";

interface StoreTypes {
  config?: Config | null;
  session?: Session | null;
  addNotification: any;
  invoices: any;
  stickers: {
    entries: StickerEntry[];
  };
  register: any;
  helpPanel: any;
  isMobile: boolean;
  version?: string;
  isInEditorView: boolean;
  isBelowIpad: boolean;
  menuOverContent: boolean;
  menuIsCollapsed: boolean;
  configReload?: any; // Todo transform to async action
  logout?: any;
  login?: any;
  loginWithSSO?: any;
  adminVerify?: any;
  adminCreate?: any;
  editingTheme?: boolean;
  theme: EThemes | undefined;
  apolloClientArticles?: any;
  tutorial: {
    minimized: boolean;
    hidden: boolean;
  };
}

export const GlobalStore = new Store<StoreTypes>({
  config: undefined,
  session: undefined,
  login: undefined,
  loginWithSSO: undefined,
  adminCreate: undefined,
  adminVerify: undefined,
  addNotification: undefined,
  isInEditorView: false,
  isBelowIpad: false,
  menuOverContent: false,
  version: "",
  editingTheme: false,
  menuIsCollapsed: false,
  invoices: [],
  stickers: { entries: [] },
  register: {},
  helpPanel: {},
  isMobile: false,
  theme: EThemes.light,
  tutorial: {
    minimized: localStorage.getItem("tutorial-minimized") === "true" || false,
    hidden: localStorage.getItem("tutorial-hidden") === "true" || false
  }
});
