import React, { useEffect, useState, useCallback } from "react";
import compareVersion from "compare-versions";
import URI from "urijs";
import axios from "axios";
import { Button } from "../componentsV2/Button";
import { useTranslation } from "react-i18next";

export default function Version() {
  const [currentVersion] = useState(process.env.REACT_APP_VERSION);
  const [recentVersion, setRecentVersion] = useState();
  const currentUri = new URI(window.location.href);
  const { t } = useTranslation();

  const getVersion = useCallback(async () => {
    try {
      const { data } = await axios.get(currentUri.origin() + "/manifest.json");
      return data.version;
    } catch (e: any) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    const id = setInterval(() => {
      getVersion().then(version => setRecentVersion(version));
    }, 600000);

    return () => clearInterval(id);
  }, []);

  const handleReload = () => {
    window.location.reload();
  };

  const results = recentVersion && currentVersion && compareVersion(recentVersion, currentVersion);

  if (!results || results < 1 || !recentVersion || !currentVersion) return null;

  return (
    <div id="version">
      <div className="header">
        <p>{t("A new version of your backoffice is available")}</p>
      </div>
      <div style={{ display: "grid", justifyContent: "center" }}>
        <Button type="button" variant="primary" onClick={handleReload}>
          {t("Load the latest version")}
        </Button>
      </div>
    </div>
  );
}
