import React, { useState, useEffect, useRef } from "react";
import { GlobalStore } from "../../stores/global";
import DatePicker from "../styled/datePicker";
import Input from "../styled/input";
import Radio from "../styled/radio";
import Checkbox from "../styled/checkbox";
import ButtonV1 from "../styled/button";
import { Button } from "../../componentsV2/Button";
import { Select, SelectCreatable } from "../styled/select";
import { BulkStore } from "../../stores/bulk";
import { GET_INVENTORY_BULK_ESTIMATE, POST_ITEM_LISTING_BULK_UPDATE } from "../../graphql/queries/item";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import clone from "clone";
import moment from "moment";
import Modal from "../modal";
import Loader from "../common/loader";
import { SelectSupplier } from "../common/select";
import { useTranslation } from "react-i18next";
import { Config, Item } from "../../__generated__/graphql";
import { TFunction } from "i18next";
import { GET_CONFIG_METADATA_INVENTORY } from "../../graphql/queries/config";
import { AddNotification } from "../../types/globals";
import { ModalHeaderContainer } from "../../componentsV2/SectionHeader/SectionHeader.styles";
import { Typography } from "../../componentsV2/Typography";
import { StickerEntry } from "../stickers/stickers";
import { Link } from "react-router-dom";

export default () => {
  const { t } = useTranslation();
  const { addNotification, config } = GlobalStore.useState(c => c);
  const inventoryBulkStore = BulkStore.useState(c => c.inventory);
  const modalRef = useRef<any>();

  const handleClearBulkStore = async () => {
    BulkStore.update(s => {
      s.inventory.listings = [];
      s.inventory.entries = [];
    });
  };

  const listingSelected = inventoryBulkStore?.listings?.length > 0;
  return (
    <div id="bulkInventory">
      <Modal ref={modalRef} style={{ minWidth: "50%" }}>
        <BulkFieldsEditor addNotification={addNotification} t={t} config={config as Config} close={() => modalRef.current.close()} />
      </Modal>
      <div style={{ display: "flex", gap: "var(--gutter)" }}>
        <Button
          variant={inventoryBulkStore?.listings?.length ? "primary" : "secondary"}
          onClick={() => modalRef.current.open()}
          disabled={!listingSelected}>
          {inventoryBulkStore.listings?.length ? t("Edit") + ` ${inventoryBulkStore.listings?.length} ` + t("Listings") : t("Bulk edit")}
        </Button>
        {listingSelected ? (
          <Button onClick={() => handleClearBulkStore()} variant="secondary">
            {t("Clear")}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

const BulkFieldsEditor = ({
  addNotification,
  config,
  t,
  close
}: {
  addNotification: AddNotification;
  config: Config;
  t: TFunction;
  close: () => void;
}) => {
  const { data, refetch } = useQuery(GET_CONFIG_METADATA_INVENTORY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only"
  });

  const inventoryBulkStore = BulkStore.useState(c => c.inventory);
  const [fields, setFields] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const hasValidFields = !!fields?.find((f: any) => f.value !== undefined);

  const [getEstimate, { loading, data: bulkEstimateData }] = useLazyQuery(GET_INVENTORY_BULK_ESTIMATE);
  const [inventoryBulkUpdate] = useMutation(POST_ITEM_LISTING_BULK_UPDATE);
  const isLegacyPrinting = localStorage.getItem(`${config.id}-legacyPrinting`) === "true";

  useEffect(() => {
    if (!config || !data?.configMetadata) return;
    const { categories, locations } = data?.configMetadata;
    setFields([
      {
        title: "Categories",
        id: "categories",
        multiValue: true,
        search: null,
        value: undefined,
        clearable: true,
        type: "dropdownCreatable",
        updateType: "replace",
        updateTypes: [
          { label: t("Find & replace"), value: "findAndReplace" },
          { label: t("Replace all"), value: "replace" },
          { label: t("Append"), value: "append" },
          { label: t("Remove all"), value: "clear" }
        ],
        values: categories?.map(l => ({ label: l, value: l }))
      },
      {
        title: t("Price"),
        id: "price",
        value: undefined,
        type: "number",
        updateType: "replace"
      },
      {
        title: t("Stock quantity"),
        id: "stock.quantity",
        value: undefined,
        type: "number",
        updateType: "replace"
      },
      {
        title: t("Location"),
        updateType: "replace",
        clearable: true,
        id: "location",
        value: undefined,
        type: "dropdownCreatable",
        values: [{ label: "-", value: "clearValue" }, ...(locations?.map(l => ({ label: l, value: l })) || [])]
      },
      {
        title: t("Supplier"),
        updateType: "replace",
        clearable: true,
        id: "supplierCode",
        value: undefined,
        type: "dropdownCreatable",
        values: []
      },
      {
        title: t("Status"),
        id: "status",
        value: undefined,
        type: "dropdown",
        updateType: "replace",
        values: [
          { label: t("Published"), value: "published" },
          { label: t("Private"), value: "private" },
          { label: t("Draft"), value: "draft" }
        ]
      },
      {
        title: t("Second hand") + "/" + t("New"),
        id: "secondHand",
        value: undefined,
        type: "dropdown",
        updateType: "replace",
        values: [
          { label: t("Second hand"), value: true },
          { label: t("New"), value: false }
        ]
      },
      { title: t("Available"), updateType: "replace", id: "available", value: undefined, type: "date" },
      {
        title: t("Pre order"),
        id: "preOrder",
        value: undefined,
        type: "dropdown",
        updateType: "replace",
        values: [
          { label: t("Set as pre order"), value: true },
          { label: t("Unset as pre order"), value: false }
        ]
      }
    ]);
  }, [data]);

  useEffect(() => {
    if (fields && inventoryBulkStore?.listings?.length && fields.filter((f: any) => f.value).length)
      getEstimate({
        variables: { fields: fields.filter((f: any) => f.value), listingRefs: clone(inventoryBulkStore.listings) }
      });
  }, [fields, inventoryBulkStore]);

  const handlePrint = () => {
    GlobalStore.update(s => {
      s.stickers.entries = inventoryBulkStore.entries;
    });
    BulkStore.update(s => {
      s.inventory.entries = [];
      s.inventory.listings = [];
    });
    close();
    addNotification({
      ok: 1,
      message: (
        <p>
          {t("Listing added to print queue")} <br />
          <br /> <Link to="/stickers">{t("Print now")}</Link>
        </p>
      )
    });
  };

  const handleSubmitJob = async (deleteField?: any) => {
    try {
      setIsLoading(true);
      let fieldsToSend;
      if (deleteField) {
        if (window.confirm("This will delete the current listing selection, are you sure ?")) {
          fieldsToSend = [deleteField];
        } else return;
      } else {
        fieldsToSend = clone(fields).filter((f: any) => f.value !== undefined);
        if (fieldsToSend.length === 0) return addNotification({ ok: 0, message: "One field must be selected" });
        for (const fieldToSend of fieldsToSend) {
          if (fieldToSend.value === undefined) return addNotification({ ok: 0, message: "A field cannot be empty" });
          else if (
            fieldToSend.id === "categories" &&
            fieldToSend.updateType === "findAndReplace" &&
            (!fieldToSend.value?.find || !fieldToSend.value?.replace)
          )
            throw new Error("Find and replace fields are required");
        }
      }
      await inventoryBulkUpdate({
        variables: {
          fields: fieldsToSend.map((f: any) => ({ id: f.id, title: f.title, value: f.value, updateType: f.updateType })),
          listingRefs: inventoryBulkStore.listings
        }
      });
      BulkStore.update(s => {
        s.inventory = { listings: [], isRunning: true, entries: [] };
      });
      addNotification({ ok: 1, message: "Your bulk item edit will be processed shortly" });
      refetch().then(() => close());
    } catch (error: any) {
      addNotification({ ok: 0, message: error.message });
    } finally {
      setIsLoading(false);
    }
  };
  if (!fields) return <Loader />;

  return (
    <div id="bulkInventoryModal">
      <ModalHeaderContainer>
        <Typography variant="pageTitle" tag="h2">
          {t("Bulk editor") + " (" + inventoryBulkStore.listings.length + " " + t("Listings") + ")"}
        </Typography>
        <button className="reset" type="button" onClick={close}>
          <i className="cg-icon-burger-close" />
        </button>
      </ModalHeaderContainer>
      <div className="content" style={{ margin: "calc(var(--gutter)*2) 0" }}>
        <FieldEditor fields={fields} setFields={setFields} />
      </div>
      <div className="footer">
        <div style={{ display: "flex" }}>
          {hasValidFields ? (
            <p>
              {t("Estimated process time")}: {bulkEstimateData?.inventoryBulkEstimate?.durationString || t("Calculating")}
            </p>
          ) : (
            <Button
              variant="warning"
              disabled={loading || isLoading}
              onClick={() => handleSubmitJob({ title: "Delete", id: "delete", value: "delete" })}
              styleProps={{ margin: "0 var(--gutter)" }}>
              {t("Delete listings")}
            </Button>
          )}
          {!isLegacyPrinting ? (
            <Button
              variant="secondary"
              disabled={loading || isLoading}
              onClick={() => handlePrint()}
              styleProps={{ margin: "0 var(--gutter)" }}>
              {t("Print")}
            </Button>
          ) : null}
        </div>
        <Button
          variant="primary"
          disabled={loading || isLoading || inventoryBulkStore.isRunning || !hasValidFields}
          onClick={() => handleSubmitJob()}>
          {t("Submit bulk update")}
        </Button>
      </div>
    </div>
  );
};

export const BulkInventorySelectAll = ({ items }: { items: Item[] }) => {
  const { t } = useTranslation();
  const inventoryBulkStore = BulkStore.useState(c => c.inventory);
  const listingRefs: string[] = [];
  items.forEach(i => i.listings.forEach(l => listingRefs.push(l._id)));
  let allSelected = listingRefs.length > 0;

  for (const l of listingRefs) {
    if (inventoryBulkStore.listings.indexOf(l) === -1) allSelected = false;
  }

  const handleSelectAll = (e: any) => {
    const set = new Set(inventoryBulkStore.listings);
    if (!e.target.checked) {
      listingRefs.forEach(l => set.delete(l));
      const copiedEntries = clone(inventoryBulkStore.entries).filter(e => listingRefs.indexOf(e.listing._id) === -1);
      BulkStore.update(s => {
        s.inventory.listings = Array.from(set);
        s.inventory.entries = copiedEntries;
      });
    } else if (e.target.checked) {
      const entries: StickerEntry[] = [];
      items.forEach(i => {
        i.listings.forEach(l => {
          entries.push({ item: i, listing: l });
        });
      });
      listingRefs.forEach(l => set.add(l));
      BulkStore.update(s => {
        s.inventory.listings = Array.from(set);
        s.inventory.entries = entries;
      });
    }
  };

  return (
    <Checkbox
      label={allSelected ? t("Deselect all") : t("Select all")}
      disabled={inventoryBulkStore.isRunning}
      onChange={handleSelectAll}
      name="all"
      checked={allSelected}
    />
  );
};

const FieldEditor = ({ fields, setFields }: { fields: any; setFields: any }) => {
  const { t } = useTranslation();
  const handleFieldChange = (e: any) => {
    const field = fields.find((f: any) => f.id === e.target.name);
    if (!field) return;
    field.value = e.target.value;
    setFields([...fields]);
  };

  const handleDropdownChange = async (values: any, event: any, name: any) => {
    const field = fields.find((f: any) => f.id === name);
    if (event.name) {
      if (!field.value) field.value = {};
      if (!Array.isArray(values)) field.value[event.name] = values.value;
      else if (Array.isArray(values) && !values.length) field.value[event.name] = null;
      else if (Array.isArray(values)) field.value[event.name] = values.map((v: any) => v.value);
    } else {
      if (!Array.isArray(values)) {
        field.value = values?.value;
        if (field.value === "clearValue" && field.clearable) field.updateType = "clear";
      } else if (Array.isArray(values) && !values.length) field.value = null;
      else if (Array.isArray(values)) field.value = values.map((v: any) => v.value);
    }
    setFields([...fields]);
  };

  const handleClear = (e: any, name: string) => {
    e.preventDefault();
    const field = fields.find((f: any) => f.id === name);
    field.value = undefined;
    field.updateType = "replace";
    setFields([...fields]);
  };

  const handleDateChange = (date: string) => {
    const field = fields.find((f: any) => f.id === "available");
    field.value = moment(date).format();
    setFields([...fields]);
  };

  const handUpdateTypeChange = (e: any, id: string) => {
    const field = fields.find((f: any) => f.id === id);
    field.updateType = e.target.value;
    field.value = null;
    if (field.updateType === "clear") field.value = "clearValue";
    setFields([...fields]);
  };

  return (
    <div className="bulkInventoryFields">
      {fields.map((f: any) => (
        <div key={f.id} className="field" style={{ gridArea: f.id }}>
          <div className={"labelHeader" + (f.value ? " active" : "")}>
            {f.title}
            {f.updateTypes ? (
              <div className="updateType">
                <Radio onChange={(e: any) => handUpdateTypeChange(e, f.id)} value={f.updateType} entries={f.updateTypes} />
              </div>
            ) : (
              <span />
            )}
            {f.value !== undefined ? (
              <ButtonV1 variant="noStyle" type="button" onClick={(e: any) => handleClear(e, f.id)}>
                {t("Clear")}
              </ButtonV1>
            ) : (
              <span />
            )}
          </div>
          <div className="fieldContent">
            {f.type === "text" ? (
              <Input type="text" placeholder={`${f.title}...`} onChange={handleFieldChange} name={f.id} value={f.value || ""} />
            ) : null}
            {f.type === "number" ? (
              <Input type="number" placeholder={`${f.title}...`} onChange={handleFieldChange} name={f.id} value={f.value || ""} />
            ) : null}
            {f.type === "date" ? (
              <>
                <DatePicker onChange={handleDateChange} name={f.id} value={f.value || ""} />
              </>
            ) : null}
            {f.type === "dropdown" ? (
              <div className="dropdown">
                <Select
                  isMulti={f.multiValue}
                  options={f.values}
                  value={f.value !== undefined ? f.values.find((v: any) => v.value === f.value) : null}
                  onChange={(a: any, b: any) => handleDropdownChange(a, b, f.id)}
                />
              </div>
            ) : null}
            {f.type === "dropdownCreatable" ? (
              <div className="dropdownCreatable">
                {f.updateType === "findAndReplace" ? (
                  <div className={f.updateType}>
                    <Select
                      isMulti={false}
                      options={f.values.filter((f: any) => f.value !== "clearValue")}
                      label={t("Find")}
                      name="find"
                      value={f.value && f.value.find ? f.values.find((v: any) => v.value === f.value.find) : null}
                      onChange={(a: any, b: any) => handleDropdownChange(a, b, f.id)}
                    />
                    <SelectCreatable
                      isMulti={false}
                      options={[{ label: "-", value: "clearValue" }, ...f.values.filter((v: any) => v.value !== f.value?.find)]}
                      label={t("Replace")}
                      name="replace"
                      value={f.value && f.value.replace ? f.values.find((v: any) => v.value === f.value.replace) : null}
                      onChange={(a: any, b: any) => handleDropdownChange(a, b, f.id)}
                    />
                  </div>
                ) : f.updateType === "clear" ? (
                  <p>{t("Field will be cleared")}</p>
                ) : f.id === "supplierCode" ? (
                  <SelectSupplier
                    isMulti={f.multiValue}
                    selectOnly={true}
                    value={
                      f.value
                        ? Array.isArray(f.value)
                          ? f.value.map((v: any) => ({ label: v, value: v }))
                          : { value: f.value, label: f.value }
                        : null
                    }
                    onChange={(a: any, b: any) => handleDropdownChange(a, b, f.id)}
                  />
                ) : (
                  <SelectCreatable
                    isMulti={f.multiValue}
                    options={f.values}
                    isClearable={false}
                    value={
                      f.value
                        ? Array.isArray(f.value)
                          ? f.value.map((v: any) => ({ label: v, value: v }))
                          : { value: f.value, label: f.value }
                        : null
                    }
                    onChange={(a: any, b: any) => handleDropdownChange(a, b, f.id)}
                  />
                )}
              </div>
            ) : null}
          </div>
        </div>
      ))}
    </div>
  );
};
