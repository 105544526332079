import React from "react";
import Zone from "../../components/styled/zone";

const Liner = (props: any) => {
  const { index, children, className } = props;
  const overZone = !(index % 2);
  if (overZone)
    return (
      <Zone {...props} className={className} overZone={overZone}>
        {children}
      </Zone>
    );
  else
    return (
      <Zone {...props} inverted className={className}>
        {children}
      </Zone>
    );
};

export default Liner;
