import { gql } from "../../__generated__/gql";

gql(`
  fragment NotificationFields on Notification {
    _id
    link {
      type
      target
    }
    created
    message
    read
  }
`);

export const GET_NOTIFICATIONS = gql(`
  query notificationsConnection($page: Int) {
    notificationsConnection(page: $page) {
      notifications {
        ...NotificationFields
      }
      pagination {
        pages
        page
      }
      unreadCount
    }
  }
`);

gql(`
  fragment UserNotificationSettingsFields on UserNotificationsSettings {
    _id
    newOnlineOrder { email backoffice }
    newDiscogsOrder { email backoffice }
    orderPaymentReceived { email backoffice }
    discogsImportWarning { email backoffice }
    newOrderBuyerMessage { email backoffice }
    newOrderAdminMessage { email backoffice }
    newBuyerAccount { email backoffice }
    newGiftCardIssued { email backoffice }
    newSupportMessage { email backoffice }
  }
`);

export const GET_NOTIFICATION_SETTINGS = gql(`
  query notificationsSettings {
    notificationsSettings {
      ...UserNotificationSettingsFields
    }
  }
`);

export const POST_NOTIFICATIONS_MARK_AS_READ = gql(`
  mutation notificationMarkAsRead($refs: [ID!]) {
    notificationMarkAsRead(refs: $refs) {
      notifications {
        ...NotificationFields
      }
      unreadCount
    }
  }
`);

export const POST_NOTIFICATIONS_SETTINGS = gql(`
  mutation notificationsSettingsUpdate($settings: UserNotificationsSettingsInput!) {
    notificationsSettingsUpdate(settings: $settings) {
      ...UserNotificationSettingsFields
    }
  }
`);
