import React, { useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button as ButtonV2 } from "../../componentsV2/Button";
import Loader from "../common/loader";
import Liner from "../common/liner";
import Input from "../styled/input";
import moment from "moment";
import { GlobalStore } from "../../stores/global";
import Modal from "../../components/modal";
import { useMutation, useQuery } from "@apollo/client";
import { GET_CAMPAIGNS, POST_CAMPAIGN_CREATE, POST_CAMPAIGN_DELETE } from "../../graphql/queries/campaign";
import Pagination from "../../components/common/pagination";
import URI from "urijs";
import { Typography } from "../../componentsV2/Typography";
import { useTranslation } from "react-i18next";
import { ModalHeaderContainer } from "../../componentsV2/SectionHeader/SectionHeader.styles";
import { Select } from "../styled/select";
import { GET_LISTS } from "../../graphql/queries/list";
import { List } from "../../__generated__/graphql";

export default function Campaigns() {
  const currentUri = new URI(location.pathname + location.search);
  const searchQuery = currentUri.search(true);
  const { addNotification } = GlobalStore.useState(c => c);
  const [isCreatingCampaign, setIsCreatingCampaign] = useState(false);
  const [campaignTitle, setCampaignTitle] = useState<string>("");
  const [campaignList, setCampaignList] = useState<List | null>(null);
  const { data, refetch } = useQuery(GET_CAMPAIGNS, {
    fetchPolicy: "cache-and-network",
    variables: { page: parseInt(searchQuery.page) || 1 }
  });
  const { data: listData } = useQuery(GET_LISTS);
  const [createCampaign] = useMutation(POST_CAMPAIGN_CREATE);
  const [deleteCampaign] = useMutation(POST_CAMPAIGN_DELETE);
  const modalRef = useRef<any>();
  const history = useHistory();
  const { t } = useTranslation();

  document.title = "Campaigns";

  const campaigns = data?.campaigns?.campaigns;
  const pagination = data?.campaigns?.pagination;
  const lists = listData?.lists?.lists;

  const handleDeleteCampaign = async (ref: string) => {
    deleteCampaign({ variables: { campaignRef: ref } })
      .then(() => refetch())
      .catch(e => addNotification({ ok: 0, message: e.message }));
  };

  const handleSelectList = (option: { value: List }) => {
    setCampaignList(option.value);
  };

  const handleCreateCampaign = async (e: any) => {
    e.preventDefault();
    if (isCreatingCampaign) return;
    try {
      setIsCreatingCampaign(true);
      const { data } = await createCampaign({ variables: { title: e.target.title.value, listRef: campaignList?._id } });
      refetch().then(() => {
        addNotification({ ok: 1, message: "Campaign created" });
        history.push(`/campaign/${data?.campaignCreate?._id}`);
      });
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    } finally {
      setIsCreatingCampaign(false);
    }
  };

  if (!campaigns || !pagination) return <Loader withMargins />;

  return (
    <div id="campaigns">
      <Modal style={{}} ref={modalRef}>
        <div id="newThemeModal">
          <form onSubmit={handleCreateCampaign}>
            <ModalHeaderContainer>
              <Typography variant="pageTitle" tag="h2">
                {t("Create a campaign")}
              </Typography>
              <button className="reset" type="button" onClick={() => modalRef.current.close()}>
                <i className="cg-icon-burger-close" />
              </button>
            </ModalHeaderContainer>
            <div style={{ marginBottom: "calc(var(--gutter) * 2)", display: "grid", gridGap: "calc(var(--gutter) * 2)" }}>
              <Select
                label={t("Select a list of recipients")}
                onChange={handleSelectList}
                value={campaignList ? { label: campaignList.title } : null}
                options={lists?.map(l => ({ value: l, label: l.title + " -  " + l.count + " recipients" })) || []}
              />
              <Input
                disabled={isCreatingCampaign}
                autoFocus={true}
                value={campaignTitle}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCampaignTitle(e.target.value)}
                label={t("Campaign title")}
                type="text"
                name="title"
                placeholder={t("Campaign title")}
                autoComplete="off"
                required
              />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span />
              <ButtonV2 variant="primary" disabled={isCreatingCampaign || !campaignTitle} type="submit">
                {isCreatingCampaign ? <Loader /> : t("Submit")}
              </ButtonV2>
            </div>
          </form>
        </div>
      </Modal>
      <section className="header">
        <Typography variant="pageTitle" tag="h1">
          {t("Campaigns")}
        </Typography>
        <ButtonV2 variant="primary" onClick={() => modalRef.current.open()} type="button">
          {t("Create a campaign")}
        </ButtonV2>
      </section>
      <section>
        {campaigns.length ? (
          <div id="campaignsTable">
            <div className="campaignTableEntry header">
              <p className="title">{t("Title")}</p>
              <p className="created">{t("Created")}</p>
              <p className="status">{t("Status")}</p>
              <p className="preview">{t("Preview")}</p>
              <p className="listings">{t("Listings")}</p>
              <p>{t("Action")}</p>
            </div>
            {campaigns.map((c, index: number) => (
              <Liner index={index} className="campaignTableEntry" key={c.id}>
                <Link to={`/campaign/${c._id}`} className="title">
                  {c.title}
                </Link>
                <p className="created">{moment(c.created).format("ll")}</p>
                <p className="status">{c.status}</p>
                <p>
                  {c.uri ? (
                    <a target="tab" href={c.uri}>
                      {t("Open web version")}
                    </a>
                  ) : null}
                </p>
                <p className="listings">{c.entriesCount}</p>
                {c.status === "draft" ? (
                  <ButtonV2
                    variant="warning"
                    onClick={() =>
                      window.confirm("Are you sure you wish to delete this campaign? It cannot be recovered") && handleDeleteCampaign(c._id)
                    }
                    type="button">
                    {t("Delete")}
                  </ButtonV2>
                ) : (
                  <span />
                )}
              </Liner>
            ))}
          </div>
        ) : (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <h2>{t("Get started by creating a new campaign")}</h2>
            <ButtonV2 variant="primary" onClick={() => modalRef.current.open()} type="button">
              {t("Create campaign")}
            </ButtonV2>
          </div>
        )}
      </section>
      <Pagination pagination={pagination} currentUri={currentUri} />
    </div>
  );
}
