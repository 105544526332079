import { useEffect, useState } from "react";

export const usePopupPosition = (
  containerRef: React.RefObject<HTMLDivElement>,
  popupRef: React.RefObject<HTMLDivElement>,
  isOpen: boolean
) => {
  const [popupPosition, setPopupPosition] = useState({ left: "0px", right: "unset" });

  const adjustPopupPosition = () => {
    if (!containerRef.current || !popupRef.current) {
      return;
    }

    const containerRect = containerRef.current.getBoundingClientRect();
    const popup = popupRef.current;
    const viewportWidth = window.innerWidth;

    if (containerRect.left + popup.offsetWidth > viewportWidth) {
      setPopupPosition({ right: "0px", left: "unset" });
    } else {
      setPopupPosition({ left: "0px", right: "unset" });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (isOpen) {
        adjustPopupPosition();
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isOpen]);

  useEffect(() => {
    adjustPopupPosition();
  }, [isOpen, popupRef?.current?.clientWidth]);

  return popupPosition;
};
