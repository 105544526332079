import React, { useState, useImperativeHandle } from "react";
import Input from "../../components/styled/input";
import Zone from "../../components/styled/zone";
import moment from "moment";
import { FaRegFileAlt } from "react-icons/fa";
import FileSize from "../common/fileSize";
import { findUrlForMedia } from "../../utils";
import { Media } from "../../__generated__/graphql";
import { useTranslation } from "react-i18next";

interface Props {
  media: Media;
}

export interface PropsToForward {
  getState: any;
}

// export default forwardRef<PropsToForward, Props>((props: Props, ref: any) => {

const Details = React.forwardRef<PropsToForward, Props>(({ media: mediaToCopy }, ref: any) => {
  const [media, setMedia] = useState({ ...mediaToCopy });
  const { t } = useTranslation();

  const handleChange = (e: any) => {
    setMedia({ ...media, [e.target.name]: e.target.value });
  };

  useImperativeHandle(ref, () => ({
    getState: () => media
  }));

  return (
    <div ref={ref} id="mediaDetails">
      <div className="left">
        <div className="image">
          {media.mime?.includes("image/") ? (
            <img src={findUrlForMedia(media, "medium")} />
          ) : (
            <i>
              <FaRegFileAlt />
            </i>
          )}
        </div>
      </div>
      <div className="right">
        <Zone className="specs">
          <div className="entry">
            <h3>{t("Size")}</h3>
            <p>
              <FileSize size={media.size as number} />
            </p>
          </div>
          <div className="entry">
            <h3>{t("Created date")}</h3>
            <p>{moment(media.created?.date).format("llll")}</p>
          </div>
          <div className="entry">
            <h3>{t("Dimensions")}</h3>
            <p>{media.width ? `${media.width}x${media.height}` : "-"}</p>
          </div>
          <div className="entry">
            <h3>{t("Extension")}</h3>
            <p>{media.ext?.toUpperCase()}</p>
          </div>
        </Zone>
        <form className="detailsForm">
          <Input label={t("File name")} type="text" name="title" autoComplete="off" value={media.title || ""} onChange={handleChange} />
          <Input
            label={t("Alternative text")}
            placeholder={t("Alternative text") + "..."}
            type="text"
            name="alt"
            autoComplete="off"
            onChange={handleChange}
            value={media.alt || ""}
          />
          <Input
            label={t("Caption")}
            type="text"
            placeholder={t("Caption") + "..."}
            name="caption"
            autoComplete="off"
            onChange={handleChange}
            value={media.caption || ""}
          />
        </form>
      </div>
    </div>
  );
});

export default Details;
