import React, { useState } from "react";
import styled from "styled-components";
import colors from "@common-ground-io/common-assets/assets/colors.json";

const inputLayout = {
  light: {
    primary: {
      fontColorLabel: colors.grey,
      bcgColorResult: colors.white,
      bcgColorResultSecondary: colors.greyLightest,
      bcgColorInput: colors.greyLightest,
      fontColorInput: colors.greyDarker,
      fontColorResult: colors.greyDarker,
      fontColorInputReadonly: colors.greyLight,
      fontColorPlaceholder: colors.grey
    },
    overZone: {
      fontColorLabel: colors.grey,
      bcgColorResult: colors.white,
      bcgColorResultSecondary: colors.greyLightest,
      bcgColorInput: colors.white,
      fontColorInput: colors.greyDarker,
      fontColorResult: colors.greyDarker,
      fontColorInputReadonly: colors.greyLight,
      fontColorPlaceholder: colors.greyDark
    }
  },
  dark: {
    primary: {
      fontColorLabel: colors.grey,
      bcgColorResult: colors.greyDarker,
      bcgColorResultSecondary: colors.greyDark,
      bcgColorInput: colors.greyDarker,
      fontColorInput: colors.greyLighter,
      fontColorResult: colors.greyLighter,
      fontColorResultHover: colors.greyLight,
      fontColorInputReadonly: colors.grey,
      fontColorPlaceholder: colors.grey
    },
    overZone: {
      fontColorLabel: colors.grey,
      bcgColorResult: colors.greyDark,
      bcgColorResultSecondary: colors.greyDarker,
      bcgColorInput: colors.greyDark,
      fontColorInput: colors.greyLighter,
      fontColorResult: colors.greyLighter,
      fontColorInputReadonly: colors.grey,
      fontColorPlaceholder: colors.greyLight
    }
  }
};

const iconLayout = {
  light: {
    primary: {
      bcgColor: colors.greyLightest,
      fontColor: colors.grey,
      borderColorHover: colors.grey,
      fontColorHover: colors.grey,
      bcgColorActive: colors.grey,
      borderColorActive: "transparent",
      fontColorActive: colors.greyLightest,
      separatorColor: colors.greyLighter
    },
    overZone: {
      bcgColor: colors.white,
      fontColor: colors.grey,
      borderColorHover: colors.grey,
      fontColorHover: colors.grey,
      bcgColorActive: colors.grey,
      borderColorActive: colors.grey,
      fontColorActive: colors.white,
      separatorColor: colors.greyLighter
    }
  },
  dark: {
    primary: {
      bcgColor: colors.greyDarker,
      fontColor: colors.greyLight,
      borderColorHover: colors.greyLight,
      fontColorHover: colors.greyLight,
      bcgColorActive: colors.greyLight,
      borderColorActive: "transparent",
      fontColorActive: colors.greyDark,
      separatorColor: colors.greyDark
    },
    overZone: {
      bcgColor: colors.greyDark,
      fontColor: colors.greyLight,
      borderColorHover: colors.greyLight,
      fontColorHover: colors.greyLight,
      bcgColorActive: colors.greyLight,
      borderColorActive: "transparent",
      fontColorActive: colors.greyDark,
      separatorColor: colors.grey
    }
  }
};

const Styledlabel = styled.label<{ variant?: string }>`
  font-style: normal;
  font-weight: 600;
  font-size: inherit;
  line-height: 1;

  color: ${props => path(inputLayout, props).fontColorLabel};
`;

const StyledInput = styled.input.attrs({ autoComplete: "off", autoFill: "off" })<{ variant?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 10px;
  border: 1px solid transparent;

  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  margin: 0;

  box-shadow: none;
  outline: none;

  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  font-family: inherit;
  line-height: 1;

  width: 100%;
  background-color: ${props => path(inputLayout, props).bcgColorInput};
  color: ${props => path(inputLayout, props).fontColorInput};

  &:read-only {
    color: ${props => path(inputLayout, props).fontColorInputReadonly};
  }
  ::placeholder {
    color: ${props => path(inputLayout, props).fontColorPlaceholder};
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
    transition-delay: 9999s;
  }
`;

const StyledIconZone = styled.div<{ variant?: string; fullWidth?: boolean }>`
  display: flex;
  align-items: center;
  height: auto;
  border: 1px solid transparent;

  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  padding: 4px;

  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  font-style: normal;
  line-height: 1;
  text-align: center;
  margin: 0;
  width: ${({ fullWidth }) => fullWidth && "100%"};
  background-color: ${props => path(iconLayout, props).bcgColor};
  color: ${props => path(iconLayout, props).fontColor};
`;

const StyledButton = styled.button<{ variant?: string }>`
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  background-color: transparent;
  color: ${props => path(iconLayout, props).fontColor};
`;

const StyledContainer = styled.form<{ label?: string }>`
  display: flex;
  align-content: flex-start;
  margin-top: ${props => (props.label ? "10px" : "0px")};
  position: relative;
`;

const Container = styled.div`
  display: block;
`;

const computeVariant = (variant: any, layout: any) => (layout.light[variant] ? variant : "primary");
const path = (layout: any, props: any) => layout[props.theme.name || "light"][props.variant];

const SearchComponent = (props: any) => {
  const variant = computeVariant(props.variant, inputLayout);
  const [isSubmitted, setIsSubmitted] = useState(!!props.term);
  const [term, setTerm] = useState(props.term || "");

  const changeHandler = (e: any) => {
    const value = e.target.value;
    setTerm(value);
  };

  const resetAndClose = () => {
    setTerm("");
    setIsSubmitted(false);
    if (props.onClear) props.onClear();
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setIsSubmitted(true);
    if (props.onSubmit) props.onSubmit(term);
  };

  return (
    <Container id={props.id || undefined} style={props.style || {}} className={`cg-common cg-search ${props.className || ""}`}>
      {props.label && (
        <Styledlabel
          htmlFor={props.id || props.name || ""}
          className={`cg-common ${variant} ${props.className || ""} cg-label`}
          variant={variant}>
          {props.label}
        </Styledlabel>
      )}
      <StyledContainer label={props.label} onSubmit={handleSubmit}>
        <StyledInput
          readOnly={isSubmitted}
          type="text"
          name={props.name || "search"}
          value={term}
          onChange={changeHandler}
          placeholder={props.placeholder || "Search..."}
          className={`cg-common cg-input ${variant} ${props.className || ""}`}
          variant={variant}
          disabled={props.disabled}
        />
        <StyledIconZone variant={variant}>
          {!isSubmitted ? (
            <i className="cg-icon-search" />
          ) : (
            <StyledButton variant={variant} onClick={() => resetAndClose()}>
              X
            </StyledButton>
          )}
        </StyledIconZone>
      </StyledContainer>
    </Container>
  );
};

export default SearchComponent;
