import React from "react";
import styled from "styled-components";

import colors from "@common-ground-io/common-assets/assets/colors.json";

const getBackgroundColor = ({ theme, inverted }: { theme?: { name: string }; inverted?: boolean }) => {
  if (!theme) return colors.greyLighter;

  if (theme.name === "light") {
    if (inverted) return colors.white;
    else return colors.greyLightest;
  } else if (theme.name === "dark") {
    if (inverted) return colors.greyDarkest;
    else return colors.greyDarker;
  }
};

const StyledDiv = styled.div<{ inverted?: boolean }>`
  border-radius: 10px;
  padding: 15px;
  background-color: ${props => getBackgroundColor({ theme: props.theme, inverted: props.inverted })};
`;

const ZoneComponent = (props: any) => (
  <StyledDiv {...props} className={`cg-common cg-zone ${props.className || ""}`}>
    {props.children}
  </StyledDiv>
);

export default ZoneComponent;
