import React from "react";
import { Address, Config } from "../__generated__/graphql";

const AddressComponent = ({ config, address }: { config: Config; address: Address }) => {
  const region = config.taxes.country.code;

  if (region === "US")
    return (
      <address>
        <p>
          {address.addressLine1}
          <br />
          {address.addressLine2}
        </p>
        <p>
          {address.city} {address.state ? <span>{address.state} </span> : null} {address.postCode}
        </p>
        <p>{address.country}</p>
      </address>
    );

  return (
    <address>
      <p>
        {address.addressLine1}
        <br />
        {address.addressLine2}
      </p>
      <p>
        {address.city} {address.postCode}
      </p>
      <p>
        {address.state ? <span>{address.state} </span> : null}
        {address.country}
      </p>
    </address>
  );
};

export default AddressComponent;
