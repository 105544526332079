import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Zone from "../styled/zone";
import Button from "../styled/button";
import { Button as ButtonV2 } from "../../componentsV2/Button";
import InputWithSubmit from "../styled/inputWithSubmit";
import {
  updateOrderStatus,
  updateOrderBilling,
  updateLineItem,
  addItem,
  cancelOrder,
  mergeOrder,
  removeVoucher,
  removeDiscount
} from "../../@services/orderService";
import clone from "clone";
import Loader from "../common/loader";
import Price from "../common/price";
import Liner from "../common/liner";
import { Select } from "../styled/select";
import { FaFilePdf } from "react-icons/fa";
import { SearchListings, SearchUsers } from "../global/search";
import { OrderStatuses } from "../common/orderDefinitions";
import { OrderPaymentMethods, RefundMethods } from "../common/paymentMethods";
import AddressEdit from "./includes/addressEdit";
import Cancel from "./includes/cancel";
import SendInvoice from "./includes/sendInvoice";
import Tooltip from "../common/tooltip";
import History from "../global/history";
import Four0Four from "../global/404";
import Messages from "./includes/message";
import AddVoucher from "./includes/addVoucher";
import Line, { ShippingLine } from "./includes/line";
import { GlobalStore } from "../../stores/global";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ORDER, POST_ORDER_GENERATE_PDF, POST_ORDER_ADD_NOTE, POST_ORDER_SHIPPING_UPDATE } from "../../graphql/queries/order";
import PackingList from "./includes/packingList";
import { getConfigProperty } from "../../utils";
import { Address, Buyer, Config, LineItem, Listing, Order, OrderMergeable } from "../../__generated__/graphql";
import { Match } from "../../types/globals";
import { useTranslation } from "react-i18next";
const { getName } = require("country-list");

const OrderComponent = ({ order: orderProp, refetch }: { order: Order; refetch: () => void }) => {
  const { config, addNotification, isMobile } = GlobalStore.useState(c => c);

  const [orderCopy, setOrderCopy] = useState<Order>(clone(orderProp));
  const [order, setOrder] = useState<Order>(clone(orderProp));
  const [generatingPdf, setGeneratingPdf] = useState<boolean>(false);
  const [mergeableOrder, setMergeableOrder] = useState<any>(null);
  const [addNote] = useMutation(POST_ORDER_ADD_NOTE);
  const [generatePdf] = useMutation(POST_ORDER_GENERATE_PDF);
  const [updateShipping] = useMutation(POST_ORDER_SHIPPING_UPDATE);
  const { t } = useTranslation();

  document.title = `Order #${order.incId} | #${order.id}`;

  useEffect(() => {
    if (orderProp) setOrder(clone(orderProp));
  }, [orderProp]);

  const handleAddNote = async (event: any) => {
    event.preventDefault();
    const content = event.target.note.value;
    event.target.note.value = "";
    try {
      const { data } = await addNote({ variables: { orderRef: order._id, orderAddNoteInput: content } });
      addNotification({ ok: 1, message: "Note added" });
      setOrder({ ...order, ...data?.orderAddNote });
    } catch (error: any) {
      addNotification({ ok: 0, message: error.message });
    }
  };

  const handleGeneratePdf = async (noWindow?: boolean) => {
    setGeneratingPdf(true);
    try {
      const { data } = await generatePdf({ variables: { orderRef: order._id } });
      if (data?.orderGeneratePdf) {
        setOrder({ ...order, billing: data.orderGeneratePdf.billing });
        if (!noWindow && data.orderGeneratePdf.billing.invoice?.pdf)
          window.open(data.orderGeneratePdf.billing.invoice.pdf.toString(), "_blank");
      }
    } catch (error: any) {
      addNotification({ ok: 0, message: error.message });
    } finally {
      setGeneratingPdf(false);
    }
  };

  const handleRemoveVoucher = () => {
    if (!order.billing.voucher) return;

    if (!order.billing.voucher.id) {
      removeDiscount({ orderRef: order._id, type: "discount", discountRef: order.billing.voucher._id })
        .then(({ data }) => {
          setOrder({ ...data.order });
          addNotification(data);
        })
        .catch(e => addNotification({ ok: 0, message: e.data }));
    } else
      removeVoucher({ orderRef: order._id })
        .then(({ data }) => {
          setOrder({ ...data.order });
          addNotification(data);
        })
        .catch(e => addNotification({ ok: 0, message: e.data }));
  };

  const handleLockOrder = async () => {
    const { data } = await updateOrderStatus({
      orderRef: order._id,
      status: "Invoice Sent"
    });
    addNotification(data);
    if (data.ok) setOrder({ ...order, ...data.order });
  };

  const handleUpdateLine = async (line: LineItem) => {
    try {
      const dataToSend = { orderId: order.id, line };
      const { data } = await updateLineItem(dataToSend);
      setOrder({ ...order, ...data.order });
      addNotification(data);
    } catch (e: any) {
      addNotification({ ok: 0, message: e.data ? e.data : e.toString() });
      throw e;
    }
  };

  const handleUpdateShippingLine = async (line: LineItem) => {
    try {
      const { data } = await updateShipping({
        variables: {
          orderRef: order._id,
          orderShippingPriceInput: {
            base: line.price.base !== undefined ? line.price.base : null,
            beforeTaxes: line.price.beforeTaxes !== undefined ? line.price.beforeTaxes : null,
            taxes: line.price.taxes.map(t => ({ name: t.name, rate: t.rate }))
          }
        }
      });
      if (data?.orderShippingUpdate) {
        setOrder({ ...data.orderShippingUpdate });
        return data.orderShippingUpdate;
      }
    } catch (error: any) {
      addNotification({ ok: 0, message: error.message });
    }
  };

  const handleAddEntry = async (entry: any, listing: Listing) => {
    if (listing.stock.quantity <= 0) return;
    const dataToSend = { orderId: order.id, listingRef: listing._id };
    try {
      const { data } = await addItem(dataToSend);
      addNotification(data);
      if (data.ok) {
        setOrder({ ...order, ...data.order });
      }
    } catch (e: any) {
      addNotification({ ok: 0, message: e.data });
    }
  };

  const handleCustomerSelect = async (buyer: Buyer | null, e?: any) => {
    if (e) e.preventDefault();
    try {
      const { data } = await updateOrderBilling({
        orderRef: order._id,
        buyerRef: buyer ? buyer._id : null
      });
      setOrder({ ...order, ...data.order });
      addNotification(data);
    } catch (e: any) {
      addNotification({ ok: 0, message: e.data });
    }
  };

  const handleSetInvoiceId = async (e: any) => {
    e.preventDefault();
    const { data } = await updateOrderBilling({
      orderRef: order._id,
      invoiceId: order.billing.invoice?.id || null
    });
    addNotification(data);
    if (data.ok) {
      setOrder({ ...order, ...data.order });
    } else addNotification(data);
  };

  const handleCancelOrder = async (dataToSend: any) => {
    try {
      const { data } = await cancelOrder({ orderRef: order._id, ...dataToSend });
      addNotification(data);
      setOrder({ ...order, ...data.order });
      return 1;
    } catch (e: any) {
      addNotification({ ok: 0, message: e.data });
      return 0;
    }
  };

  const handleMergeableOrderChange = async (entry: OrderMergeable) => {
    setMergeableOrder(entry);
  };

  const handleInvoiceInputChange = (e: any) => {
    if (!order.billing.invoice) order.billing.invoice = {};
    order.billing.invoice.id = e.target.value;
    setOrder({ ...order });
  };

  const handleVoucherSubmitted = (order: Order) => {
    setOrder({ ...order });
  };

  const handleMergeOrder = async (event: any) => {
    event.preventDefault();
    const id = mergeableOrder.value;
    try {
      const { data } = await mergeOrder({ orderId: order.id, mergeableId: id });
      addNotification(data);
      setOrder({ ...order, ...data.order });
      setMergeableOrder(null);
    } catch (e: any) {
      addNotification({ ok: 0, message: e.data });
    }
  };

  const handleSubmitBillingAddress = async (e: any, type: string, updatedAddress: Address) => {
    e.preventDefault();

    try {
      const { data } = await updateOrderBilling({ orderRef: order._id, billingAddress: updatedAddress });
      // @ts-ignore
      order[type] = data.order[type];
      order.logs = data.order.logs;
      setOrder({ ...order });
      setOrderCopy(clone(data.order));
      addNotification(data);
    } catch (e: any) {
      addNotification({ ok: 0, message: e.data });
    }
  };

  const handleBillingAddressCancel = () => {
    setOrder({ ...order, billing: { ...order.billing, address: clone(orderCopy.billing.address) } });
  };

  const handleShippingAddressCancel = () => {
    setOrder({ ...order, shipping: { ...order.shipping, address: clone(orderCopy.shipping.address) } });
  };

  const handleSubmitShippingAddress = async (e: any, updatedAddress: Address, ref: string) => {
    e?.preventDefault();
    try {
      const variables: any = { orderRef: order._id, orderShippingAddressInput: null, orderShippingAddressRef: null };

      if (updatedAddress) {
        variables.orderShippingAddressInput = {
          addressLine1: updatedAddress.addressLine1,
          addressLine2: updatedAddress.addressLine2,
          postCode: updatedAddress.postCode,
          alpha2: updatedAddress.alpha2,
          streetNumber: updatedAddress.streetNumber,
          city: updatedAddress.city,
          state: updatedAddress.state,
          stateCode: updatedAddress.stateCode
        };
        variables.orderShippingAddressRef = null;
      } else if (ref) {
        variables.orderShippingAddressInput = null;
        variables.orderShippingAddressRef = ref;
      } else if (ref === null) {
        variables.orderShippingAddressInput = null;
        variables.orderShippingAddressRef = null;
      }
      const { data } = await updateShipping({ variables });
      if (!data?.orderShippingUpdate) throw new Error("Error while updating");
      setOrder({ ...data.orderShippingUpdate });
      setOrderCopy(clone(data.orderShippingUpdate));
      addNotification({ ok: 1, message: "Address updated" });
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
  };

  const handleAddressChange = async (type: string, name: string, value: any) => {
    // @ts-ignore
    if (!order[type].address) order[type].address = {};
    // @ts-ignore
    order[type].address[name] = value;
    // @ts-ignore
    if (name === "alpha2") order[type].address.country = getName(value);
    setOrder({ ...order });
  };

  const editable = order && (order.status === "New Order" || order.status === "Buyer Contacted") && !order.billing.voucher;
  const isDiscogs = order.origin === "Discogs";
  const isBandcamp = order.origin === "Bandcamp";
  const statusIndex = OrderStatuses.findIndex(s => s.value === order.status);
  const packageWeightExtra = getConfigProperty(config as Config, "shipping", "packageWeightExtra");
  const isOnlineGuest = order.origin === "Online" && (!order.buyer || !order.buyer._id);
  const isCancelled = order.status.toLowerCase().includes("cancelled");
  const preTaxes = config?.taxes?.rules?.editPricesBeforeTaxes;

  return (
    <div id="orderPage" key={order.id}>
      <div className="content">
        <div id="contentLeft">
          {isOnlineGuest ? <p className="guestWarning">{t("This order was created from a guest session")}</p> : null}
          <Zone id="notes">
            <div className="tooltipFlex">
              <h2>{t("Notes")}</h2>
              <Tooltip>
                <p>{t("Notes are private and may contain any additional information regarding this order")}</p>
              </Tooltip>
            </div>
            {order.notes
              ? order.notes.map(n => (
                  <div key={n.date} className="note">
                    <p className="noteHeader">
                      <span>{n.user ? `${n.user.name}` : ""}</span>
                      <span>{moment(n.date).format("ll")}</span>
                    </p>
                    <p>{n.content}</p>
                  </div>
                ))
              : null}
            <form id="add" onSubmit={handleAddNote}>
              <InputWithSubmit
                fullWidth
                variant="overZone"
                autoComplete="off"
                name="note"
                submitText="Add"
                required
                type="text"
                placeholder={t("Add a note")}
              />
            </form>
          </Zone>
          {order.status === "New Order" ? (
            <section id="lock">
              <div className="sectionHeader">
                <div className="tooltipFlex">
                  <h2>{t("Lock order")}</h2>
                  <Tooltip>
                    <p>
                      {t("After locking an order, items cannot be edited anymore and the invoice can be generated.")}
                      <br />
                      {t("Make sure the order's state is final before proceeding.")}
                    </p>
                  </Tooltip>
                </div>
              </div>
              <ButtonV2 variant="primary" onClick={handleLockOrder}>
                {t("Lock order / Ready to invoice")}
              </ButtonV2>
            </section>
          ) : null}
          {order.instructions ? (
            <Zone className="instructions">
              <h2>
                <i className="cg-icon-warning" />
                {t("Instructions")}
              </h2>
              <p>{order.instructions}</p>
            </Zone>
          ) : null}
          <Zone id="billing">
            <div className="sectionHeader">
              <h2>{t("Billing")}</h2>
              <p title={moment(order.billing.paymentDate).format("llll")}>{!isCancelled && order.billing.status}</p>
            </div>
            <div className="subSection">
              <div className="sectionHeader">
                <h4>{t("Customer")}</h4>
                {(order.buyer && order.origin !== "Online") ||
                (order.origin === "Online" &&
                  order.buyer &&
                  !order.buyer._id &&
                  getConfigProperty(config as Config, "shipping", "placeBasket")) ? (
                  <Button
                    variant="noStyle"
                    className="editButton"
                    onClick={() => window.confirm(t("Are you sure?")) && handleCustomerSelect(null)}>
                    <p>{t("Remove")}</p>
                  </Button>
                ) : null}
              </div>
              {order.buyer ? (
                <>
                  {order.buyer._id ? <Link to={"/user/" + order.buyer._id}>{order.buyer.name}</Link> : <p>{order.buyer.name}</p>}
                  <p>{order.buyer.telephone}</p>
                  <p>{order.buyer.email}</p>
                  {order.buyer.organisation ? <p>{order.buyer.organisation}</p> : null}
                  {order.buyer.taxNumber ? (
                    <p>
                      {t("Tax ID")}: {order.buyer.taxNumber}
                    </p>
                  ) : null}
                </>
              ) : (
                order.origin !== "Discogs" && <SearchUsers variant="overZone" onSelect={handleCustomerSelect} />
              )}
            </div>
            <div id="billingAddress" className="subSection">
              <AddressEdit
                type="billing"
                handleSubmit={handleSubmitBillingAddress}
                handleChange={handleAddressChange}
                handleCancel={handleBillingAddressCancel}
                address={order.billing.address || {}}
                config={config as Config}
              />
            </div>
            <div id="paymentMethods" className="subSection">
              <OrderPaymentMethods isMobile={isMobile} refetch={refetch} addNotification={addNotification} order={order} />
            </div>
            <div id="refunds" className="subSection">
              <RefundMethods isMobile={isMobile} refetch={refetch} order={order} addNotification={addNotification} />
            </div>
            <div id="invoice" className="subSection">
              <form id="invoiceId" onSubmit={handleSetInvoiceId}>
                <InputWithSubmit
                  label={t("Custom invoice ID")}
                  type="text"
                  fullWidth
                  variant="overZone"
                  name="id"
                  value={order.billing.invoice?.id || ""}
                  onChange={handleInvoiceInputChange}
                  placeholder={t("Custom invoice ID") + "..."}
                />
              </form>
            </div>
            <div id="invoiceEmail" className="subSection">
              <SendInvoice
                key={order.id + order.buyer?.email}
                handleGeneratePdf={handleGeneratePdf}
                generatingPdf={generatingPdf}
                order={order}
                addNotification={addNotification}
                onSent={refetch}
              />
              {order.billing.invoice && order.billing.invoice.emails && order.billing.invoice.emails.length ? (
                <div id="invoiceRecipients">
                  {order.billing.invoice.emails.map(e => (
                    <div key={e.date} className="recipientEntry">
                      <p className="date">{moment(e.date).format("llll")}</p>
                      <p className="recipient">
                        <span>{t("Sent to:")}</span>
                        <span>{e.recipient}</span>
                      </p>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
            <div id="pdf" className="subSection">
              <div className="sectionHeader">
                <h4>PDF</h4>
                {order.billing && order.billing.invoice.pdf ? (
                  <p>
                    <a target="_tab" href={order.billing.invoice.pdf}>
                      {t("Download")}
                    </a>
                  </p>
                ) : null}
              </div>
              <Button type="button" onClick={() => handleGeneratePdf(false)} fullWidth variant="secondaryOverZone">
                {generatingPdf ? (
                  <Loader size={5} />
                ) : (
                  <p>
                    <FaFilePdf />
                    {order.status === "New Order" ? t("Generate the PDF quote") : t("Generate the PDF invoice")}
                  </p>
                )}
              </Button>
            </div>
          </Zone>
          {!isBandcamp ? (
            <Zone id="shipping">
              <div className="sectionHeader">
                <h2>{t("Delivery")}</h2>
                {order.shipping.status ? (
                  <p title={moment(order.shipping.date).format("llll")}>{order.shipping.status}</p>
                ) : (
                  <p>{order.status === "Shipped" ? t("Shipped") : ""}</p>
                )}
              </div>
              {order.status === "Shipped" ? (
                <div id="trackingAndEmailConfirmation" className="subSection">
                  {order.shipping && order.shipping.confirmationEmail ? (
                    <div id="emailConfirmation" key={order.shipping.confirmationEmail.date} className="recipientEntry">
                      <p className="date">{moment(order.shipping.confirmationEmail.date).format("llll")}</p>
                      <p className="recipient">
                        <span>{t("Email sent:")}</span>
                        <span>{order.shipping.confirmationEmail.recipient}</span>
                      </p>
                    </div>
                  ) : null}
                  {order.shipping && order.shipping.tracking ? (
                    <div id="trackingLink">
                      <i className="cg-icon-shipping" />
                      <a target="_tab" href={order.shipping.tracking}>
                        <h4>{t("Track this package")}</h4>
                      </a>
                    </div>
                  ) : (
                    <div className="sectionHeader">
                      <p>{t("No tracking number provided")}</p>
                      {/* <Button>{t("Edit")}</Button> */}
                    </div>
                  )}
                </div>
              ) : null}
              {order.shipping.shopCollection ? (
                <div className="subSection">
                  <h4>{t("Method")}</h4>
                  <p>{t("Shop collection")}</p>
                </div>
              ) : null}
              {order.shipping.method ? (
                <div className="subSection">
                  <h4>{t("Method")}</h4>
                  <p>{order.shipping.method}</p>
                </div>
              ) : null}
              <div className="details subSection">
                {order.shipping ? (
                  <AddressEdit
                    type="shipping"
                    editable={order.shipping.status !== "Shipped"}
                    buyer={order.buyer}
                    handleSubmit={handleSubmitShippingAddress}
                    handleChange={handleAddressChange}
                    handleCancel={handleShippingAddressCancel}
                    address={order.shipping.address || {}}
                    config={config as Config}
                  />
                ) : null}
              </div>
              {order.shipping && order.shipping.weight ? (
                <div id="weight" className="subSection">
                  <h4>
                    {t("Weight")} {packageWeightExtra ? `+ ${packageWeightExtra}%` : ""}
                  </h4>
                  {/* eslint-disable-next-line i18next/no-literal-string */}
                  <p>{(order.shipping.weight / 1000).toFixed(2)}kg</p>
                </div>
              ) : null}
              <div className="subSection">
                {order.status !== "Shipped" && order.status !== "New Order" && statusIndex < 5 ? (
                  <PackingList order={order} refetch={refetch} />
                ) : null}
              </div>
            </Zone>
          ) : null}
          {!order.status.toLowerCase().includes("cancelled") && !isBandcamp ? (
            <Cancel config={config as Config} cancel={handleCancelOrder} order={order} t={t} />
          ) : null}
          {!isBandcamp ? (
            <Zone id="generateCreditNote">
              <Link to={`/creditnotes?orderId=${order.incId}`}>
                <Button variant="secondaryOverZone" type="button">
                  {t("Add a credit note")}
                </Button>
              </Link>
            </Zone>
          ) : null}
          <Zone id="logs">
            <div className="sectionHeader">
              <h2 className="header">{t("Timeline")}</h2>
            </div>
            <History logs={order.logs} />
          </Zone>
        </div>
        <div id="contentRight">
          <section id="header">
            <div>
              <h2>
                {/* eslint-disable-next-line i18next/no-literal-string */}
                {order.origin} {t("order")} #{order.incId} | #{order.id}{" "}
                {order.billing.invoice && order.billing.invoice.id ? ` - ${order.billing.invoice.id}` : ""}
              </h2>
              <p>
                {t("Created")} {moment(order.created).format("llll")}
                {order.seller ? t("by") + order.seller.name : ""}
              </p>
            </div>
            <div>
              {order.origin === "Discogs" ? (
                <div id="discogsStatus">
                  <img alt="Discogs logo" src="https://static.common-ground.io/common/media/dicsogs.png" /> <h2>{order.discogsStatus}</h2>
                </div>
              ) : (
                <h2>{order.status}</h2>
              )}
            </div>
          </section>
          {/* {order.origin === "Discogs" && order.shipping && !order.shipping.price.base && order.status === "New Order" ? (
            <p id="shippingPriceSuggestion">
              You must set the shipping cost for this order. <br />
              {order.shipping.suggestedMethods && order.shipping.suggestedMethods.length ? (
                <>
                  Suggested price from your shipping policies:{" "}
                  {order.shipping.suggestedMethods.map(m => (
                    <Price key={m.total} value={m.total} />
                  ))}
                </>
              ) : null}
            </p>
          ) : null} */}
          {order.billing.voucher ? <h3>{t("Orders with voucher can not be edited")}</h3> : null}
          {editable && !isDiscogs ? (
            <div id="addAndMerge">
              <div id="addItem">
                <h3>{t("Add an item")}</h3>
                <SearchListings
                  quantitySort={true}
                  placeholder={t("Find releases, products or books") + "..."}
                  config={config as Config}
                  onSelect={handleAddEntry}
                />
              </div>
              {order.mergeableOrders && order.mergeableOrders.length ? (
                <div id="merge">
                  <div className="tooltipFlex">
                    <h3>{t("This order can be merged from")}</h3>
                  </div>

                  <form id="mergeForm" onSubmit={handleMergeOrder}>
                    <Select
                      name="mergeableId"
                      required
                      onChange={handleMergeableOrderChange}
                      value={mergeableOrder}
                      options={order.mergeableOrders.map(o => ({
                        label: (
                          <>
                            {o.id} {o.description} - <Price value={o.total} /> - {moment(o.created).format("ll")}
                          </>
                        ),
                        value: o.id
                      }))}
                    />
                    <Button variant="secondaryOverZone" disabled={!mergeableOrder} type="submit">
                      {t("Merge")}
                    </Button>
                  </form>
                </div>
              ) : null}
            </div>
          ) : null}
          <div id="items">
            <Zone inverted id="itemsHeader">
              <p className="image">{t("Image")}</p>
              <p>{t("Description")}</p>
              <p>
                {t("Qty")} ({order.totals.itemQuantity})
              </p>
              <p className="base">{t("Price")}</p>
              <p>{t("Taxes")}</p>
              <p>{t("Discount")}</p>
              <p>{t("Subtotal")}</p>
              <p className="action">{t("Action")}</p>
            </Zone>
            {order.items.map((i, index) => (
              <Liner index={index} key={index}>
                <Line
                  overZone={!(index % 2)}
                  key={new Date().getTime() + index}
                  order={order}
                  addNotification={addNotification}
                  refetch={refetch}
                  config={config as Config}
                  isDiscogs={isDiscogs}
                  editable={editable}
                  updateLine={handleUpdateLine}
                  item={i}
                />
              </Liner>
            ))}
            <Zone inverted={!!(order.items.length % 2)}>
              <ShippingLine
                line={order.shipping}
                editable={editable || (isDiscogs && order.status === "New Order")}
                config={config as Config}
                updateLine={handleUpdateShippingLine}
              />
            </Zone>
          </div>
          <div id="totalsContainer">
            <div id="totals">
              <p>
                {t("Subtotal")}: <Price value={(preTaxes ? order.totals.subtotalBeforeTaxes : order.totals.subtotal) as number} />
              </p>
              {order.billing.voucher && order.billing.voucher.type === "coupon" ? (
                <p>
                  {order.billing.voucher.id ? (
                    <span>
                      {t("Voucher discount")}: <Link to={`/vouchers?term=${order.billing.voucher.id}`}>({order.billing.voucher.id})</Link> -
                      <Price value={order.totals.discount as number} />
                    </span>
                  ) : (
                    <span>
                      {t("Subtotal discount")}:{" "}
                      {order.billing.voucher.redeemType === "percentage" ? (
                        <>
                          {/* eslint-disable-next-line i18next/no-literal-string */}
                          {order.billing.voucher.value}% (<Price value={order.totals.discount as number} />)
                        </>
                      ) : (
                        <Price value={order.billing.voucher.value} />
                      )}
                    </span>
                  )}
                </p>
              ) : null}

              {order.totals.taxes?.map((t, i) => (
                <div key={i} className="tax">
                  {t.name} {t.rate}%: <Price value={t.amount as number} />
                </div>
              ))}
              <p>
                {t("Shipping")}: <Price value={order.shipping.price.base} />
              </p>
              <p className="total">
                <strong>
                  {t("Total")}: <Price value={order.totals.grand} />
                </strong>
                {/* <br />
                <span>Amount due: <Price value={order.totals.leftToPay} /></span> */}
              </p>
              {(order.status === "New Order" || order.status === "Invoice Sent") &&
              order.origin !== "Discogs" &&
              order.billing &&
              !order.billing.paymentMethods.find(p => p.origin === "giftCard") ? (
                <>
                  {!order.billing.voucher ? (
                    <AddVoucher onSubmitted={handleVoucherSubmitted} addNotification={addNotification} order={order} />
                  ) : (
                    <Button type="button" variant="danger" onClick={handleRemoveVoucher}>
                      {t("Remove discount")}
                    </Button>
                  )}
                </>
              ) : null}
            </div>
          </div>
          <Messages order={order} addNotification={addNotification} />
        </div>
      </div>
    </div>
  );
};

const LoaderComponent = ({ match }: { match: Match }) => {
  const id = match.params.id;
  const { data, refetch } = useQuery(GET_ORDER, { variables: { id }, fetchPolicy: "cache-and-network" });

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  if (!data) return <Loader />;
  else if (!data?.order) return <Four0Four />;
  else return <OrderComponent order={data?.order} refetch={refetch} />;
};

export default LoaderComponent;
