import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import Price, { PriceInput } from "../../common/price";
import Loader from "../../common/loader";
import Modal from "../../modal";
import Button from "../../styled/button";
import Input from "../../styled/input";
import Zone from "../../styled/zone";
import { Select } from "../../styled/select";
import Checkbox from "../../styled/checkbox";
import { round } from "@common-ground-io/common-assets/helpers/utils.js";
import { GlobalStore } from "../../../stores/global";
import { IoMdInformationCircleOutline } from "react-icons/io";
import Tooltip from "../../common/tooltip";
import { SearchListings, SearchVouchers, SearchUsers } from "../../global/search";
import UserCreate from "../../common/userCreate";
import { FiArrowUp } from "react-icons/fi";
import { Button as ButtonV2 } from "../../../componentsV2/Button";
import {
  FaExclamationTriangle,
  FaMoneyBillWave,
  FaGift,
  FaCreditCard,
  FaTimes,
  FaCcStripe,
  FaMoneyCheckAlt,
  FaExchangeAlt,
  FaCcPaypal
} from "react-icons/fa";
import { POST_USER_BUYER_CREATE } from "../../../graphql/queries/user";

import {
  GET_CHECKOUT,
  POST_CHECKOUT_ADD_LISTING,
  POST_CHECKOUT_REMOVE_ENTRY,
  POST_CHECKOUT_UPDATE_LINE,
  POST_CHECKOUT_ADD_NOTE,
  POST_CHECKOUT_UPDATE_SHIPPING,
  POST_CHECKOUT_UPDATE_BUYER,
  POST_CHECKOUT_UPDATE_PAYMENTS,
  POST_CHECKOUT_SUBMIT,
  POST_CHECKOUT_VERIFY,
  POST_CHECKOUT_UPDATE_VOUCHER,
  POST_CHECKOUT_UPDATE_GIFT_CARD
} from "../../../graphql/queries/checkout";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { GET_VOUCHERS } from "../../../graphql/queries/voucher";
import * as amplitude from "@amplitude/analytics-browser";
import { addressMap } from "./addressEdit";
import { Buyer, Config, Item, List, Listing, PaymentMethod, Voucher } from "../../../__generated__/graphql";
import { getPlaceholderImage } from "../../../utils";
import { IconType } from "react-icons/lib";
import isNumber from "is-number";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

interface HandlePaymentMethodAmountChange {
  (method: PaymentMethod, value: number): void | undefined;
}

const InputComponent = ({
  method,
  onAmountChange,
  onSave,
  onClick
}: {
  method: any;
  onAmountChange: HandlePaymentMethodAmountChange;
  onSave: any;
  onClick: any;
}) => {
  const { t } = useTranslation();
  return (
    <Input
      variant="overZone"
      onBlur={(e: any) => onSave(method, e.target.value)}
      onWheel={(e: any) => e.target.blur()}
      onClick={() => onClick && onClick()}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onAmountChange(method, parseFloat(e.target.value))}
      onKeyPress={(e: any) => e.target.keyCode === 13 && e.preventDefault()}
      placeholder={MeansOfPayments(t).find(m => m.origin === method.origin)?.placeholder}
      type="number"
      step="0.01"
      value={method.amount || ""}
    />
  );
};

const GiftCardComponent = ({ method, onSave }: { method: PaymentMethod; onSave: any }) => {
  const { t } = useTranslation();

  const handleSelectGiftCard = (giftCard: Voucher) => {
    onSave({ ...method, voucherRef: giftCard._id });
  };

  const handleClearGiftCard = () => {
    onSave(method);
  };

  return (
    <div className="giftCard">
      {!isNumber(method.amount) ? (
        <SearchVouchers
          variant="overZone"
          placeholder={t("Search gift cards") + "..."}
          query={{ status: "active", type: "giftCard" }}
          onSelect={handleSelectGiftCard}
        />
      ) : (
        <Zone className="info">
          <span>
            {method.charge?.id} | <Price value={method.amount} />
          </span>
          <Button type="button" variant="noStyle" onClick={handleClearGiftCard}>
            <FaTimes />
          </Button>
        </Zone>
      )}
    </div>
  );
};

export interface MeanOfPayment {
  origin: string;
  icon: IconType;
  placeholder: string;
  component: any;
  added?: Date;
  amount?: number | null;
  charge?: any;
}

const MeansOfPayments = (t: TFunction): MeanOfPayment[] => {
  return [
    {
      origin: "cash",
      amount: null,
      icon: FaMoneyBillWave,
      placeholder: t("In cash"),
      component: InputComponent
    },
    {
      origin: "card",
      amount: null,
      icon: FaCreditCard,
      placeholder: t("Paid on card"),
      component: InputComponent
    },
    {
      origin: "stripe",
      amount: null,
      icon: FaCcStripe,
      placeholder: t("By Stripe"),
      component: InputComponent
    },
    {
      origin: "paypal",
      amount: null,
      icon: FaCcPaypal,
      placeholder: t("By Paypal"),
      component: InputComponent
    },
    {
      origin: "cheque",
      amount: null,
      icon: FaMoneyCheckAlt,
      placeholder: t("By cheque"),
      component: InputComponent
    },
    {
      origin: "bankTransfer",
      amount: null,
      icon: FaExchangeAlt,
      placeholder: t("Via bank transfer"),
      component: InputComponent
    },
    {
      origin: "giftCard",
      amount: null,
      icon: FaGift,
      placeholder: t("Gift card"),
      component: GiftCardComponent
    }
  ];
};

const Checkout = ({
  checkoutRef,
  onDelete,
  createNewCheckout,
  lists,
  onSubmitted
}: {
  checkoutRef: string;
  onDelete: any;
  createNewCheckout: any;
  lists: List[];
  onSubmitted: any;
}) => {
  const { t } = useTranslation();
  const { config, addNotification } = GlobalStore.useState(c => c);
  const defaultMethods = MeansOfPayments(t).map(m => ({ ...m, amount: null }));
  const modalRef = useRef<any>();
  const [paymentMethods, setPaymentMethods] = useState<MeanOfPayment[]>(defaultMethods);
  const [availableVouchers, setAvailableVouchers] = useState<Voucher[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [collected, setCollected] = useState(false);
  const [updateCheckoutAddListing] = useMutation(POST_CHECKOUT_ADD_LISTING);
  const [updateCheckoutRemoveEntry] = useMutation(POST_CHECKOUT_REMOVE_ENTRY);
  const [updateCheckoutLine] = useMutation(POST_CHECKOUT_UPDATE_LINE);
  const [updateCheckoutAddNote] = useMutation(POST_CHECKOUT_ADD_NOTE);
  const [updateCheckoutShipping] = useMutation(POST_CHECKOUT_UPDATE_SHIPPING);
  const [updateCheckoutBuyer] = useMutation(POST_CHECKOUT_UPDATE_BUYER);
  const [updateCheckoutPayments] = useMutation(POST_CHECKOUT_UPDATE_PAYMENTS);
  const [updateCheckoutSubmit] = useMutation(POST_CHECKOUT_SUBMIT);
  const [updateCheckoutVerify] = useMutation(POST_CHECKOUT_VERIFY);
  const [updateCheckoutVoucher] = useMutation(POST_CHECKOUT_UPDATE_VOUCHER);
  const [updateCheckoutGiftCard] = useMutation(POST_CHECKOUT_UPDATE_GIFT_CARD);
  const [createBuyer, { loading: creatingBuyer }] = useMutation(POST_USER_BUYER_CREATE);
  const [getVouchers] = useLazyQuery(GET_VOUCHERS, { fetchPolicy: "cache-and-network" });

  const { data, loading } = useQuery(GET_CHECKOUT, { fetchPolicy: "cache-and-network", variables: { checkoutRef } });

  const checkout = data && data.checkout;

  useEffect(() => {
    if (checkout) {
      const defaultMethods = MeansOfPayments(t).map(m => ({ ...m, amount: "" } as any));
      checkout.billing.paymentMethods.forEach(om => {
        const index = defaultMethods.findIndex(pm => pm.origin === om.origin);
        if (index > -1) {
          defaultMethods[index].amount = om.amount;
          defaultMethods[index].charge = om.charge;
        }
      });
      setPaymentMethods(defaultMethods);
    }
  }, [checkout]);

  useEffect(() => {
    if (checkout && checkout.totals.leftToPay === 0) {
      setCollected(true);
    }
  }, [checkout]);

  const handleAddEntry = async (_: Item, listing: Listing) => {
    if (!checkout) return;
    try {
      if (checkout.items.length >= 50)
        throw new Error(t("A checkout may not contain more than 50 items, submit it then continue from the order page"));
      if (!listing.stock.quantity) return addNotification({ ok: 0, message: "This item isn't avaialble" });

      await updateCheckoutAddListing({ variables: { id: checkout.id, listingRef: listing._id } });
      amplitude.track("Checkout item added");
    } catch (error: any) {
      addNotification({ ok: 0, message: error.message });
    }
  };

  const computePaymentMethodsTotal = (paymentsToSend: MeanOfPayment[]) => {
    let total = 0;
    paymentsToSend
      .filter(m => !!m.amount)
      .forEach(m => {
        total += m.amount as number;
      });
    return round(total);
  };

  const getIsSubmittable = () => {
    if (!checkout) return;
    return checkout.items.length > 0;
  };

  const getMethodstotal = () => {
    if (!checkout) return;
    let paymentMethodsTotal = 0;
    paymentMethodsTotal = checkout.billing.paymentMethods.reduce((t, m) => t + (!isNaN(m.amount) ? m.amount : 0), paymentMethodsTotal);
    return paymentMethodsTotal;
  };

  const areTotalsMatching = () => {
    if (!checkout) return;
    const paymentMethodsTotal = getMethodstotal();
    return (paymentMethodsTotal === 0 || paymentMethodsTotal === checkout.totals.grand) && checkout.items.length !== 0;
  };

  const handleLineChange = async (entryRef: string, value: number, quantity: number) => {
    if (!checkout) return;
    if (!quantity) return;
    try {
      await updateCheckoutLine({
        variables: {
          id: checkout.id,
          entryRef,
          lineBasePrice: value,
          lineQuantity: quantity,
          version: checkout.version
        }
      });
      amplitude.track("Checkout line updated");
      setDirty(false);
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
  };

  const isFullyPaid = checkout && checkout.totals.grand > 0 && getMethodstotal() === checkout.totals.grand;

  const handleCustomerSelect = async (buyer: Buyer, e: any) => {
    if (!checkout) return;
    if (e) e.preventDefault();
    try {
      await updateCheckoutBuyer({
        variables: { id: checkout.id, buyerRef: buyer._id, version: checkout.version }
      });
      const { data } = await getVouchers({ variables: { type: "coupon", userRef: buyer._id, status: "active" } });
      if (data?.vouchers.vouchers) setAvailableVouchers(data?.vouchers.vouchers);
      amplitude.track("Checkout buyer selected");
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
  };

  const handleSubmitcheckout = async () => {
    if (!checkout) return;
    try {
      setIsSubmitting(true);
      await updateCheckoutVerify({ variables: { checkoutRef: checkout._id, version: checkout.version } });
      const { data } = await updateCheckoutSubmit({ variables: { id: checkout.id, collected, version: checkout.version } });
      onSubmitted(data?.checkoutSubmit?.checkout);
      amplitude.track("Order created");
      addNotification({ ok: 1, message: t("Order was created") });
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAddGiftCard = async ({ voucherRef }: { voucherRef: string }) => {
    if (!checkout) return;
    await updateCheckoutGiftCard({
      variables: { checkoutRef: checkout._id, voucherRef: voucherRef || null, version: checkout.version }
    }).catch((e: any) => addNotification({ ok: 0, message: e.message }));
  };

  const handlePaymentMethodSave = async (method: PaymentMethod & { voucherRef: string }) => {
    if (!checkout) return;
    try {
      const total: number = computePaymentMethodsTotal(paymentMethods);
      if (total > checkout.totals.grand) return addNotification({ ok: 0, message: "Payment methods cannot be greater than total" });

      const existingMethod = checkout.billing.paymentMethods.find(p => p.origin === method.origin);

      if ((!method.amount && !existingMethod) || (method.amount && existingMethod && existingMethod.amount === method.amount)) {
        setDirty(false);
      } else
        await updateCheckoutPayments({
          variables: {
            id: checkout.id,
            version: checkout.version,
            paymentMethods: paymentMethods
              .filter(p => p.amount)
              .map(p => ({ origin: p.origin, amount: p.amount as number, charge: p.charge }))
          }
        });
      amplitude.track("Checkout payment methods updated");

      setDirty(false);
    } catch (error: any) {
      addNotification({ ok: 0, message: error.message });
    }
  };

  const handleFillInAmount = async (method: MeanOfPayment) => {
    if (!checkout) return;
    if (!checkout.totals.grand || method.origin === "giftCard") return;
    try {
      await updateCheckoutPayments({
        variables: {
          id: checkout.id,
          paymentMethods: [{ origin: method.origin, amount: checkout.totals.grand }],
          version: checkout.version
        }
      });
      amplitude.track("Checkout payment method amount filled");
      setDirty(false);
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
  };

  const handlePaymentMethodAmountChange = (method: PaymentMethod, value: number) => {
    const foundMethod = paymentMethods.find(m => m.origin === method.origin);
    if (!foundMethod) return;
    foundMethod.amount = value;
    setPaymentMethods([...paymentMethods]);
  };

  const handleRemoveBuyer = async () => {
    if (!checkout) return;
    try {
      await updateCheckoutBuyer({ variables: { id: checkout.id, buyerRef: null } });
      amplitude.track("Checkout buyer removed");
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
    setAvailableVouchers([]);
  };

  const handleRemoveLine = async (ref: string) => {
    if (!checkout) return;
    try {
      await updateCheckoutRemoveEntry({ variables: { id: checkout.id, entryRef: ref, version: checkout.version } });
      amplitude.track("Checkout line removed");
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
  };

  const handleAddVoucher = async (voucher: Voucher) => {
    if (!checkout) return;
    try {
      await updateCheckoutVoucher({ variables: { checkoutRef: checkout._id, voucherRef: voucher._id, version: checkout.version } });
      amplitude.track("Checkout voucher updated");
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
  };

  const handleRemoveVoucher = async () => {
    if (!checkout) return;
    try {
      await updateCheckoutVoucher({ variables: { checkoutRef: checkout._id, voucherRef: null, version: checkout.version } });
      amplitude.track("Checkout voucher removed");
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
  };

  const handleAddNote = async (content: string) => {
    if (!checkout) return;
    try {
      await updateCheckoutAddNote({ variables: { id: checkout.id, content: content || "", version: checkout.version } });
      amplitude.track("Checkout note added");
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
  };

  const handleCreateUser = async (userData: any) => {
    if (!checkout) return;
    try {
      const { data } = await createBuyer({
        variables: {
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
          telephone: userData.telephone,
          taxNumber: userData.taxNumber,
          organisation: userData.organisation,
          addresses: userData.addresses,
          lists: userData.lists.map((l: any) => l.ref)
        }
      });
      await updateCheckoutBuyer({ variables: { id: checkout.id, buyerRef: data?.userBuyerCreate?._id, version: checkout.version } });
      amplitude.track("Buyer created");
      modalRef.current.close();
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
  };

  const handleShippingUpdate = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!checkout) return;
    if (!e.target.value) return;
    try {
      await updateCheckoutShipping({ variables: { id: checkout.id, basePrice: parseFloat(e.target.value), version: checkout.version } });
      amplitude.track("Checkout shipping updated");
      setDirty(false);
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
  };

  const handleSelectShippingAddress = async (option: any) => {
    if (!checkout) return;
    try {
      await updateCheckoutShipping({ variables: { id: checkout.id, addressRef: option.value, version: checkout.version } });
      amplitude.track("Checkout shipping updated");
      setDirty(false);
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
  };

  const handleDeleteCheckout = () => {
    if (!checkout) return;
    onDelete(checkout.id);
  };

  if (!checkout) return <Loader />;

  const isSubmittable = getIsSubmittable();

  if (checkout.status === "complete")
    return (
      <div key={checkout.id} className="checkout complete">
        <Link to={`/order/${checkout.orderId}`}>
          <h2>
            #{checkout.orderId} - {t("Order was created")}{" "}
          </h2>
        </Link>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Link to={`/order/${checkout.orderId}`}>
            <Button variant="secondaryOverZone">{t("Go to order page")}</Button>
          </Link>
          <ButtonV2 variant="primary" onClick={createNewCheckout}>
            {t("Create a checkout")}
          </ButtonV2>
        </div>
      </div>
    );

  const displayPreTaxes = config?.taxes?.rules?.editPricesBeforeTaxes;
  const selectedAddress = checkout.shipping.shopCollection
    ? null
    : checkout.shipping.address
    ? checkout.buyer?.addresses?.find(a => a?._id === checkout.shipping.address?._id)
    : null;

  return (
    <Zone className="checkout">
      <Modal style={{}} ref={modalRef}>
        <div className="userCreateModal">
          <div className="header">
            <h2>{t("Create a customer")}</h2>
            <Button type="button" variant="noStyle" onClick={() => modalRef.current.close()}>
              X
            </Button>
          </div>
          <div className="content">
            <UserCreate onCreate={handleCreateUser} isCreatingUser={creatingBuyer} lists={lists} />
          </div>
        </div>
      </Modal>
      <div className="header">
        <div className="left">
          <h2>
            {" "}
            {t("Created")} {moment(checkout.created).fromNow()}
          </h2>
          <div className="dirty"></div>
        </div>
        <div className="right">
          <Button
            type="button"
            disabled={isSubmitting || loading || dirty}
            onClick={() => window.confirm(t("Are you sure?")) && handleDeleteCheckout()}
            variant="danger">
            {t("Delete")}
          </Button>
        </div>
      </div>
      <hr />
      <div className="checkoutContent">
        <div className="left">
          <div className="note">
            <Input
              variant="overZone"
              label={t("Add a note")}
              name="note"
              type="text"
              onBlur={(e: any) => handleAddNote(e.target.value)}
              defaultValue={checkout.note ? checkout.note.content : ""}
              autoComplete="off"
              placeholder={t("Add a note")}
            />
          </div>
          <div className="paymentMethods">
            <div className="header">
              <div className="tooltipFlex">
                <h3>{t("Payment methods")}</h3>
                <Tooltip>
                  <p>{t("Specify how the customer paid for this checkout")}</p>
                </Tooltip>
              </div>
              <div>
                <p className="leftToPay">
                  {t("Left to pay")}: <Price value={checkout.totals.leftToPay} />
                </p>
              </div>
            </div>
            <div className="paymentMethods">
              {paymentMethods.map((m, index) => (
                <div key={index} className="paymentMethod">
                  <Button
                    variant="secondaryOverZone"
                    type="button"
                    disabled={!isSubmittable || isSubmitting || loading || dirty}
                    onClick={() => handleFillInAmount(m)}
                    className="icon">
                    {/* @ts-ignore */}
                    {React.createElement(MeansOfPayments(t).find(method => m.origin === method.origin).icon, {})}
                  </Button>
                  <m.component
                    method={m}
                    onClick={() => setDirty(true)}
                    onAmountChange={handlePaymentMethodAmountChange}
                    onSave={m.origin === "giftCard" ? handleAddGiftCard : handlePaymentMethodSave}
                  />
                </div>
              ))}
            </div>
            {availableVouchers.length && availableVouchers.find(v => v.type === "giftCard") ? (
              <div className="availableVouchers">
                <hr />
                <h3>{t("Available gift cards for this customer")}</h3>
                {availableVouchers
                  .filter(v => v.type === "giftCard")
                  .map(v => (
                    <Button variant="secondary" key={v._id} type="button" onClick={() => handleAddGiftCard({ voucherRef: v._id })}>
                      <span>
                        {v.id} - <Price value={v.value} />
                      </span>
                    </Button>
                  ))}
              </div>
            ) : null}
          </div>
          <div className="user">
            {checkout.buyer ? (
              <div className="buyerContainer">
                <div className="nameAndRemove">
                  <p>
                    {checkout.buyer.name} - {checkout.buyer.email}
                  </p>
                  <div>
                    <Button variant="danger" type="button" onClick={handleRemoveBuyer}>
                      {t("Remove")}
                    </Button>
                  </div>
                </div>
                {checkout.buyer?.addresses?.length ? (
                  <Select
                    label="Select a shipping address"
                    variant="overZone"
                    onChange={handleSelectShippingAddress}
                    value={
                      selectedAddress && selectedAddress.type
                        ? // @ts-ignore
                          { label: `${addressMap(t)[selectedAddress.type]}: ${selectedAddress.city} - ${selectedAddress.addressLine1}` }
                        : { label: t("Shop collection"), value: null }
                    }
                    options={[
                      { label: t("Shop collection"), value: null },
                      ...checkout.buyer?.addresses?.map(a => ({
                        value: a?._id,
                        // @ts-ignore
                        label: `${addressMap(t)[a.type]}: ${a.city} - ${a.addressLine1}`
                      }))
                    ]}
                  />
                ) : null}
              </div>
            ) : (
              <div className="userSelectOrCreate">
                <SearchUsers variant="overZone" label={t("Search customers") + "..."} onSelect={handleCustomerSelect} />
                <Button fullWidth variant="secondary" onClick={() => modalRef.current.open()} type="button">
                  {t("Create a customer")}
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className="right">
          <SearchListings
            label={t("Search and add listings")}
            variant="overZone"
            config={config as Config}
            quantitySort={true}
            placeholder={t("Search your inventory and add listings to this checkout") + "..."}
            onSelect={handleAddEntry}
          />
          <div className="items">
            <div className="item header">
              <p>{t("Artwork")}</p>
              <p>{t("Description")}</p>
              <p>
                {t("Quantity")} ({checkout.totals.itemQuantity})
              </p>
              <p>{t("Price")}</p>
              <p>{t("Total")}</p>
              <p>{t("Delete")}</p>
            </div>
            {checkout.items.length ? (
              checkout.items.map(e => (
                <div key={e._id} className="item">
                  <img src={e.item.thumbnail || getPlaceholderImage(config as Config)} />
                  <div className="flexRow">
                    {e.listing.options.length ? (
                      <Tooltip Icon={IoMdInformationCircleOutline} className="moreInfo">
                        <>
                          {e.listing.location ? (
                            <p>
                              {t("Location")} : {e.listing.location}
                            </p>
                          ) : null}
                          {e.listing.options.map(o => (
                            <p key={o.name}>
                              {o.name} {"->"} {o.value}
                            </p>
                          ))}
                        </>
                      </Tooltip>
                    ) : null}
                    <div className="flexColumn">
                      <Link to={e.item.path || ""}>
                        <p>{e.item.description}</p>
                      </Link>
                      {e.listing.location ? (
                        <small>
                          {t("Location")}: {e.listing.location}
                        </small>
                      ) : null}
                    </div>
                  </div>
                  <div className="column">
                    <Input
                      variant="overZone"
                      onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleLineChange(e._id, displayPreTaxes ? e.price.beforeTaxes : e.price.base, parseInt(event.target.value))
                      }
                      name="quantity"
                      type="number"
                      onWheel={(e: any) => e.target.blur()}
                      onClick={() => setDirty(true)}
                      min="1"
                      required
                      step="1"
                      max={e.listing.stock.quantity}
                      defaultValue={e.quantity || ""}
                    />
                    <small>
                      {e.listing.stock.quantity - e.quantity > 0 ? (
                        <>
                          {e.listing.stock.quantity - e.quantity} {t("more in stock")}{" "}
                        </>
                      ) : (
                        <>{t("Last copy")}</>
                      )}
                    </small>
                  </div>
                  <div className="column">
                    <PriceInput
                      variant="overZone"
                      onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleLineChange(e._id, parseFloat(event.target.value), e.quantity)
                      }
                      type="number"
                      onClick={() => setDirty(true)}
                      name="price"
                      min="0"
                      required
                      step="0.01"
                      max="999"
                      defaultValue={displayPreTaxes ? e.price.beforeTaxes : e.price.base}
                    />
                    {(displayPreTaxes ? e.price.beforeTaxes : e.price.base) !==
                    (displayPreTaxes ? e.listing.prices.beforeTaxes : e.listing.prices.sale) ? (
                      <small>
                        {" "}
                        {t("Was")} <Price value={(displayPreTaxes ? e.listing.prices.beforeTaxes : e.listing.prices.sale) as number} />
                      </small>
                    ) : null}
                  </div>
                  <h3>
                    <Price value={(displayPreTaxes ? e.price.subtotalBeforeTaxes : e.price.subtotal) as number} />
                  </h3>
                  <Button
                    type="button"
                    disabled={!isSubmittable || isSubmitting || loading || dirty}
                    variant="danger"
                    onClick={() => handleRemoveLine(e._id)}>
                    {t("Delete")}
                  </Button>
                </div>
              ))
            ) : (
              <p className="noItems">
                {t("Add items from the search input above")} <FiArrowUp />
              </p>
            )}
          </div>
          <hr />
          <div className="newCheckoutfooter">
            <div className="voucher">
              <SearchVouchers
                label={t("Apply a coupon discount")}
                variant="overZone"
                voucher={checkout.billing.voucher}
                removeVoucher={handleRemoveVoucher}
                onSelect={handleAddVoucher}
                placeholder={t("Search for coupons") + "..."}
              />
              {checkout.buyer && checkout.buyer._id ? (
                <small>{t("Note: Make sure the voucher you select matches the selected buyer")}</small>
              ) : null}
              {availableVouchers.length && availableVouchers.find(v => v.type === "coupon") ? (
                <div className="availableVouchers">
                  <hr />
                  <h3>{t("Available coupons for this customer")}</h3>
                  {availableVouchers
                    .filter(v => v.type === "coupon")
                    .map(v => (
                      <Button variant="secondary" key={v._id} type="button" onClick={() => handleAddVoucher(v)}>
                        {v.id}
                        <span>
                          {v.id} - {v.redeemType === "percentage" ? <>{v.value}%</> : <Price value={v.value} />}
                        </span>
                      </Button>
                    ))}
                </div>
              ) : null}
            </div>
            <div className="totalsAndSubmit">
              {checkout.billing.voucher ? (
                <p>
                  {t("Voucher reduction")}: {checkout.billing.voucher.value}
                  {checkout.billing.voucher.redeemType === "percentage" ? "%" : config?.currencySymbol}
                </p>
              ) : null}
              <PriceInput
                label={t("Shipping cost")}
                onKeyPress={(e: any) => {
                  e.target.keyCode === 13 && e.preventDefault();
                }}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => handleShippingUpdate(e)}
                name="shipping"
                variant="overZone"
                onClick={() => setDirty(true)}
                defaultValue={(displayPreTaxes ? checkout.shipping.price.beforeTaxes : checkout.shipping.price.base) as number}
                type="number"
                max="999"
                min="0"
                step="0.01"
                placeholder={t("Shipping amount")}
              />
              <p>
                {t("Subtotal")}:{" "}
                <Price value={(displayPreTaxes ? checkout.totals.subtotalBeforeTaxes : checkout.totals.subtotal) as number} />
              </p>
              <p>
                {t("Shipping")}:{" "}
                <Price value={(displayPreTaxes ? checkout.shipping.price.beforeTaxes : checkout.totals.shipping) as number} />
              </p>
              {checkout.totals.discount ? (
                <p>
                  {/* eslint-disable-next-line i18next/no-literal-string */}
                  {t("Discount")}: -<Price value={checkout.totals.discount} />
                </p>
              ) : null}
              {checkout.totals.taxes?.map(t => (
                <p key={t.name}>
                  {t.name} <Price value={t.amount as number} />
                </p>
              ))}
              <h2>
                {t("Total")}: <Price value={checkout.totals.grand} />
              </h2>
              {isFullyPaid ? (
                <Checkbox
                  checked={collected}
                  className="markAsCollected"
                  label={t("Mark as collected")}
                  name="collected"
                  onChange={() => setCollected(!collected)}
                />
              ) : null}
              <ButtonV2
                variant="primary"
                disabled={!isSubmittable || isSubmitting || loading || dirty}
                onClick={handleSubmitcheckout}
                type="submit">
                {isSubmitting || loading ? <Loader /> : t("Submit")}
              </ButtonV2>
              {checkout.items.length && !areTotalsMatching() ? (
                <small>
                  <span>
                    <FaExclamationTriangle />
                  </span>
                  {t("The payment methods do not match the total.")}
                  <br />
                  {t("Billing status for this order will be set as Partial Payment.")}
                </small>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Zone>
  );
};

export default Checkout;
