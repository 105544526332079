import { gql } from "../../__generated__/gql";

export const GET_LOGS_FOR_LISTING = gql(`
  query itemLogsConnection($ref: ID!, $page: Int) {
    itemLogsConnection(ref: $ref, page: $page) {
      pagination {
        pages
        page
        count
      }
      logs {
        _id
        created
        content
        user {
          name
        }
      }
    }
  }
`);
