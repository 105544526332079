import React, { useState } from "react";
import Loader from "../../../common/loader";
import Button from "../../../styled/button";
import Zone from "../../../styled/zone";
import InputWithSubmit from "../../../styled/inputWithSubmit";
import { useQuery, useMutation } from "@apollo/client";
import { GET_CONFIG_REFERRALS, POST_CONFIG_REFERRAL } from "../../../../graphql/queries/config";
import { AddNotification } from "../../../../types/globals";
import { useTranslation } from "react-i18next";

const Referrals = ({ addNotification, helpPanel }: { addNotification: AddNotification; helpPanel: any }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { data, loading, refetch } = useQuery(GET_CONFIG_REFERRALS, { fetchPolicy: "cache-and-network" });
  const [createReferral] = useMutation(POST_CONFIG_REFERRAL);
  const { t } = useTranslation();

  const handleSubmitReferral = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await createReferral({ variables: { email: e.target.referralEmail.value } });
      refetch();
      addNotification({ ok: 1, message: "Your referral was sent" });
    } catch (error: any) {
      addNotification({ ok: 0, message: error.message });
    } finally {
      setIsLoading(false);
    }
  };

  const referrals = data?.configReferrals?.referrals;

  if (!referrals || loading) return <Loader />;

  return (
    <Zone className="referrals">
      <div className="left">
        <div className="header">
          <h2>{t("My referrals")}</h2>
          <Button
            variant="secondaryOverZone"
            overZone
            style={{ marginLeft: "var(--gutter)" }}
            type="button"
            onClick={() => helpPanel.load("7329018")}>
            <span>{t("Help?")}</span>
          </Button>
        </div>
        <p className="text-s">
          {t("For each referred customer we offer a free month membership.")}
          <br />
          {t("A referral is completed when the first monthly payment is processed.")}
        </p>
        <div className="blocks">
          <div className="block">
            <p className="number">{referrals.length}</p>
            <p className="label">{t("Invites sent")}</p>
          </div>
          <div className="block">
            <p className="number">{referrals.filter(r => r.redeem?.confirmed).length}</p>
            <p className="label">{t("Completed referrals")}</p>
          </div>
        </div>
      </div>
      <div className="right">
        <form onSubmit={handleSubmitReferral} className="invite">
          <InputWithSubmit
            variant="overZone"
            name="referralEmail"
            placeholder={t("Email address") + "..."}
            label="Invite someone"
            type={t("Email address")}
            submitText={isLoading ? <Loader /> : t("Send referral invite")}
          />
        </form>
        <div className="invites">
          {referrals.map(r => (
            <div className="referral" key={r.id}>
              {r.redeem?.confirmed ? (
                <Button disabled variant="success">
                  {r.email}
                </Button>
              ) : (
                <Button disabled variant="secondaryOverZone">
                  {r.email} {t("(pending)")}
                </Button>
              )}
            </div>
          ))}
        </div>
      </div>
    </Zone>
  );
};

export default Referrals;
