import React from "react";
import { useLocation } from "react-router-dom";
import URI from "urijs";
import Pagination from "../common/pagination";
import Loader from "../common/loader";
import Tile from "../common/tile";
import { GlobalStore } from "../../stores/global";
import { useQuery } from "@apollo/client";
import { GET_INVENTORY_AS_CATALOGUE } from "../../graphql/queries/item";
import { Config } from "../../__generated__/graphql";
import { useTranslation } from "react-i18next";

export default function Catalogue() {
  const location = useLocation();
  const currentPath = location.pathname + location.search;
  const currentUri = new URI(currentPath);
  const query = currentUri.search(true);
  const config = GlobalStore.useState(c => c.config);
  const { t } = useTranslation();

  const initialFilters: any = {
    limit: 24,
    page: query.page || 1
  };

  if (query.styles) {
    initialFilters.styles = Array.isArray(query.styles) ? query.styles : [query.styles];
  }
  if (query.genres) {
    initialFilters.genres = Array.isArray(query.genres) ? query.genres : [query.genres];
  }
  if (query.labels) {
    initialFilters.labels = Array.isArray(query.labels) ? query.labels : [query.labels];
  }
  if (query.years) {
    initialFilters.years = (Array.isArray(query.years) ? query.years : [query.years]).map(id => parseInt(id));
  }
  if (query.artists) initialFilters.artists = (Array.isArray(query.artists) ? query.artists : [query.artists]).map(id => parseInt(id));
  if (query.from) initialFilters.from = query.from;
  if (query.categories) initialFilters.categories = query.categories;
  if (query.page) initialFilters.page = parseInt(query.page) || 1;

  const { data } = useQuery(GET_INVENTORY_AS_CATALOGUE, { fetchPolicy: "cache-and-network", variables: initialFilters });
  const page = data?.inventory;

  return (
    <div id="catalogue">
      <div id="filtersAndContent">
        {page && page.entries ? (
          <section id="catalogueEntries">
            {page.entries && page.entries.length ? (
              page.entries.map(e => <Tile key={e.id} config={config as Config} entry={e}></Tile>)
            ) : (
              <p>{t("No results found")}</p>
            )}
          </section>
        ) : (
          <Loader withMargins />
        )}
      </div>
      {page ? <Pagination pagination={{ ...page.pagination, page: initialFilters.page }} currentUri={currentUri} /> : null}
    </div>
  );
}
