import { ReactNode } from "react";

export interface AddNotification {
  (options: { ok: number; message: string | ReactNode; children?: any }): void;
}

export interface ApolloError {
  message: string;
}

export enum EThemes {
  light = "light",
  dark = "dark"
}

export interface Location {
  pathname: string;
  search: string;
}

export interface Match {
  path: string;
  params: {
    id: string;
    state?: string;
  };
}

export interface ConfigModule {
  id: string;
  data: any;
}
