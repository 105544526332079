import React, { useState } from "react";
import Button from "../../styled/button";
import TextArea from "../../styled/textArea";
import Zone from "../../styled/zone";
import Input from "../../styled/input";
import { Select } from "../../styled/select";
import clone from "clone";
import { useMutation } from "@apollo/client";
import { POST_CONFIG_UPDATE } from "../../../graphql/queries/config";
import { Config, ConfigSocial } from "../../../__generated__/graphql";
import { AddNotification } from "../../../types/globals";
import { Button as ButtonV2 } from "../../../componentsV2/Button";
import { useTranslation } from "react-i18next";

const Timezones = require("../../../assets/timezones.json");

export default function Information({
  config,
  module: moduleToUpdate,
  updateModule,
  addNotification
}: {
  config: Config;
  module: any;
  updateModule: any;
  addNotification: AddNotification;
}) {
  const [module, setModule] = useState(clone(moduleToUpdate));
  const [updateConfig] = useMutation(POST_CONFIG_UPDATE);
  const [emailFrom, setEmailFrom] = useState(config.communication?.emails?.from);
  const { t } = useTranslation();

  const handleChange = (event: any) => {
    const currentStateData = module.data;
    const target = event.target;
    currentStateData[event.target.name] = target.type === "checkbox" ? target.checked : target.value;
    setModule({ ...module, data: currentStateData });
  };

  const handleSelectChange = async (selectedOption: any, name: string) => {
    if (name === "timezone") {
      const currentStateData = module.data;
      currentStateData[name] = selectedOption.value;
      setModule({ ...module, data: currentStateData });
    } else if (name === "language") {
      try {
        await updateConfig({ variables: { language: selectedOption.value } });
        addNotification({ ok: 1, message: "Default language updated" });
      } catch (error: any) {
        addNotification({ ok: 0, message: error.message });
      }
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    await updateModule(module);
    updateConfig({ variables: { emailFrom } });
  };

  const timezone = module.data.timezone;
  const languages = [
    { name: t("English"), code: "en" },
    { name: t("French"), code: "fr" },
    { name: t("German"), code: "de" },
    { name: t("Italian"), code: "it" },
    { name: t("Spanish"), code: "es" },
    { name: t("Russian"), code: "ru" }
  ];
  const defaultLanguage = config.communication?.language && languages.find(l => l.code === config.communication?.language);

  return (
    <div id="information">
      <section>
        <form className="" onSubmit={handleSubmit}>
          <div className="preferencesFields">
            <Input label={t("Business name")} required type="text" name="shopName" value={module.data.shopName} onChange={handleChange} />
            <Input
              label={t("Short description")}
              type="text"
              name="introduction"
              value={module.data.introduction}
              onChange={handleChange}
            />
            <div className="emails">
              <Zone className="emailInputs">
                <Input
                  variant="overZone"
                  label={t("Email address")}
                  type="email"
                  name="email"
                  required
                  value={module.data.email}
                  onChange={handleChange}
                />
                <Input
                  variant="overZone"
                  label={t("Billing email")}
                  type="email"
                  name="billingEmail"
                  value={module.data.billingEmail}
                  onChange={handleChange}
                />
                <Input
                  variant="overZone"
                  iconPosition="right"
                  label={t("Email sender name")}
                  required
                  type="text"
                  pattern="[A-Za-z0-9&-_ ]+"
                  name="emailFrom"
                  value={emailFrom || ""}
                  onChange={(e: any) => {
                    if (e.target.value.length < 32) setEmailFrom(e.target.value);
                  }}
                />
                <p>
                  {t("Email sender will appear as")}: <br />
                  <span className="reset" style={{ fontStyle: "italic" }}>
                    {emailFrom}
                    {" <"}
                    {config.communication?.emails?.endpoints ? config.communication.emails?.endpoints[0] : ""}
                    {">"}
                  </span>
                </p>
              </Zone>
            </div>
            <TextArea
              label={t("Description")}
              value={module.data.shopDescription}
              name="shopDescription"
              rows="8"
              onChange={handleChange}
            />
            <TextArea label={t("Shop address")} value={module.data.shopAddress} name="shopAddress" rows="8" onChange={handleChange} />
            <div className="spacedRows">
              <Select
                label={t("Select your timezone")}
                options={Timezones.map((t: any) => ({ label: t.timeZoneName, value: t.timeZoneName }))}
                onChange={(e: any) => handleSelectChange(e, "timezone")}
                value={timezone ? { label: timezone, value: timezone } : { label: "Europe/London", value: "Europe/London" }}
              />
              <Select
                label={t("Select a default outgoing emails language")}
                options={languages.map(t => ({ label: t.name, value: t.code }))}
                onChange={(e: any) => handleSelectChange(e, "language")}
                value={defaultLanguage ? { label: defaultLanguage.name, value: defaultLanguage.code } : null}
              />
            </div>
          </div>
          <ButtonV2 variant="primary" type="submit" styleProps={{ marginTop: "var(--gutter)" }}>
            {t("Submit")}
          </ButtonV2>
        </form>
      </section>

      <div className="preferencesFields">
        <Zone>
          <Socials config={config} addNotification={addNotification} />
        </Zone>
      </div>
    </div>
  );
}

const Socials = ({ config, addNotification }: { config: Config; addNotification: AddNotification }) => {
  const [updateConfig] = useMutation(POST_CONFIG_UPDATE);
  const [socials, setSocials] = useState<ConfigSocial[]>(clone(config.socials) || []);
  const [dirty, setDirty] = useState(false);
  const { t } = useTranslation();

  const handleSubmitForm = async (e: any) => {
    e.preventDefault();
    try {
      const { data } = await updateConfig({ variables: { socials: socials.map(s => ({ id: s.id, title: s.title, value: s.value })) } });
      if (data?.configUpdate?.socials) {
        setSocials(clone(data.configUpdate.socials));
        setDirty(false);
        addNotification({ ok: 1, message: t("Updated") });
      }
    } catch (error: any) {
      addNotification({ ok: 0, message: error.message });
    }
  };

  const handleAddSocial = (value: any) => {
    setDirty(true);
    socials.push({ title: value.label, id: value.value, value: "" });
    setSocials([...socials]);
  };

  const handleChangeValue = (idx: number, value: string) => {
    setDirty(true);
    socials[idx].value = value;
    setSocials([...socials]);
  };

  const handleRemoveEntry = (idx: number) => {
    setDirty(true);
    socials.splice(idx, 1);
    setSocials([...socials]);
  };

  const list = [
    { title: "Discogs", id: "discogs" },
    { title: "Ebay", id: "ebay" },
    { title: "Facebook", id: "facebook" },
    { title: "Instagram", id: "instagram" },
    { title: "Soundcloud", id: "soundcloud" },
    { title: "Mixcloud", id: "mixcloud" },
    { title: "Bandcamp", id: "bandcamp" },
    { title: "Twitter", id: "twitter" },
    { title: "Youtube", id: "youtube" },
    { title: "Spotify", id: "spotify" },
    { title: "Apple Music", id: "apple-music" },
    { title: "Phone", id: "phone" },
    { title: "Mail", id: "mail" },
    { title: "Maps", id: "maps" }
  ];

  return (
    <div className="socials">
      <Select
        variant="overZone"
        label={t("Add a social network")}
        options={list.filter(l => !socials.find(s => s.id === l.id)).map(e => ({ label: e.title, value: e.id }))}
        onChange={handleAddSocial}
      />
      <form id="socials" onSubmit={handleSubmitForm}>
        <div className="entries">
          {socials.map((s, idx) => (
            <div key={s.id} className="entry">
              <i className={`cg-icon-${s.id}`} />
              <Input
                variant="overZone"
                name="value"
                label={s.title}
                placeholder={t("Enter a web URL") + "..."}
                required
                value={s.value || ""}
                onChange={(e: any) => handleChangeValue(idx, e.target.value)}
              />
              <Button variant="noStyle" type="button" onClick={() => handleRemoveEntry(idx)}>
                <i className="cg-icon-burger-close" />
              </Button>
            </div>
          ))}
        </div>
        <ButtonV2 variant="primary" type="submit" disabled={!dirty} styleProps={{ marginTop: "var(--gutter)" }}>
          {t("Submit")}
        </ButtonV2>
      </form>
    </div>
  );
};
