import { useTranslation } from "react-i18next";
import React from "react";

export default function FourOFour({ message }: { message?: string }) {
  const { t } = useTranslation();
  return (
    <div id="fourOFour">
      <section>
        <h1>{message || t("The page you requested wasn't found")}</h1>
      </section>
    </div>
  );
}
