import React, { useState, useEffect } from "react";
import Checkbox from "../../styled/checkbox";
import Input from "../../styled/input";
import Zone from "../../styled/zone";
import Button from "../../styled/button";
import InputWithIcon from "../../styled/inputWithIcon";
import clone from "clone";
import { GET_CONFIG_DISCOGS_IDENTITY, POST_CONFIG_BANDCAMP_SETTINGS, POST_CONFIG_DISCOGS_UPDATE } from "../../../graphql/queries/config";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GlobalStore } from "../../../stores/global";
import * as amplitude from "@amplitude/analytics-browser";
import { Config, ConfigDiscogs } from "../../../__generated__/graphql";
import { AddNotification } from "../../../types/globals";
import { Button as ButtonV2 } from "../../../componentsV2/Button";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { Typography } from "../../../componentsV2/Typography";
import { useHistory, useLocation } from "react-router-dom";
import URI from "urijs";

export default () => {
  const { config, addNotification, helpPanel } = GlobalStore.useState(c => c);
  const { t } = useTranslation();
  return (
    <div className="externalServices">
      <DiscogsSettings />
      <BandcampSettings addNotification={addNotification} helpPanel={helpPanel} config={config as Config} t={t} />
      <Zone>
        <div className="pixel">
          <Typography variant="subTitle">{t("Facebook & Instagram marketplace")}</Typography>
          <hr />
          <p>
            {t("Your inventory CSV data source link")}:
            <br />
            <strong className="facebookCSVLink">{`https://api-client.common-ground.io/inventory/${config?._id}/csv`}</strong>
            <br />
          </p>
          <p>
            {t("From your Facebook Business catalogue page, select Add Items and select the Use bulk upload method.")}
            {t("It may take up to 15 minutes for your items to be available.")}
          </p>
        </div>
      </Zone>
    </div>
  );
};

const BandcampSettings = ({
  config,
  addNotification,
  helpPanel,
  t
}: {
  config: Config;
  addNotification: AddNotification;
  helpPanel: any;
  t: TFunction;
}) => {
  const [clientId, setClientId] = useState(config.bandcamp?.clientId);
  const [clientSecret, setClientSecret] = useState(config.bandcamp?.clientSecret);
  const [bands, setBands] = useState(config.bandcamp?.bands || []);
  const [updateBandcamp, { loading }] = useMutation(POST_CONFIG_BANDCAMP_SETTINGS);
  const [dirty, setDirty] = useState(false);

  const handleSubmitModule = async (e: any) => {
    e.preventDefault();
    try {
      if (!clientId || !clientSecret) throw new Error("Invalid client or secret");
      const { data } = await updateBandcamp({
        variables: {
          clientId,
          clientSecret,
          bands: bands.filter(b => b.active).map(b => b.id)
        }
      });
      if (!data?.configBandcampUpdateSettings.bandcamp) throw new Error("Update error");
      const results = data.configBandcampUpdateSettings.bandcamp;
      setBands(results.bands);
      setClientId(results.clientId);
      setClientSecret(results.clientSecret);
      addNotification({ ok: 1, message: "Bandcamp settings updated" });
      setDirty(false);
    } catch (error: any) {
      setClientId("");
      setClientSecret("");
      setDirty(false);
      console.log(error.toString());
      addNotification({ ok: 0, message: error.toString() });
    }
  };

  const handleDisconnect = async () => {
    try {
      if (!clientId || !clientSecret) throw new Error("Invalid client or secret");
      const { data } = await updateBandcamp({
        variables: {
          clientId,
          clientSecret,
          bands: bands.filter(b => b.active).map(b => b.id),
          disconnect: true
        }
      });
      if (!data?.configBandcampUpdateSettings.bandcamp) throw new Error("Update error");
      const results = data.configBandcampUpdateSettings.bandcamp;
      setBands(results.bands);
      setClientId(results.clientId);
      setClientSecret(results.clientSecret);
      addNotification({ ok: 1, message: "Bandcamp disconnected" });
      setDirty(false);
    } catch (error: any) {
      console.log(error.toString());
      addNotification({ ok: 0, message: error.toString() });
    }
  };

  const handleChange = (e: any) => {
    setDirty(true);
    if (e.target.name === "clientId") setClientId(e.target.value);
    else if (e.target.name === "clientSecret") setClientSecret(e.target.value);
  };

  const handleCheckboxChange = (e: any) => {
    setDirty(true);
    const cloned = clone(bands);
    const band = cloned.find(b => b.id === parseInt(e.target.name));
    if (band) band.active = e.target.checked;
    setBands(cloned);
  };

  return (
    <Zone className="bandcamp">
      <div className="flexSpaceBetween">
        <Typography variant="subTitle">{t("Bandcamp digital for labels")}</Typography>
        <Button variant="secondary" type="button" onClick={() => helpPanel.load("7328973")}>
          <span>{t("Help?")}</span>
        </Button>
      </div>
      <hr />
      <form onSubmit={handleSubmitModule}>
        <div className="preferencesFields">
          <Input
            variant="overZone"
            label={t("Bandcamp Client ID")}
            placeholder={t("Bandcamp Client ID") + "..."}
            type="text"
            required
            name="clientId"
            value={clientId || ""}
            onChange={handleChange}
          />
          <Input
            variant="overZone"
            label={t("Bandcamp Client Secret")}
            placeholder={t("Bandcamp Client Secret") + "..."}
            type="text"
            required
            name="clientSecret"
            value={clientSecret || ""}
            onChange={handleChange}
          />
        </div>
        {bands.map(b => (
          <Checkbox
            onChange={handleCheckboxChange}
            name={b.id}
            label={`Fetch sales for ${b.name}`}
            checked={b.active || false}
            key={b.id}
          />
        ))}
        <div className="flexSpaceBetween">
          <ButtonV2 variant={dirty ? "primary" : "secondary"} disabled={loading || !dirty} type="submit">
            {t("Submit")}
          </ButtonV2>
          {clientSecret ? (
            <Button
              variant="danger"
              disabled={loading || dirty}
              type="button"
              onClick={() => (window.confirm(t("Are you sure?")) ? handleDisconnect() : null)}>
              {t("Disconnect my account")}
            </Button>
          ) : null}
        </div>
      </form>
    </Zone>
  );
};

interface DiscogsIdentity {
  id: number;
  username: string;
  resource_url: string;
  consumer_name: string;
}

const DiscogsSettings = () => {
  const { config, addNotification, helpPanel } = GlobalStore.useState(c => c);
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [discogsIdentity, setDiscogsIdentity] = useState<DiscogsIdentity | undefined>(undefined);
  const [state, setState] = useState<ConfigDiscogs>(clone((config as Config).discogs));
  const [discogsUrl, setDiscogsUrl] = useState<string | null>(null);
  const [dirty, setDirty] = useState<boolean>(false);
  const [updateDiscogsSettings] = useMutation(POST_CONFIG_DISCOGS_UPDATE);
  const [getIdentity] = useLazyQuery(GET_CONFIG_DISCOGS_IDENTITY, { fetchPolicy: "cache-and-network" });

  useEffect(() => {
    const search = new URI(location.pathname + location.search).search(true);
    const submitConnect = async (verifier: string) => {
      updateDiscogsSettings({ variables: { verifier } })
        .then(() => amplitude.track("Discogs connected"))
        .catch(e => {
          addNotification({ ok: 0, message: e.message });
          history.push("/preferences/external-services");
        });
    };
    if (!search.denied && search.oauth_verifier) submitConnect(search.oauth_verifier);
  }, [location]);

  useEffect(() => {
    if (config?.discogs.enabled)
      getIdentity()
        .then(response => {
          if (response.data?.configDiscogsIdentity) {
            setDiscogsIdentity(response.data?.configDiscogsIdentity);
          }
        })
        .catch((e: any) => addNotification({ ok: 0, message: e.mesasge }));
  }, [config?.discogs.enabled]);

  const handleChange = (name: string, value: any) => {
    // @ts-ignore
    state[name] = value;
    setState(clone(state));
    setDirty(true);
  };

  const handleDiscogsConnect = async () => {
    try {
      updateDiscogsSettings({ variables: { requestToken: true } }).then(({ data }) => {
        const url = data?.configDiscogsUpdate.discogs.requestData?.authorizeUrl;
        if (url) {
          setDiscogsUrl(url);
          setState(clone(state));
          amplitude.track("Discogs connect initiated");
        }
      });
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
  };

  const handleDiscogSave = async ({ disconnect }: { disconnect: boolean }) => {
    if (disconnect) {
      updateDiscogsSettings({ variables: { disconnect: true } }).then(() => setDirty(false));
    } else {
      updateDiscogsSettings({
        variables: {
          settings: {
            excludeTaxesFromPrice: state.excludeTaxesFromPrice,
            syncInventory: state.syncInventory,
            listingExtraRate: state.listingExtraRate
          }
        }
      })
        .then(() => setDirty(false))
        .catch(e => addNotification({ ok: 0, message: e.message }));
    }
  };

  const handleSynchronizeChange = () => {
    state.syncInventory = !state.syncInventory;
    setState(clone(state));
    setDirty(true);
  };

  const handlePriceTaxChange = () => {
    state.excludeTaxesFromPrice = !state.excludeTaxesFromPrice;
    setState(clone(state));
    setDirty(true);
  };

  const isDiscogsConnected = config?.discogs.enabled;

  return (
    <Zone>
      <div className="flexSpaceBetween">
        <Typography variant="subTitle">{t("Discogs")}</Typography>
        <Button variant="secondary" type="button" onClick={() => helpPanel.load("7328923")}>
          <span>{t("Help?")}</span>
        </Button>
      </div>
      <hr />
      <div style={{ marginTop: "calc(var(--gutter)*1)" }}>
        {isDiscogsConnected ? (
          <form
            style={{ display: "grid", gridGap: "var(--gutter)" }}
            onSubmit={(e: any) => {
              e.preventDefault();
              handleDiscogSave({ disconnect: false });
            }}>
            <div style={{ marginBottom: "var(--gutter)", display: "grid", gridTemplateColumns: "1fr 1fr" }}>
              <div>
                <Typography variant="copy" level="highlight" tag="p">
                  {t("Your account is connected to Discogs")}.
                </Typography>
                {discogsIdentity ? (
                  <Typography variant="copy" level="secondary" tag="p">
                    {t("All queries are made using your Discogs account")} <strong>{discogsIdentity.username}</strong>
                  </Typography>
                ) : null}
                <div style={{ marginTop: "calc(var(--gutter)*1)" }}>
                  <Checkbox
                    variant="overZone"
                    label={t("Synchronise the stock and import orders from Discogs")}
                    checked={state.syncInventory || false}
                    onChange={handleSynchronizeChange}
                  />
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "column", gap: "var(--gutter)" }}>
                <div>
                  <InputWithIcon
                    icon="%"
                    iconPosition="right"
                    variant="overZone"
                    label={t("Percentage to add to Discogs listing price")}
                    type="number"
                    min="-99"
                    max="99"
                    step="0.1"
                    name="listingExtraRate"
                    onWheel={(e: any) => e.target.blur()}
                    value={state.listingExtraRate}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange("listingExtraRate", e.target.valueAsNumber)}
                  />
                </div>
                <Checkbox
                  variant="overZone"
                  label={t("Exclude tax from Discogs listing prices")}
                  checked={state.excludeTaxesFromPrice || false}
                  onChange={handlePriceTaxChange}
                />
                <Typography variant="copy" level="secondary">
                  {t("Changing this value is not retro-active, only newly listed items will reflect this margin")}
                </Typography>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "var(--gutter)" }}>
              <ButtonV2
                styleProps={{ marginTop: "var(--gutter)" }}
                variant="warning"
                disabled={!discogsIdentity}
                onClick={() => window.confirm(t("Are you sure?")) && handleDiscogSave({ disconnect: true })}>
                {t("Disconnect my account")}
              </ButtonV2>
              <ButtonV2 variant="primary" type="submit" disabled={!dirty} styleProps={{ marginTop: "var(--gutter)" }}>
                {t("Submit")}
              </ButtonV2>
            </div>
          </form>
        ) : (
          <div>
            {discogsUrl ? (
              <div style={{}}>
                <Typography variant="copy" tag="p" style={{ marginBottom: "var(--gutter)" }}>
                  {t("Click on the following link and follow the instructions")}
                </Typography>
                <a target="_tab" href={discogsUrl}>
                  <ButtonV2 type="button" variant="primary">
                    {t("Continue to discogs.com")}
                  </ButtonV2>{" "}
                </a>
              </div>
            ) : (
              <div>
                <Typography tag="p" style={{ marginBottom: "var(--gutter)" }} variant="copy">
                  {t("Your Discogs account is not connected")}
                </Typography>
                <ButtonV2 variant="primary" onClick={handleDiscogsConnect}>
                  {t("Connect my Discogs account")}
                </ButtonV2>
              </div>
            )}
          </div>
        )}
      </div>
    </Zone>
  );
};
