import React from "react";
import Text from "../../styled/text";
import Zone from "../../styled/zone";
import { Link } from "react-router-dom";
import Price from "../../common/price";
import { Close } from "../../global/icons";
import { FiCheck } from "react-icons/fi";
import { useQuery } from "@apollo/client";
import { GET_CONFIG_STATISTICS } from "../../../graphql/queries/config";
import { Config } from "../../../__generated__/graphql";
import { getConfigProperty } from "../../../utils";
import { useTranslation } from "react-i18next";

const Summary = ({ config }: { config: Config }) => {
  const hasPaypal = !!getConfigProperty(config, "paypal", "clientID");
  const hasStripe = config.eshop.checkout.paymentMethods?.stripe?.enabled;
  const logoImage = getConfigProperty(config, "designs", "logoImage");
  const { data } = useQuery(GET_CONFIG_STATISTICS, { fetchPolicy: "cache-and-network" });
  const { t } = useTranslation();

  const statistics = data?.configStatistics;

  return (
    <Zone className="summary">
      <div className="left">{logoImage ? <img src={logoImage} alt="shop logo" /> : config.shopName}</div>
      <div className="right">
        <div className="header">
          <div className="top">
            <Text>
              <h2 className="text-l-medium">{config.shopName} </h2>
            </Text>
            <p className="text-l">
              {config.plan ? (
                <>
                  {t("Available seats")}: {config.plan.seats - config.admins.length}
                </>
              ) : null}
            </p>
          </div>
          <p className="text-m">{getConfigProperty(config, "information", "shopAddress")}</p>
        </div>
        <hr />
        <div className="stats">
          <h3>
            {statistics?.itemCount} {t("items")}
          </h3>
          <h3>
            {statistics?.listingsCount} {t("listings")}
          </h3>
          <h3>
            {statistics?.stockCount} {t("in stock")}
          </h3>
          <h3>
            <Price value={statistics?.total as number} />
          </h3>
        </div>
        <hr />
        <div className="methods">
          <Link to="/editor/checkout/payments" className={`step ${hasPaypal ? "active" : ""}`}>
            <div className="state">{hasPaypal ? <FiCheck /> : <Close />}</div>
            <div className="text">
              <p>{"Paypal"}</p>
            </div>
          </Link>
          <Link to="/editor/checkout/payments" className={`step ${hasStripe ? "active" : ""}`}>
            <div className="state">{hasStripe ? <FiCheck /> : <Close />}</div>
            <div className="text">
              <p>{"Stripe"}</p>
            </div>
          </Link>
        </div>
      </div>
    </Zone>
  );
};

export default Summary;
