import React, { useState, useRef, useEffect } from "react";
import Button from "../styled/button";
import TextArea from "../styled/textArea";
import { Link } from "react-router-dom";
import Modal from "../modal";
import Loader from "../common/loader";
import Liner from "../common/liner";
import { GlobalStore } from "../../stores/global";
import moment from "moment";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  GET_SUPPORT_CONVERSATIONS,
  GET_SUPPORT_CONVERSATION_SIGNED_URL,
  POST_SUPPORT_CONVERSATION_CLOSE,
  POST_SUPPORT_CONVERSATION_CREATE,
  POST_SUPPORT_CONVERSATION_REPLY
} from "../../graphql/queries/support";
import { convert } from "html-to-text";
import { Select } from "../styled/select";
import Axios from "axios";
import * as Sentry from "@sentry/react";
import { useQueryState } from "../../hooks/useQueryParamsState";
import { Config, Session } from "../../__generated__/graphql";
import { AddNotification } from "../../types/globals";
import { getConfigProperty } from "../../utils";
import { Button as ButtonV2 } from "../../componentsV2/Button";
import { useTranslation } from "react-i18next";

export default function Support() {
  const { config, addNotification, session } = GlobalStore.useState(c => c);
  const modalRef = useRef<any>();
  const { t } = useTranslation();

  document.title = t("Support");

  const { data, loading, refetch } = useQuery(GET_SUPPORT_CONVERSATIONS, { fetchPolicy: "cache-and-network" });
  const [closeConversation] = useMutation(POST_SUPPORT_CONVERSATION_CLOSE);
  const conversations = data?.supportConversations?.conversations;
  const [ticketTypeQuery, setTicketTypeQuery] = useQueryState("type");

  const handleCloseIntercomTicket = async (id: string) => {
    try {
      await closeConversation({ variables: { conversationId: id } });
      addNotification({ ok: 1, message: t("Ticket was closed") });
    } catch (e: any) {
      Sentry.captureException(e);
      addNotification({ ok: 0, message: e.message });
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "0",
      minWidth: "50vw",
      maxHeight: "80vh",
      transform: "translate(-50%, -50%)"
    }
  };

  const getShortContent = (content: string) => {
    const length = content.length;
    const maxLength = 120;
    if (length > maxLength) return convert(content).substring(0, 120) + "...";
    else return convert(content);
  };

  useEffect(() => {
    if (ticketTypeQuery) {
      modalRef.current.open();
    }
  }, [ticketTypeQuery]);

  const onClose = () => {
    // setTicketTypeQuery(null);
  };

  return (
    <div id="support">
      <Modal ref={modalRef} style={customStyles} onClose={onClose}>
        <TicketModal
          session={session as Session}
          config={config as Config}
          refetch={refetch}
          hideModal={modalRef?.current?.close}
          addNotification={addNotification}
          ticketTypeQuery={ticketTypeQuery}
          setTicketTypeQuery={setTicketTypeQuery}
        />
      </Modal>
      <section className="header">
        <h2>{t("Your support tickets")}</h2>
        <ButtonV2 variant="primary" onClick={() => modalRef.current.open()} type="button">
          {t("Submit a ticket")}
        </ButtonV2>
      </section>
      <section id="tickets">
        <div id="intercom">
          {conversations ? (
            conversations.map((c, index) => (
              <Liner index={index} key={c.id} className="ticket">
                <p>
                  <Link to={`/support/ticket/${c.id}`}>#{c.id.substring(11, 16)}</Link>
                </p>
                <p>
                  <Link to={`/support/ticket/${c.id}`}>
                    {moment(c.createdAt).format("ll")} - {moment(c.createdAt).fromNow()}
                  </Link>
                </p>
                <Link to={`/support/ticket/${c.id}`}>
                  <h3>{getShortContent(c.source?.body || "")}</h3>
                </Link>
                <p className={c.state || ""}> {c.state}</p>
                {c.state === "open" ? (
                  <span>
                    <Button variant="secondary" onClick={() => handleCloseIntercomTicket(c.id)}>
                      {t("Close ticket")}
                    </Button>
                  </span>
                ) : (
                  <span />
                )}
              </Liner>
            ))
          ) : loading ? (
            <Loader />
          ) : null}
        </div>
      </section>
    </div>
  );
}

const TicketModal = ({
  session,
  config,
  hideModal,
  refetch,
  addNotification,
  ticketTypeQuery,
  setTicketTypeQuery
}: {
  session: Session;
  config: Config;
  hideModal: any;
  refetch: any;
  addNotification: AddNotification;
  ticketTypeQuery: any;
  setTicketTypeQuery: any;
}) => {
  const [domainTag] = useState(config.domain?.substring(0, 32));
  const [configName] = useState(getConfigProperty(config, "information", "shopName").substring(0, 32));
  const [createConversation] = useMutation(POST_SUPPORT_CONVERSATION_CREATE);
  const [getSignedUrl] = useLazyQuery(GET_SUPPORT_CONVERSATION_SIGNED_URL);
  const [sendReply] = useMutation(POST_SUPPORT_CONVERSATION_REPLY);
  const { t } = useTranslation();
  const [ticket, setTicket] = useState({
    subject: "",
    description: "",
    status: 2,
    priority: 1,
    type: "Feedback:",
    name: session.user.name,
    email: config.communication?.emails?.endpoints ? config.communication.emails.endpoints[0] : "",
    tags: [domainTag, configName, config.id],
    attachments: []
  });

  useEffect(() => {
    if (ticketTypeQuery) {
      updateTicketType("type", ticketTypeQuery);
    }
  }, [ticketTypeQuery]);

  const handleChange = (event: any) => {
    // @ts-ignore
    ticket[event.target.name] = event.target.value;
    setTicket({ ...ticket });
  };

  const updateTicketType = (name: string, value: any) => {
    // FIXME : This is a temporary fix to capitalize the first letter of the ticket type
    value = value.charAt(0).toUpperCase() + value.slice(1).replace(/:/g, "") + ":";
    // @ts-ignore
    ticket[name] = value;
    setTicket({ ...ticket });
  };

  const handleSelectChange = (name: any) => (selectedOption: any) => {
    const formattedValue = selectedOption.value.toLowerCase().replace(/ /g, "+");
    setTicketTypeQuery(formattedValue);
    updateTicketType(name, selectedOption.value);
  };

  const handleFileChange = (event: any) => {
    ticket.attachments = event.target.files;
    setTicket({ ...ticket });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setSubmitting(true);
    try {
      if (!ticket.type) throw new Error(t("Invalid type"));
      const { data: conversationData } = await createConversation({
        variables: { content: `${ticket.type} (v${process.env.REACT_APP_VERSION}) - ${ticket.description}` }
      });
      if (!conversationData?.supportConversationCreate) throw new Error(t("Error"));
      const conversationId = conversationData.supportConversationCreate;
      const urlsToSend: string[] = [];
      if (ticket.attachments) {
        const files = Array.from(ticket.attachments) || [];
        for (const file of files as any) {
          const { data } = await getSignedUrl({ variables: { conversationId, filename: file.name } });
          if (!data?.supportConversationSignedUrl?.url) throw new Error(t("Error"));
          const options = { headers: { "Content-Type": file.type, "Cache-Control": "max-age=31536000" } };
          await Axios.put(data.supportConversationSignedUrl.url, file, options);
          urlsToSend.push("https://static.common-ground.io/" + data.supportConversationSignedUrl.key);
        }
      }
      await new Promise(resolve => setTimeout(resolve, !urlsToSend.length ? 3000 : 1000));
      if (urlsToSend && urlsToSend.length)
        await sendReply({ variables: { conversationId, attachments: urlsToSend, content: "Attachments" } });
      addNotification({ ok: 1, message: t("Ticket was created") });
      refetch();
      hideModal();
    } catch (e: any) {
      Sentry.captureException(e);
      console.error(e);
      addNotification({ ok: 0, message: e.message });
    } finally {
      setSubmitting(false);
    }
  };

  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const options = [
    { value: "Feedback:", label: t("Feedback & Question") },
    { value: "Feature request:", label: t("Feature request") },
    { value: "Bug report:", label: t("Bug report") }
  ];

  return (
    <div id="ticketModal">
      {submitting || submitted ? (
        <section>
          <h2>
            {submitted ? (
              <div className="submitted">
                <p>
                  {t("Your ticket was submitted")}
                  <br />
                  {t("It may take a few minutes to appear in your support section")}
                </p>
                <Button
                  variant="noStyle"
                  type="button"
                  onClick={() => {
                    setTicketTypeQuery(null);
                    hideModal();
                    setSubmitted(false);
                  }}>
                  {t("Close")}
                </Button>
              </div>
            ) : null}
            {submitting ? (
              <span className="submitting">
                {t("Your ticket is being submitted please wait")}
                {"..."}
              </span>
            ) : null}
          </h2>
        </section>
      ) : null}
      {!submitting && !submitted ? (
        <form id="ticketForm" onSubmit={handleSubmit}>
          <div className="header">
            <h2>{t("Submit a ticket")}</h2>
            <Button
              variant="noStyle"
              type="button"
              onClick={() => {
                setTicketTypeQuery(null);
                hideModal();
              }}>
              {"x"}
            </Button>
          </div>
          <hr />
          <div className="notice">
            <div className="center">
              <a target="_tab" href="https://docs.common-ground.io">
                <h2>{t("Before submitting a ticket, please check our FAQ")}</h2>
              </a>
            </div>
            <h3>{t("Follow these rules if you are reporting a bug or glitch")}:</h3>
            <ul>
              <li>{t("One ticket per report. Do not discuss multiple topics within the same support ticket")}</li>
              <li>
                {t("Give enough information. Always specify a link to the page / release / item / order concerned to be more efficient")}
              </li>
              <li>
                {t("Tell us how to reproduce the bug you encountered. Where have you clicked when the bug appeared?")}
                <br />
                {t("Which pages you were on before it happened? Avoid short formulations and stay specific")}
              </li>
              <li>{t("Add screenshots of the pages when possible")}</li>
            </ul>
          </div>
          <section className="preferencesFields">
            <Select
              label="Type"
              value={ticket.type ? options.find(o => o.value === ticket.type) : null}
              onChange={handleSelectChange("type")}
              options={options}
            />
            <TextArea
              label={t("Ticket Description. Also mention any relevant information such as page url or steps to re-create the issue")}
              value={ticket.description}
              name="description"
              placeholder={t("Description") + "..."}
              rows="8"
              required
              onChange={handleChange}
            />
            <label>
              {t("Attach image files such as screenshots")}
              <input type="file" name="attachments" multiple onChange={(e: any) => handleFileChange(e)} />
            </label>
          </section>
          <ButtonV2 type="submit" variant="primary" disabled={!ticket.description}>
            {submitting ? <Loader /> : t("Submit")}
          </ButtonV2>
        </form>
      ) : null}
    </div>
  );
};
