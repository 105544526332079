/* eslint-disable quotes */
import React, { useCallback } from "react";
import Button from "../styled/button";
import { Button as ButtonV2 } from "../../componentsV2/Button";
import TextArea from "../styled/textArea";
import Input from "../styled/input";
import Zone from "../styled/zone";
import { PlayerButton } from "../common/button";
import { Select } from "../styled/select";
import { useDropzone } from "react-dropzone";
import { Config, Item, ItemType, Listing, ReleaseFormat } from "../../__generated__/graphql";
import { ItemEditable, ReleaseTrackEditable } from "./edit.release";
import { TFunction } from "i18next";

export function FormatEditor({
  formats,
  label,
  readOnly,
  handleFormatHeadUpdate,
  handleDeleteFormat,
  handleDescriptionUpdate,
  handleDeleteDescription,
  handleAddDescription,
  handleFormatAdd,
  t
}: {
  handleFormatHeadUpdate: any;
  formats: ReleaseFormat[];
  label: string;
  readOnly?: boolean;
  handleDeleteFormat: any;
  handleDescriptionUpdate: any;
  handleDeleteDescription: any;
  handleAddDescription: any;
  handleFormatAdd: any;
  t: TFunction;
}) {
  return (
    <div className="formatEditor">
      <label style={{ fontWeight: 600 }}>{label}</label>
      <Zone className="formats" style={{ marginTop: 10 }}>
        {formats.map((f, formatIndex) => (
          <div className="format" key={formatIndex}>
            <div className="header">
              <p>{t("Quantity per media and media type")}</p>
              <div className="content">
                <Input
                  variant="overZone"
                  type="number"
                  min="1"
                  max="99"
                  className="quantity"
                  value={f.qty}
                  disabled={readOnly === true}
                  onWheel={(e: any) => e.target.blur()}
                  onChange={(e: any) =>
                    handleFormatHeadUpdate({
                      formatIndex,
                      name: "qty",
                      value: e.target.value
                    })
                  }
                />
                <p>X</p>
                <Input
                  variant="overZone"
                  disabled={readOnly === true}
                  onChange={(e: any) =>
                    handleFormatHeadUpdate({
                      formatIndex,
                      name: "name",
                      value: e.target.value
                    })
                  }
                  className="name"
                  value={f.name}
                />
                {formatIndex > 0 ? (
                  <Button variant="noStyle" type="button" onClick={() => handleDeleteFormat(formatIndex)}>
                    X
                  </Button>
                ) : null}
              </div>
            </div>
            <div className="descriptions">
              <p>{t('Format description (eg. Album, 12", LP)')}</p>
              {f.descriptions.map((d, index) => (
                <div key={`d${index}-${formatIndex}`} className="description">
                  <Input
                    variant="overZone"
                    fullWidth
                    disabled={readOnly === true}
                    onChange={(e: any) =>
                      handleDescriptionUpdate({
                        formatIndex,
                        index,
                        value: e.target.value
                      })
                    }
                    value={d}
                  />
                  <Button variant="noStyle" type="button" onClick={() => handleDeleteDescription({ formatIndex, index })}>
                    X
                  </Button>
                </div>
              ))}
            </div>
            <div style={{ marginBottom: "var(--gutter)" }}>
              <Input
                label={t("Free text")}
                variant="overZone"
                disabled={readOnly === true}
                onChange={(e: any) =>
                  handleFormatHeadUpdate({
                    formatIndex,
                    name: "text",
                    value: e.target.value
                  })
                }
                className="text"
                value={f.text}
              />
            </div>
            {!readOnly ? (
              <ButtonV2 variant="secondary" type="button" onClick={() => handleAddDescription(formatIndex)}>
                {t("Add")}
              </ButtonV2>
            ) : (
              <span />
            )}
          </div>
        ))}
        {!formats.length ? (
          <ButtonV2 variant="secondary" type="button" onClick={() => handleFormatAdd()}>
            {t("Add")}
          </ButtonV2>
        ) : null}
      </Zone>
    </div>
  );
}

export function DescriptionEditor({ content, onChange, label }: { content: any; onChange: any; label: string }) {
  return (
    <div className="descriptionEditor">
      <TextArea label={`${label} (${content.length})`} onChange={(e: any) => onChange(e.target.value)} rows="6" value={content || ""}>
        {content}
      </TextArea>
    </div>
  );
}

export const TracklistEditor = ({
  onInputChange,
  onDeleteTrack,
  release,
  new: isNew,
  label,
  onSnippetDrop,
  readOnly = false,
  onError,
  tracklist,
  onAddTrack,
  t,
  newEditor
}: {
  onInputChange: any;
  onDeleteTrack: any;
  release: Item | ItemEditable;
  new: boolean;
  label: string;
  onSnippetDrop: any;
  readOnly?: boolean;
  onError: any;
  tracklist: ReleaseTrackEditable[];
  onAddTrack: any;
  t: TFunction;
  newEditor?: boolean;
}) => {
  return (
    <div id={newEditor ? "tracklistEditorNew" : "tracklistEditor"}>
      {!newEditor ? <label style={{ fontWeight: 600 }}>{label}</label> : null}
      <Zone id="tracklist" style={{ marginTop: newEditor ? 0 : 10 }}>
        <div id="tracklistHeader">
          <p></p>
          <p>{t("Type")}</p>
          <p>{t("Position")}</p>
          <p>{t("Title")}</p>
          <p className="duration">{t("Duration")}</p>
          <p className="snippet">{t("Snippet")}</p>
        </div>
        {tracklist.map((track, index) => (
          <div key={index} className="track">
            <Button onClick={() => onDeleteTrack(index)} type="button" className="delete" variant="noStyle">
              X
            </Button>
            <Select
              variant="overZone"
              value={{ value: track.type_, label: track.type_ }}
              options={["track", "heading"].map(e => ({ label: e, value: e }))}
              className="type"
              readOnly={readOnly}
              onChange={(option: any) =>
                onInputChange({
                  name: "type_",
                  value: option.value,
                  index
                })
              }
            />
            <Input
              variant="overZone"
              disabled={readOnly === true}
              onChange={(e: any) =>
                onInputChange({
                  name: "position",
                  value: e.target.value,
                  index
                })
              }
              className="position"
              value={track.position || ""}
            />
            <Input
              variant="overZone"
              disabled={readOnly === true}
              onChange={(e: any) =>
                onInputChange({
                  name: "title",
                  value: e.target.value,
                  index
                })
              }
              className="title"
              value={track.title || ""}
            />
            <Input
              variant="overZone"
              onChange={(e: any) =>
                onInputChange({
                  name: "duration",
                  value: e.target.value,
                  index
                })
              }
              disabled={readOnly === true}
              className="duration"
              value={track.duration || ""}
            />
            <div className={`snippet ${track.isUploaded ? "uploaded" : ""}`}>
              {track.uri ? <PlayerButton track={track} release={release} /> : <span />}
              {track.type_ === "track" ? (
                <>
                  {isNew ? (
                    <small>{t("Please submit this item by clicking “Save item” before uploading snippets")}</small>
                  ) : (
                    <SnippetDropzone
                      isUploaded={track.isUploaded}
                      position={track.position}
                      onDrop={onSnippetDrop}
                      uploadingState={track.isUploading}
                      onError={onError}
                      t={t}
                    />
                  )}
                </>
              ) : null}
            </div>
          </div>
        ))}
        <ButtonV2 variant="secondary" disabled={readOnly === true} type="button" onClick={onAddTrack}>
          {t("Add track")}
        </ButtonV2>
      </Zone>
    </div>
  );
};

function SnippetDropzone({
  position,
  onError,
  onDrop,
  uploadingState,
  isUploaded,
  t
}: {
  position?: string | null;
  onError: any;
  onDrop: any;
  uploadingState?: boolean;
  isUploaded?: boolean;
  t: TFunction;
}) {
  const onDropFile = useCallback(async (acceptedFiles: File[], rejectedFiles: File[]) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      onDrop(position, file);
    }
    if (rejectedFiles.length) {
      onError("File rejected - Format allowed mp3 - File size max 5MB");
    }
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropFile,
    minSize: 0,
    maxSize: 5000000,
    accept: "audio/mpeg, audio/mp3"
  });

  return (
    <div {...getRootProps({ className: "dropzone" })}>
      <input {...getInputProps()} />
      <p>{isUploaded ? t("Uploaded") : uploadingState ? t("Uploading") + "..." : t("Drop snippet here")}</p>
    </div>
  );
}

export function getDefaultListingData(config: Config, type: ItemType): Listing {
  const discardStorage = localStorage.getItem(config.id + "-dontRememberInputs") === "true";
  const costStorage = localStorage.getItem(`${config.id}-add-listing-cost`) || null;
  const costPrice: number | null = costStorage ? parseFloat(costStorage) : null;
  if (type === "ReleaseItem")
    return {
      stock: { quantity: 1 },
      status: discardStorage ? "published" : localStorage.getItem(`${config.id}-add-listing-status`) || "published",
      available: new Date(),
      secondHand: discardStorage ? false : localStorage.getItem(`${config.id}-add-listing-secondHand`) === "true",
      location: discardStorage ? "" : localStorage.getItem(`${config.id}-add-listing-location`) || "",
      supplierCode: discardStorage ? "" : localStorage.getItem(`${config.id}-add-listing-supplierCode`) || "",
      categories: discardStorage ? [] : JSON.parse(localStorage.getItem(`${config.id}-add-listing-categories`) || "[]"),
      comments: discardStorage ? "" : localStorage.getItem(`${config.id}-add-listing-comments`) || "",
      taxDefinition: discardStorage ? "" : localStorage.getItem(`${config.id}-add-listing-taxDefinition`) || "",
      options: [
        {
          name: "Media Condition",
          value: discardStorage ? "Mint (M)" : localStorage.getItem(`${config.id}-add-listing-options-ReleaseItem-0-value`) || "Mint (M)"
        },
        {
          name: "Sleeve Condition",
          value: discardStorage ? "Mint (M)" : localStorage.getItem(`${config.id}-add-listing-options-ReleaseItem-1-value`) || "Mint (M)"
        }
      ],
      prices: { cost: discardStorage ? null : costPrice },
      posted: null
    } as Listing;
  else
    return {
      stock: { quantity: 1 },
      status: discardStorage ? "published" : localStorage.getItem(`${config.id}-add-listing-status`) || "published",
      available: new Date(),
      location: discardStorage ? "" : localStorage.getItem(`${config.id}-add-listing-location`) || "",
      supplierCode: discardStorage ? "" : localStorage.getItem(`${config.id}-add-product-supplierCode`) || "",
      categories: discardStorage ? [] : JSON.parse(localStorage.getItem(`${config.id}-add-listing-categories`) || "[]"),
      comments: discardStorage ? "" : localStorage.getItem(`${config.id}-add-listing-comments`) || "",
      options: [],
      prices: { cost: discardStorage ? null : costPrice },
      posted: null
    } as unknown as Listing;
}

export const IdentifiersEditor = ({
  item,
  handleIdentifierUpdate,
  t
}: {
  item: Item | ItemEditable;
  handleIdentifierUpdate: any;
  t: TFunction;
}) => {
  const handleChange = (index: number, name: string, value: string) => {
    // @ts-ignore
    item.data.identifiers[index][name] = value;
    handleIdentifierUpdate(item.data.identifiers);
  };

  const handleNewRow = () => {
    if (!item.data.identifiers) item.data.identifiers = [];
    item.data.identifiers.push({ type: "", value: "" });
    handleIdentifierUpdate(item.data.identifiers);
  };

  const handleDeleteRow = (index: number) => {
    // @ts-ignore
    item.data.identifiers.splice(index, 1);
    handleIdentifierUpdate(item.data.identifiers);
  };

  return (
    <div className="identifiers">
      {item.data?.identifiers?.map((i, idx) => (
        <div key={idx} className="identifier">
          <Input
            label="Identifier type"
            placeholder="Barcode, Runout, custom field..."
            required
            value={i.type}
            onChange={(e: any) => handleChange(idx, "type", e.target.value)}
          />
          <Input
            label="Value"
            placeholder="Enter identifier value..."
            required
            value={i.value}
            onChange={(e: any) => handleChange(idx, "value", e.target.value)}
          />
          <span>
            <Button type="button" variant="danger" onClick={() => handleDeleteRow(idx)}>
              {t("Delete")}
            </Button>
          </span>
        </div>
      ))}
      <Button type="button" variant="secondary" onClick={() => handleNewRow()}>
        {t("Add a new identifier")}
      </Button>
    </div>
  );
};
