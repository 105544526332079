import React from "react";
import styled from "styled-components";

import colors from "@common-ground-io/common-assets/assets/colors.json";

const layout = {
  light: {
    primary: {
      fontColorLabel: colors.grey,
      bcgColorInput: colors.greyLightest,
      fontColorInput: colors.greyDarker,
      fontColorInputReadonly: colors.greyLight,
      fontColorPlaceholder: colors.grey
    },
    overZone: {
      fontColorLabel: colors.grey,
      bcgColorInput: colors.white,
      fontColorInput: colors.greyDarker,
      fontColorInputReadonly: colors.greyLight,
      fontColorPlaceholder: colors.grey
    }
  },
  dark: {
    primary: {
      fontColorLabel: colors.grey,
      bcgColorInput: colors.greyDarker,
      fontColorInput: colors.greyLighter,
      fontColorInputReadonly: colors.grey,
      fontColorPlaceholder: colors.greyLight
    },
    overZone: {
      fontColorLabel: colors.grey,
      bcgColorInput: colors.greyDark,
      fontColorInput: colors.greyLighter,
      fontColorInputReadonly: colors.grey,
      fontColorPlaceholder: colors.greyLight
    }
  }
};

const Styledlabel = styled.label<{ variant?: string }>`
  font-style: normal;
  font-weight: 600;
  font-size: inherit;
  line-height: 1;

  color: ${props => path(layout, props).fontColorLabel};
`;

const StyledTextArea = styled.textarea<{ variant?: string; fullWidth?: boolean; label?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  margin: 0;
  margin-top: ${props => (props.label ? "10px" : "0px")};
  border: transparent;
  border-radius: 10px;
  outline: none;

  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  line-height: 1;

  width: ${({ fullWidth }) => fullWidth && "100%"};
  background-color: ${props => path(layout, props).bcgColorInput};
  color: ${props => path(layout, props).fontColorInput};
  box-shadow: none;
  outline: none;

  &:read-only {
    color: ${props => path(layout, props).fontColorInputReadonly};
  }
  ::placeholder {
    color: ${props => path(layout, props).fontColorPlaceholder};
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
    transition-delay: 9999s;
    box-shadow: none;
    outline: none;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const computeVariant = (variant: any, layout: any) => (layout.light[variant] ? variant : "primary");
const path = (layout: any, props: any) => layout[props.theme.name || "light"][props.variant];

const TextArea = (props: any) => {
  const variant = computeVariant(props.variant, layout);

  return (
    <Container className="cg-common cg-textArea">
      {props.label && (
        <Styledlabel
          htmlFor={props.id || props.name || ""}
          className={`cg-common ${variant} ${props.className || ""}-label`}
          variant={variant}>
          {props.label}
        </Styledlabel>
      )}
      <StyledTextArea {...props} className={`${variant}`} variant={variant} />
    </Container>
  );
};

export default TextArea;
