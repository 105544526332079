import React from "react";
import { Tooltip } from "react-tooltip";
import { FaQuestionCircle } from "react-icons/fa";
import "react-tooltip/dist/react-tooltip.css";

export default function TooltipComponent({ className, Icon, children }: { className?: string; Icon?: any; children?: any }) {
  const id = Math.random().toString(36).substring(2, 8);

  return (
    <div className={`tooltipContainer ${className || ""}`}>
      <div data-tooltip-id={id} data-tooltip-place="right">
        {Icon ? (
          // @ts-ignore
          <Icon />
        ) : (
          <>
            <FaQuestionCircle />{" "}
          </>
        )}
      </div>

      <Tooltip id={id} place="right" className="tooltip">
        {children}{" "}
      </Tooltip>
    </div>
  );
}
