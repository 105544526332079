import React, { FormEvent, ReactNode, useState } from "react";
import { Config, Item, Listing, Template } from "../../__generated__/graphql";
import { useTranslation } from "react-i18next";
import { Button } from "../Button";
import { POST_ITEM_LISTING_DELETE, POST_ITEM_LISTING_DISCOGS, POST_ITEM_LISTING_UPDATE } from "../../graphql/queries/item";
import { useMutation } from "@apollo/client";
import { ModalHeaderContainer } from "../SectionHeader/SectionHeader.styles";
import { Typography } from "../Typography";
import { AddNotification } from "../../types/globals";
import moment from "moment";
import { PriceInput } from "../../components/common/price";
import clone from "clone";
import DatePicker from "../../components/styled/datePicker";
import { Checkbox } from "../Checkbox";
import Input from "../../components/styled/input";
import {
  ListingEditContent,
  ListingEditContentSection,
  Tabs,
  Tab,
  ListingEditContentContainer,
  ListingEditContentTwoColumns,
  ListingEditContainer,
  ListingEditHeader,
  ListingEditFooter
} from "./Listing.styles";
import { Options } from "../../components/common/options";
import Loader from "../../components/common/loader";
// @ts-ignore
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getPlaceholderImage } from "../../utils";
import { MentionWithIndicator } from "./Listing";
import { SelectTemplate, SelectCategories, SelectLocation, SelectSupplier } from "../../components/common/select";
import TextArea from "../../components/styled/textArea";
import TemplateEditor from "../../components/templates/editor";

interface TabOption {
  value: string;
  label: string;
  enable: boolean;
  icon?: ReactNode;
  showIndicator: boolean;
}

const ListingEditor = ({
  item,
  config,
  listing: listingProp,
  onSubmit,
  addNotification
}: {
  item: Item;
  config: Config;
  listing: Listing;
  onSubmit: () => void;
  addNotification: AddNotification;
}) => {
  const { t } = useTranslation();
  const itemType = item.type;
  const isRelease = itemType === "ReleaseItem";

  const tabOptions: TabOption[] = [
    { value: "information", showIndicator: false, label: t("Information"), enable: true },
    { value: "comments", showIndicator: false, label: "Comments", enable: true },
    { value: "pos", showIndicator: true, label: "POS", enable: true },
    { value: "eshop", showIndicator: true, label: "Eshop", enable: true },
    {
      value: "discogs",
      label: "Discogs",
      showIndicator: true,
      icon: (
        <img
          alt="Discogs logo"
          src="https://static.common-ground.io/common/media/dicsogs.png"
          style={{ width: "15px", marginRight: "5px" }}
        />
      ),
      enable: config.discogs.enabled && isRelease && !!item.data.discogsId
    },
    { value: "meta", showIndicator: true, label: "Meta", enable: true },
    {
      value: "bandcamp",
      label: "Bandcamp",
      showIndicator: true,
      icon: (
        <img
          alt="Discogs logo"
          src="https://static.common-ground.io/common/media/bandcamp.png"
          style={{ width: "15px", marginRight: "5px" }}
        />
      ),
      enable: !!config.bandcamp?.refreshToken && isRelease
    }
  ];

  const [updateListing, { loading }] = useMutation(POST_ITEM_LISTING_UPDATE);
  const [dirty, setDirty] = useState(false);
  const [listing, setListing] = useState(clone(listingProp));
  const [activeTab, setActiveTab] = useState<TabOption>(tabOptions[0]);
  const [deleteListing] = useMutation(POST_ITEM_LISTING_DELETE);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const listingData = listing;
    if (listingData?.stock?.quantity === null || listingData?.stock?.quantity === undefined || listingData?.stock?.quantity === "")
      return addNotification({ message: "Stock quantity cannot be null", ok: 0 });

    setDirty(false);

    const isNew = !listing._id;
    const listingDataForGraphql = {
      stock: { quantity: parseInt(listingData?.stock?.quantity) },
      status: listing.status,
      available: listing.available ? moment(listing.available).format() : moment().format(),
      posted: listing.posted ? moment(listing.posted).format() : moment().format(),
      secondHand: listing.secondHand,
      location: listing.location,
      supplierCode: listing.supplierCode,
      categories: listing.categories,
      comments: listing.comments,
      taxDefinition: listing.taxDefinition,
      options: listing.options.map(o => ({ name: o.name, value: o.value })),
      prices: {
        sale: listing?.prices?.sale || undefined,
        cost: listing?.prices?.cost || undefined,
        beforeTaxes: listing?.prices?.beforeTaxes || undefined,
        compare: listing?.prices?.compare || undefined
      },
      barcode: listing.barcode,
      sku: listing.sku,
      privateComments: listing.privateComments,
      onePerCustomer: listing.onePerCustomer,
      preventDiscogsListing: listing.preventDiscogsListing,
      preOrder: listing.preOrder
    };

    try {
      if (isNew && item._id) {
        // await addListing({ variables: { itemRef: item._id, itemListingInput: listingDataForGraphql } });
        // if (onSubmit) onSubmit();
        // addNotification({ ok: 1, message: "Listing added" });
        // amplitude.track("Listing added", { itemId: item.id });
      } else {
        await updateListing({ variables: { listingRef: listing._id, itemListingInput: listingDataForGraphql } });
        // amplitude.track("Listing updated", { itemId: item.id });
        addNotification({ ok: 1, message: "Listing updated" });
        onSubmit();
      }
    } catch (error: any) {
      addNotification({ ok: 0, message: error.message });
    }

    // setEdit(false);
    // setIsUpdating(false);
  };

  const handlePriceChange = (event: any) => {
    setDirty(true);
    // @ts-ignore
    if (listing.prices) listing.prices[event.target.name] = parseFloat(event.target.value);
    localStorage.setItem(`${config.id}-add-listing-${event.target.name}`, event.target.value);
    setListing({ ...listing });
  };

  const handleAvailableChange = (e: any) => {
    setDirty(true);
    if (e === null) listing.available = null;
    else if (!moment(e).isValid()) return;
    else listing.available = moment(e).format();
    setListing({ ...listing });
  };

  const handlePreOrderChange = () => {
    setDirty(true);
    listing.preOrder = !listing.preOrder;
    setListing({ ...listing });
  };

  const handlePublishedChange = (e: any) => {
    setDirty(true);
    if (!moment(e).isValid()) return;
    listing.posted = moment(e).format();
    setListing({ ...listing });
  };

  const handleCategoryCreate = (value: string) => {
    setDirty(true);
    listing.categories.push(value);
    setListing({ ...listing });
  };

  const handleListingDelete = async () => {
    try {
      if (
        !window.confirm(
          t("Are you sure you wish to delete this listing? The sales history for this listing will be lost and cannot be recovered.")
        )
      )
        return;
      await deleteListing({ variables: { listingRef: listing._id } });
      addNotification({ ok: 1, message: "Listing deleted" });
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
  };

  const handleCategoriesSelect = (options: any, event: any) => {
    setDirty(true);
    if (event.action === "clear") {
      listing.categories = [];
      localStorage.removeItem(`${config.id}-add-listing-categories`);
    } else if (event.action === "remove-value") {
      localStorage.removeItem(`${config.id}-add-listing-categories`);
      listing.categories = listing.categories.filter(c => c !== event.removedValue.value);
    } else if (event.action === "select-option") {
      const mappedOptions = options.map((i: any) => i.value);
      localStorage.setItem(`${config.id}-add-listing-categories`, JSON.stringify(mappedOptions));
      // @ts-ignore
      listing[event.name] = mappedOptions;
    }
    setListing({ ...listing });
  };

  const handleOptionOnChange = (option: any, event: any, index: number) => {
    setDirty(true);
    const storageStr = `${config.id}-add-listing-options-${item.type}-${index}-${event.name}`;
    if (event.action === "clear") {
      localStorage.removeItem(storageStr);
    } else if (event.action === "select-option") {
      // @ts-ignore
      listing.options[index][event.name] = option.value;
      localStorage.setItem(storageStr, option.value);
    }
    setListing({ ...listing });
  };

  const handleOptionCreate = (value: any, name: string, index: number) => {
    setDirty(true);
    // @ts-ignore
    listing.options[index][name] = value;
    setListing({ ...listing });
  };

  const handleAddAttribute = () => {
    setDirty(true);
    listing.options.push({ name: "", value: "" });
    setListing({ ...listing });
  };

  const handleDeleteOption = (index: number) => {
    setDirty(true);
    listing.options.splice(index, 1);
    setListing({ ...listing });
  };

  const handleOnePerCustomerChange = () => {
    setDirty(true);
    listing.onePerCustomer = !listing.onePerCustomer;
    setListing({ ...listing });
  };
  const handleStockChange = (event: any) => {
    setDirty(true);
    if (listing.stock) listing.stock.quantity = event.target?.value;
    setListing({ ...listing });
  };
  const handleCreatableSelect = (option: any, event: any) => {
    setDirty(true);
    if (event.action === "clear") {
      // @ts-ignore
      listing[event.name] = null;
      localStorage.removeItem(`${config.id}-add-listing-${event.name}`);
    } else if (event.action === "select-option") {
      localStorage.setItem(`${config.id}-add-listing-${event.name}`, option.value);
      // @ts-ignore
      listing[event.name] = option.value;
    }
    setListing({ ...listing });
  };
  const handleLocationCreate = (value: string) => {
    setDirty(true);
    listing.location = value;
    setListing({ ...listing });
  };
  const handleSupplierCreate = (value: string) => {
    setDirty(true);
    listing.supplierCode = value;
    setListing({ ...listing });
  };

  const handleSkuOrBarcodeChange = (e: any) => {
    setDirty(true);
    // @ts-ignore
    listing[e.target.name] = e.target.value;
    setListing({ ...listing });
  };

  return (
    <ListingEditContainer>
      <form onSubmit={handleSubmit} id="listingEdit">
        <div>
          <ListingEditHeader>
            <ModalHeaderContainer>
              <Typography variant="pageTitle">{t("Update listing")}</Typography>
              <button className="reset" type="button" onClick={() => onSubmit()}>
                <i className="cg-icon-burger-close" />
              </button>
            </ModalHeaderContainer>
            <Tabs>
              {tabOptions.map(o => (
                <>
                  <Tab
                    enable={o.enable}
                    onClick={() => (o.enable ? setActiveTab(o) : null)}
                    active={activeTab.value === o.value}
                    key={o.value}>
                    <div>{o.showIndicator ? <MentionWithIndicator state={o.enable ? "active" : "danger"} text={o.label} /> : o.label}</div>
                  </Tab>
                  {/* {o.value === "comments" ? <div style={{ width: "30px", height: "100%", backgroundColor: "red" }} /> : null} */}
                </>
              ))}
            </Tabs>
          </ListingEditHeader>

          <ListingEditContent>
            {activeTab.value === "information" ? (
              <ListingEditContentContainer>
                <div>
                  <ListingEditContent>
                    <ListingEditContentTwoColumns>
                      <LazyLoadImage
                        alt={item?.descriptions?.main}
                        src={(item?.data?.images?.length ? item?.data?.images[0]?.uri : null) || getPlaceholderImage(config)}
                      />
                      <div>
                        <ListingEditContent>
                          <Input
                            label={t("Stock quantity")}
                            variant={"overZone"}
                            required={true}
                            value={listing?.stock?.quantity}
                            type="number"
                            onWheel={(e: any) => e.target.blur()}
                            placeholder="Available quantity"
                            onChange={handleStockChange}
                          />
                          <Checkbox
                            checked={listing.secondHand || false}
                            onClick={() => console.log("sh")}
                            label={t("Second hand listing")}
                          />
                          <Checkbox label={t("Set as pre order")} onClick={handlePreOrderChange} checked={listing.preOrder || false} />
                          <Checkbox
                            label={t("One per customer")}
                            onClick={handleOnePerCustomerChange}
                            checked={listing.onePerCustomer || false}
                          />
                        </ListingEditContent>
                      </div>
                    </ListingEditContentTwoColumns>

                    <Options
                      onChange={handleOptionOnChange}
                      onCreate={handleOptionCreate}
                      onAdd={handleAddAttribute}
                      options={listing.options}
                      item={item}
                      deleteOption={handleDeleteOption}
                    />
                  </ListingEditContent>
                  <ListingEditContent></ListingEditContent>
                </div>
                <div>
                  <ListingEditContentSection>
                    <ListingEditContentTwoColumns>
                      <PriceInput
                        label={t("Price incl. sales tax")}
                        variant="primary"
                        value={listing.prices?.sale as number}
                        name="sale"
                        type="number"
                        step="0.01"
                        fullWidth
                        min="0.00"
                        placeholder={t("Enter a value") + "..."}
                        required={true}
                        onChange={handlePriceChange}
                      />
                      <PriceInput
                        label={t("Item cost / Supplier price")}
                        variant="primary"
                        value={listing.prices?.cost as number}
                        name="cost"
                        step="0.01"
                        fullWidth
                        min="0.00"
                        type="number"
                        onChange={handlePriceChange}
                      />
                    </ListingEditContentTwoColumns>
                    <ListingEditContentTwoColumns>
                      <DatePicker
                        label={t("Available from")}
                        dateFormat="dd/MM/yyyy"
                        variant="primary"
                        value={listing.available}
                        onChange={handleAvailableChange}
                        className="available datePicker"
                      />
                      <DatePicker
                        label={t("Published date")}
                        variant={"overZone"}
                        value={listing.posted || moment()}
                        dateFormat="dd/MM/yyyy"
                        onChange={handlePublishedChange}
                        className="available datePicker"
                      />
                    </ListingEditContentTwoColumns>

                    {/* <PriceInput
                    label={t("Price before sales tax")}
                    variant="primary"
                    value={listing.prices?.beforeTaxes as number}
                    name="beforeTaxes"
                    type="number"
                    step="0.01"
                    fullWidth
                    min="0.00"
                    placeholder={t("Enter a value") + "..."}
                    required={true}
                    onChange={handlePriceChange}
                  /> */}

                    <SelectCategories
                      label={t("Categories")}
                      variant="primary"
                      isMulti
                      creatable={true}
                      onCreateOption={handleCategoryCreate}
                      onChange={handleCategoriesSelect}
                      value={listing.categories?.map(c => ({ label: c.substring(0, 32), value: c.substring(0, 32) }))}
                      name="categories"
                    />

                    <ListingEditContentTwoColumns>
                      <SelectSupplier
                        variant={"overZone"}
                        label={t("Supplier")}
                        onChange={handleCreatableSelect}
                        onCreateOption={handleSupplierCreate}
                        value={listing.supplierCode ? { label: listing.supplierCode, value: listing.supplierCode } : null}
                        name="supplierCode"
                      />
                      <SelectLocation
                        label={t("Location")}
                        variant={"overZone"}
                        onCreateOption={handleLocationCreate}
                        onChange={handleCreatableSelect}
                        value={listing.location ? { label: listing.location || "", value: listing.location || "" } : null}
                        name="location"
                      />
                    </ListingEditContentTwoColumns>

                    <ListingEditContentTwoColumns>
                      <Input
                        label={t("Barcode identifier")}
                        variant={"overZone"}
                        required={false}
                        value={listing.barcode || ""}
                        type="text"
                        name="barcode"
                        autoComplete="off"
                        placeholder={t("Barcode identifier") + "..."}
                        onChange={handleSkuOrBarcodeChange}
                      />
                      <Input
                        label={t("SKU")}
                        variant={"overZone"}
                        required={false}
                        value={listing.sku || ""}
                        type="text"
                        autoComplete="off"
                        name="sku"
                        placeholder={t("SKU") + "..."}
                        onChange={handleSkuOrBarcodeChange}
                      />
                    </ListingEditContentTwoColumns>
                  </ListingEditContentSection>
                </div>
              </ListingEditContentContainer>
            ) : activeTab.value === "comments" ? (
              <Comments config={config} setListing={setListing} listing={listing} setDirty={setDirty} />
            ) : activeTab.value === "discogs" ? (
              <DiscogsOptions item={item} setListing={setListing} listing={listing} setDirty={setDirty} />
            ) : activeTab.value === "pos" ? (
              <PosOptions config={config} setListing={setListing} listing={listing} setDirty={setDirty} />
            ) : activeTab.value === "bandcamp" ? (
              <BandcampOptions />
            ) : null}
          </ListingEditContent>
        </div>
      </form>
      <ListingEditFooter>
        <div>
          <Button type="button" disabled={loading} variant="warning" onClick={() => handleListingDelete()}>
            {t("Delete listing")}
          </Button>
        </div>
        <div>
          <Button variant="primary" disabled={!dirty || loading} type="submit" form="listingEdit">
            {loading ? <Loader /> : t("Submit")}
          </Button>
        </div>
      </ListingEditFooter>
    </ListingEditContainer>
  );
};

const Comments = ({ config, setListing, listing, setDirty }: { config: Config; setListing: any; listing: Listing; setDirty: any }) => {
  const { t } = useTranslation();

  const handleTemplateSelect = (template: Template) => {
    setDirty(true);
    listing.comments = template.data.content;
    localStorage.setItem(`${config.id}-add-listing-comments`, template.data.content || "");
    setListing({ ...listing });
  };

  const handleCommentsUpdate = (name: string, value: any) => {
    setDirty(true);
    // @ts-ignore
    listing[name] = value;
    localStorage.setItem(`${config.id}-add-listing-${name}`, value);
    setListing({ ...listing });
  };

  return (
    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "var(--gutter)" }}>
      <ListingEditContent>
        <SelectTemplate
          label={t("Public comments")}
          placeholder={t("Select from template")}
          type="comment"
          onChange={handleTemplateSelect}
        />
        <TextArea rows="5" onChange={(e: any) => handleCommentsUpdate("comments", e.target.value)} value={listing.comments || ""} />
        {/* <TemplateEditor type="comment" /> */}
      </ListingEditContent>
      <ListingEditContent>
        <TextArea
          label={t("Private comments")}
          rows="4"
          onChange={(e: any) => handleCommentsUpdate("privateComments", e.target.value)}
          value={listing.privateComments || ""}
        />
      </ListingEditContent>
    </div>
  );
};

const DiscogsOptions = ({ item, setListing, listing, setDirty }: { item: Item; setListing: any; listing: Listing; setDirty: any }) => {
  const { t } = useTranslation();
  const [listOrUnlistToDiscogs, { loading }] = useMutation(POST_ITEM_LISTING_DISCOGS);
  const couldBeListedToDiscogs = item.type === "ReleaseItem" && item?.data?.discogsId && item.data.discogsId > 0;

  const handleDiscogsList = async () => {
    try {
      await listOrUnlistToDiscogs({ variables: { listingRef: listing._id, list: true } });
      // addNotification({ message: "Listed to Discogs", ok: 1 });
    } catch (e: any) {
      // addNotification({ message: e.message, ok: 0 });
    }
  };

  const handleDiscogsUnlist = async () => {
    try {
      await listOrUnlistToDiscogs({ variables: { listingRef: listing._id, unlist: true } });
      // addNotification({ message: "Unlisted from Discogs", ok: 1 });
    } catch (e: any) {
      // addNotification({ message: e.message, ok: 0 });
    }
  };

  const handleAutolistChange = () => {
    setDirty(true);
    listing.preventDiscogsListing = !listing.preventDiscogsListing;
    setListing({ ...listing });
  };

  return (
    <div>
      <ListingEditContent>
        {couldBeListedToDiscogs ? (
          <Checkbox
            label={t("Disable Discogs auto-list")}
            onClick={handleAutolistChange}
            checked={listing.preventDiscogsListing || false}
          />
        ) : null}
        {listing.discogsId ? (
          <div>
            <Button variant="warning" onClick={handleDiscogsUnlist} disabled={listing.readOnly || loading}>
              {loading ? <Loader /> : t("Unlist")}
            </Button>
            <p>
              <a href={`https://discogs.com/buy/Vinyl/listing/${listing.discogsId}`} rel="noopener noreferrer" target="_blank">
                {t("View on Discogs")} #{listing.discogsId}
              </a>
            </p>
          </div>
        ) : null}
        {listing?.stock?.quantity > 0 && !listing.discogsId ? (
          <Button variant="primary" onClick={handleDiscogsList}>
            {loading ? <Loader /> : t("List to Discogs")}
          </Button>
        ) : null}
      </ListingEditContent>
    </div>
  );
};

const PosOptions = ({ config, setListing, listing, setDirty }: { config: Config; setListing: any; listing: Listing; setDirty: any }) => {
  const { t } = useTranslation();

  const handleOnePerCustomerChange = () => {
    setDirty(true);
    listing.onePerCustomer = !listing.onePerCustomer;
    setListing({ ...listing });
  };

  // const handleEnableChange = () => {
  //   setDirty(true);
  //   listing.channels.pos.enable = !listing.channels.pos.enable;
  //   setListing({ ...listing });
  // };

  const handlePriceChange = (event: any) => {
    setDirty(true);
    // @ts-ignore
    if (listing.prices) listing.prices[event.target.name] = parseFloat(event.target.value);
    localStorage.setItem(`${config.id}-add-listing-${event.target.name}`, event.target.value);
    setListing({ ...listing });
  };

  return (
    <div>
      <ListingEditContent>
        {/* <Checkbox label={"Enabled"} onClick={handleEnableChange} checked={listing.channels.pos.enable} /> */}
        <PriceInput
          label={t("Price")}
          variant="primary"
          value={listing.prices?.sale as number}
          name="sale"
          type="number"
          step="0.01"
          fullWidth
          min="0.00"
          placeholder={t("Enter a value") + "..."}
          required={true}
          onChange={handlePriceChange}
        />
        <Checkbox label={t("One per customer")} onClick={handleOnePerCustomerChange} checked={listing.onePerCustomer || false} />
      </ListingEditContent>
    </div>
  );
};

const BandcampOptions = () => {
  return <Button variant="primary">{"Link to bandcamp item"}</Button>;
};

export { ListingEditor };
