import React, { useCallback, useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Modal from "../components/modal";
import Button from "../components/styled/button";
import Text from "../components/styled/text";
import { communityClient, clientClient } from "../contexts/apolloClients";
import { GET_CLIENT_ITEM, GET_POST, GET_POSTS, POST_SAVE_POST } from "../graphql/queries/post";
import moment from "moment";
import { GlobalStore } from "../stores/global";
import Loader from "../components/common/loader";
import clone from "clone";
import Languages from "@common-ground-io/common-assets/assets/languages.json";
import { useAudioPlayer } from "react-use-audio-player";
import { Item as IItem, ReleaseTrack, Session } from "../__generated__/graphql";
import { AddNotification } from "../types/globals";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

interface PostItem {
  _id: string;
  artwork: string;
  uri: string;
  isPreorder: boolean;
  hasAudio: boolean;
}

interface Config {
  _id: string;
  name: string;
  thumbnail: string;
  uri: string;
}

interface Post {
  _id: string;
  id: string;
  type: string;
  from: string;
  title: string;
  content: string;
  timestamp: string;
  item: PostItem;
  isFavorite: boolean;
  config: Config;
}

interface PlayerState {
  isReady: boolean;
  currentTrack: string | null;
  player: any;
  isPlaying: boolean;
  post: Post | null;
}

interface Interval {
  _id: string;
  created: Date;
  config: Config;
  posts: Post[];
}

const Community = ({ close }: { close: () => void }) => {
  const [socket] = useState(io(process.env.REACT_APP_FEED_ENDPOINT as string));
  const { session, addNotification } = GlobalStore.useState(c => c);
  const [postType, setPostType] = useState("intervals");
  const [post, setPost] = useState<any>();
  const [playerState, setPlayerState] = useState<PlayerState>({
    isReady: false,
    currentTrack: null,
    player: null,
    isPlaying: false,
    post: null
  });
  const [getItem] = useLazyQuery(GET_CLIENT_ITEM, { fetchPolicy: "cache-and-network", client: clientClient });
  const { load, stop } = useAudioPlayer();

  const { t } = useTranslation();

  const { data, refetch } = useQuery(GET_POSTS, {
    client: communityClient,
    fetchPolicy: "cache-and-network",
    variables: { userRef: session?.user._id, favorites: postType === "favorites" }
  });

  const loadTrack = useCallback(
    (track: string, post: Post) => {
      if (playerState.isPlaying && track === playerState.currentTrack) {
        setPlayerState({
          ...playerState,
          post: null,
          isPlaying: false,
          currentTrack: null
        });
        stop();
      } else {
        load(track, {
          autoplay: true,
          onplay: () =>
            setPlayerState({
              ...playerState,
              post,
              isReady: true,
              currentTrack: track,
              isPlaying: true
            }),
          onend: () =>
            setPlayerState({
              ...playerState,
              post,
              isReady: true,
              currentTrack: null,
              isPlaying: false
            })
        });
      }
    },
    [playerState]
  );

  const stopPlayer = () => {
    setPlayerState({
      ...playerState,
      post: null,
      isPlaying: false,
      currentTrack: null
    });
    stop();
  };

  useEffect((): (() => any) => {
    socket.connect();
    socket.on("post", () => refetch());
    return () => socket.disconnect();
  }, []);

  const loadTrackForPost = async (post: Post) => {
    getItem({ variables: { itemRef: post.item._id }, context: { headers: { "commonground-origin": post.config._id } } })
      .then(({ data }) => {
        loadTrack(data.item.data.tracklist.find((t: any) => t.uri)?.uri, clone(post));
      })
      .catch(e => addNotification({ ok: 0, message: e.message }));
  };

  const intervals = data?.getIntervals;
  if (!intervals) return null;

  return (
    <>
      {post ? (
        <Item
          back={() => setPost(null)}
          close={close}
          post={post}
          addNotification={addNotification}
          session={session as Session}
          playerState={playerState}
          loadTrack={loadTrack}
        />
      ) : (
        <div id="community" className="root">
          <div className="header">
            <h2>{t("News feed")}</h2>
            <div className="right">
              <Button variant="noStyle" className={postType === "intervals" ? "active" : ""} onClick={() => setPostType("intervals")}>
                {t("All")}
              </Button>
              <Button variant="noStyle" className={postType === "favorites" ? "active" : ""} onClick={() => setPostType("favorites")}>
                {t("Favorites")}
              </Button>
              <Button variant="noStyle" className="close" onClick={() => close()}>
                <i className="cg-icon-burger-close" />
              </Button>
            </div>
          </div>
          <div className="content">
            {intervals.map((interval: Interval) => (
              <div key={interval._id} className="entry">
                <div className="configDescription">
                  <img src={interval.config.thumbnail} />
                  <h3>
                    <a target="_blank" href={interval.config.uri} rel="noreferrer">
                      {interval.config.name}
                    </a>{" "}
                    <Text variant="secondary">{moment(interval.created).fromNow()}</Text>
                  </h3>
                </div>
                <hr />
                <div className="posts">
                  {interval.posts.map(p => (
                    <Post
                      key={p._id}
                      post={p}
                      addNotification={addNotification}
                      session={session as Session}
                      setPost={setPost}
                      loadTrackForPost={loadTrackForPost}
                      stopPlayer={stopPlayer}
                      playerState={playerState}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

const Post = ({
  post,
  addNotification,
  session,
  setPost,
  loadTrackForPost,
  playerState,
  stopPlayer
}: {
  post: Post;
  addNotification: AddNotification;
  session: Session;
  setPost: any;
  loadTrackForPost: any;
  playerState: PlayerState;
  stopPlayer: any;
}) => {
  const { isPlaying, post: postFromPlayerState } = playerState;
  const [isHover, setIsHover] = useState(false);
  const [savePost] = useMutation(POST_SAVE_POST, { client: communityClient });

  const buttonIsPlaying = isPlaying && post._id === postFromPlayerState?._id;

  const handleAddToFavorite = async (ref: any) => {
    savePost({ variables: { postRef: ref, userRef: session.user._id } }).catch(e => addNotification({ ok: 0, message: e.message }));
  };

  return (
    <div className={`post ${isHover ? "hover" : ""}`} onMouseOver={() => setIsHover(true)} onMouseOut={() => setIsHover(false)}>
      <div className="item" onClick={() => setPost(clone(post))}>
        <img src={post.item.artwork} />
      </div>
      <div className="postHover">
        <div className="left">
          {isHover || post.isFavorite ? (
            <Button onClick={() => handleAddToFavorite(post._id)} variant="noStyle" className={`${post.isFavorite ? "active" : ""}`}>
              <i className="cg-icon-feed-fav" />
            </Button>
          ) : null}
          {isHover && post.item.hasAudio ? (
            <Button variant="noStyle" className="audio" onClick={() => (buttonIsPlaying ? stopPlayer() : loadTrackForPost(post))}>
              {buttonIsPlaying ? <i className="cg-icon-feed-player-stop" /> : <i className="cg-icon-feed-player" />}
            </Button>
          ) : null}
        </div>
        <div className="right">
          {post.item.isPreorder ? (
            <Button variant="noStyle" className="preorder">
              <i className="cg-icon-feed-clock" />
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const CommunityButton = () => {
  const [isActive, setIsActive] = useState(false);
  const modalRef = useRef<any>();
  const { t } = useTranslation();

  useEffect(() => {
    if (isActive) modalRef.current?.open();
    else modalRef.current?.close();
  }, [isActive]);

  return (
    <div id="communityButton">
      <Modal ref={modalRef} style={{ width: "70vw", height: "70vh" }} onClose={() => setIsActive(false)}>
        <div id="communityModal">{isActive ? <Community close={() => setIsActive(false)} /> : null}</div>
      </Modal>
      <button className="reset" onClick={() => setIsActive(!isActive)}>
        {t("Feed")}
      </button>
    </div>
  );
};

const Item = ({
  session,
  addNotification,
  close,
  post: postFromProps,
  back,
  playerState,
  loadTrack
}: {
  session: Session;
  addNotification: AddNotification;
  close: any;
  post: Post;
  back: any;
  playerState: PlayerState;
  loadTrack: any;
}) => {
  const [getItem, { data: itemData }] = useLazyQuery(GET_CLIENT_ITEM, { fetchPolicy: "cache-and-network", client: clientClient });
  const [savePost] = useMutation(POST_SAVE_POST, { client: communityClient });
  const { data } = useQuery(GET_POST, {
    client: communityClient,
    fetchPolicy: "cache-and-network",
    variables: { userRef: session.user._id, postRef: postFromProps._id }
  });
  const post = data?.getPost;
  const item = itemData?.item;

  const { t } = useTranslation();

  const handleAddToFavorite = async (ref: any) => {
    savePost({ variables: { postRef: ref, userRef: session.user._id } }).catch(e => addNotification({ ok: 0, message: e.message }));
  };

  useEffect(() => {
    if (post) getItem({ variables: { itemRef: post.item._id }, context: { headers: { "commonground-origin": post.config._id } } });
  }, [post]);

  const handleLoadTrack = (uri: string) => {
    loadTrack(uri, post);
  };

  if (!item || !post) return <Loader />;

  return (
    <div className="feedItem">
      <div className="header">
        <div className="left">
          <Button variant="noStyle" className="" onClick={() => back()}>
            <i className="cg-icon-arrow-back" />
          </Button>
          <div className="configDescription">
            <img src={post.config.thumbnail} />
            <a target="_blank" href={post.config.uri} rel="noreferrer">
              <h3>{post.config.name}</h3>
            </a>
          </div>
        </div>
        <div className="right">
          {post.item.isPreorder ? (
            <Button variant="noStyle" className="preorder">
              <i className="cg-icon-feed-clock" />
            </Button>
          ) : null}
          <Button onClick={() => handleAddToFavorite(post._id)} variant="noStyle" className={`${post.isFavorite ? "active" : ""}`}>
            <i className="cg-icon-feed-fav" />
          </Button>
          <a target="_blank" href={item.uri} rel="noreferrer">
            <Button variant="secondary">{t("Open in browser")}</Button>
          </a>
          <Button variant="noStyle" className="" onClick={() => close()}>
            <i className="cg-icon-burger-close" />
          </Button>
        </div>
      </div>
      <div className="contentAndDescription">
        <div className="content">
          <div className="left">
            <img src={item.data.images[0].uri} />
          </div>
          <div className="right">
            <Title item={item} />
            <Specs item={item} t={t} />
            {item.type === "ReleaseItem" ? <Tracklist item={item} playerState={playerState} loadTrack={handleLoadTrack} /> : null}
          </div>
        </div>
        {item.descriptions.shop?.html ? (
          <div className="description" dangerouslySetInnerHTML={{ __html: item.descriptions.shop.html }} />
        ) : null}
      </div>
    </div>
  );
};

const Specs = ({ item, t }: { item: IItem; t: TFunction }) => {
  const LabelNames = () => {
    return item.data.labels?.map((l, index) => (
      <span key={index}>
        {l.name} ({l.catno}) <br />
      </span>
    ));
  };

  if (item.type === "ReleaseItem")
    return (
      <div className="specs">
        <div>
          <LabelNames />
        </div>
        <div className="formats">
          {item.data.formats?.map((f, i) => (
            <span key={i}>
              <span>
                {/* eslint-disable-next-line i18next/no-literal-string */}
                {f.qty}x {f.name}
              </span>
              {f.descriptions
                .filter(d => !!d)
                .map(d => (
                  <span key={d}> {d}</span>
                ))}
              <span>{f.descriptions.join(", ")}</span>
            </span>
          ))}
        </div>
        <div className="styles">
          {item.data.genres?.join(" ")} {item.data.styles?.join(" ")}
        </div>
        {item.data.releaseDate ? (
          <p>
            {moment(item.data.releaseDate).format("ll")}, {item.data.country ? item.data.country : null}
          </p>
        ) : null}
      </div>
    );
  else if (item.type === "ProductItem")
    return (
      <div className="specs">
        <p>
          {item.data.manufacturer} {item.data.cat ? `(${item.data.cat})` : ""}
        </p>
        <p>{item.data.type}</p>
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <p>{item.data.weight}g</p>
        {item.data.identifiers
          ? Object.keys(item.data.identifiers).map(key => (
              <p key={key}>
                {/* @ts-ignore */}
                {item.data.identifiers[key].type}: <span>{item.data.identifiers[key].value}</span>
              </p>
            ))
          : null}
      </div>
    );
  else if (item.type === "BookItem")
    return (
      <div className="specs">
        <p>{item.data.publisher}</p>
        {item.data.pageCount ? (
          <p>
            {item.data.pageCount} {t("pages")}
          </p>
        ) : null}
        <p>{item.data.categories?.join(", ")}</p>
        <p>{Languages.find(c => c.code === item.data.language)?.name}</p>
        {item.data.publishedDate ? <p>{moment(item.data.publishedDate).format("DD")}</p> : null}
        {Object.keys(item.data.identifiers || []).map(key => (
          <p key={key}>
            {/* @ts-ignore */}
            {item.data.identifiers[key].type}: <span>{item.data.identifiers[key].value}</span>
          </p>
        ))}
      </div>
    );
  else return null;
};

const Title = ({ item }: { item: IItem }) => {
  if (item.type === "ReleaseItem")
    return (
      <div>
        <p className="artistsAndTitle">
          {item.data.artists?.map((a, index) => (
            <span key={index}>
              {a.anv || a.name}
              {/* @ts-ignore */}
              {index < item.data.artists.length - 1 ? ", " : ""}
            </span>
          ))}
          <br />
          <span className="title">{item.data.title}</span>
        </p>
      </div>
    );
  else if (item.type === "ProductItem")
    return (
      <div className="title">
        <p>{item.data.manufacturer}</p>
        <p>{item.data.title}</p>
      </div>
    );
  else if (item.type === "BookItem")
    return (
      <div className="title">
        <p>
          {item.data.title} {item.data.subtitle ? <span> - {item.data.subtitle}</span> : null}
        </p>
        <p>{item.data.authors ? item.data.authors.join(", ") : ""}</p>
      </div>
    );
  else return null;
};

const Tracklist = ({ item, playerState, loadTrack }: { item: IItem; playerState: PlayerState; loadTrack: any }) => {
  return (
    <div className="tracklist">
      {item.data.tracklist
        ?.filter(t => t.type_ === "track")
        .map((t, index) => (
          <PlayerButtonDetailed key={`${t.position}-${index}`} track={t} playerState={playerState} loadTrack={loadTrack} />
        ))}
    </div>
  );
};

export const PlayerButtonDetailed = ({
  track,
  playerState,
  loadTrack
}: {
  track: ReleaseTrack;
  playerState: PlayerState;
  loadTrack: any;
}) => {
  const { currentTrack, isPlaying } = playerState;
  const buttonIsPlaying = isPlaying && currentTrack === track.uri;

  const hasSnippet = !!track.uri;

  const handleClick = () => {
    loadTrack(track.uri);
  };

  const PlayButton = () => (
    <Button variant="noStyle" disabled={!track.uri} onClick={handleClick} className={`player reset ${hasSnippet ? "playable" : ""}`}>
      {buttonIsPlaying ? <i className="cg-icon-feed-player-stop" /> : <i className="cg-icon-feed-player" />}
    </Button>
  );

  return (
    <div key={track.position} onClick={handleClick} className={`track ${buttonIsPlaying ? "playing " : ""}${track.uri ? "playable " : ""}`}>
      <div className="left">
        <PlayButton />
        <span className="position">
          <span>{track.position}</span>
        </span>
        <span className="description">
          <span>
            {track.artists?.length ? (track.artists[0].anv || track.artists[0].name) + " - " : null}
            {track.title}
          </span>
        </span>
      </div>
      <span className="duration">
        <span>{track.duration}</span>
      </span>
    </div>
  );
};

export default CommunityButton;
