import React, { useState, useEffect, useCallback } from "react";
import Button from "../../components/styled/button";
import { useLocation, useHistory } from "react-router-dom";
import URI from "urijs";
import Liner from "../../components/common/liner";

import { GlobalStore } from "../../stores/global";
import Loader from "../../components/common/loader";
import csvtojson from "csvtojson";
// import { useHelpPanel } from "../../components/helpPanel";
import { useMutation } from "@apollo/client";
import { POST_ITEM_IMPORT_PREVIEW, POST_ITEM_IMPORT_PROCESS } from "../../graphql/queries/item";
import clone from "clone";
import { useDropzone } from "react-dropzone";
import { Config, ItemUploadPreviewReport, ItemUploadPreviewReportRow } from "../../__generated__/graphql";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { Typography } from "../../componentsV2/Typography";

interface ConfigToLoad {
  id: string;
  title: string;
  link: string;
}

const Uploader = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const segment = location.pathname.split("/")[2];

  const rootUrl = "https://docs.google.com/spreadsheets/d/1s5jHYfle9O6EnB5AtyknM9k7eDnbmHfIJOm0f1tLubI/edit?pli=1#gid=";
  const configs: ConfigToLoad[] = [
    {
      id: "releases-with-discogs",
      title: t("Add releases with Discogs"),
      link: rootUrl + "1203542982"
    },
    {
      id: "releases-manually",
      title: t("Add releases manually"),
      link: rootUrl + "0"
    },
    {
      id: "listings-update",
      title: t("Update listings"),
      link: rootUrl + "1996685308"
    },
    {
      id: "listings-add",
      title: t("Add listings"),
      link: rootUrl + "1470015701"
    },
    {
      id: "listings-delete",
      title: t("Delete listings"),
      link: rootUrl + "718197711"
    },
    {
      id: "customers-add",
      title: t("Add customers"),
      link: rootUrl + "1209159221"
    }
  ];

  const configLoaded = configs.find(c => c.id === segment) as ConfigToLoad;

  // const { load } = useHelpPanel({ id: "7328994", show: false });
  const { addNotification, config } = GlobalStore.useState(c => c) as { addNotification: any; config: Config };
  const currentUri = new URI(location.pathname + location.search);
  const [uploadPreview] = useMutation(POST_ITEM_IMPORT_PREVIEW, {
    onError: error => addNotification({ ok: 1, message: error.message })
  });
  const [processImport] = useMutation(POST_ITEM_IMPORT_PROCESS, {
    onError: error => addNotification({ ok: 1, message: error.message })
  });

  const isDiscogsConnected = config.discogs.enabled;

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fileReader] = useState(new FileReader());
  const invalidationMessage: string =
    configLoaded.id === "releases-with-discogs" && !isDiscogsConnected
      ? t("Please connect your Discogs account in order to use this uploader")
      : "";
  const [rows, setRows] = useState<ItemUploadPreviewReportRow[] | null>();
  const [report, setReport] = useState<ItemUploadPreviewReport | null>();

  const pagination = { limit: 50 };

  const generateReport = async () => {
    if (rows) {
      const clonedRows = clone(rows);
      const paginatedRows = clonedRows.splice(0, pagination.limit);
      const { data } = await uploadPreview({ variables: { id: configLoaded?.id, rows: paginatedRows } });
      if (data?.importPreview) setReport(data.importPreview);
    }
  };

  useEffect(() => {
    generateReport();
  }, [rows]);

  const handleSubmitUpload = async () => {
    setIsSubmitting(true);
    if (!selectedFile) return;
    await processImport({ variables: { file: selectedFile, id: configLoaded.id } });
    history.push(currentUri.path("/jobs").toString());
  };

  const isValid = () => {
    if (!rows?.length || !report || !selectedFile) return false;
    return true;
  };

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (acceptedFiles.length) {
      try {
        fileReader.onloadend = async () => {
          const content = fileReader.result as string;
          try {
            const rows = await csvtojson({ delimiter: "auto" }).fromString(content);
            setRows(rows || []);
          } catch (e: any) {
            addNotification({ ok: 0, message: e.toString() });
          }
        };
        fileReader.readAsText(acceptedFiles[0]);
        setSelectedFile(acceptedFiles[0]);
      } catch (error: any) {
        addNotification({ ok: 0, message: error.message });
      }
    }
  }, []);

  const handleReset = () => {
    setSelectedFile(null);
    setRows(null);
    setReport(null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    minSize: 0,
    maxSize: 8000000,
    accept: "text/*"
  });

  if (!configLoaded) return null;

  const hasInvalidRow = report?.rows.find(r => r.error);

  return (
    <div id="uploader">
      <section className="header">
        <div className="left">
          <Typography variant="pageTitle" tag="h2">
            <h1>{configLoaded.title}</h1>
          </Typography>
          {/* <Button variant="secondary" type="button" onClick={() => load("7328994")}>
            <span>{t("Help?")}</span>
          </Button> */}
        </div>
        <div className="right">
          {report ? (
            <Button variant="danger" type="button" onClick={handleReset}>
              {t("Start over")}
            </Button>
          ) : null}
          <a href={"https://static.common-ground.io/common/misc/" + configLoaded.id + ".csv"} target="_blank" rel="noopener noreferrer">
            {t("Download CSV template")} <i className="cg-icon-download" />
          </a>
          <a target="_tab" href={configLoaded.link}>
            {t("View CSV template")} <i className="cg-icon-open-tab" />
          </a>
        </div>
      </section>
      {!selectedFile ? (
        <section className="upload">
          <div {...getRootProps({ id: "dropzone" })}>
            <input {...getInputProps()} />
            <p>{t("Drop CSV file here")}</p>
          </div>
        </section>
      ) : report && rows ? (
        <section className="submitUpload">
          <div>
            <Button
              disabled={isSubmitting || !isValid() || !!invalidationMessage}
              onClick={() => window.confirm(t("Are you sure?")) && handleSubmitUpload()}>
              {t("Submit upload")} ({rows?.length} {t("listings")})
            </Button>
            {invalidationMessage ? <p className="error">{invalidationMessage}</p> : null}
            <p>
              {t("Time estimate")}: {report?.timeEstimate} {t("seconds")}
            </p>
          </div>
        </section>
      ) : (
        <Loader />
      )}
      {report ? (
        <section>
          <h2>
            {pagination.limit} {t("Rows preview")}
          </h2>
          {hasInvalidRow ? (
            <span>
              <br />
              <a
                target="_blank"
                href="https://docs.google.com/spreadsheets/d/1s5jHYfle9O6EnB5AtyknM9k7eDnbmHfIJOm0f1tLubI/edit?pli=1#gid=1203542982"
                rel="noreferrer">
                {t("Please refer to our templates for formatting guidelines")}
              </a>
            </span>
          ) : null}
          <div className="rows">{report.rows ? report.rows.map((row, idx) => <Row key={idx} row={row} idx={idx + 1} t={t} />) : null}</div>
        </section>
      ) : null}
    </div>
  );
};

const Row = ({ row, idx, t }: { row: ItemUploadPreviewReportRow; idx: number; t: TFunction }) => {
  const [open, setOpen] = useState(false);
  const fields = Object.keys(row.fields).map(key => ({ id: key, ...row.fields[key] }));
  const okFields = fields.filter(f => !f.error);
  const errorFields = fields.filter(f => f.error);

  return (
    <Liner className="row" index={idx - 1}>
      <div className="header">
        <p>
          {t("Row")} {idx}:{" "}
          {row.error ? (
            <span className="error">
              {t("This row cannot be processed because of the following columns")}: {row.error ? row.error : ""}
            </span>
          ) : (
            <span className="">
              {okFields.length} {t("valid fields")}{" "}
              {errorFields.length ? (
                <span className="error">
                  {errorFields.length} {errorFields.length > 1 ? t("invalid fields") : t("invalid field")}
                </span>
              ) : (
                ""
              )}
            </span>
          )}
        </p>

        <Button onClick={() => setOpen(!open)} variant="noStyle">
          {!open ? <i className="cg-icon-filter-more" /> : <i className="cg-icon-filter-less" />}
        </Button>
      </div>
      {open ? (
        <div className="content">
          <div className="fields">
            <div className="entries">
              {okFields.map(f => (
                <p key={f.id} className={"field " + (f.error ? "error" : f.value ? "ok" : "")}>
                  {f.id}: {f.value || "-"}
                </p>
              ))}
            </div>
          </div>
          {errorFields.length ? (
            <div className="fields">
              <div className="entries">
                {errorFields.map(f => (
                  <p key={f.id} className="field error">
                    {f.id}:{" "}
                    {f.error ? (
                      <span className="">
                        {f.error} - {f.value}
                      </span>
                    ) : null}
                  </p>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </Liner>
  );
};

export default Uploader;
