import React, { useState } from "react";
import Button from "../../../styled/button";
import { Button as ButtonV2 } from "../../../../componentsV2/Button";
import Zone from "../../../styled/zone";
import { POST_CONFIG_RESET } from "../../../../graphql/queries/config";
import { useMutation } from "@apollo/client";
import { GlobalStore } from "../../../../stores/global";
import Checkbox from "../../../styled/checkbox";
import Loader from "../../../common/loader";
import { Config } from "../../../../__generated__/graphql";
import { AddNotification } from "../../../../types/globals";
import { TFunction } from "i18next";

const InventoryPreferences = ({
  config,
  addNotification,
  helpPanel,
  t
}: {
  config: Config;
  addNotification: AddNotification;
  logout: any;
  helpPanel: any;
  t: TFunction;
}) => {
  const [resetAccount, { loading: isDeleting }] = useMutation(POST_CONFIG_RESET);
  const defaults = Object.freeze({
    releases: false,
    products: false,
    books: false,
    customers: false,
    orders: false,
    credits: false
  });

  const [resetData, setResetData] = useState({ ...defaults });

  // @ts-ignore
  const collectionsToRemove = Object.keys(resetData).filter(k => resetData[k] > 0);

  const handleResetAccountData = async (form: any) => {
    form.preventDefault();
    if (window.confirm("You are about to delete some account data, are you sure?")) {
      if (!collectionsToRemove.length) return addNotification({ ok: 0, message: "At least one collection must be selected" });
      try {
        const { data } = await resetAccount({ variables: resetData });
        const configReset = data?.configReset;
        if (!configReset) throw new Error("Reset error");
        const str = Object.keys(configReset)
          // @ts-ignore
          .filter(k => configReset[k] > 0)
          // @ts-ignore
          .map(k => `Deleted: ${configReset[k]} ${k}`)
          .join(",");
        addNotification({ ok: 0, message: str || "Nothing deleted" });
        setResetData({ ...defaults });
      } catch (error: any) {
        addNotification({ ok: 0, message: error.message });
      }
    }
  };

  const handleresetDataChange = (e: any) => {
    setResetData({ ...resetData, [e.target.name]: e.target.checked });
  };

  const [state, setState] = useState({
    dontRememberInputs: localStorage.getItem(`${config.id}-dontRememberInputs`) === "true",
    legacyPrinting: localStorage.getItem(`${config.id}-legacyPrinting`) === "true",
    locationSearch: localStorage.getItem(config.id + "-locationSearch") === "true"
  });

  const handleCheckboxChange = (event: any) => {
    localStorage.setItem(`${config.id}-${event.target.name}`, String(event.target.checked));
    if (event.target.name === "legacyPrinting" && event.target.checked)
      GlobalStore.update(s => {
        s.stickers.entries = [];
      });
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const resetDataType = [
    { id: "releases", label: t("Delete all releases") },
    { id: "products", label: t("Delete all products") },
    { id: "books", label: t("Delete all books") },
    { id: "orders", label: t("Delete all orders") },
    { id: "customers", label: t("Delete all customers") },
    { id: "credits", label: t("Delete all credit notes") }
  ];

  return (
    <div className="dangerZone">
      <Zone className="backofficeOptions">
        <div className="header">
          <h2>{t("Preferences")}</h2>
          <Button variant="secondaryOverZone" type="button" onClick={() => helpPanel.load("7328974")}>
            <span>{t("Help?")}</span>
          </Button>
        </div>
        <Checkbox
          label={t("Do not keep in memory last entered values for listings such a sale price, cost or descriptions")}
          name="dontRememberInputs"
          variant="overZone"
          checked={state.dontRememberInputs}
          onChange={handleCheckboxChange}
        />
        <Checkbox
          label={t("Legacy printing via browser print dialogue")}
          name="legacyPrinting"
          variant="overZone"
          checked={state.legacyPrinting}
          onChange={handleCheckboxChange}
        />
        <Checkbox
          label={t("Inventory locations filtering via search")}
          name="locationSearch"
          variant="overZone"
          checked={state.locationSearch}
          onChange={handleCheckboxChange}
        />
      </Zone>
      <Zone className="resetData">
        <div className="header">
          <h2>{t("Delete account data")}</h2>
        </div>
        <div className="content">
          <form onSubmit={handleResetAccountData}>
            <div className="left">
              {resetDataType.map(e => (
                <div key={e.id}>
                  {/* @ts-ignore */}
                  <Checkbox name={e.id} checked={resetData[e.id]} onChange={handleresetDataChange} label={e.label} />
                  {e.id === "releases" && resetData[e.id] ? (
                    <small>{t("Discogs listings must be deleted manually if required")}</small>
                  ) : null}
                </div>
              ))}
            </div>
            <div className="right">
              <ButtonV2
                variant={collectionsToRemove.length ? "warning" : "secondary"}
                type="submit"
                disabled={isDeleting || collectionsToRemove.length === 0}>
                {isDeleting ? <Loader /> : t("Delete selected collections")}
              </ButtonV2>
            </div>
          </form>
        </div>
      </Zone>
    </div>
  );
};

export default InventoryPreferences;
