import React, { useState, useEffect } from "react";
import Button from "../../styled/button";
import Checkbox from "../../styled/checkbox";
import TextArea from "../../styled/textArea";
import Input from "../../styled/input";
import Zone from "../../styled/zone";
import clone from "clone";
import { useMutation } from "@apollo/client";
import { Select } from "../../styled/select";
import Loader from "../../common/loader";
import { POST_INVOICING_UPDATE, POST_CONFIG_TAXES_UPDATE } from "../../../graphql/queries/config";
import { GlobalStore } from "../../../stores/global";
import { Config, ConfigTaxes, Template } from "../../../__generated__/graphql";
import { Button as ButtonV2 } from "../../../componentsV2/Button";

import currencies from "../../../assets/currencies.json";
import { useTranslation } from "react-i18next";
import QuillEditor from "../../quillEditor";
import { Typography } from "../../../componentsV2/Typography";

const Other = ({
  ga,
  facebook,
  templates,
  information,
  shipping,
  updateModule
}: {
  ga: any;
  updateModule: any;
  facebook: any;
  templates: Template[];
  information: any;
  shipping: any;
}) => {
  const { config, addNotification, helpPanel } = GlobalStore.useState(c => c);
  const [updateInvoicing] = useMutation(POST_INVOICING_UPDATE);
  const [updateTaxes, { loading }] = useMutation(POST_CONFIG_TAXES_UPDATE);
  const [taxes, setTaxes] = useState<ConfigTaxes>(clone((config as Config)?.taxes));
  const [invoicing, setInvoicing] = useState<any>();
  const { t } = useTranslation();

  const handleSubmitTaxes = async (e: any) => {
    e.preventDefault();
    try {
      const { data } = await updateTaxes({
        variables: {
          vatNumber: e.target.vatNumber.value,
          countryCode: taxes.country.code,
          countryZone: taxes.country.zone ? taxes.country.zone.code : undefined,
          definitions: taxes.definitions
            .filter(d => !d.default)
            .map(d => ({ title: d.title || "", name: d.name, rate: d.rate, default: false })),
          rules: {
            collectTaxesInZone: taxes.rules.collectTaxesInZone,
            editPricesBeforeTaxes: taxes.rules.editPricesBeforeTaxes,
            showPricesBeforeTaxes: taxes.rules.showPricesBeforeTaxes,
            collectTaxesNationally: taxes.rules.collectTaxesNationally,
            collectTaxesInArea: taxes.rules.collectTaxesInArea,
            collectTaxesOutsideCountryOrArea: taxes.rules.collectTaxesOutsideCountryOrArea,
            collectTaxesOnShipping: taxes.rules.collectTaxesOnShipping,
            europeanDistanceSalesOverride: taxes.rules.europeanDistanceSalesOverride,
            fallbackToNationalRate: taxes.rules.fallbackToNationalRate
          }
        }
      });
      if (!data?.configTaxesUpdate) throw new Error("Update error");
      setTaxes(clone(data.configTaxesUpdate.taxes));
      addNotification({ ok: 1, message: "VAT number updated" });
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
  };

  const [state, setState] = useState({
    ga: clone(ga),
    facebook: clone(facebook),
    templates: clone(templates),
    information: clone(information),
    shipping: clone(shipping)
  });

  useEffect(() => {
    setInvoicing({
      active: config?.invoicing?.generator?.active,
      counter: config?.invoicing?.generator?.counter,
      format: config?.invoicing?.generator?.format
    });
  }, [config]);

  const handleChange = (module: any, event: any) => {
    // @ts-ignore
    const data = state[module].data;
    data[event.target.name] = event.target.value;
    setState(clone(state));
  };

  const handleInvoiceFooterChange = (content: string) => {
    const { information } = state;
    information.data.invoiceFooter = content;
    setState(clone(state));
  };

  const handleLegalChange = (content: string) => {
    const { information } = state;
    information.data.legalText = content;
    setState(clone(state));
  };

  const handlePolicyTextChange = (content: string) => {
    const { shipping } = state;
    shipping.data.policyText = content;
    setState(clone(state));
  };

  const handleFooterChange = (content: string) => {
    const { information } = state;
    information.data.emailFooter = content;
    setState(clone(state));
  };

  const handleSubmitModule = (module: any, event: any) => {
    event.preventDefault();
    // @ts-ignore
    updateModule(state[module]);
    event.preventDefault();
  };

  const handleSelectChange = (name: string) => (selectedOption: any) => {
    const { information } = state;
    information.data[name] = selectedOption.value;
    setState(clone(state));
  };

  const handleUpdateInvoicing = async (e: any) => {
    e.preventDefault();
    try {
      await updateInvoicing({
        variables: { settings: { format: invoicing.format, counter: parseInt(invoicing.counter), active: invoicing.active } }
      });
      addNotification({ ok: 1, message: "Invoicing updated" });
    } catch (error: any) {
      addNotification({ ok: 0, message: error.toString() });
    }
  };

  const handleInvoicingChange = (e: any) => {
    if (e.target.name === "active") setInvoicing({ ...invoicing, active: e.target.checked });
    else setInvoicing({ ...invoicing, [e.target.name]: e.target.value });
  };

  if (!invoicing) return <Loader />;

  return (
    <div id="legal">
      <Zone>
        <Typography variant="subTitle" style={{ marginBottom: "20px" }}>
          {t("VAT registration")}
        </Typography>
        <hr />
        <form onSubmit={(e: any) => handleSubmitTaxes(e)}>
          <div className="preferencesFields">
            <Input
              label={t("Tax / VAT Number")}
              variant="overZone"
              name="vatNumber"
              value={taxes?.vat?.id}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTaxes({ ...taxes, vat: { id: e.target.value, validated: false } })}
            />
            {taxes.country.area?.code === "EU" ? (
              <div>
                <p>
                  {t("Status")}: {taxes.vat?.validated ? t("Valid in the European Union") : t("Invalid or not registered")}
                </p>
              </div>
            ) : null}
          </div>
          <ButtonV2 variant="primary" type="submit" disabled={loading} styleProps={{ marginTop: "var(--gutter)" }}>
            {loading ? <Loader /> : t("Submit")}
          </ButtonV2>
        </form>
      </Zone>
      <Zone>
        <div className="flexSpaceBetween">
          <Typography variant="subTitle" style={{ marginBottom: "20px" }}>
            {t("Currency & Bank details")}
          </Typography>
          <Button variant="secondary" type="button" onClick={() => helpPanel.load("7328954")}>
            <span>{t("Help?")}</span>
          </Button>
        </div>
        <hr />
        <form onSubmit={(e: any) => handleSubmitModule("information", e)}>
          <div className="preferencesFields">
            <Select
              label={t("Currency")}
              variant="overZone"
              value={{
                value: state.information.data.currency,
                label: state.information.data.currency
              }}
              onChange={handleSelectChange("currency")}
              options={currencies.map(c => ({ value: c, label: c }))}
            />
            <Select
              label={t("Currency Symbol Position")}
              variant="overZone"
              options={[
                { value: "before", label: t("Before") },
                { value: "after", label: t("After") }
              ]}
              value={{
                value: state.information.data.currencySymbolPosition,
                label: state.information.data.currencySymbolPosition
              }}
              onChange={handleSelectChange("currencySymbolPosition")}
            />
            <TextArea
              variant="overZone"
              label={t("Billing address (private for invoicing)")}
              value={state.information.data.billingAddress}
              name="billingAddress"
              rows="8"
              onChange={(e: any) => handleChange("information", e)}
            />
            <Input
              label={t("IBAN Number")}
              variant="overZone"
              type="text"
              name="iban"
              value={state.information.data.iban}
              onChange={(e: any) => handleChange("information", e)}
            />
            <Input
              label={t("BIC identifier")}
              variant="overZone"
              type="text"
              name="bic"
              value={state.information.data.bic}
              onChange={(e: any) => handleChange("information", e)}
            />
            <Input
              label={t("Company ID")}
              variant="overZone"
              type="text"
              name="companyId"
              value={state.information.data.companyId}
              onChange={(e: any) => handleChange("information", e)}
            />
          </div>
          <ButtonV2 variant="primary" type="submit" disabled={loading} styleProps={{ marginTop: "var(--gutter)" }}>
            {loading ? <Loader /> : t("Submit")}
          </ButtonV2>
        </form>
      </Zone>
      <Zone>
        <form onSubmit={handleUpdateInvoicing}>
          <div className="flexSpaceBetween">
            <Typography variant="subTitle" style={{ marginBottom: "20px" }}>
              {t("Invoice numbering")}
            </Typography>
            <Button variant="secondary" type="button" onClick={() => helpPanel.load("7328963")}>
              <span>{t("Help?")}</span>
            </Button>
          </div>
          <hr />
          <p>{t("When enabled, an incremental id is generated for every new invoice as custom id")}</p>
          <div className="preferencesFields">
            <Checkbox
              name="active"
              onChange={handleInvoicingChange}
              checked={!!invoicing.active}
              label={t("Enable invoice custom id generator")}
            />
            <span />
            <Input
              label={t("Invoice id format")}
              placeholder={t("Format such as: 2022-{counter}")}
              variant="overZone"
              type="text"
              required
              name="format"
              value={invoicing.format}
              onChange={handleInvoicingChange}
            />
            <Input
              label={t("Current counter value")}
              variant="overZone"
              type="number"
              min="0"
              max="99999999"
              required
              name="counter"
              value={invoicing.counter}
              onChange={handleInvoicingChange}
            />
          </div>
          <ButtonV2 variant="primary" type="submit" disabled={loading} styleProps={{ marginTop: "var(--gutter)" }}>
            {loading ? <Loader /> : t("Submit")}
          </ButtonV2>
        </form>
      </Zone>
      <Zone>
        <div className="flexSpaceBetween">
          <Typography variant="subTitle" style={{ marginBottom: "20px" }}>
            {t("Legal notices")}
          </Typography>
          <Button variant="secondary" type="button" onClick={() => helpPanel.load("7328969")}>
            <span>{t("Help?")}</span>
          </Button>
        </div>
        <div className="preferencesFields">
          <form onSubmit={(e: any) => handleSubmitModule("shipping", e)}>
            <p>{t("Shipping policy")}</p>
            <QuillEditor content={state.shipping.data.policyText || ""} onChange={handlePolicyTextChange} />
            <ButtonV2 variant="primary" type="submit" disabled={loading} styleProps={{ marginTop: "var(--gutter)" }}>
              {loading ? <Loader /> : t("Submit")}
            </ButtonV2>
          </form>
          <form onSubmit={(e: any) => handleSubmitModule("information", e)}>
            <p>{t("Terms and conditions")}</p>
            <QuillEditor content={state.information.data.legalText || ""} onChange={handleLegalChange} />
            <ButtonV2 variant="primary" type="submit" disabled={loading} styleProps={{ marginTop: "var(--gutter)" }}>
              {loading ? <Loader /> : t("Submit")}
            </ButtonV2>
          </form>
          <form onSubmit={(e: any) => handleSubmitModule("information", e)}>
            <p>{t("Invoice footer")}</p>
            <QuillEditor content={state.information.data.invoiceFooter || ""} onChange={handleInvoiceFooterChange} />
            <ButtonV2 variant="primary" type="submit" disabled={loading} styleProps={{ marginTop: "var(--gutter)" }}>
              {loading ? <Loader /> : t("Submit")}
            </ButtonV2>
          </form>
          <form onSubmit={(e: any) => handleSubmitModule("information", e)}>
            <p>{t("Online order email footer")}</p>
            <QuillEditor content={state.information.data.emailFooter || ""} onChange={handleFooterChange} />
            <ButtonV2 variant="primary" type="submit" disabled={loading} styleProps={{ marginTop: "var(--gutter)" }}>
              {loading ? <Loader /> : t("Submit")}
            </ButtonV2>
          </form>
        </div>
      </Zone>
    </div>
  );
};

export default Other;
