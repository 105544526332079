import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { POST_CONFIG_TAXES_UPDATE } from "../../../graphql/queries/config";
import Zone from "../../styled/zone";
import Checkbox from "../../styled/checkbox";
import { Select } from "../../styled/select";
import TaxEditor from "./includes/taxEditor";
import clone from "clone";
import { GlobalStore } from "../../../stores/global";
import { Config, ConfigTaxDefinition, ConfigTaxes } from "../../../__generated__/graphql";
import isNumber from "is-number";
import { Button as ButtonV2 } from "../../../componentsV2/Button";
import { useTranslation } from "react-i18next";

export default function Taxes() {
  const { addNotification, config } = GlobalStore.useState(c => c);
  const [taxes, setTaxes] = useState<ConfigTaxes>(clone((config as Config).taxes));
  const [updateTaxes] = useMutation(POST_CONFIG_TAXES_UPDATE);
  const { t } = useTranslation();
  const handleUpdateTaxes = (taxeDefinitions: ConfigTaxDefinition[]) => {
    taxes.definitions = taxeDefinitions;
    setTaxes(clone(taxes));
  };

  const handleSubmitTaxes = async (e: any) => {
    e.preventDefault();
    try {
      taxes.definitions
        ?.filter(d => !d.default)
        .forEach(d => {
          if (!d?.title || !d?.name || !isNumber(d?.rate)) throw new Error("Fill in all tax fields");
        });
      const { data } = await updateTaxes({
        variables: {
          countryCode: taxes.country?.code,
          countryZone: taxes.country?.zone ? taxes.country.zone.code : undefined,
          definitions: taxes.definitions
            .filter(d => !d.default)
            .map(d => ({
              title: d.title || "",
              name: d.name,
              rate: typeof d.rate === "string" ? parseFloat(d.rate) : d.rate,
              default: false
            })),
          rules: {
            collectTaxesInZone: taxes.rules.collectTaxesInZone,
            editPricesBeforeTaxes: taxes.rules.editPricesBeforeTaxes,
            showPricesBeforeTaxes: taxes.rules.showPricesBeforeTaxes,
            collectTaxesNationally: taxes.rules.collectTaxesNationally,
            collectTaxesInArea: taxes.rules.collectTaxesInArea,
            collectTaxesOutsideCountryOrArea: taxes.rules.collectTaxesOutsideCountryOrArea,
            collectTaxesOnShipping: taxes.rules.collectTaxesOnShipping,
            europeanDistanceSalesOverride: taxes.rules.europeanDistanceSalesOverride,
            fallbackToNationalRate: taxes.rules.fallbackToNationalRate
          }
        }
      });
      if (!data?.configTaxesUpdate) throw new Error("Update error");
      setTaxes(clone(data.configTaxesUpdate.taxes));
      addNotification({ ok: 1, message: "Tax settings updated" });
    } catch (e: any) {
      addNotification({ ok: 0, message: e.data || e.toString() });
    }
  };

  const handleRuleCheckboxUpdate = (e: any) => {
    // @ts-ignore
    taxes.rules[e.target.name] = e.target.checked;
    setTaxes(clone(taxes));
  };

  const handleZoneSelectChange = (selectedOption: any) => {
    const clonedTaxes = clone(taxes);
    clonedTaxes.country.zone = selectedOption.value;
    setTaxes(clonedTaxes);
  };

  const { country, rules } = taxes;
  const isInTaxArea = !!taxes.country?.area;
  const area = taxes.country.area;
  const isInZone = !!taxes.country?.taxZoneRequired;
  const areaName = country && country.area ? country.area.name : country?.name;
  const zone = country && country.taxZoneRequired ? country.zone : null;

  const hasTaxesEnabled = rules.collectTaxesInZone || rules.collectTaxesNationally;

  return (
    <form onSubmit={handleSubmitTaxes} id="taxes">
      <section className="header">
        <h2>
          {t("Taxes information for")} {taxes.country.name}
        </h2>
      </section>
      <div className="country">
        <div className="left">
          {country && country.taxZoneRequired && taxes.country.zones?.length ? (
            <div className="zoneSelect">
              <h3 className={!zone ? "danger" : ""}>{t("Select your tax region")}</h3>
              <Select
                value={taxes.country.zone ? { label: taxes.country.zone.name } : null}
                onChange={handleZoneSelectChange}
                options={taxes.country.zones.map(z => ({ label: z.name, value: z }))}
              />
            </div>
          ) : null}
          {country ? (
            <>
              <h3>
                {t("Taxes information for")} {(zone || country).name}
              </h3>
              <p>
                {t("Your tax jurisdiction is")} {areaName}
              </p>
              <p>
                {country.tax
                  ? t("The national tax rate for {{countryName}} is {{countryTaxRate}}% {{countryTaxName}}", {
                      countryName: country.name,
                      countryTaxRate: country.tax.rate,
                      countryTaxName: country.tax.name
                    })
                  : t("{{countryName}} does not have a national tax rate", { countryName: country.name })}
              </p>
              {zone ? (
                <p>
                  {zone.tax
                    ? t("Your provincial / state tax is {{zoneTaxRate}}% {{zoneTaxName}}", {
                        zoneTaxRate: zone.tax.rate,
                        zoneTaxName: zone.tax.name
                      })
                    : t("No provincial tax")}
                  {zone.overrideNationalTaxRate ? (
                    <>
                      <br />
                      {t("National tax rate does not apply")}
                    </>
                  ) : null}
                </p>
              ) : null}
              <p>
                {country.taxZoneRequired
                  ? t("When shipping out of {{area}}, you must collect taxes", { area: areaName })
                  : t("When shipping out of {{area}}, you must not collect taxes", { area: areaName })}
              </p>
            </>
          ) : null}
        </div>
        {country && rules ? (
          <div className="right">
            <h3>{t("Taxes preferences")}</h3>
            <div className="taxRules">
              {isInZone && taxes.country.zone ? (
                <div className="rule">
                  <Checkbox
                    disabled={true}
                    label={t("Collect taxes in {{taxesCountryZoneName}} (cannot be modified)", {
                      taxesCountryZoneName: taxes.country.zone.name
                    })}
                    checked={rules.collectTaxesInZone}
                    onChange={handleRuleCheckboxUpdate}
                    name="collectTaxesInZone"
                  />
                  <p>
                    {t("Orders from the Point Of Sale and online orders shipping to {{taxesCountryZoneName}} will collect regional taxes", {
                      taxesCountryZoneName: taxes.country.zone.name
                    })}
                  </p>
                </div>
              ) : null}

              <div className="rule">
                <Checkbox
                  disabled={true}
                  label={t("Collect taxes in {{taxesCountryZoneName}} (cannot be modified)", {
                    taxesCountryZoneName: taxes.country.name
                  })}
                  checked={rules.collectTaxesNationally}
                  onChange={handleRuleCheckboxUpdate}
                  name="collectTaxesNationally"
                />
                <p>
                  {isInZone
                    ? t("Online orders shipping to {{taxesCountryName}} will collect national taxes", {
                        taxesCountryName: taxes.country.name
                      })
                    : t("Point Of Sale and Online orders shipping to {{taxesCountryName}} will collect national taxes", {
                        taxesCountryName: taxes.country.name
                      })}
                </p>
              </div>

              {hasTaxesEnabled ? (
                <>
                  <div className="rule">
                    <Checkbox
                      label={t("Edit prices before taxes")}
                      checked={rules.editPricesBeforeTaxes}
                      onChange={handleRuleCheckboxUpdate}
                      name="editPricesBeforeTaxes"
                    />
                    <p>{t("Edit listing and order prices before taxes from your Backoffice")}</p>
                  </div>
                  <div className="rule">
                    <Checkbox
                      label={t("Show prices before taxes")}
                      checked={rules.showPricesBeforeTaxes}
                      onChange={handleRuleCheckboxUpdate}
                      name="showPricesBeforeTaxes"
                    />
                    <p>
                      {t(
                        "Eshop prices are displayed before sales tax. Totals and sales tax will be computed based on the destination country during checkout"
                      )}
                      {"."}
                    </p>
                  </div>

                  {isInTaxArea && area ? (
                    <div className="rule">
                      <Checkbox
                        label={t("Collect taxes in {{areaName}}", { areaName: area.name })}
                        checked={rules.collectTaxesInArea}
                        onChange={handleRuleCheckboxUpdate}
                        name="collectTaxesInArea"
                      />
                      <p>{t("Online orders shipping to {{areaName}} will collect {{areaName}} taxes", { areaName: area.name })}</p>
                    </div>
                  ) : null}

                  <div className="rule">
                    <Checkbox
                      label={t("Collect taxes when shipping out of {{area}}", { area: areaName })}
                      checked={rules.collectTaxesOutsideCountryOrArea}
                      onChange={handleRuleCheckboxUpdate}
                      name="collectTaxesOutsideCountryOrArea"
                    />
                    <p>{t("When shipping out of {{area}} you have the option to avoid tax collection", { area: areaName })}</p>
                  </div>
                  <div className="rule">
                    <Checkbox
                      label={t("Collect taxes on shipping cost")}
                      checked={rules.collectTaxesOnShipping}
                      onChange={handleRuleCheckboxUpdate}
                      name="collectTaxesOnShipping"
                    />
                    <p>{t("When active, the item's taxes will also apply to the shipping cost")}.</p>
                  </div>
                  {country.area && country.area.code === "EU" ? (
                    <div className="rule">
                      <Checkbox
                        label={t("Distance sales is over the 10k€ threshold")}
                        checked={rules.collectTaxesInArea && rules.europeanDistanceSalesOverride}
                        onChange={handleRuleCheckboxUpdate}
                        name="europeanDistanceSalesOverride"
                      />
                      <p>
                        {t(
                          "When part of the EEA and above the distance sales threshold of 10k, you have to collect VAT in the destination's country"
                        )}
                        .
                      </p>
                    </div>
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
      {hasTaxesEnabled ? (
        <Zone>
          <TaxEditor update={handleUpdateTaxes} definitions={taxes.definitions} />
        </Zone>
      ) : null}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span />
        <ButtonV2 variant="primary" type="submit">
          {t("Submit")}
        </ButtonV2>
      </div>
    </form>
  );
}
