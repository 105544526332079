import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { IoIosWarning } from "react-icons/io";
import { useTranslation } from "react-i18next";

export default function Notice() {
  const start = moment("2020-01-12T00:00:00+01:00");
  const end = moment("2020-01-12T10:00:00+01:00");
  const { t } = useTranslation();

  if (moment().isAfter(end)) return null;
  return (
    <div id="notice">
      <IoIosWarning />
      <Link to="/news">
        {t("Common Ground will go under maintenance from {{date}} for about 10 hours", { date: `${start.format("llll")}` })}
      </Link>
    </div>
  );
}
