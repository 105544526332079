import React, { useState, useEffect, useRef, useMemo } from "react";
import Button from "../styled/button";
import Search from "../styled/search";
import { useLocation, Link } from "react-router-dom";
import { discogsSearch } from "../../@services/discogsService";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import Price from "../common/price";
import { useLazyQuery } from "@apollo/client";
import { GET_VOUCHERS } from "../../graphql/queries/voucher";
import Liner from "../../components/common/liner";
import { GoDotFill } from "react-icons/go";
import debounce from "lodash.debounce";
import color from "@common-ground-io/common-assets/assets/colors.json";
import { GlobalStore } from "../../stores/global";
import { GET_ITEM_SEARCH } from "../../graphql/queries/item";
import { GET_USER_SEARCH } from "../../graphql/queries/user";
import { GET_ORDER_SEARCH } from "../../graphql/queries/order";
import moment from "moment";
import { Buyer, Config, ConfigDiscogsOauthData, Item, Listing, Order, Voucher } from "../../__generated__/graphql";
import { getPlaceholderImage } from "../../utils";
import { EThemes } from "../../types/globals";
import { useTranslation } from "react-i18next";

const SearchListings = ({
  config,
  placeholder,
  variant,
  onSelect,
  label,
  quantitySort
}: {
  config: Config;
  placeholder?: string;
  variant?: string;
  onSelect: (entry: any, listing: any) => void;
  label?: string;
  quantitySort?: boolean;
}) => {
  const searchRef = useRef<any>();
  const [searchItem] = useLazyQuery(GET_ITEM_SEARCH, { fetchPolicy: "cache-and-network" });

  const handleSearch = async (term: string) => {
    try {
      const { data } = await searchItem({ variables: { term, quantitySort: !!quantitySort } });
      if (data?.itemSearch?.entries && data.itemSearch?.entries.length === 1) {
        const matchingListing = data.itemSearch.entries[0].listings.find(
          l => l.id === parseFloat(term) || l.barcode === term || l.sku === term
        );
        if (matchingListing) {
          onSelect(data?.itemSearch.entries[0], matchingListing);
          searchRef.current?.close();
          return null;
        }
      }
      return data?.itemSearch?.entries;
    } catch (e: any) {
      console.log(e);
    }
  };

  const handleSelect = (e: any, entry: any, listing: Listing) => {
    e.preventDefault();
    if (onSelect) onSelect(entry, listing);
  };

  const displayPreTaxes = config.taxes?.rules?.editPricesBeforeTaxes;

  const suggestionComponent = ({ entry, handleResultClick }: { entry: Item; handleResultClick: any }) => {
    if (!entry) return null;

    return (
      <div className="suggestion">
        <img src={entry.data.thumb || (config ? getPlaceholderImage(config) : "")} />
        <div className="entry">
          <p className="description">{`${entry.descriptions.main}`}</p>
          {entry.listings.map(l => (
            <div key={l._id} className="listing">
              <Link
                to={entry.path}
                onClick={(e: any) => {
                  handleSelect(e, entry, l);
                  handleResultClick({ focus: true });
                }}>
                {l.status === "private" ? <i style={{ marginRight: "5px" }} className="cg-icon-padlock" /> : null}
                {/* <Text variant={l.status !== "published" ? "secondary" : "primary"}> */}
                {/* eslint-disable-next-line i18next/no-literal-string */}
                {l.stock.quantity}x <Price value={(displayPreTaxes ? l.prices.beforeTaxes : l.prices.sale) as number} /> -{" "}
                {l.options.map((o, i) => (
                  <span key={o.name}>
                    {o.name} {"->"} {o.value} {i < l.options.length - 1 ? " / " : null}
                  </span>
                ))}
                {l.location ? ` - ${l.location}` : null}
                {/* </Text> */}
              </Link>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div id="mainSearch">
      <Search
        ref={searchRef}
        variant={variant}
        label={label}
        className="listingsSearch"
        debounceTime={500}
        placeholder={placeholder}
        search={handleSearch}
        suggestionComponent={suggestionComponent}
      />
    </div>
  );
};

export interface SearchReleaseDiscogs {
  style: string[];
  thumb: string;
  title: string;
  type: "release";
  format: string[];
  uri: string;
  label: string[];
  country: string;
  catno: string;
  year?: string;
  genre: string[];
  resource_url: string;
  master_id: number;
  id: number;
}

const SearchWithDiscogs = ({
  placeholder,
  variant,
  onSelect,
  label,
  token,
  accessData
}: {
  placeholder?: string;
  variant?: string;
  onSelect?: any;
  label?: string;
  token?: any;
  accessData?: ConfigDiscogsOauthData;
}) => {
  const handleSearch = async (term: string) => {
    try {
      const { data } = await discogsSearch({ token, term, type: "release", accessData });
      return data.results;
    } catch (e: any) {
      console.log(e);
    }
  };

  const handleSelect = (e: any, entry: any, listing?: Listing) => {
    e.preventDefault();
    if (onSelect) onSelect(entry, listing);
  };

  const suggestionComponent = ({ entry, handleResultClick }: { entry: SearchReleaseDiscogs; handleResultClick: any }) => {
    return (
      <div className="suggestion">
        <img src={entry.thumb} alt={entry.title} />
        <Link
          to="#"
          onClick={(e: any) => {
            handleSelect(e, entry);
            handleResultClick({ focus: true });
          }}>
          <span>
            {entry.title} {entry.year}
            <br />
            {entry.label.join(" / ")} ({entry.catno}) {entry.format.join("/")} - {entry.country}
          </span>
        </Link>
      </div>
    );
  };

  return (
    <Search
      variant={variant}
      label={label}
      className="discogsSearch"
      debounceTime={500}
      placeholder={placeholder}
      search={handleSearch}
      suggestionComponent={suggestionComponent}
    />
  );
};

const SearchVouchers = ({
  placeholder,
  variant,
  onSelect,
  label,
  voucher,
  removeVoucher,
  query
}: {
  placeholder?: string;
  variant?: string;
  onSelect?: any;
  label?: string;
  voucher?: Voucher | null;
  removeVoucher?: any;
  query?: any;
}) => {
  const [getVouchers] = useLazyQuery(GET_VOUCHERS, { fetchPolicy: "cache-and-network" });

  const { t } = useTranslation();

  const handleSearch = async (term: string) => {
    try {
      const { data } = await getVouchers({ variables: { term, status: "active", type: "coupon", ...(query || {}) } });
      return data?.vouchers?.vouchers.map(i => ({ label: i.id, data: i }));
    } catch (e: any) {
      console.log(e);
    }
  };

  const handleSelect = (e: any, voucher: Voucher) => {
    e.preventDefault();
    if (onSelect) onSelect(voucher);
  };

  const suggestionComponent = ({ entry, handleResultClick }: { entry: { label: string; data: Voucher }; handleResultClick: any }) => {
    return (
      <div className="suggestion">
        <div className="entry">
          <Link
            to={"#"}
            onClick={(e: any) => {
              handleSelect(e, entry.data);
              handleResultClick();
            }}>
            {entry.label} {" | "}
            {entry.data.redeemType === "percentage" ? `${entry.data.value}%` : <Price value={entry.data.value as number} />}
          </Link>
        </div>
      </div>
    );
  };

  if (voucher) {
    return (
      <div className="voucherInfo">
        <h3>{t("Active coupon discount")}</h3>
        <p>
          {voucher.id} - {voucher.redeemType === "percentage" ? <>{voucher.value}%</> : <Price value={voucher.value as number} />}
        </p>
        <Button variant="noStyle" type="button" onClick={removeVoucher}>
          <IoIosRemoveCircleOutline />
        </Button>
      </div>
    );
  }

  return (
    <Search
      variant={variant}
      label={label}
      className="vouchersSearch"
      debounceTime={500}
      placeholder={placeholder}
      search={handleSearch}
      suggestionComponent={suggestionComponent}
    />
  );
};

const SearchUsers = ({ onSelect, variant, label }: { onSelect?: any; variant?: string; label?: string }) => {
  const [searchUsers] = useLazyQuery(GET_USER_SEARCH, { fetchPolicy: "cache-and-network" });
  const { t } = useTranslation();
  const getSuggestions = async (term: string) => {
    if (!term) return;
    try {
      const { data } = await searchUsers({ variables: { term } });
      return data?.userSearch?.map(i => ({ label: `${i.name || ""} ${i.email}`, path: i.path, data: i }));
    } catch (e: any) {
      console.error(e);
    }
  };

  const handleSelect = (e: any, entry: { path: any; label: string; data: Buyer }) => {
    if (onSelect) onSelect(entry.data, e);
  };

  const suggestionComponent = ({
    entry,
    handleResultClick
  }: {
    handleResultClick: any;
    entry: { path: any; label: string; data: Buyer };
  }) => {
    return (
      <div key={entry.path} className={"suggestion"}>
        <Link
          onClick={(e: any) => {
            handleSelect(e, entry);
            handleResultClick();
          }}
          to={entry.path}>
          {entry.label}
        </Link>
      </div>
    );
  };

  return (
    <Search
      label={label}
      variant={variant}
      suggestionComponent={suggestionComponent}
      className="usersSearch"
      search={getSuggestions}
      placeholder={t("Search by name, organization") + "..."}
      debounceTime={300}
    />
  );
};

const SearchOrders = () => {
  const [searchOrders] = useLazyQuery(GET_ORDER_SEARCH, { fetchPolicy: "cache-and-network" });
  const { t } = useTranslation();
  const getSuggestions = async (term: string) => {
    if (!term) return;
    try {
      const { data } = await searchOrders({ variables: { term } });
      return data?.orderSearch;
    } catch (e: any) {
      console.error(e);
    }
  };

  const suggestionComponent = ({ entry, handleResultClick }: { entry: Order; handleResultClick: any }) => {
    return (
      <div key={entry.path} className={"suggestion"}>
        <Link onClick={() => handleResultClick()} to={`/order/${entry.incId}`}>
          {/* eslint-disable-next-line i18next/no-literal-string */}
          {/* eslint-disable-next-line i18next/no-literal-string */}#{entry.incId} - #{entry.id} - <Price value={entry.totals.grand} />{" "}
          {entry.buyer?.name ? `- ${entry.buyer?.name}` : ""}
          <br />
          {t("Created")} {moment(entry.created).fromNow()} - {entry.status}
        </Link>
      </div>
    );
  };

  return (
    <div className="orderSearch">
      <Search
        suggestionComponent={suggestionComponent}
        className="ordersSearch"
        search={getSuggestions}
        placeholder={t("Search by name, organization, total, id, notes") + "..."}
        debounceTime={300}
      />
    </div>
  );
};

const inputLayout = {
  light: {
    primary: {
      backgroundColor: color.greyLightest
    },
    overZone: {
      backgroundColor: color.white
    }
  },
  dark: {
    primary: {
      backgroundColor: color.greyDarker
    },
    overZone: {
      backgroundColor: color.greyDark
    }
  }
};

const SearchItems = ({
  variant = "primary",
  focus = false,
  onSelect,
  placeholder,
  formId = "none"
}: {
  variant?: string;
  focus?: boolean;
  onSelect?: (item: Item, e?: any) => void;
  placeholder?: string;
  formId?: string;
}) => {
  const theme = GlobalStore.useState(c => c.theme) as EThemes;
  const [term, setTerm] = useState("");
  const [hasNavigated, setHasNavigated] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [results, setResults] = useState<Item[] | null>(null);
  const location = useLocation();
  const localRef = useRef<any>(null);
  const [searchItem] = useLazyQuery(GET_ITEM_SEARCH, { fetchPolicy: "no-cache" });
  const { t } = useTranslation();

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => document.removeEventListener("click", handleClickOutside, true);
  }, []);

  useEffect(() => {
    setHasNavigated(true);
    resetAndClose();
  }, [location.pathname + location.search]);

  const handleSearch = async (term: string) => {
    try {
      const { data } = await searchItem({ variables: { term } });
      return data?.itemSearch?.entries as Item[];
    } catch (e: any) {
      console.log(e);
    }
  };

  const eventHandler = (term: string) => {
    if (term) {
      handleSearch(term).then(results => {
        setResults(results as Item[]);
        setIsSearching(false);
      });
    }
  };

  const changeHandler = (e: any) => {
    const value = e.target.value;
    setIsSearching(true);
    setTerm(value);
    setHasNavigated(false);
    if (value) debouncedChangeHandler(value);
    else resetAndClose();
  };

  const resetAndClose = () => {
    setResults(null);
    setTerm("");
    setIsSearching(false);
  };

  const handleClickOutside = (event: any) => {
    if (localRef.current && !localRef.current.contains(event.target)) {
      resetAndClose();
    }
  };

  const debouncedChangeHandler = useMemo(() => debounce(eventHandler, 1000), []);

  // @ts-ignore
  const backgroundColor = inputLayout[theme][variant || "primary"].backgroundColor;

  return (
    <div className="mainSearch" style={{ backgroundColor }} ref={localRef}>
      <div className="container">
        <div className="inputContainer">
          <input
            type="text"
            form={formId}
            name="search"
            required
            autoFocus={focus}
            style={{ backgroundColor }}
            autoComplete="off"
            value={term}
            onChange={changeHandler}
            placeholder={placeholder || t("Find releases, products or books") + "..."}
          />
          <button type="submit" className="reset">
            <i className="cg-icon-search" />
          </button>
        </div>
        {!hasNavigated ? (
          <div className="suggestions">
            {isSearching ? (
              <Liner className="suggestionEntry">
                <div className="suggestion">
                  <p>{t("Searching")}...</p>
                </div>
              </Liner>
            ) : null}
            {!isSearching && results && Array.isArray(results) && results.length === 0 ? (
              <Liner className="suggestionEntry">
                <div className="suggestion">
                  <p>{t("No results")}...</p>
                </div>
              </Liner>
            ) : null}
            {!isSearching && results && Array.isArray(results) && results.length
              ? results.map((item, i) => (
                  <Liner className="suggestionEntry" index={i} key={i}>
                    <SuggestionComponent onSelect={onSelect} item={item} resetAndClose={resetAndClose} />
                  </Liner>
                ))
              : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const SuggestionComponent = ({
  item,
  onSelect,
  resetAndClose
}: {
  item: Item;
  onSelect?: (item: Item, e?: any) => void;
  resetAndClose: () => void;
}) => {
  const handleItemClick = (e: any) => {
    if (onSelect) {
      e.preventDefault();
      onSelect(item);
      resetAndClose();
    }
  };
  const stockCount = item.listings.reduce((total, a) => a.stock.quantity + total, 0);

  return (
    <div className="suggestion">
      <div className={`indicator ${stockCount <= 0 ? "soldOut" : "inStock"}`}>
        <GoDotFill />
      </div>
      {item?.data?.images && item.data.images.length ? (
        <div>
          <img alt="Release artwork" src={item.data.images[0].uri || ""} />
        </div>
      ) : (
        <span />
      )}
      <span className={`stock ${stockCount > 0 ? "instock" : "soldout"}`}></span>
      <Link to={item.path || ""} onClick={handleItemClick}>
        {/* eslint-disable-next-line i18next/no-literal-string */}
        {stockCount} x {item.descriptions.main}
      </Link>
    </div>
  );
};

export { SearchListings, SearchItems, SearchWithDiscogs, SearchVouchers, SearchUsers, SearchOrders };
