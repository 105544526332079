import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import Tooltip from "../../common/tooltip";
import Input from "../../styled/input";
import Button from "../../styled/button";
import { Button as ButtonV2 } from "../../../componentsV2/Button";
import { IoMdInformationCircleOutline, IoIosRemoveCircleOutline } from "react-icons/io";
import Price from "../../common/price";
import Modal from "../../modal";
import { FaTimes } from "react-icons/fa";
import clone from "clone";
import { round } from "@common-ground-io/common-assets/helpers/utils.js";
import { useMutation } from "@apollo/client";
import { POST_ORDER_GENERATE_GIFT_CARD, POST_ORDER_LINE_MARK_UNAVAILABLE } from "../../../graphql/queries/order";
import { AddNotification } from "../../../types/globals";
import { Config, ConfigTaxDefinition, LineItem, Order, Shipping } from "../../../__generated__/graphql";
import { getPlaceholderImage } from "../../../utils";
import { useTranslation } from "react-i18next";
import { ModalHeaderContainer } from "../../../componentsV2/SectionHeader/SectionHeader.styles";
import { Typography } from "../../../componentsV2/Typography";

export default function Line({
  editable,
  overZone,
  addNotification,
  refetch,
  order,
  config,
  isDiscogs,
  item: lineToCopy,
  updateLine
}: {
  editable?: boolean;
  overZone?: boolean;
  addNotification: AddNotification;
  refetch?: any;
  order: Order;
  config: Config;
  isDiscogs?: boolean;
  item: LineItem;
  updateLine: any;
}) {
  const { t } = useTranslation();
  const taxes = config.taxes.definitions;
  const [line, setLine] = useState(clone(lineToCopy));
  const modalRef = useRef<any>();
  const [updateGenerateGiftCard] = useMutation(POST_ORDER_GENERATE_GIFT_CARD);
  const [markAsUnavailable] = useMutation(POST_ORDER_LINE_MARK_UNAVAILABLE);

  const availableEditable = !editable && !isDiscogs && !line.unavailable;

  const cancelAndRevert = () => {
    setLine(clone(lineToCopy));
    modalRef.current.close();
  };

  const quantityUpdate = (value: number) => {
    line.quantity = value;
    setLine(clone(line));
  };

  const priceBeforeTaxesUpdate = (value: number) => {
    line.price.beforeTaxes = value;
    setLine(clone(line));
  };

  const priceUpdate = (value: number) => {
    line.price.base = value;
    setLine(clone(line));
  };

  const handleRemoveLine = async () => {
    line.quantity = 0;
    await updateLine(line);
  };

  const handleAddTax = async () => {
    if (!taxes.length) return;
    const taxToAdd = taxes[0];
    line.price.taxes.push({ rate: taxToAdd.rate, name: taxToAdd.name });
    setLine(clone(line));
  };

  const handleRemoveTax = async (e: any, index: number) => {
    line.price.taxes.splice(index, 1);
    setLine(clone(line));
  };

  const handleTaxSelect = async (e: any, idx: number) => {
    const index = e.target.value;
    const tax = taxes[index];
    line.price.taxes[idx] = { rate: tax.rate, name: tax.name };
    setLine(clone(line));
  };

  const handleSubmitMark = async (e: any) => {
    e.preventDefault();

    try {
      const { data } = await markAsUnavailable({ variables: { orderRef: order._id, orderLineRef: line._id } });
      if (data?.orderLineMarkAsUnavailable) addNotification({ ok: 1, message: data.orderLineMarkAsUnavailable.message });
      refetch();
    } catch (error: any) {
      addNotification({ ok: 0, message: error.message });
    }
  };

  const handleAddDiscount = () => {
    if (!line.price.discounts) line.price.discounts = [];
    line.price.discounts.push({ type: "percentage", value: 0 });
    setLine(clone(line));
  };

  const handleRemoveDiscount = (e: any, idx: number) => {
    if (!line.price.discounts) line.price.discounts = [];
    line.price.discounts.splice(idx, 1);
    setLine(clone(line));
  };

  const handleDiscountUpdate = (e: any, idx: number) => {
    if (!line.price.discounts) line.price.discounts = [];
    line.price.discounts[idx].value = parseFloat(e.target.value);
    setLine(clone(line));
  };

  const submit = async (e: any) => {
    e.preventDefault();
    try {
      await updateLine(line);
      modalRef.current.close();
    } catch (e: any) {}
  };

  const handleGenerateGiftCard = async () => {
    try {
      await updateGenerateGiftCard({ variables: { orderRef: order._id, lineRef: line._id } });
      addNotification({ ok: 1, message: "Gift card generated" });
      refetch();
    } catch (error: any) {
      addNotification({ ok: 1, message: error.toString() });
    }
  };

  const displayPreTaxes = config.taxes.rules.editPricesBeforeTaxes;

  return (
    <div className={`item ${line.unavailable ? "unavailable " : ""}`}>
      <Modal ref={modalRef} style={{ minWidth: "40%" }}>
        {availableEditable ? (
          <div id="orderModal" className="markAsUnavailable">
            <ModalHeaderContainer>
              <Typography variant="pageTitle" tag="h2">
                {t("Mark item as unavailable")}
              </Typography>
              <button className="reset" type="button" onClick={() => modalRef.current.close()}>
                <i className="cg-icon-burger-close" />
              </button>
            </ModalHeaderContainer>
            <hr />
            <form onSubmit={handleSubmitMark}>
              <p>{line.item.description}</p>
              <hr />
              <p>{t("This item will be marked as unavailable for this order and will not show in the Sales Export for that listing.")}</p>
              <p>{t("Totals will remain unchanged but you may issue refunds.")}</p>
              <hr />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span />
                <ButtonV2 variant="primary" type="submit">
                  {t("Submit")}
                </ButtonV2>
              </div>
            </form>
          </div>
        ) : null}
        {!isDiscogs && editable ? (
          <div id="orderModal" className="editLine">
            <ModalHeaderContainer>
              <Typography variant="pageTitle" tag="h2">
                {t("Edit order item")}
              </Typography>
              <Button type="button" variant="danger" className="removeLine" onClick={handleRemoveLine}>
                {t("Remove")}
              </Button>
              <button className="reset" type="button" onClick={() => cancelAndRevert()}>
                <i className="cg-icon-burger-close" />
              </button>
            </ModalHeaderContainer>
            <hr />
            <div className="imageAndDescription">
              <Link to={line.item.path || ""}>
                <img src={line.item.thumbnail || ""} />
              </Link>
              <div className="description">
                <p className="main">{line.item.description}</p>
                {line.listing.location ? (
                  <p>
                    {t("Location")}: {line.listing.location}
                  </p>
                ) : null}
                {line.listing.supplierCode ? (
                  <p>
                    {t("Supplier")}: {line.listing.supplierCode}
                  </p>
                ) : null}
                {line.listing.options.map(o => (
                  <p key={o.name}>
                    {o.name} {"->"} {o.value}
                  </p>
                ))}
              </div>
            </div>

            <LineEditor
              config={config}
              line={line}
              taxes={taxes}
              quantityUpdate={quantityUpdate}
              handleDiscountUpdate={handleDiscountUpdate}
              submit={submit}
              handleAddDiscount={handleAddDiscount}
              handleRemoveDiscount={handleRemoveDiscount}
              handleTaxSelect={handleTaxSelect}
              handleRemoveTax={handleRemoveTax}
              priceUpdate={priceUpdate}
              priceBeforeTaxesUpdate={priceBeforeTaxesUpdate}
              handleAddTax={handleAddTax}
            />
          </div>
        ) : null}
      </Modal>
      <div className="image">
        <Link to={line.item.path || ""}>
          <img src={line.item.thumbnail || getPlaceholderImage(config)} />
        </Link>
      </div>
      <div className="description">
        {line.listing.options.length || line.listing.location ? (
          <Tooltip Icon={IoMdInformationCircleOutline} className="moreInfo">
            <>
              {line.listing.location ? (
                <p>
                  {t("Location")}: {line.listing.location}
                </p>
              ) : null}
              {line.listing.options.map(o => (
                <p key={o.name}>
                  {o.name} {"->"} {o.value}
                </p>
              ))}
            </>
          </Tooltip>
        ) : null}
        <div className="column">
          <Link to={line.item.path || ""}>
            <p>{line.item.description}</p>
          </Link>
          {line.giftCard ? (
            <div>
              {line.giftCard.generated ? (
                <div className="generated">
                  <p>
                    {t("Gift card")}: <Link to={`/vouchers?term=${line.giftCard.id}`}>{line.giftCard.id}</Link>
                  </p>
                </div>
              ) : (
                <div className="toGenerate">
                  <Button variant="secondary" onClick={handleGenerateGiftCard}>
                    {t("Generate gift card")}
                  </Button>
                </div>
              )}
            </div>
          ) : null}
          {line.listing.location ? (
            <small>
              {t("Location")}: {line.listing.location}
            </small>
          ) : null}
        </div>
      </div>
      <div className="quantity">
        <p className={line.quantity > 1 ? "moreThanOne" : ""}>{line.quantity}</p>
      </div>
      <div className="net">
        <p>
          <Price value={displayPreTaxes ? line.price.beforeTaxes : line.price.base} />
        </p>
        {!displayPreTaxes && line.price.base !== (displayPreTaxes ? line.listing.prices.beforeTaxes : line.listing.prices.sale) ? (
          <small>
            {t("Was")}: <Price value={(displayPreTaxes ? line.listing.prices.beforeTaxes : line.listing.prices.sale) as number} />
          </small>
        ) : null}
      </div>
      <div className="taxes">
        {line.price.taxes.map((t, i) => (
          <div key={i} className="tax">
            <p>
              {t.name} {t.rate}%
            </p>
          </div>
        ))}
      </div>
      <div className="discounts">
        {line.price.discounts?.map((d, i) => (
          <div key={i} className="tax">
            <p>{d.type === "percentage" ? `${d.value}%` : null}</p>
          </div>
        ))}
      </div>
      <div className="total">
        <p>
          <Price value={round(line.price.subtotal)} />
        </p>
      </div>
      <div className="action">
        {!isDiscogs && (editable || availableEditable) ? (
          <div className="markItem">
            <Button variant={`secondary${overZone ? "OverZone" : ""}`} onClick={() => modalRef.current.open()} type="button">
              {t("Edit")}{" "}
            </Button>
          </div>
        ) : (
          <span />
        )}
      </div>
    </div>
  );
}

const LineEditor = ({
  config,
  submit,
  line,
  taxes,
  handleTaxSelect,
  handleRemoveTax,
  handleAddDiscount,
  handleRemoveDiscount,
  handleDiscountUpdate,
  quantityUpdate,
  priceUpdate,
  priceBeforeTaxesUpdate,
  handleAddTax
}: {
  config: Config;
  submit: any;
  line: LineItem;
  taxes: ConfigTaxDefinition[];
  handleTaxSelect: any;
  handleRemoveTax: any;
  handleAddDiscount: any;
  handleRemoveDiscount: any;
  handleDiscountUpdate: any;
  quantityUpdate: any;
  priceUpdate: any;
  priceBeforeTaxesUpdate: any;
  handleAddTax: any;
}) => {
  const { t } = useTranslation();
  const displayPreTaxes = config.taxes.rules.editPricesBeforeTaxes;

  return (
    <form onSubmit={submit}>
      <div className="lineEditor">
        <div className="quantity">
          <Input
            label={t("Quantity")}
            min="0"
            max="9999"
            required
            type="number"
            name="quantity"
            value={line.quantity}
            onWheel={(e: any) => e.target.blur()}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => quantityUpdate(e.target.valueAsNumber)}
          />
        </div>
        {displayPreTaxes ? (
          <div className="net">
            <Input
              label={t("Price before sales taxes")}
              min="0"
              max="9999"
              step="0.001"
              required
              type="number"
              name="beforeTaxes"
              onWheel={(e: any) => e.target.blur()}
              value={round(line.price.beforeTaxes, 3)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => priceBeforeTaxesUpdate(e.target.valueAsNumber)}
            />
          </div>
        ) : (
          <div className="net">
            <Input
              label={t("Price incl. sales taxes")}
              min="0"
              max="9999"
              step="0.001"
              required
              type="number"
              name="base"
              onWheel={(e: any) => e.target.blur()}
              value={round(line.price.base, 3)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => priceUpdate(e.target.valueAsNumber)}
            />
          </div>
        )}
        <div className="discounts">
          {line.price.discounts?.map((d, i) => (
            <div key={i} className="discountEntry">
              <Input
                label={t("Discounts") + " %"}
                onChange={(e: any) => handleDiscountUpdate(e, i)}
                value={d.value}
                type="number"
                onWheel={(e: any) => e.target.blur()}
                placeholder="%"
                max="100"
                min="0"
                step="0.01"
              />
              <Button variant="noStyle" onClick={(e: any) => handleRemoveDiscount(e, i)} type="button">
                <IoIosRemoveCircleOutline />
              </Button>
            </div>
          ))}
          {!line.price.discounts?.length ? (
            <div className="add">
              <Button variant="secondary" onClick={handleAddDiscount} type="button">
                {t("Add a discount")}
              </Button>
            </div>
          ) : null}
        </div>
        <div className="taxes">
          <h3>{t("Taxes")}</h3>
          {line.price.taxes.map((t, i) => (
            <div key={i} className="taxEntry">
              <select
                value={taxes.findIndex(tax => tax.rate === t.rate && tax.name === t.name)}
                onChange={(e: any) => handleTaxSelect(e, i)}>
                {taxes.map((t, index) => (
                  <option key={index} value={index}>
                    {t.name} {t.rate}%
                  </option>
                ))}
              </select>
              <Button variant="noStyle" onClick={(e: any) => handleRemoveTax(e, i)} type="button">
                <IoIosRemoveCircleOutline />
              </Button>
            </div>
          ))}
          <div className="add">
            <Button variant="secondary" onClick={handleAddTax} type="button">
              {t("Add a tax")}
            </Button>
          </div>
        </div>
      </div>
      <div className="footer">
        <span />
        <ButtonV2 variant="primary" type="submit">
          {t("Submit")}
        </ButtonV2>
      </div>
    </form>
  );
};

export function ShippingLine({
  editable,
  line: lineToCopy,
  config,
  updateLine
}: {
  editable: boolean;
  line: Shipping;
  config: Config;
  updateLine: any;
}) {
  const [line, setLine] = useState(clone(lineToCopy));
  const taxes = config.taxes.definitions;
  const modalRef = useRef<any>();
  const displayPreTaxes = config.taxes.rules.editPricesBeforeTaxes;
  const { t } = useTranslation();

  const cancelAndRevert = () => {
    setLine(clone(lineToCopy));
    modalRef.current.close();
  };

  const priceBeforeTaxesUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    line.price.beforeTaxes = parseFloat(event.target.value);
    setLine(clone(line));
  };

  const handleAddTax = async () => {
    if (!taxes.length) return;
    const taxToAdd = taxes[0];
    line.price.taxes.push({ rate: taxToAdd.rate, name: taxToAdd.name });
    setLine(clone(line));
  };

  const handleRemoveTax = async (e: any, index: number) => {
    const lineToUpdate = clone(line);
    lineToUpdate.price.taxes.splice(index, 1);
    setLine({ ...lineToUpdate });
  };

  const handleTaxSelect = async (e: any, idx: number) => {
    const index = e.target.value;
    const tax = taxes[index];
    line.price.taxes[idx] = { rate: tax.rate, name: tax.name };
    setLine(clone(line));
  };

  const priceUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    line.price.base = event.target.valueAsNumber;
    setLine(clone(line));
  };

  const submitChanges = async (e: any) => {
    e.preventDefault();
    const order = await updateLine(line);
    if (order) {
      setLine(clone(order.shipping));
      modalRef.current.close();
    }
  };

  return (
    <div className="item">
      <Modal style={{}} ref={modalRef} onClose={cancelAndRevert}>
        <div id="orderModal" className="editLine">
          <div className="header">
            <div className="left">
              <h2>{t("Edit Shipping")}</h2>
            </div>
            <Button variant="noStyle" onClick={cancelAndRevert}>
              <FaTimes />
            </Button>
          </div>
          <hr />
          <ShippingLineEditor
            line={line}
            taxes={taxes}
            submit={submitChanges}
            handleTaxSelect={handleTaxSelect}
            handleRemoveTax={handleRemoveTax}
            priceUpdate={priceUpdate}
            priceBeforeTaxesUpdate={priceBeforeTaxesUpdate}
            handleAddTax={handleAddTax}
            displayPreTaxes={!!displayPreTaxes}
          />
        </div>
      </Modal>
      <div className="image">
        <i className="cg-icon-shipping" />
      </div>
      <div className="description">
        <p>{t("Shipping / Delivery")}</p>
      </div>
      <div className="quantity"></div>
      <div className="net">
        <Price value={displayPreTaxes ? line.price.beforeTaxes : line.price.base} />
      </div>

      <div className="taxes">
        {line.price.taxes.map((t, i) => (
          <div key={i} className="tax">
            <p>
              {" "}
              {t.name} {t.rate}%{" "}
            </p>
          </div>
        ))}
      </div>
      <div />
      <div className="total">
        <p>
          <Price value={line.price.base} />
        </p>
      </div>
      <div className="action">
        {editable ? (
          <Button variant="secondary" onClick={() => modalRef.current.open()} type="button">
            {t("Edit")}{" "}
          </Button>
        ) : (
          <span />
        )}
      </div>
    </div>
  );
}

const ShippingLineEditor = ({
  submit,
  line,
  taxes,
  handleTaxSelect,
  handleRemoveTax,
  priceBeforeTaxesUpdate,
  priceUpdate,
  handleAddTax,
  displayPreTaxes
}: {
  submit: any;
  line: Shipping;
  taxes: ConfigTaxDefinition[];
  handleTaxSelect: any;
  handleRemoveTax: any;
  priceBeforeTaxesUpdate: any;
  priceUpdate: any;
  handleAddTax: any;
  displayPreTaxes: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={submit}>
      <div className="lineEditor">
        {displayPreTaxes ? (
          <div className="net">
            <Input
              label={t("Price before sales taxes")}
              min="0"
              max="9999"
              step="0.001"
              required
              placeholder={t("Price before sales taxes") + "..."}
              type="number"
              onWheel={(e: any) => e.target.blur()}
              name="beforeTaxes"
              value={round(line.price.beforeTaxes, 3)}
              onChange={priceBeforeTaxesUpdate}
            />
          </div>
        ) : (
          <div className="net">
            <Input
              label={t("Price incl. sales taxes")}
              min="0"
              max="9999"
              step="0.001"
              placeholder={t("Price incl. sales taxes") + "..."}
              required
              type="number"
              name="base"
              onWheel={(e: any) => e.target.blur()}
              value={round(line.price.base, 3)}
              onChange={priceUpdate}
            />
          </div>
        )}
        <div className="taxes">
          <h3>{t("Taxes")}</h3>
          {line.price.taxes.map((t, i) => (
            <div key={i} className="taxEntry">
              <select
                value={taxes.findIndex(tax => tax.rate === t.rate && tax.name === t.name)}
                onChange={(e: any) => handleTaxSelect(e, i)}>
                {taxes.map((t, index) => (
                  <option key={index} value={index}>
                    {t.name} {t.rate}%
                  </option>
                ))}
              </select>
              <Button variant="noStyle" onClick={(e: any) => handleRemoveTax(e, i)} type="button">
                <IoIosRemoveCircleOutline />
              </Button>
            </div>
          ))}
          <div className="add">
            <Button variant="secondary" onClick={handleAddTax} type="button">
              {t("Add a tax")}
            </Button>
          </div>
        </div>
      </div>
      <div className="footer">
        <span />
        <ButtonV2 variant="primary" type="submit">
          {t("Submit")}
        </ButtonV2>
      </div>
    </form>
  );
};
