import { gql } from "../../__generated__/gql";

gql(`
  fragment CreditFields on Credit {
    _id
    id
    createdAt
    reference
    status
    title
    user {
      _id
      name
      organisation
      email
      telephone
      lastName
      firstName
      taxNumber
    }
    spendings {
      orderId
      orderRef
      amount
    }
    totals {
      grand
      subtotal
      subtotalBeforeTaxes
      shipping
      itemQuantity
      discount
      balance
      taxes {
        ...TaxFields
      }
    }
    items {
      ...LineItemFields
    }
    pdf
  }
`);

export const GET_CREDITS = gql(`
  query credits($status: String, $sortBy: String, $sortOrder: Int, $userRef: ID, $term: String) {
    credits(status: $status, sortBy: $sortBy, sortOrder: $sortOrder, userRef: $userRef, term: $term) {
      pagination {
        count
        pages
        page
      }
      credits {
        ...CreditFields
      }
    }
  }
`);

export const GET_CREDIT = gql(`
  query credit($creditRef: ID!) {
    credit(creditRef: $creditRef) {
      ...CreditFields
    }
  }
`);

export const POST_CREDIT_VOID = gql(`
  mutation creditVoid($creditRef: ID!) {
    creditVoid(creditRef: $creditRef) {
      ...CreditFields
    }
  }
`);

export const POST_CREDIT_PDF = gql(`
  mutation creditPdf($creditRef: ID!) {
    creditPdf(creditRef: $creditRef) {
      uri
      credit {
        ...CreditFields
      }
    }
  }
`);

export const POST_CREDIT_CREATE = gql(`
  mutation creditCreate($userRef: ID!, $items: [CreditCreateItemInput]!, $title: String!, $reference: String, $updateStock: Boolean) {
    creditCreate(userRef: $userRef, items: $items, title: $title, reference: $reference, updateStock: $updateStock) {
      ...CreditFields
    }
  }
`);
