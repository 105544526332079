import { Store } from "pullstate";

interface StoreTypes {
  loadTrack: any;
  stopAndClose: any;
  playPauseTrack: any;
  playedTrackData: any;
  isPlaying: any;
  isShowing: any;
  wavesurfer: any;
}

export const PlayerStore = new Store<StoreTypes>({
  loadTrack: null,
  stopAndClose: null,
  playPauseTrack: null,
  playedTrackData: null,
  isPlaying: null,
  isShowing: null,
  wavesurfer: null
});
