import { gql } from "../../__generated__/gql";

gql(`
  fragment SupportConversationFields on SupportConversation {
    id
    state
    createdAt
    source {
      body
      subject
      author {
        id
        name
        type
      }
    }
    parts {
      id
      createdAt
      body
      attachments {
        id
        url
        mimetype
        title
      }
      author {
        id
        name
        type
      }
    }
  }
`);

export const GET_SUPPORT_CONVERSATIONS = gql(`
  query supportConversations {
    supportConversations {
      conversations {
        id
        createdAt
        state
        source {
          body
        }
      }
      count
    }
  }
`);

export const GET_SUPPORT_CONVERSATION = gql(`
  query supportConversation($conversationId: String!) {
    supportConversation(conversationId: $conversationId) {
      ...SupportConversationFields
    }
  }
`);

export const GET_SUPPORT_CONVERSATION_SIGNED_URL = gql(`
  query supportConversationSignedUrl($conversationId: String!, $filename: String!) {
    supportConversationSignedUrl(conversationId: $conversationId, filename: $filename) {
      url
      key
    }
  }
`);

export const POST_SUPPORT_CONVERSATION_REPLY = gql(`
  mutation supportConversationReply($conversationId: String!, $content: String!, $attachments: [String!]) {
    supportConversationReply(conversationId: $conversationId, content: $content, attachments: $attachments) {
      ...SupportConversationFields
    }
  }
`);

export const POST_SUPPORT_CONVERSATION_CREATE = gql(`
  mutation supportConversationCreate($content: String!) {
    supportConversationCreate(content: $content)
  }
`);

export const POST_SUPPORT_CONVERSATION_CLOSE = gql(`
  mutation supportConversationClose($conversationId: String!) {
    supportConversationClose(conversationId: $conversationId) {
      ...SupportConversationFields
    }
  }
`);
