import React from "react";
import { StyledMainWrapper } from "../../Inventory/InventoryStatistics/InventoryStatistics.styles";
import { Statistic } from "../../../Statistic";
import { Button } from "../../../Button";
import Loader from "../../../../components/common/loader";

type Props = {
  customers?: number | null;
  isLoading: boolean;
  handleExport: () => void;
};

export const CustomerStatistics: React.FC<Props> = ({ customers, isLoading, handleExport }) => {
  return (
    <StyledMainWrapper>
      <Statistic copy={`Customer${(customers ?? 0) > 1 ? "s" : ""}`} count={customers ?? 0} />

      <Button disabled={isLoading} onClick={handleExport} variant="primary">
        {isLoading ? <Loader size={6} /> : "Export customers"}
      </Button>
    </StyledMainWrapper>
  );
};
