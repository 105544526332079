import React, { useRef, useState } from "react";
import Button from "../styled/button";
import { Button as ButtonV2 } from "../../componentsV2/Button";
import Zone from "../styled/zone";
import SearchBySubmit from "../styled/searchBySubmit";
import Input from "../styled/input";
import InputWithSubmit from "../styled/inputWithSubmit";
import InputWithIcon from "../styled/inputWithIcon";
import Checkbox from "../styled/checkbox";
import CapsuleGroup from "../styled/capsule";
import { Link, useLocation, useHistory } from "react-router-dom";
import Loader from "../common/loader";
import { Select } from "../styled/select";
import Liner from "../common/liner";
import Price, { PriceInput } from "../common/price";
import DatePicker from "../styled/datePicker";
import { SearchUsers } from "../global/search";
import moment from "moment";
import URI from "urijs";
import Pagination from "../common/pagination";
import { GlobalStore } from "../../stores/global";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_VOUCHERS,
  POST_VOUCHER_CREATE,
  POST_VOUCHER_DELETE,
  POST_VOUCHER_SEND_RECEIPT,
  POST_VOUCHER_DEACTIVATE
} from "../../graphql/queries/voucher";
import { Buyer } from "../../__generated__/graphql";
import { Typography } from "../../componentsV2/Typography";
import Modal from "../modal";
import { useTranslation } from "react-i18next";
import { ModalHeaderContainer } from "../../componentsV2/SectionHeader/SectionHeader.styles";

export default function Vouchers() {
  const location = useLocation();
  const history = useHistory();
  const currentUri = new URI(location.pathname + location.search);
  const searchQuery = currentUri.search(true);
  const { config, addNotification, isMobile } = GlobalStore.useState(c => c);
  const [voucherSendReceipt] = useMutation(POST_VOUCHER_SEND_RECEIPT);
  const [createVouchers] = useMutation(POST_VOUCHER_CREATE);
  const [deleteVoucher] = useMutation(POST_VOUCHER_DELETE);
  const [deactivateVoucher] = useMutation(POST_VOUCHER_DEACTIVATE);
  const [isLoading, setIsLoading] = useState(false);
  const modalRef = useRef<any>();
  const { t } = useTranslation();

  document.title = "Vouchers";

  const filters = {
    status: searchQuery.status || localStorage.getItem(`${config?.id}-vouchers-status`) || "All",
    sortBy: searchQuery.sortBy || localStorage.getItem(`${config?.id}-vouchers-sortBy`) || "created",
    sortOrder: parseInt(localStorage.getItem(`${config?.id}-vouchers-sortOrder`) || searchQuery.sortOrder || -1),
    type: searchQuery.type || localStorage.getItem(`${config?.id}-vouchers-type`) || "All",
    expired: searchQuery.expired || localStorage.getItem(`${config?.id}-vouchers-expired`) || "All",
    term: searchQuery.term,
    page: parseInt(searchQuery.page || 1)
  };
  const [generator, setGenerator] = useState<any>({
    value: "",
    type: "coupon",
    amount: 1,
    redeemType: "value",
    expires: "",
    codeLength: "6",
    id: "",
    client: "",
    singleSpending: false
  });

  const { data, refetch } = useQuery(GET_VOUCHERS, { fetchPolicy: "cache-and-network", variables: filters });
  const page = data?.vouchers;

  const handleCapsuleSelect = async (value: any, name: string) => {
    localStorage.setItem(`${config?.id}-vouchers-${name}`, value);
    currentUri.setSearch({ [name]: value }).removeSearch("page");
    history.push(currentUri.resource());
  };

  const handleGeneratorGenericChange = (e: any) => {
    generator[e.target.name] = e.target.value;
    setGenerator({ ...generator });
  };

  const handleGeneratorBooleanGenericChange = (e: any) => {
    generator[e.target.name] = e.target.checked;
    setGenerator({ ...generator });
  };

  const handleGeneratorDateChange = (e: any) => {
    generator.expires = new Date(e);
    setGenerator({ ...generator });
  };

  const handleGeneratorTypeChange = (option: any) => {
    generator.type = option.value;
    generator.unlimited = false;
    if (generator.type === "giftCard") {
      generator.unlimited = false;
      generator.redeemType = "value";
      generator.value = "";
      generator.amount = 1;
    }
    setGenerator({ ...generator });
  };

  const handleGeneratorRedeemTypeChange = (option: any) => {
    generator.redeemType = option.value;
    if (generator.type === "giftCard") {
      generator.unlimited = false;
      generator.redeemType = "value";
      generator.value = "";
      generator.amount = 1;
      generator.id = "";
    }
    setGenerator({ ...generator });
  };

  const handleSelectClient = (buyer: Buyer, e: any) => {
    e.preventDefault();
    generator.user = buyer;
    generator.userRef = buyer._id;
    setGenerator({ ...generator });
  };

  const handleRemoveSearchFilter = async () => {
    currentUri.removeSearch("term").removeSearch("page");
    history.push(currentUri.resource());
  };

  const handleGeneratorCheckboxChange = (e: any) => {
    generator[e.target.name] = e.target.checked;
    setGenerator({ ...generator });
  };

  const handleCodeLengthChange = (e: any) => {
    generator.codeLength = e.target.value;
    setGenerator({ ...generator });
  };

  const handleSearchSubmit = async (term: string) => {
    currentUri.setSearch({ term }).removeSearch("page");
    history.push(currentUri.resource());
  };

  const handleCreateVouchers = async (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    createVouchers({
      variables: {
        ...generator,
        expires: moment(generator.expires).isValid() ? moment(generator.expires).format() : null,
        amount: parseInt(generator.amount) || 1,
        value: parseFloat(generator.value),
        codeLength: parseInt(generator.codeLength)
      }
    })
      .then(() => {
        addNotification({ ok: 1, message: "Vouchers created" });
        refetch();
        setGenerator({ ...generator, amount: generator.type === "giftCard" ? 1 : "", value: "" });
        modalRef.current.close();
      })
      .catch(err => addNotification({ ok: 0, message: err.message }))
      .finally(() => setIsLoading(false));
  };

  const handleDeleteVoucher = async (ref: any) => {
    deleteVoucher({ variables: { voucherRef: ref } })
      .then(() => {
        addNotification({ ok: 1, message: "Voucher deleted" });
        refetch();
      })
      .catch(err => addNotification({ ok: 0, message: err.message }));
  };

  const handleDeactivateVoucher = async (ref: any) => {
    deactivateVoucher({ variables: { voucherRef: ref } })
      .then(() => {
        addNotification({ ok: 1, message: "Voucher deactivated" });
        refetch();
      })
      .catch(err => addNotification({ ok: 0, message: err.message }));
  };

  const handleSendGiftCardReceipt = async (e: any, voucherRef: string) => {
    e.preventDefault();
    try {
      await voucherSendReceipt({ variables: { voucherRef, recipient: e.target.gcemail.value } });
      refetch();
      addNotification({ ok: 1, message: "Voucher email receipt was sent" });
    } catch (error: any) {
      addNotification({ ok: 0, message: error.message });
    }
  };

  const redeemTypes = [
    { value: "value", label: "Amount in your currency" },
    { value: "percentage", label: "Percentage" }
  ].filter(e => (generator.type === "giftCard" ? e.value === "value" : e));
  const types = [
    { value: "coupon", label: "Coupon discount" },
    { value: "giftCard", label: "Gift card / payment method" }
  ];

  const isGeneratorGiftCard = generator.type === "giftCard";
  return (
    <div id="vouchers">
      <Modal style={{}} ref={modalRef}>
        <div id="creditNoteCreate">
          <ModalHeaderContainer>
            <Typography variant="pageTitle" tag="h2">
              {t("Create vouchers")}
            </Typography>
            <button className="reset" type="button" onClick={() => modalRef.current.close()}>
              <i className="cg-icon-burger-close" />
            </button>
          </ModalHeaderContainer>
          <form onSubmit={handleCreateVouchers} id="voucherGenerator">
            <Select
              variant="overZone"
              label={t("Redeem type")}
              onChange={handleGeneratorTypeChange}
              options={types}
              value={{ label: types.find(t => t.value === generator.type)?.label, value: generator.type }}
            />
            <div className="value">
              <Select
                variant="overZone"
                label={t("Redeem value type")}
                onChange={handleGeneratorRedeemTypeChange}
                options={redeemTypes}
                value={{ label: redeemTypes.find(t => t.value === generator.redeemType)?.label, value: generator.redeemType }}
              />
              {generator.redeemType === "percentage" ? (
                <InputWithIcon
                  label={t("Voucher value")}
                  onChange={handleGeneratorGenericChange}
                  required
                  autoComplete="off"
                  variant="overZone"
                  icon="%"
                  iconPosition="right"
                  name="value"
                  step="0.01"
                  min="0"
                  max="100"
                  type="number"
                  value={generator.value}
                />
              ) : (
                <PriceInput
                  required
                  variant="overZone"
                  label={t("Voucher value")}
                  name="value"
                  step="0.01"
                  onChange={handleGeneratorGenericChange}
                  placeholder={t("Amount")}
                  min="0"
                  value={generator.value}
                />
              )}
            </div>
            <div className="value">
              <Input
                variant="overZone"
                label={t("Quantity to generate")}
                name="amount"
                disabled={isGeneratorGiftCard}
                onChange={handleGeneratorGenericChange}
                type="number"
                min="1"
                max="100"
                autoComplete="off"
                value={generator.amount}
              />
              <DatePicker
                variant="overZone"
                label={t("Set optional expiry date")}
                onChange={handleGeneratorDateChange}
                value={generator.expires}
                dateFormat="dd/MM/yyyy"
              />
            </div>
            <div className="value">
              {isGeneratorGiftCard ? (
                <>
                  <Input
                    label={t("Gift card code prefix (optional)")}
                    variant="overZone"
                    placeholder={t("Gift card prefix") + "..."}
                    onChange={handleGeneratorGenericChange}
                    type="text"
                    pattern="[a-zA-Z0-9-_]+"
                    name="prefix"
                    value={generator.prefix || ""}
                  />
                  <Input
                    label={t("Code length (min. 6)")}
                    variant="overZone"
                    type="number"
                    min="6"
                    max="10"
                    placeholder={t("Enter a number from 6 to 10") + "..."}
                    name="codeLength"
                    onChange={handleCodeLengthChange}
                    value={generator.codeLength || ""}
                  />
                </>
              ) : (
                <>
                  <Input
                    label={t("Voucher code prefix (optional)")}
                    placeholder={t("Voucher ID") + "..."}
                    variant="overZone"
                    onChange={handleGeneratorGenericChange}
                    type="text"
                    disabled={isGeneratorGiftCard}
                    name="id"
                    value={generator.id}
                  />
                </>
              )}
            </div>
            <div className="value">
              <SearchUsers variant="overZone" label="Assign a user" onSelect={handleSelectClient} />
              <Input
                label={t("Note")}
                variant="overZone"
                placeholder={t("Note") + "..."}
                onChange={handleGeneratorGenericChange}
                type="text"
                name="note"
                value={generator.note || ""}
              />
              {generator.user ? (
                <p>
                  {t("Selected customer")}: {generator.user.name}{" "}
                </p>
              ) : null}
            </div>
            {isGeneratorGiftCard ? (
              <Checkbox
                label={t("Single spending / no balance: the gift card may only be used once.")}
                name="singleSpending"
                onChange={handleGeneratorBooleanGenericChange}
                checked={generator.singleSpending}
              />
            ) : null}
            {generator.redeemType === "percentage" ? (
              <Checkbox
                label={t("Unlimited balance, this voucher will stay active and may be used multiple times")}
                name="unlimited"
                checked={generator.unlimited}
                onChange={handleGeneratorCheckboxChange}
              />
            ) : null}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <span />
              <ButtonV2 disabled={isLoading || !generator.value} type="submit" variant="primary">
                {isLoading ? <Loader /> : t("Submit")}
              </ButtonV2>
            </div>
          </form>
        </div>
      </Modal>
      <section className="header">
        <div className="left" style={{ gap: "var(--gutter)" }}>
          <Typography variant="pageTitle" tag="h1">
            {t("Vouchers")}
          </Typography>
          <div className="search">
            <SearchBySubmit
              type="text"
              term={filters.term}
              autoComplete="off"
              name="term"
              variant="overZone"
              onSubmit={handleSearchSubmit}
              onClear={handleRemoveSearchFilter}
              placeholder={t("Filter vouchers") + "..."}
            />
          </div>
        </div>
        <div className="right">
          <ButtonV2 variant="primary" type="button" onClick={() => modalRef.current.open()}>
            {t("Create vouchers")}
          </ButtonV2>
        </div>
      </section>
      <Zone id="vouchersFilters">
        <div className="column">
          <div id="status" className="column-item">
            <p>{t("Status")}</p>
            <CapsuleGroup
              variant="overZone"
              name="status"
              value={filters.status}
              onClick={handleCapsuleSelect}
              entries={[
                { label: t("All"), value: "All" },
                { label: t("Redeemed"), value: "redeemed" },
                { label: t("Active"), value: "active" }
              ]}
            />
          </div>
          <div id="expiry" className="column-item">
            <p>{t("Expires")}</p>
            <CapsuleGroup
              variant="overZone"
              name="expired"
              value={filters.expired}
              onClick={handleCapsuleSelect}
              entries={[
                { label: t("All"), value: "All" },
                { label: t("Expired"), value: "expired" },
                { label: t("Non-expired"), value: "nonexpired" }
              ]}
            />
          </div>
          <div id="type" className="column-item">
            <p>{t("Type")}</p>
            <CapsuleGroup
              variant="overZone"
              name="type"
              value={filters.type}
              onClick={handleCapsuleSelect}
              entries={[
                { label: t("All"), value: "All" },
                { label: t("Gift cards"), value: "giftCard" },
                { label: t("Coupons"), value: "coupon" }
              ]}
            />
          </div>
        </div>
        <div className="column">
          <div id="sort" className="column-item">
            <p>{t("Sort by")}</p>
            <CapsuleGroup
              isMobile={isMobile}
              variant="overZone"
              name="sortBy"
              value={filters.sortBy}
              onClick={handleCapsuleSelect}
              entries={[
                { label: t("Balance"), value: "value" },
                { label: t("Original value"), value: "originalValue" },
                { label: t("Created"), value: "created" },
                { label: t("Expiry date"), value: "expires" }
              ]}
            />
          </div>
          <div id="order" className="column-item">
            <p>{t("Order by")}</p>
            <CapsuleGroup
              variant="overZone"
              name="sortOrder"
              value={filters.sortOrder}
              onClick={handleCapsuleSelect}
              entries={[
                { label: t("Ascending"), value: -1 },
                { label: t("Descending"), value: 1 }
              ]}
            />
          </div>
        </div>
      </Zone>
      {page ? (
        <>
          <Pagination pagination={page.pagination} currentUri={currentUri} />
          <section id="vouchersTable">
            <div className="voucherEntry header">
              {/* eslint-disable-next-line i18next/no-literal-string */}
              <p># / {t("Type")}</p>
              <p>{t("Original value")}</p>
              <p className="value">{t("Balance")}</p>
              <p className="created">{t("Created")}</p>
              <p>{t("Expires")}</p>
              <p>{t("User")}</p>
              <p>{t("Spendings")}</p>
              <p>{t("Action")}</p>
            </div>
            {page.vouchers.map((v, index) => (
              <Liner index={index} key={v._id} className={"voucherEntry"}>
                <div>
                  <p>
                    {v.id} / {v.type === "giftCard" ? t("Gift card") : t("Coupon")}
                    <br />
                    {v.redeemed ? t("Redeemed") : t("Active")}
                    {v.note ? (
                      <strong>
                        <br />
                        {v.note.content}
                      </strong>
                    ) : null}
                    <br />
                    {v.resourcePath ? <Link to={v.resourcePath}>{t("Go to linked resource")}</Link> : null}
                    <br />
                  </p>
                  {v.type === "giftCard" ? (
                    <div>
                      {v.recipients && v.recipients.length ? (
                        <p>
                          {t("Email receipt sent to")} {v.recipients.map(r => r.recipient)}
                        </p>
                      ) : (
                        <div className="emailReceipt">
                          <form onSubmit={(e: any) => handleSendGiftCardReceipt(e, v._id)}>
                            <InputWithSubmit
                              variant={!(index % 2) && "overZone"}
                              label={t("Send email receipt")}
                              submit="Send receipt"
                              type="email"
                              name="gcemail"
                              placeholder={t("Recipient email address")}
                              required
                              defaultValue=""
                            />
                          </form>
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>
                <p>
                  {v.originalValue}
                  {v.redeemType === "value" ? config?.currencySymbol : "%"}
                  {v.unlimited ? (
                    <>
                      <br />
                      {t("Unlimited")}
                    </>
                  ) : (
                    ""
                  )}
                </p>
                <p className="value">{v.redeemType === "value" ? <Price value={v.value} /> : ""}</p>
                <p className="created">{moment(v.created).format("DD/MM/YY")}</p>
                <p className={`${v.expires && moment(v.expires).isBefore(moment()) ? t("Expired") : ""}`}>
                  {v.expires ? moment(v.expires).format("DD/MM/YY") : ""}
                </p>
                <p>{v.user ? <Link to={`/user/${v.user.ref}`}>{v.user.name}</Link> : ""}</p>
                <div className="metadata">
                  {v?.spendings?.map((m, idx) => (
                    <div key={idx} className="meta">
                      {m.orderId ? (
                        <Link to={`/order/${m.orderId}`}>
                          #{m.orderId} {"->"} {m.amount !== "All" ? <Price value={parseFloat(m.amount || "")} /> : `${m.amount}`}
                        </Link>
                      ) : (
                        <p>
                          {t("Spending")} {"->"} {m.amount !== "All" ? <Price value={parseFloat(m.amount || "")} /> : `${m.amount}`}
                        </p>
                      )}
                    </div>
                  ))}
                  {v.singleSpending ? (
                    <span>
                      <br />
                      {t("Single spending")}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="buttons">
                  {!v.spendings?.length && !v.resourcePath && !v.recipients?.length ? (
                    <Button
                      variant="danger"
                      onClick={() => handleDeleteVoucher(v._id)}
                      disabled={!!(v.originalValue !== v.value || v.redeemed === true || v.resourcePath)}>
                      {t("Delete")}
                    </Button>
                  ) : null}
                  {!v.redeemed ? (
                    <Button variant="danger" onClick={() => window.confirm(t("Are you sure?")) && handleDeactivateVoucher(v._id)}>
                      {t("Deactivate")}
                    </Button>
                  ) : null}
                </div>
              </Liner>
            ))}
          </section>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}
