import React from "react";
import { Link } from "react-router-dom";
import { PlayerStore } from "../../stores/player";

export default function Player() {
  const { playPauseTrack, playedTrackData, isPlaying, stopAndClose, isShowing } = PlayerStore.useState(c => c);

  const image = playedTrackData?.release?.data?.images[0]?.uri;
  return (
    <div id="player" className={`${playedTrackData && isShowing ? "" : "hidden"}`}>
      <div id="playerContent">
        {playedTrackData && playedTrackData.release ? (
          <div id="left">
            <div id="artwork">{image ? <img alt={playedTrackData.release.descriptions.main} src={image} /> : null}</div>
            <div id="playerTitle">
              <Link to={playedTrackData.release.path}>
                {playedTrackData.release.data.artists[0].anv || playedTrackData.release.data.artists[0].name} |{" "}
                {playedTrackData.track.position} - {playedTrackData.track.title}
              </Link>
            </div>
          </div>
        ) : null}
        <div id="waveform"></div>
        <div id="right">
          {playedTrackData ? (
            <>
              <button type="button" onClick={playPauseTrack}>
                {isPlaying ? <i className="cg-icon-player-pause" /> : <i className="cg-icon-player-play" />}
              </button>
              <button type="button" onClick={stopAndClose}>
                <i className="cg-icon-burger-close" />
              </button>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}
