import React from "react";
import styled from "styled-components";
import colors from "@common-ground-io/common-assets/assets/colors.json";

const layout = {
  light: {
    primary: {
      color: colors.greyDark
    },
    secondary: {
      color: colors.greyLight
    }
  },
  dark: {
    primary: {
      color: colors.greyLight
    },
    secondary: {
      color: colors.grey
    }
  }
};

const StyledText = styled.span<{ variant?: string }>`
  color: ${props => path(layout, props).color};
`;

const computeVariant = (variant: any, layout: any) => (layout.light[variant] ? variant : "primary");
const path = (layout: any, props: any) => layout[props.theme.name || "light"][props.variant];

const TextComponent = (props: any) => {
  const variant = computeVariant(props.variant, layout);

  return (
    <StyledText className={`cg-common cg-text ${props.className}`} variant={variant}>
      {props.children}
    </StyledText>
  );
};

export default TextComponent;
