import { useCallback, useEffect } from "react";
import axios from "axios";
import { GlobalStore } from "../stores/global";
import * as Sentry from "@sentry/react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_SESSION, POST_SESSION_LOGIN, POST_SESSION_LOGOUT, POST_SSO_SIGNIN } from "../graphql/queries/session";
import { POST_USER_ADMIN_CREATE, POST_USER_ADMIN_VERIFY } from "../graphql/queries/user";
import clone from "clone";
import * as amplitude from "@amplitude/analytics-browser";
import { Session } from "../__generated__/graphql";
import { getSessionJWTForDomain, removeSessionJWTForDomain, writeSessionJWTForDomain } from "../utils";

export default () => {
  const session = GlobalStore.useState(c => c.session);

  const [getSession] = useLazyQuery(GET_SESSION, { fetchPolicy: "cache-and-network" });
  const [loginWithPassword] = useMutation(POST_SESSION_LOGIN);
  const [logout] = useMutation(POST_SESSION_LOGOUT);
  const [loginWithSSO] = useMutation(POST_SSO_SIGNIN);
  const [adminCreate] = useMutation(POST_USER_ADMIN_CREATE);
  const [adminVerify] = useMutation(POST_USER_ADMIN_VERIFY);

  const setSession = useCallback((session: Session | null) => {
    GlobalStore.update(s => {
      if (session === null) {
        s.session = null;
        s.config = null;
        return;
      }
      s.session = clone(session);
      s.config = undefined;
    });
  }, []);

  useEffect(() => {
    axios.interceptors.response.use(
      res => res,
      error => {
        if (error.response && error.response.status === 403) {
          removeSessionJWTForDomain();
          setSession(null);
        }
        return Promise.reject(error.response || { ok: 0, data: error.toString() });
      }
    );
    const jwt = getSessionJWTForDomain();
    if (jwt) {
      getSession({ variables: { jwt } })
        .then(({ data }) => {
          if (!data?.session) removeSessionJWTForDomain();
          setSession(clone(data?.session || null));
        })
        .catch(() => {
          removeSessionJWTForDomain();
          setSession(null);
        });
    } else setSession(null);
  }, [setSession]);

  const handleLogin = useCallback(
    async (loginData: any) => {
      const { data } = await loginWithPassword({ variables: { ...loginData } });
      if (data && data.sessionLogin) {
        writeSessionJWTForDomain(data.sessionLogin.jwt);
        setSession(data.sessionLogin);
        Sentry.setUser({
          id: data.sessionLogin.user._id,
          username: data.sessionLogin.user.name as string,
          email: data.sessionLogin.user.email
        });
        amplitude.setUserId(data.sessionLogin.user._id);
        amplitude.track("Login with password");
        return data.sessionLogin;
      } else {
        Sentry.configureScope(scope => scope.setUser(null));
        throw new Error("You are not allowed here");
      }
    },
    [setSession]
  );

  const handleSSOLogin = useCallback(
    async (ssoData: any) => {
      const { data } = await loginWithSSO({ variables: { ...ssoData } });
      if (data && data.session) {
        writeSessionJWTForDomain(data.session.jwt);
        setSession(data.session);
        Sentry.setUser({ id: data.session.user._id, username: data.session.user.name as string, email: data.session.user.email });
        amplitude.setUserId(data.session.user._id);
        amplitude.track("Login with SSO");
        return data;
      }
    },
    [setSession]
  );

  const handleLogout = useCallback(async () => {
    await logout();
    removeSessionJWTForDomain();
    setSession(null);
    amplitude.reset();
  }, [setSession]);

  const handleAdminCreate = useCallback(
    async (payload: any) => {
      const { data } = await adminCreate({ variables: payload });
      if (data && data.userAdminCreate) {
        writeSessionJWTForDomain(data.userAdminCreate.session.jwt);
        setSession(data.userAdminCreate.session);
        Sentry.setUser({
          id: data.userAdminCreate.user._id,
          username: data.userAdminCreate.user.name as string,
          email: data.userAdminCreate.user.email
        });
        amplitude.setUserId(data.userAdminCreate.user._id);
        return data;
      }
    },
    [setSession]
  );

  const handleAdminVerify = useCallback(
    async (payload: any) => {
      const { data } = await adminVerify({ variables: payload });
      if (data && data.userAdminVerify) {
        GlobalStore.update(s => {
          // @ts-ignore
          s.session.user = clone(data.userAdminVerify); // why?
        });
        return data;
      }
    },
    [setSession, session]
  );

  useEffect(() => {
    GlobalStore.update(s => {
      s.login = handleLogin;
      s.loginWithSSO = handleSSOLogin;
      s.logout = handleLogout;
      s.adminCreate = handleAdminCreate;
      s.adminVerify = handleAdminVerify;
    });
  }, [handleLogin, handleLogout, handleSSOLogin]);

  return null;
};
