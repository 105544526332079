import React, { useEffect, useRef, useState } from "react";
import Input from "../../../components/styled/input";
import { useTranslation } from "react-i18next";
import ModalComponent from "../../../components/modal";
import { Typography } from "../../../componentsV2/Typography";
import { Button } from "../../../componentsV2/Button";
import { GlobalStore } from "../../../stores/global";
import { AddItemStore } from "../../../stores/AddItem";
import { Config, ItemType, ReleaseLabel } from "../../../__generated__/graphql";
import ZoneComponent from "../../../components/styled/zone";
import clone from "clone";
import { EThemes } from "../../../types/globals";
import { Select, SelectCreatable } from "../../../components/styled/select";
import DatePicker from "../../../components/styled/datePicker";
import {
  FormatEditor,
  DescriptionEditor,
  TracklistEditor,
  IdentifiersEditor,
  getDefaultListingData
} from "../../../components/items/inputs";
import moment from "moment";
import { ArtistEntries, LabelEntries } from "../../../components/items/edit.release";
import { GET_CONFIG_METADATA_RELEASES } from "../../../graphql/queries/config";
import Liner from "../../../components/common/liner";
import styled from "styled-components";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Zoom from "react-medium-image-zoom";
import { ReleaseAdd } from "../../../components/items/add";
import { useMutation, useQuery } from "@apollo/client";
import ListingComponent from "../Listing/AddListing";
import { POST_ITEM_CREATE } from "../../../graphql/queries/item";
import { useHistory } from "react-router-dom";
import { convertListingForGraphQL } from "../../../utils";
import { GET_RELEASE } from "../../../graphql-creators/queries/release";

const { getData, getName, getCode } = require("country-list");

interface MarginProps {
  withMargin?: boolean;
}

const TwoColumnGrid = styled.div<MarginProps>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--gutter);
  margin-bottom: ${p => (p.withMargin ? "var(--gutter)" : "0")};
`;

const Section = styled.section<MarginProps>`
  margin-bottom: ${p => (p.withMargin ? "calc(var(--gutter)*2)" : "0")};
`;

const AddRelease = () => {
  const { config, addNotification } = GlobalStore.useState(c => c) as {
    config: Config;
    addNotification: any;
    isMobile: boolean;
    theme: EThemes;
    isBelowIpad: boolean;
  };
  const history = useHistory();
  const release = AddItemStore.useState(c => c.release);
  const listings = AddItemStore.useState(c => c.listings);
  const [dirty, setDirty] = useState(true);
  const { t } = useTranslation();
  const { data: metadata } = useQuery(GET_CONFIG_METADATA_RELEASES, { fetchPolicy: "no-cache" });
  const [createItem, { loading }] = useMutation(POST_ITEM_CREATE);
  const { data } = useQuery(GET_RELEASE, { variables: { discogsId: 1 }, context: { clientName: "creators" } });
  const modalRef = useRef<any>();
  const [index, setIndex] = useState(0);

  console.log(data?.release);

  const token = config.discogs.token;
  const accessData = config.discogs.accessData;
  const hasDiscogsToken = !!(token || accessData);
  const isNew = true;

  useEffect(() => {
    if (release && !listings.length) {
      AddItemStore.update(s => {
        s.listings = [getDefaultListingData(config as Config, ItemType.ReleaseItem)];
      });
    }
  }, [release]);

  const handleGenericInputUpdate = (name: string, value: string) => {
    setDirty(true);
    AddItemStore.update(s => {
      // @ts-ignore
      s.release.data[name] = value;
    });
  };

  const handleSubmitItem = async () => {
    try {
      const { data } = await createItem({
        variables: {
          type: "ReleaseItem",
          listings: listings.map(l => convertListingForGraphQL(l)),
          itemCreateReleaseInput: {
            title: release.data.title,
            images: release.data.images.map(i => ({ uri: i.uri })),
            artists: release.data.artists.map(a => ({ id: a.id, join: a.join, name: a.name, role: a.role })),
            assetLink: release.data.assetLink,
            country: release.data.country,
            discogsId: release.data.discogsId,
            formats: release.data.formats.map(f => ({ descriptions: f.descriptions, name: f.name, qty: f.qty })),
            genres: release.data.genres,
            identifiers: release.data.identifiers.map(i => ({ type: i.type, value: i.value })),
            labels: release.data.labels.map(l => ({ name: l.name, id: l.id, catno: l.catno })),
            releaseDate: release.data.releaseDate,
            styles: release.data.styles,
            tracklist: release.data.tracklist.map(t => ({
              type_: t.type_,
              position: t.position,
              artists: t.artists?.map(a => ({ id: a.id, join: a.join, name: a.name, role: a.role })) || [],
              title: t.title
            })),
            weight: release.data.weight
          }
        }
      });
      if (data?.itemCreate) {
        history.push(data.itemCreate.path);
        handleClear();
        AddItemStore.update(s => {
          s.drafts.release = false;
        });
      }
    } catch (error: any) {
      addNotification({ ok: 0, message: error.message });
    }
  };

  const handleCountryChange = (country: string) => {
    setDirty(true);
    AddItemStore.update(s => {
      s.release.data.country = getName(country);
    });
  };

  const handleStyleChange = (entries: { value: string }[]) => {
    AddItemStore.update(s => {
      s.release.data.styles = entries.map(e => e.value);
    });
  };

  const handleGenreChange = (entries: { value: string }[]) => {
    AddItemStore.update(s => {
      s.release.data.genres = entries.map(e => e.value);
    });
  };

  const handleAvailableChange = (e: any) => {
    setDirty(true);
    AddItemStore.update(s => {
      s.release.data.releaseDate = moment(e).format();
    });
  };

  const handleAddArtist = (artistObj: any) => {
    setDirty(true);
    AddItemStore.update(s => {
      s.release.data.artists.push({ name: artistObj.title, id: artistObj.id || new Date().getTime() });
    });
  };

  const handleRemoveArtist = (obj: { name: string }) => {
    setDirty(true);
    const index = release.data.artists.findIndex(a => a.name === obj.name);
    const cloned = clone(release.data.artists);
    cloned.splice(index, 1);
    AddItemStore.update(s => {
      s.release.data.artists = cloned;
    });
  };

  const handleRemoveLabel = (obj: { name: string }) => {
    setDirty(true);
    const index = release.data.labels.findIndex(a => a.name === obj.name);
    const cloned = clone(release.data.labels);
    cloned.splice(index, 1);
    AddItemStore.update(s => {
      s.release.data.labels = cloned;
    });
  };

  const handleEditLabel = (label: ReleaseLabel, value: string) => {
    setDirty(true);
    const index = release.data.labels.findIndex(a => a.name === label.name);
    const cloned = clone(release.data.labels);
    cloned[index].catno = value;
    AddItemStore.update(s => {
      s.release.data.labels = cloned;
    });
  };

  const handleAddLabel = (labelObj: { title: string; id: number }) => {
    setDirty(true);
    AddItemStore.update(s => {
      s.release.data.labels.push({ name: labelObj.title, id: labelObj.id || new Date().getTime() });
    });
  };

  const handleAddDescription = (index: number) => {
    setDirty(true);
    const format = release.data.formats[index];
    const cloned = clone(release.data.formats);
    if (format) cloned[index].descriptions.push("");
    AddItemStore.update(s => {
      s.release.data.formats = cloned;
    });
  };

  const handleDescriptionUpdate = (object: any) => {
    setDirty(true);
    AddItemStore.update(s => {
      s.release.data.formats[object.formatIndex].descriptions[object.index] = object.value;
    });
  };

  const handleDescriptionDelete = ({ formatIndex, index }: { formatIndex: number; index: number }) => {
    setDirty(true);
    const cloned = clone(release.data.formats);
    cloned[formatIndex].descriptions.splice(index, 1);
    AddItemStore.update(s => {
      s.release.data.formats = cloned;
    });
  };

  const handleFormatDelete = (index: number) => {
    setDirty(true);
    const cloned = clone(release.data.formats);
    cloned.splice(index);
    AddItemStore.update(s => {
      s.release.data.formats = cloned;
    });
  };

  const handleFormatAdd = () => {
    setDirty(true);
    const cloned = clone(release.data.formats);
    cloned.push({ qty: "1", name: "Vinyl", descriptions: [] });
    AddItemStore.update(s => {
      s.release.data.formats = cloned;
    });
  };

  const handleFormatHeadUpdate = (object: any) => {
    setDirty(true);
    const cloned = clone(release.data.formats);
    const format = cloned[object.formatIndex];
    // @ts-ignore
    if (format) format[object.name] = object.value;
    AddItemStore.update(s => {
      s.release.data.formats = cloned;
    });
  };

  const handleShopDescriptionUpdate = (content: string) => {
    setDirty(true);
    AddItemStore.update(s => {
      s.release.descriptions.shop.text = content;
    });
  };

  const handleTracklistInputChange = (object: any) => {
    setDirty(true);
    const cloned = clone(release.data.tracklist);
    // @ts-ignore
    cloned[object.index][object.name] = object.value;
    AddItemStore.update(s => {
      s.release.data.tracklist = cloned;
    });
  };

  const handleIdentifierUpdate = (object: any) => {
    setDirty(true);
    AddItemStore.update(s => {
      s.release.data.identifiers = clone(object);
    });
  };

  const handleSnippetError = (message: string) => {
    addNotification({ ok: 0, message });
  };

  const handleSnippetDrop = async () => {
    console.log("Wont add tracks");
  };

  const handleAddTrack = async () => {
    setDirty(true);
    const cloned = clone(release.data.tracklist);
    cloned.push({
      type_: "track",
      position: undefined,
      title: undefined
    });
    AddItemStore.update(s => {
      s.release.data.tracklist = cloned;
    });
  };

  const handleDeleteTrack = async (index: number) => {
    setDirty(true);
    AddItemStore.update(s => {
      s.release.data.tracklist = clone(release.data.tracklist.splice(index, 1));
    });
  };

  const handleClear = () => {
    AddItemStore.update(s => {
      s.drafts.release = false;
      s.release = {
        type: "ReleaseItem",
        descriptions: { main: "", shop: {} },
        data: {
          discogsId: null,
          identifiers: [],
          assetLink: "",
          title: "",
          tracklist: [],
          images: [],
          formats: [],
          country: "",
          genres: [],
          styles: [],
          releaseDate: moment().format(),
          artists: [],
          labels: [],
          weight: null
        },
        listings: []
      };
      s.listings = [getDefaultListingData(config as Config, ItemType.ReleaseItem)];
    });
  };

  const shopDescription = release.descriptions.shop.text;

  return (
    <div>
      <ModalComponent ref={modalRef} style={{}}>
        <ReleaseAdd close={() => modalRef.current?.close()} isOpen={modalRef.current?.isOpen} />
      </ModalComponent>
      <Section>
        <div>
          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "var(--gutter)" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="pageTitle" tag="h2" style={{ marginRight: "var(--gutter" }}>
                {t("Add an item")}
              </Typography>
              <Button variant="secondary" onClick={() => modalRef?.current?.open()} styleProps={{ marginRight: "var(--gutter" }}>
                {t("Search database")}
              </Button>
              <Button variant="warning" onClick={() => handleClear()}>
                {t("Clear")}
              </Button>
            </div>
            <div>
              <Button variant="primary" disabled={!dirty || loading} type="button" onClick={handleSubmitItem}>
                {t("Submit")}
              </Button>
            </div>
          </div>
        </div>
      </Section>
      <Section withMargin>
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <div style={{ display: "flex", flexDirection: "row", gap: "var(--gutter)" }}>
            {release.data.images
              .filter((i, idx) => idx < 1)
              .map((i, idx: number) => (
                <Zoom zoomMargin={100} key={idx + (i.uri || "")} classDialog="zoomZone">
                  <img key={idx + (i.uri || "")} src={i.uri || ""} style={{ width: "100px", height: "auto" }} />
                </Zoom>
              ))}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="copy" level="highlight">
              {release.data.title}
            </Typography>
            <Typography variant="copy" level="normal">
              {release.data.artists.length ? release.data.artists[0].name : null}
            </Typography>
          </div>
        </div>
      </Section>
      <Section withMargin={true}>
        <Tabs className="tabView" selectedIndex={index} onSelect={index => setIndex(index)}>
          <TabList className="tabList">
            <Tab className={"tab react-tabs__tab"}>
              <span>{t("Listings")}</span>
            </Tab>
            <Tab className={"tab react-tabs__tab"}>
              <span>{t("Information")}</span>
            </Tab>
            <Tab className={"tab react-tabs__tab"}>
              <span>{t("Tracklist & Identifiers")}</span>
            </Tab>
            <Tab className={"tab react-tabs__tab"}>
              <span>{t("Images")}</span>
            </Tab>
            <Tab className={"tab react-tabs__tab"}>
              <span>{t("SEO")}</span>
            </Tab>
          </TabList>

          <TabPanel>
            {listings.map((l, i) => (
              <Liner key={i} index={i}>
                <ListingComponent config={config} item={release} listing={l} index={i} />
              </Liner>
            ))}
          </TabPanel>
          <TabPanel>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "var(--gutter)", marginBottom: "var(--gutter)" }}>
              <div style={{ display: "flex", flexDirection: "column", gap: "var(--gutter)" }}>
                <Input
                  label={t("Title") + "*"}
                  name="title"
                  required
                  type="text"
                  value={release.data.title}
                  onChange={(e: any) => handleGenericInputUpdate(e.target.name, e.target.value)}
                />
                <TwoColumnGrid>
                  <Select
                    label={t("Country") + "*"}
                    value={{ value: getCode(release.data.country || ""), label: release.data.country || "" }}
                    onChange={(option: any) => handleCountryChange(option.value)}
                    options={getData().map((c: any) => ({ label: c.name, value: c.code }))}
                    placeholder={t("Select") + "..."}
                  />
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    value={release.data.releaseDate ? moment(release.data.releaseDate).format() : ""}
                    onChange={handleAvailableChange}
                    label={t("Release date")}
                    className="releaseDate"
                    closeOnSelect={true}
                  />
                </TwoColumnGrid>
                <TwoColumnGrid>
                  <SelectCreatable
                    isMulti={true}
                    label={t("Styles")}
                    value={release.data.styles.map(s => ({ label: s, value: s }))}
                    onChange={(option: any) => handleStyleChange(option)}
                    options={(metadata?.configMetadata?.styles || []).map((c: string) => ({ label: c, value: c }))}
                  />
                  <SelectCreatable
                    isMulti={true}
                    label={t("Genres")}
                    value={release.data.genres.map(s => ({ label: s, value: s }))}
                    onChange={(option: any) => handleGenreChange(option)}
                    options={(metadata?.configMetadata?.genres || []).map(c => ({ label: c, value: c }))}
                  />
                </TwoColumnGrid>
                <Input
                  label={t("Discogs ID")}
                  type="number"
                  name="discogsId"
                  value={release.data.discogsId || ""}
                  onWheel={(e: any) => e.target.blur()}
                  onChange={(e: any) => handleGenericInputUpdate(e.target.name, e.target.value)}
                />
                <Input
                  label={t("Weight in grams") + "*"}
                  name="weight"
                  type="number"
                  required
                  step="1"
                  min="0"
                  onWheel={(e: any) => e.target.blur()}
                  value={release.data.weight || ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleGenericInputUpdate(e.target.name, e.target.value)}
                />
                <Input
                  label={t("Public asset download link")}
                  type="url"
                  name="assetLink"
                  value={release.data.assetLink || ""}
                  onChange={(e: any) => handleGenericInputUpdate(e.target.name, e.target.value)}
                />
                <DescriptionEditor label={t("Full description")} content={shopDescription || ""} onChange={handleShopDescriptionUpdate} />
              </div>
              <div style={{ display: "flex", flexDirection: "column", gap: "var(--gutter)" }}>
                <ArtistEntries
                  entries={release.data.artists}
                  config={config}
                  onSelect={handleAddArtist}
                  onDelete={handleRemoveArtist}
                  label={t("Artists") + "*"}
                  hasDiscogsToken={hasDiscogsToken}
                  t={t}
                />
                <LabelEntries
                  entries={release.data.labels || []}
                  config={config}
                  label={t("Labels") + "*"}
                  onSelect={handleAddLabel}
                  onEdit={handleEditLabel}
                  onDelete={handleRemoveLabel}
                  hasDiscogsToken={hasDiscogsToken}
                  t={t}
                />
                <FormatEditor
                  handleAddDescription={handleAddDescription}
                  formats={release.data.formats || []}
                  handleDescriptionUpdate={handleDescriptionUpdate}
                  handleDeleteDescription={handleDescriptionDelete}
                  handleDeleteFormat={handleFormatDelete}
                  handleFormatAdd={handleFormatAdd}
                  handleFormatHeadUpdate={handleFormatHeadUpdate}
                  label={t("Format")}
                  t={t}
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <Section>
              <TwoColumnGrid withMargin={true} style={{ gridTemplateColumns: "2fr 1fr" }}>
                <TracklistEditor
                  tracklist={release.data.tracklist || []}
                  label={t("Tracklist")}
                  release={release}
                  new={isNew}
                  newEditor={true}
                  onInputChange={handleTracklistInputChange}
                  onSnippetDrop={handleSnippetDrop}
                  onError={handleSnippetError}
                  onAddTrack={handleAddTrack}
                  onDeleteTrack={handleDeleteTrack}
                  t={t}
                />
                <ZoneComponent>
                  <IdentifiersEditor item={release} handleIdentifierUpdate={handleIdentifierUpdate} t={t} />
                </ZoneComponent>
              </TwoColumnGrid>
              <TwoColumnGrid></TwoColumnGrid>
            </Section>
          </TabPanel>
          <TabPanel>
            <div
              style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gridGap: "var(--gutter)", marginBottom: "var(--gutter)" }}>
              {release.data.images.map((i, idx: number) => (
                <img key={(i.uri || "") + idx} src={i.uri || ""} />
              ))}
            </div>
          </TabPanel>
          <TabPanel>
            <p>{"SEO"}</p>
          </TabPanel>
        </Tabs>
      </Section>
    </div>
  );
};

export default AddRelease;
