import React, { useState, useRef, useEffect } from "react";
import { Button } from "../../componentsV2/Button";
import moment from "moment";
import { GlobalStore } from "../../stores/global";
import { useQuery, useMutation } from "@apollo/client";
import { GET_ORDERS_REPORT, POST_ORDER_REPORT_PDF, POST_ORDER_REPORT_SEND } from "../../graphql/queries/order";
import Modal from "../modal";
import Loader from "../common/loader";
import Price from "../common/price";
import styled from "styled-components";
import colors from "@common-ground-io/common-assets/assets/colors.json";
import color from "color-js";
import Input from "../styled/input";
import TextArea from "../styled/textArea";
import Checkbox from "../styled/checkbox";
import * as amplitude from "@amplitude/analytics-browser";
import { IOrdersFilters } from "./orders";
import { AddNotification } from "../../types/globals";
import { Session } from "../../__generated__/graphql";
import { useTranslation } from "react-i18next";

const StyledDiv = styled.div`
  border-radius: 10px;
  padding: 15px;
  background-color: ${({ theme }) => (theme.name === "light" ? colors.greyLightest : colors.greyDarkest)};
  color: ${colors.grey};
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default function Reporting({ filters }: { filters: IOrdersFilters }) {
  const { addNotification, session, isMobile } = GlobalStore.useState(c => c);
  const modalRef = useRef<any>();
  const tableRef = useRef<any>();
  const [state, setState] = useState<string | null>("report");
  const [htmlReport, setHtmlReport] = useState<string>("");
  const { t } = useTranslation();

  const handleCloseReportModal = () => {
    modalRef.current.close();
    setState(null);
  };

  const handleSendEmail = () => {
    const html = tableRef.current;
    setHtmlReport(html.innerHTML);
    setState("email");
    amplitude.track("Sales report email section");
  };

  const handleStartReport = () => {
    if (!filters.from || !filters.to) return addNotification({ ok: 0, message: "A time range must be selected..." });
    modalRef.current.open();
    amplitude.track("Sales report open");
    setState("report");
  };

  return (
    <>
      <Modal ref={modalRef} onClose={() => setState(null)} style={{ padding: 0, display: "block", minWidth: isMobile ? "100%" : "60%" }}>
        <StyledDiv id="salesReportModal">
          <div className="header">
            <div className="left">
              <h2>{t("Sales report")}</h2>
              <p>
                {t("Paid orders between {{from}} and {{to}}", {
                  from: moment(filters.from).format("ll"),
                  to: moment(filters.to).format("ll")
                })}
                {filters.origin && filters.origin.length ? "-" + t("Sales channel") + ": " + filters.origin : ""}
              </p>
            </div>
            <Button variant="secondary" onClick={handleCloseReportModal}>
              {t("Close")}
            </Button>
          </div>
          <div className="content">
            {state === "email" ? (
              <EmailSender
                setState={setState}
                html={htmlReport}
                addNotification={addNotification}
                filters={filters}
                onClose={handleCloseReportModal}
                session={session as Session}
              />
            ) : state === "report" ? (
              <SalesReport filters={filters} handleSendEmail={handleSendEmail} tableRef={tableRef} addNotification={addNotification} />
            ) : null}
          </div>
        </StyledDiv>
      </Modal>
      <Button variant="navigation" onClick={handleStartReport}>
        {t("Sales report")}
      </Button>
    </>
  );
}

const EntryDiv = styled.div<{ secondary?: boolean }>`
  border-radius: 10px;
  padding: 15px;
  background-color: ${props =>
    color(props.secondary ? colors.blueDark : colors.purpleDark)
      .setAlpha(0.1)
      .toRGB()};
  color: ${props =>
    color(props.secondary ? colors.blueDark : colors.purpleDark)
      .setAlpha(1)
      .toRGB()};
`;

const SalesReport = ({
  filters,
  handleSendEmail,
  tableRef,
  addNotification
}: {
  filters: IOrdersFilters;
  handleSendEmail: any;
  tableRef: any;
  addNotification: AddNotification;
}) => {
  const { data, loading, error } = useQuery(GET_ORDERS_REPORT, {
    variables: { ordersFiltersInput: filters },
    fetchPolicy: "cache-and-network"
  });
  const { t } = useTranslation();

  if (error) addNotification({ ok: 0, message: error.toString() });

  if (!data || loading) return <Loader />;
  return (
    <div id="salesReport">
      <div className="salesReportContent" ref={tableRef}>
        <div className="amounts">
          <EntryDiv className="entry grand" style={{ gridArea: "grand" }}>
            <p className="title">{t("Grand total")}</p>
            <span className="value">
              <Price value={data.ordersReport.totals.grand as number} />
            </span>
          </EntryDiv>
          <EntryDiv className="entry grand" style={{ gridArea: "subtotal" }}>
            <p className="title">{t("Subtotal excl. shipping")}</p>
            <span className="value">
              <Price value={data.ordersReport.totals.subtotal as number} />
            </span>
          </EntryDiv>
          <EntryDiv className="entry grand" style={{ gridArea: "grandBeforeTaxes" }}>
            <p className="title">{t("Total excl. taxes")}</p>
            <span className="value">
              <Price value={data.ordersReport.totals.subtotalBeforeTaxes as number} />
            </span>
          </EntryDiv>
          <EntryDiv className="entry" style={{ gridArea: "shippingBeforeTaxes" }}>
            <p className="title">{t("Shipping excl. taxes")}</p>
            <span className="value">
              <Price value={data.ordersReport.totals.shippingBeforeTaxes as number} />
            </span>
          </EntryDiv>
          <EntryDiv className="entry" style={{ gridArea: "secondHandProfit" }}>
            <p className="title">{t("Second hand profit")}</p>
            <span className="value">
              <Price value={data.ordersReport.totals.secondHandProfit as number} />
            </span>
          </EntryDiv>

          <EntryDiv className="entry" style={{ gridArea: "origins" }}>
            <div>
              <div className="valueInline">
                <span className="label">{t("Point of sale")}</span>
                <Price value={data.ordersReport.totals.origins.pos} />
              </div>
              <div className="valueInline">
                <span className="label">{t("Online")}</span>
                <Price value={data.ordersReport.totals.origins.online} />
              </div>
              <div className="valueInline">
                <span className="label">{"Discogs"}</span>
                <Price value={data.ordersReport.totals.origins.discogs} />
              </div>
              <div className="valueInline">
                <span className="label">{"Bandcamp"}</span>
                <Price value={data.ordersReport.totals.origins.bandcamp} />
              </div>
            </div>
          </EntryDiv>

          <EntryDiv className="entry" style={{ gridArea: "itemTypes" }}>
            <div>
              <div className="valueInline">
                <span className="label">{t("New items")}</span>
                <Price value={data.ordersReport.items.news} />
              </div>
              <div className="valueInline">
                <span className="label">{t("Second hand items")}</span>
                <Price value={data.ordersReport.items.secondHands} />
              </div>
              <div className="valueInline">
                <span className="label">{t("Books")}</span>
                <Price value={data.ordersReport.items.books} />
              </div>
              <div className="valueInline">
                <span className="label">{t("Products")}</span>
                <Price value={data.ordersReport.items.products} />
              </div>
              <div className="valueInline">
                <span className="label">{t("Gift cards")}</span>
                <Price value={data.ordersReport.items.giftCards} />
              </div>
            </div>
          </EntryDiv>

          <EntryDiv className="entry" style={{ gridArea: "taxes" }}>
            <div className="entries">
              {data.ordersReport?.totals?.taxes?.map((t, idx) => (
                <div key={idx} className="valueInline">
                  <span className="label">{t.id}</span>
                  <Price value={t.total} />
                </div>
              ))}
            </div>
          </EntryDiv>
        </div>
        {data.ordersReport.paymentMethods.length ? (
          <div className="paymentMethods">
            <div className="entries">
              {data.ordersReport.paymentMethods.map(pm => (
                <EntryDiv key={pm.origin} secondary className="entry">
                  <div className="valueInline">
                    <p className="title">
                      {pm.origin} (<span>{pm.count}</span>)
                    </p>
                  </div>
                  <span className="value">
                    <Price value={pm.total} />{" "}
                  </span>
                </EntryDiv>
              ))}
            </div>
          </div>
        ) : null}
      </div>
      <Footer className="sendButton">
        <span />
        <Button variant="primary" onClick={() => handleSendEmail()}>
          {t("Send report")}
        </Button>
      </Footer>
    </div>
  );
};

const EmailSender = ({
  filters,
  html,
  setState,
  addNotification,
  onClose,
  session
}: {
  filters: IOrdersFilters;
  html: string;
  setState: any;
  addNotification: AddNotification;
  onClose: any;
  session: Session;
}) => {
  const from = moment(filters.from);
  const to = moment(filters.to);

  const [pdfUrl, setPdfUrl] = useState<string | undefined>();
  const [emailAddress, setEmailAddress] = useState("");
  const [message, setMessage] = useState("PDF available to download below");
  const [subject, setSubject] = useState(`Sales report ${from.format("ll")} - ${to.format("ll")}`);
  const [generatePdf, { loading }] = useMutation(POST_ORDER_REPORT_PDF);
  const [sendReport, { loading: sendingPdf }] = useMutation(POST_ORDER_REPORT_SEND);
  const [cc, setCc] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    generatePdf({ variables: { html, title: subject } }).then(({ data }) => {
      if (data?.orderReportPdf) setPdfUrl(data.orderReportPdf);
    });
  }, []);

  const handleSendPdf = async (event: any) => {
    if (!pdfUrl) return;
    event.preventDefault();
    amplitude.track("Sales report email sent", { email: emailAddress, withCC: !!cc });
    sendReport({
      variables: {
        pdfUrl,
        subject,
        message,
        email: emailAddress,
        cc: cc ? session.user.email : ""
      }
    })
      .then(({ data }) => {
        if (data?.orderReportSend) addNotification({ ok: 1, message: data.orderReportSend });
        onClose();
      })
      .catch(e => {
        addNotification({ ok: 0, message: e.message });
      });
  };

  return (
    <div className="sendReport">
      <div className="left">
        {loading || !pdfUrl ? (
          <div>
            <p>{t("Generating PDF")}</p>
            <Loader />
          </div>
        ) : (
          <embed src={pdfUrl} width="auto" height="auto" />
        )}
      </div>
      <div className="right">
        <form onSubmit={handleSendPdf}>
          <Input
            label={t("Email address")}
            onChange={(e: any) => setEmailAddress(e.target.value)}
            type="email"
            name="email"
            required
            value={emailAddress}
            placeholder={t("Email address") + "..."}
          />
          <Input
            label={t("Email subject")}
            onChange={(e: any) => setSubject(e.target.value)}
            type="text"
            name="subject"
            autoComplete="off"
            required
            value={subject}
            placeholder={t("Email subject") + "..."}
          />
          <div className="templateControls">
            <TextArea
              label={t("Content")}
              rows="8"
              placeholder={t("Message to recipient") + "..."}
              required
              value={message}
              onChange={(e: any) => setMessage(e.target.value)}
            />
          </div>
          <div>
            <Checkbox label={t("Send a copy to {{email}}")} checked={cc} onChange={() => setCc(!cc)} />
          </div>
          <div className="buttons">
            {!sendingPdf ? (
              <Button variant="secondary" onClick={() => setState("report")}>
                {t("Cancel")}
              </Button>
            ) : null}
            <Button disabled={sendingPdf || loading || !pdfUrl} variant="primary" type="submit">
              {sendingPdf ? <Loader /> : t("Send sales report")}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
