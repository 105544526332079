import React, { useRef, useState } from "react";
import { Button } from "../../componentsV2/Button";
import InputComponent from "../../components/styled/input";
import Modal from "../../components/modal";
import ButtonComponent from "../../components/styled/button";
import { SpaceBetweenContainer } from "../orders/export";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export const ModalContent = styled.div`
  & > *,
  input {
    margin-bottom: var(--gutter);
  }
`;

export const CreateListButton = ({ onSubmit }: { onSubmit: (title: string) => void }) => {
  const [title, setTitle] = useState<string>("");
  const modalRef = useRef<any>();
  const { t } = useTranslation();

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onSubmit(title);
    modalRef.current.close();
  };

  return (
    <div>
      <Modal style={{}} ref={modalRef}>
        <ModalContent>
          <SpaceBetweenContainer>
            <h2>{t("Create a new list")}</h2>
            <ButtonComponent type="button" variant="noStyle" onClick={() => modalRef.current.close()}>
              X
            </ButtonComponent>
          </SpaceBetweenContainer>
          <div>
            <form id="newListForm" onSubmit={e => handleSubmit(e)}>
              <InputComponent
                variant="overZone"
                label={t("List title")}
                value={title}
                placeholder={t("List title")}
                autoComplete="off"
                required
                onChange={(e: any) => setTitle(e.target.value)}
                name="title"
              />
              <SpaceBetweenContainer>
                <span />
                <Button variant="primary" type="submit" disabled={!title}>
                  {t("Submit")}
                </Button>
              </SpaceBetweenContainer>
            </form>
          </div>
        </ModalContent>
      </Modal>

      <Button variant="navigation" type="button" onClick={() => modalRef.current.open()}>
        {t("Create a new list")}
      </Button>
    </div>
  );
};
