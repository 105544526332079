import React, { useState, useEffect, useCallback } from "react";
import { GlobalStore } from "../../../stores/global";
import { Link, Prompt } from "react-router-dom";
import Button from "../../../components/styled/button";
import { Button as ButtonV2 } from "../../../componentsV2/Button";
import { useIsSaving } from "../../../hooks/useKeyPress";
import Loader from "../../../components/common/loader";
import { ThemeV1 as IThemeV1, Session, ThemeV2 } from "../../../__generated__/graphql";
import { AddNotification } from "../../../types/globals";
import { vscodeDark } from "@uiw/codemirror-theme-vscode";
import CodeMirror from "@uiw/react-codemirror";
import { css } from "@codemirror/lang-css";
import { javascript } from "@codemirror/lang-javascript";
import { useTranslation } from "react-i18next";
import { POST_THEME_COMPILE_SASS } from "../../../graphql/queries/theme";
import { useMutation } from "@apollo/client";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import InputComponent from "../../../components/styled/input";
import clone from "clone";
import { Typography } from "../../../componentsV2/Typography";

export const basicSetup = {
  lineNumbers: true,
  highlightActiveLineGutter: true,
  foldGutter: true,
  dropCursor: false,
  allowMultipleSelections: false,
  indentOnInput: true,
  bracketMatching: true,
  closeBrackets: true,
  autocompletion: true,
  rectangularSelection: false,
  crosshairCursor: false,
  highlightActiveLine: false,
  highlightSelectionMatches: false,
  closeBracketsKeymap: false,
  searchKeymap: false,
  foldKeymap: false,
  completionKeymap: true,
  lintKeymap: false
};

export default function ({
  theme,
  updateTheme,
  addNotification,
  updateCss
}: {
  theme: IThemeV1 | ThemeV2;
  updateTheme: any;
  addNotification: AddNotification;
  updateCss: (css: string) => void;
}) {
  if (theme.__typename === "ThemeV2")
    return <ThemeV2Editor theme={theme} updateTheme={updateTheme} updateCss={updateCss} addNotification={addNotification} />;
  else if (theme.__typename === "ThemeV1") return <ThemeV1 theme={theme} updateTheme={updateTheme} addNotification={addNotification} />;
}

const ThemeV1 = ({ addNotification, updateTheme, theme }: { addNotification: AddNotification; updateTheme: any; theme: IThemeV1 }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isSaving = useIsSaving();
  const { t } = useTranslation();
  const [scss, setScss] = useState(theme.scss || "");

  const handleSubmit = async (e?: any) => {
    if (e) e.preventDefault();
    setIsSubmitting(true);
    try {
      await updateTheme({
        variables: {
          themeRef: theme._id,
          scss
        }
      });
      addNotification({ ok: 1, message: "Theme saved" });
    } catch (e: any) {
      addNotification({ ok: 0, message: e.toString() });
    } finally {
      setIsSubmitting(false);
    }
  };

  const onChange = (value: string) => {
    setScss(value);
  };

  useEffect(() => {
    if (isSaving && !isSubmitting) handleSubmit();
  }, [isSaving]);

  return (
    <div className="cssEditor">
      <div className="header">
        <div className="left">
          <h2>{t("CSS editor")}</h2>
        </div>
        <div className="right">
          <ButtonV2 variant="primary" onClick={handleSubmit} disabled={isSubmitting} type="submit">
            {isSubmitting ? <Loader /> : t("Save")}
          </ButtonV2>
        </div>
      </div>
      <CodeMirror
        indentWithTab={true}
        basicSetup={basicSetup}
        value={scss}
        height="100%"
        theme={vscodeDark}
        onChange={(val: string) => onChange(val)}
        extensions={[css()]}
      />
    </div>
  );
};

const ThemeV2Editor = ({
  theme,
  addNotification,
  updateTheme,
  updateCss
}: {
  theme: ThemeV2;
  updateTheme: any;
  addNotification: AddNotification;
  updateCss: (css: string) => void;
}) => {
  const session = GlobalStore.useState(c => c.session) as Session;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const isSaving = useIsSaving();
  const [dirty, setDirty] = useState(false);
  const [scss, setScss] = useState(theme.scss || "");
  const [js, setJs] = useState(theme.js || "");
  const { t } = useTranslation();
  const [compileSass] = useMutation(POST_THEME_COMPILE_SASS);
  const [timeoutId, setTimeoutId] = useState<any>(null);
  const [index, setIndex] = useState(0);
  const [tags, setTags] = useState<string[]>(clone(theme.tags) || []);

  const handleAddMetaTag = (val: string) => {
    setDirty(true);
    setTags([...tags, val]);
  };

  const handleDeleteMetaTag = (i: number) => {
    setDirty(true);
    tags.splice(i, 1);
    setTags([...tags]);
  };

  const handleMetaChange = (e: any, i: number) => {
    setDirty(true);
    tags[i] = e.target.value;
    setTags([...tags]);
  };

  useEffect(() => {
    return () => {
      updateCss(theme.css || "");
    };
  }, [theme]);

  useEffect(() => {
    if (isSubmitting || !isSaving || !dirty) return;
    handleSubmit();
  }, [isSaving, dirty]);

  const handleSubmit = async (e?: any) => {
    if (e) e.preventDefault();
    setIsSubmitting(true);
    try {
      const { data } = await updateTheme({
        variables: {
          themeRef: theme._id,
          version: theme.version as number,
          scss,
          js,
          tags
        }
      });
      setScss(data?.themeUpdate.theme.scss || "");
      setJs(data?.themeUpdate.theme.js || "");
      setDirty(false);
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    } finally {
      setIsSubmitting(false);
    }
  };
  const onChange = useCallback(
    (val: string, type: "sass" | "js") => {
      setDirty(true);
      if (type === "sass") {
        setScss(val);
        if (timeoutId) clearTimeout(timeoutId);
        const id = setTimeout(() => {
          compileSass({ variables: { sass: val } })
            .then(({ data }) => {
              if (data) updateCss(data?.themeSassCompile);
            })
            .catch((e: any) => {
              console.log(e.message);
            });
        }, 1000);
        setTimeoutId(id);
        return () => {
          clearTimeout(id);
        };
      } else if (type === "js") {
        setJs(val);
      }
    },
    [timeoutId]
  );

  return (
    <div className="codeEditor">
      <Prompt when={dirty} message={() => t("Changes were unsaved, are you sure?")} />
      <div style={{ display: "flex", justifyContent: "space-between", padding: "var(--gutter)", alignItems: "center" }}>
        <div className="left">
          <Link to={`/editor/theme/${theme._id}`} className="back">
            <i className="cg-icon-arrow-back" />
          </Link>
        </div>
        <div className="right">
          <ButtonV2 onClick={handleSubmit} disabled={isSubmitting || !dirty} variant="primary" type="submit">
            {isSubmitting ? <Loader /> : t("Save files")}
          </ButtonV2>
        </div>
      </div>
      <Tabs className="tabView" selectedIndex={index} onSelect={index => setIndex(index)}>
        <TabList className="tabList">
          <Tab className={"tab react-tabs__tab"}>
            <span>{t("CSS editor")}</span>
          </Tab>
          {session.user.type === "SuperAdmin" && theme.appRevision > 13 ? (
            <>
              <Tab className={"tab react-tabs__tab superAdmin"}>
                <span>{t("JavaScript editor")}</span>
              </Tab>
              <Tab className={"tab react-tabs__tab superAdmin"}>
                <span>{t("Head tags")}</span>
              </Tab>
            </>
          ) : null}
        </TabList>
        <TabPanel>
          <CodeMirror
            indentWithTab={true}
            basicSetup={basicSetup}
            value={scss}
            height="100%"
            theme={vscodeDark}
            onChange={(val: string) => onChange(val, "sass")}
            extensions={[css()]}
          />
        </TabPanel>
        <TabPanel>
          <CodeMirror
            indentWithTab={true}
            basicSetup={basicSetup}
            value={js}
            height="100%"
            theme={vscodeDark}
            onChange={(val: string) => onChange(val, "js")}
            extensions={[javascript()]}
          />
        </TabPanel>
        <TabPanel>
          <div style={{ padding: "var(--gutter)" }}>
            <Typography variant="subTitle" tag="p" style={{ marginBottom: "var(--gutter)" }}>
              {t("Head tags")}
            </Typography>
            {tags?.map((tag, i) => (
              <div key={i} style={{ display: "grid", gridTemplateColumns: "1fr 0.2fr", marginBottom: "calc(var(--gutter) / 2)" }}>
                <InputComponent
                  placeholder={t("Meta tag definition") + "..."}
                  onChange={(e: any) => handleMetaChange(e, i)}
                  variant=""
                  value={tag}
                />
                <Button type="button" variant="noStyle" onClick={() => handleDeleteMetaTag(i)}>
                  {t("Delete")}
                </Button>
              </div>
            ))}
            {/* eslint-disable-next-line quotes */}
            <Button type="button" variant="secondary" onClick={() => handleAddMetaTag('<meta content="">')}>
              {t("Add a head tag")}
            </Button>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};
