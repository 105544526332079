import { gql } from "../../__generated-creators__";

gql(`
  fragment ReleaseFields on Release {
    discogsId
    title
    images {
      uri
    }
    weight
    genres
    styles
    releaseDate
    year
    identifiers {
      type
      description
      value
    }
    tracklist {
      _id
      type
      title
      uri
      duration
      position
    }
    formats {
      descriptions
      name
      qty
    }
    country
    artists {
      name
      id
    }
    labels {
      name
      id
    }
  }
`);

export const GET_RELEASE = gql(`
  query release($discogsId: Float) {
    release(discogsId: $discogsId) {
      ...ReleaseFields
    }
  }
`);
