import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import Button from "../styled/button";
import { Button as ButtonV2 } from "../../componentsV2/Button";
import Text from "../styled/text";
import TextArea from "../styled/textArea";
import Languages from "@common-ground-io/common-assets/assets/languages.json";
import Loader from "../common/loader";
import Image from "../image";
import { getDefaultListingData } from "./inputs";
import Four0Four from "../global/404";
import Meta from "../global/meta";
import { PlayerButton } from "../common/button";
import moment from "moment";
import { ArtistNames, LabelNames, FormatNames } from "../common/arrayToNames";
import { Listing } from "../../componentsV2/Listing";
import TrackVisibility from "react-on-screen";
import Statistics from "../common/statistics";
import { FaExternalLinkAlt } from "react-icons/fa";
import Copy from "copy-to-clipboard";
import { GlobalStore } from "../../stores/global";
import URI from "urijs";
import clone from "clone";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { GET_ITEM, POST_ITEM_LINK_GENERATE, POST_ITEM_UPDATE } from "../../graphql/queries/item";
import Modal from "../modal";
import { CREATE_POST } from "../../graphql/queries/post";
import { communityClient } from "../../contexts/apolloClients";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { useTranslation } from "react-i18next";
import { AddNotification, Match } from "../../types/globals";
import { Listing as IListing, Item as IItem, ReleaseImage, Config } from "../../__generated__/graphql";
import { GET_CONFIG_METADATA_ITEM } from "../../graphql/queries/config";
import { TFunction } from "i18next";
import ExportButton from "../exportButton";

export default function Item({ match }: { match: Match }) {
  const { config, addNotification } = GlobalStore.useState(c => c);
  const [listings, setListings] = useState<IListing[]>([]);
  const [imageToShow, setImageToShow] = useState<ReleaseImage | undefined | null>();
  const location = useLocation();
  const currentUri = new URI(location.pathname + location.search);
  const search = currentUri.search(true);
  const isAdding = search.add;
  const itemId = match.params?.id;
  const { data } = useQuery(GET_ITEM, { fetchPolicy: "cache-and-network", variables: { id: parseInt(itemId) } });
  const item = data && data.item;
  const history = useHistory();
  const { t } = useTranslation();
  const [getMetada] = useLazyQuery(GET_CONFIG_METADATA_ITEM, { fetchPolicy: "cache-and-network" });
  const [createPost] = useMutation(CREATE_POST, { client: communityClient, variables: { title: "A new post" } });
  const [createShortLink] = useMutation(POST_ITEM_LINK_GENERATE);

  useEffect(() => {
    if (!item) return;
    try {
      const fetchedItem = clone(item);
      document.title = fetchedItem.descriptions.main || "";
      if (isAdding) {
        fetchedItem.listings.unshift(getDefaultListingData(config as Config, fetchedItem.type));
        setTimeout(() => {
          const rowElement = document.getElementById("listingsHeader");
          if (rowElement) {
            const bodyRect = document.body.getBoundingClientRect();
            const elemRect = rowElement.getBoundingClientRect();
            const offset = elemRect.top - bodyRect.top;
            window.scrollTo({ top: offset - 40, behavior: "smooth" });
          }
        }, 100);
      }
      setListings(fetchedItem.listings);
      if (fetchedItem.data.images && data.item?.data.images.length) setImageToShow(fetchedItem.data.images[0]);
      else setImageToShow(null);
    } catch (e: any) {
      addNotification({ ok: 0, message: e.data || e.toString() });
    }
  }, [addNotification, isAdding, item]);

  const handleAddListing = () => {
    if (item) setListings([getDefaultListingData(config as Config, item.type), ...listings]);
  };

  const handleShowImage = (entry: ReleaseImage) => {
    setImageToShow(entry);
  };

  const handleCreateBitly = async () => {
    if (!item) return;
    try {
      if (item.bitly) return handleCopy(item.bitly);
      const { data } = await createShortLink({ variables: { itemRef: item._id } });
      if (data?.itemGenerateLink?.uri) handleCopy(data.itemGenerateLink.uri);
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
  };

  const handleAddOrDeleteListingComplete = () => {
    if (isAdding) {
      currentUri.removeQuery("add");
      history.push(currentUri.toString());
    }
    getMetada();
  };

  const handleCancelNewListing = () => {
    const listingsUpdate = listings.filter(l => !!l._id);
    setListings([...listingsUpdate]);
  };

  const handleCopy = (text: string) => {
    Copy(text);
    addNotification({ ok: 1, message: "Copied to clipboard" });
  };

  const handleCreatePost = async (e: any) => {
    if (!item) return;
    e.preventDefault();
    const imageUri = item.data.images[0]?.uri;
    if (!imageUri) return addNotification({ ok: 0, message: "An item artwork image is required..." });
    createPost({
      variables: {
        item: {
          artwork: item.data.images[0]?.uri,
          uri: item.uri,
          ref: item._id,
          hasAudio: item.type === "ReleaseItem" && item.data.tracklist?.some(t => t.uri),
          isPreorder: item.listings.some(l => l.preOrder)
        },
        configRef: config?._id
      }
    })
      .then(() => addNotification({ ok: 1, message: "Item posted" }))
      .catch(e => addNotification({ ok: 0, message: e.message }));
  };

  if (item === null) return <Four0Four message="The requested item wasn't found" />;
  else if (item === undefined || !config) return <Loader withMargins />;

  const isRelease = item.type === "ReleaseItem";
  const isProduct = item.type === "ProductItem";
  const isBook = item.type === "BookItem";

  return (
    <div id="itemPage" key={item.id} className={isProduct ? "product" : isBook ? "book" : "release"}>
      <div className="item">
        <Meta
          description={item.descriptions?.main || item.descriptions?.shop?.short || item.descriptions?.shop?.text}
          title={item.descriptions.main}
          path={item.path}
          updated={item.created}
          image={item.data.images.length ? item.data.images[0].uri : null}
        />
        <div className="content">
          <div className="left">
            <Zoom zoomMargin={100} classDialog="zoomZone">
              {imageToShow ? <Image id="mainImage" entry={imageToShow} /> : null}
            </Zoom>
            {item.data.images.length > 1 ? (
              <div className="images">
                {item.data.images.map((i, index) => (
                  <Image onClick={handleShowImage} entry={i} key={index} />
                ))}
              </div>
            ) : null}
          </div>
          <div className="right">
            <div className="header">
              <div className="itemTitle">
                {isRelease ? (
                  <>
                    <Text className="title">
                      <h1>{item.data.title}</h1>
                    </Text>
                    <Text className="artists">
                      <h2>{item.data.artists ? <ArtistNames artists={item.data.artists} /> : null}</h2>
                    </Text>
                    {item.data.labels ? (
                      <Text variant="secondary" className="labels">
                        <h2>
                          {item.data.labels.map((l, i) => (
                            <Link to={l.id ? `/catalogue?labels=${l.id}` : "#"} key={i}>
                              {l.name} - {l.catno}{" "}
                            </Link>
                          ))}
                        </h2>
                      </Text>
                    ) : null}
                  </>
                ) : null}
                {isProduct ? (
                  <>
                    <h1>{item.data.manufacturer}</h1>
                    <Text variant="secondary" className="manufacturer">
                      <h2>
                        {item.data.title} - {item.data.cat}
                      </h2>
                    </Text>
                  </>
                ) : null}
                {isBook ? (
                  <>
                    <h1>{item.data.title}</h1>
                    <Text variant="secondary" className="authors">
                      <h2>
                        {item.data.authors?.join(", ")} ({item.data.publisher})
                      </h2>
                    </Text>
                  </>
                ) : null}
              </div>
              <div>
                <ButtonV2 variant="primary" type="link" href={`/item/${item.id}/edit`}>
                  {t("Edit item")}
                </ButtonV2>
              </div>
            </div>
            <div className="buttonsAndLinks">
              <div className="buttons">
                <Link to={`/inventory?itemId=${item.id}`}>
                  <Button variant="info">{t("View in inventory")}</Button>
                </Link>
                {isRelease && item.data.discogsId ? (
                  <a href={`https://discogs.com/release/${item.data.discogsId}`} rel="noopener noreferrer" target="_blank">
                    <Button variant="info">
                      {t("View on Discogs")} <i className="cg-icon-open-tab" />
                    </Button>
                  </a>
                ) : null}
                <Button onClick={() => handleCopy(item.uri || "")} className="withIcon" variant="secondary">
                  {t("Eshop link")} <i className="cg-icon-link" />
                </Button>
                <Button onClick={handleCreateBitly} className="withIcon" variant="secondary">
                  {t("Short link")} <i className="cg-icon-link" />
                </Button>
                {config.community?.feed?.enabled ? (
                  <Button onClick={handleCreatePost} className="withIcon" variant="secondary">
                    <span>
                      {t("Post to feed")} <i className="cg-icon-feed" />
                    </span>
                  </Button>
                ) : null}
              </div>
              <div className="links">
                <p>
                  {t("Public link")}:{" "}
                  <a target="_blank" href={item.uri || ""} rel="noreferrer">
                    {item.uri}
                  </a>
                </p>
                <p>
                  {t("Created")}: {moment(item.created).format("ll")} ({moment(item.created).fromNow()})
                </p>
              </div>
            </div>
            <div className="threeColumns">
              <div className="left">
                <div className="flexSpaceBetween header">
                  <Text variant="secondary">
                    <h2>{t("Item information")}</h2>
                  </Text>
                </div>
                {isRelease ? (
                  <>
                    <p className="releaseInfo">
                      {t("Artists")}: {item.data.artists ? <ArtistNames artists={item.data.artists} /> : null}
                    </p>
                    <p className="releaseInfo">
                      {t("Label")}: {item.data.labels ? <LabelNames labels={item.data.labels} /> : null}
                    </p>
                    {item.data.labels && item.data.labels.length > 0 ? (
                      <p className="releaseInfo">
                        {t("Catno")}:{" "}
                        {item.data.labels.map((l, idx) => (
                          <span key={idx}>{l.catno} </span>
                        ))}
                      </p>
                    ) : null}
                    <p className="releaseInfo">
                      {t("Formats")}: {item.data.formats ? <FormatNames formats={item.data.formats} /> : null}
                    </p>
                    <p className="releaseInfo">
                      {t("Country")}: <span>{item.data.country}</span>
                    </p>
                    <p className="releaseInfo">
                      {t("Release date")}:<span> {moment(item.data.releaseDate).format("ll")}</span>
                    </p>
                    <p className="releaseInfo">
                      {t("Genres")}:{" "}
                      <span>
                        {item.data.genres?.map((g, i) => (
                          <Link key={g + i} to={`/catalogue?genres=${g}`}>
                            {g}{" "}
                          </Link>
                        ))}
                      </span>
                    </p>
                    <p className="releaseInfo">
                      {t("Styles")}:
                      <span>
                        {" "}
                        {item.data.styles?.map((g, i) => (
                          <Link key={g + i} to={`/catalogue?styles=${g}`}>
                            {g}{" "}
                          </Link>
                        ))}
                      </span>
                    </p>
                    <p className="releaseInfo">
                      {/* eslint-disable-next-line i18next/no-literal-string */}
                      {t("Weight")}: {item.data.weight}g
                    </p>
                    {item.data.assetLink ? (
                      <p className="releaseInfo">
                        <a href={item.data.assetLink} target="_tab">
                          {t("Public asset download link")} <i className="cg-icon-open-tab" />
                        </a>
                      </p>
                    ) : null}
                  </>
                ) : null}
                {isProduct ? (
                  <>
                    <p className="productInfo">
                      {t("Title")}: <span>{item.data.title}</span>
                    </p>
                    <p className="productInfo">
                      {t("Vendor")}: <span>{item.data.manufacturer}</span>
                    </p>
                    <p className="productInfo">
                      {t("Product type")}: <span>{item.data.type}</span>
                    </p>
                    {item.data.giftCard && item.data.giftCard.active ? (
                      <p className="productInfo">
                        {t("Gift card")}:{" "}
                        <span>
                          {item.data.giftCard.prefix || t("No prefix")} | {item.data.giftCard.codeLength} |{" "}
                          {item.data.giftCard.singleSpending ? t("Single spending") : t("Multi spending")}
                        </span>
                      </p>
                    ) : null}
                    <p className="productInfo">
                      {t("Catno")}: <span>{item.data.cat}</span>
                    </p>
                    <p className="productInfo">
                      {/* eslint-disable-next-line i18next/no-literal-string */}
                      {t("Weight")}: {item.data.weight}g
                    </p>
                    {item.data.assetLink ? (
                      <p className="productInfo">
                        {t("Public asset download link")}:{" "}
                        <a href={item.data.assetLink} target="_tab">
                          <FaExternalLinkAlt /> {item.data.assetLink}
                        </a>
                      </p>
                    ) : null}
                  </>
                ) : null}
                {isBook ? (
                  <>
                    <p className="productInfo">
                      {t("Title")}: <span>{item.data.title}</span>
                    </p>
                    <p className="productInfo">
                      {t("Subtitle")}: <span>{item.data.subtitle}</span>
                    </p>
                    <p className="productInfo">
                      {t("Authors")}: <span>{item.data.authors?.join(", ")}</span>
                    </p>
                    <p className="productInfo">
                      {t("Format")}: <span>{item.data.format}</span>
                    </p>
                    <p className="productInfo">
                      {t("Publisher")}: <span>{item.data.publisher}</span>
                    </p>
                    <p className="productInfo">
                      {t("Language")}: <span>{item.data.language ? Languages.find(l => l.code === item.data.language)?.name : null}</span>
                    </p>
                    <p className="productInfo">
                      {t("Page count")}: <span>{item.data.pageCount}</span>
                    </p>
                    <p className="productInfo">
                      {t("Categories")}: <span>{item.data.categories?.join(", ")}</span>
                    </p>
                    <p className="releaseInfo">
                      {t("Published date")}:<span> {moment(item.data.publishedDate).format("ll")}</span>
                    </p>
                    <p className="releaseInfo">
                      {/* eslint-disable-next-line i18next/no-literal-string */}
                      {t("Weight")}: {item.data.weight}g
                    </p>
                  </>
                ) : null}
                {item.data.identifiers?.map((i, idx) => (
                  <p key={idx} className="productInfo">
                    {i.type}: <span>{i.value}</span>
                  </p>
                ))}
              </div>
              <div className="middle">
                <div className="header flexSpaceBetween">
                  <Text variant="secondary">
                    <h2>{t("Descriptions")}</h2>
                  </Text>
                  <span> - </span>
                  <DescriptionEditor item={item} addNotification={addNotification} t={t} />
                </div>
                <div className="shortDescription">
                  <h3>{t("Tag line / SEO description - 160 characters max")}</h3>
                  {item.descriptions.shop && item.descriptions.shop.short ? (
                    <p>{item.descriptions.shop.short}</p>
                  ) : (
                    <p>{t("No tag line")}</p>
                  )}
                </div>
                <div className="fullDescription">
                  <h3>{t("Full description")}</h3>
                  <Description item={item} t={t} />
                </div>
              </div>
              {isRelease ? (
                <div className="right">
                  <div className="flexSpaceBetween header">
                    <Text variant="secondary">
                      <h2>{t("Tracklist")}</h2>
                    </Text>
                  </div>
                  <div className="tracklist">
                    {item.data.tracklist?.map((t, index) => (
                      <div key={`${t.position}-${index}`} className="trackEntry">
                        {t.type_ === "track" ? (
                          <div className="track" key={t.position}>
                            <div className="playAndTrackName">
                              {t.uri ? <PlayerButton track={t} release={item} /> : null}
                              <p>
                                {t.position} -{" "}
                                {t.artists && t.artists.length ? <span>{t.artists[0].anv || t.artists[0].name} - </span> : null}
                                {t.title}
                                {/* eslint-disable-next-line i18next/no-literal-string */}
                                {t.plays ? <span> - {t.plays}x</span> : null}
                              </p>
                            </div>
                            <p>{t.duration}</p>
                          </div>
                        ) : (
                          <p className="trackHeader">{t.title}</p>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <section className="listings">
        <div id="listingsHeader">
          <div style={{ display: "flex", gap: "var(--gutter)", alignItems: "center" }}>
            <h2>
              {t("Listings")} ({listings.length})
            </h2>
            <ExportButton addNotification={addNotification} stats={{ listingsCount: item.listings.length }} filters={{ itemId: item.id }} />
            <ButtonV2 href={`/inventory?itemId=${item.id}`} variant="secondary" type="link">
              {t("View in inventory")}
            </ButtonV2>
          </div>
          <ButtonV2 variant="primary" onClick={handleAddListing}>
            {t("Add listing")}
          </ButtonV2>
        </div>
        <div style={{ display: "grid", gridGap: "var(--gutter)" }}>
          {listings.map((l, i) => (
            <Listing bulk={false} listing={l} config={config} key={l._id} item={item} />
          ))}
        </div>
      </section>
      <section className="statistics">
        <TrackVisibility once>
          {({ isVisible }) => isVisible && item && <Statistics item={item} itemRef={item._id} location={location} />}
        </TrackVisibility>
      </section>
    </div>
  );
}

const Description = ({ item, t }: { item: IItem; t: TFunction }) => {
  const limit = 160;
  const limitToAccept = 310;
  const long = item.descriptions.shop?.html;
  const short =
    item.descriptions.shop?.html && item.descriptions.shop.html?.length > limitToAccept
      ? item.descriptions.shop.html.substring(0, limit)
      : null;
  const [isOpen, setIsOpen] = useState(!short);

  if (short && long) {
    return (
      <div className="description">
        {isOpen ? <div dangerouslySetInnerHTML={{ __html: long }} /> : <div dangerouslySetInnerHTML={{ __html: short + "..." }} />}
        <button className="seeMore" onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? t("Show less") : t("Show more")}
        </button>
      </div>
    );
  } else if (!short && long) return <div className="description" dangerouslySetInnerHTML={{ __html: long }} />;
  else return <p>{t("No full description")}</p>;
};

const DescriptionEditor = ({ item, addNotification, t }: { item: IItem; addNotification: AddNotification; t: TFunction }) => {
  const [editItem] = useMutation(POST_ITEM_UPDATE);
  const modalRef = useRef<any>();
  const [descriptions, setDescriptions] = useState({ short: item.descriptions?.shop?.short, long: item.descriptions?.shop?.text });

  const limit = 160;

  const handleSave = async (e: any) => {
    e.preventDefault();
    const long = e.target.long.value;
    const short = e.target.short.value;
    try {
      await editItem({ variables: { itemRef: item._id, descriptions: { long, short } } });
      modalRef.current.close();
    } catch (e: any) {
      addNotification({ ok: 0, message: e.toString() });
    }
  };

  const handleChange = (e: any) => {
    const name = e.target.name;
    let content = e.target.value;
    if (name === "short" && content.length > limit) {
      content = content.substring(0, limit);
      addNotification({ ok: 0, message: "Max number of characters is 160" });
    }
    setDescriptions({ ...descriptions, [name]: content });
  };

  return (
    <>
      <Modal ref={modalRef} style={{ minWidth: "50%" }}>
        <div id="editDescriptionsEditorModal">
          <div className="header">
            <h2>{t("Edit descriptions")}</h2>
            <Button variant="secondary" onClick={() => modalRef.current.close()}>
              {t("Cancel")}
            </Button>
          </div>
          <div className="content">
            <form onSubmit={handleSave} id="itemEditDescriptionsForm">
              <TextArea
                name="short"
                rows="2"
                label={t("Tag line / SEO description - 160 characters max")}
                placeholder={t("Tag line / SEO description - 160 characters max") + "..."}
                onChange={handleChange}
                value={descriptions.short}
              />
              <TextArea
                name="long"
                rows="6"
                label={t("Full description")}
                placeholder={t("Full description") + "..."}
                onChange={handleChange}
                value={descriptions.long}
              />
            </form>
          </div>
          <div className="footer flexSpaceBetween">
            <span />
            <Button variant="primary" type="submit" form="itemEditDescriptionsForm">
              {t("Save")}
            </Button>
          </div>
        </div>
      </Modal>
      <button type="button" onClick={() => modalRef.current.open()} className="reset">
        {t("Edit")}
      </button>
    </>
  );
};
