import React, { useState, useEffect } from "react";
import { Link, Prompt, useHistory, useLocation } from "react-router-dom";
import Button from "../styled/button";
import { OrderStatuses } from "../common/orderDefinitions";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import EditUser from "./edit";
import Four0Four from "../global/404";
import OrderTable from "../orders/table";
import Pagination from "../common/pagination";
import Loader from "../common/loader";
import URI from "urijs";
import { GlobalStore } from "../../stores/global";
import CreditNotes from "../../pages/creditNotes/creditNotes";
import { GET_ORDERS, GET_ORDERS_STATISTICS } from "../../graphql/queries/order";
import { useQuery, useMutation } from "@apollo/client";
import clone from "clone";
import { GET_USER, POST_USER_BUYER_UPDATE, POST_USER_BUYER_DELETE, GET_USER_WANTLIST } from "../../graphql/queries/user";
import { Buyer, Config, Pagination as IPagination } from "../../__generated__/graphql";
import { Match } from "../../types/globals";
import Tile from "../common/tile";
import { useTranslation } from "react-i18next";

export default function SingleUser({ match }: { match: Match }) {
  const { addNotification, config } = GlobalStore.useState(c => c);
  const history = useHistory();
  const [tabIndex, setTabIndex] = useState(0);
  const [user, setUser] = useState<Buyer | undefined>(undefined);
  const [dirty, setDirty] = useState(false);
  const [updateUser] = useMutation(POST_USER_BUYER_UPDATE);
  const [deleteUser] = useMutation(POST_USER_BUYER_DELETE);
  const { data: userData, refetch } = useQuery(GET_USER, {
    fetchPolicy: "cache-and-network",
    variables: { userRef: match.params.id }
  });
  const { t } = useTranslation();
  const lists = userData?.lists?.lists;

  useEffect(() => {
    if (userData?.user) setUser(clone(userData.user));
  }, [userData]);

  const handleChange = (event: any) => {
    if (!user) return;
    // @ts-expect-error
    user[event.target.name] = event.target.value;
    setUser({ ...user });
    setDirty(true);
  };

  const handleAddressUpdate = async (index: number, name: string, value: any) => {
    if (!user) return;
    // @ts-expect-error
    user.addresses[index][name] = value;
    setUser({ ...user });
    setDirty(true);
  };

  const handleUpdate = async (e: any) => {
    if (!user) return;
    if (e) e.preventDefault();
    try {
      const { data } = await updateUser({
        variables: {
          userRef: user._id,
          userBuyerUpdateInput: {
            firstName: user.firstName || "",
            lastName: user.lastName || "",
            email: user.email || "",
            telephone: user.telephone,
            taxNumber: user.taxNumber,
            organisation: user.organisation,
            notes: user.notes,
            addresses: user.addresses.map(a => ({
              addressLine1: a.addressLine1,
              addressLine2: a.addressLine2,
              city: a.city,
              state: a.state,
              alpha2: a.alpha2,
              postCode: a.postCode,
              type: a.type,
              description: a.description
            }))
          }
        }
      });
      setDirty(false);
      addNotification({ ok: 1, message: t("Updated") });
      setUser({ ...user, ...data?.userBuyerUpdate });
      refetch();
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
  };

  const addNewAddress = () => {
    if (!user) return;
    setDirty(true);
    user.addresses.push({ type: "billingAndShipping" });
    setUser({ ...user });
  };

  const handleRemoveAddress = async (index: number) => {
    if (!user) return;
    if (index === undefined) return;
    user.addresses.splice(index, 1);
    setDirty(true);
    setUser({ ...user });
  };

  const handleRemoveUser = async (userRef: string) => {
    try {
      await deleteUser({ variables: { userRef } });
      addNotification({ ok: 1, message: t("User deleted") });
      history.push("/users");
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
  };

  if (user === null) return <Four0Four message={t("The requested user wasn't found")} />;
  else if (!user || !lists) return <Loader />;

  return (
    <div id="user">
      <section className="header">
        <div className="left">
          <h1>{user.name}</h1>
          <Link to="/users">
            <Button type="button" variant="noStyle">
              {t("Back")}
            </Button>
          </Link>
        </div>
        <div className="right">
          {user.active ? (
            <Button variant="danger" onClick={() => window.confirm(t("Are you sure?")) && handleRemoveUser(user._id)}>
              {t("Remove user")}
            </Button>
          ) : (
            <p>{t("User was deactivated")}</p>
          )}
        </div>
      </section>

      <Prompt when={dirty} message={() => t("Your changes will be lost, are you sure you want to leave this page ?")} />

      <Tabs selectedIndex={tabIndex} onSelect={tabIndex => setTabIndex(tabIndex)} className="userTabs tabView">
        <TabList className="tabList">
          <Tab className="tab">{t("Details")}</Tab>
          <Tab className="tab">
            {t("Orders")} ({user ? user.orderCount : ""})
          </Tab>
          <Tab className="tab">
            {t("Credit notes")} ({user && user.creditNotesCount})
          </Tab>
          <Tab className="tab">
            {t("Wantlist")} ({user.wantsCount || 0})
          </Tab>
        </TabList>
        <TabPanel>
          <div className="content">
            <EditUser
              user={user}
              dirty={dirty}
              lists={lists}
              getUser={refetch}
              handleChange={handleChange}
              handleAddressChange={handleAddressUpdate}
              handleUpdate={handleUpdate}
              addNewAddress={addNewAddress}
              removeAddress={handleRemoveAddress}
              addNotification={addNotification}
            />
          </div>
        </TabPanel>
        <TabPanel>
          <OrdersTab user={user} />
        </TabPanel>
        <TabPanel>
          <CreditNotes noHeader={true} userRef={user._id} />
        </TabPanel>
        <TabPanel>
          <Wantlist config={config as Config} user={user} />
        </TabPanel>
      </Tabs>
    </div>
  );
}

const OrdersTab = ({ user }: { user: Buyer }) => {
  const location = useLocation();
  const currentUri = new URI(location.pathname + location.search);
  const searchQuery = currentUri.search(true);

  const filters = {
    page: parseInt(searchQuery.page || 1),
    buyerRef: user._id,
    status: OrderStatuses.map(s => s.value)
  };

  const { loading, data } = useQuery(GET_ORDERS, {
    fetchPolicy: "cache-and-network",
    variables: { ordersFiltersInput: filters }
  });

  const { data: statisticsData } = useQuery(GET_ORDERS_STATISTICS, {
    fetchPolicy: "cache-and-network",
    variables: { ordersFiltersInput: filters }
  });

  const page = {
    pagination: data ? clone(data.orders.pagination) : { page: parseInt(searchQuery.page) || 1 },
    orders: data ? clone(data.orders.orders) : [],
    stats: statisticsData?.ordersStatistics
  };

  if (!data && loading) return <Loader />;

  return (
    <>
      <Pagination loading={loading} pagination={page.pagination as IPagination} stats={page.stats} currentUri={currentUri} />
      <OrderTable orders={page.orders} />
    </>
  );
};

const Wantlist = ({ config, user }: { config: Config; user: Buyer }) => {
  const location = useLocation();
  const currentUri = new URI(location.pathname + location.search);
  const searchQuery = currentUri.search(true);
  const { t } = useTranslation();
  const { data, loading } = useQuery(GET_USER_WANTLIST, {
    fetchPolicy: "cache-and-network",
    variables: { userRef: user._id, page: 1 }
  });

  const page = {
    pagination: data ? clone(data.userWantlist.pagination) : { page: parseInt(searchQuery.page) || 1 },
    entries: data ? clone(data.userWantlist.entries) : []
  };

  if (!data && loading) return <Loader />;

  return (
    <>
      <Pagination loading={loading} pagination={page.pagination as IPagination} currentUri={currentUri} />
      <div className="content">
        <div style={{ display: "grid", gridTemplateColumns: "repeat(8, 1fr)", gridGap: "var(--gutter)" }}>
          {page.entries.length ? (
            page.entries.map(e => <Tile key={e.id} config={config as Config} entry={e}></Tile>)
          ) : (
            <p>{t("No results found")}</p>
          )}
        </div>
      </div>
    </>
  );
};
