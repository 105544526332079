import { Store } from "pullstate";
import { StickerEntry } from "../components/stickers/stickers";

type BulkStore = {
  inventory: {
    isRunning: boolean | undefined;
    listings: string[];
    entries: StickerEntry[];
  };
};

export const BulkStore = new Store<BulkStore>({
  inventory: {
    isRunning: undefined,
    listings: [],
    entries: []
  }
});
