import React from "react";
import { GlobalStore } from "../../stores/global";
import { Link } from "react-router-dom";
import moment from "moment";
import { Config } from "../../__generated__/graphql";
import { useTranslation } from "react-i18next";

export default function Announcement() {
  const { config } = GlobalStore.useState(s => s);

  const isInTrial = config?.plan?.trial?.active;
  const message = null;

  if (isInTrial)
    return (
      <div id="announcement" className="information">
        {isInTrial ? <Trial config={config} /> : null}
      </div>
    );
  else if (message)
    return (
      <div id="announcement" className="information">
        {message}
      </div>
    );
  return null;
}

const Trial = ({ config }: { config: Config }) => {
  const { t } = useTranslation();
  return (
    <Link to="/account">
      {t("Your free trial ends")} {moment(config.plan?.nextPaymentDate).from(new Date())}
    </Link>
  );
};
