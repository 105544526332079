import React, { useEffect, useState, useRef } from "react";
import Button from "../../components/styled/button";
import { Button as ButtonV2 } from "../../componentsV2/Button";
import Checkbox from "../../components/styled/checkbox";
import TextArea from "../../components/styled/textArea";
import Input from "../../components/styled/input";
import Zone from "../../components/styled/zone";
import clone from "clone";
import Tile from "../common/tile";
import { Select } from "../styled/select";
import Loader from "../common/loader";
import { FaTimes } from "react-icons/fa";
import CarouselComponent from "../global/carousel";
import MediaSelector from "../media/selector";
import { GlobalStore } from "../../stores/global";
import { ThemeAdd } from "../../components/global/icons";
import useOutsideClick, { useInsideClick } from "../../hooks/useOutsideClick";
import { GET_COLLECTION, GET_COLLECTION_DATA } from "../../graphql/queries/collection";
import { GET_ARTICLE, GET_ARTICLES } from "../../graphql/queries/article";
import { GET_ITEMS } from "../../graphql/queries/item";
import { useLazyQuery, useQuery, gql } from "@apollo/client";
import { SearchItems } from "../../components/global/search";
import { findUrlForMedia } from "../../utils";
import { Article, ArticleContentRow, ArticleContentRowColumnBlock, Item, List, Media, MediaFormat } from "../../__generated__/graphql";
import { TFunction, t } from "i18next";
import QuillEditor from "../quillEditor";

interface ArticleBlockBlogOptions {
  columns: number;
  format: string;
  limit: number;
  tags: string[];
  sort: { key: "published"; order: number };
}

export default function Blocks({
  blocks,
  setArticle,
  article,
  row,
  playlists,
  collections,
  lists,
  tags,
  t
}: {
  blocks: ArticleContentRowColumnBlock[];
  setArticle: any;
  article: Article;
  row: ArticleContentRow;
  playlists: any;
  collections: any;
  lists: List[];
  tags: string[];
  t: TFunction;
}) {
  const getBlock = (block: ArticleContentRowColumnBlock) => {
    switch (block.type) {
      case "text":
        return <Text key={block.id} article={article} setArticle={setArticle} block={block} t={t} />;
      case "embed":
        return (
          <div key={block.id} className="embed">
            <Embed block={block} article={article} setArticle={setArticle} t={t} />
          </div>
        );
      case "image":
        return (
          <div key={block.id} className="image">
            <Image block={block} article={article} setArticle={setArticle} t={t} />
          </div>
        );
      case "playlist":
        return (
          <div key={block.id} className="playlist">
            <Playlist block={block} article={article} setArticle={setArticle} playlists={playlists} t={t} />
          </div>
        );
      case "collection":
        return (
          <div key={block.id} className="collection">
            <Collection block={block} article={article} setArticle={setArticle} collections={collections} t={t} />
          </div>
        );
      case "subscribe":
        return (
          <div key={block.id} className="subscribe">
            <Subscribe block={block} article={article} setArticle={setArticle} lists={lists} t={t} />
          </div>
        );
      case "carousel":
        return <Carousel key={block.id} block={block} article={article} setArticle={setArticle} t={t} />;
      case "separator":
        return <Separator key={block.id} block={block} article={article} setArticle={setArticle} t={t} />;
      case "article":
        return <ArticleBlock key={block.id} block={block} article={article} setArticle={setArticle} t={t} />;
      case "items":
        return <Items key={block.id} block={block} article={article} setArticle={setArticle} t={t} />;
      case "blog":
        return <Blog key={block.id} tags={tags} block={block} article={article} setArticle={setArticle} t={t} />;
      case null:
        return (
          <BlockProposal
            key={block.id}
            tags={tags}
            block={block}
            row={row}
            article={article}
            setArticle={setArticle}
            collections={collections}
            playlists={playlists}
            t={t}
          />
        );
      default: {
        console.log("not handled", block);
        return null;
      }
    }
  };

  return <div className="blocks">{blocks.map(b => getBlock(b))}</div>;
}

const BlockProposal = ({
  block,
  row,
  article,
  setArticle,
  collections,
  playlists,
  tags,
  t
}: {
  block: ArticleContentRowColumnBlock;
  row: ArticleContentRow;
  article: Article;
  setArticle: any;
  collections: any;
  playlists: any;
  tags: string[];
  t: TFunction;
}) => {
  const elements = [
    { title: t("Text"), id: "text", data: { content: "" } },
    { title: t("Image"), id: "image", data: { media: null, to: "" } },
    { title: t("Article"), id: "article", data: { articleId: null, format: null } },
    {
      title: t("Blog"),
      id: "blog",
      disabled: !tags.length,
      data: { limit: 4, format: "", tags: [], columns: 2, sort: { key: "published", order: -1 } } as ArticleBlockBlogOptions
    },
    { title: t("Items"), id: "items", data: { entries: [], entriesPerRow: 2, title: "" } },
    { title: t("Carousel"), id: "carousel", data: { entries: [] } },
    { title: t("Collection"), id: "collection", data: { id: null }, disabled: !collections || collections.length === 0 },
    { title: t("Playlist"), id: "playlist", data: { id: null }, disabled: playlists && playlists.length === 0 },
    { title: t("Embed"), id: "embed", data: { html: null } },
    { title: t("Subscribe"), id: "subscribe", data: { listRef: null } },
    { title: t("Separator"), id: "separator", data: {}, disabled: row.layout !== "oneColumn" }
  ];

  const handleEditBlock = ({ id, type }: { id: string; type: string }) => {
    const articleCopy = clone(article);
    const block = findBlock({ article: articleCopy, id });
    if (block) {
      block.type = type;
      block.data = elements.find(e => e.id === type)?.data;
      setArticle(articleCopy);
    }
  };
  return (
    <div className="blockProposal">
      {elements.map(elem => (
        <div className="entry" key={elem.id}>
          <Button
            variant="noStyle"
            disabled={elem.disabled || (elem.id === "carousel" && row.layout !== "oneColumn")}
            onClick={() => handleEditBlock({ id: block.id, type: elem.id })}>
            {elem.title}
          </Button>
        </div>
      ))}
    </div>
  );
};

const ToolBar = ({
  article,
  setArticle,
  block,
  isEditing,
  setIsEditing,
  done,
  t
}: {
  block: ArticleContentRowColumnBlock;
  article: Article;
  setArticle: any;
  isEditing?: boolean;
  setIsEditing?: any;
  done?: any;
  t: TFunction;
}) => {
  const handleReset = () => {
    const articleCopy = clone(article);
    const blockFound = findBlock({ article: articleCopy, id: block.id });
    if (blockFound) {
      blockFound.type = "";
      blockFound.data = {};
      setArticle(articleCopy);
    }
  };

  const deleteBlock = () => {
    if (window.confirm(t("Are you sure you wish to delete this block?"))) {
      const articleCopy = clone(article);
      articleCopy.content.rows.forEach(r => {
        r.columns.forEach(c => {
          const foundBlockIndex = c.blocks.findIndex(b => b.id === block.id);
          if (foundBlockIndex !== -1) {
            if (r.layout === "oneColumn" && c.blocks.length === 1) {
              const rowIndex = articleCopy.content.rows.findIndex(row => row.id === r.id);
              if (rowIndex !== -1) articleCopy.content.rows.splice(rowIndex, 1);
            } else c.blocks.splice(foundBlockIndex, 1);
            setArticle(articleCopy);
          }
        });
      });
    }
  };

  return (
    <Zone className={"toolbar"}>
      {!isEditing && !!setIsEditing ? (
        <Button type="button" variant="secondaryOverZone" onClick={() => setIsEditing(!isEditing)}>
          {t("Edit")}
        </Button>
      ) : null}
      {isEditing ? (
        <ButtonV2 type="button" variant="primary" onClick={() => done && done()}>
          {t("Done")}
        </ButtonV2>
      ) : null}
      {!isEditing ? (
        <Button type="button" variant="secondaryOverZone" onClick={handleReset}>
          {t("Reset")}
        </Button>
      ) : null}
      {!isEditing ? (
        <Button type="button" variant="secondaryOverZone" onClick={deleteBlock}>
          {t("Delete")}
        </Button>
      ) : null}
    </Zone>
  );
};

const findBlock = ({ article, id }: { article: Article; id: string }): ArticleContentRowColumnBlock | null => {
  let block: any = null;
  article.content.rows.forEach(r => {
    r.columns.forEach(c => {
      const foundBlock = c.blocks.find(b => b.id === id);
      if (foundBlock) return (block = foundBlock);
    });
  });
  return block;
};

const ArticleBlock = ({
  block: blockToCopy,
  article,
  setArticle,
  t
}: {
  block: ArticleContentRowColumnBlock;
  article: Article;
  setArticle: any;
  t: TFunction;
}) => {
  const [block, setBlock] = useState(clone(blockToCopy));
  const insideRef = useRef<HTMLInputElement>(null);
  const [isEditing, setIsEditing] = useState(!block.data.articleId);
  const { data } = useQuery(gql("query { configMetadata { articles { id handle title } } }"), {
    fetchPolicy: "cache-and-network"
  });
  const [getArticle, { data: articleData, loading: loadingArticle }] = useLazyQuery(GET_ARTICLE);

  const articles = data?.configMetadata?.articles;

  const handleDone = () => {
    const articleCopy = clone(article);
    const blockFound = findBlock({ article: articleCopy, id: block.id });
    if (blockFound) {
      blockFound.data = block.data;
      setArticle(articleCopy);
      setIsEditing(false);
    }
  };

  useEffect(() => {
    if (block.data.articleId) {
      getArticle({ variables: { id: block.data.articleId } });
    }
  }, [block.data.articleId]);

  const handleArticleSelect = (value: string) => {
    block.data.articleId = value;
    setBlock(clone(block));
  };

  const handleSizeSelect = (value: string) => {
    block.data.format = value;
    setBlock(clone(block));
  };

  const articleOptions = articles?.map((a: Article) => ({ label: a.title, value: a.id }));
  const selectedArticle = articleData?.article as Article | null;

  if (!articles) return <Loader />;

  let bannerImage;
  let formats;
  let selectedFormat;
  const thumbnail = selectedArticle?.thumbnail as Media;
  if (thumbnail?.formatArray) {
    formats = thumbnail.formatArray.map((f: any) => ({
      label: `${f.name} ${f.width ? `(${f.width}x${f.height || ""})` : ""}`,
      value: f.name
    }));
    selectedFormat = formats.find(f => f.value === block.data.format);

    const bannerFormat = thumbnail.formatArray.find(f => f.name === block.data.format);
    if (bannerFormat) {
      bannerImage = bannerFormat.url;
    } else bannerImage = thumbnail.url;
  }

  return (
    <div className={`block text ${isEditing ? "isEditing" : ""}`}>
      <div className="content" ref={insideRef}>
        {isEditing ? (
          <div className="editor">
            <Select
              label={t("Select an article")}
              options={articleOptions}
              value={selectedArticle ? { label: selectedArticle.title } : null}
              onChange={(e: any) => handleArticleSelect(e.value)}
            />
            {formats ? (
              <Select
                label={t("Image size / format")}
                options={formats}
                value={selectedFormat}
                onChange={(e: any) => handleSizeSelect(e.value)}
              />
            ) : null}
          </div>
        ) : (
          <div className="preview">
            {selectedArticle && !loadingArticle ? (
              <>
                {bannerImage ? <img src={bannerImage} /> : <small>{t("No thumbnail available")}...</small>}
                <h2 style={{ padding: "var(--gutter" }}>{selectedArticle.title}</h2>
              </>
            ) : null}
          </div>
        )}
      </div>
      <ToolBar
        setArticle={setArticle}
        article={article}
        block={block}
        done={handleDone}
        setIsEditing={setIsEditing}
        isEditing={isEditing}
        t={t}
      />
    </div>
  );
};

const Items = ({
  block: blockToCopy,
  article,
  setArticle,
  t
}: {
  block: ArticleContentRowColumnBlock;
  article: Article;
  setArticle: any;
  t: TFunction;
}) => {
  const config = GlobalStore.useState((s: any) => s.config);
  const [block, setBlock] = useState(clone(blockToCopy));
  const insideRef = useRef(null);
  const [isEditing, setIsEditing] = useState(!block.data.entries.length);
  const { data, loading, refetch } = useQuery(GET_ITEMS, { variables: { ids: block.data.entries || [] } });
  const items = data?.items || [];

  const handleDone = (e: any) => {
    if (e) e.preventDefault();
    const articleCopy = clone(article);
    const blockFound = findBlock({ article: articleCopy, id: block.id });
    if (blockFound) {
      blockFound.data = {
        entries: block.data.entries,
        title: block.data.title,
        entriesPerRow: parseInt(block.data.entriesPerRow || 4)
      };
      setBlock({ ...blockFound });
      setArticle(articleCopy);
      setIsEditing(false);
    }
  };

  const handleAddEntry = (item: Item, e?: any) => {
    if (e) e.preventDefault();
    block.data.entries.push(item.id);
    block.data.entriesPerRow = block.data.entries.length;
    refetch({ ids: block.data.entries });
    setBlock({ ...block });
  };

  const handleDeleteEntry = (idx: number) => {
    block.data.entries.splice(idx, 1);
    block.data.entriesPerRow = block.data.entries.length;
    refetch({ ids: block.data.entries });
    setBlock(clone(block));
  };

  const handleEntriesPerRowChange = (e: any) => {
    block.data.entriesPerRow = e.target.value;
    setBlock(clone(block));
  };

  const handleTitleChange = (e: any) => {
    block.data.title = e.target.value;
    setBlock(clone(block));
  };

  return (
    <div className={`block items ${isEditing ? "isEditing" : ""}`}>
      <div className="content" ref={insideRef}>
        {isEditing ? (
          <div className="editor">
            <SearchItems
              focus={true}
              variant="primary"
              placeholder={t("Find releases, products or books") + "..."}
              onSelect={handleAddEntry}
            />
            <form onSubmit={handleDone}>
              <div className="options">
                <Input
                  type="text"
                  placeholder={t("Items") + "..."}
                  label={t("Title")}
                  value={block.data.title || ""}
                  onChange={handleTitleChange}
                />
                <Input
                  required
                  onWheel={(e: any) => e.target.blur()}
                  label={t("Entries per row")}
                  type="number"
                  onChange={handleEntriesPerRowChange}
                  min="1"
                  max="16"
                  value={block.data.entriesPerRow}
                />
              </div>
            </form>
          </div>
        ) : null}
        <div className="preview">
          {block.data.title ? <h2>{block.data.title}</h2> : null}
          <div className="entries" style={{ gridTemplateColumns: `repeat(${block.data.entriesPerRow || 4}, 1fr)` }}>
            {items ? (
              items.map((e: any, idx: number) => (
                <div key={idx} className="entry">
                  <Tile entry={e} config={config} />
                  {isEditing ? (
                    <Button type="button" variant="secondary" onClick={() => handleDeleteEntry(idx)}>
                      {t("Remove")}
                    </Button>
                  ) : null}
                </div>
              ))
            ) : loading ? (
              <Loader />
            ) : null}
          </div>
        </div>
      </div>
      <ToolBar
        setArticle={setArticle}
        article={article}
        block={block}
        done={handleDone}
        setIsEditing={setIsEditing}
        isEditing={isEditing}
        t={t}
      />
    </div>
  );
};

const Text = ({
  block,
  article,
  setArticle,
  t
}: {
  block: ArticleContentRowColumnBlock;
  article: Article;
  setArticle: any;
  t: TFunction;
}) => {
  const insideRef = useRef(null);
  const [content, setContent] = useState<string>(block.data.content || "");
  useInsideClick(insideRef, () => setIsEditing(true));
  const [isEditing, setIsEditing] = useState(!block.data.content);

  const handleDone = () => {
    const articleCopy = clone(article);
    const blockFound = findBlock({ article: articleCopy, id: block.id });
    if (blockFound) {
      blockFound.data.content = content;
      setArticle(articleCopy);
      setIsEditing(false);
    }
  };

  return (
    <div className={`block text ${isEditing ? "isEditing" : ""}`}>
      <div className="content" ref={insideRef}>
        <div className="editor">
          <QuillEditor content={content} onChange={value => setContent(value)} />
        </div>
        <div className="preview" dangerouslySetInnerHTML={{ __html: block.data.content || t("Enter your text") + "..." }} />
      </div>
      <ToolBar
        setArticle={setArticle}
        article={article}
        block={block}
        done={handleDone}
        setIsEditing={setIsEditing}
        isEditing={isEditing}
        t={t}
      />
    </div>
  );
};

const Image = ({
  block: blockToCopy,
  article,
  setArticle,
  t
}: {
  block: ArticleContentRowColumnBlock;
  article: Article;
  setArticle: any;
  t: TFunction;
}) => {
  const [block, setBlock] = useState(clone(blockToCopy));
  const [isEditing, setIsEditing] = useState(!block.data.media);

  const handleSelectMedia = (media: Media | null) => {
    if (!media) block.data.media = null;
    else {
      const formats = media.formatArray;
      block.data = { media, to: "", format: formats.length ? formats[0].name : null };
    }
    setBlock(clone(block));
  };

  const handleDone = () => {
    setIsEditing(false);
    const articleCopy = clone(article);
    const blockFound = findBlock({ article: articleCopy, id: block.id });
    if (blockFound) {
      blockFound.data = { ...block.data };
      setArticle(articleCopy);
    }
  };

  const handleLinkChange = (value: string) => {
    block.data.to = value;
    setBlock(clone(block));
  };

  const handleSizeSelect = (value: string) => {
    block.data.format = value;
    setBlock(clone(block));
  };

  const formats =
    block.data?.media?.formatArray &&
    block.data.media.formatArray.map((format: MediaFormat) => ({
      label: `${format.name} ${format.width ? `(${format.width}x${format.height || ""})` : ""}`,
      value: format.name
    }));
  const selectedFormat = formats && block.data?.format ? formats.find((f: any) => f.value === block.data.format) : null;

  return (
    <div className={`block image ${isEditing ? "isEditing" : ""}`}>
      <div className="content">
        {isEditing ? (
          <>
            <MediaSelector
              extensions={["png", "jpg", "jpeg", "svg", "gif"]}
              onSelected={(selected: Media[]) => handleSelectMedia(selected.length ? selected[0] : null)}
              onClear={() => handleSelectMedia(null)}
              media={block.data?.media}
            />
            <div className="linkAndFormat">
              <Input
                label="Link"
                onChange={(e: any) => handleLinkChange(e.target.value)}
                placeholder="Enter path such as /catalogue or external URL"
                value={block.data.to || ""}
                type="text"
                name="to"
              />
              {formats ? (
                <Select label="Size / format" options={formats} value={selectedFormat} onChange={(e: any) => handleSizeSelect(e.value)} />
              ) : null}
            </div>
          </>
        ) : null}
        {!isEditing && block.data?.media ? (
          <img onClick={() => setIsEditing(true)} src={findUrlForMedia(block.data.media, block.data.format)} />
        ) : null}
        {!isEditing && !block.data?.media ? (
          <Button variant="secondary" type="button" onClick={() => setIsEditing(true)}>
            {t("Add a media")}
          </Button>
        ) : null}
      </div>
      <ToolBar
        setArticle={setArticle}
        article={article}
        block={block}
        done={handleDone}
        setIsEditing={setIsEditing}
        isEditing={isEditing}
        t={t}
      />
    </div>
  );
};

const Playlist = ({
  block,
  article,
  setArticle,
  playlists,
  t
}: {
  block: ArticleContentRowColumnBlock;
  article: Article;
  setArticle: any;
  playlists: any;
  t: TFunction;
}) => {
  const [isEditing, setIsEditing] = useState(!block.data.id);
  const config = GlobalStore.useState((s: any) => s.config);

  const handleSelectPlaylist = (playlist: any) => {
    const articleCopy = clone(article);
    const blockFound = findBlock({ article: articleCopy, id: block.id });
    if (blockFound) {
      blockFound.data = { id: playlist.id };
      setArticle(articleCopy);
      setIsEditing(false);
    }
  };

  const handleDone = () => {
    setIsEditing(false);
  };

  if (!playlists) return <Loader withMargins />;
  const matchingPlaylist = playlists && playlists.find((p: any) => p.id === block.data?.id);
  return (
    <div className={`block playlist ${isEditing ? "isEditing" : ""}`}>
      <div className="content">
        {isEditing ? (
          <div className="playlistSelector">
            <h2>{t("Select a playlist")}</h2>
            <div className="content">
              {playlists.map((p: any) => (
                <Button variant="secondary" onClick={() => handleSelectPlaylist(p)} key={p.id}>
                  {p.title} ({p.entries.length})
                </Button>
              ))}
            </div>
          </div>
        ) : null}

        {!isEditing && matchingPlaylist ? (
          <div className="playlistComponent">
            <h2>{matchingPlaylist.title}</h2>
            <div className="entries">
              {matchingPlaylist.entries.map((e: any, i: number) => (
                <Tile key={i} entry={e.item} config={config} />
              ))}
            </div>
          </div>
        ) : null}
        {!isEditing && !block.data.id ? (
          <Button className="reset danger selectPlaylist" onClick={() => setIsEditing(true)}>
            {t("Select a playlist")}
          </Button>
        ) : null}
        {!isEditing && block.data.id && !!playlists && !matchingPlaylist ? (
          <h2 className="danger notice"> {t("Playlist not found")}</h2>
        ) : null}
      </div>
      <ToolBar
        setArticle={setArticle}
        article={article}
        block={block}
        done={handleDone}
        setIsEditing={setIsEditing}
        isEditing={isEditing}
        t={t}
      />
    </div>
  );
};

const Collection = ({
  block,
  article,
  setArticle,
  collections,
  t
}: {
  block: ArticleContentRowColumnBlock;
  article: Article;
  setArticle: any;
  collections: any;
  t: TFunction;
}) => {
  const [isEditing, setIsEditing] = useState(!block.data.id);
  const [sorting, setSorting] = useState({});
  const [getCollection, { data }] = useLazyQuery(GET_COLLECTION);
  const [getCollectionData, { data: collectionFetch }] = useLazyQuery(GET_COLLECTION_DATA);
  const config = GlobalStore.useState((s: any) => s.config);

  useEffect(() => {
    if (block.data.id !== null && !isNaN(block.data.id)) {
      getCollection({ variables: { id: block.data.id } });
    }
  }, [block.data.id]);

  useEffect(() => {
    if (data && data.collection) {
      const segmentForQuery = { filters: data.collection.segments[0].filters?.map((f: any) => ({ id: f.id, value: f.value })) };
      getCollectionData({
        variables: {
          segment: segmentForQuery,
          page: 1,
          limit: block.data.limit,
          sort: block.data.sort,
          randomise: block.data.randomise,
          order: block.data.order
        }
      });
    }
  }, [data, block.data.sort]);

  const handleSelectCollection = (collection: any) => {
    const articleCopy = clone(article);
    const blockFound = findBlock({ article: articleCopy, id: block.id });
    if (blockFound) {
      blockFound.data = { id: collection.id, limit: 8, sort: "added", order: -1 };
      setArticle(articleCopy);
      setIsEditing(false);
    }
  };

  const handleInputChange = (e: any) => {
    const value = parseInt(e.target.value);
    if (!isNaN(value)) setSorting({ ...sorting, limit: value });
    const articleCopy = clone(article);
    const blockFound = findBlock({ article: articleCopy, id: block.id });
    if (blockFound) {
      blockFound.data.limit = value;
      setArticle(articleCopy);
    }
  };

  const handleDone = () => {
    setIsEditing(false);
  };

  const collection = data?.collection;
  const collectionData = collectionFetch?.collectionData;

  if (!collections) return <Loader withMargins />;

  const handleSortSelect = ({ value }: { value: any }) => {
    if (!value) return;
    setSorting(value);
    const articleCopy = clone(article);
    const blockFound = findBlock({ article: articleCopy, id: block.id });
    if (blockFound) {
      blockFound.data = { ...block.data, ...value };
      setArticle(articleCopy);
    }
  };

  const handleRandomise = (e: any) => {
    const articleCopy = clone(article);
    const blockFound = findBlock({ article: articleCopy, id: block.id });
    if (blockFound) {
      blockFound.data = { ...block.data, randomise: e.target.checked };
      setArticle(articleCopy);
    }
  };

  const sorts = [
    {
      label: t("Price high to low"),
      value: { sort: "price", order: -1 }
    },
    {
      label: t("Price low to high"),
      value: { sort: "price", order: 1 }
    },
    {
      label: t("Added recent to old"),
      value: { sort: "added", order: -1 }
    },
    {
      label: t("Added old to recent"),
      value: { sort: "added", order: 1 }
    }
  ];

  return (
    <div className={`block collection ${isEditing ? "isEditing" : ""}`}>
      <div className="content">
        {isEditing ? (
          <div className="collectionEditor">
            {collection ? (
              <div className="parameters">
                <Input
                  onChange={handleInputChange}
                  onWheel={(e: any) => e.target.blur()}
                  onBlur={handleInputChange}
                  type="number"
                  min="0"
                  max="80"
                  step="1"
                  name="limit"
                  label={t("Entries to display")}
                  value={block.data.limit || ""}
                />
                {!block.data.randomise ? (
                  <Select
                    label={t("Sorting")}
                    options={sorts}
                    value={block.data.sort ? sorts.find(s => s.value.sort === block.data.sort && s.value.order === block.data.order) : null}
                    onChange={(e: any) => handleSortSelect({ value: e.value })}
                  />
                ) : (
                  <span />
                )}
                <Checkbox label={t("Randomise entries")} onChange={handleRandomise} checked={!!block.data.randomise} />
              </div>
            ) : null}
            {!collection ? (
              <>
                <h2>{t("Select a collection")}</h2>
                <div className="content">
                  {collections.map((c: any) => (
                    <Button variant="secondary" onClick={() => handleSelectCollection(c)} key={c.id}>
                      {c.title}
                    </Button>
                  ))}
                </div>
              </>
            ) : null}
          </div>
        ) : null}
        {!isEditing && collection ? (
          <div className="collectionComponent">
            <h2>{collection.title}</h2>
            <div className="entries">
              {collectionData ? collectionData.items.map((e: any, i: number) => <Tile key={i} entry={e} config={config} />) : <Loader />}
            </div>
            <div className="footer">
              <Button variant="noStyle">{t("See more")}</Button>
            </div>
          </div>
        ) : null}
        {!isEditing && !block.data.id ? (
          <Button className="reset danger selectPlaylist" onClick={() => setIsEditing(true)}>
            {t("Select a collection")}
          </Button>
        ) : null}
        {!isEditing && block.data.id && !!collections && !collection ? (
          <h2 className="danger notice">{t("Collection not found")}</h2>
        ) : null}
      </div>
      <ToolBar
        setArticle={setArticle}
        article={article}
        block={block}
        done={handleDone}
        setIsEditing={setIsEditing}
        isEditing={isEditing}
        t={t}
      />
    </div>
  );
};

const Embed = ({
  block,
  article,
  setArticle,
  t
}: {
  block: ArticleContentRowColumnBlock;
  article: Article;
  setArticle: any;
  t: TFunction;
}) => {
  const ref = useRef(null);
  useOutsideClick(ref, () => setIsEditing(false));
  const [isEditing, setIsEditing] = useState(!block.data.html);

  const handleContentChange = (html: string) => {
    const articleCopy = clone(article);
    const blockFound = findBlock({ article: articleCopy, id: block.id });
    if (blockFound) {
      blockFound.data.html = html;
      setArticle(articleCopy);
    }
  };

  const handleDone = () => {
    setIsEditing(false);
  };

  return (
    <div className={`block embed ${isEditing ? "isEditing" : ""}`} ref={ref}>
      <div className="content">
        {isEditing ? (
          <TextArea
            value={block.data.html || ""}
            rows="10"
            placeholder={t("Enter your HTML") + "..."}
            onChange={(e: any) => handleContentChange(e.target.value)}
          />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: block.data.html || t("Enter your HTML") + "..." }} />
        )}
      </div>
      <ToolBar
        setArticle={setArticle}
        article={article}
        // show={showToolbar}
        block={block}
        done={handleDone}
        setIsEditing={setIsEditing}
        isEditing={isEditing}
        t={t}
      />
    </div>
  );
};

const Subscribe = ({
  block,
  article,
  setArticle,
  lists,
  t
}: {
  block: ArticleContentRowColumnBlock;
  article: Article;
  setArticle: any;
  lists: List[];
  t: TFunction;
}) => {
  const [isEditing, setIsEditing] = useState(!block.data || !block.data.listRef);

  const handleSelectList = (listRef: string) => {
    const articleCopy = clone(article);
    const blockFound = findBlock({ article: articleCopy, id: block.id });
    if (blockFound) {
      blockFound.data.listRef = listRef;
      setArticle(articleCopy);
      setIsEditing(false);
    }
  };

  const handleDone = () => {
    setIsEditing(false);
  };

  const matchingList = lists && lists.find(l => l._id === block.data?.listRef);
  return (
    <div className={`block subscribe ${isEditing ? "isEditing" : ""}`}>
      <div className="content">
        {isEditing ? (
          <div className="lists">
            <p>{lists?.length ? t("Select a list of recipients") : t("You have not created a list yet")}</p>
            {lists?.map(l => (
              <Button type="button" variant="secondary" key={l._id} onClick={() => handleSelectList(l._id as string)} className="list">
                {l.title} ({l.count})
              </Button>
            ))}
          </div>
        ) : (
          <div className="details">
            <h1>{t("Subscribe to our newsletter")}</h1>
            {matchingList ? t("Selected list:") + matchingList.title : t("No recipients list was selected")}
          </div>
        )}
      </div>
      <ToolBar
        setArticle={setArticle}
        article={article}
        block={block}
        done={handleDone}
        setIsEditing={setIsEditing}
        isEditing={isEditing}
        t={t}
      />
    </div>
  );
};

interface ArticleCarouselEntry {
  media?: Media;
  format?: string;
  to?: string;
}

const CaroulselMediaEntry = ({
  entry,
  index,
  handleLinkChange,
  handleDeleteEntry,
  handleSelectMedia,
  handleSizeSelect
}: {
  entry: ArticleCarouselEntry;
  index: number;
  handleLinkChange: any;
  block: ArticleContentRowColumnBlock;
  handleDeleteEntry: any;
  handleSelectMedia: any;
  handleSizeSelect: any;
}) => {
  const formats =
    entry.media?.formatArray &&
    entry.media.formatArray.map(format => ({
      label: `${format.name} ${format.width ? `(${format.width}x${format.height || ""})` : ""}`,
      value: format.name
    }));
  const selectedFormat = formats && entry.format ? formats.find(f => f.value === entry.format) : null;

  return (
    <div className="entry media">
      <div className="entryHeader">
        <p>{index + 1}</p>
        <Button variant="noStyle" type="button" className="reset" onClick={() => handleDeleteEntry(index)}>
          <FaTimes />
        </Button>
      </div>
      <MediaSelector
        extensions={["png", "jpg", "jpeg", "svg", "gif"]}
        onSelected={(selected: Media[]) => handleSelectMedia(selected.length ? selected[0] : null, index)}
        onClear={() => handleSelectMedia(null, index)}
        media={entry.media}
      />
      <div className="linkAndFormat">
        <Input
          label={t("Link")}
          onChange={(e: any) => handleLinkChange({ value: e.target.value, index })}
          placeholder={t("Enter path such as /catalogue or external URL")}
          value={entry.to || ""}
          type="text"
          name="to"
        />
        {formats ? (
          <Select
            label={t("Image size / format")}
            options={formats}
            value={selectedFormat}
            onChange={(e: any) => handleSizeSelect({ value: e.value, index })}
          />
        ) : null}
      </div>
    </div>
  );
};

const Carousel = ({
  block: blockToCopy,
  article,
  setArticle,
  t
}: {
  block: ArticleContentRowColumnBlock;
  article: Article;
  setArticle: any;
  t: TFunction;
}) => {
  const [isEditing, setIsEditing] = useState(!blockToCopy.data.entries.length);
  const [block, setBlock] = useState(clone(blockToCopy));

  const handleSelectMedia = (media: Media, i: number) => {
    if (!media) block.data.entries[i] = { media: null, format: null };
    else {
      const formats = media.formatArray;
      block.data.entries[i] = { media, to: "", format: formats.length ? formats[0].name : null };
    }
    setBlock(clone(block));
  };

  const handleDone = () => {
    setIsEditing(false);
    const articleCopy = clone(article);
    const blockFound = findBlock({ article: articleCopy, id: block.id });
    if (blockFound) {
      const blockCopy = clone(block);
      blockCopy.data.entries = blockCopy.data.entries.filter((e: ArticleCarouselEntry) => !!e.media);
      blockFound.data = clone(blockCopy.data);
      setBlock(clone(blockCopy));
      setArticle(articleCopy);
    }
  };

  const handleLinkChange = ({ value, index }: { value: string; index: number }) => {
    block.data.entries[index].to = value;
    setBlock(clone(block));
  };

  const handleDeleteEntry = (index: number) => {
    block.data.entries.splice(index, 1);
    setBlock(clone(block));
  };

  const handleSizeSelect = ({ value, index }: { value: string; index: number }) => {
    block.data.entries[index].format = value;
    setBlock(clone(block));
  };

  const handleAddEntry = () => {
    block.data.entries.push({ media: null, to: null });
    setBlock(clone(block));
  };

  return (
    <div className={`block carousel ${isEditing ? "isEditing" : ""}`}>
      <div className="content">
        {isEditing ? (
          <div className="entries">
            {block.data.entries.map((e: ArticleCarouselEntry, i: number) => (
              <CaroulselMediaEntry
                index={i}
                key={i}
                block={block}
                entry={e}
                handleLinkChange={handleLinkChange}
                handleDeleteEntry={handleDeleteEntry}
                handleSizeSelect={handleSizeSelect}
                handleSelectMedia={handleSelectMedia}
              />
            ))}
            <div className="addEntry">
              <Button variant="noStyle" type="button" className="reset" onClick={handleAddEntry}>
                <ThemeAdd />
              </Button>
              <small>{t("Add a media")}</small>
            </div>
          </div>
        ) : null}

        {!isEditing && block.data?.entries ? (
          <CarouselComponent entries={block.data.entries.map((e: ArticleCarouselEntry) => ({ src: findUrlForMedia(e.media, e.format) }))} />
        ) : null}
      </div>
      <ToolBar
        setArticle={setArticle}
        article={article}
        // show={showToolbar}
        block={block}
        done={handleDone}
        setIsEditing={setIsEditing}
        isEditing={isEditing}
        t={t}
      />
    </div>
  );
};

const Separator = ({
  article,
  block,
  setArticle,
  t
}: {
  article: Article;
  block: ArticleContentRowColumnBlock;
  setArticle: any;
  t: TFunction;
}) => {
  return (
    <div className="block separator">
      <small>{t("Separator")}</small>
      <hr />
      <ToolBar setArticle={setArticle} article={article} block={block} t={t} />
    </div>
  );
};

export const Blog = ({
  article,
  block,
  setArticle,
  tags: globalTags,
  t
}: {
  tags: string[];
  article: Article;
  block: ArticleContentRowColumnBlock;
  setArticle: any;
  t: TFunction;
}) => {
  const [isEditing, setIsEditing] = useState(!block.data.tags?.length);
  const [options, setOptions] = useState<ArticleBlockBlogOptions>(clone(block.data));

  const { data } = useQuery(GET_ARTICLES, { variables: { ...options } });
  const sizes = [
    {
      name: "thumbnail",
      width: 300,
      height: 300,
      options: {
        fit: "cover",
        position: "center"
      }
    },
    {
      name: "square",
      width: 600,
      height: 600,
      options: {
        fit: "cover",
        position: "center"
      }
    },
    {
      name: "banner",
      width: 1920,
      height: 800,
      options: {
        fit: "cover",
        position: "center"
      }
    },
    {
      name: "banner-free-ratio",
      width: 1920
    },
    {
      name: "banner-sm",
      width: 1440,
      height: 600,
      options: {
        fit: "cover",
        position: "center"
      }
    },
    {
      name: "large",
      width: 1440
    },
    {
      name: "medium",
      width: 1024
    },
    {
      name: "small",
      width: 720
    }
  ];
  const sorts = [
    { label: t("Published - latest first"), value: { key: "published", order: -1 } },
    { label: t("Published - oldest first"), value: { key: "published", order: 1 } }
  ];

  const articles = data?.articlesConnection?.articles || [];

  const handleRowBlogTagChange = (value: any) => {
    setOptions({ ...options, tags: value.map((v: any) => v.value) });
  };

  const handleSizeSelect = (value: any) => {
    setOptions({ ...options, format: value.value });
  };

  const handleRowBlogSortChange = (value: any) => {
    setOptions({ ...options, sort: value.value });
  };

  const handleRowBlogColumnsChange = (value: any) => {
    setOptions({ ...options, columns: value.value });
  };

  const handleArticleBlogNumberChange = (e: any) => {
    setOptions({ ...options, [e.target.name]: parseInt(e.target.value) });
  };

  const handleDone = () => {
    setIsEditing(false);
    const articleCopy = clone(article);
    const blockFound = findBlock({ article: articleCopy, id: block.id });
    if (blockFound) {
      blockFound.data = clone(options);
      setArticle(articleCopy);
      setIsEditing(false);
    }
  };

  const selectedBlogFormat = sizes.find((s: any) => s.name === options.format);
  const selectedSort = sorts.find((s: any) => s.value.key === options?.sort?.key && s.value.order === options?.sort?.order);
  const formatToLabel = (f: any) => `${f.name} - ${f.width}${f.height ? `x${f.height}` : ""}`;
  const columnsChoice = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 }
  ];
  const selectedColums = columnsChoice.find((s: any) => s.value === options?.columns);

  if (isEditing)
    return (
      <div className="block blog blogEditor isEditing">
        <div className="options">
          <div className="left">
            <Select
              label={t("Filter articles by tags")}
              value={options?.tags?.map((tag: any) => ({ label: tag, value: tag }))}
              options={globalTags.map((tag: any) => ({ label: tag, value: tag }))}
              isMulti={true}
              isClearable={true}
              onChange={handleRowBlogTagChange}
            />
            <Select
              label={t("Image size / format")}
              options={sizes.map((s: any) => ({ label: formatToLabel(s), value: s.name }))}
              value={selectedBlogFormat ? { label: formatToLabel(selectedBlogFormat) } : null}
              onChange={handleSizeSelect}
            />
            <Select label={t("Sort by")} value={selectedSort || null} options={sorts} onChange={handleRowBlogSortChange} />
          </div>
          <div>
            <Input
              label={t("Number of articles to load")}
              placeholder={t("Value between 1 and 50")}
              min="1"
              required
              max="50"
              name="limit"
              value={options.limit || ""}
              type="number"
              onChange={handleArticleBlogNumberChange}
            />
            <Select
              label={t("Number of columns")}
              value={selectedColums || null}
              options={columnsChoice}
              onChange={handleRowBlogColumnsChange}
            />
          </div>
        </div>
        <ToolBar
          block={block}
          article={article}
          setArticle={setArticle}
          done={handleDone}
          setIsEditing={setIsEditing}
          isEditing={isEditing}
          t={t}
        />
      </div>
    );

  return (
    <div className="block blog">
      <div className={`columns columns-${options.columns}`}>
        {articles.map((e: any) => (
          <BlogEntry key={e._id} article={e} format={options.format} t={t} />
        ))}
      </div>
      <ToolBar
        block={block}
        article={article}
        setArticle={setArticle}
        done={handleDone}
        setIsEditing={setIsEditing}
        isEditing={isEditing}
        t={t}
      />
    </div>
  );
};

const BlogEntry = ({ article, format, t }: { article: Article; format: string | null; t: TFunction }) => {
  let bannerImage;
  const thumbnail = article.thumbnail as Media | null;
  const bannerFormat = thumbnail?.formatArray?.find((f: any) => f.name === format);
  if (bannerFormat) {
    bannerImage = bannerFormat?.url;
  } else bannerImage = thumbnail?.url;

  return (
    <div className="blogEntry">
      {bannerImage ? <img src={bannerImage} /> : <small>{t("No thumbnail available")}...</small>}
      <h2 style={{ padding: "var(--gutter" }}>{article.title}</h2>
    </div>
  );
};

export { BlockProposal };
