import React, { useState, useEffect, useRef } from "react";
import InputWithIcon from "../../styled/inputWithIcon";
import { Button } from "../../../componentsV2/Button";
import Modal from "../../modal";
import Loader from "../../common/loader";
import Price, { PriceInput } from "../../common/price";
import { addVoucher, addDiscount } from "../../../@services/orderService";
import { SearchVouchers } from "../../global/search";
import { GET_CREDITS } from "../../../graphql/queries/credit";
import { useLazyQuery } from "@apollo/client";
import { Credit, Order, Voucher } from "../../../__generated__/graphql";
import { AddNotification } from "../../../types/globals";
import { useTranslation } from "react-i18next";
import { ModalHeaderContainer } from "../../../componentsV2/SectionHeader/SectionHeader.styles";
import { Typography } from "../../../componentsV2/Typography";

interface CreditEditable extends Credit {
  selected?: boolean;
  amount?: number;
}

export default ({ order, addNotification, onSubmitted }: { order: Order; addNotification: AddNotification; onSubmitted: any }) => {
  const [selectedVoucher, setSelectedVoucher] = useState<Voucher | undefined | null>(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [creditNotes, setCreditNotes] = useState<CreditEditable[] | undefined>(undefined);
  const modalRef = useRef<any>();
  const [discountType, setDiscountType] = useState("value");
  const [redeemType, setRedeemType] = useState("percentage");
  const [value, setValue] = useState<number | undefined>();
  const [getCreditNotes] = useLazyQuery(GET_CREDITS);

  const { t } = useTranslation();

  const orderBuyerRef = order.buyer && order.buyer._id;

  useEffect(() => {
    if (discountType === "creditNote" && orderBuyerRef) {
      getCreditNotes({ variables: { userRef: orderBuyerRef } })
        .then(({ data }) => {
          setCreditNotes(data?.credits.credits);
        })
        .catch(e => addNotification({ ok: 0, message: e.data }));
    }
  }, [discountType, addNotification, orderBuyerRef]);

  const handleSelectVoucher = async (voucher: Voucher) => {
    setSelectedVoucher({ ...voucher });
  };

  const handleRemoveVoucher = () => {
    setSelectedVoucher(null);
  };

  const handleSubmitVoucher = (e: any) => {
    e.preventDefault();
    if (!selectedVoucher) return addNotification({ ok: 0, message: "Please search and select a voucher" });
    setIsSubmitting(true);
    addVoucher({ voucherRef: selectedVoucher._id, orderRef: order._id })
      .then(({ data }) => {
        onSubmitted(data.order);
        addNotification(data);
      })
      .catch(e => addNotification({ ok: 0, message: e.data }))
      .then(() => setIsSubmitting(false));
  };

  const handleSubmitDiscount = (e: any) => {
    e.preventDefault();
    setIsSubmitting(true);
    addDiscount({ type: redeemType, orderRef: order._id, value })
      .then(({ data }) => {
        onSubmitted(data.order);
        addNotification(data);
      })
      .catch(e => {
        console.log(e);
        addNotification({ ok: 0, message: e.data });
      })
      .then(() => setIsSubmitting(false));
  };

  const handleSelectCreditNote = (i: number) => {
    if (!creditNotes) return;
    creditNotes?.forEach(c => {
      c.selected = false;
    });

    creditNotes[i].selected = !creditNotes[i].selected;
    setCreditNotes([...creditNotes]);
  };

  const handleCreditNoteAmountChange = (e: any, idx: number) => {
    if (!creditNotes) return;
    creditNotes[idx].amount = parseFloat(e.target.value);
    setCreditNotes([...creditNotes]);
  };

  const selectedCreditNote = creditNotes && creditNotes.find(c => c.selected && c.amount);

  const handleSubmitCreditNote = (e: any) => {
    e.preventDefault();
    if (!selectedCreditNote) return addNotification({ ok: 0, message: "No credit note selected" });
    setIsSubmitting(true);
    addDiscount({
      type: discountType,
      amount: selectedCreditNote.amount,
      orderRef: order._id,
      creditNoteRef: selectedCreditNote._id
    })
      .then(({ data }) => {
        onSubmitted(data.order);
        addNotification(data);
      })
      .catch(e => addNotification({ ok: 0, message: e.data }))
      .then(() => setIsSubmitting(false));
  };

  return (
    <section id="addVoucher">
      <Modal style={{}} ref={modalRef}>
        <div id="addVoucherModal" className="">
          <ModalHeaderContainer>
            <Typography variant="pageTitle" tag="h2">
              {t("Add a discount")}
            </Typography>
            <button className="reset" type="button" onClick={() => modalRef.current.close()}>
              <i className="cg-icon-burger-close" />
            </button>
          </ModalHeaderContainer>
          <hr />
          <div className="discountSelector">
            <form onSubmit={(e: any) => e.preventDefault()}>
              <label>
                <input
                  type="radio"
                  name="selector"
                  value="value"
                  onChange={() => setDiscountType("value")}
                  checked={discountType === "value"}
                />
                {t("Add a percentage or fixed amount discount")}
              </label>
              <label>
                <input
                  type="radio"
                  name="selector"
                  onChange={() => setDiscountType("voucher")}
                  value="voucher"
                  checked={discountType === "voucher"}
                />
                {t("Add a voucher discount")}
              </label>
              {process.env.whatever ? (
                <label>
                  <input
                    type="radio"
                    name="selector"
                    onChange={() => setDiscountType("creditNote")}
                    value="creditNote"
                    checked={discountType === "creditNote"}
                  />
                  {t("Add from a credit note")}
                </label>
              ) : null}
            </form>
          </div>
          <hr />

          {discountType === "value" ? (
            <form className="mainForm" onSubmit={handleSubmitDiscount}>
              <div className="valueDiscount">
                <div className="options">
                  <div className="redeemType">
                    <label>
                      <input
                        type="radio"
                        name="selector"
                        value="percentage"
                        onChange={() => setRedeemType("percentage")}
                        checked={redeemType === "percentage"}
                      />
                      {t("Percentage")} %
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="selector"
                        onChange={() => setRedeemType("value")}
                        value="value"
                        checked={redeemType === "value"}
                      />
                      {t("Fixed amount")}
                    </label>
                  </div>
                  <div className="value">
                    {redeemType === "value" ? (
                      <PriceInput
                        value={value}
                        onChange={(e: any) => setValue(e.target.value)}
                        max={String(order.totals.grand)}
                        placeholder={t("Enter an amount inferior or equal to") + " " + order.totals.grand}
                      />
                    ) : (
                      <InputWithIcon
                        type="number"
                        steps="0.001"
                        required
                        icon="%"
                        iconPosition="right"
                        placeholder={t("Enter a percentage between 0% and 100%")}
                        value={value}
                        onChange={(e: any) => setValue(e.target.value)}
                        onWheel={(e: any) => e.target.blur()}
                        min="0"
                        max={"100"}
                      />
                    )}
                  </div>
                </div>
                <Button disabled={isSubmitting} type="submit" variant="primary">
                  {isSubmitting ? <Loader /> : t("Submit")}
                </Button>
              </div>
            </form>
          ) : null}
          {discountType === "voucher" ? (
            <form className="mainForm" onSubmit={handleSubmitVoucher}>
              <div className="voucherDiscount">
                <SearchVouchers
                  label={t("Search and select a voucher")}
                  voucher={selectedVoucher}
                  removeVoucher={handleRemoveVoucher}
                  onSelect={handleSelectVoucher}
                  placeholder={t("Voucher ID")}
                />
                <p className="note">
                  {t("Note: when submitting a voucher discount, totals cannot be changed and order must be locked.")}
                  <br />
                  {t("Make sure the order is ready for invoicing.")}
                </p>
                <Button disabled={isSubmitting || !selectedVoucher} type="submit" variant="primary">
                  {isSubmitting ? <Loader /> : t("Submit and lock order")}
                </Button>
              </div>
            </form>
          ) : null}
          {discountType === "creditNote" ? (
            <form className="mainForm" onSubmit={handleSubmitCreditNote}>
              <div className="creditNoteDiscount">
                {!orderBuyerRef ? (
                  <p>{t("No user assigned to this order")}</p>
                ) : (
                  <>
                    <label>
                      {t("Select a credit note for")} {order.buyer?.name}
                    </label>
                    {creditNotes &&
                      creditNotes.map((c, idx) => (
                        <div key={idx} className="creditNote">
                          <button onClick={() => handleSelectCreditNote(idx)} type="button" className={c.selected ? "selected" : ""}>
                            {c.title} / {c.reference} <Price value={c.totals.balance as number} /> {t("Available").toLowerCase()}
                          </button>
                          {c.selected ? (
                            <label>
                              <PriceInput
                                type="number"
                                min="0"
                                max={String(order.totals.grand)}
                                value={c.amount}
                                onChange={(e: any) => handleCreditNoteAmountChange(e, idx)}
                                placeholder={t("Amount to deduct")}
                              />
                            </label>
                          ) : null}
                        </div>
                      ))}
                    {creditNotes && creditNotes.length === 0 ? (
                      <p className="noCredit">
                        <strong>{t("No credit notes found for this user")}</strong>
                      </p>
                    ) : null}
                    <Button disabled={isSubmitting || !selectedCreditNote} type="submit" variant="primary">
                      {isSubmitting ? <Loader /> : t("Submit and lock order")}
                    </Button>
                  </>
                )}
              </div>
            </form>
          ) : null}
        </div>
      </Modal>
      <Button variant="secondary" onClick={() => modalRef.current.open()}>
        {t("Add a discount")}
      </Button>
    </section>
  );
};
