import React, { useRef, useState } from "react";
import { MultiSelectValueType } from "./MultiSelect.types";
import {
  StyledContainer,
  StyledPopUpContainer,
  StyledPopUpListContainer,
  StyledSelectedLabel,
  StyledSeparator,
  StyledTagContainer,
  StyledTitle
} from "./MultiSelect.styles";
import { AddIcon, ClearIcon } from "../SVGIcons";
import { Checkbox } from "../Checkbox";
import { Button } from "../Button";
import { useHandleClickOutside } from "../../hooks/useHandleClickOutside";
import { getOptionsLabel, truncateLabel } from "./MultiSelect.utils";
import { Typography } from "../Typography";
import { usePopupPosition } from "./MultiSelect.hooks";
import { GlobalStore } from "../../stores/global";
import { useTranslation } from "react-i18next";

type Props = {
  title: string;
  options: MultiSelectValueType[];
  selectedOptions: MultiSelectValueType[];
  handleChange: (options: MultiSelectValueType[]) => void;
  limit?: number;
};

export const MultiSelect: React.FC<Props> = ({ title, selectedOptions = [], options, handleChange, limit }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [checkedOptions, setCheckedOptions] = useState<MultiSelectValueType[]>(selectedOptions);
  const containerRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const { isMobile } = GlobalStore.useState(c => c);
  const { t } = useTranslation();

  const hasDefaultOptions = options.length > 0;
  const hasSelectedOptions = selectedOptions?.length > 0;
  const optionsLabel = getOptionsLabel(selectedOptions);
  const popupPosition = usePopupPosition(containerRef, popupRef, isOpen);

  const handleClearSelection = (e: React.MouseEvent) => {
    e.stopPropagation();
    setCheckedOptions([]);
    handleChange([]);
    setIsOpen(false);
  };

  const handleCheckboxClick = (option: MultiSelectValueType) => {
    const isOptionChecked = checkedOptions.some(checkedOption => checkedOption.value === option.value);
    if (!isOptionChecked) {
      return setCheckedOptions(state => [...state, option]);
    }
    setCheckedOptions(state => state?.filter(stateOption => stateOption.value !== option.value));
  };

  const handleButtonClick = () => {
    setIsOpen(false);
    handleChange(checkedOptions);
  };

  useHandleClickOutside(containerRef, () => {
    setIsOpen(false);
    setCheckedOptions(selectedOptions);
  });

  return (
    <StyledContainer ref={containerRef}>
      <StyledTagContainer
        active={isOpen}
        hasSelectedOptions={hasSelectedOptions}
        data-testid="tag-container"
        onClick={() => {
          setIsOpen(!isOpen);
        }}>
        {!hasSelectedOptions && <AddIcon datatestid="add-icon" />}
        {hasSelectedOptions && <ClearIcon datatestid="clear-icon" onClick={handleClearSelection} />}
        <StyledTitle>
          {title}
          {hasSelectedOptions && (
            <>
              <StyledSeparator>|</StyledSeparator>
              {isMobile ? (
                <StyledSelectedLabel>{`${optionsLabel.length} filters`}</StyledSelectedLabel>
              ) : (
                optionsLabel.map((option, i) => {
                  const lastItem = i === optionsLabel?.length - 1;
                  const truncatedLabel = truncateLabel(option);
                  const title = truncatedLabel === option ? "" : option;
                  const optionCopy = `${truncatedLabel}${lastItem ? "" : ", "}`;
                  return (
                    <StyledSelectedLabel title={title} key={`${option}-${i}`}>
                      {optionCopy}
                    </StyledSelectedLabel>
                  );
                })
              )}
            </>
          )}
        </StyledTitle>
      </StyledTagContainer>
      <StyledPopUpContainer data-testid="multiselect-popup" ref={popupRef} style={{ display: isOpen ? "block" : "none", ...popupPosition }}>
        <StyledPopUpListContainer>
          {hasDefaultOptions ? (
            options?.map((option, i) => {
              const checked = checkedOptions.some(checkedOption => checkedOption.value === option.value);
              const disabled = !checked && checkedOptions.length === limit;
              const truncatedLabel = truncateLabel(option.label);
              const title = truncatedLabel === option.label ? "" : option.label;
              const marginTop = i !== 0 ? "5px" : "0";
              return (
                <Checkbox
                  key={option.label}
                  label={truncatedLabel}
                  onClick={() => handleCheckboxClick(option)}
                  checked={checked}
                  containerStyleProps={{ marginTop }}
                  disabled={disabled}
                  datatestid={`checkbox-${option.label}`}
                  title={title}
                />
              );
            })
          ) : (
            <Typography variant="copy" tag="span" style={{ whiteSpace: "nowrap" }}>
              {t("No options available")}
            </Typography>
          )}
        </StyledPopUpListContainer>
        {hasDefaultOptions && (
          <Button fullWidth variant="secondary" onClick={handleButtonClick} styleProps={{ marginTop: "5px" }}>
            {t("Submit")}
          </Button>
        )}
      </StyledPopUpContainer>
    </StyledContainer>
  );
};
