import React, { useState, useRef } from "react";
import Button from "../../../components/styled/button";
import Input from "../../../components/styled/input";
import { Link, useHistory, useLocation } from "react-router-dom";
import Loader from "../../../components/common/loader";
import Liner from "../../../components/common/liner";
import { FaHome } from "react-icons/fa";
import moment from "moment";
import { GlobalStore } from "../../../stores/global";
import Modal from "../../../components/modal";
import { GET_ARTICLES, POST_ARTICLE } from "../../../graphql/queries/article";
import { useMutation, useQuery } from "@apollo/client";
import { ArticlesPagination } from "../../../components/common/pagination";
import URI from "urijs";
import SearchBySubmit from "../../../components/styled/searchBySubmit";
import { MultiSelect } from "../../../componentsV2/MultiSelect";
import { mapTagsToMultiSelectType } from "./Articles.utils";
import { useHandleFilterChange, useSelectedTags } from "./Articles.hooks";
import { StyledFilterContainer } from "./Articles.styles";
import { Typography } from "../../Typography";
import { Button as ButtonV2 } from "../../../componentsV2/Button";
import { useTranslation } from "react-i18next";
import { ModalHeaderContainer } from "../../SectionHeader/SectionHeader.styles";

export const Articles = () => {
  const { addNotification } = GlobalStore.useState(c => c);
  const history = useHistory();
  const modalRef = useRef<any>(null);
  const location = useLocation();
  const [newArticleTitle, setNewArticleTitle] = useState("");
  const currentUri = new URI(location.pathname + location.search);
  const searchQuery = currentUri.search(true);
  const term = searchQuery?.term || "";
  const { t } = useTranslation();

  const selectedTags = useSelectedTags();
  const tags = selectedTags.map(tag => tag.value);
  const { handleFilterChange } = useHandleFilterChange("tags");

  const [createArticle] = useMutation(POST_ARTICLE);
  const { data } = useQuery(GET_ARTICLES, {
    fetchPolicy: "cache-and-network",
    variables: { page: parseInt(searchQuery.page || 1), tags, term }
  });
  const [isCreatingArticle, setIsCreatingArticle] = useState(false);

  document.title = "Articles";

  const mappedTags = mapTagsToMultiSelectType(data?.articleTags as any);

  const handleCreateArticle = async (e: any) => {
    e.preventDefault();
    if (isCreatingArticle) return;
    try {
      setIsCreatingArticle(true);
      const { data } = await createArticle({ variables: { title: e.target.title.value } });
      if (!data?.articleCreate) throw new Error("Error while creating article");
      addNotification({ ok: 1, message: "Article created" });
      history.push(`/article/${data.articleCreate.id}/edit`);
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    } finally {
      setIsCreatingArticle(false);
    }
  };

  const handleLineClick = (article: any) => {
    history.push(`/article/${article.id}/edit`);
  };

  const handleSearch = (value: string) => {
    if (value) currentUri.setSearch({ term: value }).removeSearch(["page", "tags"]);
    else currentUri.removeSearch("term");
    history.push(currentUri.resource());
  };

  const pagination = data?.articlesConnection?.pagination;
  const articles = data?.articlesConnection?.articles;

  return (
    <div id="articles">
      <Modal ref={modalRef} style={{}}>
        <div id="newThemeModal">
          <form onSubmit={handleCreateArticle}>
            <ModalHeaderContainer>
              <Typography variant="pageTitle" tag="h2">
                {t("Create an article")}
              </Typography>
              <button className="reset" type="button" onClick={() => modalRef.current.close()}>
                <i className="cg-icon-burger-close" />
              </button>
            </ModalHeaderContainer>
            <div className="content">
              <Input
                disabled={isCreatingArticle}
                autoFocus={true}
                value={newArticleTitle}
                label="Enter a title for this new article"
                type="text"
                name="title"
                placeholder="Enter a title"
                autoComplete="off"
                required
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewArticleTitle(e.target.value)}
              />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <span />
                <ButtonV2 disabled={isCreatingArticle || !newArticleTitle} variant="primary" type="submit">
                  {isCreatingArticle ? <Loader /> : t("Submit")}
                </ButtonV2>
              </div>
            </div>
          </form>
        </div>
      </Modal>
      <section className="header">
        <div className="left">
          <Typography variant="pageTitle" tag="h1">
            {t("Articles")} ({pagination?.count})
          </Typography>
        </div>
        <div className="right">
          <ButtonV2 type="button" variant="primary" onClick={() => modalRef.current.open()}>
            {t("Create an article")}
          </ButtonV2>
        </div>
      </section>
      <StyledFilterContainer>
        <SearchBySubmit
          type="text"
          label={t("Search articles")}
          placeholder={t("Search articles") + "..."}
          fullWidth
          name="term"
          value={term}
          autoComplete="off"
          submitText="Search"
          onSubmit={handleSearch}
          onClear={handleSearch}
        />
        <MultiSelect title="Tags" options={mappedTags} selectedOptions={selectedTags} handleChange={handleFilterChange} />
      </StyledFilterContainer>
      <div className="content">
        <div className="articlesTable">
          {pagination?.pages ? (
            <ArticlesPagination
              pagination={pagination}
              onPrevious={() => history.push(currentUri.setSearch("page", (pagination.page || 1) - 1).toString())}
              onNext={() => history.push(currentUri.setSearch("page", (pagination.page || 1) + 1).toString())}
            />
          ) : null}
          <div className="article header">
            <p>#</p>
            <p>{t("Title")}</p>
            <p>{t("Tags")}</p>
            <p>{t("Created")}</p>
            <p>{t("Status")}</p>
            <p>{t("Link")}</p>
          </div>
          {articles?.map((a, index: number) => (
            <Liner onClick={() => handleLineClick(a)} index={index} key={a.id} className="article">
              <p>
                <Link to={`/article/${a.id}/edit`}>#{a.id}</Link>
              </p>
              <Link to={`/article/${a.id}/edit`}>
                <p>
                  {a.homepage ? (
                    <>
                      <FaHome />
                    </>
                  ) : null}
                  {a.title}
                </p>
              </Link>
              <p className="tags">
                {a.tags.map((a: any) => (
                  <span key={a} className="tag">
                    {a}
                  </span>
                ))}
              </p>
              <p>
                {t("Created")} {moment(a.created.date).format("ll")}
                <br />
                {t("by")} {a.created.user?.name}
              </p>
              <p>{a.published && a.published.status ? `Published ${moment(a.published.date).fromNow()}` : "Draft"}</p>
              <div className="buttons">
                {a.previewUri ? (
                  <a onClick={(e: any) => e.stopPropagation()} href={a.previewUri} target="_tab">
                    <Button variant="secondary" type="button">
                      {t("View on Eshop")}
                    </Button>
                  </a>
                ) : null}
              </div>
            </Liner>
          ))}
          {pagination?.pages && pagination?.pages > 1 ? (
            <ArticlesPagination
              pagination={pagination}
              onPrevious={() => history.push(currentUri.setSearch("page", (pagination.page || 1) - 1).toString())}
              onNext={() => history.push(currentUri.setSearch("page", (pagination.page || 1) + 1).toString())}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
