import React from "react";
import Button from "../styled/button";
import Zone from "../styled/zone";
import Input from "../styled/input";
import { Select } from "../styled/select";
import styled from "styled-components";
import { Address as IAddress } from "../../__generated__/graphql";
import { useTranslation } from "react-i18next";
const { getData, getName } = require("country-list");

const FieldSet = styled.fieldset`
  & > * {
    margin-bottom: var(--gutter);
  }
`;

const AddressInline = styled.fieldset`
  display: flex;
  justify-content: space-between;
  gap: var(--gutter);
`;

function Address({
  address,
  handleChange,
  index,
  removeAddress
}: {
  address: IAddress;
  handleChange: any;
  index: number;
  removeAddress: any;
}) {
  const { t } = useTranslation();
  const addressTypeValue = { label: "", value: "" };
  switch (address.type) {
    case "billingAndShipping":
      addressTypeValue.label = addressTypeValue.value = t("Billing & Shipping");
      break;
    case "billing":
      addressTypeValue.label = addressTypeValue.value = t("Billing");
      break;
    case "shipping":
      addressTypeValue.label = addressTypeValue.value = t("Shipping");
      break;
    default:
  }

  return (
    <Zone className="address">
      <FieldSet>
        <Input
          variant="overZone"
          label={t("Address description")}
          type="text"
          autoComplete="off"
          name="description"
          value={address.description || ""}
          onChange={(e: any) => handleChange(index, e.target.name, e.target.value)}
        />
        <Select
          variant="overZone"
          label={t("Address type")}
          options={[
            { value: "billingAndShipping", label: t("Billing & Shipping") },
            { value: "billing", label: t("Billing") },
            { value: "shipping", label: t("Shipping") }
          ]}
          value={addressTypeValue}
          onChange={(option: any) => handleChange(index, "type", option.value)}
        />
        <Input
          label={t("Address line 1")}
          variant="overZone"
          type="text"
          name="addressLine1"
          required
          value={address.addressLine1 || ""}
          onChange={(e: any) => handleChange(index, e.target.name, e.target.value)}
        />
        <Input
          label={t("Address line 2")}
          variant="overZone"
          type="text"
          name="addressLine2"
          value={address.addressLine2 || ""}
          onChange={(e: any) => handleChange(index, e.target.name, e.target.value)}
        />
        <AddressInline>
          <Input
            label={t("City")}
            variant="overZone"
            type="text"
            name="city"
            required
            value={address.city || ""}
            onChange={(e: any) => handleChange(index, e.target.name, e.target.value)}
          />
          <Input
            label={t("Post code / zip")}
            variant="overZone"
            type="text"
            name="postCode"
            value={address.postCode || ""}
            onChange={(e: any) => handleChange(index, e.target.name, e.target.value)}
          />
        </AddressInline>
        <div className="addressInline">
          <Input
            label={t("State")}
            type="text"
            variant="overZone"
            name="state"
            value={address.state || ""}
            onChange={(e: any) => handleChange(index, e.target.name, e.target.value)}
          />
          <Select
            label={t("Country")}
            variant="overZone"
            value={{ value: address.alpha2, label: getName(address.alpha2 || "") }}
            onChange={(option: any) => handleChange(index, "alpha2", option.value)}
            options={getData().map((c: any) => ({ label: c.name, value: c.code }))}
          />
        </div>
      </FieldSet>
      <Button onClick={() => removeAddress(index)} variant="danger" type="button">
        {t("Remove")}
      </Button>
    </Zone>
  );
}

export default Address;
