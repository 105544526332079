import React from "react";
import clone from "clone";
import Button from "../../../../components/styled/button";
import Input from "../../../../components/styled/input";
import { ConfigTaxDefinition } from "../../../../__generated__/graphql";
import { useTranslation } from "react-i18next";

export default function TaxEditor({ definitions, update }: { definitions: ConfigTaxDefinition[]; update: any }) {
  const { t } = useTranslation();

  const handleAddTax = (e: any) => {
    e.preventDefault();
    const defs = clone(definitions);
    defs.push({
      title: "",
      name: "",
      rate: 20,
      default: false
    });
    update(defs);
  };

  const handleInputChange = (e: any, i: number) => {
    const defs = clone(definitions);
    const tax = defs[i];
    // @ts-ignore
    if (e.target.type === "checkbox") tax[e.target.name] = e.target.checked;
    else {
      const name = e.target.name;
      const value = e.target.value;
      // @ts-ignore
      tax[name] = e.target.value;
      if (name === "title") tax.title = value;
      else if (name === "name") tax.name = value;
    }
    update(defs);
  };

  const handleInputAmountChange = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
    const defs = clone(definitions);
    const tax = defs[i];

    const rate = e.target.value;
    tax.rate = rate as unknown as number;
    update(defs);
  };

  const handleRemoveTax = (i: number) => {
    const defs = clone(definitions);
    defs.splice(i, 1);
    update(defs);
  };

  return (
    <div id="taxEditor">
      <h2>{t("Additional tax definitions")}</h2>
      <hr />
      <div className="rows">
        <div className="row">
          <span>{t("Name")}</span>
          <span>{t("Description")}</span>
          <span>{t("Percentage")}</span>
        </div>
        {definitions.map((tax, i) => (
          <div key={i} className="row tax">
            <Input
              variant="overZone"
              className="shortName"
              type="text"
              required
              readOnly={tax.default}
              name="name"
              placeholder={t("VAT, GST") + "..."}
              value={tax.name || ""}
              onChange={(e: any) => handleInputChange(e, i)}
            />
            <Input
              variant="overZone"
              type="text"
              required
              readOnly={tax.default && !!tax.title}
              name="title"
              placeholder={t("Provincial sales tax") + "..."}
              value={tax.title || ""}
              onChange={(e: any) => handleInputChange(e, i)}
            />
            <Input
              variant="overZone"
              type="number"
              required
              readOnly={tax.default}
              min="0"
              max="100"
              step="0.001"
              name="rate"
              placeholder="20%..."
              value={tax.rate === undefined ? "" : tax.rate}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputAmountChange(e, i)}
            />
            {!tax.default ? (
              <Button type="button" variant="danger" onClick={() => handleRemoveTax(i)}>
                {t("Delete")}
              </Button>
            ) : (
              <span>{t("Default")}</span>
            )}
          </div>
        ))}
      </div>
      <Button variant="secondary" className="submitMainInformation" onClick={handleAddTax} type="button">
        {t("Add a new tax definition")}
      </Button>
    </div>
  );
}
