import React, { useState, useRef } from "react";
import Checkbox from "../styled/checkbox";
import Button from "../styled/button";
import { Button as ButtonV2 } from "../../componentsV2/Button";
import Input from "../styled/input";
import Zone from "../styled/zone";
import { Link } from "react-router-dom";
import { SelectCredit } from "./select";
import moment from "moment";
import DatePicker from "../styled/datePicker";
import Price, { PriceInput } from "./price";
import Modal, { PropsToForward } from "../modal";
import { useMutation } from "@apollo/client";
import {
  POST_REMOVE_PAYMENT_METHOD,
  POST_ADD_PAYMENT_METHOD,
  POST_ADD_REFUND_METHOD,
  POST_REMOVE_REFUND_METHOD
} from "../../graphql/queries/order";
import { Order, PaymentMethod } from "../../__generated__/graphql";
import { AddNotification } from "../../types/globals";
import isNumber from "is-number";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { ModalHeaderContainer } from "../../componentsV2/SectionHeader/SectionHeader.styles";
import { Typography } from "../../componentsV2/Typography";
interface HandlePaymentMethodAmountChange {
  (method: PaymentMethod, value: number): void | undefined;
}

const InputComponent = ({
  method,
  onAmountChange,
  onSave
}: {
  method: PaymentMethod;
  onAmountChange: HandlePaymentMethodAmountChange;
  onSave: any;
}) => {
  const { t } = useTranslation();
  return (
    <Input
      variant="overZone"
      onBlur={(e: any) => onSave && onSave(method, e.target.value)}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onAmountChange && onAmountChange(method, parseFloat(e.target.value))}
      onKeyPress={(e: any) => e.target.keyCode === 13 && e.preventDefault()}
      placeholder={MeansOfPayments(t).find(m => m.origin === method.origin)?.placeholder}
      type="number"
      step="0.01"
      onWheel={(e: any) => e.target.blur()}
      value={method.amount || ""}
    />
  );
};

const CreditComponent = ({ handleAddCreditNote, userRef }: { handleAddCreditNote: any; userRef: string }) => {
  const { t } = useTranslation();

  if (!userRef) return <p>{t("A buyer must be assigned to this order")}</p>;

  return (
    <div className="payWithcreditNote">
      <SelectCredit
        label={t("Pay with credit note")}
        variant="overZone"
        userRef={userRef}
        onChange={(ref: string) => handleAddCreditNote(ref)}
      />
    </div>
  );
};

export interface MeanOfPayment {
  origin: string;
  type: string;
  icon: string;
  placeholder: string;
  component: any;
  added?: Date;
  amount?: number;
}
const MeansOfPayments = (t: TFunction): MeanOfPayment[] => [
  {
    origin: "cash",
    type: "input",
    icon: "cg-icon-cash",
    placeholder: t("In cash"),
    component: InputComponent
  },
  {
    origin: "card",
    type: "input",
    icon: "cg-icon-card",
    placeholder: t("Paid on card"),
    component: InputComponent
  },
  {
    origin: "stripe",
    type: "input",
    icon: "cg-icon-stripe",
    placeholder: t("By Stripe"),
    component: InputComponent
  },
  {
    origin: "paypal",
    type: "input",
    icon: "cg-icon-paypal",
    placeholder: t("By Paypal"),
    component: InputComponent
  },
  {
    origin: "cheque",
    type: "input",
    icon: "cg-icon-cheque",
    placeholder: t("By cheque"),
    component: InputComponent
  },
  {
    origin: "bankTransfer",
    type: "input",
    icon: "cg-icon-bank-transfer",
    placeholder: t("Via bank transfer"),
    component: InputComponent
  },
  {
    origin: "creditNote",
    type: "creditNote",
    icon: "cg-icon-credit-note",
    placeholder: t("With credit note"),
    component: CreditComponent
  }
];

const Methods = ({ filter = [], onAmountFill }: { filter: string[]; onAmountFill: (m: any) => void }) => {
  const { t } = useTranslation();
  return (
    <div className="paymentMethods">
      {MeansOfPayments(t)
        .filter(p => filter.indexOf(p.origin) === -1)
        .map((m, index) => (
          <div key={index} className="paymentMethod">
            <Button variant="secondaryOverZone" type="button" onClick={() => onAmountFill(m)} className="icon">
              <i className={m.icon} />
            </Button>
            {/* <m.component method={methods.find(method => method.origin === m.origin) || m} onAmountChange={onAmountChange} onSave={onSave} /> */}
          </div>
        ))}
    </div>
  );
};

const OrderPaymentMethods = ({
  isMobile,
  order,
  refetch,
  addNotification
}: {
  isMobile: boolean;
  order: Order;
  refetch: any;
  addNotification: AddNotification;
}) => {
  const { t } = useTranslation();
  const defaultMethods = MeansOfPayments(t).map(m => ({ ...m, amount: "" }));
  if (order.billing && Array.isArray(order.billing.paymentMethods)) {
    order.billing.paymentMethods.forEach(om => {
      const pm = defaultMethods.find(pm => pm.origin === om.origin);
      // @ts-ignore
      if (pm) pm.amount = om.amount;
    });
  }
  const isNewOrder = order.status === "New Order" || order.status === "Invoice Sent";
  const hasGiftCard = order.billing.paymentMethods.find(p => p.origin === "giftCard");
  const modalRef: any = useRef<PropsToForward>();
  const [dirty, setDirty] = useState(false);
  const [markAsPaid, setMarkAsPaid] = useState(false);

  const [newPayment, setNewPayment] = useState<MeanOfPayment | undefined | null>();

  const [removePaymentMethod] = useMutation(POST_REMOVE_PAYMENT_METHOD);
  const [addPaymentMethod] = useMutation(POST_ADD_PAYMENT_METHOD);

  const handleRemovePaymentMethod = async (method: PaymentMethod) => {
    try {
      setDirty(true);
      await removePaymentMethod({
        variables: { orderRef: order._id, orderPaymentMethodOrigin: method.origin, orderPaymentMethodRef: method._id }
      });
      addNotification({ ok: 1, message: "Payment method removed" });
      refetch();
    } catch (error: any) {
      addNotification({ ok: 0, message: error.toString() });
    } finally {
      setDirty(false);
    }
  };

  const handleAddCreditNote = async (ref: string) => {
    try {
      setDirty(true);
      const { data } = await addPaymentMethod({
        variables: {
          orderRef: order._id,
          markAsPaid,
          orderPaymentMethodInput: {
            origin: newPayment?.origin as string,
            added: newPayment?.added,
            amount: order.totals.leftToPay as number,
            ref
          }
        }
      });
      refetch();
      setNewPayment(null);
      addNotification({ ok: 1, message: "Credit note added" });
      if (data?.orderAddPaymentMethod?.totals.leftToPay === 0) modalRef.current?.close();
    } catch (error: any) {
      addNotification({ ok: 0, message: error.toString() });
    } finally {
      setDirty(false);
    }
  };

  const handleSubmitNewPayment = async (e: any) => {
    e.preventDefault();
    setDirty(true);

    try {
      const { data } = await addPaymentMethod({
        variables: {
          orderRef: order._id,
          markAsPaid,
          orderPaymentMethodInput: {
            origin: newPayment?.origin as string,
            added: newPayment?.added,
            amount: newPayment?.amount as number
          }
        }
      });
      setNewPayment(null);
      addNotification({ ok: 1, message: "Payment method added" });
      if (data?.orderAddPaymentMethod?.totals.leftToPay === 0) modalRef.current.close();
      refetch();
    } catch (error: any) {
      addNotification({ ok: 0, message: error.toString() });
    } finally {
      setDirty(false);
    }
  };

  const handleNewPaymentSelect = (meanOfPayment: MeanOfPayment) => {
    setNewPayment({
      origin: meanOfPayment.origin,
      type: meanOfPayment.type,
      amount: order.totals.leftToPay,
      placeholder: meanOfPayment.placeholder,
      added: moment().toDate()
    } as any);
  };

  const handleFromToChange = async (value: string) => {
    const parsedDate = moment(value).toDate();
    setNewPayment({ ...newPayment, added: parsedDate } as any);
  };

  const hasLeftToPay = order.totals.leftToPay;
  return (
    <>
      <Modal ref={modalRef} style={{ minWidth: isMobile ? "80%" : "40%" }}>
        <div id="orderPaymentMethodsModal" className="markAsUnavailable">
          <ModalHeaderContainer>
            <Typography variant="pageTitle" tag="h2">
              {t("Edit payment methods")}
            </Typography>
            <button className="reset" type="button" onClick={() => modalRef.current.close()}>
              <i className="cg-icon-burger-close" />
            </button>
          </ModalHeaderContainer>
          <div className="content">
            <div className="left">
              {MeansOfPayments(t).map(p => (
                <Zone key={p.origin} className={`paymentMean ${p.origin === newPayment?.origin ? "active" : ""}`}>
                  <Button
                    variant="secondaryOverZone"
                    disabled={dirty || !hasLeftToPay}
                    type="button"
                    onClick={() => handleNewPaymentSelect(p)}
                    className="icon">
                    <i className={p.icon} /> {p.placeholder}
                  </Button>
                </Zone>
              ))}
            </div>
            <div className="right">
              <div className="top">
                {order.billing.paymentMethods.length ? (
                  <div className="methods">
                    {order.billing.paymentMethods.map(p => (
                      <Zone key={p.origin} className="paymentMethod">
                        <p>
                          {p.label || p.origin} <br /> {p.added ? <small>{moment(p.added).format("ll")}</small> : null}
                        </p>
                        <p>
                          <Price value={p.amount} />
                        </p>
                        <Button variant={"danger"} type="button" disabled={dirty} onClick={() => handleRemovePaymentMethod(p)}>
                          {t("Remove")}
                        </Button>
                      </Zone>
                    ))}
                  </div>
                ) : !newPayment ? (
                  <p>{t("Select a payment method")}</p>
                ) : null}
                <div className="newPayment">
                  {newPayment ? (
                    <form onSubmit={handleSubmitNewPayment} className="newPaymentForm">
                      <DatePicker value={newPayment.added} label={t("Payment date")} onChange={(v: any) => handleFromToChange(v)} />
                      {newPayment.type === "input" ? (
                        <PriceInput
                          label={newPayment.placeholder || ""}
                          value={newPayment.amount}
                          onChange={(e: any) => setNewPayment({ ...newPayment, amount: parseFloat(e.target.value) })}
                        />
                      ) : newPayment.type === "creditNote" && order.buyer?._id ? (
                        <CreditComponent userRef={order.buyer?._id} handleAddCreditNote={handleAddCreditNote} />
                      ) : null}
                      {isNewOrder ? (
                        <Checkbox
                          checked={markAsPaid}
                          onChange={(e: any) => setMarkAsPaid(e.target.checked)}
                          label={t("Also lock order as Payment Received")}
                        />
                      ) : null}
                      <ButtonV2
                        type="submit"
                        variant="primary"
                        disabled={dirty || (newPayment?.type === "creditNote" && !order.buyer?._id)}>
                        {t("Submit payment")}
                      </ButtonV2>
                    </form>
                  ) : (
                    <p></p>
                  )}
                </div>
              </div>
              <h2 className="amountDue">
                <span>{t("Amount due")}:</span> <Price value={order.totals.leftToPay} />
              </h2>
            </div>
          </div>
        </div>
      </Modal>
      <div className="sectionHeader">
        <h4>{t("Payment methods")}</h4>
        {!hasGiftCard ? (
          <Button variant="noStyle" className="editButton" onClick={() => modalRef.current.open()}>
            <p>{t("Edit")}</p>
          </Button>
        ) : null}
      </div>
      <>
        {order.billing.paymentMethods.map(m => (
          <p key={m.origin} className="paymentMethod">
            <span>
              {m.label || m.origin}:{"   "}
              <Price value={m.amount} />
              {(m.origin === "giftCard" || m.origin === "creditNote") && m.charge ? (
                <>
                  {" "}
                  - <Link to={`/${m.origin === "creditNote" ? "creditnotes" : "vouchers"}?term=${m.charge.id}`}>#{m.charge.id}</Link>
                </>
              ) : null}
            </span>
            <span>{m.status}</span>
          </p>
        ))}
        {!order.billing.paymentMethods.length ? <p>{t("No payments methods")}</p> : null}
        {order.billing.paymentDate ? (
          <p>
            {t("Paid on")} {moment(order.billing.paymentDate).format("ll")}
          </p>
        ) : null}
      </>
    </>
  );
};

const RefundMethods = ({
  isMobile,
  order,
  refetch,
  addNotification
}: {
  isMobile: boolean;
  order: Order;
  refetch: any;
  addNotification: AddNotification;
}) => {
  const { t } = useTranslation();
  const defaultMethods = MeansOfPayments(t).map(m => ({ ...m, amount: "" }));
  if (order.billing && Array.isArray(order.billing.paymentMethods)) {
    order.billing.paymentMethods.forEach(om => {
      const pm = defaultMethods.find(pm => pm.origin === om.origin);
      // @ts-ignore
      if (pm) pm.amount = om.amount;
    });
  }
  const hasGiftCard = order.billing.paymentMethods.find(p => p.origin === "giftCard");
  const modalRef: any = useRef<PropsToForward>();
  const [dirty, setDirty] = useState(false);

  const [newPayment, setNewPayment] = useState<MeanOfPayment | undefined | null>();

  const [removePaymentMethod] = useMutation(POST_REMOVE_REFUND_METHOD);
  const [addPaymentMethod] = useMutation(POST_ADD_REFUND_METHOD);

  const handleRemovePaymentMethod = async (method: PaymentMethod) => {
    try {
      setDirty(true);
      await removePaymentMethod({
        variables: { orderRef: order._id, orderPaymentMethodOrigin: method.origin, orderPaymentMethodRef: method._id }
      });
      addNotification({ ok: 1, message: "Payment method removed" });
      refetch();
    } catch (error: any) {
      addNotification({ ok: 0, message: error.toString() });
    } finally {
      setDirty(false);
    }
  };

  const handleSubmitRefund = async (e: any) => {
    e.preventDefault();
    if (!newPayment?.amount || !isNumber(newPayment?.amount)) return;
    setDirty(true);
    try {
      await addPaymentMethod({
        variables: {
          orderRef: order._id,
          orderPaymentMethodInput: {
            origin: newPayment?.origin as string,
            added: newPayment?.added,
            amount: typeof newPayment.amount === "string" ? parseFloat(newPayment?.amount) : newPayment?.amount
          }
        }
      });
      setNewPayment(null);
      addNotification({ ok: 1, message: "Payment method added" });
      modalRef.current.close();
      refetch();
    } catch (error: any) {
      addNotification({ ok: 0, message: error.message });
    } finally {
      setDirty(false);
    }
  };

  const handleNewPaymentSelect = (meanOfPayment: MeanOfPayment) => {
    setNewPayment({
      origin: meanOfPayment.origin,
      type: meanOfPayment.type,
      amount: order.totals.grand,
      placeholder: meanOfPayment.placeholder,
      added: moment().toDate()
    } as any);
  };

  const handleFromToChange = async (value: string) => {
    const parsedDate = moment(value).toDate();
    setNewPayment({ ...newPayment, added: parsedDate } as any);
  };

  const handleAmountChange = async ({ value }: { value: string }) => {
    setNewPayment({ ...newPayment, amount: value } as any);
  };

  return (
    <>
      <Modal ref={modalRef} style={{ minWidth: isMobile ? "80%" : "40%" }}>
        <div id="orderRefundMethodsModal" className="markAsUnavailable">
          <ModalHeaderContainer>
            <Typography variant="pageTitle" tag="h2">
              {t("Edit refunds")}
            </Typography>
            <button className="reset" type="button" onClick={() => modalRef.current.close()}>
              <i className="cg-icon-burger-close" />
            </button>
          </ModalHeaderContainer>
          <div className="content">
            <div className="left">
              {MeansOfPayments(t)
                .filter(m => m.origin !== "creditNote")
                .map(p => (
                  <Zone key={p.origin} className={`paymentMean ${p.origin === newPayment?.origin ? "active" : ""}`}>
                    <Button
                      variant="secondaryOverZone"
                      disabled={dirty}
                      type="button"
                      onClick={() => handleNewPaymentSelect(p)}
                      className="icon">
                      <i className={p.icon} /> {p.placeholder}
                    </Button>
                  </Zone>
                ))}
            </div>
            <div className="right">
              <div className="top">
                {order.billing.refunds.length ? (
                  <div className="methods">
                    {order.billing.refunds.map(p => (
                      <Zone key={p.origin} className="paymentMethod">
                        <p>
                          {p.label || p.origin} <br /> {p.added ? <small>{moment(p.added).format("ll")}</small> : null}
                        </p>
                        <p>
                          <Price value={p.amount} />
                        </p>
                        <Button variant={"danger"} type="button" disabled={dirty} onClick={() => handleRemovePaymentMethod(p)}>
                          {t("Remove")}
                        </Button>
                      </Zone>
                    ))}
                  </div>
                ) : !newPayment ? (
                  <p>{t("Select a refund method")}</p>
                ) : null}
                <div className="newPayment">
                  {newPayment ? (
                    <form onSubmit={handleSubmitRefund} className="newPaymentForm">
                      <DatePicker
                        variant="overZone"
                        value={newPayment.added}
                        label="Refund date"
                        onChange={(v: string) => handleFromToChange(v)}
                      />
                      {newPayment.type === "input" ? (
                        <PriceInput
                          label={newPayment.placeholder}
                          value={newPayment.amount}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleAmountChange({ value: e.target.value })}
                        />
                      ) : null}
                      <ButtonV2 variant="primary" type="submit" disabled={dirty}>
                        {t("Submit refund")}
                      </ButtonV2>
                    </form>
                  ) : (
                    <p></p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div className="sectionHeader">
        <h4>{t("Refunds")}</h4>
        {!hasGiftCard ? (
          <Button variant="noStyle" className="editButton" onClick={() => modalRef.current.open()}>
            <p>{t("Edit")}</p>
          </Button>
        ) : null}
      </div>
      <>
        {order.billing.refunds.map(m => (
          <p key={m.origin} className="paymentMethod">
            <span>
              {m.label || m.origin}:{"   "}
              <Price value={m.amount} />
            </span>
          </p>
        ))}
        {!order.billing.refunds.length ? <p>{t("No refunds")}</p> : null}
        {order.billing.refundDate ? (
          <p>
            {t("Refunded on")} {moment(order.billing.refundDate).format("ll")}
          </p>
        ) : null}
      </>
    </>
  );
};

export { OrderPaymentMethods, RefundMethods, MeansOfPayments, Methods };
