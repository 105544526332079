import { useHistory, useLocation } from "react-router-dom";
import URI from "urijs";
import { MultiSelectValueType } from "../../MultiSelect/MultiSelect.types";
import { mapTagsToMultiSelectType } from "./Articles.utils";

export const useHandleFilterChange = (filterName: string) => {
  const location = useLocation();
  const history = useHistory();
  const currentUri = new URI(location.pathname + location.search);

  const handleFilterChange = async (values: MultiSelectValueType[]) => {
    if (values.length > 0) {
      currentUri.setSearch({ [filterName]: values.map(selectedValue => selectedValue.value) }).removeSearch(["page", "term"]);
    } else {
      currentUri.removeSearch(filterName);
    }
    history.push(currentUri.resource());
  };

  return { handleFilterChange };
};

export const useSelectedTags = () => {
  const location = useLocation();
  const currentUri = new URI(location.pathname + location.search);
  const searchQuery = currentUri.search(true);

  const tags = searchQuery?.tags;

  let selectedTags: string[] = [];
  if (tags) {
    selectedTags = Array.isArray(tags) ? tags : [tags];
  }

  return mapTagsToMultiSelectType(selectedTags);
};
