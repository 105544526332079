import { gql } from "../../__generated__/gql";

export const ORDER_FIELDS = gql(`
  fragment OrderFields on Order {
    _id
    id
    incId
    status
    discogsStatus
    created
    instructions
    seller {
      name
    }
    origin
    buyerWasContacted
    unreadMessagesCount
    buyer {
      ...CheckoutBuyerFields
    }
    notes {
      date
      content
      user {
        ...UserReferenceFields
      }
    }
    items {
      ...LineItemFields
    }
    logs {
      ...LogEntryFields
    }
    billing {
      fulfilled
      status
      paymentDate
      refundDate
      address {
        ...AddressFields
      }
      voucher {
        _id
        id
        type
        redeemType
        value
      }
      paymentMethods {
        _id
        origin
        label
        ref
        amount
        charge
        added
        status
      }
      refunds {
        origin
        amount
      }
      invoice {
        pdf
        date
        id
        emails {
          ...EmailRecipientFields
        }
      }
    }
    shipping {
      fulfilled
      status
      date
      method
      tracking
      shopCollection
      weight
      price {
        base
        beforeTaxes
        taxes {
          ...TaxFields
        }
      }
      address {
        ...AddressFields
      }
      confirmationEmail {
        ...EmailRecipientFields
      }
    }
    totals {
      grand
      subtotal
      leftToPay
      subtotalBeforeTaxes
      shipping
      itemQuantity
      discount
      taxes {
        ...TaxFields
      }
    }
    mergeableOrders {
      _id
      id
      total
      created
      description
    }
  }
`);

export const POST_ORDER_GENERATE_GIFT_CARD = gql(`
  mutation orderGenerateGiftCard($orderRef: ID!, $lineRef: ID!) {
    orderGenerateGiftCard(orderRef: $orderRef, lineRef: $lineRef) {
      ...OrderFields
    }
  }
`);

export const POST_SEND_INVOICE = gql(`
  mutation orderSendInvoice($orderRef: ID!, $orderInvoiceInput: OrderInvoiceInput!) {
    orderSendInvoice(orderRef: $orderRef, orderInvoiceInput: $orderInvoiceInput) {
      ...OrderFields
    }
  }
`);

export const POST_ADD_PAYMENT_METHOD = gql(`
  mutation orderAddPaymentMethod($orderRef: ID!, $orderPaymentMethodInput: OrderPaymentMethodInput!, $markAsPaid: Boolean) {
    orderAddPaymentMethod(orderRef: $orderRef, orderPaymentMethodInput: $orderPaymentMethodInput, markAsPaid: $markAsPaid) {
      ...OrderFields
    }
  }
`);

export const POST_ADD_REFUND_METHOD = gql(`
  mutation orderAddRefundMethod($orderRef: ID!, $orderPaymentMethodInput: OrderPaymentMethodInput!) {
    orderAddRefundMethod(orderRef: $orderRef, orderPaymentMethodInput: $orderPaymentMethodInput) {
      ...OrderFields
    }
  }
`);

export const POST_ORDER_ADD_NOTE = gql(`
  mutation orderAddNote($orderRef: ID!, $orderAddNoteInput: String) {
    orderAddNote(orderRef: $orderRef, orderAddNoteInput: $orderAddNoteInput) {
      ...OrderFields
    }
  }
`);

export const POST_ORDER_GENERATE_PDF = gql(`
  mutation orderGeneratePdf($orderRef: ID!) {
    orderGeneratePdf(orderRef: $orderRef) {
      ...OrderFields
    }
  }
`);

export const POST_ORDER_SHIPPING_UPDATE = gql(`
  mutation orderShippingUpdate($orderRef: ID!, $orderShippingPriceInput: PriceInput, $orderShippingAddressInput: AddressInput, $orderShippingAddressRef: ID) {
    orderShippingUpdate(orderRef: $orderRef, orderShippingPriceInput: $orderShippingPriceInput, orderShippingAddressInput: $orderShippingAddressInput, orderShippingAddressRef: $orderShippingAddressRef) {
      ...OrderFields
    }
  }
`);

export const POST_ORDER_MARK_AS_SHIPPED = gql(`
  mutation orderMarkAsShipped($orderRef: ID!, $trackingLink: String, $email: String) {
    orderMarkAsShipped(orderRef: $orderRef, trackingLink: $trackingLink, email: $email) {
      ...OrderFields
    }
  }
`);

export const POST_REMOVE_PAYMENT_METHOD = gql(`
  mutation orderRemovePaymentMethod($orderRef: ID!, $orderPaymentMethodRef: ID $orderPaymentMethodOrigin: String) {
    orderRemovePaymentMethod(orderRef: $orderRef, orderPaymentMethodOrigin: $orderPaymentMethodOrigin, orderPaymentMethodRef: $orderPaymentMethodRef) {
      ...OrderFields
    }
  }
`);

export const POST_REMOVE_REFUND_METHOD = gql(`
  mutation orderRemoveRefundMethod($orderRef: ID!, $orderPaymentMethodRef: ID $orderPaymentMethodOrigin: String) {
    orderRemoveRefundMethod(orderRef: $orderRef, orderPaymentMethodOrigin: $orderPaymentMethodOrigin, orderPaymentMethodRef: $orderPaymentMethodRef) {
      ...OrderFields
    }
  }
`);

export const POST_ORDER_REPORT_PDF = gql(`
  mutation orderReportPdf($html: String!, $title: String!) {
    orderReportPdf(html: $html, title: $title)
  }
`);

export const POST_ORDERS_EXPORT = gql(`
  mutation ordersExport($format: String!, $ordersFiltersInput: OrdersFiltersInput!) {
    ordersExport(format: $format, ordersFiltersInput: $ordersFiltersInput) {
      ...JobFields
    }
  }
`);

export const POST_ORDER_LINE_MARK_UNAVAILABLE = gql(`
  mutation orderLineMarkAsUnavailable($orderRef: ID!, $orderLineRef: ID!) {
    orderLineMarkAsUnavailable(orderRef: $orderRef, orderLineRef: $orderLineRef) {
      message
      order {
        ...OrderFields
      }
    }
  }
`);

export const POST_ORDER_REPORT_SEND = gql(`
  mutation orderReportSend($pdfUrl: String!, $email: String!, $cc: String,  $subject: String!, $message: String!) {
    orderReportSend(pdfUrl: $pdfUrl, email: $email, cc: $cc, subject: $subject, message: $message)
  }
`);

export const GET_ORDERS = gql(`
  query orders($ordersFiltersInput: OrdersFiltersInput!) {
    orders(ordersFiltersInput: $ordersFiltersInput)
    {
      pagination {
        pages
        page
        count
      }
      stats {
        total
      }
      orders {
        ...OrderFields
      }
    }
  }
`);

export const GET_ORDERS_STATISTICS = gql(`
  query ordersStatistics($ordersFiltersInput: OrdersFiltersInput!) {
    ordersStatistics(ordersFiltersInput: $ordersFiltersInput)
    {
      total
      count
    }
  }
`);

export const GET_ORDERS_REPORT = gql(`
  query ordersReport($ordersFiltersInput: OrdersFiltersInput!) {
    ordersReport(ordersFiltersInput: $ordersFiltersInput) {
      totals {
        grand
        subtotal
        subtotalBeforeTaxes
        shippingBeforeTaxes
        secondHandProfit
        taxes {
          id
          total
        }
        origins {
          pos
          online
          discogs
          bandcamp
        }
      }
      paymentMethods {
        origin
        total
        count
      }
      items {
        count
        news
        secondHands
        books
        products
        giftCards
      }
    }
  }
`);

export const GET_ORDER = gql(`
  query order($id: String $ref: ID) {
    order(id: $id ref: $ref) {
      ...OrderFields
    }
  }
`);

export const GET_ORDER_SEARCH = gql(`
  query orderSearch($term: String!) {
    orderSearch(term: $term) {
      _id
      id
      incId
      status
      discogsStatus
      created
      origin
      buyer {
        _id
        email
        name
        firstName
        lastName
      }
      totals {
        grand
      }
    }
  }
`);
