import React, { useCallback, useEffect, useState, useRef } from "react";
import Zone from "../components/styled/zone";
import { Button } from "../componentsV2/Button";
import { Link } from "react-router-dom";
import Loader from "./common/loader";
import { GlobalStore } from "../stores/global";
import { useLazyQuery } from "@apollo/client";
import Modal from "../components/modal";
import Parse, { domToReact } from "html-react-parser";
import { Select } from "./styled/select";
import URI from "urijs";
import * as amplitude from "@amplitude/analytics-browser";
import { useTranslation } from "react-i18next";
import { GET_HELP_ARTICLE } from "../graphql/queries/help";

export const useHelpPanel = ({ id, show }: { id: number; show: boolean }) => {
  const helpPanel = GlobalStore.useState(c => c.helpPanel);

  useEffect(() => {
    if (show && helpPanel?.load) helpPanel.load(id);
  }, [helpPanel]);

  return { load: helpPanel?.load, id: helpPanel.id };
};

const HelpPanel = () => {
  const { session, config } = GlobalStore.useState(c => c);
  const [isShowing, setIsShowing] = useState(false);
  const [getHelpContent, { loading, data }] = useLazyQuery(GET_HELP_ARTICLE);
  const article = data && data.helpArticle;
  const [currentId, setCurrentId] = useState<number | undefined>();
  const modalRef = useRef<any>();
  const [videoEmbed, setVideoEmbed] = useState<string>();
  const [locale, setLocale] = useState(localStorage.getItem(`${config?.id}-help-locale`) || "en");
  const [locales, setLocales] = useState<string[]>([]);
  const { t } = useTranslation();

  const isEditable = session?.user.type === "SuperAdmin";

  const load = useCallback(
    (id: number) => {
      getHelpContent({ variables: { id: String(id) } });
      setCurrentId(id);
      setIsShowing(true);
    },
    [currentId]
  );

  useEffect(() => {
    GlobalStore.update(s => {
      s.helpPanel.load = load;
    });
  }, []);

  useEffect(() => {
    GlobalStore.update(s => {
      s.helpPanel.id = currentId;
    });
  }, [currentId]);

  useEffect(() => {
    if (article) {
      const translatedContent = article?.translatedContent;
      const locales = translatedContent
        ? Object.keys(translatedContent)
            .filter(e => !e.includes("__"))
            // @ts-ignore
            .filter(key => article.translatedContent && article.translatedContent[key])
        : [];
      setLocales(["en", ...locales]);
      if (locales.indexOf(locale) === -1) setLocale("en");
      amplitude.track("Help panel open", { title: article.title });
    }
  }, [article, locale]);

  const handleOpen = (src: string) => {
    if (src) setVideoEmbed(src);
    modalRef.current.open();
  };

  const handleDropdownChange = async (value: any) => {
    localStorage.setItem(`${config?.id}-help-locale`, value.value);
    setLocale(value.value);
  };

  const replace = (domNode: any): any => {
    if (domNode.name === "a" && domNode.attribs.href && domNode.attribs.href.includes("https://admin.common-ground.io")) {
      const uri = new URI(domNode.attribs.href);
      return <Link to={uri.path()}>{domToReact(domNode.children)}</Link>;
    } else if (domNode.name === "a" && domNode.attribs.href && !domNode.attribs.href.includes("://")) {
      return <Link to={domNode.attribs.href}>{domToReact(domNode.children)}</Link>;
    } else if (domNode.attribs?.class === "intercom-h2b-video") {
      return (
        <div className="video">
          <Button variant="secondary" onClick={() => handleOpen(domNode.children[0].attribs.src)}>
            <i className="cg-icon-player-play" /> {t("Video")}
          </Button>
        </div>
      );
    } else return domToReact(domNode);
  };

  let htmlToParse = article?.body;

  // @ts-ignore
  if (article && locale && locale !== "en" && article.translatedContent[locale]) {
    // @ts-ignore
    htmlToParse = article.translatedContent[locale].body;
  }

  const parsedContent = Parse(htmlToParse || "", { replace });

  return (
    <Zone id="helpPanel" className={isShowing ? "open" : "closed"}>
      <Modal ref={modalRef} style={{ width: "50%", minHeight: "50vh" }}>
        {videoEmbed ? <iframe style={{ width: "100%", minHeight: "400px" }} src={videoEmbed} /> : null}
      </Modal>
      <div className="header">
        {locales?.length ? (
          <Select
            required
            variant="overZone"
            placeholder={t("Select a language")}
            isMulti={false}
            options={locales.map(c => ({ label: c.toUpperCase(), value: c }))}
            value={locale ? { label: locale.toUpperCase() } : null}
            onChange={(a: any) => handleDropdownChange(a)}
          />
        ) : (
          <span />
        )}
        <button className="reset" type="button" onClick={() => setIsShowing(false)}>
          <i className="cg-icon-burger-close" />
        </button>
      </div>
      {article ? <div className="content">{loading || !article ? <Loader /> : parsedContent}</div> : <Loader />}
      {isEditable && article ? (
        <a target="_tab" href={`https://app.intercom.com/a/apps/pkr9zs1z/articles/articles/${article.id}/show?language=${locale}`}>
          <Button variant="primary" styleProps={{ marginTop: "50px" }} type="button">
            {t("Edit")}
          </Button>
        </a>
      ) : null}
    </Zone>
  );
};

export default HelpPanel;
