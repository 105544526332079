import React from "react";
import { Select } from "../../components/styled/select";
import { useTranslation } from "react-i18next";

export const ListingOptionName = ["Media Condition", "Sleeve Condition"];

export const MediaConditions = [
  "Mint (M)",
  "Near Mint (NM or M-)",
  "Very Good Plus (VG+)",
  "Very Good (VG)",
  "Good Plus (G+)",
  "Good (G)",
  "Fair (F)",
  "Poor (P)"
];

export const SleeveConditions = [
  "Mint (M)",
  "Near Mint (NM or M-)",
  "Very Good Plus (VG+)",
  "Very Good (VG)",
  "Good Plus (G+)",
  "Good (G)",
  "Fair (F)",
  "Poor (P)",
  "Not Graded",
  "Generic",
  "No Cover"
];

function statesWithLabelTranslation(): Array<{ label: any; value: string }> {
  const { t } = useTranslation();
  return [
    { label: t("Published"), value: "published" },
    { label: t("Private"), value: "private" },
    { label: t("Draft"), value: "draft" }
  ];
}
export function StatusSelect({ value, overZone, onChange }: { value: any; overZone?: boolean; onChange: any }) {
  const states = statesWithLabelTranslation();
  const selectedOption = states.find(s => s.value === value);
  const { t } = useTranslation();
  return (
    <div className="listingStatus">
      <Select
        label={t("Status")}
        variant={overZone && "overZone"}
        value={selectedOption}
        name="status"
        onChange={onChange}
        options={states}
      />
    </div>
  );
}
