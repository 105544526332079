import { gql } from "../../__generated__/gql";

gql(`
  fragment MediaFields on Media {
    _id
    id
    modified {
      date
    }
    created {
      date
      by {
        name
      }
    }
    title
    alt
    caption
    ext
    mime
    size
    width
    height
    url
    formatArray {
      name
      ext
      mime
      width
      height
      size
      url
      key
    }
  }
`);

export const POST_MEDIA_DELETE = gql(`
  mutation mediaDelete($mediaRefs: [ID]!) {
    mediaDelete(mediaRefs: $mediaRefs)
  }
`);

export const POST_MEDIA_UPDATE = gql(`
  mutation mediaUpdate($mediaRef: ID!, $mediaUpdateInput: MediaUpdateInput!) {
    mediaUpdate(mediaRef: $mediaRef, mediaUpdateInput: $mediaUpdateInput) {
      ...MediaFields
    }
  }
`);

export const POST_MEDIA_CREATE = gql(`
  mutation mediaCreate($file: Upload!) {
    mediaCreate(file: $file) {
      ...MediaFields
    }
  }
`);

export const GET_MEDIA = gql(`
  query media($id: Int, $ids: [Int], $page: Int, $extensions: [String]) {
    media(id: $id, ids: $ids, page: $page, extensions: $extensions) {
      pagination {
        page
        pages
        count
      }
      media {
        ...MediaFields
      }
    }
  }
`);
