import styled from "styled-components";
import { colorsAsRgbArray, colorsAsRgbString, applyOpacity } from "@common-ground-io/colors";
import { getThemeProps } from "../../utils/styles";
import { BaseStyledComponentProps, LayoutType } from "../../types/styles.types";

type ThemeProps = {
  fontColorLabel: string;
  fontColorHover: string;
  disabledFontColorLabel: string;
  fontColorChecked: string;
  hoverBgColorInput: string;
  bgColorInput: string;
  iconColor: string;
  iconColorActive: string;
};

type IconProps = {
  checked: boolean;
};

export type CheckboxContainerProps = {
  marginTop?: string;
  disabled?: boolean;
};

type ThemeVariants = {
  primary: string;
};

type CheckboxProps = BaseStyledComponentProps<ThemeProps, ThemeVariants> & {
  disabled?: boolean;
};

const baseVariant = {
  fontColorLabel: colorsAsRgbString.grey,
  // background
  hoverBgColorInput: applyOpacity(colorsAsRgbArray.greyDark, 0.4),
  bgColorInput: applyOpacity(colorsAsRgbArray.greyDark, 0.2),
  // icon
  iconColor: "transparent",
  iconColorActive: colorsAsRgbString.greyLightest
};

const primaryLightVariant = {
  // font
  fontColorHover: colorsAsRgbString.black,
  disabledFontColorLabel: colorsAsRgbString.greyDark,
  fontColorChecked: colorsAsRgbString.greyDark,
  ...baseVariant
};

const primaryDarkVariant = {
  // font
  fontColorHover: colorsAsRgbString.white,
  disabledFontColorLabel: colorsAsRgbString.greyDark,
  fontColorChecked: colorsAsRgbString.greyDark,
  ...baseVariant
};

const layout: LayoutType<ThemeProps, ThemeVariants> = {
  light: {
    primary: primaryLightVariant
  },
  dark: {
    primary: primaryDarkVariant
  }
};

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
`;

export const StyledCheckbox = styled.div<CheckboxProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  background: ${props => getThemeProps(layout, props).bgColorInput};
  border-radius: 2px;
  transition: all 150ms;
`;

export const Icon = styled.svg<IconProps>`
  height: 10px;
  width: 12px;
  stroke-width: ${({ checked }) => checked && 1};
  stroke: ${props => (props.checked ? getThemeProps(layout, props).iconColorActive : getThemeProps(layout, props).iconColor)};
  fill: ${props => (props.checked ? getThemeProps(layout, props).iconColorActive : getThemeProps(layout, props).iconColor)};
`;

export const Styledlabel = styled.label<CheckboxProps>`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  cursor: inherit;
  margin-left: 5px;
  color: ${props => (props.disabled ? getThemeProps(layout, props).disabledFontColorLabel : getThemeProps(layout, props).fontColorLabel)};
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const Container = styled.div<CheckboxContainerProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: ${({ marginTop }) => marginTop || "0"};
  cursor: pointer;
  &:hover ${StyledCheckbox} {
    background: ${props => (props.disabled ? getThemeProps(layout, props).bgColorInput : getThemeProps(layout, props).hoverBgColorInput)};
  }
  &:hover ${Styledlabel} {
    color: ${props => (props.disabled ? getThemeProps(layout, props).fontColorLabel : getThemeProps(layout, props).fontColorHover)};
  }
`;
