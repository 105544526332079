import React from "react";
import { Link, useHistory } from "react-router-dom";
import Price from "./price";
import Button from "../styled/button";
import { Button as ButtonV2 } from "../../componentsV2/Button";
import BulkInventory, { BulkInventorySelectAll } from "../bulk/inventory";
import Loader from "../common/loader";
import { Select } from "../styled/select";
import { useTranslation } from "react-i18next";
import URI from "urijs";
import { Config, Pagination as IPagination, Item } from "../../__generated__/graphql";
import { IOrdersPagination } from "../orders/orders";

export function InventoryPagination({
  currentUri,
  pagination,
  sorting,
  items,
  noBulk,
  filters,
  stickers,
  config
}: {
  currentUri: URI;
  metadataLoading?: boolean;
  pagination: IPagination;
  statistics: boolean;
  stats: any;
  sorting: any;
  items: Item[];
  noBulk?: boolean;
  filters: any;
  stickers?: any;
  config: Config;
}) {
  const history = useHistory();

  const { t } = useTranslation();
  const { page, pages } = pagination;
  const nextUri = currentUri.clone().setSearch({ ...currentUri.search(true), page: page + 1 });
  const prevUri = currentUri.clone().setSearch({ ...currentUri.search(true), page: page - 1 });

  const handleSortChange = (value: any, event: any) => {
    const action = event.name;
    const newValue = value.value;
    currentUri.setSearch({ [action]: value.value }).removeSearch("page");
    history.push(currentUri.resource());
    localStorage.setItem(`${config.id}-inventory-${action}`, newValue);
  };

  const sorts = [
    { label: t("Listing price"), value: "price", sortLabel: "numerical" },
    { label: t("Listing available date"), value: "available", sortLabel: "date" },
    { label: t("Listing published date"), value: "posted", sortLabel: "date" },
    { label: t("Listing cost"), value: "cost", sortLabel: "numerical" },
    { label: t("Item created date"), value: "created", sortLabel: "date" },
    { label: t("Item wants"), value: "wants", sortLabel: "numerical" }
  ];

  const sort = sorts.find(s => s.value === filters.sort) || sorts[1];
  const orders = [
    {
      label: sort.sortLabel === "numerical" ? t("Lowest first") : sort.sortLabel === "date" ? t("Oldest first") : t("Ascending"),
      value: "1"
    },
    {
      label: sort.sortLabel === "numerical" ? t("Highest first") : sort.sortLabel === "date" ? t("Newest first") : t("Descending"),
      value: "-1"
    }
  ];

  return (
    <div className="pagination inventory">
      <div className="top">
        {!noBulk ? (
          <>
            <div className="selectAll">
              <BulkInventorySelectAll items={items} />
              <BulkInventory />
            </div>
          </>
        ) : null}
        {sorting ? (
          <div className="left">
            <Select
              name="sort"
              value={sort ? { ...sort, label: t("Sort by") + ` ${sort.label}` } : null}
              placeholder={t("Sort by")}
              options={sorts}
              onChange={handleSortChange}
            />
            <Select
              name="order"
              onChange={handleSortChange}
              value={orders.find(s => s.value === filters.order)}
              placeholder={t("Order by")}
              options={orders}
            />
          </div>
        ) : null}
        <div className="middle">
          {stickers && stickers.entries.length ? (
            <ButtonV2 variant="primary" href="/stickers" type="link">
              {t("Print queue")}
            </ButtonV2>
          ) : null}
        </div>
        <div className="right">
          <span className="pagesRecap">
            {t("Page")} {page} {t("of")} {pages}
          </span>
          <ul>
            <li>
              <Link
                to={prevUri.toString()}
                className={`previous ${page < 2 ? "disabled" : ""}`}
                onClick={(e: any) => (page < 2 ? e.preventDefault() : e)}>
                <span className="paginationWords">{t("Prev")}</span>
              </Link>
            </li>
            <li>
              <Link
                to={nextUri.toString()}
                className={`next ${page >= pages ? "disabled" : ""}`}
                onClick={(e: any) => (page >= pages ? e.preventDefault() : e)}>
                <span className="paginationWords">{t("Next")}</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export const DiscogsInventoryPagination = ({
  pagination,
  currentUri,
  loading
}: {
  currentUri: URI;
  loading?: boolean;
  pagination: IPagination & { itemCount?: number };
}) => {
  const { t } = useTranslation();
  const { page, pages, count } = pagination;
  const nextUri = currentUri.clone().setSearch({ ...currentUri.search(true), page: page + 1 });
  const prevUri = currentUri.clone().setSearch({ ...currentUri.search(true), page: page - 1 });

  return (
    <div className="pagination">
      <p className="page">
        {t("Page")} {pagination.page} {t("of")} {pages} | {count} {t("Items").toLowerCase()}
      </p>
      {loading ? <Loader /> : null}
      <nav>
        <ul>
          {page > 1 && (
            <li>
              <Link to={prevUri.toString()} className="previous">
                {"<"} <span className="paginationWords">{t("Previous")}</span>
              </Link>
            </li>
          )}
          <li>
            {t("Page")} {page} {t("of")} {pages}
          </li>
          {page < pages && (
            <li>
              <Link to={nextUri.toString()} className="next">
                <span className="paginationWords">{t("Next")}</span> {">"}
              </Link>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

export const InvoicesPagination = ({
  pagination,
  onNext,
  loading,
  onPrevious
}: {
  loading?: boolean;
  pagination: IPagination;
  onNext: any;
  onPrevious: any;
}) => {
  const { page, pages } = pagination;
  const { t } = useTranslation();
  return (
    <div className="pagination">
      {loading ? <Loader /> : null}
      <nav>
        <ul>
          {page > 1 && (
            <li>
              <Button variant="noStyle" className="previous" onClick={() => onPrevious()}>
                {"<"}
              </Button>
            </li>
          )}
          <li>
            {t("Page")} {page} {t("of")} {pages}
          </li>
          {page < pages && (
            <li>
              <Button variant="noStyle" className="next" onClick={() => onNext()}>
                {">"}
              </Button>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

export const ArticlesPagination = ({
  pagination,
  onNext,
  loading,
  onPrevious
}: {
  loading?: boolean;
  pagination: IPagination;
  onNext: any;
  onPrevious: any;
}) => {
  const { page, pages } = pagination;
  const { t } = useTranslation();
  return (
    <div className="pagination">
      {loading ? <Loader /> : null}
      <span />
      <nav>
        <ul>
          <li>
            {t("Page")} {page} {t("of")} {pages}
          </li>
          {page > 1 && (
            <li>
              <Button variant="noStyle" className="previous" onClick={() => onPrevious()}>
                {t("Previous")}
              </Button>
            </li>
          )}
          {page < pages && (
            <li>
              <Button variant="noStyle" className="next" onClick={() => onNext()}>
                {t("Next")}
              </Button>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default function Pagination({
  pagination,
  loading,
  stats,
  currentUri,
  isCustomer = false
}: {
  loading?: boolean;
  pagination: IPagination;
  stats?: any;
  onPrevious?: any;
  currentUri: URI;
  isCustomer?: boolean;
}) {
  const { page, pages } = pagination;
  const { t } = useTranslation();
  const nextUri = currentUri.clone().setSearch({ ...currentUri.search(true), page: page + 1 });
  const prevUri = currentUri.clone().setSearch({ ...currentUri.search(true), page: page - 1 });
  return (
    <div className="pagination">
      <div className="left">
        <p className="page">
          {pagination.count && !isCustomer ? `${pagination.count || 0} items` : ""}
          {stats ? (
            <>
              {stats.stockCount !== undefined ? (
                <span>
                  {" - "}
                  {t("Stock")}: {stats.stockCount}
                </span>
              ) : null}
              <span>
                {" | "}
                <Price value={stats.total.toFixed(2)} />
              </span>
            </>
          ) : null}
        </p>
        {loading ? <Loader /> : null}
      </div>
      <nav>
        <ul>
          {page > 1 && (
            <li>
              <Link to={prevUri.toString()} className="previous">
                {"<"} <span className="paginationWords">{t("Previous")}</span>
              </Link>
            </li>
          )}
          <li>
            {t("Page")} {page} {t("of")} {pages}
          </li>
          {page < pages && (
            <li>
              <Link to={nextUri.toString()} className="next">
                <span className="paginationWords">{t("Next")}</span> {">"}
              </Link>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
}

export function OrdersPagination({
  pagination,
  sorting,
  config,
  currentUri
}: {
  pagination: IOrdersPagination;
  sorting: any;
  config: any;
  statistics?: any;
  currentUri: URI;
}) {
  const history = useHistory();
  const { t } = useTranslation();
  const { page, pages } = pagination;
  const nextUri = currentUri.clone().setSearch({ ...currentUri.search(true), page: page + 1 });
  const prevUri = currentUri.clone().setSearch({ ...currentUri.search(true), page: page - 1 });

  const handleSortChange = (value: any, event: any) => {
    const action = event.name;
    const newValue = value.value;
    currentUri.setSearch({ [action]: value.value }).removeSearch("page");
    history.push(currentUri.resource());
    localStorage.setItem(`${config.id}-orders-${action}`, newValue);
  };

  const sorts = [
    { label: t("Created date"), value: "createdDate", sortLabel: "date" },
    { label: t("Payment date"), value: "paymentDate", sortLabel: "date" },
    { label: t("Total"), value: "total", sortLabel: "numerical" }
  ];

  const sort = sorts.find(s => s.value === pagination.sort) || sorts[0];
  const orders = [
    {
      label: sort.sortLabel === "numerical" ? t("Lowest first") : sort.sortLabel === "date" ? t("Oldest first") : t("Ascending"),
      value: 1
    },
    {
      label: sort.sortLabel === "numerical" ? t("Highest first") : sort.sortLabel === "date" ? t("Newest first") : t("Ascending"),
      value: -1
    }
  ];
  return (
    <div className="pagination orders">
      <div className="top">
        {sorting ? (
          <div className="left">
            <Select
              name="sort"
              value={sort ? { ...sort, label: t("Sort by") + sort.label } : null}
              placeholder={t("Sort by")}
              options={sorts}
              onChange={handleSortChange}
            />
            <Select
              name="order"
              onChange={handleSortChange}
              value={orders.find(s => s.value === pagination.order)}
              placeholder={t("Order by")}
              options={orders}
            />
          </div>
        ) : null}
        <div className="right">
          <span className="pagesRecap">
            {t("Page")} {page} {t("of")} {pages}
          </span>
          <ul>
            <li>
              <Link
                to={prevUri.toString()}
                className={`previous ${page < 2 ? "disabled" : ""}`}
                onClick={(e: any) => (page < 2 ? e.preventDefault() : e)}>
                <span className="paginationWords">{t("Prev")}</span>
              </Link>
            </li>
            <li>
              <Link
                to={nextUri.toString()}
                className={`next ${page >= pages ? "disabled" : ""}`}
                onClick={(e: any) => (page >= pages ? e.preventDefault() : e)}>
                <span className="paginationWords">{t("Next")}</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
