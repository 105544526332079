import React, { useState, useEffect } from "react";
import Checkbox from "../../components/styled/checkbox";
import { Button } from "../../componentsV2/Button";
import Input from "../../components/styled/input";
import { round } from "@common-ground-io/common-assets/helpers/utils.js";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import { SearchUsers, SearchListings } from "../../components/global/search";
import Clone from "clone";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_ORDER } from "../../graphql/queries/order";
import { POST_CREDIT_CREATE } from "../../graphql/queries/credit";
import { AddNotification } from "../../types/globals";
import { Buyer, Config, Credit, Item, LineItem, Listing, Tax } from "../../__generated__/graphql";
import { Typography } from "../../componentsV2/Typography";
import { useTranslation } from "react-i18next";
import { ModalHeaderContainer } from "../../componentsV2/SectionHeader/SectionHeader.styles";

export default ({
  onSubmitted,
  addNotification,
  config,
  searchQuery
}: {
  onSubmitted: any;
  addNotification: AddNotification;
  config: Config;
  searchQuery: any;
}) => {
  const taxes = config.taxes.definitions;
  const [getOrder] = useLazyQuery(GET_ORDER, { fetchPolicy: "cache-and-network" });
  const [createCredit] = useMutation(POST_CREDIT_CREATE);
  const preTaxes = config.taxes.rules.editPricesBeforeTaxes;
  const { t } = useTranslation();

  const [updateStock, setUpdateStock] = useState(false);
  const [note, setNote] = useState<Credit | { title: string; reference: string; items: LineItem[]; user: any }>({
    title: "",
    reference: "",
    items: [],
    user: null
  });

  useEffect(() => {
    if (searchQuery && searchQuery.orderId) {
      getOrder({ variables: { id: searchQuery.orderId } })
        .then(({ data }) => {
          const order = data?.order;
          if (!order) throw new Error("Order error");
          setNote({
            ...note,
            items: Clone(order.items),
            user: order.buyer && order.buyer._id ? order.buyer : null,
            title: `Credit note for order ${order.id}`,
            reference: `#${order.id} - ${order.incId}`
          });
          addNotification({ ok: 1, message: `New credit note was pre-filled from order document #${searchQuery.orderId} ` });
        })
        .catch(error => addNotification({ ok: 0, message: error.data || error.toString() }));
    }
  }, [searchQuery.orderId]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!note.user) return addNotification({ ok: 0, message: "User is required" });
    try {
      const dataToSend = {
        userRef: note.user._id,
        items: note.items.map(i => ({
          listingRef: i.listing._id,
          quantity: i.quantity,
          price: {
            base: i.price.base || 0,
            beforeTaxes: i.price.beforeTaxes || 0,
            taxes: i.price.taxes?.map((t: any) => ({ rate: t.rate, name: t.name }))
          }
        })),
        title: note.title || "",
        reference: note.reference,
        updateStock: e.target.updateStock.checked
      };
      await createCredit({ variables: dataToSend });
      addNotification({ ok: 1, message: "Credit note created" });
      setNote({ ...note, items: [], title: "", reference: "", user: null });
      onSubmitted();
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
  };

  const handleInputChange = (e: any) => {
    // @ts-ignore
    note[e.target.name] = e.target.value;
    setNote({ ...note });
  };

  const handleUserSelect = (user: Buyer, e: any) => {
    e.preventDefault();
    note.user = Clone(user);
    setNote({ ...note });
  };

  const handleAddEntry = async (entry: Item, listing: Listing) => {
    try {
      if (note.items.length >= 50)
        throw new Error("A credit note may not contain more than 50 items, submit it then continue from the order page");
      const existing = note.items.find(i => i.listing._id === listing._id);
      if (existing) return addNotification({ ok: 0, message: "Item already added to credit note" });

      const newEntry = {
        listing,
        price: { taxes: [], beforeTaxes: listing.prices.sale, base: listing.prices.sale },
        quantity: 1,
        added: new Date(),
        item: {
          id: entry.id,
          description: entry.descriptions.main,
          thumbnail: entry.data.thumb,
          path: entry.path,
          ref: entry._id,
          weight: entry.data.weight,
          type: entry.type
        }
      };
      note.items.push(newEntry as any);
      setNote({ ...note });
    } catch (error: any) {
      addNotification({ ok: 0, message: error.message || error.data || error.toString() });
    }
  };

  const handlePriceChange = (value: any, index: number) => {
    if (!preTaxes) note.items[index].price.base = value;
    else note.items[index].price.beforeTaxes = value;
    setNote({ ...note });
  };

  const handleQuantityChange = (value: any, index: number) => {
    note.items[index].quantity = value;
    setNote({ ...note });
  };

  const handleRemoveItem = (index: number) => {
    note.items.splice(index, 1);
    setNote({ ...note });
  };

  const handleAddTax = async (idx: number) => {
    if (!taxes.length) return;
    note.items[idx].price.taxes.push(taxes[0] as Tax);
    setNote({ ...note });
  };

  const handleRemoveTax = async (itemIndex: number, taxIndex: number) => {
    note.items[itemIndex].price.taxes.splice(taxIndex, 1);
    setNote({ ...note });
  };

  const handleTaxSelect = async (e: any, itemIndex: number, taxIndex: number) => {
    const selectIndex = e.target.value;
    const tax = taxes[selectIndex];
    // @ts-ignore
    note.items[itemIndex].price.taxes[taxIndex] = tax;
    setNote({ ...note });
  };

  return (
    <section id="creditNoteCreate">
      <form onSubmit={handleSubmit}>
        <ModalHeaderContainer>
          <Typography variant="pageTitle" tag="h2">
            {t("Add a credit note")}
          </Typography>
          <button className="reset" type="button" onClick={() => onSubmitted()}>
            <i className="cg-icon-burger-close" />
          </button>
        </ModalHeaderContainer>
        <div className="inline">
          <div>
            <SearchUsers label={t("Assign a customer")} variant="overZone" onSelect={handleUserSelect} />
            <p className="user">
              {note.user ? (
                <>
                  {" "}
                  {t("Selected")}: {note.user.name || note.user.email}
                </>
              ) : (
                <>{t("No user selected")}</>
              )}
            </p>
          </div>
          <Input
            variant="overZone"
            label={t("Title")}
            type="text"
            required
            placeholder={t("Title") + "..."}
            name="title"
            autoComplete="off"
            onChange={handleInputChange}
            value={note.title}
          />
          <Input
            label={t("Reference")}
            variant="overZone"
            type="text"
            name="reference"
            autoComplete="off"
            required
            placeholder={t("Reference") + "..."}
            onChange={handleInputChange}
            value={note.reference}
          />
        </div>
        <div className="items">
          <div className="header">
            <h3>{t("Items")}</h3>
            <SearchListings
              variant="overZone"
              config={config}
              quantitySort={true}
              placeholder={t("Search your inventory and add listings to this checkout") + "..."}
              onSelect={handleAddEntry}
            />
          </div>
          <div className="entries" style={{ display: "grid", gridGap: "var(--gutter)" }}>
            <div className="entry">
              <p>{t("Item information")}</p>
              <p>{preTaxes ? t("Price before sales taxes") : t("Price incl. sales taxes")}</p>
              <p>{t("Quantity")}</p>
              <p>{t("Taxes")}</p>
              <p>{t("Action")}</p>
            </div>
            {note.items.map((i, idx) => (
              <div key={idx} className="entry">
                <p>{i.item?.description}</p>
                <div className="price">
                  <Input
                    variant="overZone"
                    type="number"
                    name="price"
                    min="0"
                    required
                    max="9999"
                    autoComplete="off"
                    step="0.01"
                    placeholder={t("Price")}
                    onWheel={(e: any) => e.target.blur()}
                    onChange={(e: any) => handlePriceChange(parseFloat(e.target.value), idx)}
                    value={round(preTaxes ? i.price.beforeTaxes : i.price.base) || ""}
                  />
                </div>
                <div className="quantity">
                  <Input
                    variant="overZone"
                    type="number"
                    name="quantity"
                    min="0"
                    required
                    max="9999"
                    onWheel={(e: any) => e.target.blur()}
                    autoComplete="off"
                    step="1"
                    placeholder={t("Quantity")}
                    onChange={(e: any) => handleQuantityChange(parseInt(e.target.value), idx)}
                    value={i.quantity || ""}
                  />
                </div>
                <div className="taxes">
                  {i.price.taxes.map((t, i) => (
                    <div key={i} className="taxEntry">
                      <select
                        value={taxes.findIndex(tax => tax.rate === t.rate && tax.name === t.name)}
                        onChange={(e: any) => handleTaxSelect(e, idx, i)}>
                        {taxes.map((t, index) => (
                          <option key={index} value={index}>
                            {t.name} {t.rate}%
                          </option>
                        ))}
                      </select>
                      <button className="reset" onClick={() => handleRemoveTax(idx, i)} type="button">
                        <IoIosRemoveCircleOutline />
                      </button>
                    </div>
                  ))}
                  <div className="add">
                    <Button variant="secondary" onClick={() => handleAddTax(idx)} type="button">
                      {t("Add a tax")}
                    </Button>
                  </div>
                </div>
                <div className="action">
                  <button className="reset" type="button" onClick={() => handleRemoveItem(idx)}>
                    {t("Remove")}
                  </button>
                </div>
              </div>
            ))}
            {note.items.length === 0 ? <p>{t("Get started by searching for a listing above")}</p> : null}
          </div>
        </div>
        <hr />
        <div className="footer">
          <div className="left">
            <Checkbox
              checked={updateStock}
              onChange={(e: any) => setUpdateStock(e.target.checked)}
              label={t("Increase items stock quantity")}
              name="updateStock"
            />
          </div>
          <Button variant="primary" disabled={!note.user || !note.items.length} type="submit">
            {t("Submit")}
          </Button>
        </div>
      </form>
    </section>
  );
};
