import React, { useState, useEffect, useCallback } from "react";
import { ApolloClient, NormalizedCacheObject, useLazyQuery } from "@apollo/client";
import Intro from "../components/intro";
import { GlobalStore } from "../stores/global";
import { BulkStore } from "../stores/bulk";
import clone from "clone";
import useWindowSize from "../hooks/useWindowSize";
import * as Sentry from "@sentry/react";
import { GET_CONFIG } from "../graphql/queries/config";
import moment from "moment";
import "moment/locale/fr";

interface ThemeContextType {
  selectedDomain?: string;
  configReload?: () => void;
  configSelect?: (origin: string, resetCache?: boolean) => void;
}
export const ConfigContextData = React.createContext<ThemeContextType>({});

export const ConfigContext = ({ children, apolloClient }: { children: any; apolloClient: ApolloClient<NormalizedCacheObject> }) => {
  const { isMobile } = useWindowSize(576);
  const { isMobile: isBelowIpad } = useWindowSize(820 + 1);
  const { isBelowBreakpoint: menuOverContent } = useWindowSize(1152);
  const { config: configStore, session } = GlobalStore.useState(c => c);
  const [selectedDomain, setSelectedDomain] = useState<string | undefined>(undefined);

  const [getConfig, { data: configData, refetch: refetchConfig }] = useLazyQuery(GET_CONFIG, { fetchPolicy: "cache-and-network" });

  useEffect(() => {
    if (configData && configData.config) {
      const retrievedConfig = clone(configData.config);
      GlobalStore.update(s => {
        s.config = clone(retrievedConfig);
        s.configReload = configReload;

        const printingKey = `${retrievedConfig.id}-legacyPrinting`;
        const currentPrintingVersion = localStorage.getItem(printingKey);

        if (!currentPrintingVersion) {
          if (moment(retrievedConfig.created).isBefore(moment("12/11/2022"))) localStorage.setItem(printingKey, "true");
          else localStorage.setItem(printingKey, "false");
        }
      });
      if (retrievedConfig.domain) setSelectedDomain(retrievedConfig.domain);

      Sentry.setContext("config", { origin: retrievedConfig.domain, shopName: retrievedConfig.shopName, id: retrievedConfig.id });
    }
  }, [configData]);

  useEffect(() => {
    moment.locale(localStorage.getItem("locale") || "en");
  }, [localStorage.getItem("locale")]);

  const resetStore = useCallback(async () => {
    // await apolloClient.queryManager.fetchQueryRejectFns;
    await apolloClient.cache.reset();
  }, [apolloClient]);

  const handleConfigSelect = async (origin: string, resetCache = true) => {
    localStorage.setItem("origin", origin);
    GlobalStore.update(s => {
      s.config = undefined;
    });
    if (resetCache) resetStore();
    BulkStore.update(s => {
      s.inventory.listings = [];
    });
    try {
      getConfig({ variables: { origin } });
    } catch (e: any) {
      GlobalStore.update(s => {
        s.config = null;
      });
    }
  };

  useEffect(() => {
    const listener = (e: any) => {
      if (e.key === "origin") window.location.href = "/";
    };
    window.addEventListener("storage", listener);
    if (session) {
      const savedOrigin = localStorage.getItem("origin");
      const index = session?.adminDomains.findIndex(d => d.domainName === savedOrigin);
      const origin = index > -1 ? savedOrigin : session?.adminDomains[0].domainName;
      if (origin) handleConfigSelect(origin, false);
    }
    return () => window.removeEventListener("storage", listener);
  }, [session]);

  useEffect(() => {
    GlobalStore.update(s => {
      s.isMobile = isMobile;
      s.isBelowIpad = isBelowIpad;
      s.menuOverContent = menuOverContent;
    });
  }, [isMobile, menuOverContent, isBelowIpad]);

  const configReload = useCallback(() => {
    const origin = localStorage.getItem("origin");
    refetchConfig({ origin });
  }, []);

  if (session === undefined || configStore === undefined) return <Intro />;
  // eslint-disable-next-line i18next/no-literal-string
  if (configStore === null) return <p>Config error</p>;

  return (
    <ConfigContextData.Provider
      value={{
        selectedDomain,
        configSelect: handleConfigSelect,
        configReload
      }}>
      {children}
    </ConfigContextData.Provider>
  );
};

export const { Consumer } = ConfigContextData;
