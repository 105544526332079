import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import CapsuleGroup from "../styled/capsule";
import Loader from "../common/loader";
import Liner from "../common/liner";
import Price, { getPriceWithCurrencyString } from "../common/price";
import Zone from "../styled/zone";
import { FaEnvelope } from "react-icons/fa";
import News from "../../pages/news/newsPanel";
import { DashboardStore } from "../../stores/dashboard";
import { GlobalStore } from "../../stores/global";
import { Line, Doughnut } from "react-chartjs-2";
import moment from "moment";
import clone from "clone";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GET_DASHBOARD, POST_DASHBOARD_UPDATE } from "../../graphql/queries/dashboard";
import useIdleDetector from "../../hooks/useIdleDetector";
import cgColors from "@common-ground-io/common-assets/assets/colors.json";

import { Chart as ChartJS, ChartOptions, registerables } from "chart.js";

import { Config, Dashboard, DashboardPanelPageViews, Order } from "../../__generated__/graphql";
import { AddNotification } from "../../types/globals";
import { useTranslation } from "react-i18next";
import { applyOpacity, colorsAsRgbArray, colorsAsRgbString } from "@common-ground-io/colors";
import "chartjs-adapter-moment";
import { tooltip } from "../../pages/dashboard/tooltip";
import { getConfigProperty } from "../../utils";

ChartJS.register(...registerables);

const { getName } = require("country-list");

export default () => {
  const [wasInitiallyLoaded, setWasInitiallyLoaded] = useState(false);
  const { addNotification, config } = GlobalStore.useState(c => c);

  const { data, error } = useQuery(GET_DASHBOARD, {
    variables: {
      panels: [
        "salesOverTime",
        "origins",
        "recentOrders",
        "newMessages",
        "visits",
        "pageViews",
        "realTimeUsers",
        "bestSellers",
        "salesLocations"
      ]
    },
    fetchPolicy: "no-cache"
  });
  const [updateDashboard] = useMutation(POST_DASHBOARD_UPDATE);
  document.title = "Dashboard";

  const updateStore = (data: any) =>
    DashboardStore.update(s => {
      Object.keys(data.panels).forEach(key => {
        // @ts-ignore
        if (data.panels[key]) s[key] = data.panels[key];
      });
    });

  useEffect(() => {
    if (error) {
      // addNotification({ ok: 0, message: error.message });
    } else if (data) {
      updateStore(data.dashboard);
      setWasInitiallyLoaded(true);
    }
  }, [error, data]);

  return (
    <div id="dashboard">
      <Poller wasInitiallyLoaded={wasInitiallyLoaded} updateStore={updateStore} addNotification={addNotification} />
      <SalesOverTime config={config as Config} addNotification={addNotification} updateDashboard={updateDashboard} />
      <PageViews config={config as Config} addNotification={addNotification} updateDashboard={updateDashboard} />
      <BestSellers updateDashboard={updateDashboard} addNotification={addNotification} />
      <News />
      <Visits config={config as Config} />
      <SalesLocations />
      <Origins />
      <GlobalPanel />
      <RecentOrders updateDashboard={updateDashboard} />
      <NewMessages />
    </div>
  );
};

ChartJS.register({
  id: "verticalLine",
  afterDraw: (chart: { tooltip?: any; scales?: any; ctx?: any }) => {
    // eslint-disable-next-line no-underscore-dangle
    if (chart.tooltip._active && chart.tooltip._active.length) {
      // find coordinates of tooltip
      const activePoint = chart.tooltip._active[0];
      const { ctx } = chart;
      const { x } = activePoint.element;
      const topY = chart.scales.y?.top || 0;
      const bottomY = chart.scales.y?.bottom || 0;

      // draw vertical line
      ctx.save();
      ctx.beginPath();
      ctx.setLineDash([2]);
      ctx.moveTo(x, topY);
      ctx.lineTo(x, bottomY);
      ctx.lineWidth = 1;
      ctx.strokeStyle = applyOpacity(colorsAsRgbArray.grey, 0.2);
      ctx.stroke();
      ctx.restore();
    }
  }
});

const SalesOverTime = ({
  config,
  addNotification,
  updateDashboard
}: {
  config: Config;
  addNotification: AddNotification;
  updateDashboard: (data: any) => Promise<{ data?: any }>;
}) => {
  const salesOverTime = DashboardStore.useState(c => c.salesOverTime);
  const { t } = useTranslation();
  const clonedPanel: any = clone(salesOverTime);
  const chartOptions: ChartOptions = {
    borderColor: cgColors.blue,
    responsive: true,
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          title: context => {
            if (context.length) {
              return `${moment(context[0].label).format("ll")} - ${t("Week")}  ${moment(context[0].label).week()}`;
            } else return "";
          },
          label: function (context: any) {
            let label = context.dataset.label || "";

            if (context.parsed.y !== null) {
              label += "|" + getPriceWithCurrencyString(config, context.parsed.y);
            }
            return label;
          }
        },
        external: tooltip,
        enabled: false,
        position: "nearest"
      }
    },
    animation: false,
    layout: {
      padding: 0
    },
    interaction: {
      intersect: false,
      mode: "index"
    },
    scales: {
      x: {
        offset: false,
        border: { display: false },
        grid: { display: false },
        type: "time",
        ticks: {
          display: true,
          maxTicksLimit: 4,
          backdropPadding: 0,
          padding: 0,
          showLabelBackdrop: false,
          callback: function (val) {
            return moment(val).format("MMM");
          }
        }
      },
      y: {
        offset: false,
        border: { display: false, dash: [2] },
        grid: { display: true, color: applyOpacity(colorsAsRgbArray.grey, 0.1) },
        ticks: { display: false }
      }
    }
  };

  if (clonedPanel && clonedPanel.data) {
    clonedPanel.data.datasets.forEach((s: any) => {
      s.tension = 0.5;
      s.pointRadius = 1;
    });
  }

  const handleCapsuleSelect = async (value: number) => {
    try {
      const { data } = await updateDashboard({ variables: { panel: { id: "salesOverTime", settings: { months: value } } } });
      const panelData = data.dashboard.panels.salesOverTime;
      if (panelData)
        DashboardStore.update(s => {
          s.salesOverTime = panelData;
        });
    } catch (error: any) {
      addNotification({ ok: 0, message: error.data });
    }
  };

  return (
    <Zone id="salesOverTime">
      <div className="header">
        <h3>{t("Sales over time")}</h3>
        <CapsuleGroup
          variant="overZone"
          name="months"
          value={clonedPanel?.settings?.months || 3}
          onClick={handleCapsuleSelect}
          entries={[
            { label: t("{{value}} months", { value: 3 }), value: 3 },
            { label: t("{{value}} months", { value: 6 }), value: 6 },
            { label: t("{{value}} months", { value: 12 }), value: 12 }
          ]}
        />
      </div>

      {clonedPanel?.data ? (
        <div className="chart">
          <Line data={{ ...clonedPanel.data }} options={chartOptions as any} style={{ height: "100%" }} />
        </div>
      ) : null}
    </Zone>
  );
};

const GlobalPanel = () => {
  const [time, setTime] = useState(moment());
  const recentOrders = DashboardStore.useState(c => c.recentOrders);
  const { t } = useTranslation();
  useEffect(() => {
    const id = setInterval(() => {
      setTime(moment());
    }, 10000);
    return () => {
      clearInterval(id);
    };
  }, []);

  return (
    <Zone id="globals">
      <div className="top">
        <h2>
          {t("Week")} {time.isoWeek()}
        </h2>
        <p className="date">
          {time.format("dddd")}
          <br />
          {time.format("ll")}
        </p>
      </div>
      <div className="bottom">
        <p className="time">
          {time.format("LT")}
          <br />
        </p>
        <h2 title={t("Total sales for current week")}>{recentOrders ? <Price value={recentOrders?.data?.weeklySales} /> : "-"}</h2>
      </div>
    </Zone>
  );
};

const Visits = ({ config }: { config: Config }) => {
  const visits = DashboardStore.useState(c => c.visits);
  const realTimeUsers = DashboardStore.useState(c => c.realTimeUsers);

  const hasLoadedAndHasData = visits && visits.data;

  const lastMonth = hasLoadedAndHasData && visits.data?.periods?.find(p => p.id === "lastMonth");
  const thisMonth = hasLoadedAndHasData && visits.data?.periods?.find(p => p.id === "thisMonth");
  const ready = thisMonth && lastMonth;
  const ratio = ready && thisMonth.count && lastMonth.count ? 100 - (lastMonth.count / thisMonth.count) * 100 : 0;
  const hasAnalyticsSetup = !!config.google?.analytics?.property?.id;
  const { t } = useTranslation();
  return (
    <Zone id="visits">
      <div className="header">
        <h3>{t("Eshop visitors")}</h3>
        {/* To translate */}
        <p>{thisMonth?.title || ""}</p>
      </div>
      {!ready && hasAnalyticsSetup ? (
        <div className="loading">
          <Loader withMargins color="#d2d2d2" />
        </div>
      ) : null}
      <div className="bottom">
        <div className="left">
          <h2>{realTimeUsers?.data?.count !== undefined ? realTimeUsers?.data?.count : ""}</h2>
          <p>{ready ? t("Right now") : ""}</p>
        </div>
        <div className="right">
          <h2>{thisMonth?.count || ""}</h2>
          <p>{ready ? <>{(ratio < 0 ? "" : "+") + ratio.toFixed(1)}%</> : null}</p>
        </div>
      </div>
      {!hasAnalyticsSetup ? (
        <div className="setup">
          <Link className="" to="/editor/settings">
            {t("Setup my Google Analytics account")}
          </Link>
        </div>
      ) : null}
    </Zone>
  );
};

const PageViews = ({
  config,
  addNotification,
  updateDashboard
}: {
  config: Config;
  addNotification: AddNotification;
  updateDashboard: (data: any) => Promise<{ data?: any }>;
}) => {
  const pageViews = DashboardStore.useState(c => c.pageViews);
  const clonedPanel = clone(pageViews);
  const chartOptions: ChartOptions = {
    borderColor: cgColors.blue,
    responsive: true,
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          title: context => {
            if (context.length) {
              return `${moment(context[0].label).format("ddd ll")} - ${t("Week")}  ${moment(context[0].label).week()}`;
            } else return "";
          },
          label: function (context: any) {
            let label = context.dataset.label || "";

            if (context.parsed.y !== null) {
              label += "|" + context.parsed.y;
            }
            return label;
          }
        },
        external: tooltip,
        enabled: false,
        position: "nearest"
      }
    },
    animation: false,
    layout: {
      padding: 0
    },
    interaction: {
      intersect: false,
      mode: "index"
    },
    scales: {
      x: {
        offset: false,
        border: { display: false },
        grid: { display: false },
        type: "time",
        ticks: {
          display: true,
          maxTicksLimit: 6,
          backdropPadding: 0,
          padding: 0,
          showLabelBackdrop: false,
          callback: val => moment(val).format("MMM")
        }
      },
      y: {
        offset: false,
        border: { display: false, dash: [2] },
        grid: { display: true, color: applyOpacity(colorsAsRgbArray.grey, 0.1) },
        ticks: { display: false }
      }
    }
  };
  const { t } = useTranslation();

  if (clonedPanel && clonedPanel.data) {
    clonedPanel.data.datasets.forEach((s: any) => {
      s.tension = 0.5;
      s.pointRadius = 1;
    });
  }

  const handleCapsuleSelect = async (value: any, name: string) => {
    const clonedPanel = clone(pageViews as DashboardPanelPageViews);
    if (!clonedPanel.settings) clonedPanel.settings = {};
    // @ts-ignore
    clonedPanel.settings[name] = value;
    try {
      const { data } = await updateDashboard({ variables: { panel: { id: "pageViews", settings: clonedPanel.settings } } });
      const panelData = data.dashboard.panels.pageViews;
      if (panelData)
        DashboardStore.update(s => {
          s.pageViews = panelData;
        });
    } catch (error: any) {
      addNotification({ ok: 0, message: error.data });
    }
  };

  const hasAnalyticsSetup = !!config.google?.analytics?.property?.id;

  return (
    <Zone id="pageViews">
      <div className="header">
        <h3>{t("Eshop views")}</h3>
        <CapsuleGroup
          variant="overZone"
          name="start"
          value={clonedPanel?.settings?.start || "30daysAgo"}
          onClick={handleCapsuleSelect}
          entries={[
            { label: t("{{value}} days", { value: "30" }), value: "30daysAgo" },
            { label: t("{{value}} days", { value: "60" }), value: "60daysAgo" },
            { label: t("{{value}} days", { value: "90" }), value: "90daysAgo" }
          ]}
        />
      </div>

      {clonedPanel?.data ? (
        <div className="chart">
          {/* @ts-expect-error height null */}
          <Line height={null} data={clonedPanel.data} options={chartOptions} />
        </div>
      ) : null}

      {!hasAnalyticsSetup ? (
        <div className="configureAnalytics">
          <Link to="/editor/settings">{t("Setup my Google Analytics account")}</Link>
        </div>
      ) : null}

      {!clonedPanel?.data && hasAnalyticsSetup ? (
        <div className="loading">
          <Loader withMargins styles={{ marginTop: "50px" }} color="#d2d2d2" />
        </div>
      ) : null}
    </Zone>
  );
};

const Origins = () => {
  const origins = DashboardStore.useState(c => c.origins);
  const { t } = useTranslation();
  const options: any = { animation: false, plugins: { legend: { position: "bottom" } } };
  const clonedPanel = clone(origins);
  if (origins && clonedPanel?.data?.datasets) {
    clonedPanel.data.datasets[0].backgroundColor = [colorsAsRgbString.primary, colorsAsRgbString.grey, colorsAsRgbString.blue];
    clonedPanel.data.datasets[0].borderWidth = 0;
  }

  return (
    <Zone id="origins">
      <div className="header">
        <h3>{t("Sales origins")}</h3>
      </div>
      {/* How can I translate the origins inside the chart ? */}
      <div className="content">{origins ? <Doughnut data={clonedPanel?.data as any} options={options} /> : null}</div>
    </Zone>
  );
};

const BestSellers = ({
  updateDashboard,
  addNotification
}: {
  addNotification: AddNotification;
  updateDashboard: (data: any) => Promise<{ data?: any }>;
}) => {
  const bestSellers = DashboardStore.useState(c => c.bestSellers);
  const config = GlobalStore.useState(c => c.config);
  const { t } = useTranslation();

  const handleCapsuleSelect = async (value: any, name: string) => {
    if (!bestSellers) return;
    const clonedPanel = clone(bestSellers);
    if (!clonedPanel.settings) clonedPanel.settings = {};
    // @ts-ignore
    clonedPanel.settings[name] = value;
    try {
      const { data } = await updateDashboard({ variables: { panel: { id: clonedPanel.id, settings: clonedPanel.settings } } });
      const panelData = data.dashboard.panels.bestSellers;
      if (panelData)
        DashboardStore.update(s => {
          s.bestSellers = panelData;
        });
    } catch (error: any) {
      console.log(error);
      addNotification({ ok: 1, message: error.message });
    }
  };

  return (
    <Zone id="bestSellers">
      <div className="header">
        <h3>{t("Best sellers")}</h3>
        <CapsuleGroup
          variant="overZone"
          name="type"
          value={bestSellers?.settings?.type || "all"}
          onClick={handleCapsuleSelect}
          entries={[
            { label: t("All"), value: "all" },
            { label: t("Releases"), value: "releases" },
            { label: t("Products"), value: "products" }
          ]}
        />
      </div>
      <div className="entries">
        {bestSellers &&
          bestSellers.items.map(e => (
            <div className="entry" key={e.id}>
              <Link to={e.path || ""}>
                {e.data.images.length ? (
                  <img src={e.data.images[0]?.uri || ""} />
                ) : (
                  <img alt="Default image" src={getConfigProperty(config as Config, "designs", "missingImageUri")} />
                )}
              </Link>
            </div>
          ))}
      </div>
      {bestSellers && bestSellers.items.length === 0 ? (
        <div className="empty">
          <p>{t("Not enough sales data")}</p>
        </div>
      ) : null}
    </Zone>
  );
};

const SalesLocations = () => {
  const salesLocations = DashboardStore.useState(c => c.salesLocations);
  const { t } = useTranslation();
  return (
    <Zone id="salesLocations">
      <div className="header">
        <h3>{t("Top 10 online country sales")}</h3>
      </div>
      <div className="entries">
        {salesLocations &&
          salesLocations.data.map(e => (
            <div className="entry" key={e.alpha2}>
              <p>{getName(e.alpha2)}</p>
              <p>{e.count}</p>
            </div>
          ))}
        {salesLocations?.data?.length === 0 ? (
          <div className="empty">
            <p>{t("Not enough sales data")}</p>
          </div>
        ) : null}
      </div>
    </Zone>
  );
};

const RecentOrders = ({ updateDashboard }: { updateDashboard: (data: any) => Promise<{ data?: any }> }) => {
  const history = useHistory();
  const recentOrders = DashboardStore.useState(c => c.recentOrders);
  const { t } = useTranslation();

  const handleCapsuleSelect = async (value: any, name: string) => {
    if (!recentOrders) return;
    const clonedPanel = clone(recentOrders);
    if (!clonedPanel.settings) clonedPanel.settings = {};
    // @ts-ignore
    clonedPanel.settings[name] = value;
    try {
      const { data }: { data?: { dashboard: Dashboard } } = await updateDashboard({
        variables: { panel: { id: clonedPanel.id, settings: clonedPanel.settings } }
      });
      const panelData = data?.dashboard?.panels.recentOrders;
      if (panelData)
        DashboardStore.update(s => {
          s.recentOrders = panelData;
        });
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleClick = (order: Order) => {
    history.push(`/order/${order.incId}`);
  };

  return (
    <Zone id="recentOrders">
      <div className="content">
        <div className="header">
          <h3>{t("Recent orders")}</h3>
          <CapsuleGroup
            variant="overZone"
            name="origin"
            value={recentOrders?.settings?.origin || "All"}
            onClick={handleCapsuleSelect}
            entries={[
              { label: t("All"), value: "All" },
              { label: t("Shop"), value: "Shop" },
              { label: t("Discogs"), value: "Discogs" },
              { label: t("Online"), value: "Online" }
            ]}
          />
        </div>
        <div className="entries">
          {recentOrders?.data.orders &&
            recentOrders.data.orders.map((o, index) => (
              <Liner index={index} key={o.id} className="entry" onClick={() => handleClick(o)}>
                <div className="heading">
                  <span>#{o.incId}</span>
                  <span>{o.status}</span>
                  <span>{o.origin}</span>
                  <Price value={o.totals?.grand} />
                </div>
                <div className="info">
                  <span>#{o.id}</span>
                  <span>{o.buyer ? `${t("by")} ${o.buyer.name}` : ""}</span>
                  <span>{moment(o.created).format("ll")}</span>
                  <span>{moment(o.created).fromNow()}</span>
                </div>
              </Liner>
            ))}
        </div>
        {recentOrders?.data.orders && recentOrders.data.orders.length === 0 ? (
          <div className="empty">
            <p>{t("Not enough sales data")}</p>
          </div>
        ) : null}
      </div>
      <div className="seeMore">
        <Link to="/orders">{t("See more")}</Link>
      </div>
    </Zone>
  );
};

const NewMessages = () => {
  const history = useHistory();
  const newMessages = DashboardStore.useState(c => c.newMessages);
  const clonedMessage = clone(newMessages);
  const { t } = useTranslation();

  const handleClick = (id: string) => {
    history.push(`/order/${id}`);
  };

  return (
    <Zone id="newMessages">
      <div className="header">
        <h3>{t("Recent messages")}</h3>
        <Link to="/messages">{t("See more")}</Link>
      </div>
      <div className="entries">
        {clonedMessage?.data.messages &&
          clonedMessage.data.messages.map((m, i) => (
            <Liner index={i} key={i} className="entry" onClick={() => handleClick(String(m.orderIncId))}>
              <div className="header">
                <p>
                  {m.read ? "" : <FaEnvelope />} {m.sender?.name || m.sender?.email}
                </p>
                <span>{moment(m.created).fromNow()}</span>
              </div>
              <div className="content">
                <p>{m.message}</p>
              </div>
            </Liner>
          ))}
        {clonedMessage?.data?.messages.length === 0 ? (
          <div className="empty">
            <p>{t("You have no recent messages")}</p>
          </div>
        ) : null}
      </div>
    </Zone>
  );
};

const Poller = ({
  wasInitiallyLoaded,
  updateStore,
  addNotification
}: {
  wasInitiallyLoaded: boolean;
  updateStore: (data: any) => void;
  addNotification: AddNotification;
}) => {
  const [getDashboard] = useLazyQuery(GET_DASHBOARD, { fetchPolicy: "cache-and-network" });
  const { isIdled } = useIdleDetector();
  useEffect(() => {
    if (!wasInitiallyLoaded) return;

    const id = setInterval(() => {
      if (!isIdled)
        getDashboard({ variables: { panels: ["newMessages", "recentOrders", "realTimeUsers"] } })
          .then(({ data }) => {
            if (data) updateStore(data.dashboard);
          })
          .catch(e => addNotification({ ok: 0, message: e.message }));
    }, 60000);
    return () => clearInterval(id);
  }, [wasInitiallyLoaded, isIdled]);

  return null;
};
