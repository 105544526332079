import { gql } from "../../__generated__/gql";
import { ADDRESS_FIELDS } from "../types";

gql(`
  ${ADDRESS_FIELDS}
  fragment SupplierFields on Supplier {
    _id
    name
    taxInformation
    addresses {
      ...AddressFields
    }
    contacts {
      firstName
      lastName
      email
    }
  }
`);

export const GET_SUPPLIERS = gql(`
  query inventorySuppliers($page: Int!, $limit: Int, $term: String) {
    inventorySuppliers(page: $page, limit: $limit, term: $term) {
      suppliers {
        ...SupplierFields
      }
      pagination {
        page
        pages
      }
    }
  }
`);

export const POST_SUPPLIER_CREATE = gql(`
  mutation inventorySupplierCreate($name: String!) {
    inventorySupplierCreate(name: $name) {
      ...SupplierFields
    }
  }
`);

export const POST_SUPPLIER_UPDATE = gql(`
  mutation inventorySupplierUpdate($supplierRef: ID!, $supplierUpdateInput: SupplierUpdateInput!) {
    inventorySupplierUpdate(supplierRef: $supplierRef, supplierUpdateInput: $supplierUpdateInput) {
      ...SupplierFields
    }
  }
`);

export const POST_SUPPLIER_DELETE = gql(`
  mutation inventorySupplierDelete($supplierRef: ID!) {
    inventorySupplierDelete(supplierRef: $supplierRef)
  }
`);
