import React, { useState, useRef } from "react";
import { Button as ButtonV2 } from "../../componentsV2/Button";
import Modal from "../../components/modal";
import { useTranslation } from "react-i18next";
import { AddNotification } from "../../types/globals";
import { Item, Collection, Article, SeoEntry, Config } from "../../__generated__/graphql";
import { ModalHeaderContainer } from "../../componentsV2/SectionHeader/SectionHeader.styles";
import { Typography } from "../../componentsV2/Typography";
import TextArea from "../../components/styled/textArea";
import { useMutation } from "@apollo/client";
import { POST_ITEM_SEO_UPDATE } from "../../graphql/queries/item";
import { POST_ARTICLE_SEO_UPDATE } from "../../graphql/queries/article";
import { POST_COLLECTION_SEO_UPDATE } from "../../graphql/queries/collection";
import { Checkbox } from "../../componentsV2/Checkbox";
import moment from "moment";
import InputComponent from "../../components/styled/input";
import URI from "urijs";
import { Link } from "react-router-dom";

const SeoEditor = ({
  config,
  document,
  addNotification,
  onSubmit
}: {
  config: Config;
  document: Item | Article | Collection;
  addNotification: AddNotification;
  onSubmit?: () => void;
}) => {
  const [dirty, setDirty] = useState(false);
  const [udpateItemSeo] = useMutation(POST_ITEM_SEO_UPDATE);
  const [updateArticleSeo] = useMutation(POST_ARTICLE_SEO_UPDATE);
  const [updateCollectionSeo] = useMutation(POST_COLLECTION_SEO_UPDATE);
  const modalRef = useRef<any>();
  const [seo, setSeo] = useState<SeoEntry>(document.seo || { snapshot: null, title: "", description: "", index: false });
  const { t } = useTranslation();
  const limit = 160;

  const handleSave = async (e: any) => {
    e.preventDefault();
    try {
      const truncatedDescription = (seo.description || "").length > limit ? seo.description?.substring(0, limit) + "..." : seo.description;
      if (document.__typename === "Item")
        await udpateItemSeo({
          variables: {
            itemRef: document._id,
            seoInput: { title: seo?.title || "", description: truncatedDescription || "", slug: seo.slug || "", index: !!seo.index }
          }
        });
      else if (document.__typename === "Article") {
        const { data } = await updateArticleSeo({
          variables: {
            articleRef: document._id,
            seoInput: { title: seo?.title || "", description: truncatedDescription || "", slug: seo.slug || "", index: !!seo.index }
          }
        });
        if (onSubmit && data?.articleSeoUpdate) onSubmit();
      } else if (document.__typename === "Collection")
        await updateCollectionSeo({
          variables: {
            collectionRef: document._id,
            seoInput: { title: seo?.title || "", description: truncatedDescription || "", slug: seo.slug || "", index: !!seo.index }
          }
        });
      modalRef.current.close();
      setDirty(false);
    } catch (e: any) {
      addNotification({ ok: 0, message: e.toString() });
    }
  };

  const handleCheckboxClick = () => {
    setDirty(true);
    setSeo({ ...seo, index: !seo.index });
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDirty(true);
    setSeo({ ...seo, description: e.target.value });
  };
  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDirty(true);
    setSeo({ ...seo, title: e.target.value });
  };
  const handleSlugChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDirty(true);
    setSeo({ ...seo, slug: e.target.value.toLowerCase().replace(" ", "-") });
  };

  const configThemeRevisionIsRecent = config.eshop.theme.generation === 2 && (config.eshop.theme.revision || 0) > 13;

  return (
    <>
      <Modal ref={modalRef} style={{ minWidth: "40%" }}>
        <div id="editDescriptionsEditorModal">
          <ModalHeaderContainer>
            <Typography variant="pageTitle" tag="h2">
              {t("SEO settings")}
            </Typography>
            <button className="reset" type="button" onClick={() => modalRef.current.close()}>
              <i className="cg-icon-burger-close" />
            </button>
          </ModalHeaderContainer>

          <div className="content">
            <form onSubmit={handleSave} id="seoEditorForm">
              <div style={{ marginBottom: "30px" }}>
                <Checkbox
                  label={t("Index this page")}
                  onClick={() => handleCheckboxClick()}
                  checked={!!seo.index}
                  disabled={!config.eshop.seo.index || !configThemeRevisionIsRecent}
                  title={"Index this page"}
                />
              </div>
              <div style={{ marginBottom: "30px" }}>
                <InputComponent
                  onChange={handleTitleChange}
                  disabled={!seo.index || !configThemeRevisionIsRecent}
                  type="text"
                  label={t("Page title")}
                  name="title"
                  value={seo.title || ""}
                />
              </div>
              <div style={{ marginBottom: "30px" }}>
                <InputComponent
                  onChange={handleSlugChange}
                  disabled={!seo.index || !configThemeRevisionIsRecent}
                  autoComplete="off"
                  type="text"
                  pattern="^\S+$"
                  required
                  label={t("URL slug")}
                  name="slug"
                  value={seo.slug || ""}
                />
                <small>{new URI("http://example.com").path(document.path || document.handle || "").resource()}</small>
              </div>
              <div style={{ marginBottom: "30px" }}>
                <TextArea
                  name="short"
                  rows="2"
                  label={t("Description")}
                  placeholder={t("160 characters max")}
                  onChange={handleDescriptionChange}
                  value={seo.description || ""}
                  disabled={!seo.index || !configThemeRevisionIsRecent}
                />
              </div>
              {
                document.seo?.snapshot?.uri ? (
                  <div style={{}}>
                    <Typography variant="copy" tag="p" style={{ marginBottom: "var(--gutter)" }}>
                      {t("The last snapshot was made")} {moment(document.seo.snapshot.date).fromNow()}
                    </Typography>
                    <div>
                      <a target="_tab" href={document.seo.snapshot.uri}>
                        {t("View snapshot")}
                      </a>{" "}
                      <i className="cg-icon-open-tab" />
                    </div>
                  </div>
                ) : null
                // <Typography variant="copy" tag="p" style={{ marginBottom: "var(--gutter)" }}>
                //   {t("Snapshot not available")}
                // </Typography>
              }
              {document.__typename === "Article" && seo.slug !== document.seo?.slug ? (
                <Typography variant="copy" level="info">
                  {t("Menu elements that include a link to this article will be updated automatically")}
                </Typography>
              ) : null}
              {!configThemeRevisionIsRecent ? (
                <div style={{ width: "70%" }}>
                  <Link to={`/editor/theme/${config.eshop.theme.ref}`}>
                    <Typography variant="copy" level="warning">
                      {t("Your Eshop's current theme doesn't support SEO settings. Please update to the latest version of the theme.")}
                    </Typography>
                  </Link>
                </div>
              ) : null}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span />
                <ButtonV2 variant="primary" form="seoEditorForm" type="submit" disabled={!dirty || !configThemeRevisionIsRecent}>
                  {t("Save")}
                </ButtonV2>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <div>
        <ButtonV2 type="button" variant="secondary" onClick={() => modalRef.current.open()}>
          <span>{t("SEO settings")}</span>
        </ButtonV2>
      </div>
    </>
  );
};

export default SeoEditor;
