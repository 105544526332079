import styled from "styled-components";
import { getThemeProps } from "../../utils/styles";
import { BaseStyledComponentProps } from "../../types/styles.types";
import { applyOpacity, colorsAsRgbArray, colorsAsRgbString } from "@common-ground-io/colors";

type ThemeProps = {
  iconColorActive: string;
};
type ThemeVariants = {
  primary: string;
};
type ListingProps = BaseStyledComponentProps<ThemeProps, ThemeVariants> & {
  disabled?: boolean;
};

const layout = {
  light: {
    primary: {
      background: colorsAsRgbString.greyLightest,
      tabsBackground: colorsAsRgbString.greyLight,
      tabsTextColor: colorsAsRgbString.greyDarkest,
      tabBackgroundActive: colorsAsRgbString.grey,
      tabTextActive: colorsAsRgbString.greyLightest,
      tabTextDisable: colorsAsRgbString.grey
    }
  },
  dark: {
    primary: {
      background: colorsAsRgbString.greyDarkest,
      tabsBackground: colorsAsRgbString.greyDark,
      tabsTextColor: colorsAsRgbString.greyLight,
      tabBackgroundActive: colorsAsRgbString.greyLight,
      tabTextActive: colorsAsRgbString.greyDarkest,
      tabTextDisable: colorsAsRgbString.grey
    }
  }
};

const capsule = {
  light: {
    primary: {
      background: colorsAsRgbString.greyLight,
      text: colorsAsRgbString.grey
    }
  },
  dark: {
    primary: {
      background: colorsAsRgbString.greyDark,
      text: colorsAsRgbString.greyLight
    }
  }
};

export const ListingContainer = styled.div<{ bulk: boolean }>`
  padding: 15px;
  border-radius: 10px;
  background: ${props => getThemeProps(layout, props).background};
  display: grid;
  grid-gap: 20px;
  grid-template-columns: ${({ bulk }) => (bulk ? "10px 100px 100px 1.2fr 1fr 150px 10px" : "100px 100px 1.2fr 1fr 150px 10px")};
`;

export const ListingColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const ActionButtons = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

export const ListingsWithItemHeaderContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  margin-bottom: 20px;
`;

export const InventoryViewItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const InventoryViewItemListings = styled.div`
  display: grid;
  grid-gap: 20px;
`;

export const Capsules = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const CapsulesVertical = styled.div`
  display: grid;
  gap: 10px;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const OptionCapsule = styled.span`
  background-color: ${props => getThemeProps(capsule, props).background};
  color: ${colorsAsRgbString.blue};
  padding: 3px 11px;
  border-radius: 15px;
  white-space: nowrap;
`;

export const CategorieCapsule = styled.span`
  background-color: ${applyOpacity(colorsAsRgbArray.success, 0.2)};
  color: ${colorsAsRgbString.success};
  padding: 3px 11px;
  border-radius: 15px;
  text-align: center;
`;

export const TagCapsule = styled.span`
  background-color: ${props => getThemeProps(capsule, props).background};
  color: ${props => getThemeProps(capsule, props).text};
  padding: 3px 11px;
  border-radius: 15px;
  text-align: center;
`;

// ListingEditor

export const ListingEditContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--gutter);
  justify-content: space-between;
  min-height: 100%;
  box-sizing: content-box;
`;

export const ListingEditHeader = styled.div`
  margin-bottom: 20px;
`;

export const ListingEditContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
`;

export const ListingEditContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
`;

export const ListingEditContentTwoColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
`;

export const ListingEditContentSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
`;

export const Tabs = styled.div`
  display: flex;
  justify-content: space-between;
  grid-gap: 10px;
  background-color: ${props => getThemeProps(layout, props).tabsBackground};
  color: ${props => getThemeProps(layout, props).tabsTextColor};
  border-radius: 5px;
`;

export const Tab = styled.div<{ active: boolean; enable: boolean }>`
  display: flex;
  flex-basis: 100%;
  padding: 15px;
  :hover {
    cursor: ${props => (props.enable ? "pointer" : "not-allowed")};
  }
  border-radius: 5px;
  justify-content: center;
  background-color: ${props => (props.active ? getThemeProps(layout, props).tabBackgroundActive : "inherit")};
  color: ${props =>
    !props.enable ? getThemeProps(layout, props).tabTextDisable : props.active ? getThemeProps(layout, props).tabTextActive : "inherit"};
`;

export const ListingEditFooter = styled.div`
  display: flex;
  justify-content: space-between;
  /* grid-area: footer; */
`;
