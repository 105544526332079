import { useState, useEffect } from "react";

export const useTheme = (window: any) => {
  const [theme, setTheme] = useState(undefined);
  const handleChange = (value: any) => setTheme(value);

  const eventListener = (e: any) => handleChange(e.matches ? "dark" : "light");

  useEffect(() => {
    try {
      window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", eventListener);

      handleChange(window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light");

      return () => {
        window.matchMedia("(prefers-color-scheme: dark)").removeEventListener("change", eventListener);
      };
    } catch (error: any) {
      handleChange("light");
    }
  }, []);
  return { theme, setTheme };
};
