import React, { useState, useRef } from "react";
import Button from "../../styled/button";
import { Button as ButtonV2 } from "../../../componentsV2/Button";
import { Link } from "react-router-dom";
import Zone from "../../styled/zone";
import Loader from "../../common/loader";
import Input from "../../styled/input";
import moment from "moment";
import Modal from "../../modal";
import { useMutation } from "@apollo/client";
import { POST_CONFIG_ADMIN_ADD, POST_CONFIG_ADMIN_REMOVE } from "../../../graphql/queries/config";
import { Admin as IAdmin, Config, ConfigAdmin, MutationConfigAdminAddArgs, Session } from "../../../__generated__/graphql";
import { AddNotification } from "../../../types/globals";
import { Typography } from "../../../componentsV2/Typography";
import { useTranslation } from "react-i18next";
import { ModalHeaderContainer } from "../../../componentsV2/SectionHeader/SectionHeader.styles";

interface NewAdmin {
  firstName: string;
  lastName: string;
  email: string;
}

export const AdminList = ({
  config,
  addNotification,
  session,
  isMobile,
  helpPanel
}: {
  config: Config;
  addNotification: AddNotification;
  session: Session;
  isMobile: boolean;
  helpPanel: any;
}) => {
  const [adminToEdit, setAdminToEdit] = useState<NewAdmin>({ firstName: "", lastName: "", email: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [removeAdmin] = useMutation(POST_CONFIG_ADMIN_REMOVE);
  const [addAdmin] = useMutation(POST_CONFIG_ADMIN_ADD);

  const { t } = useTranslation();
  const modalRef = useRef<any>();

  const handleRemoveAdmin = async (ref: string) => {
    setIsLoading(true);
    try {
      await removeAdmin({ variables: { userRef: ref } });
      addNotification({ ok: 1, message: t("Admin removed") });
    } catch (error: any) {
      addNotification({ ok: 0, message: error.message });
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddAdmin = async (e: any) => {
    setIsLoading(true);
    e.preventDefault();
    const form: MutationConfigAdminAddArgs = {
      firstName: adminToEdit.firstName,
      lastName: adminToEdit.lastName,
      email: adminToEdit.email
    };
    try {
      await addAdmin({ variables: form });
      modalRef.current.close();
      addNotification({ ok: 1, message: t("Admin created") });
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    } finally {
      setIsLoading(false);
    }
  };

  const availableSeats = config.plan.seats - config.admins.length;
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => setAdminToEdit({ ...adminToEdit, [e.target.name]: e.target.value });
  return (
    <Zone className="shopAdmins">
      <Typography variant="pageTitle" tag="h1">
        {t("Team")}
      </Typography>
      <Modal style={{}} ref={modalRef}>
        <div id="">
          <ModalHeaderContainer>
            <Typography variant="pageTitle" tag="h2">
              {t("Add an admin")}
            </Typography>
            <button type="button" className="reset" onClick={() => modalRef.current.close()}>
              X
            </button>
          </ModalHeaderContainer>
          <form onSubmit={handleAddAdmin}>
            <div style={{ display: "flex", flexDirection: "column", gap: "var(--gutter)", marginBottom: "var(--gutter)" }}>
              <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "var(--gutter)" }}>
                <Input
                  required
                  type="text"
                  autoComplete="none"
                  variant="overZone"
                  name="firstName"
                  placeholder={t("First name") + "..."}
                  value={adminToEdit.firstName}
                  onChange={handleInputChange}
                  label={t("First name")}
                />
                <Input
                  required
                  type="text"
                  autoComplete="none"
                  variant="overZone"
                  name="lastName"
                  placeholder={t("Last name") + "..."}
                  value={adminToEdit.lastName}
                  onChange={handleInputChange}
                  label={t("Last name")}
                />
              </div>
              <div className="email">
                <Input
                  required
                  type="email"
                  autoComplete="none"
                  variant="overZone"
                  name="email"
                  placeholder={t("Email address") + "..."}
                  value={adminToEdit.email}
                  onChange={handleInputChange}
                  label={t("Email address")}
                />
              </div>
              <p>{t("In order to login to their account, this user will be required to reset their password")}</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <ButtonV2 variant="secondary" disabled={isLoading} onClick={() => modalRef.current.close()} type="button">
                {t("Cancel")}
              </ButtonV2>
              <ButtonV2 variant="primary" disabled={isLoading || !adminToEdit.email} type="submit">
                {isLoading ? <Loader /> : t("Submit")}
              </ButtonV2>
            </div>
          </form>
        </div>
      </Modal>
      <div className="header">
        <div className="left">
          {config.plan && config.plan.seats ? (
            <p>
              {t("Available seats")}: {availableSeats}/{config.plan.seats}
            </p>
          ) : null}
          <Button
            variant="secondaryOverZone"
            style={{ marginLeft: "var(--gutter)" }}
            type="button"
            onClick={() => helpPanel.load("7329015")}>
            <span>{t("Help?")}</span>
          </Button>
        </div>
        {!isMobile ? (
          <>
            {config.plan && availableSeats > 0 ? (
              <div className="addAdmin">
                <ButtonV2 variant="primary" disabled={isLoading} onClick={() => modalRef.current.open()}>
                  {t("Add an admin")}
                </ButtonV2>
              </div>
            ) : (
              <a href="mailto:support@common-ground.io?subject=Plan upgrade">{t("Need more seats? Upgrade your plan")}</a>
            )}{" "}
          </>
        ) : null}
      </div>
      <div className="adminList">
        {config &&
          config.admins.map((u, i) => (
            <React.Fragment key={i}>
              <Admin config={config} user={u} session={session} addNotification={addNotification} handleRemoveAdmin={handleRemoveAdmin} />
              {i < config.admins.length - 1 ? <hr /> : null}
            </React.Fragment>
          ))}
      </div>
    </Zone>
  );
};

const Admin = ({
  user,
  config,
  session,
  isLoading,
  handleRemoveAdmin
}: {
  user: ConfigAdmin;
  config: Config;
  session: Session;
  isLoading?: boolean;
  handleRemoveAdmin?: any;
  handleAddAdmin?: any;
  addNotification: any;
  isNew?: boolean;
  handleCancelNewAdmin?: any;
}) => {
  const orgOwner = config?.admins.find(a => a.role === "owner");
  const isOwner = orgOwner && session.user._id === orgOwner.admin?._id;
  const isMyself = (user.admin as IAdmin)?._id === session.user._id;
  const { t } = useTranslation();

  return (
    <div className="admin">
      <p>
        {(user.admin as IAdmin)?.name}
        <span className="email"> - {user.admin?.email}</span>
      </p>
      <p className="added">
        {(user as ConfigAdmin).added ? (
          <span>
            {t("Added")} {moment((user as ConfigAdmin)?.added?.date).format("ll")} {t("by")} {(user as ConfigAdmin).added?.by?.name}
          </span>
        ) : null}
      </p>
      <p className="role">{(user as ConfigAdmin).role}</p>
      <div className="actions">
        {isMyself ? <Link to="/profile">{t("Edit my profile")}</Link> : null}
        {!isMyself && isOwner ? (
          <Button
            variant="danger"
            disabled={isLoading || user.admin?.email === session.user.email}
            onClick={() =>
              window.confirm(`${(user as ConfigAdmin).admin?.name} ${t("will be removed and notified, are you sure?")}`) &&
              handleRemoveAdmin((user as ConfigAdmin).admin?._id)
            }>
            {isLoading ? <Loader /> : t("Remove")}
          </Button>
        ) : null}
      </div>
    </div>
  );
};
