import moment from "moment";

export const today = {
  start: moment().startOf("day").format(),
  end: moment().endOf("day").format()
};
export const yesterday = {
  start: moment().subtract(1, "day").startOf("day").format(),
  end: moment().subtract(1, "day").endOf("day").format()
};
export const thisWeek = {
  start: moment().startOf("isoWeek").format(),
  end: moment().endOf("isoWeek").format()
};
export const lastWeek = {
  start: moment().subtract(1, "week").startOf("isoWeek").format(),
  end: moment().subtract(1, "week").endOf("isoWeek").format()
};
export const last7Days = {
  start: moment().subtract(1, "week").startOf("day").format(),
  end: moment().endOf("day").format()
};
export const last30Days = {
  start: moment().subtract(1, "month").startOf("day").format(),
  end: moment().endOf("day").format()
};
export const thisMonth = {
  start: moment().startOf("month").format(),
  end: moment().endOf("month").format()
};
export const lastMonth = {
  start: moment().subtract(1, "month").startOf("month").format(),
  end: moment().subtract(1, "month").endOf("month").format()
};
