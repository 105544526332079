import React, { useState, useEffect, useCallback } from "react";
import Button from "../styled/button";
import Input from "../styled/input";
import Zone from "../styled/zone";
import { Link, Prompt, useLocation, useHistory } from "react-router-dom";
import clone from "clone";
import { FiRefreshCw } from "react-icons/fi";
import { Select } from "../styled/select";
import Loader from "../common/loader";
import { GlobalStore } from "../../stores/global";
import Four0Four from "../global/404";
import URI from "urijs";
import {
  GET_TEMPLATE,
  POST_TEMPLATE_DATASET_GENERATE,
  POST_TEMPLATE_DELETE,
  POST_TEMPLATE_RENDER,
  POST_TEMPLATE_UDPATE
} from "../../graphql/queries/template";
import { useQuery, useMutation } from "@apollo/client";
import { AddNotification, Match } from "../../types/globals";
import { Session, Template } from "../../__generated__/graphql";
import { Typography } from "../../componentsV2/Typography";
import { useTranslation } from "react-i18next";
import { Button as ButtonV2 } from "../../componentsV2/Button";
import { TFunction } from "i18next";
import { vscodeDark } from "@uiw/codemirror-theme-vscode";
import CodeMirror from "@uiw/react-codemirror";
import { css } from "@codemirror/lang-css";
import { basicSetup } from "../../pages/eshopEditor/includes/cssEditor";

export const templateTypes = (t: TFunction) => {
  return [
    { value: "newsletter-html", label: t("Newsletter - html") },
    { value: "sticker-html", label: t("Sticker - html") },
    { value: "order-invoice-html-pdf", label: t("Order Invoice - html/pdf") },
    { value: "credit-invoice-html-pdf", label: t("Credit Invoice - html/pdf") },
    { value: "order-receipt-html", label: t("Order email receipt - html") },
    { value: "invoice-receipt-html", label: t("Invoice email receipt - html") },
    { value: "message-receipt-html", label: t("Message email receipt - html") },
    { value: "shipping-receipt-html", label: t("Shipping email receipt - html") },
    { value: "backinstock-receipt-html", label: t("Back in stock receipt - html") },
    { value: "account-creation-receipt-html", label: t("Account creation - html") },
    { value: "message", label: t("Order message - text") },
    { value: "comment", label: t("Listing comment - text") }
  ];
};

export default function TemplateEditor({ match }: { match: Match }) {
  const { addNotification, session } = GlobalStore.useState(c => c);
  const isNew = match.path.includes("new");
  const id = match.params.id;
  const { loading, data } = useQuery(GET_TEMPLATE, { variables: { templateRef: id }, fetchPolicy: "cache-and-network" });

  const template = data && data.template;

  if (template === null) return <Four0Four />;
  else if (!template || loading) return <Loader />;

  return <TemplateSingle data={clone(template)} isNew={isNew} session={session as Session} addNotification={addNotification} />;
}

const TemplateSingle = ({
  data,
  isNew,
  addNotification,
  session
}: {
  data: Template;
  isNew: boolean;
  addNotification: AddNotification;
  session: Session;
}) => {
  const [template, setTemplate] = useState(data);
  const [dirty, setDirty] = useState(false);
  const history = useHistory();

  const [updateTemplate] = useMutation(POST_TEMPLATE_UDPATE);
  const [deleteTemplate] = useMutation(POST_TEMPLATE_DELETE);
  const [renderTemplate] = useMutation(POST_TEMPLATE_RENDER);
  const [generateDataset] = useMutation(POST_TEMPLATE_DATASET_GENERATE);
  const { t } = useTranslation();

  const isAdmin = session.user.type === "SuperAdmin";
  const location = useLocation();
  const search = new URI(location.pathname + location.search).search(true);
  const [preview, setPreview] = useState("");
  const [isComputingPreview, setIsComputingPreview] = useState(false);
  const [timerHandle, setTimerHandle] = useState<any>(undefined);

  const handleSubmitTemplate = async () => {
    if (!template.title || !template.type) return addNotification({ ok: 0, message: t("Missing parameters") });

    try {
      const { data } = await updateTemplate({
        variables: {
          templateRef: template._id,
          templateUpdateInput: {
            title: template.title,
            data: template.data,
            type: template.type
          }
        }
      });
      if (data?.templateUpdate) {
        setTemplate(clone(data.templateUpdate));
        setDirty(false);
        addNotification({ ok: 1, message: t("Updated") });
        if (search.redirect) {
          history.push(search.redirect);
        }
      }
    } catch (e: any) {
      addNotification({ ok: 0, message: e.toString() });
    }
  };

  const reloadData = useCallback(async () => {
    if (isNew) return;
    try {
      await generateDataset({ variables: { templateRef: template._id } });
    } catch (e: any) {
      addNotification({ ok: 0, message: e.data.toString() });
    }
  }, [addNotification, isNew, template]);

  const handleUpdateContent = async (content: string) => {
    template.data.content = content;
    setTemplate(clone(template));
  };

  const handleDeleteTemplate = async (ref: string) => {
    try {
      await deleteTemplate({ variables: { templateRef: ref } });
      addNotification({ ok: 1, message: t("Deleted") });
      history.push("/templates");
    } catch (error: any) {
      addNotification({ ok: 0, message: error.message });
    }
  };

  const handleSelectChange = (selectedOption: any) => {
    template.type = selectedOption.value;
    setTemplate(clone(template));
    setDirty(true);
  };

  const handleEditTemplateTitle = (e: any) => {
    // @ts-ignore
    template[e.target.name] = e.target.value;
    setTemplate(clone(template));
    setDirty(true);
  };

  let templatesToUse: any = [...templateTypes(t)];

  if (isAdmin)
    templatesToUse = [
      ...templatesToUse,
      { value: "account-creation-receipt-html", label: t("Account creation receipt - html") },
      { value: "cg-invoice-html-pdf", label: t("CG Invoice - html/pdf") },
      { value: "cg-invoice-receipt-html", label: t("CG Invoice receipt - html") },
      { value: "cg-invoice-reminder-html", label: t("CG Invoice reminder - html") },
      { value: "cg-trial-end-notice-html", label: t("CG Trial end notice - html") },
      { value: "cg-account-suspension-notice-html", label: t("CG Account suspension notice - html") },
      { value: "html", label: t("Generic - html") }
    ];

  const computePreview = useCallback(
    async (content: string) => {
      if (isNew || isComputingPreview || !template._id) return;
      setIsComputingPreview(true);
      try {
        const { data } = await renderTemplate({ variables: { templateRef: template._id, content } });
        if (data?.templateRender) setPreview(data.templateRender);
      } catch (e: any) {
        setPreview(`<!DOCTYPE html><html><head><body><p>${e.message}</p></body></head></html>`);
      } finally {
        setIsComputingPreview(false);
      }
    },
    [isNew, template._id]
  );

  useEffect(() => {
    computePreview(template.data.content);
  }, [computePreview]);

  const onChange = (value: string) => {
    if (value !== template.data.content) setDirty(true);
    handleUpdateContent(value);
    if (!isComputingPreview) {
      if (timerHandle !== undefined) {
        clearTimeout(timerHandle);
        const id = setTimeout(() => computePreview(value), 500);
        setTimerHandle(id);
      } else if (!timerHandle) setTimerHandle(1);
    }
  };

  const handleDatasetGenerate = async () => {
    setIsComputingPreview(true);
    await reloadData();
    await computePreview(template.data.content);
    setIsComputingPreview(false);
  };

  let codeType = "text";
  if (template.type.includes("html")) codeType = "pug";

  const selectedType = templatesToUse.find((tu: any) => tu.value === template?.type);

  return (
    <div id="editTemplate">
      <Prompt when={!isNew && dirty} message={() => t("Are you sure you want to discard your changes?")} />
      <section className="header">
        <div className="left">
          <Typography variant="pageTitle" tag="h1">
            {t("Template")}
          </Typography>
          <Link to="/templates">
            <Button type="button" variant="noStyle">
              {t("Back")}
            </Button>
          </Link>
        </div>
        <div className="right">
          {isNew ? null : (
            <ButtonV2
              type="button"
              variant="warning"
              onClick={() => {
                if (window.confirm(t("Are you sure?"))) handleDeleteTemplate(template._id);
              }}>
              {t("Delete")}
            </ButtonV2>
          )}
          <ButtonV2 type="submit" form="templateForm" variant="primary" disabled={!dirty}>
            {!isNew ? t("Save") : t("Create")}
          </ButtonV2>
        </div>
      </section>
      {template ? (
        <Zone>
          <form
            id="templateForm"
            onSubmit={(e: any) => {
              e.preventDefault();
              handleSubmitTemplate();
            }}>
            <div className="left">
              <div className="title">
                <Input
                  label={t("Template title")}
                  variant="overZone"
                  type="text"
                  name="title"
                  required
                  value={template.title}
                  onChange={handleEditTemplateTitle}
                />
              </div>
              <div className="type">
                <Select
                  variant="overZone"
                  label={t("Template type")}
                  value={selectedType}
                  onChange={(e: any) => handleSelectChange(e)}
                  options={templatesToUse}
                />
              </div>
            </div>
            <div className="right"></div>
          </form>
        </Zone>
      ) : (
        <Loader withMargins />
      )}
      {template ? (
        <div className="editor">
          <div id="codeEditor">
            <CodeMirror
              indentWithTab={true}
              basicSetup={basicSetup}
              value={template.data.content || ""}
              height="100%"
              theme={vscodeDark}
              onChange={(val: string) => onChange(val)}
              extensions={[css()]}
            />
          </div>
          <div id="codePreview" style={{ position: "sticky", top: "0" }}>
            <div className="header">
              <h3>{isNew ? t("Create and save template to start the live preview") : t("Live preview")}</h3>
              {codeType === "pug" && !isNew ? (
                <Button variant="secondary" disabled={isComputingPreview} type="button" onClick={() => handleDatasetGenerate()}>
                  {isComputingPreview ? (
                    t("Processing") + "..."
                  ) : (
                    <>
                      <FiRefreshCw /> {t("Shuffle data")}
                    </>
                  )}
                </Button>
              ) : null}
            </div>
            <iframe title="Preview" srcDoc={preview} />
          </div>
        </div>
      ) : null}
    </div>
  );
};
