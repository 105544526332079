import { gql } from "../../__generated__/gql";

export const ARTICLE_FIELDS = gql(`
  fragment ArticleFields on Article {
    _id
    id
    private
    homepage
    title
    tags
    handle
    seo {
      ...SeoFields
    }
    published {
      date
      status
    }
    created {
      date
      user {
        name
      }
    }
    modified {
      date
      user {
        name
      }
    }
    content {
      rows {
        id
        type
        options {
          fullWidth
          noPadding
        }
        feed {
          tags
          format
          limit
          sort {
            key
            order
          }
        }
        layout
        columns {
          id
          blocks {
            id
            type
            data
          }
        }
      }
    }
    thumbnail {
      ...MediaFields
    }
  }
`);

export const GET_ARTICLE = gql(`
  query article($id: Int) {
    article(id: $id) {
      ...ArticleFields
    }
    articleTags
  }
`);

export const GET_ARTICLES = gql(`
  query articlesConnection($page: Int, $tags: [String], $term: String, $limit: Int, $sortBy: String, $sortOrder: Int) {
    articlesConnection(page: $page, tags: $tags, term: $term, limit: $limit, sortBy: $sortBy, sortOrder: $sortOrder) {
      articles {
        _id
        id
        handle
        title
        tags
        homepage
        previewUri
        thumbnail {
          ...MediaFields
        }
        published {
          status
          date
        }
        modified {
          date
          user {
            name
          }
        }
        created {
          date
          user {
            name
          }
        }
      }
      pagination {
        page
        pages
        count
      }
    }
    articleTags
  }
`);

export const POST_ARTICLE = gql(`
  mutation articleCreate($title: String!) {
    articleCreate(title: $title) {
      id
      handle
      title
    }
  }
`);

export const POST_ARTICLE_UPDATE = gql(`
  mutation articleUpdate($articleRef: ID!, $articleUpdateInput: ArticleUpdateInput!) {
    articleUpdate(articleRef: $articleRef, articleUpdateInput: $articleUpdateInput) {
      ...ArticleFields
    }
  }
`);

export const POST_ARTICLE_DELETE = gql(`
  mutation articleDelete($articleRef: ID!) {
    articleDelete(articleRef: $articleRef)
  }
`);

export const POST_ARTICLE_SEO_UPDATE = gql(`
  mutation articleSeoUpdate($articleRef: ID!, $seoInput: SeoInput!) {
    articleSeoUpdate(articleRef: $articleRef, seoInput: $seoInput) {
      ...ArticleFields
    }
  }
`);
