import { gql } from "../__generated__/gql";

gql(`
  fragment SeoFields on SeoEntry {
    title
    description
    index
    slug
    snapshot {
      date
      uri
    }
  }
`);

export const LISTING_FIELDS = gql(`
  fragment ListingFields on Listing {
    _id
    id
    discogsId
    status
    available
    location
    posted
    supplierCode
    taxDefinition
    readOnly
    sku
    barcode
    stock {
      quantity
    }
    prices {
      sale
      compare
      cost
      beforeTaxes
    }
    onePerCustomer
    options {
      name
      value
    }
    categories
    comments
    privateComments
    preventDiscogsListing
    secondHand
    preOrder
    # channels {
    #   pos {
    #     enable
    #   }
    #   eshop {
    #     enable
    #   }
    #   discogs {
    #     enable
    #   }
    #   bandcamp {
    #     enable
    #   }
    # }
  }
`);

export const LISTING_COPY_FIELDS = gql(`
  fragment ListingCopyFields on ListingCopy {
    _id
    id
    discogsId
    status
    available
    location
    posted
    supplierCode
    taxDefinition
    readOnly
    sku
    barcode
    stock {
      quantity
    }
    prices {
      sale
      compare
      cost
      beforeTaxes
    }
    onePerCustomer
    options {
      name
      value
    }
    categories
    comments
    supplierCode
    privateComments
    preventDiscogsListing
    secondHand
    preOrder
  }
`);

export const ITEM_FIELDS = gql(`
  fragment ItemFields on Item {
    _id
    id
    incId
    type
    path
    wants
    created
    uri
    bitly
    listings {
      ...ListingFields
    }
    seo {
      ...SeoFields
    }
    descriptions {
      main
      shop {
        text
        html
        short
      }
    }
    data {
      discogsId
      title
      images {
        uri
        alternative {
          uri
        }
      }
      thumb
      assetLink
      weight
      genres
      styles
      manufacturer
      cat
      type
      releaseDate
      year
      identifiers {
        type
        description
        value
      }
      tracklist {
        type_
        title
        artists {
          name
          id
          anv
        }
        uri
        duration
        position
        plays
      }
      formats {
        descriptions
        name
        qty
        text
      }
      country
      artists {
        name
        anv
        id
        join
      }
      labels {
        name
        id
        catno
      }
      giftCard {
        active
        codeLength
        prefix
        singleSpending
        generateVoucherOnSale
      }

      subtitle
      authors
      publisher
      publishedDate
      pageCount
      categories
      maturityRating
      language
      format
    }
  }
`);

export const INVENTORY = gql(`
  fragment InventoryFields on Inventory {
    pagination {
      limit
      pages
      page
    }
    stats {
      total
      stockCount
      listingsCount
    }
    entries {
      _id
      id
      incId
      type
      path
      wants
      listings {
        ...ListingFields
      }
      descriptions {
        main
      }
      data {
        discogsId
        images {
          uri
        }
      }
    }
  }
`);

export const ADDRESS_FIELDS = gql(`
  fragment AddressFields on Address {
    _id
    description
    firstName
    lastName
    addressLine1
    addressLine2
    streetNumber
    city
    state
    stateCode
    postCode
    alpha2
    country
    type
  }
`);

export const USER_REFERENCE_FIELDS = gql(`
  fragment UserReferenceFields on UserReference {
    type
    name
    email
    ref
  }
`);

export const EMAIL_RECIPIENT_FIELDS = gql(`
  fragment EmailRecipientFields on EmailRecipient {
    recipient
    date
  }
`);

export const TAX_FIELDS = gql(`
  fragment TaxFields on Tax {
    name
    rate
    amount
  }
`);

export const LOG_ENTRY_FIELDS = gql(`
  fragment LogEntryFields on LogEntry {
    action
    description
    date
    user {
      ...UserReferenceFields
    }
  }
`);

export const LINE_ITEM_FIELDS = gql(`
  fragment LineItemFields on LineItem {
    _id
    quantity
    listing {
      ...ListingCopyFields
    }
    added
    giftCard {
      id
      generated
    }
    unavailable
    price {
      base
      beforeTaxes
      discounts {
        value
        type
      }
      subtotal
      subtotalBeforeTaxes
      taxes {
        ...TaxFields
      }
    }
    item {
      path
      description
      id
      ref
      thumbnail
    }
  }
`);

export const BUYER_FIELDS = gql(`
  fragment BuyerFields on Buyer {
    _id
    created
    updated
    lastLogin
    email
    accountCreated
    active
    firstName
    lastName
    name
    telephone
    notes
    organisation
    taxNumber
    path
    orderCount
    creditNotesCount
    wantsCount
    verification {
      status
      date
    }
    lists {
      _id
      ref
      added
      lastModified
      optout {
        status
        date
        reason
      }
    }
    addresses {
      ...AddressFields
    }
  }
`);

export const ADMIN_FIELDS = gql(`
  fragment AdminFields on Admin {
    _id
    email
    firstName
    lastName
    name
    addedAt
    type
    hasIntercom
    verification {
      status
      date
      token
      hash
    }
  }
`);

export const TEMPLATES_FIELDS = gql(`
  fragment TemplateFields on Template {
    _id
    id
    created
    title
    type
    primary
    createdBy {
      name
    }
    upgradeAvailable
    versions {
      version
      notes
      date
    }
    version
    modified
    data {
      fields {
        type
        name
        value
        description
        required
      }
      content
      model
    }
  }
`);
