import React from "react";
import BeatLoader from "react-spinners/BeatLoader";
import { GlobalStore } from "../../stores/global";
import Colours from "@common-ground-io/common-assets/assets/colors.json";
import { EThemes } from "../../types/globals";

export default function Loader(props: any) {
  const theme = GlobalStore.useState(c => c.theme);

  return (
    <div className="loader" style={props.styles || (props.withMargins ? { marginTop: "5%" } : {})}>
      <BeatLoader size={props.size || 5} color={theme === EThemes.dark ? Colours.greyLight : Colours.greyDarker} loading={true} />
    </div>
  );
}
