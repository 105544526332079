import React, { useState, useEffect, useRef } from "react";
import DatePicker from "../styled/datePicker";
import Checkbox from "../styled/checkbox";
import Button from "../styled/button";
import { Button as ButtonV2 } from "../../componentsV2/Button";
import Input from "../styled/input";
import InputWithSubmit from "../styled/inputWithSubmit";
import { useHistory, Link, Prompt, RouteComponentProps } from "react-router-dom";
import { SearchListings } from "../global/search";
import Loader from "../common/loader";
import Four0Four from "../global/404";
import { Select } from "../styled/select";
import { SelectTemplate } from "../../components/common/select";
import moment from "moment";
import { FiArrowUp } from "react-icons/fi";
import ReactModal from "../modal";
import { GlobalStore } from "../../stores/global";
import Reorder from "react-reorder";
import { GET_LISTS } from "../../graphql/queries/list";
import { GET_CAMPAIGN, POST_CAMPAIGN_SEND, POST_CAMPAIGN_TEST_EMAIL, POST_CAMPAIGN_UPDATE } from "../../graphql/queries/campaign";
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import clone from "clone";
import { CampaignMetrics, Config, Campaign, Template, Listing, Item, Media, ThemeColorPreset } from "../../__generated__/graphql";
import { useTranslation } from "react-i18next";
import MediaSelector from "../../components/media/selector";
import { ModalHeaderContainer } from "../../componentsV2/SectionHeader/SectionHeader.styles";
import { Typography } from "../../componentsV2/Typography";
import { BlockPicker, ColorResult } from "react-color";
import useOutsideClick from "../../hooks/useOutsideClick";
import { GET_THEME } from "../../graphql/queries/theme";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import QuillEditor from "../quillEditor";

export default ({ match }: RouteComponentProps<{ id: string }>) => {
  const { addNotification, config } = GlobalStore.useState(c => c);
  const modalRef = useRef<any>(null);
  const { data: listData } = useQuery(GET_LISTS);
  const lists = listData?.lists?.lists;

  const history = useHistory();
  const [campaign, setCampaign] = useState<Campaign | null>();
  const [metrics, setMetrics] = useState<CampaignMetrics | undefined>(undefined);
  const [testEmails, setTestEmails] = useState("");
  const [sendingDate, setSendingDate] = useState<string | undefined>();
  const [sendAtTime, setSendAtTime] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [isSendingTestEmail, setIsSendingTestEmail] = useState(false);
  const [isSavingOrCreating, setIsSavingOrCreating] = useState(false);
  const [isSendingCampaign, setisSendingCampaign] = useState(false);
  const [getCampaign] = useLazyQuery(GET_CAMPAIGN, { fetchPolicy: "cache-and-network" });
  const [updateCampaign] = useMutation(POST_CAMPAIGN_UPDATE);
  const [sendCampaign] = useMutation(POST_CAMPAIGN_SEND);
  const [sendTestEmail] = useMutation(POST_CAMPAIGN_TEST_EMAIL);
  const { data } = useQuery(GET_THEME, { fetchPolicy: "cache-and-network", variables: { themeRef: config?.eshop.theme.ref } });
  const [index, setIndex] = useState(parseInt(localStorage.getItem("campaign-tab-id") || "") || 0);

  useEffect(() => {
    localStorage.setItem("campaign-tab-id", String(index));
  }, [index]);

  const { t } = useTranslation();
  const theme = data?.theme;

  document.title = "Campaign";

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await getCampaign({ variables: { campaignRef: match.params.id } });
        if (!data?.campaign?.campaign) throw new Error("Invalid campaign");
        setCampaign(clone(data.campaign.campaign) as Campaign);
        if (data.campaign.metrics) setMetrics(data.campaign.metrics);
      } catch (e: any) {
        setCampaign(null);
        addNotification({ ok: 0, message: e.message });
      }
    };
    getData();
  }, [match.params.id, addNotification]);

  const handleSelectTemplate = async (template: Template) => {
    if (!campaign?.fields) return;
    try {
      campaign.template = { _id: template._id, title: template.title };

      const campaignData = {
        title: campaign.title,
        subject: campaign.subject,
        listingRefs: campaign.entries.filter(e => e.listing && e.item).map(e => e.listing._id),
        listRef: campaign.list?._id as string,
        fields: campaign.fields,
        templateRef: template._id
      };
      if (!campaignData.listRef) throw new Error("A list is required");
      const { data } = await updateCampaign({ variables: { campaignRef: campaign._id, campaignUpdateInput: campaignData } });
      setCampaign(clone(data?.campaignUpdate) as Campaign);
      addNotification({ ok: 1, message: "Campaign updated" });
      setDirty(false);
    } catch (e: any) {
      setIsSavingOrCreating(false);
      addNotification({ ok: 0, message: e.message || e.toString() });
    }
  };

  const handleSelectList = (option: any) => {
    if (!campaign) return;
    campaign.list = option.value;
    setCampaign({ ...campaign });
    setDirty(true);
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!campaign) return;
    // @ts-expect-error because of index
    campaign[event.target.name] = event.target.value;
    setCampaign({ ...campaign });
    setDirty(true);
  };

  const handleDeleteEntry = (id: number) => {
    if (!campaign) return;
    const index = campaign.entries.findIndex(e => e.listing.id === id);
    if (index !== -1) {
      campaign.entries.splice(index, 1);
      setCampaign({ ...campaign });
      setDirty(true);
    }
  };

  const handleSelectListing = (item: Item, listing: Listing) => {
    if (!campaign) return;
    campaign.entries.push({ listing, item });
    setCampaign({ ...campaign });
    setDirty(true);
  };

  const handleSubmitCampaignForm = async (event?: any) => {
    if (!campaign) return;
    if (event) event.preventDefault();
    if (!campaign || !campaign.list) return addNotification({ ok: 0, message: "A list of recipients is required" });
    setIsSavingOrCreating(true);
    const campaignData = {
      title: campaign.title,
      subject: campaign.subject,
      listingRefs: campaign.entries.filter(e => e.listing && e.item).map(e => e.listing._id),
      listRef: campaign.list?._id,
      fields: campaign.fields,
      templateRef: campaign.template?._id as string
    };

    try {
      const { data } = await updateCampaign({ variables: { campaignRef: campaign._id, campaignUpdateInput: campaignData } });
      setCampaign(clone(data?.campaignUpdate) as Campaign);
      addNotification({ ok: 1, message: "Campaign updated" });
      setDirty(false);
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    } finally {
      setIsSavingOrCreating(false);
    }
  };

  const handleSendToList = async () => {
    if (!campaign) return;
    if (sendAtTime && !sendingDate) return addNotification({ ok: 0, message: "Please specify date and time" });
    if (sendAtTime && moment(sendingDate).isBefore(moment())) return addNotification({ ok: 0, message: "Date must be in the future" });
    setisSendingCampaign(true);
    try {
      await sendCampaign({
        variables: {
          campaignRef: campaign._id,
          sendAt: sendAtTime ? sendingDate : null
        }
      });
      addNotification({ ok: 1, message: "Campaign sent" });
      history.push("/newsletters");
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
    setisSendingCampaign(false);
  };

  const handleSendTestEmail = async (e: any) => {
    if (!campaign) return;
    e.preventDefault();
    setIsSendingTestEmail(true);
    try {
      await sendTestEmail({
        variables: {
          campaignRef: campaign._id,
          emails: testEmails.split(",")
        }
      });
      addNotification({ ok: 1, message: "Test emails sent" });
      setTestEmails("");
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
    setIsSendingTestEmail(false);
  };

  const handleUpdateTestEmail = async (e: any) => {
    setTestEmails(e.target.value);
  };

  const handleReorder = async (e: any, start: number, end: number) => {
    if (!campaign) return;
    const newEntries = [...campaign.entries];
    const entryMoving = newEntries.splice(start, 1)[0];
    newEntries.splice(end, 0, entryMoving);
    campaign.entries = newEntries;
    setCampaign({ ...campaign });
    setDirty(true);
  };

  const handleFieldValueChange = ({ fieldName, value }: { fieldName: string; value: string | null }) => {
    if (!campaign) return;
    const field = campaign?.fields?.find(f => f.name === fieldName);
    if (field) {
      setDirty(true);
      field.value = value;
      setCampaign(clone(campaign));
    }
  };

  if (campaign === undefined) return <Loader style={{ marginTop: "var(--gutter)" }} />;
  else if (campaign === null) return <Four0Four />;

  const entries = [
    { title: t("Content"), id: "content" },
    { title: `${t("Items")} (${campaign.entries.length})`, id: "listings" },
    { title: t("Settings"), id: "settings" }
  ];

  const availableFonts: Set<string> = new Set(theme?.__typename === "ThemeV2" ? theme.styles.map(s => s.family?.family || "") : []);

  return (
    <div id="campaign">
      <ReactModal style={{}} ref={modalRef}>
        <div id="campaignModal" className="">
          <ModalHeaderContainer>
            <Typography variant="pageTitle" tag="h2">
              {t("Preview and send")}
            </Typography>
            <button className="reset" type="button" onClick={() => modalRef.current.close()}>
              <i className="cg-icon-burger-close" />
            </button>
          </ModalHeaderContainer>
          <div id="campaignModalContent">
            <div id="testEmail">
              <Typography variant="subTitle" tag="p" style={{ marginBottom: "var(--gutter)" }}>
                {t("Send a test email")}
              </Typography>
              <form onSubmit={handleSendTestEmail}>
                <InputWithSubmit
                  fullWidth
                  label={t("Recipients separated by a coma")}
                  name="testEmails"
                  required
                  disabled={isSendingTestEmail}
                  type="text"
                  value={testEmails}
                  onChange={handleUpdateTestEmail}
                  placeholder="contact@mail.com,contact2@gmail.com"
                  submitText={isSendingTestEmail ? t("Sending test email") + "..." : t("Send test email")}
                />
              </form>
            </div>
            <hr style={{ margin: "var(--gutter) 0" }} />
            {!config?.plan?.trial?.active ? (
              <div id="sendCampaign">
                <Typography variant="subTitle" tag="p" style={{ marginBottom: "var(--gutter)" }}>
                  {t("Send campaign")} - {campaign.list?.title}
                </Typography>
                <p>
                  {campaign.list
                    ? t("This campaign will be sent to {{recipients}} recipients", {
                        recipients: campaign.list?.count ? campaign.list.count.toString() : "0"
                      })
                    : t("No recipients list was selected")}
                </p>
                <div className="sendingTime">
                  <Checkbox checked={sendAtTime} onChange={() => setSendAtTime(!sendAtTime)} label={t("Schedule for later")} />
                  {sendAtTime ? (
                    <DatePicker
                      dateFormat="dd/MM/yyyy hh:mm"
                      required
                      showTimeSelect={true}
                      value={sendingDate}
                      onChange={(e: any) => setSendingDate(moment(e).format())}
                    />
                  ) : null}
                </div>

                <ButtonV2
                  type="button"
                  variant="warning"
                  disabled={isSendingCampaign || !campaign.list || campaign.list?.count === 0}
                  onClick={() => handleSendToList()}>
                  <span>
                    {" "}
                    {isSendingCampaign
                      ? t("Submitting") + "..."
                      : t("Send to {{recipients}} recipients", {
                          recipients: campaign.list?.count ? campaign.list.count.toString() : "0"
                        })}
                  </span>
                </ButtonV2>
              </div>
            ) : (
              <div>
                <hr />
                <h2>{t("Trial notice")}</h2>
                <p>{t("Accounts in trial are not allowed to send campaigns in order to prevent spamming")}</p>
              </div>
            )}
          </div>
        </div>
      </ReactModal>
      <Prompt when={dirty} message={() => t("Changes were unsaved, are you sure?")} />
      <section className="header">
        <div className="left">
          <Typography variant="pageTitle">{t("Campaign")}</Typography>
          <Link to="/campaigns">
            <Button variant="noStyle" type="button" disabled={isSavingOrCreating}>
              {t("Back")}
            </Button>
          </Link>
        </div>
        <div className="right">
          {campaign.status === "draft" ? (
            <>
              {dirty ? (
                <div id="updateAndDelete">
                  <ButtonV2 variant="primary" form="edit" type="submit" disabled={isSavingOrCreating || !dirty}>
                    {dirty ? "*" : ""}
                    {isSavingOrCreating ? t("Saving") + "..." : t("Save")}
                  </ButtonV2>
                </div>
              ) : (
                <div id="send">
                  <ButtonV2
                    variant="primary"
                    disabled={dirty || isSavingOrCreating || !campaign.subject}
                    type="button"
                    onClick={async () => modalRef.current.open()}>
                    {isSavingOrCreating ? t("Saving") + "..." : t("Preview and send")}
                  </ButtonV2>
                </div>
              )}
            </>
          ) : null}
        </div>
      </section>
      {campaign.status === "sent" ? (
        <div id="report">
          <div id="reportHeader">
            <div className="left">
              {metrics && campaign.list ? (
                <div>
                  <Typography variant="secondaryTitle" tag="h2" style={{ marginBottom: "var(--gutter)" }}>
                    {t("This campaign was sent to")} {campaign.list.title} (#
                    {/* eslint-disable-next-line i18next/no-literal-string */}
                    {campaign.list.count}) - {moment(metrics.sendAt).fromNow()}
                  </Typography>
                  <div className="stats">
                    <h2>
                      {t("Open rate")}: {metrics?.openRate}%
                    </h2>
                    <h2>
                      {t("Delivery rate")}: {metrics.deliveryRate}%
                    </h2>
                    <h2>
                      {t("Bounce rate")}: {metrics.bounceRate}%
                    </h2>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div id="preview">
            <iframe title="Email preview" srcDoc={campaign.html || ""} />
          </div>
        </div>
      ) : (
        <div className="edit">
          <form onSubmit={handleSubmitCampaignForm} id="edit">
            <Tabs className="tabView" selectedIndex={index} onSelect={index => setIndex(index)}>
              <TabList className="tabList">
                {entries.map(e => (
                  <Tab className={"tab react-tabs__tab"} key={e.id}>
                    {e.title}
                  </Tab>
                ))}
              </TabList>

              <TabPanel>
                {campaign.fields && campaign.fields.length ? (
                  <div className="settings">
                    <div style={{ display: "grid", gridGap: "var(--gutter)" }}>
                      {campaign.fields.map(f => (
                        <div key={f.name} className={f.type || ""}>
                          {f.type === "text" ? (
                            <Input
                              label={f.description || f.name}
                              name={f.name}
                              required={f.required}
                              value={f.value || ""}
                              onChange={(e: any) => handleFieldValueChange({ fieldName: f.name || "", value: e.target.value })}
                              type="text"
                              placeholder={f.description}
                            />
                          ) : null}
                          {f.type === "html" ? (
                            <>
                              <p>{f.description}</p>
                              <QuillEditor
                                dependancies={campaign.fields}
                                content={f.value || ""}
                                onChange={content => handleFieldValueChange({ fieldName: f.name || "", value: content })}
                              />
                            </>
                          ) : null}
                          {f.type === "media" ? (
                            <label>
                              {f.description}
                              <MediaSelector
                                extensions={["png", "jpg", "jpeg"]}
                                onSelected={(selected: Media[]) =>
                                  handleFieldValueChange({
                                    value: selected.length ? selected[0].url || null : null,
                                    fieldName: f.name || ""
                                  })
                                }
                                onClear={() => handleFieldValueChange({ value: null, fieldName: f.name || "" })}
                                media={f.value ? ({ url: f.value, formatArray: [] } as any) : null}
                              />
                            </label>
                          ) : null}
                          {f.type === "color" ? (
                            <label>
                              {f.description}
                              <ColorPicker
                                presets={theme && theme.__typename === "ThemeV2" ? theme.colors.presets : []}
                                value={f.value}
                                onChange={value => handleFieldValueChange({ fieldName: f.name || "", value })}
                              />
                            </label>
                          ) : null}
                          {f.type === "font" ? (
                            <label>
                              {f.description}
                              <Select
                                value={{ label: f.value || "Default" }}
                                onChange={(value: any) => handleFieldValueChange({ fieldName: f.name || "", value: value.value })}
                                options={[{ label: "-", value: "Inter" }, ...Array.from(availableFonts).map(v => ({ label: v, value: v }))]}
                              />
                            </label>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}
              </TabPanel>
              <TabPanel>
                <div className="settings">
                  <label>
                    {t("Search through your inventory")}
                    <SearchListings config={config as Config} onSelect={handleSelectListing} />
                  </label>
                  {campaign.entries.length ? (
                    <Reorder
                      className="entries"
                      reorderId="draggable"
                      reorderGroup="reorder-group"
                      lock="horizontal"
                      holdTime={100}
                      onReorder={handleReorder}
                      autoScroll={true}
                      disabled={false}
                      disableContextMenus={false}>
                      {campaign.entries.map((e, i) => (
                        <div key={i} className="entry">
                          <div className="info">
                            <i className="cg-icon-burger" />
                          </div>
                          <div style={{ maxWidth: "40px" }}>
                            {e.item.data.images.length ? <img src={e.item.data.images[0].uri || ""} /> : null}
                          </div>
                          <Link to={e.item.path}>
                            {i + 1} - {e.item.descriptions.main}
                          </Link>
                          <ButtonV2 variant="warning" type="button" onClick={() => handleDeleteEntry(e.listing.id)}>
                            {t("Delete")}
                          </ButtonV2>
                        </div>
                      ))}
                    </Reorder>
                  ) : (
                    <p className="noItems">
                      {t("Add items from the search input above")} <FiArrowUp />
                    </p>
                  )}
                </div>
              </TabPanel>
              <TabPanel>
                <div className="settings">
                  <div className="twoColumns">
                    <Input
                      label={t("Campaign title")}
                      required
                      name="title"
                      onChange={handleTitleChange}
                      placeholder={t("eg. News of the week, Latest releases") + "..."}
                      value={campaign.title}
                    />
                    <Input
                      label={t("Email subject")}
                      required
                      name="subject"
                      onChange={handleTitleChange}
                      placeholder={t("eg. News of the week, Latest releases") + "..."}
                      value={campaign.subject}
                    />
                  </div>
                  <div className="twoColumns">
                    <SelectTemplate
                      value={campaign?.template ? { label: campaign?.template.title } : null}
                      label={t("Select a template")}
                      placeholder={t("Select a template")}
                      type="newsletter-html"
                      onChange={handleSelectTemplate}
                    />
                    <Select
                      label={t("Select a list of recipients")}
                      onChange={handleSelectList}
                      value={campaign && campaign.list ? { value: campaign.list.id, label: campaign.list.title } : null}
                      options={lists?.map(l => ({ value: l, label: l.title + " -  " + l.count + " recipients" })) || []}
                    />
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </form>
          <div id="preview">
            <iframe title="Email preview" srcDoc={campaign.html || ""} />
          </div>
        </div>
      )}
    </div>
  );
};

const ColorPicker = ({
  value,
  onChange,
  presets
}: {
  value?: string | null;
  onChange: (value: string) => void;
  presets: ThemeColorPreset[];
}) => {
  const [isPicking, setIsPicking] = useState(false);
  const ref = useRef<any>();

  const popover: any = {
    position: "absolute",
    zIndex: "2"
  };
  const cover: any = {
    position: "absolute",
    top: "30px"
  };

  useOutsideClick(ref, () => {
    setIsPicking(false);
  });

  const handleChangeColor = (color: ColorResult) => {
    onChange(color.hex);
  };

  return (
    <div
      ref={ref}
      className="colorZone"
      onClick={() => setIsPicking(true)}
      style={{ width: "100px", height: "50px", backgroundColor: `${value || "#000"}`, color: "white", border: "1px solid grey" }}>
      {isPicking ? (
        <div className="picker" style={popover}>
          <div style={cover}>
            <BlockPicker colors={presets.map(c => c.value)} color={value || "#ddd"} onChange={c => handleChangeColor(c)} />
          </div>
        </div>
      ) : null}
    </div>
  );
};
