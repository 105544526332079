import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { GlobalStore } from "../../stores/global";
import { FiCheck } from "react-icons/fi";
import { useQuery } from "@apollo/client";
import { GET_CONFIG_ONBOARDING } from "../../graphql/queries/config";
import { useTranslation } from "react-i18next";
import { colorsAsRgbString } from "@common-ground-io/colors";
import styled from "styled-components";
import { Typography } from "../../componentsV2/Typography";

const QuickStartStyled = styled.div`
  padding: 5px;
  background-color: ${({ theme }) => (theme.name === "light" ? colorsAsRgbString.greyLightest : colorsAsRgbString.greyDarkest)};
`;
const QuickStartTop = styled.div`
  padding: 10px 10px;
`;
const QuickStartStep = styled.div<{ active: boolean }>`
  color: ${({ active }) => (active ? colorsAsRgbString.success : "inherit")};
  padding: 5px;
  &:hover {
    opacity: 0.2;
  }
`;

export default function Tutorial() {
  const { tutorial } = GlobalStore.useState(s => s);
  const { data } = useQuery(GET_CONFIG_ONBOARDING);
  const { t } = useTranslation();
  const location = useLocation();

  if (location.hash.includes("quickstart")) localStorage.setItem("tutorial-hidden", "false");

  const steps = data?.configOnboarding?.steps || [];

  const hidden = localStorage.getItem("tutorial-hidden") === "true";

  const toggleMinimize = () => {
    localStorage.setItem("tutorial-minimized", tutorial.minimized ? "false" : "true");
    GlobalStore.update(s => {
      s.tutorial.minimized = !s.tutorial.minimized;
    });
  };

  const handleClose = () => {
    localStorage.setItem("tutorial-hidden", "true");
    GlobalStore.update(s => {
      s.tutorial.hidden = true;
    });
  };

  const todos = steps && steps.filter(s => !s.completed);

  useEffect(() => {
    if (todos.length === 0) handleClose();
  }, [todos.length]);

  useEffect(() => {
    if (!hidden) {
      GlobalStore.update(s => {
        s.tutorial.hidden = false;
      });
    }
  }, [hidden]);

  if (tutorial === undefined) return null;

  return (
    tutorial &&
    !tutorial.hidden && (
      <QuickStartStyled id="tutorial" className={"steps"}>
        <QuickStartTop className="top">
          <div className="text">
            <button type="button" onClick={toggleMinimize}>
              <Typography variant="subTitle" level="secondary">
                {" "}
                {t("Setup your shop")} {steps.length - todos.length}/{steps.length}
              </Typography>
            </button>
          </div>
          <div className="icons">
            <button onClick={handleClose}>
              <FaTimes />
            </button>
          </div>
        </QuickStartTop>

        <div className={`steps ${tutorial.minimized ? "minimized" : ""}`}>
          {steps.map((step, i) => (
            <Link to={step.path || ""} key={i} className="step">
              <QuickStartStep active={!!step.completed} className={`step ${step.completed ? "completed" : ""}`}>
                <div className="state">{step.completed ? <FiCheck /> : i + 1}</div>
                <div className="text">
                  <Typography variant="copy" level={step.completed ? "success" : "normal"}>
                    {step.title}
                  </Typography>
                </div>
              </QuickStartStep>
            </Link>
          ))}
        </div>
      </QuickStartStyled>
    )
  );
}

const QuickstartButton = () => {
  const { t } = useTranslation();

  const handleTutorialOpen = (e: any) => {
    e.preventDefault();
    localStorage.setItem("tutorial-hidden", "false");
    GlobalStore.update(s => {
      s.tutorial.hidden = !s.tutorial.hidden;
    });
  };

  return (
    <a href="" className="reset" onClick={handleTutorialOpen}>
      <span>{t("Quickstart")}</span>
    </a>
  );
};

export { QuickstartButton };
