import React, { useState } from "react";
import Button from "../styled/button";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import Liner from "../common/liner";
import Price from "../common/price";
import { FaEnvelope, FaPaperPlane } from "react-icons/fa";
import { IoMdDownload } from "react-icons/io";
import { GoDotFill } from "react-icons/go";
import Loader from "../common/loader";
import { RiUserUnfollowLine } from "react-icons/ri";
import { BiRefresh } from "react-icons/bi";
import { GlobalStore } from "../../stores/global";
import { POST_ORDER_GENERATE_PDF } from "../../graphql/queries/order";
import { useMutation } from "@apollo/client";
import { Order } from "../../__generated__/graphql";
import { AddNotification } from "../../types/globals";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

export default function OrderTable({ orders, highlight }: { orders: Order[]; highlight?: string }) {
  const addNotification = GlobalStore.useState(c => c.addNotification);
  const history = useHistory();

  const handleLineClick = (o: Order) => {
    history.push(`/order/${o.incId}`);
  };

  const { t } = useTranslation();

  return (
    <div id="orderTable">
      <div className="orderTableItem header">
        <p>{t("Origin - ID")}</p>
        <p>{t("Status")}</p>
        <p className="description">{t("Items")}</p>
        <p className="paid">{t("Billing")}</p>
        <p className="shipping">{t("Shipping")}</p>
        <p>{t("Customer")}</p>
        <p>{t("Total")}</p>
      </div>
      {orders &&
        orders.map((o, index) => (
          <Liner onClick={() => handleLineClick(o)} className="orderTableEntry" index={index} key={`${o.id}`}>
            <OrderTableEntry order={o} highlight={highlight} addNotification={addNotification} />
          </Liner>
        ))}
    </div>
  );
}

const OrderTableEntry = ({
  order: orderFromProps,
  highlight,
  addNotification
}: {
  order: Order;
  highlight?: string;
  addNotification: AddNotification;
}) => {
  const [generatePdf] = useMutation(POST_ORDER_GENERATE_PDF);
  const [order, setOrder] = useState(orderFromProps);
  const [isGeneratingInvoice, setIsGeneratingInvoice] = useState(false);
  const isCancelled = order.status.includes("Cancelled");
  const isMerged = order.status === "Merged";
  const invoice = order.billing && order.billing.invoice;

  const handleGeneratePdf = async () => {
    try {
      setIsGeneratingInvoice(true);
      const { data } = await generatePdf({ variables: { orderRef: order._id } });
      if (data?.orderGeneratePdf) setOrder({ ...order, billing: data.orderGeneratePdf.billing });
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    } finally {
      setIsGeneratingInvoice(false);
    }
  };

  return (
    <div className={`orderTableItem ${isMerged ? "merged" : ""} ${isCancelled ? "cancelled" : ""}`}>
      <div className="origin">
        <p>
          {order.origin === "Discogs" ? (
            <span>
              <img alt="Discogs logo" src="https://static.common-ground.io/common/media/dicsogs.png" />
            </span>
          ) : null}
          {order.origin} {order.origin === "Online" && order.buyer && !order.buyer._id ? <RiUserUnfollowLine /> : null}
        </p>
        <p className="id">
          <Link onClick={(e: any) => e.stopPropagation()} to={`/order/${order.incId}`}>
            <span title="Private id">#{order.incId}</span>
            <br />
            <span title="Public id">#{order.id}</span>
            {invoice && invoice.id ? (
              <>
                <br />
                <span>#{invoice.id}</span>
              </>
            ) : null}
          </Link>
        </p>

        <div className="pdf">
          {order.billing && order.billing.invoice.pdf ? (
            <a onClick={(e: any) => e.stopPropagation()} target="_tab" href={order.billing.invoice.pdf}>
              <Button variant="noStyle" type="button">
                <span>
                  <IoMdDownload />
                </span>{" "}
                <span>PDF</span>
              </Button>
            </a>
          ) : (
            <Button
              variant="noStyle"
              disabled={isGeneratingInvoice}
              type="button"
              onClick={(e: any) => {
                e.stopPropagation();
                handleGeneratePdf();
              }}>
              {isGeneratingInvoice ? (
                <Loader />
              ) : (
                <>
                  <BiRefresh />
                  <p>PDF</p>
                </>
              )}
            </Button>
          )}
        </div>
      </div>
      <div className="created">
        <p>{order.discogsStatus || order.status}</p>
        <p>{moment(order.created).format("ll")}</p>
        <p>{moment(order.created).fromNow()}</p>
      </div>
      <div className="description">
        {order.items.map(i => (
          <p
            key={i._id}
            className={`descriptionItem ${highlight && highlight === i.item.ref ? "highlight" : ""} ${i.unavailable ? "unavailable" : ""}`}>
            {i.item ? (
              <Link onClick={(e: any) => e.stopPropagation()} to={i.item.path || ""}>
                {/* eslint-disable-next-line i18next/no-literal-string */}
                {i.quantity}x <Price value={i.price.base} />
                {" - "}
                {i.item.description}
              </Link>
            ) : (
              <span>{t("Item missing")}</span>
            )}
          </p>
        ))}
      </div>
      <div className="5 billing">
        <p>
          <span className={order.billing.fulfilled || isCancelled ? "fulfilled" : "unfulfilled"}>
            <GoDotFill />
          </span>
          {isCancelled ? t("Cancelled") : order.billing.status}
        </p>
      </div>
      <div className="6 shipping">
        <p>
          <span className={order.shipping.fulfilled || isCancelled ? "fulfilled" : "unfulfilled"}>
            <GoDotFill />
          </span>
          {isCancelled ? t("Cancelled") : order.shipping.status}
        </p>
      </div>
      <div className="customer">
        {order.buyer ? (
          <>
            <p>
              {order.buyer._id ? <Link to={`/user/${order.buyer._id}`}>{order.buyer.name}</Link> : order.buyer.name}
              {order.buyer.organisation ? (
                <span>
                  <br />
                  {order.buyer.organisation}
                </span>
              ) : null}
            </p>
            <p className="icons">
              <span className="contacted">
                {order.buyerWasContacted ? <FaPaperPlane title={t("You have contacted the buyer")} /> : null}
              </span>
              <span>{order.unreadMessagesCount && order.unreadMessagesCount > 0 ? <FaEnvelope title={t("New message")} /> : null}</span>
            </p>
          </>
        ) : (
          <span />
        )}
      </div>
      <div>
        <Price value={order.totals.grand} />
      </div>
    </div>
  );
};
