import React, { useState, useRef } from "react";
import Button from "../../styled/button";
import { Button as ButtonV2 } from "../../../componentsV2/Button";
import Zone from "../../styled/zone";
import Loader from "../../common/loader";
import Liner from "../../common/liner";
import { useStripe, Elements } from "@stripe/react-stripe-js";
import { PaymentIntent, loadStripe } from "@stripe/stripe-js";
import moment from "moment";
import Modal from "../../modal";
import { useMutation, useQuery } from "@apollo/client";
import { GET_INVOICES, POST_INVOICE_CHARGE } from "../../../graphql/queries/invoice";
import * as Sentry from "@sentry/react";
import { GET_CUSTOMER, POST_STRIPE_PAYMENT_METHOD } from "../../../graphql/queries/config";
import { GlobalStore } from "../../../stores/global";
import IbanForm from "./includes/ibanForm";
import CardForm from "./includes/card";
import { InvoicesPagination } from "../../common/pagination";
import BlockedAccount from "./blocked";
import { Config, ConfigStripeUser, Invoice, InvoicesConnection, ConfigStripUserPaymentMethod } from "../../../__generated__/graphql";
import { AddNotification } from "../../../types/globals";
import Termination from "./includes/termination";
import { useTranslation } from "react-i18next";
import { GET_PLANS } from "../../../graphql/queries/plan";
import { getFormattedPrice } from "../../../utils";
import { PriceInConfiCurrency } from "../../common/price";
import { ModalHeaderContainer } from "../../../componentsV2/SectionHeader/SectionHeader.styles";
import { Typography } from "../../../componentsV2/Typography";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE as string);

const Membership = () => {
  const { config, addNotification, configReload, logout, helpPanel } = GlobalStore.useState(c => c);
  const { data } = useQuery(GET_CUSTOMER, { fetchPolicy: "cache-and-network" });
  const { data: plansData } = useQuery(GET_PLANS, {
    variables: { currencies: [(config as Config).plan.currency] },
    fetchPolicy: "cache-and-network"
  });
  const customer = data && data.configStripeUser;
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const { data: invoicesData, refetch: refetchInvoices } = useQuery(GET_INVOICES, {
    fetchPolicy: "cache-and-network",
    variables: { page, limit: 8 }
  });
  const invoices = invoicesData?.invoices;
  const hasOutstandingInvoices = !!invoices?.hasOutstandingInvoices;

  const nextPaymentDate = config?.plan && config.plan.nextPaymentDate;

  const defaultSource = customer && customer.defaultPaymentId;
  const defaultPaymentMethod = defaultSource && customer.paymentMethods?.find(pm => pm.id === defaultSource);
  const names = { card: "Card", sepa_debit: "SEPA Debit" };

  const handleMembershipStarted = async () => {
    configReload();
    refetchInvoices();
  };

  const plans = plansData?.plans?.plans || [];

  return (
    <div className="membership">
      {!config?.status?.active && plans.length ? (
        <BlockedAccount
          handleReload={handleMembershipStarted}
          customer={customer as ConfigStripeUser}
          plans={plans}
          hasOutstandingInvoices={hasOutstandingInvoices}
        />
      ) : null}
      <Zone>
        <div className="header">
          <h2 className="text-l-medium">{t("Subscription plans")}</h2>
          <p>
            {t("Started on")} {moment(config?.created).format("ll")} ({moment(config?.created).fromNow()})
          </p>
        </div>
        <div className="plans">
          {plans.map(p => {
            const active = config?.plan && p.title === config?.plan.title;
            const seats = active ? config?.plan?.seats : p.seats;
            return (
              <Zone inverted key={p.title} className={`plan ${active ? "active" : ""}`}>
                <div className="title">
                  <h2>{p.title}</h2>
                  <p>
                    {seats} {(seats || 0) > 1 ? t("seats") : t("seat")}
                  </p>
                </div>
                <div className="price">
                  <h2>
                    {getFormattedPrice({ value: (active ? config?.plan.price.value : p.price.value) || 0, config: config as Config })}
                  </h2>
                  {p.trial ? <p>{t("Includes a {{daysOfTrial}} days free trial", { daysOfTrial: p.trial.days })}</p> : null}
                </div>
                <p className="description">{p.description}</p>
              </Zone>
            );
          })}
        </div>
      </Zone>
      <Zone className="invoices">
        <div className="nextPaymentDate">
          <h2 className="text-xl">
            {t("Next payment date")}: {nextPaymentDate ? moment(nextPaymentDate).format("ll") : t("Not scheduled")}
          </h2>
          {nextPaymentDate && defaultPaymentMethod ? (
            <p>
              {/* @ts-ignore */}
              {t("We will charge your payment method")}: {names[defaultPaymentMethod.type]}{" "}
              {defaultPaymentMethod.last4 ? `**** ${defaultPaymentMethod.last4}` : ""}
            </p>
          ) : null}
        </div>
        <Invoices
          addNotification={addNotification}
          customer={customer}
          defaultSource={defaultSource}
          configReload={configReload}
          invoices={invoices as InvoicesConnection}
          page={page}
          setPage={setPage}
        />
      </Zone>
      <PaymentMethods addNotification={addNotification} customer={customer} />
      <Termination config={config as Config} addNotification={addNotification} logout={logout} helpPanel={helpPanel} />
    </div>
  );
};

const Invoices = ({
  addNotification,
  customer,
  defaultSource,
  configReload,
  invoices,
  setPage,
  page
}: {
  addNotification: AddNotification;
  customer?: ConfigStripeUser;
  defaultSource?: string | null;
  configReload: any;
  invoices: InvoicesConnection;
  setPage: any;
  page: number;
}) => {
  const { t } = useTranslation();

  if (!invoices) return <Loader />;

  return (
    <div className="invoicesTable" id="invoicesTable">
      <div className="header flexSpaceBetween">
        <h2 className="text-l-medium">
          {t("Invoices")} ({invoices.pagination.count})
        </h2>
        <InvoicesPagination pagination={invoices.pagination} onNext={() => setPage(page + 1)} onPrevious={() => setPage(page - 1)} />
      </div>

      <section className="table">
        {invoices?.invoices.map((i, index) => (
          <Liner key={i.id} index={index} className={"invoiceTableEntry"}>
            <p className="date">
              {moment(i.created).format("ll")}
              <span className="fromNow">({moment(i.created).fromNow()})</span>
            </p>
            <p className="description">
              #{i.id}
              <span className="title"> - {i.title || i.note}</span>
            </p>
            <p className={`status ${!i.paid.status && !i.paid.processing ? t("unpaid") : ""}`}>
              {i.paid.status ? (
                <>{t("Paid")}</>
              ) : (
                <>
                  {i.paid.processing ? (
                    <>
                      <br />
                      {t("Processing")}...
                    </>
                  ) : null}
                </>
              )}
            </p>
            <p className="price">
              <PriceInConfiCurrency value={i.totals.grand} currency={i.currency} />
            </p>
            <div className="actions">
              {i.pdf ? (
                <div>
                  <a target="tab" href={i.pdf}>
                    <Button variant="secondaryOverZone" type="button">
                      PDF
                    </Button>
                  </a>
                </div>
              ) : null}
              {!i.paid.status && !i.paid.processing ? (
                <Elements stripe={stripePromise}>
                  <ChargeInvoice
                    invoice={i}
                    addNotification={addNotification}
                    handleInvoiceCharged={configReload}
                    paymentMethods={(customer && customer.paymentMethods) || []}
                    defaultSource={defaultSource}
                  />
                </Elements>
              ) : null}
            </div>
          </Liner>
        ))}
      </section>
    </div>
  );
};

const ChargeInvoice = ({
  invoice,
  handleInvoiceCharged,
  paymentMethods,
  defaultSource,
  addNotification
}: {
  invoice: Invoice;
  handleInvoiceCharged: any;
  paymentMethods: ConfigStripUserPaymentMethod[];
  defaultSource?: string | null;
  addNotification: AddNotification;
}) => {
  const [chargeInvoice] = useMutation(POST_INVOICE_CHARGE);
  const [isCharging, setIsCharging] = useState(false);
  const stripe = useStripe();
  const modalRef = useRef<any>();
  const { t } = useTranslation();

  const handleSubmitPayment = async (e: any) => {
    e.preventDefault();
    console.log(e.target.method);

    if (!e.target.method.value) return;
    setIsCharging(true);
    try {
      const { data } = await chargeInvoice({ variables: { invoiceRef: invoice._id, paymentMethodId: e.target.method.value } });
      if (!data?.invoiceCharge) throw new Error("Error during charge");
      if (data.invoiceCharge.error && data.invoiceCharge.error === "authentication_required") {
        const intent = data.invoiceCharge.intent;
        const results = await stripe?.confirmCardPayment(intent.client_secret, {
          payment_method: intent.last_payment_error.payment_method.id
        });
        if (results?.error) addNotification({ ok: 0, message: results.error.message || "" });
        else if (results?.paymentIntent.status === "succeeded") {
          addNotification({ ok: 1, message: "Invoice was paid successfully" });
          window.location.reload();
        }
      } else if (data.invoiceCharge.ok) addNotification({ ok: 1, message: data.invoiceCharge.message || "" });
      await new Promise(resolve => setTimeout(resolve, 2000));
      await handleInvoiceCharged();
    } catch (e: any) {
      Sentry.captureException(e);
      addNotification({ ok: 0, message: e.message });
    } finally {
      setIsCharging(false);
    }
  };
  const totalToCharge = invoice.totals;

  return (
    <div className="setAsPaid">
      <Modal style={{}} ref={modalRef}>
        <div id="payMyInvoiceModalContent">
          <ModalHeaderContainer>
            <Typography variant="pageTitle" tag="h2">
              {t("Invoice payment")} #{invoice.id}
            </Typography>
            <button className="reset" type="button" onClick={() => modalRef.current.close()}>
              <i className="cg-icon-burger-close" />
            </button>
          </ModalHeaderContainer>

          {!paymentMethods || paymentMethods.length === 0 ? (
            <div className="noPaymentMethod">
              <p>
                {t("You have not added any payment method yet. Please add a payment method in order to process the payment", {
                  interpolation: { escapeValue: false }
                })}
                .
              </p>
              <ButtonV2 variant="primary" type="button" onClick={() => modalRef.current.close()}>
                {t("Add a payment method")}
              </ButtonV2>
            </div>
          ) : (
            <form onSubmit={handleSubmitPayment}>
              <p>{t("Select your preferred payment method below")}:</p>
              {paymentMethods.map(p => (
                <label key={p.id}>
                  <input
                    type="radio"
                    name="method"
                    required
                    placeholder={t("Enter a payment method")}
                    value={p.id}
                    defaultChecked={p.id === defaultSource}
                  />
                  {/* eslint-disable-next-line i18next/no-literal-string */}
                  {"*** **** ****"} {p.last4} {p.id === defaultSource ? ` - ${t("Default")}` : ""}
                </label>
              ))}
              <div className="total">
                <p>
                  {t("Payable total for this invoice is")} <PriceInConfiCurrency value={totalToCharge.grand} currency={invoice.currency} />
                </p>
              </div>
              <ButtonV2 variant="primary" type="submit" disabled={!paymentMethods || paymentMethods.length === 0 || isCharging}>
                {isCharging ? <Loader /> : t("Submit payment")}
              </ButtonV2>
            </form>
          )}
        </div>
      </Modal>
      <ButtonV2 variant="primary" onClick={() => modalRef.current.open()}>
        {t("Pay my invoice")}
      </ButtonV2>
    </div>
  );
};

export const PaymentMethods = ({ addNotification, customer }: { addNotification: AddNotification; customer?: ConfigStripeUser }) => {
  const [methodBeingAdded, setMethodBeingAdded] = useState<any>(null);
  const [isWorking, setIsWorking] = useState(false);
  const [stripePaymentMethodUpdate] = useMutation(POST_STRIPE_PAYMENT_METHOD);
  const sources = customer && customer.paymentMethods;
  const defaultSource = customer && customer.defaultPaymentId;
  const { t } = useTranslation();

  const handleCompleteSetup = async (intent: PaymentIntent) => {
    if (!intent.payment_method) return;
    try {
      await stripePaymentMethodUpdate({ variables: { attachPaymentMethodId: intent.payment_method.toString() } });
      addNotification({ ok: 1, message: "Payment method added" });
      setMethodBeingAdded(null);
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
  };

  const handleSetAsDefault = async (paymentMethodId: string) => {
    setIsWorking(true);
    try {
      await stripePaymentMethodUpdate({ variables: { defaultPaymentMethodId: paymentMethodId } });
      addNotification({ ok: 1, message: "Payment method set as default" });
    } catch (e: any) {
      addNotification({ ok: 0, message: e.data });
    } finally {
      setIsWorking(false);
    }
  };

  const handleDeleteMethod = async (paymentMethodId: string) => {
    setIsWorking(true);
    try {
      await stripePaymentMethodUpdate({ variables: { detachPaymentMethodId: paymentMethodId } });
      addNotification({ ok: 1, message: "Payment method removed" });
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    } finally {
      setIsWorking(false);
    }
  };

  if (customer === undefined) return <Loader />;
  else if (customer === null) return <p>{t("No Stripe account found")}</p>;

  const names = { card: t("Card"), sepa_debit: t("SEPA Debit") };
  const hasNoMethod = !sources || sources.length === 0;

  if (hasNoMethod && !methodBeingAdded)
    return (
      <div id="paymentMethods" className={"paymentMethods hasNoMethod"}>
        <div className="header">
          <div className="left">
            <h2 className="text-l-medium">{t("Your payment methods")}</h2>
          </div>
          <div className="right">
            <Button variant="danger" onClick={() => setMethodBeingAdded("card")}>
              {t("Add a Bank Card")}
            </Button>
            <Button variant="danger" onClick={() => setMethodBeingAdded("sepa")}>
              {t("Setup SEPA")}
            </Button>
          </div>
        </div>
        <div className="hasNoMethod">
          <h3>
            {t("You have not added a payment method. Please add a bank card or configure SEPA to keep your account active", {
              interpolation: { escapeValue: false }
            })}
            .
          </h3>
        </div>
      </div>
    );

  return (
    <Zone id="paymentMethods" className={`paymentMethods ${hasNoMethod ? "hasNoMethod" : ""}`}>
      <div className="header">
        <div className="left">
          <h2 className="text-l-medium">{t("Your payment methods")}</h2>
        </div>
        <div className="right">
          {methodBeingAdded ? (
            <Button variant="secondaryOverZone" onClick={() => setMethodBeingAdded(null)}>
              {t("Cancel")}
            </Button>
          ) : (
            <>
              <Button variant="secondaryOverZone" onClick={() => setMethodBeingAdded("card")}>
                {t("Add a Bank Card")}
              </Button>
              <Button variant="secondaryOverZone" onClick={() => setMethodBeingAdded("sepa")}>
                {t("Setup SEPA")}
              </Button>
            </>
          )}
        </div>
      </div>
      <div className="content">
        {methodBeingAdded ? (
          <div className="addingPaymentMethod">
            <Elements stripe={stripePromise}>
              {methodBeingAdded === "card" ? <CardForm addNotification={addNotification} onFinish={handleCompleteSetup} /> : null}
              {methodBeingAdded === "sepa" ? <IbanForm addNotification={addNotification} onFinish={handleCompleteSetup} /> : null}
            </Elements>
          </div>
        ) : null}

        {!methodBeingAdded ? (
          <div className="sources">
            {sources?.map(s => (
              <div key={s.id} className={`source ${s.id === defaultSource ? t("Default") : ""}`}>
                <p>{s.name || s.type}</p>
                {/* eslint-disable-next-line i18next/no-literal-string */}
                <p className="last4"> *** **** **** {s.last4}</p>
                {/* @ts-ignore */}
                <p className="type">{s.type === "card" ? s.brand?.toUpperCase() : names[s.type]}</p>
                <p className="expires">{s.type === "card" ? `Expires: ${s.expMonth + "/" + s.expYear}` : ""}</p>
                {s.id !== defaultSource ? (
                  <div className="delete">
                    <ButtonV2
                      type="button"
                      disabled={isWorking || s.id === defaultSource}
                      variant="warning"
                      onClick={() => window.confirm(t("Are you sure?")) && handleDeleteMethod(s.id)}>
                      {t("Delete")}
                    </ButtonV2>
                  </div>
                ) : (
                  <span />
                )}
                {s.id === defaultSource ? (
                  <div className="default">
                    <Button type="button" variant="noStyle" disabled>
                      {t("Default")}
                    </Button>
                  </div>
                ) : (
                  <div className="default">
                    <ButtonV2 type="button" variant="secondary" disabled={isWorking} onClick={() => handleSetAsDefault(s.id)}>
                      {t("Make default")}
                    </ButtonV2>
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </Zone>
  );
};

export default Membership;
