import React from "react";
import { Select } from "../styled/select";
import { Buyer, List as Ilist } from "../../__generated__/graphql";

const Lists = ({ addToList, removeFromList, user, lists }: { addToList: any; removeFromList: any; user: Buyer; lists: Ilist[] }) => {
  const handleDropdownChange = async (values: any, event: any) => {
    if (event.action === "select-option") addToList(user, event.option.value);
    else if (event.action === "clear") {
      for (const list of user.lists) {
        await removeFromList(user, list.ref);
      }
    } else if (event.action === "remove-value") await removeFromList(user, event.removedValue.value);
  };

  const entries: any = [];
  const options: any = [];
  lists.forEach(l => {
    const isInList = user.lists.find(ul => ul.ref === l._id);
    const isOptedOut = isInList && isInList.optout?.status;
    if (isInList && !isOptedOut) {
      entries.push({ label: l.title, value: l._id, disabled: isOptedOut });
    }
    options.push({
      label: `${l.title} ${isOptedOut ? ` - Opted out with reason: ${isInList.optout?.reason}` : ""}`,
      value: l._id,
      disabled: isOptedOut
    });
  });

  return (
    <Select
      placeholder="Add to list..."
      variant="overZone"
      isMulti={true}
      label="Lists"
      isClearable={false}
      options={options}
      value={entries}
      onChange={(a: any, b: any) => handleDropdownChange(a, b)}
    />
  );
};

export { Lists };
